import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CLIENT_INFO, USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { CollaborationVM, JourneyCollaborations } from '../../collaboration.model';
import { privilegeOptions } from 'src/modules/reporting/constant/privilegeOptions';
import { NgxSpinnerService } from 'ngx-spinner';

export interface UserModel {
  email: string;
  userId?: number;
}

@Component({
  selector: 'app-edit-permission-dialog',
  templateUrl: './edit-permission-dialog.component.html',
  styleUrls: ['./edit-permission-dialog.component.scss'],
})
export class EditPermissionDialogComponent implements OnInit {

  selectable = true;
  removable = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  user: { userId: number; email: string, privilege: string };
  privilegeOptions = privilegeOptions;
  selectedPrivilege = new FormControl();

  journey: any;

  constructor(
    public dialogRef: MatDialogRef<EditPermissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public userService: UsersService,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document,
  ) {

  }

  ngOnInit() {
    this.journey = this.data.journey;
    this.user = { userId: this.journey.sharedWith, email: this.journey.email, privilege: this.journey.privilege };
    this.selectedPrivilege.setValue(this.journey.privilege);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const shareVM: JourneyCollaborations = {
      Id: this.journey.id,
      JourneyId: this.journey.journeyId,
      SharedBy: this.journey.sharedBy,
      SharedWith: this.journey.userId,
      ClientConfigurationId: this.storage.get(CLIENT_INFO).clientConfigurationId,
      Instance: this.document.baseURI,
      Privilege: this.selectedPrivilege.value
    };
    this.spinner.show();
    this.configService
      .updateJourneyCollaborations(shareVM)
      .subscribe((res) => {
        this.spinner.hide();
        this.dialogRef.close(true);
      });

  }

}
