
export const CATEGORY_COL_DEFS = [
  {
    headerName: 'Name',
    field: 'categoryName',
    minWidth: 50,
    resizable: true,
  },
  {
    headerName: "Parent Id",
    field: "parentId",
    width: 120,
    enableRowGroup: true

  },
  // {
  //   headerName: 'Status',
  //   field: 'active',
  //   minWidth: 50,
  //   resizable: true,
  //   cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
  //   cellStyle: (params) => {
  //     if (params.value === false) {
  //       return { color: 'red', 'font-weight': '600' };
  //     } else {
  //       return { color: 'green', 'font-weight': '500' };
  //     }
  //   }
  // }
];
