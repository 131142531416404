import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { PlotlyHTMLElement } from 'plotly.js-dist-min';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SegmentationService } from 'src/services/segmentation.service';

@Component({
  selector: 'app-comparison-segmentaion',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit, AfterViewInit {

  @ViewChildren('radarChart') containerDivs: QueryList<ElementRef>;

  @Input() runTabs: Array<any>;
  @Input() sampleData: any;
  @Input() resizeGraphSubject: Subject<boolean>;
  
  allSegments = [];

   // spider chart
   spiderPlotConfig = {
    data: [
      {
        r: [39, 28, 8, 7, 28, 39],
        theta: ['A', 'B', 'C', 'D', 'E', 'A'],
        type: 'scatterpolar',
        fill: 'toself',
        name: 'Group A'
      },
      {
        r: [5, 14, 30, 17, 25],
        theta: ['A', 'B', 'C', 'D', 'F'],
        type: 'scatterpolar',
        fill: 'toself',
        name: 'Group B'
      }
    ],
    layout: {
      autosize: true
    }
  };

  constructor(private plotlyRef: PlotlyService, private segmentationService: SegmentationService) { }

  ngOnInit(): void {
    // for window resize handling (graph responsiveness)
    fromEvent(window, 'resize').pipe(
      debounceTime(250))
      .subscribe(() => this.refreshAll());

    this.resizeGraphSubject
      .pipe(debounceTime(250))
      .subscribe(() => this.refreshAll());
  }
  
  ngAfterViewInit() {
    this.fetchAllSegments();
  }

  plotGraph(data, layout, htmlDivElement: HTMLDivElement) {
    this.plotlyRef.newPlot(
      htmlDivElement,
      data,
      layout
    ).then((res: PlotlyHTMLElement) => {
    });
  }

  refreshAll() {
    this.containerDivs && this.containerDivs.forEach((e, index) => {
      this.refreshGraph(this.runTabs[index].chartData, this.spiderPlotConfig.layout, e.nativeElement);
    });
  }

  refreshGraph(data, layout, htmlDivElement) {
    this.plotlyRef.update(htmlDivElement, data, layout);
  }

  fetchAllSegments() {
    this.segmentationService.fetchAllSegments(this.sampleData.userId, this.sampleData.fileName, this.sampleData.versionNo, 'RUN_0')
    .subscribe((res: any) => {
      this.allSegments = res.payload;
      this.fetchPlots();
    });
  }

  fetchPlots() {
    this.containerDivs && this.containerDivs.forEach((e, index) => {
      const runTabData = this.runTabs[index];
      this.segmentationService.fetchSpiderPlotData(runTabData.userId, runTabData.fileName, runTabData.versionNo, runTabData.uu_id)
      .subscribe((res: any) => {
        this.plotSpiderChart(res.payload, e.nativeElement, index);
      });
    });
  }

  plotSpiderChart(data, nativeElement, index) {
    const spiderChartData = [];
    if (data) {
      for (let i=0; i<data[0].length; i++) {
        let segmentName = '';
        this.allSegments.forEach(element => {
          (element.uu_id == i+1) ? segmentName = element.name : null;
        });
        const r = data[0][i];
        segmentName && spiderChartData.push({
          r: r,
          theta: data[1],
          type: 'scatterpolar',
          fill: 'toself',
          name: segmentName
        });
      }
    }
    this.runTabs[index].chartData = spiderChartData;
    this.plotGraph(spiderChartData, this.spiderPlotConfig.layout, nativeElement);
  }

}
