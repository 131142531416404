import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bulk-update-planogram',
  templateUrl: './bulk-update-planogram.component.html',
  styleUrls: ['./bulk-update-planogram.component.scss']
})
export class BulkUpdatePlanogramComponent implements OnInit {

  selectedColumns = [];

  constructor(private dialogRef: MatDialogRef<BulkUpdatePlanogramComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      selectedData: Array<any>
    },
    private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  close(state: any) {
    this.dialogRef.close(state || false);
  }

  pasted(event: ClipboardEvent) {
    if (!this.selectedColumns.length) {
      this.toastrService.error('Please select some column to continue');
      return;
    }
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let row_data = pastedText.split('\n');
    row_data =  row_data.filter(e =>  e);
    if (row_data.length != this.data.selectedData.length) {
      this.toastrService.error('Something wrong with your pasted data');
      return;
    }

    for (let i=0; i < row_data.length; i++) {
      row_data[i].replace('\r', '');
      let columns_splitted = row_data[i].split('\t');
      columns_splitted =  columns_splitted.filter(e =>  e);
      if (columns_splitted.length != this.selectedColumns.length) {
        this.toastrService.error('Pasted columns should be equal to the selected ones.');
        return;
      } else {
        for (let j=0; j < this.selectedColumns.length; j++) {
          columns_splitted[j] = columns_splitted[j].replace('\r', '');
          if (!columns_splitted[j]) {
            this.toastrService.error('Pasted data seems to be inconsistent.');
            return;
          }
          else this.data.selectedData[i][this.selectedColumns[j]] = columns_splitted[j];
        }
      }
    }
    this.close(this.data.selectedData);
  }

}
