<div class="container-fluid promo-manager-detail-page">
  <div class="item-wrapper m-5">
    <form class="promo-form" [formGroup]="seasonalityEditForm">
      <div class="d-flex">
        <div class="alert alert-info mr-3" *ngIf="checkWeekDiff">
          No. of Weeks: {{getWeeksDiff}}
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div>
            <label for="seasonalityName">Index Name</label>
            <div class="form-group">
              <mat-form-field >
                <input matInput type="text" name="seasonalityName" formControlName="seasonalityName" style="width:93%" />
              </mat-form-field>
            </div>
            <div
              *ngIf="seasonalityEditForm.controls['seasonalityName'].invalid  && (seasonalityEditForm.controls['seasonalityName'].dirty || seasonalityEditForm.controls['seasonalityName'].touched)"
              class="alert alert-danger">
              Please enter the Index Name.
            </div>
          </div>
        </div>
        <div class="col-4">
          <div >
            <label for="seasonalityLevelName">Index Level</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput readonly type="text" name="seasonalityLevelName"
                  formControlName="seasonalityLevelName" style="width:93%"/>
              </mat-form-field>
            </div>
            <div
              *ngIf="seasonalityEditForm.controls['seasonalityLevelName'].invalid  && (seasonalityEditForm.controls['seasonalityLevelName'].dirty || seasonalityEditForm.controls['seasonalityLevelName'].touched)"
              class="alert alert-danger">
              Please enter the Index Level.
            </div>
          </div>
        </div>

        <div class="col-4">
          <div >
            <label for="retailer">{{_dataService.getDataById(1)}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput readonly type="text" name="retailer" formControlName="retailer" style="width:93%"/>
              </mat-form-field>
            </div>
            <div
              *ngIf="seasonalityEditForm.controls['retailer'].invalid  && (seasonalityEditForm.controls['retailer'].dirty || seasonalityEditForm.controls['retailer'].touched)"
              class="alert alert-danger">
              Please enter the Account Name.
            </div>
          </div>
        </div>

        <div class="col-4" *ngIf="validateSeasonalityLevelControls('brand')">
          <label for="brands">{{_dataService.getDataById(2)}}</label>
          <div class="form-group">
            <mat-select name="brands" formControlName="brands" multiple>
              <mat-option *ngFor="let brand of brandList" [value]="brand" disabled>
                {{brand}}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="col-4" *ngIf="validateSeasonalityLevelControls('productGroup')">
          <label for="productGroups">{{_dataService.getDataById(3)}}</label>
          <div class="form-group">
            <mat-select name="productGroups" formControlName="productGroups" multiple>
              <mat-option *ngFor="let productGroup of productGroupList" [value]="productGroup" disabled>
                {{productGroup}}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="col-4" *ngIf="validateSeasonalityLevelControls('skuDescription')">
          <label for="skuDescriptions">{{_dataService.getDataById(5)}}</label>
          <div class="form-group">
            <mat-select name="skuDescriptions" formControlName="skuDescriptions" multiple>
              <mat-option *ngFor="let skuDescription of skuDescriptionList" [value]="skuDescription" disabled>
                {{skuDescription}}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="col-4" *ngIf="validateSeasonalityLevelControls('sku')">
          <label for="sku">{{_dataService.getDataById(4)}}</label>
          <div class="form-group">
            <mat-select name="sku" formControlName="sku" multiple>
              <mat-option *ngFor="let sku of skuList" [value]="sku" disabled>
                {{sku}}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="col-4" *ngIf="seasonalityType.value==='static'">
          <label for="endWeekDate">End Week*</label>
          <div class="form-group">
            <mat-form-field>
              <input readonly matInput [matDatepickerFilter]="weekDayFilter" [matDatepicker]="endDatePicker"
                name="endWeekDate" formControlName="endWeekDate" (dateChange)="endDateChange($event)" [max]="endDateMax"
                [min]="endDateMin">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-4" *ngIf="seasonalityType.value==='static'">
          <label for="startWeekDate">Start Week*</label>
          <div class="form-group">
            <mat-form-field>
              <input readonly matInput [matDatepickerFilter]="weekDayFilter" [matDatepicker]="startDatePicker"
                name="startWeekDate" formControlName="startWeekDate" (dateChange)="startDateChange($event)"
                [max]="startDateMax">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div
            *ngIf="!isValidWeeks || (seasonalityEditForm.controls['endWeekDate'].invalid  && (seasonalityEditForm.controls['endWeekDate'].dirty || seasonalityEditForm.controls['endWeekDate'].touched))"
            class="alert alert-danger">
            End date is required and should be minimum 52 and maximum 156 weeks older from start date.
          </div>
        </div>
        <div class="col-4" *ngIf="seasonalityType.value=== 'rolling'">
          <label for="rollingWeek">Rolling Weeks</label>
          <div class="form-group">
            <mat-form-field>
              <input matInput type="number" name="rollingWeek" formControlName="rollingWeek" />
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="create-button">
        <mat-slide-toggle class="promo-toggle-btn ml-10 mt-five" name="active" formControlName="active">Active
        </mat-slide-toggle>
        <button mat-flat-button *ngIf="isGridLoaded" color="warn" class="ml-10"
          (click)="onExportGridData()">Export</button>
        <button *ngIf="isFormValueUpdated" mat-flat-button color="warn" class="ml-10"
          (click)="applyDataChanges()">Apply</button>
        <button *ngIf="isApplyBtnClicked && isFormValueUpdated" mat-flat-button color="warn" class="ml-10"
          (click)="updateSeasonality()">Save</button>
      </div>
    </form>
  </div>
  <hr />
  <div class="m-5" *ngIf="loadChartData">
    <fusioncharts width="100%" height="500" [chartConfig]=chartConfig [dataSource]=dataSource>
    </fusioncharts>
  </div>

  <hr />
  <div class="mapping-action-btn row-reverse mb-2">

    <button *ngIf="isGridDataUpdated" mat-flat-button color="accent" class="mr-10"
      (click)="updateChartData()">Apply</button>
    <button *ngIf="isGridDataUpdated" mat-flat-button color="accent" class="mr-10"
      (click)="saveUpdatedGridData()">Save</button>
  </div>
  <div class="all-show-grid forecast-engine-ag-grid" *ngIf="isGridLoaded">
    <ag-grid-angular #seasonalityWeeksGrid id="seasonality-weeks-data-grid"
      class="ag-theme-material seasonality-weeks-data-grid" [components]="components"
      [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowData]="gridData" [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)" (cellValueChanged)="gridDataUpdated($event)">
    </ag-grid-angular>
  </div>

</div>
