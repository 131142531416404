import { dateRenderer, statusRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import { tooltipcellRenderer } from "src/common/util/util";
import { AppInjector } from "src/shared/shared.module";

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getColDefs = (hierarchyMetadataList: any = [], nodeMetadataList: any = []) => {

  const hierarchyMetadataColDef: any = [];
  const nodeMetadataColDef: any = [];
  hierarchyMetadataList.forEach(item => {
    hierarchyMetadataColDef.push({
      headerName: item.locationHierarchyName,
      field: `l${item.locationHierarchyLevel}Value`,
      cellRenderer: 'locationSetupEditRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    });
  });
  nodeMetadataList.forEach(node => {
    if (node.locationHierarchyNodeLevel === 2) {
      nodeMetadataColDef.push({
        headerName: node.locationHierarchyNodeName,
        field: `node${node.locationHierarchyNodeLevel}`,
        cellRenderer: 'locationSetupEditRenderer',
        filter: 'agTextColumnFilter',
        cursor: 'pointer',
        filterParams: {
          cellRenderer: tooltipcellRenderer,
          suppressAndOrCondition: true,
          trimInput: true,
          debounceMs: 2000,
          filterOptions: ['contains', 'equals']
        }
      });
    } else {
      nodeMetadataColDef.push({
        headerName: node.locationHierarchyNodeName,
        field: `node${node.locationHierarchyNodeLevel}`,
        cellRenderer: 'locationSetupEditRenderer',
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
          trimInput: true,
          debounceMs: 2000,
          filterOptions: ['contains', 'equals']
        },
      });
    }
  });
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    ...hierarchyMetadataColDef,
    ...nodeMetadataColDef,
    {
      headerName: 'Status',
      field: 'active',
      filter: false,
      sortable: false,
      width: 110,
      cellRenderer: statusRenderer,
    },
    {
      headerName: 'Launch Date',
      field: 'launchDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      },
      width: 150,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Closing Date',
      field: 'closingDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      },
      width: 150,
      cellRenderer: dateRenderer,
    },
  ];

  return colDefs;
}




export const getExportTemplateColDef = (hierarchyMetadataList: any = [], nodeMetadataList: any = []) => {
  const hierarchyMetadataColDef: any = [];
  const nodeMetadataColDef: any = [];
  hierarchyMetadataList.forEach(item => {
    hierarchyMetadataColDef.push({
      headerName: item.locationHierarchyName,
      children: [{
        headerName: `L${item.locationHierarchyLevel}`,
        field: `l${item.locationHierarchyLevel}Value`
      }]
    });
  });
  nodeMetadataList.forEach(node => {
    if (node.locationHierarchyNodeLevel === 2) {
      nodeMetadataColDef.push({
        headerName: node.locationHierarchyNodeName,
        children: [{
          headerName: `Node${node.locationHierarchyNodeLevel}`,
          field: `node${node.locationHierarchyNodeLevel}`
        }]
      });
    }
  });

  const colDefs: any = [
    ...hierarchyMetadataColDef,
    ...nodeMetadataColDef,
    {
      headerName: 'LocationStatus',
      field: 'active',
      cellRenderer: statusRenderer,
    },
    {
      headerName: 'Launch Date',
      field: 'launchDate',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Closing Date',
      field: 'closingDate',
      cellRenderer: dateRenderer,
    },
  ];
  return colDefs;
}


