<div class="journey-modal-header">
  <h2>Journey</h2>
  <mat-icon class="cursor-pointer" (click)="dialogRef.close()">cancel</mat-icon>
</div>
<span class="material-symbols-outlined analytics-icon">monitoring</span>
<mat-tab-group class="journey-view-dialog">
  <mat-tab label="My Journey">
    <app-my-journey></app-my-journey>
  </mat-tab>
  <mat-tab label="Shared With Me">
    <app-journey-shared-with-me></app-journey-shared-with-me>
  </mat-tab>
  <mat-tab label="Activity Log">
    <journey-logs></journey-logs>
  </mat-tab>
</mat-tab-group>
