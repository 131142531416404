import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { LocationHierarchyManagerService } from 'src/services/setup-services/location-hierarchy-manager.service';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-location-hierarchy-manager-editor',
  templateUrl: './location-hierarchy-manager-editor.component.html',
  styleUrls: ['./location-hierarchy-manager-editor.component.scss']
})
export class LocationHierarchyManagerEditorComponent implements OnInit {

  isEdit: boolean = false;
  locationHierarchyManagerLevel: any = [];
  hierarchyValuesObject: any = {};

  savedHierarchyValueList: any = [];

  filteredLookupLocations: Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LocationHierarchyManagerEditorComponent>,
    public spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public toastr: NgxToasterService,
    private locationHierarchyManagerService: LocationHierarchyManagerService,
  ) { }

  ngOnInit(): void {
    this.initControlValues();
  }

  initControlValues() {
    this.isEdit = this.data.isEdit;
    this.locationHierarchyManagerLevel = this.data.itemHierarchyManagerLevel;
    this.savedHierarchyValueList = this.createHierarchyValueList(this.data.gridData);
    if (this.isEdit) {
      this.initEditModeList(this.data.rowData);
    }
  }

  initEditModeList(rowData) {
    const updatedList: any = [];
    this.locationHierarchyManagerLevel.forEach((location: any) => {
      updatedList.push({
        ...location,
        locationHierarchyId: rowData[`l${location.locationHierarchyLevel}`],
        locationParentId: rowData[`l${location.locationHierarchyLevel}ParentId`],
        locationHierarchyValue: rowData[`l${location.locationHierarchyLevel}Value`],
        locationHierarchyKey: rowData[`l${location.locationHierarchyLevel}Value`],
        locationHierarchyMetadataId: rowData[`l${location.locationHierarchyLevel}MetadataId`],
        locationHierarchyRelationId: rowData.locationHierarchyRelationId,
      });
    });
    this.locationHierarchyManagerLevel = updatedList;
  }

  getMaxHierachyLevel() {
    return _.get(_.maxBy(this.locationHierarchyManagerLevel, a => a.locationHierarchyLevel), 'locationHierarchyLevel', 2);
  }

  createHierarchyValueList(data) {
    const hierarchyValueList: any = [];
    const maxLevel = this.getMaxHierachyLevel();
    data.forEach(row => {
      for (let i = 1; i <= maxLevel; i++) {
        hierarchyValueList.push({
          locationHierarchyLevel: i,
          locationHierarchyId: row[`l${i}`] || null,
          locationParentId: row[`l${i}ParentId`] || null,
          locationHierarchyValue: row[`l${i}Value`] || null,
          locationHierarchyKey: row[`l${i}Value`] || null,
          locationHierarchyMetadataId: row[`l${i}MetadataId`] || null,
          locationHierarchyRelationId: row.locationHierarchyRelationId || null,
        });
      }
    });
    return hierarchyValueList;
  }

  displayLocationFn(item): string {
    return item && typeof item === 'object' ? (item.locationHierarchyValue ? `${item.locationHierarchyValue}` : '') : item;
  }

  _filterLocationLabels(event, location) {
    let value = _.toLower(event.target.value);
    let locationList: any = _.filter(this.savedHierarchyValueList, a => a.hierarchyLevel === location.locationHierarchyLevel);
    locationList = _.uniqBy(locationList, a => a.locationHierarchyValue);
    const filteredList = _.filter(locationList, a => { return _.toLower(a.locationHierarchyValue).indexOf(value) > -1; })
    this.filteredLookupLocations = of(filteredList);
    const validObject = _.find(locationList, a => _.toLower(a.locationHierarchyValue) === value);
    if (!this.isEdit) {
      location.locationHierarchyId = validObject ? validObject.locationHierarchyId : null;
    }
    location.locationHierarchyValue = event.target.value;
  }

  onLocationControlFocus(event, location) {
    const maxLevel = this.getMaxHierachyLevel();
    if (location.locationHierarchyLevel < maxLevel) {
      let locationList: any = _.filter(this.savedHierarchyValueList, a => a.locationHierarchyLevel === location.locationHierarchyLevel);
      locationList = _.uniqBy(locationList, a => a.locationHierarchyValue);
      this.filteredLookupLocations = of(locationList);
    } else {
      this.filteredLookupLocations = of(_.get(location));
    }
  }

  locationValueChange(selectedItem, control) {
    if (control.locationHierarchyId != selectedItem.locationHierarchyId) {
      this.autoPopulateHierarchyValues(selectedItem);
    }
    control.locationHierarchyId = selectedItem.locationHierarchyId;
    control.locationHierarchyMetadataId = selectedItem.locationHierarchyMetadataId;
    control.locationHierarchyValue = selectedItem.locationHierarchyValue;
  }

  autoPopulateHierarchyValues(selectedItem) {
    const selectedRowList = _.orderBy(
      _.filter(this.savedHierarchyValueList, a => a.locationHierarchyRelationId === selectedItem.locationHierarchyRelationId),
      ['locationHierarchyLevel'], ['asc']);
    this.locationHierarchyManagerLevel.forEach(location => {
      if (location.locationHierarchyLevel < selectedItem.locationHierarchyLevel) {
        const selectedItem = _.find(selectedRowList, a => a.locationHierarchyLevel === location.locationHierarchyLevel);
        if (selectedItem) {
          location.locationHierarchyId = selectedItem.locationHierarchyId;
          location.locationHierarchyMetadataId = selectedItem.locationHierarchyMetadataId;
          location.locationHierarchyValue = selectedItem.locationHierarchyValue;
        }
      } else if (location.locationHierarchyLevel > selectedItem.locationHierarchyLevel) {
        location.locationHierarchyId = null;
        location.locationHierarchyValue = '';
      }
    });
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  isAllHierarcyValueAvailable(hierarchyValuesList) {
    const valuesListOnly = _.map(hierarchyValuesList, a => a.locationHierarchyValue);
    return valuesListOnly.length === _.compact(valuesListOnly).length;
  }

  isLastNodeValuesValid(hierarchyValuesList, savedHierarchyList) {
    const maxLevel = this.getMaxHierachyLevel();
    const lastNodeValue = _.get(_.find(hierarchyValuesList, a => a.locationHierarchyLevel === maxLevel), 'locationHierarchyValue', '');
    let isInSavedList: any = [];
    if (!this.isEdit) {
      isInSavedList = _.filter(savedHierarchyList,
        a => a.locationHierarchyLevel === maxLevel
          && a.locationHierarchyValue === lastNodeValue);
    } else {
      const locationHierarchyRelationId = _.get(this.data, 'rowData.locationHierarchyRelationId', '');
      isInSavedList = _.filter(savedHierarchyList,
        a => a.locationHierarchyLevel === maxLevel
          && a.locationHierarchyValue === lastNodeValue
          && a.locationHierarchyRelationId !== locationHierarchyRelationId);
    }
    return isInSavedList.length ? false : true;

  }




  isAllCombinationUniq(hierarchyValuesList, savedHierarchyList) {
    let isValidCombination = true;
    const newHierarchyValues = _.map(_.orderBy(hierarchyValuesList, ['locationHierarchyLevel'], ['asc']), a => a.locationHierarchyValue);
    if (newHierarchyValues.length === _.uniq(newHierarchyValues).length) {
      const saveDataByRows = _.groupBy(savedHierarchyList, a => a.locationHierarchyRelationId);
      _.keys(saveDataByRows).every(key => {
        const saveItemValues = _.map(_.orderBy(saveDataByRows[key], ['locationHierarchyLevel'], ['asc']), a => a.locationHierarchyValue);
        if (_.isEqual(newHierarchyValues, saveItemValues)) {
          isValidCombination = false;
          return false;
        } else {
          return true;
        }
      });
    } else {
      isValidCombination = false;
    }
    return isValidCombination;
  }

  isValuesAtRightLevel(hierarchyValuesList, savedHierarchyList) {
    let isAllValueAtRightLevel: boolean = true;
    hierarchyValuesList.every(newItem => {
      savedHierarchyList.every(savedItem => {
        if (newItem.locationHierarchyLevel !== savedItem.locationHierarchyLevel
          && newItem.locationHierarchyValue === savedItem.locationHierarchyValue) {
          isAllValueAtRightLevel = false;
          return false;
        }
        return true;
      });
      return isAllValueAtRightLevel;
    });
    return isAllValueAtRightLevel;
  }


  createSubmitPayload() {
    if (!this.isEdit) {
      this.locationHierarchyManagerLevel.forEach(location => {
        location = {
          ...location,
          locationHierarchyId: null,
          locationParentName: '',
          locationParentId: null,
          locationHierarchyValue: location.locationHierarchyValue,
          locationHierarchyKey: location.locationHierarchyValue
        }
      });
    }
    return this.locationHierarchyManagerLevel;
  }


  onSubmit() {
    // Validation for NULL Values
    const isAllValuesAvailable = this.isAllHierarcyValueAvailable(this.locationHierarchyManagerLevel);
    // Validation for last node duplication
    const isLastNodeValuesValid = isAllValuesAvailable && this.isLastNodeValuesValid(this.locationHierarchyManagerLevel, this.savedHierarchyValueList);
    // Validation for uniq combination
    const isAllCombinationUniq = isLastNodeValuesValid && this.isAllCombinationUniq(this.locationHierarchyManagerLevel, this.savedHierarchyValueList);
    // Validation for value at right level
    const isValuesAtRightLevel = isAllCombinationUniq && this.isValuesAtRightLevel(this.locationHierarchyManagerLevel, this.savedHierarchyValueList);

    const hierarchyPayload = this.createSubmitPayload();

    if (
      isAllCombinationUniq
      && isAllValuesAvailable
      && isLastNodeValuesValid
      && isValuesAtRightLevel) {
      this.spinner.show();
      const payload = {
        locationHierarchy: hierarchyPayload,
      };
      this.locationHierarchyManagerService.AddUpdateLocationHierarchyList(payload).pipe(finalize(() => this.spinner.hide())).subscribe(res => {
        if (res) {
          const code = _.get(res, '0.messageCode', 400);
          const message = _.get(res, '0.messageText', 'Failed to create Location Hierarchy Manager.');
          if (code === 200) {
            this.dialogRef.close(true);
            this.toastr.success('Success', `${message}`);
          } else {
            res.forEach(element => {
              this.toastr.error('Error', `${element.messageText}`);
            });
          }
        }
      },
        err => {
          this.toastr.error('Error', 'System failed to Created Location Hierarchy Manager.');
        });
    } else {
      if (!isAllValuesAvailable || !isLastNodeValuesValid || !isAllCombinationUniq || !isValuesAtRightLevel) {
        this.toastr.error('Error', 'Please fill data in correct format.');
      }
    }

  }
}
