<div>
  <div id="item-manager">
    <div class="item-search-wrapper">
      <form action="" class="form-fields show-search">
        <div class="form-group">
          <mat-form-field floatLabel='never'>
            <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </form>
      <div class="item-search-btn">
        <img (click)="exportExcel()" src="assets/images/add-icon.svg" alt="">
        <button class="btn btn-primary" (click)="navigateToUser({})">Add User</button>
      </div>
    </div>

    <div class="all-show-grid items-wrapper-grid">
      <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders suppressRowClickSelection
        toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptions" [headerHeight]="headerHeight"
        [rowHeight]="rowHeight" [columnDefs]="columnDefs" [rowData]="userList" (gridReady)="onGridReady($event)"
         [defaultColDef]="defaultColDef" #grid></ag-grid-angular>
    </div>


  </div>
