import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-save-version',
  templateUrl: './save-version.component.html',
  styleUrls: ['./save-version.component.scss']
})
export class SaveVersionComponent implements OnInit {

  name = '';

  excludeOutliers = false;
  excludeExceptions = false;
  excludeDuplicates = false;

  constructor(public dialogRef: MatDialogRef<SaveVersionComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.name = JSON.parse(JSON.stringify(data.name));
  }

  ngOnInit(): void {
  }

  onSaveClick() {
    // dunction to check for special characters :: if the string contains completely special characters
    const checkForSpecialCharacters = (headerName: string) => {
      const format = /[a-zA-Z0-9]/;
      return !format.test(headerName);
    }

    if (!checkForSpecialCharacters(this.name.trim())) {
      const obj = {
        name: this.name.trim(),
        excludeOutliers: this.excludeOutliers,
        excludeExceptions: this.excludeExceptions,
        excludeDuplicates: this.excludeDuplicates
      }
      this.dialogRef.close(obj);
    }
    else {
      this.toastrService.warning('Name cannot be special characters solely.');
    }
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}
