import { dateRenderer, dateTimeRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import * as _ from 'lodash';
import { tooltipcellRenderer } from "src/common/util/util";
import moment from "moment";

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  filterParams: {
    excelMode: 'windows',
  },
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};


export const getForecastHiddenColDefs = (componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: sharedDataService.getDataById(1),
      field: 'retailerName',
    },
    {
      headerName: sharedDataService.getDataById(4),
      field: 'itemDescription',
    },
    {
      headerName: 'Seasonality Key',
      field: 'seasonalityKey',
    },
    {
      headerName: 'Market Index Name',
      field: 'seasonalityName',
    }
  ];

  return colDefs;
}

export const getForecastManagerColDefs = (filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: 'Forecast Key',
      field: 'forecastKey',
      cellRenderer: 'ForecastManagerEditRenderComponent',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
      width: 130
    },
    {
      headerName: sharedDataService.getDataById(3),
      field: 'productGroup',
      width: 140
    },
    {
      headerName: 'From Date',
      field: 'forecastFromWeek',
      type: 'date',
      cellRenderer: dateRenderer,
      width: 120
    },
    {
      headerName: 'To Date',
      field: 'forecastFromWeekToTimePeriodDate',
      type: 'date',
      cellRenderer: dateRenderer,
      width: 115
    },
    {
      headerName: 'Time Period',
      field: 'forecastWeekPeriod',
      width: 135
    },
    {
      headerName: 'Forecast Method',
      field: 'forecastMethod',
      width: 155
    },
    {
      headerName: 'Forecast Status',
      field: 'status',
      cellRenderer: forecastStatusRenderer,
      width: 145
    },
    {
      headerName: 'Date Created',
      field: 'createdDate',
      type: 'datetime',
      cellRenderer: dateTimeRenderer,
      width: 175
    },
    {
      headerName: 'Last Updated',
      field: 'modifiedDate',
      type: 'datetime',
      cellRenderer: dateTimeRenderer,
      width: 190
    }

  ];

  return colDefs;
}

export const forecastStatusRenderer = (params) => {
  return `${params.value === 'Succeeded' ? 'Completed' : params.value}`;
}

export const skuGetter = (params) => {
  return `${params.data.SKUDescription ? params.data.SKU : ''}`;
}


export const getForecastSeasonalityWeeksColDefs = (weeksList: any = [], filterLookupModel: any = {}) => {
  const colDefs: any = [
    {
      headerName: 'Values',
      field: 'values',
      width: 130
    }
  ];
  weeksList.forEach(week => {
    colDefs.push({
      headerName: `W-${week.weekNumber.toString().slice(-2)}`,
      children: [{
        headerName: `${moment(week.weekStartDate).format('MM/DD/YYYY')}`,
        headerClass: 'week-label-header',
        field: `${week.columnField}`,
        width: 130,
        cellEditor: 'numericCellEditor',
        editable: (params) => {
          if (params.data.values === 'Adjusted') {
            return false;
          } else {
            return false;
          }
        },
      }]
    });
  });

  return colDefs;
}
