
const ACCOUNT_API = "https://eus-qa-play-as-accountapi.azurewebsites.net/api";
const CONFIG_API = "https://eus-qa-play-as-configapi.azurewebsites.net/api";
const FORECAST_API = "https://eus-qa-play-as-fcengineapi.azurewebsites.net/api";
const BBY_API = "APP_BBY_API";
const MSG_BASEURL = "undefined";
const MSG_API = "undefined/api";
const MSG_HUB_URL = "undefined/notify";
const BOT_URL = "https://directline.botframework.com/v3/directline";
const CLUSTER_API = "https://eus-qa-play-oneview-clustering.azurewebsites.net";
const PMS_API = "undefined"

export const environment = {
  agGridLicenseKey: "CompanyName=Algomus Inc,LicensedGroup=Algo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-024572,ExpiryDate=19_March_2023_[v2]_MTY3OTE4NDAwMDAwMA==d423d2ac6dc5fd55039df668279ac034",
  headerLogoURL: '/assets/images/algo-user-logo.png',
  loginLogoURL: '/assets/images/algo-user-logo.png',
  logoWidth: 45,
  logoWidthLogin: 100,
  logoWidthForget: 100,
  microsoftURL: "https://login.microsoftonline.com/dd70a646-992f-4f0e-a15e-d9c554e1f576/oauth2/v2.0/token",
  ClientId: 16,
  production: false,
  idleTime: 8,
  fusionChartsKey: "qqA3ydjA5C5B3A5F5G4B3E3A10B5E2B2E2ixrG1C3gcowg1B3I4F-7mC-16B5B2D3D1G1F4H4B1C8A4D2G5G3C1G2B1ippB1A4B4i1A33A18B14utcB4A2H4moA-31A7C8A3D-11geH2C2C5A9B3B5F3G4F1C2F1zllC7E2f1G4i1dB-22C6D6B5jyD3RF1B5wwf1C2A3E2E3G3C9A6E4E4C4G3B2H2H4B-21==",
  firebaseConfig: {
    apiKey: "FIREBASE_API_KEY",
    authDomain: "FIREBASE_AUTH_DOMAIN",
    databaseURL: "FIREBASE_DATABASE_URL",
    projectId: "FIREBASE_PROJECT_ID",
    storageBucket: "FIREBASE_STORAGE_BUCKET",
    messagingSenderId: "FIREBASE_SENDER_ID",
    appId: "FIREBASE_APP_ID",
    measurementId: "FIREBASE_MEASUREMENT_ID"
  },
  chuckUploadConfig: {
    noOfChunks: 20,
    minFileChuckSize: 1024 * 1024 * 10, //10MB
    blockSize: {
      size512KB: 1024 * 512,
      size4Mb: 1024 * 1024 * 4,
      size32Mb: 1024 * 1024 * 32,
      size10MB: 1024 * 1024 * 10
    }
  },
  AccessToken: `${CONFIG_API}/PowerBI/GetAccessToken`,
  BotSecretKey: "wElShXxec6w.Ox6TJCwvBgk4-H3Afs3lCgIzovdB-qYcG4lEoiUhdpM",
  mapApi: "APP_MAP_API_KEY",
  utcOffset: 180,
  AppTokenGenerate: `${BOT_URL}/tokens/generate`,
  AppJourney: `${CONFIG_API}/Journey`,
  AppConversations: `${BOT_URL}/conversations`,
  AppAccount: `${ACCOUNT_API}/Account`,
  AppClaim: `${ACCOUNT_API}/Claim`,
  AppClaimGroup: `${ACCOUNT_API}/ClaimGroup`,
  AppRole: `${ACCOUNT_API}/Role`,
  AppCalender: `${CONFIG_API}/Calender`,
  AppCategory: `${CONFIG_API}/Category`,
  AppChannel: `${CONFIG_API}/Channel`,
  AppClient: `${CONFIG_API}/Client`,
  AppDistributor: `${CONFIG_API}/Distributor`,
  AppForms: `${CONFIG_API}/Forms`,
  AppSource: `${CONFIG_API}/Sourcing`,
  AppGoal: `${CONFIG_API}/Goal`,
  AppGoalType: `${CONFIG_API}/GoalTypes`,
  AppGoalTenure: `${CONFIG_API}/GoalTenureTypes`,
  AppSourceMixForm: `${CONFIG_API}/SourceMixForm`,
  AppSourceMixRow: `${CONFIG_API}/SourceMixRow`,
  AppSourceMixHeader: `${CONFIG_API}/SourceMixHeader`,
  AppCategoryMixRow: `${CONFIG_API}/CategoryMixRow`,
  AppCategoryMixHeader: `${CONFIG_API}/CategoryMixHeader`,
  AppCategoryMixForm: `${CONFIG_API}/CategoryMixForm`,
  AppPowerBi: `${CONFIG_API}/PowerBi`,
  AppStoreMixRow: `${CONFIG_API}/NewFormRow`,
  AppStoreMixHeader: `${CONFIG_API}/NewFormHeader`,
  AppStoreMixForm: `${CONFIG_API}/NewForm`,
  AppSameStoreRow: `${CONFIG_API}/SameStoreRow`,
  AppSameStoreHeader: `${CONFIG_API}/SameStoreHeader`,
  AppSameStoreForm: `${CONFIG_API}/SameStoreForm`,
  AppCalenderActivities: `${CONFIG_API}/CalendarActivity`,
  AppEventTypes: `${CONFIG_API}/EventTypes`,
  AppActionItems: `${CONFIG_API}/ActionItems`,
  AppChannelSourceWise: `${CONFIG_API}/ChannelSourceWise`,
  AppMenu: `${CONFIG_API}/Menu`,
  AppAnnouncement: `${CONFIG_API}/Announcement`,
  AppNote: `${CONFIG_API}/Note`,
  AppAPBudgetType: "",
  AppPromotion: "",
  AppSKU: "",
  AppSKUPromotion: "Promotion",
  AppDecayCurve: "",
  AppItemMaster: "",
  AppBussinessAttribute: "",
  AppBussinessInformation: "",
  AppWorkgroup: "",
  AppLevel: "",
  AppUserLevel: "",
  UploadDoc: "",
  Anomoly: "",
  AnomolyFiles: "",
  SyncPowerBIReports: `${CONFIG_API}/PowerBI/GetReportList`,
  NotificationCommon: "",
  NotificationHub: "",
  ApplicationInsights: `${CONFIG_API}/ApplicationInsights`,
  AppDocument: "",
  AppCommonCode: `${CONFIG_API}/CommonCode`,
  AppSuggestAdmin: "",
  AppCurrency: `${CONFIG_API}/CurrencySetting`,
  AppCurrencyRate: `${CONFIG_API}/CurrencyRate`,
  AppBuyerSetting: `${CONFIG_API}/BuyerSetting`,
  AppFinanceSetting: `${CONFIG_API}/FinancialSetting`,
  AppProductSetting: `${CONFIG_API}/ProductSetting`,
  AppTeams: `${ACCOUNT_API}/Team`,
  AppMasterItem: `${BBY_API}/MasterItem`,
  AppChildItem: `${BBY_API}/ChildItem`,
  AppProductRating: `${BBY_API}/Rating`,
  AppProductClass: `${BBY_API}/Class`,
  AppProductSubClass: `${BBY_API}/SubClass`,
  AppProductAccount: `${BBY_API}/Account`,
  AppItemPromotion: `${BBY_API}/PromotionItem`,
  AppItemPromotionPlanner: `${BBY_API}/PromotionItemPlanner`,
  AppProductLookup: `${BBY_API}/Lookup`,
  AppFBForcast: `${CONFIG_API}/FBForcast`,
  AppFBSpreading: `${CONFIG_API}/Spreading`,
  AppItemManager: `${CONFIG_API}/ItemManager`,
  OrderList: `${CONFIG_API}/MSOrderList`,
  AppTeamScreenGroup: `${CONFIG_API}/TeamScreenGroup`,
  AppItemForcast: `${CONFIG_API}/ItemForecast`,
  AppAccountSettingMapping: `${CONFIG_API}/AccountSettingMapping`,
  AppProductSettingMapping: `${CONFIG_API}/ProductSettingMapping`,
  AppFixtureMapping: `${BBY_API}/Fixture`,
  FbForecastProfile: `${CONFIG_API}/FBMeasureProfile`,
  MsForecastProfile: `${CONFIG_API}/MSMeasureProfile`,
  PromotionPlanner: `${CONFIG_API}/PromotionPlanner`,
  ScreenPreference: `${ACCOUNT_API}/ScreenPreference`,
  ClusterAPI: `${CLUSTER_API}/cluster-api`,
  ClusterBaseUrl: `${CLUSTER_API}`,
  FileManagementURL: `${CLUSTER_API}/file-management-api`,
  messagingHubUrl: `${MSG_HUB_URL}`,
  messagingUrl: `${MSG_API}/ApplicationUser`,
  createChannelUrl: `${MSG_API}/Channel`,
  userInChannel: `${MSG_API}/UsersInChannel`,
  channelMessage: `${MSG_API}/ChannelMessage`,
  messagingChatHubUrl: `${MSG_BASEURL}/chathub`,
  messagingChannelHubUrl: `${MSG_BASEURL}/channelhub`,
  outlierManager: `${FORECAST_API}/OutlierManager`,
  MappingManager: `${FORECAST_API}/MappingManager`,
  maxPlannerSaveCount: 20,
  configurationUrl: `${CONFIG_API}`,
  PromoManager: `${FORECAST_API}/PromoManager`,
  SeasonalityManager: `${FORECAST_API}/SeasonalityManager`,
  ForecastManager: `${FORECAST_API}/ForecastManager`,
  ForecastMapping: `${FORECAST_API}/Lookup`,
  HierarchyManager: `${FORECAST_API}/HierarchyManager`,
  ItemHierarchyManager: `${FORECAST_API}/ItemHierarchyManager`,
  LocationHierarchyManager: `${FORECAST_API}/LocationHierarchyManager`,
  ItemManagerForecast: `${FORECAST_API}/ItemManagerForecast`,
  LocationManagerForecast: `${FORECAST_API}/LocationManagerForecast`,
  CombinationManager: `${FORECAST_API}/CombinationManager`,
  PMS: `${PMS_API}`,
};
