<div class="p-1">
  <div class="d-flex wrapper">
    <label class="btn_container" (click)="triggerOutput(false)">
      <input [disabled]="disabled" type="radio" class="yes" name="radio" value="false" [checked]="toggleValue==false">
      <div class="checkmark"><i class="ion-checkmark-round"></i></div>
    </label>
    <label class="btn_container" (click)="triggerOutput(null)">
      <input [disabled]="disabled" type="radio" class="neutral" name="radio" value="neutral" [checked]="toggleValue==null">
      <div class="checkmark"><i class="ion-record"></i></div>
    </label>
    <label class="btn_container" (click)="triggerOutput(true)">
      <input [disabled]="disabled" type="radio" class="no" name="radio" value="true" [checked]="toggleValue==true">
      <div class="checkmark "><i class="ion-close-round"></i></div>
    </label>
  </div>
</div>
