<!-- Main Content area -->
<div id="fb-forecast" class="container-fluid">
  <div class="fb-forecast-accordion item-wrapper-panel">
    <div class="item-wrapper">
      <form [formGroup]="registerForm" class="form-fields ">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="teamName">Team Name</label>
              <input type="text" id="teamName" formControlName="teamName" />
            </div>
            <div class="form-group">
              <label for="status">Status</label>
              <mat-slide-toggle formControlName="active">Active
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row no-gutters select-arrow">
              <div class="col-md-2">
                <label style="padding-top: 5px; font-size: 12px;" for="teamName">Add Users</label>
              </div>
              <div class="col-md-10">
                <div *ngFor="let user of (f.controls && f.controls.applicationUser.value || []) ; let i=index"
                  class="form-group">
                  <label>User Name</label>
                  <input [value]="user.firstName + ' ' + user.lastName" readonly>
                  <i class="icon-cross-slim" (click)="removeUser(user)"></i>
                </div>
                <div class="form-group">
                  <label>Add Member</label>
                  <mat-select formControlName="users" (selectionChange)="addUserToList()">
                    <mat-option *ngFor="let user of (usersList || []); let i = index" [value]="user">{{
                      user.firstName + ' ' + user.lastName }}</mat-option>
                  </mat-select>
                  <i class="icon-add"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="selectedTeam" class="row">
        <div class="col-md-12">
          <h2 class="sub">Team Permissions</h2>
          <div class="permission-table row">
            <table class="table col-md-6">
              <tr>
                <th></th>
                <th>View</th>
                <th>Edit</th>
                <th>Create</th>
              </tr>
              <tr *ngFor="let permission of selectedTeam.permissionList || []">
                <td>{{permission.screenGroupLabel}}</td>
                <td>
                  <mat-slide-toggle [(ngModel)]="permission.viewPermission" [ngModelOptions]="{standalone: true}">
                    {{permission.viewPermission ?
                    'Yes' : 'No'}}
                  </mat-slide-toggle>
                </td>
                <td>
                  <mat-slide-toggle [(ngModel)]="permission.editPermission" [ngModelOptions]="{standalone: true}">
                    {{permission.editPermission ?
                    'Yes' : 'No'}}
                  </mat-slide-toggle>
                </td>
                <td>
                  <mat-slide-toggle [(ngModel)]="permission.createPermission" [ngModelOptions]="{standalone: true}">
                    {{permission.createPermission
                    ? 'Yes' : 'No'}}
                  </mat-slide-toggle>
                </td>
              </tr>
            </table>
            <table *ngIf="selectedTeam.permissions && selectedTeam.permissions.length > 0" class="table col-md-6">
              <tr>
                <th></th>
                <th>View</th>
                <th>Edit</th>
                <th>Create</th>
              </tr>
              <tr *ngFor="let permission of selectedTeam.permissions || []">
                <td>{{permission.screenGroupLabel}}</td>
                <td>
                  <mat-slide-toggle [(ngModel)]="permission.viewPermission" [ngModelOptions]="{standalone: true}">
                    {{permission.viewPermission ?
                    'Yes' : 'No'}}
                  </mat-slide-toggle>
                </td>
                <td>
                  <mat-slide-toggle [(ngModel)]="permission.editPermission" [ngModelOptions]="{standalone: true}">
                    {{permission.editPermission ?
                    'Yes' : 'No'}}
                  </mat-slide-toggle>
                </td>
                <td>
                  <mat-slide-toggle [(ngModel)]="permission.createPermission" [ngModelOptions]="{standalone: true}">
                    {{permission.createPermission
                    ? 'Yes' : 'No'}}
                  </mat-slide-toggle>
                </td>
              </tr>
            </table>
          </div>
          <!-- <ng-container *ngFor="let setting of settingList; let idx = index;">
            <mat-accordion class="arcadian-panel">
              <mat-expansion-panel>
                <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
                  <mat-panel-title>
                    {{setting}}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="container-fluid">
                  <app-buyer *ngIf="setting === 'Buyer Settings'" [teamId]="id"></app-buyer>
                  <app-product-setting *ngIf="setting === 'Product Settings' && productList.length > 0"
                    [productList]="productList" [productFlatList]="productFlatList" [productSettings]="productSettings"
                    [teamId]="id">
                  </app-product-setting>
                  <app-team-currency *ngIf="setting === 'Currency Settings'" [teamId]="id"></app-team-currency>
                  <app-team-finance *ngIf="setting === 'Finance Settings'" [teamId]="id">
                  </app-team-finance>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container> -->
        </div>
      </div>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary"
          (click)="onSubmit(errorTemplate,errorTeamCodeTemplate)">{{isEdit
          ? 'Update' : 'Create'}}</button>

      </div>
    </div>
  </div>
</div>
<ng-template #errorTemplate>
  <h1 mat-dialog-title>Error</h1>
  <div mat-dialog-content>
    <p>The name of this team already exists. Please update the name.</p>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="dialogRef.close()">Ok</button>
  </div>
</ng-template>
<ng-template #errorTeamCodeTemplate>
  <h1 mat-dialog-title>Error</h1>
  <div mat-dialog-content>
    <p>This team code already exists. Please update the team code.</p>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="dialogRef.close()">Ok</button>
  </div>
</ng-template>
