import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SegmentationService } from 'src/services/segmentation.service';
import { columnVariables, SEGMENTATION_OUTLIER_ID } from '../../constants/columnVariables';

@Component({
  selector: 'app-segmentation-graphs',
  templateUrl: './segmentation-graphs.component.html',
  styleUrls: ['./segmentation-graphs.component.scss']
})
export class SegmentationGraphsComponent implements OnInit, AfterViewInit {

  @Input() isOutputTab = true;
  @Input() sampleData: any;
  @Input() resizeGraphSubject: Subject<boolean>;
  @Input() allSegmentsUpdated = new Subject();
  @Input() allSegments = [];

  columnVariables = JSON.parse(JSON.stringify(columnVariables));

  xAxis = this.columnVariables[2].id;
  yAxis = this.columnVariables[3].id;
  selectedSegments = [1];
  SEGMENTATION_OUTLIER_ID = SEGMENTATION_OUTLIER_ID;

  @ViewChild("bubbleChart1", { static: false }) bubbleChart1: ElementRef;
  @ViewChild("bubbleChart2", { static: false }) bubbleChart2: ElementRef;
  @ViewChild("bubbleChart3", { static: false }) bubbleChart3: ElementRef;
  @ViewChild("spiderChart", { static: false }) spiderChart: ElementRef;
  @ViewChild("barChart", { static: false }) barChart: ElementRef;
  @ViewChild("scatterChart", { static: false }) scatterChart: ElementRef;

  // Bubble charts
  trace1 = {
    x: [1],
    y: [10],
    mode: 'markers',
    marker: {
      color: ['rgb(93, 164, 214)'],
      size: [20]
    }
  };
  bubblePlot1 = {
    data: [this.trace1],
    layout: {
      title: '',
      showaxis: true,
      autosize: true,
      showlegend: true,
      xaxis: {
        showline: true,
        title: {
          text: '',
        }
      },
      yaxis: {
        showline: true,
        title: {
          text: '',
        }
      }
    }
  };
  bubblePlot2 = {} as any;
  bubblePlot3 = {} as any;

  // spider chart
  spiderPlotConfig = {
    data: [
      {
        r: [39, 28, 8, 7, 28, 39],
        theta: ['A', 'B', 'C', 'D', 'E', 'A'],
        type: 'scatterpolar',
        fill: 'toself',
        name: 'Group A'
      },
      {
        r: [5, 14, 30, 17, 25],
        theta: ['A', 'B', 'C', 'D', 'F'],
        type: 'scatterpolar',
        fill: 'toself',
        name: 'Group B'
      }
    ],
    layout: {
      autosize: true
    }
  };
  // bar chart
  barChartData = {
    x: [],
    y: [],
    type: 'bar'
  };
  barChartConfig = {
    data: [this.barChartData],
    layout: {
      title: 'Average Sales Amount By Each Segment',
      showaxis: true,
      autosize: true,
      showlegend: false,
      xaxis: {
        showline: true,
        title: {
          text: '',
        }
      },
      yaxis: {
        tickformat: '$',
        showline: true,
        title: {
          text: '',
        }
      }
    }
  };

  // Scatter chart
  scatterPlotData = [
    {
      x: [],
      y: [],
      mode: 'markers',
      type: 'scatter'
    }
  ];
  scatterChartConfig = {
    data: this.scatterPlotData,
    layout: {
      showaxis: true,
      autosize: true,
      showlegend: true,
      xaxis: {
        zeroline: false,
        showline: true,
        title: {
          text: 'Relationship Duration',
        }
      },
      yaxis: {
        zeroline: false,
        showline: true,
        title: {
          text: 'Retancy',
        }
      }
    }
  };
  constructor(private plotlyRef: PlotlyService, private segmentationService: SegmentationService) { }

  ngOnInit(): void {
    // for window resize handling (graph responsiveness)
    fromEvent(window, 'resize').pipe(
      debounceTime(250))
      .subscribe(() => {
        setTimeout(() => {
          this.refreshGraph(this.bubblePlot1, this.bubbleChart1);
          this.refreshGraph(this.bubblePlot2, this.bubbleChart2);
          this.refreshGraph(this.bubblePlot3, this.bubbleChart3);
          this.refreshGraph(this.barChartConfig, this.barChart);
          this.refreshGraph(this.spiderPlotConfig, this.spiderChart);
          this.isOutputTab && this.refreshGraph(this.scatterChartConfig, this.scatterChart);
        }, 10);
      });
      
      this.resizeGraphSubject
      .pipe(debounceTime(250))
      .subscribe(() => {
        this.refreshGraph(this.bubblePlot1, this.bubbleChart1);
        this.refreshGraph(this.bubblePlot2, this.bubbleChart2);
        this.refreshGraph(this.bubblePlot3, this.bubbleChart3);
        this.refreshGraph(this.barChartConfig, this.barChart);
        this.refreshGraph(this.spiderPlotConfig, this.spiderChart);
        this.isOutputTab && this.refreshGraph(this.scatterChartConfig, this.scatterChart);
      });

      this.allSegmentsUpdated
      .subscribe(() => {
        this.fetchBubblePlot();
        this.fetchSpiderPlotData();
        this.fetchbarChartData();
        this.isOutputTab && this.axisChanged();
      });
  }

  fetchBubblePlot() {
    this.fetchBubblePlotData('Frequency', `total_sales_per_${this.sampleData.groupby}`, 1);
    this.fetchBubblePlotData('Length', 'Recency', 2);
    this.fetchBubblePlotData(`items_per_transaction_${this.sampleData.groupby}`, 'Variety', 3);
  }

  ngAfterViewInit() {
    let obj = {
      id: 'dicount_percentage',
      label: 'Discount Percentage'
    };
    this.columnVariables.push(obj);
    obj = {
      id: 'total_sales_per_' + this.sampleData.groupby,
      label: 'Total Sales per ' + this.sampleData.groupby
    };
    this.columnVariables.push(obj);

    this.bubblePlot2 = JSON.parse(JSON.stringify(this.bubblePlot1));
    this.bubblePlot3 = JSON.parse(JSON.stringify(this.bubblePlot1));
    this.bubblePlot1.layout.title = 'Customer Value Matrix';
    this.bubblePlot2.layout.title = 'Customer Loyalty Matrix';
    this.bubblePlot3.layout.title = 'Customer Variety Matrix';
    this.bubblePlot1.layout.xaxis.title.text = 'Purchases per Half Year';
    this.bubblePlot1.layout.yaxis.title.text = 'Total Sales per Customer';
    this.bubblePlot2.layout.xaxis.title.text = 'Days Since Last Purchase';
    this.bubblePlot2.layout.yaxis.title.text = 'Relationship Duration';
    this.bubblePlot3.layout.xaxis.title.text = 'Items per Transaction';
    this.bubblePlot3.layout.yaxis.title.text = 'Breadth of Items Purchased';
    setTimeout(() => {
      this.plotGraph(this.bubblePlot1, this.bubbleChart1);
      this.plotGraph(this.bubblePlot2, this.bubbleChart2);
      this.plotGraph(this.bubblePlot3, this.bubbleChart3);
      this.plotGraph(this.barChartConfig, this.barChart);
      this.plotGraph(this.spiderPlotConfig, this.spiderChart);
      this.isOutputTab && this.plotGraph(this.scatterChartConfig, this.scatterChart);
    }, 10);
    this.fetchBubblePlot();
    this.fetchSpiderPlotData();
    this.fetchbarChartData();
    this.isOutputTab && this.axisChanged();
  }

  fetchbarChartData() {
    this.segmentationService.fetchbarChartData(this.sampleData.userId, this.sampleData.fileName, this.sampleData.versionNo, this.sampleData.uu_id || '0', this.sampleData.groupby)
    .subscribe((res: any) => {
      this.barChartData = {
        x: [],
        y: [],
        type: 'bar'
      };
      if (res.payload[`total_sales_per_${this.sampleData.groupby}`]) {
        res.payload[`total_sales_per_${this.sampleData.groupby}`].forEach(e => {
          if (e._id != SEGMENTATION_OUTLIER_ID) {
            this.barChartData.y.push(e.avg);
            this.allSegments.forEach(element => {
              if(Number(element.uu_id) == Number(e._id)) {
                this.barChartData.x.push(element.name);
              }
            });
          }
        });
      }
      this.barChartConfig.data = [this.barChartData];
      this.refreshGraph(this.barChartConfig, this.barChart);
    },
    err => {
    });
  }

  axisChanged() {
    this.columnVariables.forEach(element => {
      if (element.id == this.xAxis) this.scatterChartConfig.layout.xaxis.title.text = element.label;
      if (element.id == this.yAxis) this.scatterChartConfig.layout.yaxis.title.text = element.label;
    });
    this.fetchScatterPlotData();
  }

  fetchScatterPlotData() {
    this.segmentationService.fetchScatterPlotData(this.sampleData.userId, this.sampleData.fileName, this.sampleData.versionNo, this.sampleData.uu_id || '0', this.xAxis, this.yAxis, this.selectedSegments)
      .subscribe((res: any) => {
        this.scatterPlotData = [];
        if(res.payload) {
          const keys = Object.keys(res.payload) as any;
          const values = Object.values(res.payload) as any;
          keys.forEach((k, index) => {
      
            const obj = {
              x: [],
              y: [],
              mode: 'markers',
              type: 'scatter',
              name: k
            };

            values[index].forEach(v => {
              obj.x.push(v[this.xAxis]);
              obj.y.push(v[this.yAxis]);
            });

            this.scatterPlotData .push(obj);
            
          });
        }
        this.scatterChartConfig.data = this.scatterPlotData;
        this.refreshGraph(this.scatterChartConfig, this.scatterChart);
      },
        err => {
        });
  }

  fetchBubblePlotData(variableOne, variableTwo, plotIndex: number) {
    this.segmentationService.fetchBubblePlotData(this.sampleData.userId, this.sampleData.fileName, this.sampleData.versionNo, this.sampleData.uu_id || '0', this.sampleData.groupby, variableOne, variableTwo)
      .subscribe((res: any) => {
        const plotData = [];
        const colors = ['rgb(93, 164, 214)', 'rgb(255, 144, 14)', 'rgb(44, 160, 101)', 'rgb(255, 65, 54)'];
        let markerSize = [];
        res.payload.forEach((element, index) => {
          if(element._id != SEGMENTATION_OUTLIER_ID) {
            markerSize.push(element[this.sampleData.groupby]);
          }
        });
        markerSize = [...new Set(markerSize)];
        markerSize.sort();
        res.payload.forEach((element, index) => {
          if(element._id != SEGMENTATION_OUTLIER_ID) {
            let name = '';
            this.allSegments.forEach(s => {
              if (s.uu_id == element._id) name = s.name;
            });
            const trace = JSON.parse(JSON.stringify(this.trace1));
            trace.x[0] = element[variableOne];
            trace.y[0] = element[variableTwo];
            trace.marker.color[0] = colors[Math.floor(Math.random()*colors.length)];
            trace.marker.size[0] = (markerSize.indexOf(element[this.sampleData.groupby]) + 1) * 10;
            trace.name = name;
            plotData.push(trace);
          }
        });
        
        if (plotIndex == 1) this.bubblePlot1.data = plotData;
        if (plotIndex == 2) this.bubblePlot2.data = plotData;
        if (plotIndex == 3) this.bubblePlot3.data = plotData;
        this.refreshGraph(this.bubblePlot1, this.bubbleChart1);
        this.refreshGraph(this.bubblePlot2, this.bubbleChart2);
        this.refreshGraph(this.bubblePlot3, this.bubbleChart3);
      },
        err => {
        });
  }

  fetchSpiderPlotData() {
    this.segmentationService.fetchSpiderPlotData(this.sampleData.userId, this.sampleData.fileName, this.sampleData.versionNo, this.sampleData.uu_id || '0')
    .subscribe((res: any) => {
      this.plotSpiderChart(res.payload);
    },
      err => {
      });
  }

  plotSpiderChart(data) {
    this.spiderPlotConfig.data = [];
    if (data) {
      const spiderChartData = [];
      for (let i=0; i<data[0].length; i++) {
        let segmentName = '';
        this.allSegments.forEach(element => {
          (element.uu_id == i+1) ? segmentName = element.name : null;
        });
        const r = data[0][i];
        segmentName && spiderChartData.push({
          r: r,
          theta: data[1],
          type: 'scatterpolar',
          fill: 'toself',
          name: segmentName
        });
      }
      this.spiderPlotConfig.data = spiderChartData;
    }
    this.refreshGraph(this.spiderPlotConfig, this.spiderChart);
  }

  plotGraph(data, div) {
    this.plotlyRef.newPlot(
      div.nativeElement,
      data.data,
      data.layout
    ).then((res) => {
      setTimeout(() => {
        this.refreshGraph(data, div);
      }, 10);
    });
  }

  refreshGraph(data, div) {
    this.plotlyRef.update(div.nativeElement, data.data, data.layout);
  }

}
