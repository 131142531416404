<h1 mat-dialog-title>Create GRN</h1>
<div class="grnTable" mat-dialog-content>
  <div class="row m-0">
    <label><b>PO Number:</b> {{data?.poId}}</label>
    <label class="mx-4"><b>Vendor Name:</b> {{data?.vendorName}}</label>
    <label><b>Location:</b> {{data?.locationName}}</label>
  </div>
  <div class="row m-0">
    <div class="col-md-4 d-flex align-items-center">
      <button mat-stroked-button color="accent" (click)="uploadFileBtn()">Choose File</button>
      <small class="mx-2">{{uploadedFile.name || 'No File Choosen'}}</small>
      <input class="hidden" type="file" id="{{'pms-grn-file-btn'+uniqueId}}" (change)="onFileSelect($event)">
    </div>
  </div>
  <table class="mt-3">
    <tr>
      <th>Item Name</th>
      <th>Specifications</th>
      <th>Quanity</th>
      <th>Receive Date*</th>
    </tr>
    <tr *ngFor="let item of prItems">
      <td>{{item?.itemName}}</td>
      <td>{{item?.itemSpecifications}}</td>
      <td>{{item?.quantity}}</td>
      <td>
        <mat-form-field appearance="fill" style="background-color: white;">
          <input type="date" placeholder="Enter date" matInput [(ngModel)]="item.itemReceivingDate">
        </mat-form-field>
      </td>
    </tr>
  </table>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="accent" (click)="onSaveClick()">Submit</button>
</div>
