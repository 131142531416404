<div class="row product-grid-filter-row">
    <div class="product-filter-field">
        <mat-form-field appearance="fill">
            <input matInput type="search" (keydown.enter)="emitFilterEvent()" placeholder="Promotion Name"
                [(ngModel)]="promotionItemPlannerName">
            <mat-icon class="search-action-icon" (click)="emitFilterEvent()" matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <div class="product-filter-field">
        <mat-form-field appearance="fill">
            <mat-select multiple placeholder="Fiscal Year" [(ngModel)]="fiscalYears"
                (selectionChange)="fiscalYearsChange()">
                <mat-option *ngFor="let year of fiscalYearList" [value]="year.yearId">{{year.yearName}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="product-filter-field">
        <mat-form-field appearance="fill">
            <mat-select multiple placeholder="Planning Month" [(ngModel)]="planningMonths"
                (selectionChange)="planningMonthChange()">
                <mat-option *ngFor="let month of planningMonthList" [value]="month.monthId">{{month.monthName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="product-filter-field">
        <mat-form-field appearance="fill">
            <mat-select multiple placeholder="Planning Week" [(ngModel)]="planningWeeks"
                (selectionChange)="emitFilterEvent()">
                <mat-option *ngFor="let week of planningWeekList" [value]="week.calendarId">{{week.weekName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="product-filter-field">
        <mat-form-field appearance="fill">
            <mat-select placeholder="Status" [(ngModel)]="isActive" (selectionChange)="emitFilterEvent()">
                <mat-option *ngFor="let status of statusList" [value]="status.statusId">{{status.statusName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="product-filter-field">
        <mat-form-field appearance="fill">
            <mat-select multiple placeholder="Account" [disabled]="disabledAccountSelection" [(ngModel)]="accounts"
                (selectionChange)="emitFilterEvent()">
                <mat-option *ngFor="let account of accountList" [value]="account.accountId">{{account.accountName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="product-filter-clear-btn">
        <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
    </div>
</div>