import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import {
  cacheBlockSize,
  defaultColDef,
  generateColumnDefsWithRowSelection,
  GRNAwaitingColumns,
  GRNcloumns,
  paginationPageSize,
} from '../ag-grid/gridOptions';

import { NgxSpinnerService } from 'ngx-spinner';
import { ProcurementService } from 'src/services/procurement.service';
import { PmsAddEditGrnComponent } from './pms-add-edit-grn/pms-add-edit-grn.component';
import jsPDF from 'jspdf';
import { ShowGRNSummaryRenderer } from '../ag-grid/show-grn-summary-renderer.component';
import { param } from 'jquery';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'pms-grn',
  templateUrl: './pms-grn.component.html',
  styleUrls: ['./pms-grn.component.scss'],
})
export class PmsGrnComponent implements OnInit {
  GRNAwaitingColumns = GRNAwaitingColumns;
  GRNcloumns = GRNcloumns;
  // Grid Variables
  selectedStatuses = 0;
  prItems = [];
  filtersForm: FormGroup;
  vendorDetail: any;
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  defaultColDef = defaultColDef;
  public gridColumnDefs = [];

  public gridOptions: any;
  private gridApi;
  frameworkComponents = {
    showGRNSummaryRenderer: ShowGRNSummaryRenderer,
  };
  dataSource = [];
  rowSelection = 'single';
  listingNgModel = false; // false: PO Listing, true: GRN Listing
  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private procurementService: ProcurementService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.initializeFilterForm();
    this.slideToggleChanged();
  }
  slideToggleChanged() {
    this.gridApi && this.gridApi.deselectAll();
    this.listingNgModel
      ? this.fetchGRNListing()
      : this.fetchGRNAwaitingListing();
  }
  initializeFilterForm() {
    this.filtersForm = this.formBuilder.group({
      prTitle: [''],
      statusDescription: ['0'],
    });
    this.filtersForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((res: any) => {
        this.fetchGRNAwaitingListing();
      });
  }
  fetchGRNAwaitingListing() {
    this.spinner.show();
    this.dataSource = [];
    this.gridColumnDefs = generateColumnDefsWithRowSelection(
      this.GRNAwaitingColumns
    );
    this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
    this.procurementService
      .getGRNAwaitingList(
        this.filtersForm.get('prTitle').value,
        this.filtersForm.get('statusDescription').value
      )
      .subscribe(
        (res: any) => {
          this.dataSource = res;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  onStatusSelection() {
    this.fetchGRNAwaitingListing();
  }
  fetchGRNListing() {
    this.spinner.show();
    this.dataSource = [];
    this.gridColumnDefs = generateColumnDefsWithRowSelection(this.GRNcloumns);
    this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
    this.procurementService.getGRNList().subscribe(
      (res: any) => {
        this.dataSource = res;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  listenSubscriptions() {
    this.procurementService.getPMSGridActionCellSubject().subscribe((data) => {
      if (data) {
        this.viewDetails(data);
      }
    });
  }
  viewDetails(value = null) {
    this.fetchVendorDetail(value.grnData.prId);
  }
  fetchVendorDetail(paramId = null) {
    this.spinner.show();
    this.procurementService.getVendorByPRId(paramId).subscribe((res: any) => {
      this.vendorDetail = res;
      this.fetchGRNItems(paramId);
    });
  }
  fetchGRNItems(paramId = null) {
    this.procurementService.getGRNItems(paramId).subscribe(
      (prItems: []) => {
        this.prItems = prItems;
        setTimeout(() => {
          this.spinner.hide();
          var doc = new jsPDF();
          var pdfHTML = document.getElementById('grnInvoice').innerHTML;
          doc.html(pdfHTML, {
            callback: function (doc) {
              // Save the PDF
              doc.output('dataurlnewwindow');
            },
            x: 15,
            y: 15,
            width: 170, //target width in the PDF document
            windowWidth: 650, //window width in CSS pixels
          });
        }, 1000);
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  actionButton() {
    if (this.listingNgModel) {
    } else {
      const confirmationDialogRef = this.dialog.open(PmsAddEditGrnComponent, {
        maxWidth: '1100px',
        width: '95vw',
        data: this.gridApi.getSelectedRows()[0],
      });
      confirmationDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.fetchGRNListing();
        }
      });
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  clearFilter() {
    this.initializeFilterForm();
    this.fetchGRNAwaitingListing();
  }
  cellClicked(event) {
    if (
      event &&
      event.value &&
      event.colDef &&
      event.colDef.field &&
      event.colDef.field == 'prTitle'
    ) {
      const seletedPRId = _.get(this.gridApi.getSelectedRows()[0], 'prId', 0);
      this.fetchVendorDetail(seletedPRId);
    }
  }
  get isRowSelected() {
    if (
      this.gridApi &&
      this.gridApi.getSelectedRows() &&
      this.gridApi.getSelectedRows().length > 0
    )
      return false;
    else return true;
  }
}
