import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SegmentationService } from 'src/services/segmentation.service';

@Component({
  selector: 'app-output-segmentaion',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss']
})
export class OutputComponent implements OnInit, AfterViewInit {
  @Output() saveRunOutput = new EventEmitter();
  @Input() sampleData: any;
  @Input() resizeGraphSubject: Subject<boolean>;
  allSegmentsUpdated = new Subject<boolean>();
  
  allSegments = [];

  constructor(private segmentationService: SegmentationService, private spinner: NgxSpinnerService, private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.fetchAllSegments(false);
  }

  fetchAllSegments(updated: boolean) {
    this.spinner.show();
    this.segmentationService.fetchAllSegments(this.sampleData.userId, this.sampleData.fileName, this.sampleData.versionNo, 'RUN_0')
    .subscribe((res: any) => {
      this.allSegments = res.payload;
      setTimeout(() => {
        updated && this.allSegmentsUpdated.next(true);
      }, 100);
      this.spinner.hide();
    });
  }

  saveRun() {
    this.spinner.show();
    const obj = {
      'file_name': this.sampleData.fileName,
      'version_no': this.sampleData.versionNo,
      'user_id': this.sampleData.userId
    };
    this.segmentationService.saveRun(obj)
    .subscribe((res: any) => {
      this.toastrService.success('Run saved successfully.');
      this.spinner.hide();
      this.saveRunOutput.emit(true);
    },
    err => {
      if (err.error && err.error.code && err.error.code=='400' && err.error.description) {
        this.toastrService.warning(err.error.description);
      } else {
        this.toastrService.error('Failed to save run.');
      }
      this.spinner.hide();
    });
  }

}
