import { SharedDataService } from 'src/services/shared-data.service';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { MappingService } from 'src/services/forecast-services/mapping-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { ClientPreferenceService } from 'src/services/client-services/client-preference.service';
import { MatDialog } from '@angular/material/dialog';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';

interface MappedSKU {
  mappedItemId?: number,
  retailer: string,
  retailerKey: string,
  brand?: string,
  productGroup?: string,
  productDescription?: string,
  sku?: string,
  likeness?: number,
  avgWeekSaleUnits?: number,
  factoredSales?: number
}

interface ValidMappedSKU {
  retailer: boolean,
  brand: boolean,
  productGroup: boolean,
  productDescription: boolean,
  sku: boolean,
}

@Component({
  selector: 'app-add-edit-mapping-forecast-item',
  templateUrl: './add-edit-mapping-forecast-item.component.html',
  styleUrls: ['./add-edit-mapping-forecast-item.component.css']
})
export class AddEditMappingForecastItemComponent implements AfterViewInit {

  @Input() data?: any;

  LEVEL_MAPPING = {
    RETAILER: 'Retailer',
    BRAND: 'Brand',
    PRODUCT_GROUP: 'ProductGroup',
    SKU: 'SKU'
  };

  dataSource: any = {
    chart: {
      caption: "Sale Units",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100",
      numVisiblePlot: "52",
      outCnvBaseFontSize: "10",

    },
    categories: [
      {
        category: []
      }
    ],
    dataset: [
      {
        seriesname: "Adjusted Unit Sales",
        color: "#ffc107s",
        data: []
      },
      {
        seriesname: "Original Unit Sales",
        color: "#0000ff",
        data: []
      },
      {
        seriesname: "Mapped Unit Sales",
        color: "#ff0000",
        data: []
      }
    ],
    trendlines: [
      {
        line: []
      }
    ]
  };
  chartConfig: Object = {
    type: 'scrollline2d',
    dataFormat: 'json',
  };

  forecastItemForm = new FormGroup({
    retailer: new FormControl('', Validators.required),
    retailerKey: new FormControl(''),
    brand: new FormControl('', Validators.required),
    productGroup: new FormControl('', Validators.required),
    productDescription: new FormControl('', Validators.required),
    sku: new FormControl('', Validators.required),
    effectiveStartDate: new FormControl(null, Validators.required),
    effectiveEndDate: new FormControl(null, Validators.required),
  });
  validForecastForm = {
    retailer: true,
    brand: true,
    productGroup: true,
    productDescription: true,
    sku: true
  };
  levelType: string = '';
  isSKUSelected: boolean = false;
  isEdit: boolean = false;
  selectedSKU: any;
  isForecastSKUSelected: boolean = false;
  isWeekOverlapping: boolean = false;
  lookupItemList: any = [];
  calenderList: any = [];
  filteredRetailerLookupItems: Observable<any[]>;
  filteredBrandLookupItems: Observable<any[]>;
  filteredProductGroupLookupItems: Observable<any[]>;
  filteredProductDescriptionLookupItems: Observable<any[]>;
  filteredLookupItems: Observable<any[]>;
  filteredMapRetailerLookupItems: Observable<any[]>;
  filteredMapBrandLookupItems: Observable<any[]>;
  filteredMapProductGroupLookupItems: Observable<any[]>;
  filteredMapProductDescriptionLookupItems: Observable<any[]>;
  filteredMapLookupItems: Observable<any[]>;
  mappingHistory: any = [];
  mappedSKUList: MappedSKU[] = [];
  weekSaleUnitChartData: any = [];
  validMappedSKUList: ValidMappedSKU[] = [];
  maxEffectiveWeekDate: Date = new Date();
  originalPosWeek: number = 0;
  mappedPosWeek: number = 0;

  clientWeekStartDay: number = 0;

  constructor(
    public mappingService: MappingService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    private cdr: ChangeDetectorRef,
    public clientPreferenceService: ClientPreferenceService,
    public _dataService: SharedDataService,
    public forecastManagerService: ForecastManagerService,
  ) {
  }

  ngAfterViewInit(): void {
    this.data && (this.data = JSON.parse(this.data));

    this.initiateLookupData();
    this.initateEditMode();
    this.addCustomValidators();
    this.loadClientPreference();
  }

  get retailer() { return this.forecastItemForm.get('retailer'); }
  get brand() { return this.forecastItemForm.get('brand'); }
  get productGroup() { return this.forecastItemForm.get('productGroup'); }
  get productDescription() { return this.forecastItemForm.get('productDescription'); }
  get sku() { return this.forecastItemForm.get('sku'); }
  get effectiveEndDate() { return this.forecastItemForm.get('effectiveEndDate'); }

  get retailerList(): any[] {
    return _.uniqBy(this.lookupItemList, a => a.retailerKey);
  }

  get averageWeeklySaleUnits(): number {
    return this.mappedSKUList.length ? (_.sumBy(this.mappedSKUList, a => a.avgWeekSaleUnits || 0) / this.mappedSKUList.length) : 0;
  }

  get averageLikeness(): number {
    return this.mappedSKUList.length ? (_.sumBy(this.mappedSKUList, a => a.likeness || 0) / this.mappedSKUList.length) : 0;
  }

  get averageFactoredSales(): number {
    let result = 0;
    this.mappedSKUList.forEach(mapItem => {
      result = result + ((mapItem.avgWeekSaleUnits && mapItem.likeness) ? (mapItem.avgWeekSaleUnits *
        mapItem.likeness / 100) : 0)

    });
    return result / this.mappedSKUList.length;
    //return this.averageWeeklySaleUnits * this.averageLikeness / 100;

  }

  loadClientPreference() {
    this.clientWeekStartDay = this.clientPreferenceService.getClientWeekStartDay();
  }

  weekDayFilter = (date) => {
    return date && (date.day() === this.clientWeekStartDay);
  }

  addCustomValidators() {
    setTimeout(() => {
      this.forecastItemForm.controls['effectiveEndDate'].setValidators([Validators.required, this.endWeekValidator]);
    }, 0);
  }

  endWeekValidator(control: FormControl) {
    let effectiveStartDate = control.parent.controls['effectiveStartDate'].value;
    const effectiveEndDate = control.value;
    if (effectiveStartDate) {
      effectiveStartDate = new Date(effectiveStartDate);
      if (effectiveEndDate < effectiveStartDate) {
        return {
          effectiveEndDate: 'Start Week should be less then End Week.'
        };
      }
      return null;
    } else {
      return null;
    }
  }

  dateInputChange(type, event) {
    this.checkOverlapWeek();
    if (this.forecastItemForm.valid) {
      this.getWeeklyMappedChartData();
    }
  }

  checkOverlapWeek(event = {}) {
    const sku = this.forecastItemForm.get('sku').value;
    const effectiveStartDate = this.forecastItemForm.get('effectiveStartDate').value;
    const effectiveEndDate = this.forecastItemForm.get('effectiveEndDate').value;
    if (sku && effectiveStartDate && effectiveEndDate) {
      const payload = Object.assign({}, this.forecastItemForm.value);
      this.mappingService.CheckOverlapWeek({
        ...payload,
        ...this.isEdit && { forecastSKUItemId: this.selectedSKU.forecastSKUItemId }
      }).subscribe(res => {
        if (res) {
          this.isWeekOverlapping = true;
        } else {
          this.isWeekOverlapping = false;
        }
      });
    }
  }

  initateEditMode() {
    if (this.data.isEdit) {
      this.isForecastSKUSelected = true;
      this.isEdit = this.data.isEdit;
      this.selectedSKU = this.data.selectedSKU;
      this.levelType = this.selectedSKU.levelType;
      this.setFormValues();
      this.getMappingSourceByMappingManagerId(this.selectedSKU.forecastSKUItemId);
      this.getMappingHitory(this.selectedSKU);
      this.cdr.detectChanges();
    } else if (this.data.isSKUSelected) {
      this.isForecastSKUSelected = true;
      this.isSKUSelected = this.data.isSKUSelected;
      this.selectedSKU = this.data.selectedSKU;
      this.setFormValues();
      this.getMappingHitory(this.selectedSKU);
      this.getWeeklyMappedChartData();
    }
  }

  setFormValues() {
    this.forecastItemForm.get('retailer').setValue(this.selectedSKU.retailer);
    this.forecastItemForm.get('retailerKey').setValue(this.selectedSKU.retailerKey);
    this.forecastItemForm.get('brand').setValue(this.selectedSKU.brand);
    this.forecastItemForm.get('productGroup').setValue(this.selectedSKU.productGroup);
    this.forecastItemForm.get('productDescription').setValue(this.selectedSKU.productDescription);
    this.forecastItemForm.get('sku').setValue(this.selectedSKU.sku);
    this.forecastItemForm.get('effectiveStartDate').setValue(this.isEdit ? this.selectedSKU.effectiveStartDate : null);
    this.forecastItemForm.get('effectiveEndDate').setValue(this.isEdit ? this.selectedSKU.effectiveEndDate : null);
  }

  initiateLookupData() {
    this.getLookupItemsList();
    this.getLatestCommitedWeek();
  }

  getLatestCommitedWeek() {
    this.forecastManagerService.GetLatestCommitedWeek().subscribe((response: any) => {
      this.maxEffectiveWeekDate = response.committedDate;
    });
  }

  getMappingSourceByMappingManagerId(mappingManagerId) {
    this.spinner.show();
    this.mappingService.MappingSourceByMappingManagerId(mappingManagerId).subscribe((mapItems: any) => {
      this.mappedSKUList = mapItems;
      this.initValidMappedSKUObject(mapItems.length);
      this.getWeeklyMappedChartData();
      this.spinner.hide();
    });
  }

  getWeeklyMappedChartData() {
    this.spinner.show();
    const payload = {
      ...this.forecastItemForm.value,
      levelType: this.levelType,
      mappedItems: this.mappedSKUList,
      ...this.isEdit && { forecastSKUItemId: this.selectedSKU.forecastSKUItemId }
    };
    this.mappingService.GetWeeklyMappingSaleUnit(payload).subscribe((response: any) => {
      if (response) {
        this.weekSaleUnitChartData = response.saleUnits;
        this.createChartDataSource(response.baseline);
      }
      this.spinner.hide();
    });
  }

  initValidMappedSKUObject(length) {
    for (let i = 0; i < length; i++) {
      this.pushValidMappedSKUObject();
    }
  }

  pushValidMappedSKUObject() {
    this.validMappedSKUList.push({
      retailer: true,
      brand: true,
      productGroup: true,
      productDescription: true,
      sku: true
    });
  }

  createChartDataSource(baselineValue = 0) {
    for (let i = 0; i < this.weekSaleUnitChartData.length; i++) {
      if (this.weekSaleUnitChartData[i].isMapped) {
        if (i !== 0 && i !== (this.weekSaleUnitChartData.length - 1)) {
          this.weekSaleUnitChartData[i].isNextWeekOrginal = !this.weekSaleUnitChartData[i + 1].isMapped;
          this.weekSaleUnitChartData[i].isPrvWeekOriginal = !this.weekSaleUnitChartData[i - 1].isMapped;
        }
      }
    }
    const categories = this.weekSaleUnitChartData.map(a => {
      const weekNumber = a.weekNumber.toString().slice(-2);
      return {
        labelFontColor: a.isMapped ? '#ff0000' : 'inherit',
        label: `${moment(a.weekStartDate).format('MM/DD/YYYY')}-W${weekNumber}`
      };
    });
    const mappedData = [];
    const originalData = [];
    const adjustedData = [];
    this.originalPosWeek = _.filter(this.weekSaleUnitChartData, a => !a.isMapped && a.sellThroughAvg).length;
    this.mappedPosWeek = _.filter(this.weekSaleUnitChartData, a => a.isMapped && a.sellThroughAvg).length;
    this.weekSaleUnitChartData.forEach(a => {
      if (a.adjustedSellThroughAvg) {
        adjustedData.push({
          value: a.adjustedSellThroughAvg
        });
      } else {
        adjustedData.push({
          value: null,
          toolText: "$seriesName, $label, -"
        });
      }

      if (a.sellThroughAvg === 0) {
        originalData.push({
          value: null,
          toolText: "$seriesName, $label, -"
        });
        mappedData.push({
          value: null,
          toolText: "$seriesName, $label, -"
        });
      } else if (a.isMapped) {
        mappedData.push({ value: a.sellThroughAvg });

        if (a.isNextWeekOrginal || a.isPrvWeekOriginal) {
          originalData.push({
            value: a.sellThroughAvg,
            toolText: "$seriesName, $label, -"
          });
        } else {
          originalData.push({
            value: null,
            toolText: "$seriesName, $label, -"
          });
        }


      } else {
        originalData.push({ value: a.sellThroughAvg });
        mappedData.push({
          value: null,
          toolText: "$seriesName, $label, -"
        });
      }
    });
    this.dataSource.categories[0].category = categories;
    this.dataSource.dataset[0].data = adjustedData;
    this.dataSource.dataset[1].data = originalData;
    this.dataSource.dataset[2].data = mappedData;
    this.dataSource.trendlines[0].line[0] = {
      startvalue: baselineValue,
      color: "#1aaf5d",
      displayvalue: "Average{br}weekly{br}sale units",
      valueOnRight: "1",
      thickness: "2"
    };
  }

  getMappingHitory(forecastItem) {
    this.spinner.show();
    this.mappingService.GetMappingHitory(forecastItem).subscribe(mappingHistory => {
      this.mappingHistory = mappingHistory;
      this.spinner.hide();
    });
  }

  getLookupItemsList() {
    this.spinner.show();
    this.mappingService.GetLookupItemsList().subscribe((itemList: any) => {
      this.lookupItemList = itemList;
      this.filteredLookupItems = of(itemList);
      this.spinner.hide();
    });
  }

  retailerChange(item) {
    this.forecastItemForm.get('retailer').setValue(item.retailer);
    this.forecastItemForm.get('retailerKey').setValue(item.retailerKey);
    this.forecastItemForm.get('brand').setValue('');
    this.forecastItemForm.get('productGroup').setValue('');
    this.forecastItemForm.get('productDescription').setValue('');
    this.forecastItemForm.get('sku').setValue('');
    this.isForecastSKUSelected = false;
    this.validForecastForm.retailer = this.isValidRetailerValue(item.retailer);
  }

  brandChange(item) {
    this.forecastItemForm.get('brand').setValue(item.brand);
    this.forecastItemForm.get('productGroup').setValue('');
    this.forecastItemForm.get('productDescription').setValue('');
    this.forecastItemForm.get('sku').setValue('');
    this.isForecastSKUSelected = false;
    this.validForecastForm.brand = this.isValidBrandValue(item.brand);
  }

  productGroupChange(item) {
    this.forecastItemForm.get('productGroup').setValue(item.productGroup);
    this.forecastItemForm.get('productDescription').setValue('');
    this.forecastItemForm.get('sku').setValue('');
    this.isForecastSKUSelected = false;
    this.validForecastForm.productGroup = this.isValidProductGroupValue(item.productGroup);
  }

  productDescriptionChange(item) {
    this.forecastItemForm.get('productDescription').setValue(item.productDescription);
    this.forecastItemForm.get('sku').setValue(item.sku);
    this.isForecastSKUSelected = true;
    this.getMappingHitory(this.forecastItemForm.value);
    this.clearEffectivePeriod();
    this.validForecastForm.productDescription = this.isValidProductDescriptionValue(item.productDescription);
    this.validForecastForm.sku = this.isValidProductDescriptionValue(item.productDescription);
  }

  deleteForecastItem(item, index) {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Delete Mappings`,
        confirmationMessage: `Are you sure you want to delete this mapping?`
      }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.mappingService.DeleteForecastItem({ forecastItem: [item] }).subscribe(res => {
          if (res) {
            this.mappingHistory.splice(index, 1);
            this.spinner.hide();
            this.toastr.success('Success', `Mappings deleted successfully.`);
            
          };
        }, err => {
          this.toastr.error('Error', `System failed to delete mappings.`);
          this.spinner.hide();
        });
      }
    });
  }

  onRetailerFocus(event) {
    const itemsList = _.uniqBy(this.lookupItemList, a => a.retailerKey);
    this.filteredRetailerLookupItems = of(itemsList);
  }

  onBrandFocus(event) {
    const retailerKey = this.forecastItemForm.get('retailerKey').value;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey), a => a.brand);
    this.filteredBrandLookupItems = of(itemsList);
  }

  onProductGroupFocus(event) {
    const retailerKey = this.forecastItemForm.get('retailerKey').value;
    const brand = this.forecastItemForm.get('brand').value;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey && b.brand === brand), a => a.productGroup);
    this.filteredProductGroupLookupItems = of(itemsList);
  }

  onProductDescriptionFocus(event) {
    const retailerKey = this.forecastItemForm.get('retailerKey').value;
    const brand = this.forecastItemForm.get('brand').value;
    const productGroup = this.forecastItemForm.get('productGroup').value;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey && b.brand === brand && b.productGroup === productGroup), a => a.sku);
    this.filteredProductDescriptionLookupItems = of(itemsList);
  }

  onSKUFocus(event) {
    let value = event.target.value;
    const retailerKey = this.forecastItemForm.get('retailerKey').value;
    const brand = this.forecastItemForm.get('brand').value;
    const productGroup = this.forecastItemForm.get('productGroup').value;
    if (value) {
      this._filterLookupItems(event);
    } else {
      const itemsList = this.lookupItemList.filter(item => {
        if (productGroup) {
          return item.retailerKey === retailerKey
            && item.brand === brand
            && item.productGroup === productGroup;
        } else if (brand) {
          return item.retailerKey === retailerKey && item.brand === brand;
        } else if (retailerKey) {
          return item.retailerKey === retailerKey;
        } else {
          return true;
        }
      });
      this.filteredLookupItems = of(itemsList);
    }
  }

  isValidRetailerValue(value): boolean {
    const isValidObject = _.find(this.lookupItemList, item => item.retailer === value);
    return isValidObject ? true : false;
  }

  isValidBrandValue(value): boolean {
    const isValidObject = _.find(this.lookupItemList, item => item.brand === value);
    return isValidObject ? true : false;
  }


  isValidProductGroupValue(value): boolean {
    const isValidObject = _.find(this.lookupItemList, item => item.productGroup === value);
    return isValidObject ? true : false;
  }


  isValidProductDescriptionValue(value): boolean {
    const isValidObject = _.find(this.lookupItemList, item => item.productDescription === value);
    return isValidObject ? true : false;
  }


  isValidSKUValue(value): boolean {
    const isValidObject = _.find(this.lookupItemList, item => item.sku === value);
    return isValidObject ? true : false;
  }

  _filterRetailerLookupItems(event) {
    let value = _.toLower(event.target.value);
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => { return _.toLower(item.retailer).indexOf(value) > -1; }),
      a => a.retailerKey);
    this.filteredRetailerLookupItems = of(itemsList);
    this.validForecastForm.retailer = this.isValidRetailerValue(value);
  }

  _filterBrandLookupItems(event) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.forecastItemForm.get('retailerKey').value;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey && _.toLower(item.brand).indexOf(value) > -1;
      }),
      a => a.brand);
    this.filteredBrandLookupItems = of(itemsList);
    this.validForecastForm.brand = this.isValidBrandValue(value);
  }

  _filterProductGroupLookupItems(event) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.forecastItemForm.get('retailerKey').value;
    const brand = this.forecastItemForm.get('brand').value;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey &&
          item.brand === brand && _.toLower(item.productGroup).indexOf(value) > -1;
      }),
      a => a.productGroup);
    this.filteredProductGroupLookupItems = of(itemsList);
    this.validForecastForm.productGroup = this.isValidProductGroupValue(value);
  }

  _filterProductDescriptionLookupItems(event) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.forecastItemForm.get('retailerKey').value;
    const brand = this.forecastItemForm.get('brand').value;
    const productGroup = this.forecastItemForm.get('productGroup').value;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey &&
          item.brand === brand &&
          item.productGroup === productGroup &&
          _.toLower(item.productDescription).indexOf(value) > -1;
      }),
      a => a.productDescription);
    this.filteredProductDescriptionLookupItems = of(itemsList);
    this.validForecastForm.productDescription = this.isValidProductDescriptionValue(value);
  }

  _filterLookupItems(event) {
    let value = event.target.value;
    const retailerKey = this.forecastItemForm.get('retailerKey').value;
    const brand = this.forecastItemForm.get('brand').value;
    const productGroup = this.forecastItemForm.get('productGroup').value;
    const itemsList = this.lookupItemList.filter(item => {
      if (productGroup) {
        return item.retailerKey === retailerKey
          && item.brand === brand
          && item.productGroup === productGroup
          && item.sku.indexOf(value) > -1;
      } else if (brand) {
        return item.retailerKey === retailerKey && item.brand === brand && item.sku.indexOf(value) > -1;
      } else if (retailerKey) {
        return item.retailerKey === retailerKey && item.sku.indexOf(value) > -1;
      } else {
        return item.sku.indexOf(value) > -1;
      }
    });
    this.filteredLookupItems = of(itemsList);
    this.validForecastForm.sku = this.isValidSKUValue(value);
  }

  displayRetailerFn(item): string {
    return item && typeof item === 'object' ? (item.retailer ? `${item.retailer}` : '') : item;
  }

  displayBrandFn(item): string {
    return item && typeof item === 'object' ? (item.brand ? `${item.brand}` : '') : item;
  }

  displayProductGroupFn(item): string {
    return item && typeof item === 'object' ? (item.productGroup ? `${item.productGroup}` : '') : item;
  }

  displayProductDescriptionFn(item): string {
    return item && typeof item === 'object' ? (item.productDescription ? `${item.productDescription}` : '') : item;
  }

  displaySKUFn(item): string {
    return item && typeof item === 'object' ? (item.sku ? `${item.sku}` : '') : item;
  }

  selectSKUItem = (item) => {
    this.forecastItemForm.get('retailerKey').setValue(item.retailerKey);
    this.forecastItemForm.get('retailer').setValue(item.retailer);
    this.forecastItemForm.get('brand').setValue(item.brand);
    this.forecastItemForm.get('productGroup').setValue(item.productGroup);
    this.forecastItemForm.get('productDescription').setValue(item.productDescription);
    this.forecastItemForm.get('sku').setValue(item.sku);
    this.isForecastSKUSelected = true;
    this.getMappingHitory(this.forecastItemForm.value);
    this.clearEffectivePeriod();
    this.validForecastForm.retailer = this.isValidSKUValue(item.sku);;
    this.validForecastForm.brand = this.isValidSKUValue(item.sku);;
    this.validForecastForm.productGroup = this.isValidSKUValue(item.sku);;
    this.validForecastForm.productDescription = this.isValidSKUValue(item.sku);;
    this.validForecastForm.sku = this.isValidSKUValue(item.sku);
    this.getWeeklyMappedChartData();
  }

  clearEffectivePeriod() {
    this.forecastItemForm.get('effectiveStartDate').setValue(null);
    this.forecastItemForm.get('effectiveEndDate').setValue(null);
  }

  selectionChange(event) {
    this.cdr.detectChanges();
  }

  isReadonlyControl(index, controlLevel): boolean {
    let isReadonly: boolean = false;
    switch (controlLevel) {
      case this.LEVEL_MAPPING.RETAILER: {
        isReadonly = false;
        break;
      }
      case this.LEVEL_MAPPING.BRAND: {
        isReadonly = this.mappedSKUList[index].retailerKey ? false : true;
        if (index != 0
          && !isReadonly
          && this.levelType === this.LEVEL_MAPPING.RETAILER
        ) {
          isReadonly = true;
        }
        break;
      }
      case this.LEVEL_MAPPING.PRODUCT_GROUP: {
        isReadonly = this.mappedSKUList[index].brand ? false : true;
        if (index != 0
          && !isReadonly
          && this.levelType === this.LEVEL_MAPPING.BRAND
        ) {
          isReadonly = true;
        }
        break;
      }
      case this.LEVEL_MAPPING.SKU: {
        isReadonly = this.mappedSKUList[index].productGroup ? false : true;
        if (index != 0
          && !isReadonly
          && this.levelType === this.LEVEL_MAPPING.PRODUCT_GROUP
        ) {
          isReadonly = true;
        }
        break;
      }
    }
    return isReadonly;
  }

  onMapRetailerFocus(event, index) {
    const itemsList = _.uniqBy(this.lookupItemList, a => a.retailerKey);
    this.filteredMapRetailerLookupItems = of(itemsList);
  }

  onMapBrandFocus(event, index) {
    const retailerKey = this.mappedSKUList[index].retailerKey;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey), a => a.brand);
    this.filteredMapBrandLookupItems = of(itemsList);
  }

  onMapProductGroupFocus(event, index) {
    const retailerKey = this.mappedSKUList[index].retailerKey;
    const brand = this.mappedSKUList[index].brand;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey && b.brand === brand), a => a.productGroup);
    this.filteredMapProductGroupLookupItems = of(itemsList);
  }

  onMapProductDescriptionFocus(event, index) {
    const retailerKey = this.mappedSKUList[index].retailerKey;
    const brand = this.mappedSKUList[index].brand;
    const productGroup = this.mappedSKUList[index].productGroup;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey && b.brand === brand && b.productGroup === productGroup), a => a.sku);
    this.filteredMapProductDescriptionLookupItems = of(itemsList);
  }

  onMapSKUFocus(event, index) {
    let value = event.target.value;
    const retailerKey = this.mappedSKUList[index].retailerKey;
    const brand = this.mappedSKUList[index].brand;
    const productGroup = this.mappedSKUList[index].productGroup;
    if (value) {
      this._filterMapLookupItems(event, index);
    } else {
      const itemsList = this.lookupItemList.filter(item => {
        if (productGroup) {
          return item.retailerKey === retailerKey
            && item.brand === brand
            && item.productGroup === productGroup;
        } else if (brand) {
          return item.retailerKey === retailerKey && item.brand === brand;
        } else if (retailerKey) {
          return item.retailerKey === retailerKey;
        } else {
          return true;
        }
      });
      this.filteredMapLookupItems = of(itemsList);
    }
  }


  _filterMapRetailerLookupItems(event, index) {
    let value = _.toLower(event.target.value);
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => { return _.toLower(item.retailer).indexOf(value) > -1; }),
      a => a.retailerKey);
    this.filteredMapRetailerLookupItems = of(itemsList);
    this.validMappedSKUList[index].retailer = this.isValidRetailerValue(value);
  }


  _filterMapBrandLookupItems(event, index) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.mappedSKUList[index].retailerKey;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey && _.toLower(item.brand).indexOf(value) > -1;
      }),
      a => a.brand);
    this.filteredMapBrandLookupItems = of(itemsList);
    this.validMappedSKUList[index].brand = this.isValidBrandValue(value);
  }

  _filterMapProductGroupLookupItems(event, index) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.mappedSKUList[index].retailerKey;
    const brand = this.mappedSKUList[index].brand;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey &&
          item.brand === brand && _.toLower(item.productGroup).indexOf(value) > -1;
      }),
      a => a.productGroup);
    this.filteredMapProductGroupLookupItems = of(itemsList);
    this.validMappedSKUList[index].productGroup = this.isValidProductGroupValue(value);
  }

  _filterMapProductDescriptionLookupItems(event, index) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.mappedSKUList[index].retailerKey;
    const brand = this.mappedSKUList[index].brand;
    const productGroup = this.mappedSKUList[index].productGroup;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey &&
          item.brand === brand &&
          item.productGroup === productGroup &&
          _.toLower(item.productDescription).indexOf(value) > -1;
      }),
      a => a.productDescription);
    this.filteredMapProductDescriptionLookupItems = of(itemsList);
    this.validMappedSKUList[index].productDescription = this.isValidProductDescriptionValue(value);
  }

  _filterMapLookupItems(event, index) {
    let value = event.target.value;
    const retailerKey = this.mappedSKUList[index].retailerKey;
    const brand = this.mappedSKUList[index].brand;
    const productGroup = this.mappedSKUList[index].productGroup;
    const itemsList = this.lookupItemList.filter(item => {
      if (productGroup) {
        return item.retailerKey === retailerKey
          && item.brand === brand
          && item.productGroup === productGroup
          && item.sku.indexOf(value) > -1;
      } else if (brand) {
        return item.retailerKey === retailerKey && item.brand === brand && item.sku.indexOf(value) > -1;
      } else if (retailerKey) {
        return item.retailerKey === retailerKey && item.sku.indexOf(value) > -1;
      } else {
        return item.sku.indexOf(value) > -1;
      }
    });
    this.filteredMapLookupItems = of(itemsList);
    this.validMappedSKUList[index].sku = this.isValidSKUValue(value);
  }

  mapRetailerChange(item, index) {
    if (index === 0) {
      this.levelType = this.LEVEL_MAPPING.RETAILER;
    };
    this.mappedSKUList[index].retailer = item.retailer;
    this.mappedSKUList[index].retailerKey = item.retailerKey;
    this.mappedSKUList[index].brand = null;
    this.mappedSKUList[index].productGroup = null;
    this.mappedSKUList[index].productDescription = null;
    this.mappedSKUList[index].sku = null;
    this.validMappedSKUList[index].retailer = this.isValidRetailerValue(item.retailer);
    this.validMappedSKUList[index].brand = true;
    this.validMappedSKUList[index].productGroup = true;
    this.validMappedSKUList[index].productDescription = true;
    this.validMappedSKUList[index].sku = true;
  }

  mapBrandChange(item, index) {
    if (index === 0) {
      this.levelType = this.LEVEL_MAPPING.BRAND;
    };
    this.mappedSKUList[index].brand = item.brand;
    this.mappedSKUList[index].productGroup = null;
    this.mappedSKUList[index].productDescription = null;
    this.mappedSKUList[index].sku = null;
    this.validMappedSKUList[index].brand = this.isValidBrandValue(item.brand);
    this.validMappedSKUList[index].productGroup = true;
    this.validMappedSKUList[index].productDescription = true;
    this.validMappedSKUList[index].sku = true;
  }

  mapProductGroupChange(item, index) {
    if (index === 0) {
      this.levelType = this.LEVEL_MAPPING.PRODUCT_GROUP;
    };
    this.mappedSKUList[index].productGroup = item.productGroup;
    this.mappedSKUList[index].productDescription = null;
    this.mappedSKUList[index].sku = null;
    this.validMappedSKUList[index].productGroup = this.isValidProductGroupValue(item.productGroup);
    this.validMappedSKUList[index].productDescription = true;
    this.validMappedSKUList[index].sku = true;
  }

  mapProductDescriptionChange(item, index) {
    if (index === 0) {
      this.levelType = this.LEVEL_MAPPING.SKU;
    };
    this.mappedSKUList[index].productDescription = item.productDescription;
    this.mappedSKUList[index].sku = item.sku;
    this.validMappedSKUList[index].productDescription = this.isValidProductDescriptionValue(item.productDescription);
  }

  mapSKUChange(item, index) {
    if (index === 0) {
      this.levelType = this.LEVEL_MAPPING.SKU;
    };
    this.mappedSKUList[index].retailer = item.retailer;
    this.mappedSKUList[index].retailerKey = item.retailerKey;
    this.mappedSKUList[index].brand = item.brand;
    this.mappedSKUList[index].productGroup = item.productGroup;
    this.mappedSKUList[index].productDescription = item.productDescription;
    this.mappedSKUList[index].sku = item.sku;
    this.validMappedSKUList[index].sku = this.isValidSKUValue(item.sku);
  }

  mapNewSKU() {
    if (this.mappedSKUList.length < 5) {
      this.mappedSKUList.push({
        retailerKey: '',
        retailer: '',
        likeness: 100
      });
      this.pushValidMappedSKUObject();
    } else {
      this.toastr.error('Error', 'Only upto five items can be mapped.');
    }
  }

  saveForecastMappings() {
    const isForecastFormValid = this.checkForecastFormValidity();
    const isMappedCorrectly = this.checkMappedSKURowsValidty();
    if (isMappedCorrectly
      && isForecastFormValid
      && this.forecastItemForm.valid
      && this.mappedSKUList.length
      && !this.isWeekOverlapping) {
      const isLevelCorrect = this.checkLevelConsistency();
      if (isLevelCorrect) {
        const payload: any = {
          ...this.forecastItemForm.value,
          mappedItems: this.mappedSKUList,
          levelType: this.levelType
        };

        if (this.isEdit) {
          const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: {
              headerName: `Update Mapping`,
              confirmationMessage: `Do you want to update the associated adjusted sales in the outlier manager?`
            }
          });

          confirmationDialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.spinner.show();
              payload.retailerKey = this.selectedSKU.retailerKey;
              payload.forecastSKUItemId = this.selectedSKU.forecastSKUItemId;
              this.mappingService.UpdateForecastItem(payload).subscribe(res => {
                this.mappingService.addEditMappingActionSubject.next(true);
                this.spinner.hide();
                this.toastr.success('Success', 'Mapping updated successfully.');
              });
            }
          });

        } else {
          if (this.mappingHistory.length < 5) {
            this.spinner.show();
            this.mappingService.AddForecastItem(payload).subscribe((res: any) => {
              this.spinner.hide();
              this.isEdit = true;
              this.selectedSKU = res;
              this.mappingService.addEditMappingActionSubject.next(true);
              this.getMappingSourceByMappingManagerId(res.forecastSKUItemId);
              this.getMappingHitory(res);
              this.toastr.success('Success', 'Mapping added successfully.');
            });
          } else {
            this.toastr.error('Error', 'Only upto five items can be mapped.');
          }
        }
      } else {
        this.toastr.error('Error', 'Mapping level is not set correctly.');
      }

    } else {
      this.toastr.error('Error', 'Please fill the form correctly or add at least one mapping.');
    }
  }

  checkMappedSKURowsValidty(): boolean {
    let isMappedCorrectly = true;
    this.validMappedSKUList.every(item => {
      if (!(item.retailer
        && item.brand
        && item.productGroup
        && item.productDescription
        && item.sku)) {
        isMappedCorrectly = false;
        return false;
      }
      return true;
    });
    return isMappedCorrectly;
  }

  checkForecastFormValidity() {
    return this.validForecastForm.retailer
      && this.validForecastForm.brand
      && this.validForecastForm.productGroup
      && this.validForecastForm.productDescription
      && this.validForecastForm.sku;
  }

  checkLevelConsistency(): boolean {
    let isCorrectLevel = true;
    switch (this.levelType) {
      case this.LEVEL_MAPPING.RETAILER: {
        this.mappedSKUList.every(item => {
          if (!item.retailerKey || item.brand || item.productGroup || item.productDescription || item.sku) {
            isCorrectLevel = false;
            return false;
          }
          return true;
        });
        break;
      }
      case this.LEVEL_MAPPING.BRAND: {
        this.mappedSKUList.every(item => {
          if (!item.brand || item.productGroup || item.productDescription || item.sku) {
            isCorrectLevel = false;
            return false;
          }
          return true;
        });
        break;
      }
      case this.LEVEL_MAPPING.PRODUCT_GROUP: {
        this.mappedSKUList.every(item => {
          if (!item.productGroup || item.productDescription || item.sku) {
            isCorrectLevel = false;
            return false;
          }
          return true;
        });
        break;
      }
      case this.LEVEL_MAPPING.SKU: {
        this.mappedSKUList.every(item => {
          if (!item.sku) {
            isCorrectLevel = false;
            return false;
          }
          return true;
        });
        break;
      }
    }
    return isCorrectLevel;
  }

  deleteMappedItem(mapItem, index) {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Delete Mapping`,
        confirmationMessage: `Are you sure you want to delete this mapping?`
      }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {

        if (mapItem.mappedItemId) {
          this.spinner.show();
          this.mappingService.DeleteMappedItem(mapItem.mappedItemId, this.forecastItemForm.value.retailerKey, this.forecastItemForm.value.sku).subscribe(res => {
            this.mappedSKUList.splice(index, 1);
            this.validMappedSKUList.splice(index, 1);
            if (this.mappedSKUList.length == 0) {
              this.levelType = this.LEVEL_MAPPING.RETAILER;
            }
            this.spinner.hide();
            this.toastr.success('Success', `Mappings deleted successfully.`);
          }, err => {
            this.toastr.error('Error', `System failed to delete mappings.`);
            this.spinner.hide();
          });
        } else {  
          this.mappedSKUList.splice(index, 1);
          this.validMappedSKUList.splice(index, 1);
          if (this.mappedSKUList.length == 0) {
            this.levelType = this.LEVEL_MAPPING.RETAILER;
          }      
            this.getWeeklyMappedChartData();          
        }
      }
    });
  }

  applyMappedItems() {
    const isForecastFormValid = this.checkForecastFormValidity();
    const isMappedCorrectly = this.checkMappedSKURowsValidty();
    if (isMappedCorrectly
      && isForecastFormValid
      && this.forecastItemForm.valid
      && this.mappedSKUList.length
      && !this.isWeekOverlapping) {
      const isLevelCorrect = this.checkLevelConsistency();
      if (isLevelCorrect) {
        this.getAvgWeeklySaleUnits();
        this.getWeeklyMappedChartData();
      } else {
        this.toastr.error('Error', 'Mapping level is not set correctly.');
      }
    } else {
      this.toastr.error('Error', 'Please fill the form correctly or add at least one mapping.');
    }
  }

  getAvgWeeklySaleUnits() {
    const isLevelCorrect = this.checkLevelConsistency();
    if (isLevelCorrect && this.mappedSKUList.length) {
      const mappedSKUs = this.mappedSKUList.map(a => {
        return {
          ...a,
          effectiveStartDate: this.forecastItemForm.get('effectiveStartDate').value,
          effectiveEndDate: this.forecastItemForm.get('effectiveEndDate').value,
          levelType: this.levelType
        };
      });
      this.mappingService.GetAvgWeeklySaleUnit({ mappedItems: mappedSKUs }).subscribe((res: any) => {
        this.mappedSKUList = res;
      });
    } else {
      this.toastr.error('Error', 'Mapping level is not set correctly.')
    }

  }

  ngOnDestroy() {
  }

}
