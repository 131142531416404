<div class="anomoly-wrapper">
  <!-- <h1>Recent Files</h1>
  <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">TimeSeries</th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let col of AnonomyFiles">
          <td>{{col}}</td>
          <td>{{col}}</td>
          <td>{{col}}</td>
        </tr>
      </tbody>
    </table>  </div> -->
  <h1>Dataset</h1>
  <div class="anomoly-dropzone">
    <ngx-dropzone (change)="onSelect($event)">
      <ngx-dropzone-label>Drop files here!</ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>

  </div>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Column</th>
        <th scope="col">TimeSeries</th>
        <th scope="col">Value</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let col of getlist()">
        <td>{{col}}</td>
        <td>
          <input type="radio" name="abc">
        </td>
        <td><input type="radio" name="xyz"></td>
      </tr>
    </tbody>
  </table>
  <div style="display: flex; justify-content: flex-end;" *ngIf="!IsUploaded">
    <button class="btn btn-primary mat-raised-button mat-button-base" (click)="upload()">Upload</button>
  </div>
  <div style="display: flex; justify-content: flex-end;" *ngIf="IsUploaded">
    <button class="btn btn-primary mat-raised-button mat-button-base" (click)="findAnomoly()">Find Anomalies</button>
  </div>

  <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview> -->

  <!-- <div style="display: block">
    <canvas ngChartjs
        chartType="line"
        [datasets]="chartDatasets"
        [labels]="xAxis"
        legend="true"
        >
    </canvas>
  </div> -->
  <ngx-charts-line-chart #chart [view]="view" [scheme]="colorScheme" [legend]="false" [showXAxisLabel]="''"
    [showYAxisLabel]="''" [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="''" [yAxisLabel]="''" [timeline]="true"
    [results]="chartDatasets" (select)="onSelectChart($event)" (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-line-chart>
  <div class="all-show-grid">
    <ag-grid-angular id="myGrid" class="ag-theme-material"  [suppressMenuHide]="true"
       groupHeaders suppressRowClickSelection  toolPanelSuppressGroups
      toolPanelSuppressValues [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="AnomolyResponse"
      (gridReady)="onGridReady($event)"  #grid></ag-grid-angular>
  </div>
</div>
