import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalstorageService } from '../localstorage.service';
import { USER_TOKEN_INFO } from 'src/common/keys';
import { environment } from '../../environments/environment';
import * as signalR from '@aspnet/signalr';

@Injectable({
  providedIn: 'root'
})
export class ChannelHandlerService {

  connection: any;

  constructor(private httpClient: HttpClient, private storage: LocalstorageService) { }

  createConnection() {
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.messagingChannelHubUrl,{accessTokenFactory:()=>this.storage.get(USER_TOKEN_INFO)})
      .build()
  }

  connect() {
    this.connection
      .start()
      .then(function () {
      })
      .catch(function (err) {
        return console.error();
      });
  }

  stop() {
    this.connection.stop()
      .then(function () {
      })
      .catch(function (err) {
        return console.error();
      });
  }

}
