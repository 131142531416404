export const draggableArrayTypes = {
    OLD_FOSNER_B: 'OLD_FOSNER_B',
    OLD_FOSNER_C: 'OLD_FOSNER_C',
    OLD_FOSNER_D: 'OLD_FOSNER_D',
    NEW_FOSNER_B: 'NEW_FOSNER_B',
    NEW_FOSNER_C: 'NEW_FOSNER_C',
    NEW_FOSNER_D: 'NEW_FOSNER_D',
    LEFT_OVER: 'LEFT_OVER'
};

export const enforcementDropDownOptions = [
    'None',
    'Enforce FOSNR B',
    'Enforce FOSNR C',
    'Enforce FOSNR D'
];

export const planogramUrl = 'https://fosnr-planogram-backend.azurewebsites.net';