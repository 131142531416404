import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { LocalstorageService } from './localstorage.service';
import { BOT_CONVERSATION_INFO } from '../common/keys';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BotService {
  public notificationSubject = new BehaviorSubject<any>(null);
  public notificationSubject$ = this.notificationSubject.asObservable();
  constructor(public http: HttpClient, public storage: LocalstorageService) { }

  public GenerateToken = () => {
    return this.http.post<any>(`${environment.AppTokenGenerate}`, {});
  }
  public GetConversations = () => {
    return this.http.post<any>(`${environment.AppConversations}`, {});
  }
  public GetConversationActivity = (body) => {
    const conversationId = this.storage.get(BOT_CONVERSATION_INFO);
    return this.http.post<any>(`${environment.AppConversations}/${conversationId}/activities`, body);
  }
  public GetConversationActivityResponse = (id) => {
    const conversationId = this.storage.get(BOT_CONVERSATION_INFO);
    return this.http.get<any>(`${environment.AppConversations}/${conversationId}/activities?watermark=${id}`);
  }
  public GetPowerBiToken = (id) => {
    const conversationId = this.storage.get(BOT_CONVERSATION_INFO);
    return this.http.get<any>(`${environment.AppConversations}/${conversationId}/activities?watermark=${id}`);
  }
  public GetMicrosoftLoginToken = (params) => {
    return this.http.get(environment.AccessToken, {params});
  }
  public SendBroadCast = (model) => {
    return this.http.post<any>(`${environment.NotificationCommon}/SendBroadCast`, model);
  }
  public ShareMenu = (model) => {
    return this.http.post<any>(`${environment.NotificationCommon}/Share`, model);
  }
}
