<div mat-dialog-content>
    <p><strong>Select Measures</strong></p>
    <div class="row d-flex justify-content-between">
        <ng-container *ngFor="let item of matricesArray">
            <div class="col-md-5">
                <section class="ag-column-checkbox-section">
                    <mat-checkbox class="checkbox-margin" color="accent" labelPosition="before" [(ngModel)]="item.checked"><span matTooltip={{item.name}}>{{item.name}}</span></mat-checkbox>
                </section>
            </div>
        </ng-container>
    </div>
</div>
<div mat-dialog-actions class="justify-content-between">
    <div>
        <a class="cursor-pointer text-danger" (click)="clearAll()"><u>Clear All</u></a>
        <a class="ml-3 cursor-pointer text-secondary" (click)="selectAll()"><u>Select All</u></a>
    </div>
    <div>
        <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="accent" (click)="onSaveClick()">Save</button>
    </div>
</div>
