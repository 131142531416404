<div>
  <div class="dialog-close-btn">
    <h1 mat-dialog-title>Location Hierarchy Setup</h1>
    <button mat-icon-button (click)="onCloseClick()">
      <i class="material-icons">
        close
      </i>
    </button>
  </div>

  <div mat-dialog-content style="overflow: hidden;">

    <div class="item-wrapper">
      <form class="hierarchy-form">
        <div class="row">

          <ng-container *ngFor="let location of locationHierarchyManagerLevel">
            <div class="form-group col-6">
              <label [for]="location.locationHierarchyDisplayName">{{ location.locationHierarchyDisplayName + '
                (L'+location.locationHierarchyLevel +')' }}</label>

              <input [name]="location.locationHierarchyDisplayName" [matAutocomplete]="itemauto"
                [(ngModel)]="location.locationHierarchyValue" [ngModelOptions]="{standalone: true}"
                (input)="_filterLocationLabels($event, location)" (focus)="onLocationControlFocus($event, location)"
                [title]="location.locationHierarchyValue ? location.locationHierarchyValue : ''" />

              <mat-autocomplete [displayWith]="displayLocationFn" #itemauto="matAutocomplete"
                (optionSelected)="locationValueChange($event.option.value, location)">
                <mat-option *ngFor="let location of filteredLookupLocations | async" [value]="location">
                  {{location.locationHierarchyValue}}
                </mat-option>
              </mat-autocomplete>

            </div>
          </ng-container>

        </div>
      </form>
    </div>
  </div>

  <div mat-dialog-actions class="dialog-action-btn">
    <button mat-stroked-button color="accent" (click)="onCloseClick()">Cancel</button>
    <button mat-flat-button color="accent" (click)="onSubmit()">Save</button>
  </div>

</div>
