import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PromoService } from 'src/services/forecast-services/promo-service';
import { ProcurementService } from 'src/services/procurement.service';

@Component({
  selector: 'show-grn-summary-cell-renderer',
  template: `<a class="product-title-link" [title]="params.value" (click)="btnEditClickHandler()">{{params.value}}</a>`,
})
export class ShowGRNSummaryRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private procuchmentService: ProcurementService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnEditClickHandler() {
    this.procuchmentService.setPMSGridActionCellSubject({ grnData: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
