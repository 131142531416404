<div class="comparisons-main-cls mt-3">
    <div class="radar-chart-div" *ngFor="let chart of charts; let i=index">
        <div class="d-flex m-3 align-items-center">
            <h5 class="mb-3 mr-3">{{chart?.tabName}}</h5>
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>Variables</mat-label>
                    <mat-select multiple (selectionChange)="variablesSelectionChanged(i)" [(ngModel)]="chart.spiderVariables">
                        <mat-option [disabled]="chart.spiderVariables.length >= 12 && !chart.spiderVariables.includes(item.id)"
                            *ngFor="let item of chart.metrices" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <div class="d-flex align-items-center justify-content-center w-100" #radarChart>
            </div>
        </div>
    </div>
</div>
