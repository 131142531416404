<mat-expansion-panel [expanded]="true" hideToggle>
  <mat-expansion-panel-header class="disable_ripple" collapsedHeight="75px" expandedHeight="75px">
    <mat-panel-title> Channels
    </mat-panel-title>
    <mat-panel-description style="flex-grow: 0; z-index: 9999; margin: 0 !important;">
      <mat-icon (click)="openChannelDialog(); $event.stopPropagation()"><i class="material-icons md-18 white-icon">add_circle</i>
      </mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-nav-list>
    <mat-list-item  class="channel-menu-list" (click)="activeList(item)" style="height: 60px;"
      *ngFor="let item of channelMessagingModel.UsersInChannel; let i=index" [ngClass]="{active: item.channelId === selectedId}">
      <span class="unread-msg-count" *ngIf="item.messageCount && item.messageCount > 0" [matBadge]="item?.messageCount" matBadgeOverlap="false" matBadgeColor="accent"></span>
      <img matListAvatar class="channel-logo rounded-circle img-fluid"
        [ngStyle]="item.messageCount && item.messageCount > 0 && { 'border': '3px solid #f04371' }"
        [src]="item.documentPath || 'assets/images/thumbnail.png'" alt="user Image">  
      <a (click)="showChatDetailsElement(item)" matLine>{{item?.channelName}}
          <!-- <span *ngIf="item.messageCount && item.messageCount > 0" [matBadge]="item?.messageCount" matBadgeOverlap="false" matBadgeColor="accent"></span> -->
      </a>
      <mat-icon [class.active]="selected" [matMenuTriggerFor]="appMenu">chevron_right</mat-icon>
      <mat-menu class="providers-menu" #appMenu="matMenu" xPosition="after">
        <button [hidden]="item.createdById !== userId" mat-menu-item
          (click)="openChannelDialog(isEdit = true, item); $event.stopPropagation()">Manage
          Channel</button>
        <mat-divider></mat-divider>
        <button mat-menu-item [hidden]="item.createdById === userId" (click)="leaveChannel(item, i)">Leave
          Channel</button>
        <mat-divider></mat-divider>
        <button [hidden]="item.createdById !== userId" mat-menu-item class="delete-text-color"
          (click)="openDeleteDialog(DeleteDialog); $event.stopPropagation()">Delete Channel</button>
        <ng-template #DeleteDialog>
          <mat-icon style="float: right;" (click)="matDialogRef.close()">clear</mat-icon>
          <div mat-dialog-content>
            <mat-label> <strong>
                Are you sure you want to delete {{item.channelName }}
              </strong>
            </mat-label>
            <div class="action-panel text-center" style="margin-left:15px; margin-right:15px;">
              <button mat-raised-button class="btn m-left btn-light" color="warn"
                (click)="matDialogRef.close()">Cancel</button>
              <button type="submit" class="btn btn-primary d-block" (click)="deleteChannel(item, i)">Delete</button>
            </div>
          </div>
        </ng-template>
      </mat-menu>
    </mat-list-item>
  </mat-nav-list>
</mat-expansion-panel>
