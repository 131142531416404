import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

import { PromotionPlannerService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { INVALID_TEAM, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { NgxSpinnerService } from 'ngx-spinner';
import moment from 'moment';


@Component({
    selector: 'app-promotion-planner-planogram',
    templateUrl: './promotion-planner-planogram.component.html',
    styleUrls: ['./promotion-planner-planogram.component.scss']
})
export class PromotionPlannerPlanogramComponent implements OnInit, OnChanges, OnDestroy {

    @Input() promotionDetail: any;
    @Input() fixtureObject: any;
    @Input() itemDetailData: any = [];
    @Output() itemPositionChanges = new EventEmitter();

    planogramPanelOpenState: boolean = true;
    userTeam: any = '';
    isRetailerTeam: boolean = false;

    originalItemDetailData: any = [];

    draggableArrayTypes = {
        UNASSIGNED_POSITION: 'UNASSIGNED_POSITION',
        SWAP_POSITION: 'SWAP_POSITION'
    };

    rows: number = 0;
    columns: number = 0;
    planogramPromotionItems: any = [];
    unassignedPromotionItems: any = [];
    disabledPositions: any = [];

    gridId: string = `grid-view-${new Date().getTime()}`;
    tableId: string = `table-view-${new Date().getTime()}`;

    private draggingItem = {
        index: 0,
        arrayType: 'SWAP_POSITION'
    };

    constructor(
        public promotionPlannerService: PromotionPlannerService,
        public toastr: NgxToasterService,
        private usersService: UsersService,
        public spinner: NgxSpinnerService,
    ) { }

    ngOnInit() {
        this.setRoleBaseAccess();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.promotionDetail && this.promotionDetail.promotionItemPlannerId) {
            this.getDisabledPositionList();
        }
        this.originalItemDetailData = _.cloneDeep(this.itemDetailData);
    }

    get getFixtureName(): string {
        return _.get(this.fixtureObject, 'fixtureName', '');
    }

    get exportPlannogramItemDetails(): any[] {
        return _.orderBy(_.compact(this.planogramPromotionItems), ['position'], ['asc']);
    }

    setRoleBaseAccess() {
        this.userTeam = this.usersService.checkLoginUserTeam();
        switch (this.userTeam) {
            case VENDOR_TEAM_CODE: {
                this.isRetailerTeam = false;
                break;
            }
            case RETAILER_TEAM_CODE: {
                this.isRetailerTeam = true;
                break;
            }
            case INVALID_TEAM: {
                console.log('Valid Team Not assigned');
                break;
            }
        }
    }

    getDisabledPositionList() {
        const requestBody = {
            PromotionItemPlannerId: this.promotionDetail.promotionItemPlannerId
        };
        this.promotionPlannerService.GetPromotionPlannerDisabledPositionList(requestBody).subscribe(positionList => {
            this.formatDisabledPositionList(positionList);
            this.createPlanogramData(this.originalItemDetailData);
        });
    }

    formatDisabledPositionList(positionList) {
        this.disabledPositions = [];
        positionList.forEach(position => {
            const numberOfFacings = position.numberOfFacings;
            if (numberOfFacings) {
                let facingIndex = position.position;
                for (; facingIndex < (position.position + numberOfFacings); facingIndex++) {
                    this.disabledPositions.push(facingIndex);
                }
            } else {
                this.disabledPositions.push(position.position);
            }
        });
    }

    isPositionDisabled(arrayIndex): boolean {
        return this.disabledPositions.includes(arrayIndex + 1);
    }

    createPlanogramData(itemDetailData) {
        this.rows = _.get(this.fixtureObject, 'fixtureRow', 0);
        this.columns = _.get(this.fixtureObject, 'fixtureColumn', 0);

        const planogramPromotionItems = Object.assign([], _.orderBy(
            _.filter(itemDetailData, a => a.actionName === 'Accepted' && a.position),
            ['position'], ['asc']));
        this.unassignedPromotionItems = Object.assign([], _.orderBy(
            _.filter(itemDetailData, a => a.actionName === 'Accepted' && !a.position),
            ['itemDescription'], ['asc']
        ));

        this.planogramPromotionItems = (planogramPromotionItems.length || this.unassignedPromotionItems.length) ? this.counter(this.rows * this.columns) : [];
        for (let i = 0; i < this.planogramPromotionItems.length; i++) {
            const positionItem = _.find(planogramPromotionItems, a => a.position === (i + 1));
            if (positionItem && !this.disabledPositions.includes(i + 1)) {
                const numberOfFacings = positionItem.numberOfFacings;
                if (numberOfFacings) {
                    let facingIndex = i;
                    for (; facingIndex < (i + numberOfFacings); facingIndex++) {
                        this.planogramPromotionItems[facingIndex] = Object.assign({}, positionItem);
                    }
                    i = facingIndex - 1;
                } else {
                    this.planogramPromotionItems[i] = Object.assign({}, positionItem);
                }
            } else {
                this.planogramPromotionItems[i] = null;
            }
        }

    }

    getTooltip(item, arrayIndex): string {
        if (item) {
            return `${item.itemDescription} \n SKU: ${item.internalSKUId}`;
        } else if (this.disabledPositions.includes(arrayIndex + 1)) {
            return `Disabled Position`;
        } else {
            return `Open Position`;
        }
    }

    getImagePath(item): string {
        return _.get(item, 'document.documentThumbnailPath', '')
    }

    getItemDescription(item): string {
        return _.get(item, 'itemDescription', '')
    }

    // async exportPlanogramPDF(event) {
    //     event.stopPropagation();
    //     let DATA = document.getElementById('exportHtml');
    //     let pdf = new jsPDF('p', 'pt', [DATA.offsetHeight, DATA.offsetWidth]);
    //     await pdf.html(DATA);
    //     pdf.save('promotion-planner.pdf');
    // }
    async exportPlanogramPDF(event) {
        event.stopPropagation();
        try {
            this.spinner.show();

            let planogramGrid = document.getElementById(this.gridId);

            let pdf = new jsPDF('p', 'pt', [planogramGrid.clientHeight, planogramGrid.clientWidth]);
            await pdf.html(planogramGrid, {
                autoPaging: true
            });
            const data = this.exportPlannogramItemDetails.map(item => {
                return {
                    position: item.position,
                    internalSKUId: item.internalSKUId,
                    itemDescription: item.itemDescription,
                    upc: item.upc
                };
            });
            autoTable(pdf, {
                startY: planogramGrid.offsetHeight + 10,
                head: [
                    [
                        {
                            content: `${this.getFixtureName} Movie Collection ${moment(this.promotionDetail.startDate).format('MM/DD/yy')} - ${moment(this.promotionDetail.endDate).format('MM/DD/yy')}`
                            , colSpan: 4, styles: { halign: 'center' }
                        },
                    ],
                    ['Position', 'Internal SKU ID', 'Item Description', 'UPC'],
                ],
                columns: [
                    { header: 'position', dataKey: 'position' },
                    { header: 'internalSKUId', dataKey: 'internalSKUId' },
                    { header: 'itemDescription', dataKey: 'itemDescription' },
                    { header: 'upc', dataKey: 'upc' }
                ],
                body: data
            });
            pdf.save('promotion-planner.pdf');
            this.spinner.hide();

        } catch (err) {
            console.log(err);
            this.spinner.hide();
        }

    }

    savePlanogram(event) {
        event.stopPropagation();
        
        const updatedUnassingedPlanogramPromotionItems = _.uniqBy(_.compact(this.unassignedPromotionItems), a => a && a.promotionItemPlannerDetailId);
        const updatedPlanogramPromotionItems = _.uniqBy(_.compact(this.planogramPromotionItems), a => a && a.promotionItemPlannerDetailId);
        updatedUnassingedPlanogramPromotionItems.length && updatedPlanogramPromotionItems.push(
            ...updatedUnassingedPlanogramPromotionItems
        )
        const updatedPositionItems = [];
        updatedPlanogramPromotionItems.forEach(item => {
            const originalItem = this.itemDetailData.find(a => a.promotionItemPlannerDetailId == item.promotionItemPlannerDetailId);
            if (originalItem) {
                if (originalItem.position != item.position) {
                    updatedPositionItems.push(item);
                }
            } else {
                updatedPositionItems.push(updatedPositionItems);
            }
        });
        updatedPositionItems.length ? this.itemPositionChanges.emit(updatedPositionItems) : this.toastr.info('Info', 'Please update item position first.');
    }

    counter(i: number): Array<any> {
        return new Array(i);
    }

    cdkDragStarted(index: number, arrayType: string) {
        this.draggingItem.index = index;
        this.draggingItem.arrayType = arrayType;
    }

    drop(index: number, arrayType: string) {
        if (this.disabledPositions.includes(index + 1)) {
            return false;
        }
        if (!this.isRetailerTeam) {
            return false;
        }
        // SELECTING THE ARRAY ON WHICH ITEM IS DROPPED
        let arrayDropped = [];
        let arrayDroppedType = '';
        switch (arrayType) {
            case this.draggableArrayTypes.SWAP_POSITION:
                arrayDropped = this.planogramPromotionItems;
                arrayDroppedType = this.draggableArrayTypes.SWAP_POSITION;
                break;
            case this.draggableArrayTypes.UNASSIGNED_POSITION:
                arrayDropped = this.unassignedPromotionItems;
                break;
            default:
                return;
        }

        // SELECTING THE ARRAY FROM WHICH ITEM IS DRAGGED
        let draggedArray = [];
        let draggedArrayType = '';
        switch (this.draggingItem.arrayType) {
            case this.draggableArrayTypes.SWAP_POSITION:
                draggedArray = this.planogramPromotionItems;
                draggedArrayType = this.draggableArrayTypes.SWAP_POSITION;
                break;
            case this.draggableArrayTypes.UNASSIGNED_POSITION:
                draggedArray = this.unassignedPromotionItems;
                break;
            default:
                return;
        }

        if (index == this.draggingItem.index && draggedArrayType == arrayDroppedType) return; // dropped on same position

        // SWAPPING ITEMS
        const firstElement = arrayDropped[index];
        const secondElement = draggedArray[this.draggingItem.index];

        if (firstElement) {
            const firstElementPostion = firstElement.position;
            const secondElementPostion = secondElement.position;

            if (firstElement.numberOfFacings == secondElement.numberOfFacings) {
                if (firstElement.numberOfFacings) {
                    this.swapItemsWithSameFacings(firstElement, secondElement, arrayDropped, draggedArray);
                } else {
                    arrayDropped[index] = secondElement;
                    draggedArray[this.draggingItem.index] = firstElement;
                }
            } else {
                const isDragDropable = this.checkForDragDropAvailableSlots(firstElement, secondElement);
                if (!isDragDropable) {
                    this.toastr.error('Error', 'Unable to swap items with multiple facings.')
                    return false;
                }
                this.swapItemsWithDiffFacings(firstElement, secondElement, arrayDropped, draggedArray);
            }

            firstElement.position = secondElementPostion;
            secondElement.position = firstElementPostion;


        } else {
            const isDragDropable = this.checkForDragDropEmptySlots(index, secondElement);

            if (!isDragDropable) {
                this.toastr.error('Error', 'Unable to swap items with multiple facings.')
                return false;
            }

            this.swapFacingsItemsEmptySlots(index, secondElement, arrayDropped, draggedArray);

        }
        // POSITIONS SWAPING


        // REMOVE EMPTY ITEM FROM LEFT OVERS ARRAY
        if (!draggedArray[this.draggingItem.index] && this.draggingItem.arrayType == this.draggableArrayTypes.UNASSIGNED_POSITION) {
            draggedArray.splice(this.draggingItem.index, 1);
        }

        return;
    }

    swapItemsWithSameFacings(firstElement, secondElement, arrayDropped, draggedArray) {
        const dropRequiredSlots = firstElement.numberOfFacings || 1;
        const draggRequiredSlots = secondElement.numberOfFacings || 1;
        for (let dropFacingIndex = (firstElement.position - 1); dropFacingIndex < (firstElement.position - 1 + dropRequiredSlots); dropFacingIndex++) {
            arrayDropped[dropFacingIndex] = secondElement;
        }

        for (let dragFacingIndex = (secondElement.position - 1); dragFacingIndex < (secondElement.position - 1 + draggRequiredSlots); dragFacingIndex++) {
            draggedArray[dragFacingIndex] = firstElement;
        }
    }

    swapItemsWithDiffFacings(firstElement, secondElement, arrayDropped, draggedArray) {
        const dropRequiredSlots = firstElement.numberOfFacings || 1;
        const draggRequiredSlots = secondElement.numberOfFacings || 1;

        let dragFacingIndex = secondElement.position ? (secondElement.position - 1) : this.draggingItem.index;

        for (let dropIndex = (firstElement.position - 1); dropIndex < (firstElement.position - 1 + dropRequiredSlots); dropIndex++) {
            arrayDropped[dropIndex] = null;
        }

        for (let dragIndex = dragFacingIndex; dragIndex < (dragFacingIndex + (secondElement.position ? draggRequiredSlots : 1)); dragIndex++) {
            draggedArray[dragIndex] = null;
        }

        for (let dropIndex = (firstElement.position - 1); dropIndex < (firstElement.position - 1 + draggRequiredSlots); dropIndex++) {
            arrayDropped[dropIndex] = secondElement;
        }

        for (let dragIndex = dragFacingIndex; dragIndex < (dragFacingIndex + (secondElement.position ? dropRequiredSlots : 1)); dragIndex++) {
            draggedArray[dragIndex] = firstElement;
        }
    }

    swapFacingsItemsEmptySlots(index, secondElement, arrayDropped, draggedArray) {
        const draggRequiredSlots = secondElement.numberOfFacings || 1;
        let dragFacingIndex = secondElement.position ? (secondElement.position - 1) : this.draggingItem.index;

        for (let dragIndex = dragFacingIndex; dragIndex < (dragFacingIndex + (secondElement.position ? draggRequiredSlots : 1)); dragIndex++) {
            draggedArray[dragIndex] = null;
        }
        secondElement.position = (index + 1);
        for (let dropIndex = index; dropIndex < (index + draggRequiredSlots); dropIndex++) {
            arrayDropped[dropIndex] = secondElement;
        }

    }

    checkForDragDropAvailableSlots(firstElement, secondElement): boolean {
        const dropRequiredSlots = firstElement.numberOfFacings || 1;
        const draggRequiredSlots = secondElement.numberOfFacings || 1;
        let isDragDropable: boolean = true;

        for (let dropIndex = (firstElement.position - 1); dropIndex < (firstElement.position - 1 + draggRequiredSlots); dropIndex++) {
            if (dropIndex >= (this.rows * this.columns)
                || (this.planogramPromotionItems[dropIndex] && this.planogramPromotionItems[dropIndex].position != firstElement.position)) {
                isDragDropable = false;
                break;
            }
        }

        for (let dragFacingIndex = (secondElement.position - 1); dragFacingIndex < (secondElement.position - 1 + dropRequiredSlots); dragFacingIndex++) {
            if (this.planogramPromotionItems[dragFacingIndex] && this.planogramPromotionItems[dragFacingIndex].position != secondElement.position) {
                isDragDropable = false;
                break;
            }
        }

        return isDragDropable;
    }

    checkForDragDropEmptySlots(index, secondElement) {
        const draggRequiredSlots = secondElement.numberOfFacings || 1;
        let isDragDropable: boolean = true;
        for (let dropIndex = index; dropIndex < (index + draggRequiredSlots); dropIndex++) {
            if (dropIndex >= (this.rows * this.columns)
                || (this.planogramPromotionItems[dropIndex] && this.planogramPromotionItems[dropIndex].position != secondElement.position)) {
                isDragDropable = false;
                break;
            }
        }

        return isDragDropable;
    }

    ngOnDestroy() {
    }

}