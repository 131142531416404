<div class="container-fluid">

  <form [formGroup]="filtersForm" class="row product-grid-filter-row mt-10 mx-2">
    <div class="product-filter-field mx-1">
        <mat-form-field appearance="fill">
            <input formControlName="prTitle" matInput type="search" placeholder="Title">
            <mat-icon class="search-action-icon" matSuffix>search</mat-icon>
        </mat-form-field>
    </div>

    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <mat-select formControlName="statusDescription" placeholder="Select statuses"  >
            <mat-option *ngFor="let status of statuses" (click)="selectionStatusChange(allS)" [value]="status.statusDescription">{{status.statusDescription}}</mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    <div class="product-filter-clear-btn">
        <button (click)="clearFilter()" mat-flat-button color="accent">Clear</button>
    </div>
</form>

  <div class="grid-action-btn-bar">
      <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary">
          <i class="material-icons">
              insert_drive_file
          </i>
      </button>
      <button [disabled]="isRowSelected" class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="actionMenu">Actions</button>
    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item  *ngFor="let status of filterStatuses" (click)="updatePurchaseOrderStatus(status.statusId)">{{status.statusDescription==='Approved'? 'Approve' : 'Reject' }}</button>
    </mat-menu>
  </div>

  <div class="all-show-grid">
      <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
        toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
        [columnDefs]="gridColumnDefs" [rowData]="dataSource" (cellClicked)="cellClicked($event)"
        [pagination]="true" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection"
        [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" (gridReady)="onGridReady($event)"
         #bidsGrid>
      </ag-grid-angular>
  </div>

</div>

<div id="poInvoice"  class="container" hidden>
  <div class="row">
    <div class="col-6" >
      <img src="/assets/images/algo-user-logo.png" style="width:100px">
    </div>
    <div class="col-6">

       <p class="text-center"><span class=" font-weight-bold h5" >Algomus &nbsp; Limited </span></p>
      <p class="text-center">Office 1103, DHA business Hub</p>
       <p class="text-center">DHA Phase 8, Lahore. </p>
     <p class="text-center"><b>Ph:</b>  042-3661 0210  <b>Fax:</b>  042-3665 3043</p>

           </div>
    </div>
    <div class="row">
      <p class="col-12 text-center font-weight-bold h5">Purchase &nbsp; Order</p>
    </div>
    <div class="row">
      <p class="col-12 text-left font-weight-bold h6">Vendor: &nbsp; {{this.orderSummary?.vendorName}}</p>
    </div>

<div class="row">
    <table class="mt-3 col-12 itemTable ">
     <thead>
      <tr>
        <th>Item Name</th>
        <th>Specifications</th>
        <th>Quanity</th>
        <th>Total Price</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of this.prItems">
        <td>{{item?.itemName}}</td>
        <td>{{item?.itemSpecifications}}</td>
        <td>{{item?.quantity}}</td>
        <td>100</td>
    </tr>
  </tbody>
  <tfoot>
<tr>
  <td></td>
  <td></td>
  <td></td>
  <td>
    <pre>
     <b> Sum: {{this.orderSummary?.totalAmount}}
      Tax: {{this.orderSummary?.taxRate}} %
      Payable after tax deduction: {{this.payableAfterTax}}
    </b>
    </pre>
  </td>

</tr>
  </tfoot>
  </table>
</div>

<div class="row mt-5">
  <p class="col-12">
   <b>Approved by: Admin Algo</b>
  </p>
  <hr style="width:20%;text-align:left;margin-left:0">
</div>

<div class="row">
<p class="col-12 font-weight-bold text-center">Please&nbsp; deliver&nbsp; the above&nbsp; mentioned&nbsp; item(s) at Office 1103, DHA business&nbsp;&nbsp; Hub, DHA Phase 8, Lahore&nbsp; within days</p>
</div>
<div class="row">
  <p class="col-12 text-center">This is a system generated document and does not require signature.</p>
</div>
<div class="row">
<p class="col-12 text-center">----End of Document----</p>
</div>

</div>
