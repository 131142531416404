<h1 mat-dialog-title>Bids For PR-{{prId}}</h1>
<form *ngIf="bidForm" mat-dialog-content [formGroup]="bidForm">
  <hr class="theme-hr" />
  <div class="w-100" formArrayName="bids">
    <div class="row m-0" *ngFor="let item of bidsArray.controls; let i=index" [formGroupName]="i">
      <div class="col-6">
        <h4>Bid {{i + 1}}</h4>
      </div>
      <div *ngIf="this.bidForm.value.bids[0]" class="col-3">
        <button class="mr-3" *ngIf="this.data.viewDetails && this.bidsCreated && cClaim.PMSClaims.U_TYPE3"
          (click)="updateBidStatus('approved',i)" mat-stroked-button color="accent"
          (click)="onNoClick()">Approve</button>
        <button *ngIf="this.data.viewDetails && this.bidsCreated && cClaim.PMSClaims.U_TYPE3"
          (click)="updateBidStatus('rejected',i)" mat-stroked-button color="accent"
          (click)="onNoClick()">Reject</button>
      </div>
      <div class="col-3"></div>
      <div class="col-md-3">
        <strong>Vendor Name*</strong>
        <mat-form-field appearance="fill">
          <mat-select placeholder="Select" formControlName="vendorId" (selectionChange)="vendorChanged(i)">
            <mat-option *ngFor="let vendor of vendorsList" [value]="vendor.vendorId">{{ vendor.vendorName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <mat-checkbox formControlName="isTaxable" (change)="onChange($event)">Tax Required</mat-checkbox>
      </div>
      <div class="col-md-3">
        <strong>Enter NTN#*</strong>
        <mat-form-field appearance="fill">
          <input placeholder="#123456" matInput formControlName="ntnNumber">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <strong>Tax %*</strong>
        <mat-form-field appearance="fill">
          <mat-select placeholder="Select" formControlName="taxRate">
            <mat-option value="2.5">2.5%</mat-option>
            <mat-option value="4.5">4.5%</mat-option>
            <mat-option value="7">7%</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex align-items-center">
        <mat-icon *ngIf="!this.data.viewDetails" color="warn" (click)="removeItem(i)" class="cursor-pointer"
          matTooltip="Remove Bid">delete</mat-icon>
      </div>
      <div formArrayName="bidDetails" class="w-100">
        <div class="row m-0">
          <div class="col-1">
            <strong>ID</strong>
          </div>
          <div class="col-2">
            <strong>Item Name</strong>
          </div>
          <div class="col-2">
            <strong>Specifications</strong>
          </div>
          <div class="col-1">
            <strong>Qty</strong>
          </div>
          <div class="col-2">
            <strong>U. Price</strong>
          </div>
          <div class="col-2">
            <strong>Quote Price</strong>
          </div>
          <div class="col-2">
            <strong>Total Price</strong>
          </div>
        </div>
        <div class="row m-0" *ngFor="let item of bidsArray.controls[i].get('bidDetails').controls; let j=index"
          [formGroupName]="j">
          <div class="col-md-1">
            <mat-form-field appearance="fill">
              <input matInput formControlName="PRItemId">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="fill">
              <mat-select placeholder="Select" formControlName="PRItemId">
                <mat-option *ngFor="let item of itemsList" [value]="item.itemId">{{ item.itemName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="fill">
              <input matInput formControlName="itemSpecifications">
            </mat-form-field>
          </div>
          <div class="col-md-1">
            <mat-form-field appearance="fill">
              <input matInput formControlName="quantity">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="fill">
              <input matInput formControlName="unitPrice">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="fill">
              <input matInput formControlName="priceQuoted">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="fill">
              <input matInput formControlName="totalPrice">
            </mat-form-field>
          </div>
          <div class="col-md-5">
            <mat-form-field appearance="fill">
              <input matInput formControlName="reason">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-12">
        <hr class="theme-hr" />
      </div>
    </div>
  </div>
</form>
<div mat-dialog-actions class="justify-content-between">
  <button *ngIf="!this.data.viewDetails" mat-raised-button color="accent" (click)="addBid()">Add More Bids</button>
  <div>
    <div>
      <button *ngIf="!this.data.viewDetails" mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
      <button *ngIf="this.data.viewDetails" mat-stroked-button color="accent" (click)="onNoClick()">Close</button>
      <button *ngIf="!this.data.viewDetails" mat-raised-button color="accent" (click)="onSaveClick()"
        [disabled]="bidForm?.invalid">Submit</button>
    </div>
  </div>
</div>
