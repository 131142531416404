import { CombinationSetupComponent } from './../modules/forecast-engin/features/combination-manager/combination-setup/combination-setup.component';
import { ForecastEngineLocationManagerComponent } from './../modules/forecast-engin/features/location-manager/forecast-engine-location-manager.component';
import { LocationSetupComponent } from './../modules/forecast-engin/features/location-manager/location-setup/location-setup.component';
import { ItemSetupComponent } from './../modules/forecast-engin/features/item-manager/item-setup/item-setup.component';
import { ForecastSetupComponent } from './../modules/forecast-engin/features/forecast-manager/forecast-setup/forecast-setup.component';
import { ForecastManagerComponent } from '../modules/forecast-engin/features/forecast-manager/forecast-manager.component';
import { AdminSettingsComponent } from "src/modules/auth/features/admin-settings/admin-settings.component";
import { ResetPasswordComponent } from "src/modules/auth/features/login/reset-password/reset-password.component";
import { CreateUpdateRoleComponent } from "src/modules/auth/features/roles/create-update-role/create-update-role.component";
import { RolesComponent } from "src/modules/auth/features/roles/roles.component";
import { CreateUpdateTeamComponent } from "src/modules/auth/features/teams/create-update-team/create-update-team.component";
import { CurrencyComponent } from "src/modules/auth/features/teams/currency/currency.component";
import { FinanceComponent } from "src/modules/auth/features/teams/finance/finance.component";
import { TeamsComponent } from "src/modules/auth/features/teams/teams.component";
import { CreateUpdateUserComponent } from "src/modules/auth/features/users/create-update-user/create-update-user.component";
import { UsersComponent } from "src/modules/auth/features/users/users.component";
import { ClusteringForecastComponent } from "src/modules/clustering-forecast/clustering-forecast.component";
import { PlanogramOptimizerComponent } from "src/modules/item-manager/features/planogram-optimizer/planogram-optimizer.component";
import { ItemManagerComponent } from "src/modules/item-manager/item-manager.component";
import { ActivityComponent } from "src/modules/layout/features/activity/activity.component";
import { AlgoMenuComponent } from "src/modules/layout/features/algo-menu/algo-menu.component";
import { AnomolyDetectorFormComponent } from "src/modules/layout/features/anomoly-detector-form/anomoly-detector-form.component";
import { InsightListingV2Component } from "src/modules/layout/features/app-insight-v2/insight-listing-v2/insight-listing-v2.component";
import { InsightListingComponent } from "src/modules/layout/features/app-insight/insight-listing/insight-listing.component";
import { NotificationConfigurationComponent } from "src/modules/layout/features/notification-configuration/notification-configuration.component";
import { SuggestAdminComponent } from "src/modules/layout/features/suggest-admin/suggest-admin.component";
import { UserInterfaceComponent } from "src/modules/layout/features/user-interface/user-interface.component";
import { ChatDetailsComponent } from "src/modules/messaging/chat-details/chat-details.component";
import { DynamicReportComponent } from "src/modules/reporting/features/dynamic-report/dynamic-report.component";
import { QNAReportComponent } from "src/modules/reporting/features/qna-report/qna-report.component";
import { ReportingComponent } from "src/modules/reporting/reporting.component";
import {
  ItemApprovalDetailComponent,
  ItemListComponent,
  ItemPromotionDetailComponent,
  ItemPromotionListComponent,
  MasterItemListComponent,
  BBNewItemPlannerComponent,
  PromotionPlannerComponent,
  PromotionPlannerDetailComponent
} from '../modules/item-manager/features';
import { FileManagementComponent } from "src/modules/clustering-forecast/file-management/file-management.component";
import {
  AddEditMappingForecastItemComponent,
  EditSeasonalityComponent,
  ForecastDataManagerComponent,
  ForecastEngineCombinationManagerComponent,
  ForecastEngineItemManagerComponent,
  HierarchyMetadataManagerComponent,
  HierarchySetupComponent,
  HierarchySetupWhiteLabelingComponent,
  MappingManagerComponent,
  OutlierManagerComponent,
  PromoManagerComponent,
  PromoSetupDetailComponent,
  SeasonalityManagerComponent,
  SeasonalitySetupComponent
} from "src/modules/forecast-engin/features";
import {
  DemoBBNewItemPlannerComponent,
  DemoItemApprovalDetailComponent,
  DemoItemPromotionDetailComponent,
  DemoItemPromotionListComponent,
  DemoMasterChildItemListComponent,
  DemoMasterItemListComponent
} from 'src/modules/demo-manager/features';
import { SegmentationComponent } from 'src/modules/segmentation/segmentation.component';
import {
PmsDashboardComponent,
PmsItemsComponent,
PmsLocationsComponent,
PmsVendorsComponent,
PmsViewPurchaseRequestComponent,
PmsPurchaseOrdersComponent,
PmsBidsComponent,
PmsGrnComponent,
PmsEvaluationVendorComponent,
PmsAddEditBidComponent
} from "src/modules/procurement";
import { ClientsComponent } from 'src/modules/auth/features/clients/clients.component';
import { TabConfigurationComponent } from 'src/modules/auth/features/tab-configuration/tab-configuration.component';


export class Page {
  menuId: string | number;
  name: string;
  selector: string;
  module: string;
  [key: string]: any;
}
export const Pages: Page[] = [
  {
    menuId: '4',
    name: 'Change Password',
    selector: 'app-reset-password',
    module: 'Auth',
    component: ResetPasswordComponent,
  },
  {
    menuId: '5',
    name: 'activity',
    selector: 'app-activity',
    module: 'app',
    component: ActivityComponent,
  },
  {
    menuId: '6',
    name: 'roles',
    selector: 'app-roles',
    module: 'Auth',
    component: RolesComponent,
  },
  {
    menuId: '14',
    name: 'Menus',
    selector: 'app-algo-menu',
    module: 'app',
    component: AlgoMenuComponent,
  },
  {
    menuId: '39',
    name: 'Users',
    selector: 'app-users',
    module: 'Auth',
    component: UsersComponent,
  },
  {
    menuId: '40',
    name: 'Announcement Configuration',
    selector: 'app-notification-configuration',
    module: 'app',
    component: NotificationConfigurationComponent,
  },
  {
    menuId: '45',
    name: 'User Interface Activity',
    selector: 'app-user-interface',
    displayName: 'User Interface Activity',
    module: 'app',
    component: UserInterfaceComponent,
  },
  {
    menuId: '74',
    name: 'Anomoly Detector',
    selector: 'app-anomoly-detector-form',
    displayName: 'Anomoly Detector',
    module: 'app',
    component: AnomolyDetectorFormComponent,
  },
  {
    menuId: '74',
    name: 'Anomoly Detector',
    selector: 'app-dynamic-report',
    displayName: 'Anomoly Detector',
    module: 'ReportingModule',
    component: DynamicReportComponent,
  },
  {
    menuId: '75',
    name: 'QnA Report',
    selector: 'app-qna-report',
    displayName: 'QnA Report',
    module: 'ReportingModule',
    component: QNAReportComponent,
  },
  {
    menuId: '87',
    name: 'Application Insight',
    selector: 'app-insight-listing',
    displayName: 'Application Insight',
    module: 'app',
    component: InsightListingComponent,
  },
  {
    menuId: '91',
    name: 'Suggest Admin',
    selector: 'app-suggest-admin',
    displayName: 'Suggest Admin',
    module: 'app',
    component: SuggestAdminComponent,
  },
  {
    menuId: '89',
    name: 'Application Insight V2',
    selector: 'app-insight-listing-v2',
    displayName: 'Application Insight V2',
    module: 'app',
    component: InsightListingV2Component,
  },
  {
    menuId: '91',
    name: 'Admin Settings',
    selector: 'app-admin-settings',
    displayName: 'Admin Settings',
    module: 'app',
    component: AdminSettingsComponent,
  },
  {
    menuId: '92',
    name: 'Role Setting',
    selector: 'app-create-update-role',
    displayName: 'Role Setting',
    module: 'Auth',
    component: CreateUpdateRoleComponent,
  },
  {
    menuId: '93',
    name: 'User Setting',
    selector: 'app-create-update-user',
    displayName: 'User Setting',
    module: 'Auth',
    component: CreateUpdateUserComponent,
  },
  {
    menuId: '94',
    name: 'Team Setting',
    selector: 'app-create-update-team',
    displayName: 'User Setting',
    module: 'Auth',
    component: CreateUpdateTeamComponent,
  },
  {
    menuId: '95',
    name: 'Teams',
    selector: 'app-teams',
    displayName: 'Teams',
    module: 'Auth',
    component: TeamsComponent,
  },
  {
    menuId: '96',
    name: 'Currency Setting',
    selector: 'app-currency',
    displayName: 'Currency Setting',
    module: 'Auth',
    component: CurrencyComponent,
  },
  {
    menuId: '97',
    name: 'Finance Setting',
    selector: 'app-finance',
    displayName: 'Finance Setting',
    module: 'Auth',
    component: FinanceComponent,
  },
  {
    menuId: '20',
    name: 'Item Manager',
    selector: 'app-item-manager',
    displayName: 'Item Manager',
    module: 'ItemManagerModule',
    component: ItemManagerComponent
  },
  {
    menuId: '21',
    name: 'Master Item List',
    selector: 'app-master-item-list',
    displayName: 'Item Manager',
    module: 'ItemManagerModule',
    component: MasterItemListComponent
  },
  {
    menuId: '22',
    name: 'Item List',
    selector: 'app-item-list',
    displayName: 'Item List',
    module: 'ItemManagerModule',
    component: ItemListComponent
  },
  {
    menuId: '38',
    name: 'New Item Planner',
    selector: 'app-new-item-planner',
    displayName: 'New Item Planner',
    module: 'ItemManagerModule',
    component: BBNewItemPlannerComponent
  },
  {
    menuId: '39',
    name: 'New Item Promotions',
    selector: 'app-item-promotion-list',
    displayName: 'New Item Promotions',
    module: 'ItemManagerModule',
    component: ItemPromotionListComponent
  },
  {
    menuId: '399',
    name: 'New Item Promotion Detail',
    selector: 'app-item-promotion-detail',
    displayName: 'New Item Promotions',
    module: 'ItemManagerModule',
    component: ItemPromotionDetailComponent
  },
  {
    menuId: '40',
    name: 'New Item Approvals',
    selector: 'app-item-approval-detail',
    displayName: 'New Item Approvals',
    module: 'ItemManagerModule',
    component: ItemApprovalDetailComponent
  },
  {
    menuId: '59',
    name: 'Promotion Planner',
    selector: 'app-promotion-planner',
    displayName: 'Promotion Planner',
    module: 'ItemManagerModule',
    component: PromotionPlannerComponent
  },
  {
    menuId: '399',
    name: 'Promotion Planner',
    selector: 'app-promotion-planner-detail',
    displayName: 'Promotion Planner',
    module: 'ItemManagerModule',
    component: PromotionPlannerDetailComponent
  },
  {
    menuId: '69',
    name: 'Planogram Optimizer',
    selector: 'app-planogram-optimizer',
    displayName: 'Planogram Optimizer',
    module: 'ItemManagerModule',
    component: PlanogramOptimizerComponent
  },
  {
    menuId: '70',
    name: 'Self-Service Clustering',
    selector: 'app-clustering-forecast',
    displayName: 'Self-Service Clustering',
    module: 'ClusteringForecastModule',
    component: ClusteringForecastComponent
  },
  {
    menuId: '71',
    name: 'Files & Data Management',
    selector: 'app-file-data-management',
    displayName: 'Files & Data Management',
    module: 'ClusteringForecastModule',
    component: FileManagementComponent
  },
  {
    menuId: '188',
    name: 'Chat Details',
    selector: 'app-chat-details',
    displayName: 'Chat Details',
    module: 'MessagingModule',
    component: ChatDetailsComponent
  },
  {
    menuId: '316',
    name: 'Outlier Manager',
    selector: 'app-outlier-manager',
    module: 'ForecastEnginModule',
    component: OutlierManagerComponent,
  },
  {
    menuId: '415',
    name: 'Mapping Manager',
    selector: 'app-mapping-manager',
    module: 'ForecastEnginModule',
    component: MappingManagerComponent,
  },
  {
    menuId: '446',
    name: 'Promotion Planner',
    selector: 'app-promo-manager',
    module: 'ForecastEnginModule',
    component: PromoManagerComponent,
  },
  {
    menuId: '447',
    name: 'Forecast Engine Item Manager',
    selector: 'app-forecast-engine-item-manager',
    module: 'ForecastEnginModule',
    component: ForecastEngineItemManagerComponent,
  },
  {
    menuId: '448',
    name: 'Item Setup',
    selector: 'app-item-setup',
    module: 'ForecastEnginModule',
    component: ItemSetupComponent,
  },
  {
    menuId: '449',
    name: 'Promo Details',
    selector: 'app-promo-setup-detail',
    displayName: 'Promo Details',
    module: 'ForecastEnginModule',
    component: PromoSetupDetailComponent
  },
  {
    menuId: '453',
    name: 'Location Setup',
    selector: 'app-location-setup',
    module: 'ForecastEnginModule',
    component: LocationSetupComponent,
  },
  {
    menuId: '452',
    name: 'Seasonality Manager',
    selector: 'app-seasonality-manager',
    displayName: 'Seasonality Manager',
    module: 'ForecastEnginModule',
    component: SeasonalityManagerComponent
  },
  {
    menuId: '454',
    name: 'Seasonality Editor',
    selector: 'app-edit-seasonality',
    displayName: 'Seasonality Editor',
    module: 'ForecastEnginModule',
    component: EditSeasonalityComponent
  },
  {
    menuId: '455',
    name: 'Forecast Manager',
    selector: 'app-forecast-manager',
    displayName: 'Forecast Manager',
    module: 'ForecastEnginModule',
    component: ForecastManagerComponent
  },
  {
    menuId: '456',
    name: 'Forecast Data Manager',
    selector: 'app-forecast-data-manager',
    displayName: 'Forecast Data Manager',
    module: 'ForecastEnginModule',
    component: ForecastDataManagerComponent
  },
  {
    menuId: '457',
    name: 'Forecast Engine Location Manager',
    selector: 'app-forecast-engine-location-manager',
    module: 'ForecastEnginModule',
    component: ForecastEngineLocationManagerComponent,
  },
  {
    menuId: '458',
    name: 'Forecast Engine Combination Manager',
    selector: 'app-forecast-engine-combination-manager',
    module: 'ForecastEnginModule',
    component: ForecastEngineCombinationManagerComponent,
  },
  {
    menuId: '459',
    name: 'Forecast setup ',
    selector: 'app-forecast-setup',
    displayName: 'Forecast setup ',
    module: 'ForecastEnginModule',
    component: ForecastSetupComponent
  },
  {
    menuId: '460',
    name: 'Combination setup ',
    selector: 'app-combination-setup',
    displayName: 'Combination setup ',
    module: 'ForecastEnginModule',
    component: CombinationSetupComponent
  },
  {
    menuId: '21',
    name: 'Master Item List',
    selector: 'app-demo-master-item-list',
    displayName: 'Item Manager',
    module: 'DemoManagerModule',
    component: DemoMasterItemListComponent
  },
  {
    menuId: '22',
    name: 'Item List',
    selector: 'app-demo-master-child-item-list',
    displayName: 'Item List',
    module: 'DemoManagerModule',
    component: DemoMasterChildItemListComponent
  },
  {
    menuId: '38',
    name: 'New Item Planner',
    selector: 'app-demo-new-item-planner',
    displayName: 'New Item Planner',
    module: 'DemoManagerModule',
    component: DemoBBNewItemPlannerComponent
  },
  {
    menuId: '39',
    name: 'New Item Promotions',
    selector: 'app-demo-item-promotion-list',
    displayName: 'New Item Promotions',
    module: 'DemoManagerModule',
    component: DemoItemPromotionListComponent
  },
  {
    menuId: '399',
    name: 'New Item Promotion Detail',
    selector: 'app-demo-item-promotion-detail',
    displayName: 'New Item Promotions',
    module: 'DemoManagerModule',
    component: DemoItemPromotionDetailComponent
  },
  {
    menuId: '40',
    name: 'New Item Approvals',
    selector: 'app-demo-item-approval-detail',
    displayName: 'New Item Approvals',
    module: 'DemoManagerModule',
    component: DemoItemApprovalDetailComponent
  },
  {
    menuId: '444',
    name: 'Segmentation',
    selector: 'app-data-segmentation',
    displayName: 'Segmentation',
    module: 'SegmentationModule',
    component: SegmentationComponent
  },
  {
    menuId: '462',
    name: 'Item Mapping Setup',
    selector: 'app-add-edit-mapping-forecast-item',
    displayName: 'Item Mapping Setup',
    module: 'ForecastEnginModule',
    component: AddEditMappingForecastItemComponent
  },
  {
    menuId: '463',
    name: 'Client Manager',
    selector: 'app-clients',
    module: 'Auth',
    component: ClientsComponent,
  },
  {
    menuId: '464',
    name: 'Forecast White Labeling',
    selector: 'app-hierarchy-setup-white-labeling',
    module: 'ForecastEnginModule',
    component: HierarchySetupWhiteLabelingComponent,
  },
  {
    menuId: '465',
    name: 'Hierarchy Setup',
    selector: 'app-hierarchy-setup',
    module: 'ForecastEnginModule',
    component: HierarchySetupComponent,
  },
  {
    menuId: '465',
    name: 'Hierarchy Manager',
    selector: 'app-hierarchy-metadata-manager',
    module: 'ForecastEnginModule',
    component: HierarchyMetadataManagerComponent,
  },
  {
    menuId: '466',
    name: 'Tab Configuration',
    selector: 'app-tab-configuration',
    module: 'Auth',
    component: TabConfigurationComponent,
  },
  {
    menuId: '545',
    name: 'Dashboard',
    selector: 'pms-dashboard',
    displayName: 'Dashboard',
    module: 'ProcurementModule',
    component: PmsDashboardComponent
  },
  {
    menuId: '546',
    name: 'Items',
    selector: 'pms-items',
    displayName: 'Items',
    module: 'ProcurementModule',
    component: PmsItemsComponent
  },
  {
    menuId: '547',
    name: 'Locations',
    selector: 'pms-locations',
    displayName: 'Locations',
    module: 'ProcurementModule',
    component: PmsLocationsComponent
  },
  {
    menuId: '548',
    name: 'Vendors',
    selector: 'pms-vendors',
    displayName: 'Vendors',
    module: 'ProcurementModule',
    component: PmsVendorsComponent
  },

  {
    menuId: '550',
    name: 'View Purchase Requests',
    selector: 'pms-view-purchase-request',
    displayName: 'View Purchase Requests',
    module: 'ProcurementModule',
    component: PmsViewPurchaseRequestComponent
  },
  {
    menuId: '551',
    name: 'Bids',
    selector: 'pms-bids',
    displayName: 'Bids',
    module: 'ProcurementModule',
    component: PmsBidsComponent
  },
  {
    menuId: '552',
    name: 'Purchase Orders',
    selector: 'pms-purchase-orders',
    displayName: 'Purchase Orders',
    module: 'ProcurementModule',
    component: PmsPurchaseOrdersComponent
  },
  {
    menuId: '553',
    name: 'GRN',
    selector: 'pms-grn',
    displayName: 'GRN',
    module: 'ProcurementModule',
    component: PmsGrnComponent
  },
  {
    menuId: '603',
    name: 'Vendor Evaluation',
    selector: 'pms-evaluation-vendor',
    displayName: 'Vendor Evaluation',
    module: 'ProcurementModule',
    component: PmsEvaluationVendorComponent
  },
];
export const MenuList = Pages;
