<ng-container *ngIf="allSegments.length">

    <app-summary-statistics-segmentaion [isOutputTab]="false" [sampleData]="sampleData" [allSegments]="allSegments"></app-summary-statistics-segmentaion>

    <app-segmentation-graphs [isOutputTab]="false" [resizeGraphSubject]="resizeGraphSubject" [sampleData]="sampleData" [allSegments]="allSegments"></app-segmentation-graphs>

    <app-memberships-table-segmentaion [isOutputTab]="false" [allSegments]="allSegments" [sampleData]="sampleData"></app-memberships-table-segmentaion>

    <div class="d-flex justify-content-end">
        <button mat-raised-button color="accent" class="mx-2">Generate Advanced Analytics
        </button>
    </div>
    
</ng-container>
