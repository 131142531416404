import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataModuleService {

  constructor(private httpClient: HttpClient) { }

  fetchFileDataById(fileName, userId, page = 1, faulty = false, blanks = false, duplicates = false, outliers=false, exceptions = false, version = 0, valid = false) {
    return this.httpClient.get(`${environment.FileManagementURL}/user-data-upload/?user_id=${userId}&file_name=${fileName}&page=${page}&faulty=${faulty}&blanks=${blanks}&duplicate=${duplicates}&outliers=${outliers}&exceptions=${exceptions}&version=${version}&valid_data=${valid}`);
  }

  deleteSelectedRows(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/delete-user-file-data/`, obj);
  }

  fetchCorrelationMatrix(fileName: string, userId: number, versionNo = 0) {
    return this.httpClient.get(`${environment.FileManagementURL}/file-correlation-data/?file_name=${fileName}&user_id=${userId}&version_no=${versionNo}`)
  }

  updateCorrelationValue(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/file-correlation-data/`, obj)
  }

  fetchSummaryStats(fileName: string, userId: number, versionNo = 0) {
    return this.httpClient.get(`${environment.FileManagementURL}/file-summary-statistics/?file_name=${fileName}&user_id=${userId}&version_no=${versionNo}`)
  }

  resetToOriginal(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/reset-to-original/`, obj);
  }  
  
  replaceBlanksWithZeros(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/replace-blanks/`, obj);
  }

  saveExceptionRules(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/manage-exceptions/`, obj);
  }

  fetchExceptionRules(fileName: string, userId: number, versionNo = 0) {
    return this.httpClient.get(`${environment.FileManagementURL}/manage-exceptions/?file_name=${fileName}&user_id=${userId}&version_no=${versionNo}`);
  }

  saveTransformationRules(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/manage-transformation/`, obj);
  }

  fetchTransformationRules(fileName: string, userId: number, versionNo = 0) {
    return this.httpClient.get(`${environment.FileManagementURL}/manage-transformation/?file_name=${fileName}&user_id=${userId}&version_no=${versionNo}`);
  }

  updateGridData(obj = {}) {
    return this.httpClient.put(`${environment.FileManagementURL}/user-data-upload/`, obj);
  }

  fetchOutliersData(fileName: string, userId: number, versionNo = 0) {
    return this.httpClient.get(`${environment.FileManagementURL}/manage-outliers/?file_name=${fileName}&user_id=${userId}&version_no=${versionNo}`)
  }

  updateOutlierData(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/manage-outliers/`, obj);
  }

  updateFileStats(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/update-stats/`, obj);
  }

  saveAsNewVersion(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/save-data-version/`, obj);
  }

  fetchVersionDetails(fileName: string, userId: number, versionNo = 0) {
    return this.httpClient.get(`${environment.FileManagementURL}/get-version-details/?file_name=${fileName}&user_id=${userId}&version_no=${versionNo}`)
  }

}
