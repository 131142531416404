export function generateDenogramTicks(xData, yData) {
    const dataOutput = [];
    yData.forEach((element: Array<number>, index) => {
        if (element[0] == 0) {
            dataOutput.push(xData[index][0]);
        }
        if (element[3] == 0) {
            dataOutput.push(xData[index][3]);
        }
    });
    return dataOutput;
}

export const generateGridColumns = (dataArray: [], editable = true) => {
    const gridColumnDefs = [];
    dataArray.forEach((element: any) => {
        const headerObj: any = {
            headerName: editable ? element.displayName : element,
            field: editable ? element.name : element,
            minWidth: 40,
            resizable: true,
            editable: editable,
            cellEditor: element.is_matric ? 'numberCellEditor' : '',
            isMetricVariable: !editable ? true : element.is_matric,
            cellStyle: params => {
                // HIGHLIGHTING THE CELL IN CASE OF FAULT
                if (params.data.fault_columns
                    && params.data.fault_columns.length
                    && params.colDef.isMetricVariable
                    && editable) {
                    if (params.data.fault_columns.indexOf(params.column.colId) > -1) {
                        return { color: 'white', backgroundColor: 'red' };
                    }
                }
            }
        };
        gridColumnDefs.push(headerObj);
    });
    return gridColumnDefs;
}
