<div *ngIf="userTeam !== INVALID_TEAM" class="container-fluid item-approval-page" style="margin-top: 15px;">

  <app-demo-item-approval-filter (filterEvent)="applyFilters($event)"></app-demo-item-approval-filter>

  <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="setTabIndex($event)">
    <mat-tab label="Item Detail">
      <!-- Item Detail Action Buttons -->
      <div class="row tab-header-action-button">
        <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
          <div class="btn-show">
            <button *ngIf="isItemDetailGridDataUpdated" class="grid-bar-icon" type="button" mat-button mat-flat-button
              color="accent" (click)="saveUpdatedItemDetailGridData($event)">
              Save
            </button>
            <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
              (click)="openItemDetailGridColumnDialog()">
              <i class="material-icons">
                ballot
              </i>
            </button>
            <button matTooltip="Export" class="grid-bar-icon" mat-icon-button color="primary"
              (click)="onExportItemDetailGridData()">
              <i class="material-icons">
                insert_drive_file
              </i>
            </button>

            <button class="import-export-button" mat-flat-button color="accent"
              [matMenuTriggerFor]="actionsMenu">Actions</button>
            <mat-menu #actionsMenu="matMenu">
              <button mat-menu-item (click)="openActionsDialog('Accepted')">Accept</button>
              <button mat-menu-item (click)="openActionsDialog('Rejected')">Reject</button>
              <button mat-menu-item (click)="openActionsDialog('Modified')">Modify</button>
              <button mat-menu-item (click)="openActionsDialog('EOL')">EOL</button>
            </mat-menu>

          </div>
        </div>

      </div>
      <!-- End Item Detail Action Buttons -->
      <!-- [overlayLoadingTemplate]="overlayLoadingTemplate" -->
      <div class="all-show-grid" *ngIf="isItemDataLoaded">
        <ag-grid-angular class="ag-theme-material" [sideBar]="false" [suppressMenuHide]="true"
          [rowSelection]="rowSelection" [groupDefaultExpanded]="groupDefaultExpanded"
          [autoGroupColumnDef]="itemDetailAutoGroupColumnDef" (selectionChanged)="itemGridRowSelectionChanged($event)"
          [editType]="editType" [stopEditingWhenCellsLoseFocus]="true"
          (cellValueChanged)="itemDetailGridDataUpdated($event)" [groupSelectsChildren]="true"
          [groupMaintainOrder]="true" [groupSuppressBlankHeader]="true" [groupSelectsFiltered]="true"
          [suppressRowClickSelection]="true" [gridOptions]="itemDetailGridOptions" [components]="components"
          [columnDefs]="itemDetailGridColumnDefs" [defaultColDef]="defaultColDef" [rowData]="itemDetailGridData"
          (gridReady)="onItemDetailGridReady($event)"  [id]="itemDetailGridId"
          (cellKeyDown)="onCellKeyDown($event, itemDetailGridApi)">
        </ag-grid-angular>
      </div>
    </mat-tab>

    <mat-tab label="Weekly Forecast">
      <!-- Weekly Forecast Action Buttons -->
      <div class="row tab-header-action-button">
        <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
          <div class="btn-show">
            <button *ngIf="isForecastGridDataUpdated" class="grid-bar-icon" type="button" mat-button mat-flat-button
              color="accent" (click)="saveUpdatedForecastGridData($event)">
              Save
            </button>

            <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
              (click)="openForecastGridColumnDialog()">
              <i class="material-icons">
                ballot
              </i>
            </button>
            <button matTooltip="Export" class="grid-bar-icon" mat-icon-button color="primary"
              (click)="onExportForecastGridData()">
              <i class="material-icons">
                insert_drive_file
              </i>
            </button>

            <button class="import-export-button" *ngIf="isRetailerTeam" mat-flat-button color="accent"
              [matMenuTriggerFor]="importExportMenu" (click)="handleExport($event)">Export/Import</button>
            <mat-menu #importExportMenu="matMenu">
              <button mat-menu-item (click)="exportNewItemWeeklyDataTemplate()">Export</button>
              <button mat-menu-item (click)="importNewItemWeeklyData()">Import
                <input class="hidden" type="file" id="upload-new-item-approval-promotion-data"
                  name="upload-new-item-approval-promotion-data" accept=".csv" (change)="validateUploadedCSV($event)">
              </button>
            </mat-menu>

          </div>
        </div>
      </div>
      <!-- End Weekly Forecast Action Buttons -->

      <div class="all-show-grid" *ngIf="isForecastDataLoaded">
        <!-- [overlayLoadingTemplate]="overlayLoadingTemplate" -->
        <ag-grid-angular class="ag-theme-material forecast-grid" [sideBar]="false" [suppressMenuHide]="true"
          groupHeaders toolPanelSuppressGroups toolPanelSuppressValues [components]="components"
          [gridOptions]="forecastGridOptions" [editType]="editType" [stopEditingWhenCellsLoseFocus]="true"
          [columnDefs]="forecastGridColumnDefs" [defaultColDef]="defaultColDef" [rowData]="forecastGridData"
          [pagination]="false" [rowSelection]="rowSelection" [paginationPageSize]="paginationPageSize"
          [cacheBlockSize]="cacheBlockSize" [groupDefaultExpanded]="groupDefaultExpanded"
          [autoGroupColumnDef]="forecastGridAutoGroupColumnDef" [groupSelectsChildren]="true"
          (gridReady)="onForecastGridReady($event)" (cellValueChanged)="forecastGridDataUpdated($event)"
           [id]="approvalForecastGridId" (cellKeyDown)="onCellKeyDown($event, forecastGridApi)">
        </ag-grid-angular>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<app-privilege-error *ngIf="userTeam === INVALID_TEAM"></app-privilege-error>
