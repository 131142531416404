import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { CLIENT_INFO, USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { CollaborationVM, JourneyCollaborations } from '../collaboration.model';
import { privilegeOptions } from 'src/modules/reporting/constant/privilegeOptions';
import { NgxSpinnerService } from 'ngx-spinner';
import { EditPermissionDialogComponent } from './edit-permission-dialog/edit-permission-dialog.component';

export interface UserModel {
  email: string;
  userId?: number;
}

@Component({
  selector: 'app-journey-share',
  templateUrl: './journey-share.component.html',
  styleUrls: ['./journey-share.component.scss'],
})
export class JourneyShareComponent implements OnInit {
  public userList = [];
  username: FormControl = new FormControl();

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: Observable<any[]>;
  selectedUsers: any[] = [];
  allUsers: any[] = [];

  displayedColumns = ['email', 'privilege', 'action'];
  dataSource = []; //this.ELEMENT_DATA;

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  isTableVisible: boolean = true;
  privilegeOptions = privilegeOptions;
  selectedPrivilege = new FormControl();

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<JourneyShareComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { journeyId: number; sharedBy: number, createdBy: number },
    public userService: UsersService,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) =>
        user ? this._filter(user) : this.allUsers.slice()
      )
    );
  }

  ngOnInit() {
    this.getUserList();
  }

  close() { this.dialogRef.close(); }

  public getUserList = () => {
    this.userService.GetAllUsers({ userTypeId: 1 }).subscribe((res) => {
      this.userList = (res as any).data;
      this.allUsers = this.userList;
      this.getCollaborations();
    });
  };

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our user
    if ((value || '').trim()) {
      const availableItem = this.allUsers.find((item) => {
        item.email.toLowerCase() === value?.toLowerCase();
      });
      this.selectedUsers.push(availableItem);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.userCtrl.setValue(null);
  }

  remove(user: { userId: number; email: string; privilege: string }): void {
    const index = this.selectedUsers.indexOf(user);

    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const foundElement = this.selectedUsers.find((item) => {
      return item?.email?.trim() === event?.option?.value?.email;
    });

    if (!foundElement) {
      this.selectedUsers.push(event.option.value);
    }

    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
  }

  private _filter(
    value: string | { email: string; userId: number }
  ): { email: string; userId: number }[] {
    const filterValue =
      typeof value === 'string'
        ? value.toLowerCase()
        : value.email.toLocaleLowerCase();

    return this.allUsers.filter(
      (user) => user.email.toLowerCase().indexOf(filterValue) === 0
    );
  }

  save() {
    if (!this.selectedPrivilege.value) {
      this.toastr.warning('', 'Please select the Privilege');
      return;
    }
    const collaboration: CollaborationVM = new CollaborationVM();
    this.selectedUsers.forEach((item: any) => {

      let errorMsg = 'Provided email already exists.';

      let existingItem = this.dataSource.find((_item) => {
        return _item.email.toLowerCase() === item?.email?.toLowerCase();
      });

      if (item.userId === this.storage.get(USER_ID)) {
        existingItem = true;
        errorMsg = 'Owner is already part of collaboration.';
      }

      if (!existingItem) {
        const shareVM: JourneyCollaborations = {
          JourneyId: this.data.journeyId,
          SharedBy: this.data.sharedBy,
          SharedWith: item.userId,
          CreatedAt: new Date(),
          ClientConfigurationId: this.storage.get(CLIENT_INFO).clientConfigurationId,
          Instance: this.document.baseURI,
          Privilege: this.selectedPrivilege.value
        };
        collaboration.journeyCollaborations.push(shareVM);
      } else {
        alert(errorMsg);
      }
    });

    if (collaboration?.journeyCollaborations?.length > 0) {
      this.spinner.show();
      this.configService
        .createJourneyCollaborations(collaboration)
        .subscribe((res) => {
          this.spinner.hide();
          this.selectedUsers = [];
          this.userInput.nativeElement.value = '';
          this.userCtrl.setValue(null);
          this.getCollaborations();
        });
    }
  }

  delete(element: any) {
    if (confirm(`Please confirm, you want to remove ${element.email}?`)) {
      this.spinner.show();
      this.configService
        .DeleteJourneyCollaboration(this.data.journeyId, element.userId)
        .subscribe((res: any) => {
          this.spinner.hide();
          const indexOfItem = this.dataSource.indexOf(element);
          this.dataSource.splice(indexOfItem, 1);
          this.dataSource = [...this.dataSource];
          this.toastr.success('Removed', 'Access Revoked Successfully.');
        });
    }
  }

  edit(element: any) {
    const dialogRef = this.dialog.open(EditPermissionDialogComponent, {
      restoreFocus: false,
      width: '600px',
      data: {
        isEdit: true,
        journey: element
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getCollaborations();
      }
    });
  }

  getCollaborations() {
    this.dataSource = [];
    this.configService
      .getJourneyCollaboration(null, null, this.data.journeyId)
      .subscribe((collaborations) => {
        collaborations.forEach((collaboration) => {
          let existingItem: any;
          const user = _.find(this.allUsers, a => a.userId === collaboration.sharedWith);
          if (user) {
            existingItem = user;
            existingItem.privilege = collaboration.privilege;
            existingItem.id = collaboration.id;
          }
          if (existingItem) {
            this.dataSource.push(existingItem);
            this.dataSource = [...this.dataSource];
          }
        });
      });
  }

  isOwner() {
    return this.storage.get(USER_ID) === this.data.createdBy;
  }
}
