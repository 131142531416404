import * as _ from 'lodash';
import * as  moment from 'moment';
import { saveAs } from 'file-saver';

export const getFilterModel = (gridFilters) => {
  const filterFields = _.keys(gridFilters);
  const filterModel: any = [];
  filterFields.forEach(field => {
    filterModel.push({
      field: field,
      filterType: gridFilters[field].filterType,
      type: gridFilters[field].type || gridFilters[field].filterType,
      filter: gridFilters[field].filter,
      filterValues: gridFilters[field].values,
      ...gridFilters[field].dateFrom && { dateFrom: gridFilters[field].dateFrom }
    });
  });
  return filterModel;
}

export const getSortModel = (gridSorting) => {
  const sortModel: any = [];
  gridSorting.forEach(field => {
    if (field.sort) {
      sortModel.push({
        field: field.colId,
        order: field.sort
      });
    }
  });
  return sortModel;
}


export const numberValueFormatter2Decimal = (params) => {
  if (params.value) {
    return Number(Number(params.value).toFixed(2));
  } else {
    return '';
  }
}


export const tooltipcellRenderer = (params) => {
  return params.value ? `<span title="${params.value}">${params.value}</span>` : '';
}

export const currencyRenderer = (params) => {
  return `${params.value ? '$' + Number(params.value).toFixed(2) : ''}`;
}

export const SASTokenParser = (sasToken) => {
  const params = sasToken.split('&');
  let queryString = '';
  params.forEach(param => {
    const keyValue = param.split('=');
    queryString = `${queryString}&${keyValue[0]}=${encodeURIComponent(keyValue.slice(1, keyValue.length).join('='))}`;
  });
  return queryString.slice(1);
}

export const calculateAgeByDate = (date: Date | string) => {
  try {

    const dateBy = new Date(moment.utc((date)).local().toDate());
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
    const oneMin = 60 * 1000; // Milliseconds in a minute
    const diffInMilliseconds = currentDate.getTime() - dateBy.getTime();

    if (diffInMilliseconds > oneDay) {
      let days = Math.round(Math.abs(diffInMilliseconds / oneDay));
      const years = Math.floor(days / 365);
      days = years >= 1 ? (days - (365 * years)) : days;
      const months = Math.floor(days / 30);
      days = months >= 1 ? (days - (30 * months)) : days;
      const weeks = Math.floor(days / 7);
      days = weeks >= 1 ? (days - (7 * weeks)) : days;
      return `${years >= 1 ? years + 'y,' : ''} ${months >= 1 ? months + 'm,' : ''} ${weeks >= 1 ? weeks + 'w' : ''} ${days + 'd'}`;
    } else {
      let minutes = Math.round(Math.abs(diffInMilliseconds / oneMin));
      const hours = Math.floor(minutes / 60);
      minutes = hours >= 1 ? (minutes - (60 * hours)) : minutes;
      return `${hours >= 1 ? hours + 'h,' : ''} ${minutes >= 1 ? minutes + 'min' : 'now'}`;
    }
  } catch (err) {
    return `Not a date`;
  }
};

export const datetimeLocalzone = (value) => {
  return `${value ? moment.utc(value).local().format('MM/DD/YYYY hh:mm:ss A') : ''}`;
}
export const convertBase64ToFile = (base64String, fileName) => {
  let arr = base64String.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let uint8Array = new Uint8Array(n);
  while (n--) {
     uint8Array[n] = bstr.charCodeAt(n);
  }
  let file = new File([uint8Array], fileName, { type: mime });
  return file;
}

export const downloadBase64Data = (base64String, fileName) => {
  let file = convertBase64ToFile(base64String, fileName);
  saveAs(file, fileName);
}

export const removeHTMLElement = (id: string) => {
  const elem = document.getElementById(id);
  return elem.parentNode.removeChild(elem);
}
