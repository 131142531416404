
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-insight-form',
  templateUrl: './insight-form.component.html',
  styleUrls: ['./insight-form.component.css']
})
export class InsightFormComponent implements OnInit, OnDestroy {
  public fg: FormGroup;
  public submitted = false;
  public countryList = [];
  public retailerList = [];
  public categoryList = [];
  public divisionList = [];
  public metricList = [];
  public metricDropdownList = [];
  public selectedMetricList = [];
  public files = [];
  public isEdit = false;
  public columnDefs = [
    {
      headerName: 'Metric',
      field: 'metricApplicationDescription'
    },
    {
      headerName: 'Value',
      field: 'value'
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    },
  ];
  public gridApi;
  public gridOptions;

  public rightFiles = [];
  public fileModel = {};
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<InsightFormComponent>,
    public matDialog: MatDialog,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public configService: ConfigurationService,
    public toast: NgxToasterService,
    public spinner: NgxSpinnerService,
    public storage: LocalstorageService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.gridOptions = {
      frameworkComponents: {
        deleteButtonRenderer: ButtonRendererComponent,
      },
      pagination: false,
      paginationAutoPageSize: false,
    };
    this.initializeForm();
  }

  ngOnInit() {
    this.getDefaultDropdowns();
    if (this.data && this.data.applicationInsightsId) {
      Object.keys(this.fg.controls).forEach(key => {
        this.fg.controls[key].setValue(this.data[key]);
      });
      this.selectedMetricList = this.data.metricApplicationInsightsList;
      this.isEdit = true;
    }
  }
  ngOnDestroy() {
    this.isEdit = false;
    this.data = null;
  }
  public initializeForm = () => {
    this.fg = this.fb.group({
      applicationInsightsId: [0],
      dateGenerated: [],
      expiryDate: [],
      expiryCondition: [],
      appInsightsCountryId: [],
      appInsightsRetailerId: [],
      appInsightsCategoryId: [],
      appInsightsDivisionId: [],
      leftImage: [],
      rightImage: [],
      value: [],
      description: [],
      suggestedAction: [],
      metricValue: [],
      metrics: [],
      metricApplicationInsightsList: [],
      createdBy: [this.storage.get(USER_ID)],
      isExpired: [false],
      isPublish: [false],
      active: [true]
    });
  }
  public getDefaultDropdowns = () => {
    this.configService.LookupGetByCode('AP_Country').subscribe(res => {
      this.countryList = res;
    });
    this.configService.LookupGetByCode('AP_Retailer').subscribe(res => {
      this.retailerList = res;
    });
    this.configService.LookupGetByCode('AP_Product_Category').subscribe(res => {
      this.categoryList = res;
    });
    this.configService.LookupGetByCode('AP_Product_Division').subscribe(res => {
      this.divisionList = res;
    });
    this.configService.LookupGetByCode('AP_Metric').subscribe(res => {
      this.metricList = res;
      this.metricDropdownList = res;
    });
  }
  onSubmit = (flag) => {
    if (this.fg.valid) {
      const model = {
        ...this.fg.value,
        metricApplicationInsightsList: this.selectedMetricList
      }
      this.spinner.show();
      if (this.isEdit) {
        this.configService.ApplicationInsightsUpdate(model).subscribe(res => {
          if (res) {
            this.spinner.hide();
            this.toast.success('Application Insights', 'Application Insight updated successfully');
            if (flag) {
              this.initializeForm();
              this.isEdit = false;
              this.data = null;
              this.selectedMetricList = [];
              this.gridApi.setRowData(this.selectedMetricList);
            } else {
              this.dialogRef.close(true);
            }
          }
        }, error => {
          this.spinner.hide();
          this.toast.error('Application Insights', 'Application Insight not updated successfully');
          this.dialogRef.close();
        })
      } else {
        this.configService.ApplicationInsightsAdd(model).subscribe(res => {
          if (res) {
            this.spinner.hide();
            this.toast.success('Application Insights', 'Application Insight added successfully');
            if (flag) {
              this.initializeForm();
              this.isEdit = false;
              this.data = null;
              this.selectedMetricList = [];
              this.gridApi.setRowData(this.selectedMetricList);
            } else {
              this.dialogRef.close(true);
            }
          }
        }, error => {
          this.spinner.hide();
          this.toast.error('Application Insights', 'Application Insight not added successfully');
          this.dialogRef.close();
        });
      }

    }
  }
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  onSelect(event, documentType) {
    this.spinner.show();
    this.getFile(event, documentType);
  }
  private getFile(event: any, documentType) {
    let reader = new FileReader();
    if (event.addedFiles && event.addedFiles.length > 0) {
      let file = event.addedFiles[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileModel['file'] = ((reader.result as any).split(',')[1]);
        this.fileModel['fileName'] = file.name;
        this.configService.UploadFiles(this.fileModel).subscribe(res => {
          this.spinner.hide();
          this.fg.controls[documentType].setValue(res);
        }, error => {
          this.spinner.hide();
        })
      };
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  addMetric() {
    if (this.fg.controls['metrics'].value && this.fg.controls['metricValue'].value) {
      const model = {
        metricApplicationInsightsId: this.fg.controls['metrics'].value.commonCodeValueId,
        applicationInsightsId: 0,
        metricApplicationDescription: this.fg.controls['metrics'].value.value,
        value: this.fg.controls['metricValue'].value,
        createdById: 1
      }
      this.selectedMetricList.push(model);
      this.metricDropdownList = this.metricDropdownList.filter(metric => metric.commonCodeValueId !== model.metricApplicationInsightsId);
      this.gridApi.setRowData(this.selectedMetricList);
      this.fg.controls['metrics'].setValue(null);
      this.fg.controls['metricValue'].setValue(null);
    }
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: 'Metric' }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeMetric(row.rowData);
      }
    });
  }
  public removeMetric(selectedMetric: any) {
    if (selectedMetric) {
      this.selectedMetricList = this.selectedMetricList.filter(metric => metric.metricApplicationInsightsId !== selectedMetric.metricApplicationInsightsId);
      this.metricDropdownList = this.metricList.filter(metric => !this.selectedMetricList.find(met => met.metricApplicationInsightsId === metric.commonCodeValueId));
      this.gridApi.setRowData(this.selectedMetricList);
    }
  }
  public setExpiredFlag = (date) => {
    if (date && date.value) {
      return date.value > new Date() ? this.fg.controls['isExpired'].setValue(false) : this.fg.controls['isExpired'].setValue(true);
    }
  }
}
