import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
// import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: 'app-button-renderer',
  template: `<mat-slide-toggle [checked]="checked" (change)="onClick($event)"
    >{{ label }}
  </mat-slide-toggle>`,
})
export class ToggleButtonRendererComponent implements ICellRendererAngularComp {
  get label() {
    return this.params.data.isPublish ? 'Yes' : 'No';
  }
  params;
  checked = false;
  agInit(params): void {
    this.params = params;
    this.checked = this.params.data.isPublish;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onChange instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        // ...something
      };
      this.params.onChange(params);
    }
  }
}
