export class CClaim {
    public static CentreClaims =
        {
            CNT_ADD: false,
            CNT_ACT: false,
            CNT_GET: false,
            CNT_GA: false,
            CNT_GL: false,
            CNT_UP: false,
            CNT_GCAS: false,
            CNT_SR: false,
            CNT_GAC: false,
            CNT_GM: false,
            CNT_GLBM: false,
            CNT_GC: false,
            CNT_GLBC: false,
            CNT_GCBM: false
        };
    public static CustomerAppointmentClaims =
        {
            CAPT_AD: false,
            CAPT_GET: false,
            CAPT_GA: false,
            CAPT_GL: false,
            CAPT_UP: false
        };
    public static InspectionReportClaims =
        {
            IRPT_AD: false,
            IRPT_GET: false,
            IRPT_GA: false,
            IRPT_GL: false,
            IRPT_UP: false,
        };
    public static UserClaims =
        {
            UMGT_AD: false,
            UMGT_GET: false,
            UMGT_GA: false,
            UMGT_ACT: false,
            UMGT_UP: false,
        };
    public static CustomerClaims =
        {
            CUST_AD: false,
            CUST_GET: false,
            CUST_GA: false,
            CUST_GL: false,
            CUST_UP: false,
            CUST_ACT: false,
            CUST_SI: false,
            CUST_CU: false,
            CUST_SR: false
        };
    public static PurchaseClaims =
        {
            PUR_AD: false,
            PUR_GET: false,
            PUR_GA: false,
            PUR_GL: false,
            PUR_UP: false,
        };
    public static AuctionClaims =
        {
            AUC_AD: false,
            AUC_GET: false,
            AUC_GA: false,
            AUC_GL: false,
            AUC_UP: false,
        };
    public static WarehouseClaims =
        {
            WH_AD: false,
            WH_GET: false,
            WH_GA: false,
            WH_GL: false,
            WH_UP: false,
        };
    public static InventoryClaims =
        {
            WH_AD: false,
            WH_GET: false,
            WH_GA: false,
            WH_GL: false,
            WH_UP: false,
        };
    public static DealershipClaims =
        {
            DS_AD: false,
            DS_GET: false,
            DS_GA: false,
            DS_GL: false,
            DS_UP: false,
            DS_ACT: false,
            DS_APRV: false,
            DS_GDBD: false
        };
    public static ReportClaims =
        {
            Add: false,
            Activate: false,
            Get: false,
            GetAll: false,
            GetList: false,
            Update: false
        };
    public static AccountClaims =
        {
            ACC_GUBI: false,
            ACC_GA: false,
            ACC_LG: false,
            ACC_UP: false,
            ACC_CU: false,
            ACC_FP: false,
            ACC_RP: false,
            ACC_GBE: false,
            ACC_SR: false,
            ACC_AD: false,
            ACC_UD: false,
            ACC_ADS: false
        };
    public static ClaimGroupClaims =
        {
            CLM_GL: false,
            CLM_GLBR: false,
            CLM_GLBU: false,
            CLMG_GA: false,
            CLMG_GABU: false,
            CLMG_GL: false
        };
    public static DealerClaims =
        {
            DLR_ACT: false,
            DLR_AD: false,
            DLR_GET: false,
            DLR_GA: false,
            DLR_GL: false,
            DLR_UP: false
        };
    public static RoleClaims =
        {
            RL_ACT: false,
            RL_AD: false,
            RL_GET: false,
            RL_GA: false,
            RL_GL: false,
            RL_UP: false
        };
    public static CommonClaims =
        {
            CMN_GS: false,
            CMN_GC: false,
            CMN_GCHC: false,
            CMN_GUT: false,
            CMN_GDTL: false,
            CMN_GASL: false,
            CMN_GPML: false,
            CMN_GITL: false,
            CMN_GISL: false,
            CMN_GCRT: false
        };
    public static QuotationClaims = {
        QUOT_PRP: false,
        QUOT_AD: false,
        QUOT_GET: false
    };
    public static AuctionBidClaims = {
        ACBD_ACT: false,
        ACBD_AD: false,
        ACBD_GET: false,
        ACBD_GA: false,
        ACBD_GL: false,
        ACBD_UP: false
    };
    public static AuctionDocumentClaims = {
        ADOC_ACT: false,
        ADOC_AD: false,
        ADOC_GET: false,
        ADOC_GA: false,
        ADOC_GL: false,
        ADOC_UP: false
    };
    public static AuctionWishlistClaims = {
        AWL_ACT: false,
        AWL_AD: false,
        AWL_GET: false,
        AWL_GA: false,
        AWL_GL: false,
        AWL_UP: false
    };
    public static DealerVehicleAlertClaims = {
        DVAL_ACT: false,
        DVAL_AD: false,
        DVAL_GET: false,
        DVAL_GA: false,
        DVAL_GL: false,
        DVAL_UP: false,
        DVAL_DNCC: false,
        DVAL_DNCU: false
    };
    public static WarehouseVehiclePurchaseClaims = {
        WVP_ACT: false,
        WVP_AD: false,
        WVP_GET: false,
        WVP_GA: false,
        WVP_GL: false,
        WVP_UP: false,
    };
    public static DocumentClaims = {
        DOC_GET: false,
        DOC_UF: false,
        DOC_ACT: false
    };
    public static CheckpointClaims = {
        CKP_ACT: false,
        CKD_ACT: false,
        CKD_AD: false,
        CKD_GET: false,
        CKD_GA: false,
        CKD_GL: false,
        CKD_UP: false,
        CKG_ACT: false,
        CKG_AD: false,
        CKG_GET: false,
        CKG_GA: false,
        CKG_GL: false,
        CKG_UP: false,
        CKG_GLP: false,
        CKT_GET: false,
        CKT_GL: false
    };
    public static InspectionCheckpointDetailClaims = {
        ICKD_ACT: false,
        ICKD_AD: false,
        ICKD_GET: false,
        ICKD_GA: false,
        ICKD_GL: false,
        ICKD_UP: false,
    };
    public static InspectionCheckpointDocumentClaims = {
        ICKDC_ACT: false,
        ICKDC_AD: false,
        ICKDC_GET: false,
        ICKDC_GA: false,
        ICKDC_GL: false,
        ICKDC_UP: false,
    };
    public static InspectionClaims = {
        INS_AC: false,
        INS_AD: false,
        INS_GET: false,
        INS_GA: false,
        INS_UP: false,
        INS_AIP: false,
        INS_UIBS: false,
        INS_PQR: false,
        INS_SIR: false,
        INS_ICR: false,
        INS_SVR: false,
        INS_VCR: false,
        INS_RPU: false,
        INS_FO: false,
        INS_ICA: false,
        INS_IPA: false,
        INS_AIV: false,
        INS_PUR: false,
        INS_UIAP: false,
    };
    public static InspectionDocumentClaims = {
        IDOC_ACT: false,
        IDOC_AD: false,
        IDOC_GET: false,
        IDOC_GA: false,
        IDOC_GL: false,
        IDOC_UP: false,
    };
    public static VehiclePurchaseClaims = {
        VHP_ACT: false,
        VHP_AD: false,
        VHP_GET: false,
        VHP_GA: false,
        VHP_GL: false,
        VHP_UP: false,
    };
    public static WarehousClaims = {
        WH_ACT: false,
        WH_GC: false,
        WH_SR: false,
        WH_FTR: false
    };
    public static AppointmentClaims = {
        APT_ACT: false,
        APT_AD: false,
        APT_GET: false,
        APT_UP: false,
        APT_GCA: false,
        APT_GABC: false,
        APT_CNC: false,
        APT_GASS: false
    };
    public static UserNotificationClaims = {
        VHP_ACT: false,
        VHP_AD: false,
        VHP_GET: false,
        VHP_GA: false,
        VHP_GL: false,
        VHP_UP: false,
    };
    public static IndicataClaims = {
        INDC_GD: false,
    };
    public static MakeClaims = {
        MK_ACT: false,
        MK_AD: false,
        MK_GET: false,
        MK_GA: false,
        MK_GL: false,
        MK_UP: false,
    };
    public static ModelClaims = {
        MDL_ACT: false,
        MDL_AD: false,
        MDL_GET: false,
        MDL_GA: false,
        MDL_GL: false,
        MDL_UP: false,
    };
    public static TrimLevelClaims = {
        TLVL_ACT: false,
        TLVL_AD: false,
        TLVL_GET: false,
        TLVL_GA: false,
        TLVL_GL: false,
        TLVL_UP: false,
        TLVL_GLFC: false,
        TLVL_GY: false,
    };
    public static VehicleCommonClaims = {
        VCOM_GFTL: false,
        VCOM_GCTL: false,
        VCOM_GBTL: false,
        VCOM_GETL: false,
        VCOM_GTTL: false,
        VCOM_GCL: false,
        VCOM_GML: false,
        VCOM_GFLL: false,
        VCOM_GDTL: false
    };
    public static DashBoardClaims = {
        DBRD_DRRP: false,
        DBRD_DRRPD: false,
        DBRD_PCRP: false,
        DBRD_PCRPD: false,
        DBRD_ARP: false,
        DBRD_ARPD: false,
        DBRD_AARP: false,
        DBRD_AARPD: false,
        DBRD_VPR: false,
        DBRD_VPRD: false
    };
    public static PMSClaims = {
      U_TYPE1: false,
      U_TYPE2: false,
      U_TYPE3: false,
      U_TYPE4: false,
      U_TYPE5: false
  };
}
