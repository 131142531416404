import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateUpdateTeamComponent } from './create-update-team/create-update-team.component';
import { throwError } from 'rxjs';
import { AddButtonRendererComponent } from 'src/common/add-button-renderer';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { CloneRendererComponent } from 'src/common/clone-renderer';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ImageRendererComponent } from 'src/common/image-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {

  public columnDefs = [
    {
      headerName: 'Team Name',
      field: 'teamName',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openForm.bind(this),
      },
    },
    {
      headerName: 'Status',
      field: 'active',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'toggleRenderer',
      cellRendererParams: (params) => {
        const obj = {
          label: params.value ? 'Active' : 'Inactive',
          onChange: this.updateStatus.bind(this),
        }
        return obj;
      },
    },
    {
      headerName: '',
      field: 'delete',
      // pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveDialog.bind(this),
      },
      width: 80,
      sortable: false,
      resizable: false,
      filter: false,
      suppressMenu: true
    },
    // {
    //   headerName: '',
    //   cellRenderer: 'cloneButtonRenderer',
    //   cellRendererParams: (params) => {
    //     const obj = {
    //       onClick: this.openCloneDialog.bind(this),
    //     }
    //     return obj;
    //   },
    //   resizable: false,
    //   sortable: false,
    //   filter: false,
    //   suppressMenu: true
    // },
    {
      headerName: '',
      field: 'pathList',
      cellRenderer: 'imageRenderer',
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true
    },
    {
      headerName: '',
      cellRenderer: 'addButtonRenderer',
      cellStyle: (params) => {
        return { 'text-align': 'left', 'padding': '10px' };
      },
      cellRendererParams: (params) => {
        const obj = {
          onClick: this.openAssociateTeamDialog.bind(this),
        }
        return obj;
      },
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true
    },
  ];showForm: boolean;
;
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public teamsList: any;

  public user: any;
  headerHeight = 45;
  public rowHeight;
  public defaultColDef;
  public userList;
  @ViewChild('cloneDialogTemplate', { static: false }) cloneDialogTemplate: TemplateRef<any>;
  @ViewChild('associateUserTeam', { static: false }) associateUserTeam: TemplateRef<any>;
  constructor(
    public router: Router,
    public userService: UsersService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public configurationService: ConfigurationService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        'toggleRenderer': ToggleButtonRendererComponent,
        'nameRenderer': NameRendererComponent,
        'deleteButtonRenderer': ButtonRendererComponent,
        'cloneButtonRenderer': CloneRendererComponent,
        'imageRenderer': ImageRendererComponent,
        'addButtonRenderer': AddButtonRendererComponent,
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    }
  }
  async ngOnInit() {
    this.configurationService.updateListing$.subscribe(res => {
      if (res && res === 'Team') {
        this.getTeamsList();
      }
    });
    const userRespone = await this.userService.GetAllUsers({ userTypeId: 1 }).toPromise().catch(error => throwError(error));
    if (userRespone && userRespone.data) {
      this.userList = userRespone.data;
    }
    this.getTeamsList();
    this.userService.listUpdate$.subscribe(res => {
      if (res) {
        this.getTeamsList();
      }
    })
  }
  openModal(event) {
    const teamComponent = {
      menuId: `1112`,
      name: 'Team Setting',
      selector: 'app-create-update-team',
      displayName: 'Team Setting',
      data: event,
      component: CreateUpdateTeamComponent
    }
    this.configurationService.menuRendererSubject.next(teamComponent);
  }
  public getTeamsList = () => {
    this.spinner.show()
    const params = {
      active: true
    };
    this.userService.getTeams()
      .subscribe(
        data => {
          this.teamsList = (data as any);
          this.teamsList = this.teamsList.map(team => this.mapTeamList(team))
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        }
      );
  }
  public mapTeamList = (team) => {
    const ids = team.applicationUsers.map(el => el.userId);
    return {
      ...team,
      oldUsers: team.applicationUsers,
      applicationUsers: this.userList.filter(user => ids.includes(user.userId)),
      pathList: team.applicationUsers.map(el => el.documentPath)
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.rowHeight = 50;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  // onCellClicked($event) {

  //   this.router.navigate(['addTeam'], {
  //     queryParams: {
  //       id: btoa($event.data.teamId)
  //     }
  //   });
  // }
  public navigateToTeams = () => {
    const teamComponent = {
      menuId: `1112`,
      name: 'Team Setting',
      selector: 'app-create-update-team',
      displayName: 'Team Setting',
      component: CreateUpdateTeamComponent
    }
    this.configurationService.menuRendererSubject.next(teamComponent);
  }
  openRemoveDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.teamName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeTeam(row.rowData);
      }
    });
  }
  openCloneDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(this.cloneDialogTemplate, {
      width: '500px',
      data: { ...row.rowData, cloneObject: { teamName: '', teamNameCode: '', claimGroups: [], active: true } }
    });
  }
  public removeTeam = (row) => {
    this.spinner.show()
    const model = {
      teamId: row.teamId,
      isDeleted: true,
      active: true
    }
    this.userService.DeleteTeam(model).subscribe(res => {
      this.toastr.success('', 'Team deleted successfully');
      this.getTeamsList();
    }, error => {
      this.spinner.hide()
    });
  }
  public updateStatus = (row) => {
    this.spinner.show()
    const model = {
      teamId: row.rowData.teamId,
      active: row.event.checked,
      isDeleted: false,
    }
    this.userService.ActivateTeam(model).subscribe(res => {
      this.toastr.success('', 'Team status updated successfully');
      this.getTeamsList();
    }, error => {
      this.spinner.hide()
    });
  }
  public saveAndClose = (row, errorTemplate: TemplateRef<any>, errorTeamCodeTemplate: TemplateRef<any>) => {
    if (row.cloneObject && row.cloneObject.teamName && row.cloneObject.teamNameCode) {
      if (!this.checkIfAlreadyExists(row.cloneObject)) {
        if (!this.checkIfTeamCodeAlreadyExists(row.cloneObject)) {
          const requestVM = {
            ...row.cloneObject,
            active: true
          };
          this.spinner.show();
          this.userService.CreateTeam(requestVM).subscribe(res => {
            if (res) {
              this.userService.GetTeamById(row.teamId).subscribe(team => {
                const clone = {
                  ...requestVM,
                  teamId: res,
                  claimGroups: team.claimGroups
                };
                this.userService.UpdateTeam(clone).subscribe(response => {
                  this.spinner.hide();
                  this.confirmationDialogRef.close();
                  this.getTeamsList();
                });
              }, error => {
                this.spinner.hide();
              })
            }
          }, error => {
            this.spinner.hide();
          })
        }
        else {
          this.dialogRef = this.dialog.open(errorTeamCodeTemplate);
        }
      } else {
        this.dialogRef = this.dialog.open(errorTemplate);
      }
    }
  }
  checkIfAlreadyExists(team) {
    return this.teamsList.find(row => row.teamName.toLowerCase() === team.teamName.toLowerCase());
  }
  checkIfTeamCodeAlreadyExists(team) {
    return this.teamsList.find(row => row.teamNameCode.toLowerCase() === team.teamNameCode.toLowerCase());
  }
  public exportExcel = () => {
    try {
      this.gridApi.exportDataAsExcel();
    }
    catch {
      this.gridApi.exportDataAsCsv();
    }
  }
  public openAssociateTeamDialog = (rowModel) => {
    this.dialogRef = this.dialog.open(this.associateUserTeam, { width: '500px', data: rowModel.rowData })
    this.dialogRef.afterClosed().subscribe(res => {
      if (res && res.applicationUsers) {
        const userIds = rowModel.rowData.oldUsers.map(el => el.userId);
        const newUsers = res.applicationUsers.filter(el => !userIds.includes(el.userId)).map(user => user.userId);
        if (newUsers && newUsers.length > 0) {
          newUsers.forEach(userId => {
            const model = {
              ...rowModel.rowData,
              userId
            }
            this.userService.AddUserTeam(model).subscribe(res => { });
          });
        }
      }
    });
  }
  public closePanel = () => {
    this.showForm = false;
  }
  openForm(rowModel) {
    this.showForm = false;
    this.configurationService.teamsModel = JSON.stringify(rowModel)
    this.showForm = true;

  }
  updateList(){
    this.getTeamsList();
  }
}
