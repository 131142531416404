<div class="d-flex justify-content-between m-3">
    <h5>Segment Membership Summary</h5>
    <div *ngIf="!isOutputTab">
        <button mat-raised-button color="accent" class="ml-2" (click)="downloadSegmentMembership()">Download Segment Membership
        </button>
    </div>
</div>

<div class="membership-items">
    <div class="left">
        <div class="segment-item-div" *ngFor="let item of perClusterCount | keyvalue; let i=index">
            <label>{{allSegments[i].name}} Rows</label>
            <div>{{item.value}}</div>
        </div>
    </div>
    <div class="right">
        <div class="segment-item-div">
            <label>Total Cases</label>
            <div>{{totalCases}}</div>
        </div>
        <div class="segment-item-div">
            <label>Valid Cases</label>
            <div>{{validCases}}</div>
        </div>
        <div class="segment-item-div">
            <label>Outliers</label>
            <div>{{outliersCount}}</div>
        </div>
    </div>
</div>

<hr class="theme-hr" />

<div class="mat-elevation-z8 mt-3">
    <div class="d-flex justify-content-between align-items-center p-2">
        <div class="d-flex align-items-center">
            <h6 class="m-0"><strong>Summary Statistics</strong></h6>
            <button (click)="selectMeasuresPopup()" *ngIf="isOutputTab" matTooltip="Select Measures to display on table" class="grid-bar-icon ml-2" mat-icon-button color="primary">
                <i class="material-icons">
                    ballot
                </i>
            </button>
        </div>
        <button *ngIf="!isOutputTab" mat-raised-button class="mx-2" color="accent" (click)="downloadStatsTable()">Download Table
        </button>
    </div>
    <div class="summaryTable">
        <table mat-table [dataSource]="dataSource" class="w-100">

            <ng-container matColumnDef="Segment" sticky>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index"> {{element.SegmentName}} </td>
            </ng-container>

            <!-- Header group -->
            <ng-container matColumnDef="SegmentCol" sticky>
                <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>

            <ng-container *ngFor="let item of groupedColumns; let firstGroup=first;">
                <ng-container *ngIf="!firstGroup">
                    <ng-container matColumnDef="{{item+'_mean'}}">
                        <th class="text-center" mat-header-cell *matHeaderCellDef> Average </th>
                        <td mat-cell *matCellDef="let element" class="text-center"> {{element[item+'_mean'] | number : '1.0-2'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="{{item+'_min'}}">
                        <th class="text-center" style="border-left: 1px solid #d4d4d4;" mat-header-cell *matHeaderCellDef> Min </th>
                        <td mat-cell style="border-left: 1px solid #d4d4d4;" *matCellDef="let element" class="text-center"> {{element[item+'_min'] | number : '1.0-2'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="{{item+'_max'}}">
                        <th class="text-center" mat-header-cell *matHeaderCellDef> Max </th>
                        <td mat-cell *matCellDef="let element" class="text-center"> {{element[item+'_max'] | number : '1.0-2'}} </td>
                    </ng-container>

                    <!-- Header group -->
                    <ng-container matColumnDef="{{item}}">
                        <th style="border-left: 1px solid #d4d4d4;" mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="text-center font-weight-bold">{{getColumnName(item)}}
                        </th>
                    </ng-container>
                </ng-container>    
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="groupedColumns; sticky: true"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>

<hr class="theme-hr" />
