import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { getUniqueId } from 'src/modules/item-manager/features/util/util';
import { UsersService } from 'src/services/User-services/user-services';

@Component({
  selector: 'app-add-edit-client',
  templateUrl: './add-edit-client.component.html',
  styleUrls: ['./add-edit-client.component.scss']
})
export class AddEditClientComponent implements OnInit {

  clientForm: FormGroup;
  editMode = false;
  uniqueId = getUniqueId();
  formData = new FormData();
  fileDataUrl: any;
  file: File;

  constructor(public dialogRef: MatDialogRef<AddEditClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private userService: UsersService) { }

  ngOnInit(): void {
    this.initializeForm();
    if (this.data.clientConfigurationId) {
      this.clientForm.patchValue(this.data);
      this.editMode = true;
      this.clientForm.get('email').disable();
      this.clientForm.get('password').disable();
      this.editModeValidations();
    }
  }

  editModeValidations() {
    this.clientForm.controls['firstName'].setValidators([]);
    this.clientForm.controls['lastName'].setValidators([]);
    this.clientForm.controls['email'].setValidators([]);
    this.clientForm.controls['password'].setValidators([]);

    this.clientForm.get('firstName').updateValueAndValidity();
    this.clientForm.get('lastName').updateValueAndValidity();
    this.clientForm.get('email').updateValueAndValidity();
    this.clientForm.get('password').updateValueAndValidity();
  }

  initializeForm() {
    this.clientForm = this.formBuilder.group({
      clientConfigurationId: [null],
      clientName: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      active: [true],
      description: [null],
      logo: [null],
      password: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]]
    });
  }

  onSaveClick() {
    if (this.editMode) {
      this.updateClient();
    } else
      this.addClient();
  }

  addClient() {
    this.clientForm.disable();
    this.appendClientInfoForm();
    this.userService.AddClient(this.formData)
    .subscribe((res: any) => {
      this.toastrService.success('', 'Client added successfully.');
      this.clientForm.enable();
      this.dialogRef.close(true);
    }, err => {
      this.toastrService.error('', 'Failed to update client.');
      this.clientForm.enable();
    });
  }

  appendClientInfoForm() {
    this.formData.append('clientConfigurationId', this.clientForm.get('clientConfigurationId').value || '');
    this.formData.append('clientName', this.clientForm.get('clientName').value);
    this.formData.append('firstName', this.clientForm.get('firstName').value);
    this.formData.append('lastName', this.clientForm.get('lastName').value);
    this.formData.append('active', this.clientForm.get('active').value);
    this.formData.append('description', this.clientForm.get('description').value);
    this.formData.append('password', this.clientForm.get('password').value);
    this.formData.append('email', this.clientForm.get('email').value);
  }

  updateClient() {
    this.clientForm.disable();
    this.appendClientInfoForm();
    this.userService.UpdateClient(this.formData)
    .subscribe((res: any) => {
      this.toastrService.success('', 'Client updated successfully.');
      this.clientForm.enable();
      this.dialogRef.close(true);
    }, err => {
      this.toastrService.error('', 'Failed to update client.');
      this.clientForm.enable();
    });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  uploadFileBtn() {
    if (document.getElementById('logoBtn' + this.uniqueId))
      document.getElementById('logoBtn' + this.uniqueId).click();
  }

  onFileSelect(event) {
    const files = event.target.files as FileList;
    const file = files[0];
    this.file = file;
    this.formData.append('logo', file, file.name);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      this.fileDataUrl = e.target.result;
      event.target.value = null;
    }
    reader.onerror = e => {
      event.target.value = null;
      this.toastrService.error('', 'Image uploading failed!');
    }
  }

}
