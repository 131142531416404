import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as signalR from '@aspnet/signalr';
import { LocalstorageService } from '../localstorage.service';
import { USER_TOKEN_INFO } from 'src/common/keys';

@Injectable({
  providedIn: 'root'
})
export class ChatHandlerService {
  connection: any;
  constructor(public http: HttpClient,
    public storage: LocalstorageService,
    ) {
  }

  createConnection() {
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.messagingChatHubUrl,{accessTokenFactory:()=>this.storage.get(USER_TOKEN_INFO)})
      .build()
  }

  connect() {
    this.connection
      .start()
      .then(function () {
      })
      .catch(function (err) {
        return console.error();
      });
  }

  stop() {
    this.connection.stop()
      .then(function () {
      })
      .catch(function (err) {
        return console.error();
      });
  }

}
