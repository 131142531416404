import { Component, Inject, Input, OnInit } from '@angular/core';
import {  GridApi, ColumnApi } from 'ag-grid-enterprise';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { promotionPlannerColDefs, paginationPageSize, cacheBlockSize } from '../../promotion-planner/ag-grid/gridOptions';
import { PromotionPlannerService } from 'src/services/Item-Management-Services';
import { overlayLoadingTemplate } from '../../util/util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-promotion-planner-grid-dialog',
  templateUrl: './promotion-planner-grid-dialog.component.html',
  styleUrls: ['./promotion-planner-grid-dialog.component.css']
})
export class PromotionPlannerGridDialogComponent implements OnInit {

  @Input() isDialoag?: boolean = false;

  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = 'multiple';
  public gridColumnDefs = promotionPlannerColDefs(true);

  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: any;
  gridData: any = [];
  frameworkComponents = {
  }

  selectedPromotionPlannerItems: any;

  overlayLoadingTemplate = overlayLoadingTemplate;

  applyFilterSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PromotionPlannerGridDialogComponent>,
    private promotionPlannerService: PromotionPlannerService) { }

  ngOnInit() {
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  }

  promotionPlannerGridRowSelectionChanged(params) {
    this.selectedPromotionPlannerItems = params.api.getSelectedRows();
  }

  onDoneClick() {
    this.dialogRef.close({ selectedItems: this.selectedPromotionPlannerItems });
  }

  applyFilters(event) {
    if (this.applyFilterSubscription) this.applyFilterSubscription.unsubscribe();
    this.gridApi.showLoadingOverlay();
    this.applyFilterSubscription = this.promotionPlannerService.FilterPromotionPlannerItemList(event).subscribe((res: any) => {
      this.gridData = res;
      this.gridApi.hideOverlay();
    },
    err => {
      this.gridApi.hideOverlay();
    });
  }

  ngOnDestroy() {
  }
}
