<div id="fb-forecast" class="container-fluid">
  <div class="fb-forecast-accordion item-wrapper-panel">
    <div class="item-wrapper">
      <form [formGroup]="itemSetupForm">
        <div class="row">
          <div class="col-md-3">
            <img src="assets/images/thumbnail.png" alt="" class="img-fluid" />
            <ul>
              <!-- <li>
                <a (click)="openImageDialog()" href="javascript:void(0)">Upload Image</a>
              </li> -->
              <li>
                <a (click)="deleteImage()">Clear Image</a>
              </li>
            </ul>
            <div class="info">
              <div class="labels">
                <h4>Created By:</h4>
                <h4>
                  {{itemSetupForm.controls && itemSetupForm?.controls.createdByName &&
                  itemSetupForm?.controls.createdByName.value || 'N/A' }}
                </h4>
              </div>
              <div class="labels">
                <h4>Created:</h4>
                <h4>
                  {{ itemSetupForm.controls && itemSetupForm?.controls.createdDate &&
                  itemSetupForm?.controls.createdDate.value | date: "short" || 'N/A'}}
                </h4>
              </div>
              <div class="labels">
                <h4>Last Modified By:</h4>
                <h4>
                  {{ itemSetupForm.controls && itemSetupForm?.controls.modifiedByName &&
                  itemSetupForm?.controls.modifiedByName.value || 'N/A'}}
                </h4>
              </div>
              <div class="labels">
                <h4>Last Modified:</h4>
                <h4>{{ itemSetupForm.controls && itemSetupForm?.controls.modifiedDate &&
                  itemSetupForm?.controls.modifiedDate.value | date: "short" || 'N/A'
                  }}</h4>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-6">
                <div id="fb-forecast-new-planner" class="container-fluid">
                  <div class="btn-wrapper">
                    <div class="form-group">
                      <label for="productHierarchy">Product Hierarchy</label>
                      <app-treeview-account-dropdown [type]="'ProductHierarchy'" [items]="productHierarchyTreeList"
                        [isSingleSelection]="true" (valueChange)="setProductHierarcyValue($event)">
                      </app-treeview-account-dropdown>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="node2">{{productHierarchyService.getProductNodeLabel(2)}}</label>
                  <input type="text" formControlName="node2" />
                </div>

                <div class="form-group">
                  <label for="node1">{{productHierarchyService.getProductNodeLabel(1)}}</label>
                  <input type="text" formControlName="node1" />
                </div>

                <div class="form-group">
                  <label for="productSeasonId">Season</label>
                  <mat-select name="productSeasonId" formControlName="productSeasonId">
                    <mat-option *ngFor="let season of productSeasonList" [value]="season.productSeasonId">
                      {{season.productSeasonName}}</mat-option>
                  </mat-select>
                </div>
                <!--  -->
                <ng-container *ngIf="isEdit">
                  <ng-container *ngFor="let item of likeItemList; let i = index;">
                    <div class="form-group">
                      <label for="{{'likeItem'+item[i]}}">Like Item</label>
                      <input name="{{'likeItem'+item[i]}}" [(ngModel)]="item.node1"
                        [ngModelOptions]="{standalone: true}" [matAutocomplete]="likeItemKeyAuto"
                        (input)="_filterItemByKey($event, item)" (focus)="onLikeItemKeyFocus($event)" />
                      <!-- (ngModelChange)="itemKeyValueChange($event)" -->
                      <!-- [formControl]="item.likeItemFormControl" -->

                      <mat-autocomplete [displayWith]="displayItemKeyFn" #likeItemKeyAuto="matAutocomplete"
                        (optionSelected)="selectLikeItemKey($event.option.value, item)">
                        <mat-option *ngFor="let item of filteredItemList | async" [matTooltip]="item.node1"
                          [value]="item" matTooltipClass="tooltips">
                          {{item.node1}}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-icon class="hand-cursor" (click)="deleteLikeItem(item , i)">
                        delete</mat-icon>
                    </div>
                  </ng-container>

                  <button mat-mini-fab class="mini-icon-button" (click)="addLikeItem()">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </ng-container>


                <div class="form-group">
                  <label for="productSourceId">Source</label>
                  <mat-select name="productSourceId" formControlName="productSourceId">
                    <mat-option *ngFor="let source of productSourceList" [value]="source.productSourceId">
                      {{source.productSourceName}}</mat-option>
                  </mat-select>
                </div>

                <div class="form-group">
                  <label for="productSupplierId">Supplier</label>
                  <mat-select name="productSupplierId" formControlName="productSupplierId"
                    (selectionChange)="supplierChange($event)">
                    <mat-option *ngFor="let supplier of filteredProductSupplierList"
                      [value]="supplier.productSupplierId">
                      {{supplier.productSupplierName}}</mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="retailPrice">Regular Retail Price</label>
                  <input type="number" formControlName="retailPrice" (blur)="calculateGrossMargin()" />
                </div>
                <div class="form-group">
                  <label for="msrp">MSRP</label>
                  <input type="number" formControlName="msrp" />
                </div>
                <div class="form-group">
                  <label for="cost">Cost</label>
                  <input type="number" formControlName="cost" (blur)="calculateGrossMargin()" />
                </div>
                <div class="form-group">
                  <label for="grossMargin">Gross Margin %</label>
                  <input readonly type="number" formControlName="grossMargin" />
                </div>

                <div class="form-group">
                  <label for="releaseDate">Release Date</label>
                  <mat-form-field>
                    <input matInput [matDatepicker]="releaseDate" name="releaseDate" formControlName="releaseDate"
                      (dateChange)="releaseDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="releaseDate"></mat-datepicker-toggle>
                    <mat-datepicker #releaseDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <label for="endofLifeDate">End of Life</label>
                  <mat-form-field>
                    <input matInput [matDatepicker]="endofLifeDate" name="endofLifeDate" formControlName="endofLifeDate"
                      [min]="endOfLifeDateMin">
                    <mat-datepicker-toggle matSuffix [for]="endofLifeDate"></mat-datepicker-toggle>
                    <mat-datepicker #endofLifeDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <ng-container *ngFor="let uda of udaList; let i = index;">
                  <div class="form-group">
                    <label>UDA's </label>
                    <div class="col-md-4 special-case">
                      <input name="{{'productUDATypeName'+uda[i]}}" [(ngModel)]="uda.productUDATypeName"
                        [ngModelOptions]="{standalone: true}" [matAutocomplete]="udaTypeAuto"
                        (input)="_filterUdaType($event, uda)" (focus)="onUdaTypeFocus($event)" />

                      <mat-autocomplete [displayWith]="displayUdaTypeFn" #udaTypeAuto="matAutocomplete"
                        (optionSelected)="selectUdaType($event.option.value, uda)">
                        <mat-option *ngFor="let udaType of filteredUdaTypeList | async"
                          [matTooltip]="udaType.productUDATypeName" [value]="udaType" matTooltipClass="tooltips">
                          {{udaType.productUDATypeName}}
                        </mat-option>
                      </mat-autocomplete>

                    </div>
                    <div class="col-md-4 special-case">
                      <input name="{{'uda'+uda[i]}}" [(ngModel)]="uda.productUDAName"
                        [ngModelOptions]="{standalone: true}" [matAutocomplete]="udaAuto"
                        (input)="_filterUda($event, uda)" (focus)="onUdaFocus($event, uda)" />

                      <mat-autocomplete [displayWith]="displayUdaFn" #udaAuto="matAutocomplete"
                        (optionSelected)="selectUda($event.option.value, uda)">
                        <mat-option *ngFor="let auda of filteredUdaList | async" [matTooltip]="auda.productUDAName"
                          [value]="auda" matTooltipClass="tooltips">
                          {{auda.productUDAName}}
                        </mat-option>
                      </mat-autocomplete>
                    </div>
                    <mat-icon class="hand-cursor" (click)="deleteUDARowItem(uda, i)">
                      delete</mat-icon>
                  </div>
                </ng-container>

                <label>UDA</label>
                <button class="mini-icon-button" mat-mini-fab (click)="addUDA()">
                  <mat-icon>add_circle</mat-icon>
                </button>

                <div class="form-group">
                  <label for="status">Status</label>
                  <mat-slide-toggle formControlName="active"></mat-slide-toggle>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="inline-flex w-100">
          <div cdkDropList cdkDropListOrientation="horizontal" class="item-list my-2"
            (cdkDropListDropped)="drop($event)">
            <div class="item-box m-1" *ngFor="let document of imagesList; let i=index" cdkDrag>
              <img [src]="document.content ? document.content : 'assets/images/thumbnail.png'" [alt]="document.title">
              <div class="actions-div">
                <span mattooltip="Upload" class="material-icons" (click)="uploadImg(i)">publish</span>
                <span mattooltip="Delete" class="material-icons mx-2" (click)="removeImg(i)">delete</span>
              </div>
            </div>
          </div>
          <input id="imgUploadBtn" hidden type="file" accept="image/*" (change)="onFileSelect($event)" />
          <div class="button-position inline-flex">
            <button class="btn btn-primary mr-10" (click)="saveItem()">Save</button>
          </div>
        </div>

      </form>
    </div>
  </div>

</div>