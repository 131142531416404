import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-promotion-planogram-card',
  templateUrl: './promotion-planogram-card.component.html',
  styleUrls: ['./promotion-planogram-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class PromotionPlanogramCardComponent implements OnInit {

  @Input() cardData: any = {};

  @Input() index?: number;

  constructor() { }

  ngOnInit(): void {
  }

  get itemImage(): string {
    return _.get(this.cardData, 'document.documentThumbnailPath', '');
  }

  get itemDescription(): string {
    return _.get(this.cardData, 'itemDescription', '');
  }

}
