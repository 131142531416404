import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class ForecastItemManagerService {

  // Subjects
  public productGridActionCellSubject: Subject<any> = new Subject();
  public productSetupActionSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setProductGridActionCellSubject = (value: any) => { this.productGridActionCellSubject.next(value); };
  setProductSetupActionSubject = (value: any) => { this.productSetupActionSubject.next(value); };

  // Observables
  getProductGridActionCellSubject = () => this.productGridActionCellSubject.asObservable();
  getProductSetupActionSubject = () => this.productSetupActionSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  DimItemGetList(body = {}) {
    return this.http.post(`${environment.ItemManagerForecast}/dimItemGetList`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  DimItemGetById(dimItemId) {
    return this.http.get(`${environment.ItemManagerForecast}/dimItemGetById?dimItemId=${dimItemId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  ProductHierarchyTreeGetList() {
    return this.http.get(`${environment.ItemManagerForecast}/productHierarchyForecastGetList`)
      .pipe(map(response => {
        return response;
      }));
  }

  ProductSeasonGetList() {
    return this.http.get(`${environment.ItemManagerForecast}/productSeasonGetList`)
      .pipe(map(response => {
        return response;
      }));
  }

  ProductSupplierGetList() {
    return this.http.get(`${environment.ItemManagerForecast}/productSupplierGetList`)
      .pipe(map(response => {
        return response;
      }));
  }

  ProductSourceGetList() {
    return this.http.get(`${environment.ItemManagerForecast}/productSourceGetList`)
      .pipe(map(response => {
        return response;
      }));
  }

  ProductUDAGetList() {
    return this.http.get(`${environment.ItemManagerForecast}/productUDAGetList`)
      .pipe(map(response => {
        return response;
      }));
  }

  ProductUDATypeGetList() {
    return this.http.get(`${environment.ItemManagerForecast}/productUDATypeGetList`)
      .pipe(map(response => {
        return response;
      }));
  }

  DeleteLikeItem(dimLikeItemId) {
    return this.http.delete(`${environment.ItemManagerForecast}/dimLikeItemDelete?dimLikeItemId=${dimLikeItemId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  DeleteUDA(dimItemProductUDAId) {
    return this.http.delete(`${environment.ItemManagerForecast}/dimItemProductUDADelete?dimItemProductUDAId=${dimItemProductUDAId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  ItemAdd(body) {
    return this.http.post(`${environment.ItemManagerForecast}/itemManagerForecastAdd`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  ItemUpdate(body) {
    return this.http.post(`${environment.ItemManagerForecast}/itemManagerForecastUpdate`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  uploadItemDocuments(docs: FormData) {
    return this.http.post(`${environment.ItemManagerForecast}/itemManagerForecastImagesAdd`, docs)
      .pipe(map(response => {
        return response;
      }));
  }

  deleteItemDocument(obj = {}) {
    return this.http.post(`${environment.ItemManagerForecast}/DimItemDocumentRemove`, obj)
      .pipe(map(response => {
        return response;
      }));
  }

  LikeItemGetList(node2 = '') {
    return this.http.get(`${environment.ItemManagerForecast}/dimLikeItemGetList?node2=${node2}`);
  }


  ImportItemManagerTemplate(body) {
    return this.http.post(`${environment.ItemManagerForecast}/itemManagerForecastImport`, body)
      .pipe(map(data => {
        return data;
      }));
  }

}
