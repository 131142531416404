<div class="dialog-close-btn">
  <button mat-icon-button (click)="onNoClick()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>

<form [formGroup]="productForm" (ngSubmit)="productForm.valid && onSubmit()" class="product-title-dialog-form">
  <div mat-dialog-content>

    <h5>Create/Edit Product Title Name</h5>

    <div class="row">
      <div class="col-md-2 product-field-label">
        <strong>Product Title Name*</strong>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <input matInput formControlName="productTitleName">
        </mat-form-field>
      </div>

      <div class="col-md-2 product-field-label">
        <strong>Pre-awareness Date</strong>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="preAwarenessDate" (dateChange)="checkValidity()" formControlName="preAwarenessDate">
          <mat-datepicker-toggle matSuffix [for]="preAwarenessDate"></mat-datepicker-toggle>
          <mat-datepicker #preAwarenessDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-2 product-field-label">
        <strong>Product Title ID*</strong>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <input matInput readonly formControlName="productTitleId">
        </mat-form-field>
      </div>

      <div class="col-md-2 product-field-label">
        <strong>Announce Date</strong>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="announcementDate" (dateChange)="checkValidity()" formControlName="announcementDate">
          <mat-datepicker-toggle matSuffix [for]="announcementDate"></mat-datepicker-toggle>
          <mat-datepicker #announcementDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-2 product-field-label">
        <strong>Account*</strong>
      </div>
      <div class="col-md-4 product-field-label">
        <mat-form-field appearance="fill">
          <mat-select formControlName="accountId" [disabled]="disabledAccountSelection">
            <mat-option *ngFor="let account of accountList" [value]="account.accountId">
              {{account.accountName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2 product-field-label">
        <strong>Rating</strong>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-select formControlName="ratingId">
            <mat-option *ngFor="let rating of ratingList" [value]="rating.ratingId">
              {{rating.ratingName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2 product-field-label mt-3">
        <strong>Release Date*</strong>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="releaseDate" formControlName="releaseDate">
          <mat-datepicker-toggle matSuffix [for]="releaseDate"></mat-datepicker-toggle>
          <mat-datepicker #releaseDate></mat-datepicker>
        </mat-form-field>
        <div *ngIf="relDate.invalid && (relDate.dirty || relDate.touched)" class="alert alert-danger">
          {{relDate.errors.releaseDate || 'Release Date is required'}}
        </div>
      </div>

      <div class="col-md-2 product-field-label mt-3">
        <strong>Theatrical Release Date</strong>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="theatricalReleaseDate" (dateChange)="checkValidity()" formControlName="theatricalReleaseDate">
          <mat-datepicker-toggle matSuffix [for]="theatricalReleaseDate"></mat-datepicker-toggle>
          <mat-datepicker #theatricalReleaseDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions style="justify-content: flex-end;">
    <button type="button" *ngIf="data && data.isEdit" mat-button mat-stroked-button color="accent"
      (click)="openRemoveDialog()">Delete</button>
    <button type="button" mat-button mat-flat-button color="accent" (click)="onNoClick()">Cancel</button>
    <button type="submit" mat-button mat-flat-button color="accent" cdkFocusInitial>Save</button>
  </div>
</form>