<div class="comparisons-main-cls mt-3">
    <div class="radar-chart-div" *ngFor="let chart of runTabs; let i=index">
        <div class="d-flex m-3 align-items-center">
            <h5 class="mb-3 mr-3">{{chart.name}}</h5>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <div class="d-flex align-items-center justify-content-center w-75" #radarChart>
            </div>
        </div>
    </div>
</div>
