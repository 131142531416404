<div class="container-fluid mapping-manager-page">

  <div class="row product-grid-filter-row mt-10">

    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()"
          placeholder="{{productHierarchyService.getProductNodeLabel(2)}}" [(ngModel)]="node2">
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()"
          placeholder="{{productHierarchyService.getProductNodeLabel(1)}}" [(ngModel)]="node1">
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>

    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput placeholder="Release From Date" (dateChange)="applyFilterEvent()" [matDatepicker]="fromDate"
          [(ngModel)]="releaseFromDate">
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker #fromDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput placeholder="Release To Date" (dateChange)="applyFilterEvent()" [matDatepicker]="toDate"
          [(ngModel)]="releaseToDate">
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="product-filter-clear-btn mr-2">
      <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
    </div>
  </div>
  <div class="mapping-action-btn-bar">


    <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>
    <div class="grid-actions inline-flex">
      <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
        (click)="exportItemListGridData()">
        <i class="material-icons">
          insert_drive_file
        </i>
      </button>
      <div *ngIf="selectedRows.length" class="row-info">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{totalRows}}</strong>
        </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{totalRows}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>

    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="importExportMenu">Export/Import</button>
    <mat-menu #importExportMenu="matMenu">
      <button mat-menu-item (click)="onExportItemTemplate()">Export</button>
      <button mat-menu-item (click)="importDataByTemplate()">Import
        <input class="hidden" type="file" id="upload-item-setup-data"
          name="upload-item-setup-data"  accept=".csv"  (change)="validateUploadedCSV($event)">
      </button>
    </mat-menu>

    <button class="add-icon-btn ml-10" (click)="openItemSetupDetail()" matTooltip="Add Item Setup" mat-icon-button
      color="primary" type="button">
      <i class="material-icons">
        add_circle
      </i>
    </button>

  </div>

  <div class="all-show-grid" *ngIf="isGridLoaded">

    <ag-grid-angular #mappedSKUsGrid id="outlier-mapped-sku-data-grid" class="ag-theme-material mapped-skus-grid"
      [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [gridOptions]="gridOptions"
      [frameworkComponents]="frameworkComponents" [rowSelection]="rowSelection" [animateRows]="true"
      [rowModelType]="rowModelType" [serverSideStoreType]="serverSideStoreType" [pagination]="true"
      [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
      (selectionChanged)="rowSelectionChanged($event)" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>


  <div style="width: 0; height: 0;" *ngIf="isGridLoaded">
    <ag-grid-angular #itemExportTemplateHidden class="ag-theme-material hide-grid" [gridOptions]="gridOptions"
      [columnDefs]="columnHiddenDefs" [defaultColDef]="defaultColDef"
      (gridReady)="onHiddenGridReady($event)">
    </ag-grid-angular>
  </div>

</div>
