import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PlanogramService } from 'src/services/Item-Management-Services/planogram.service';
import { enforcementDropDownOptions } from '../../contants/planogram-contants';

@Component({
  selector: 'app-add-movie',
  templateUrl: './add-movie.component.html',
  styleUrls: ['./add-movie.component.scss']
})
export class AddMovieComponent implements OnInit {

  itemForm: FormGroup;
  enforcementDropDownOptions = enforcementDropDownOptions;
  loading = false;
  isEdit = false; // true for updating a title

  isCurrentWeekEdit = false;

  constructor(
    private dialogRef: MatDialogRef<AddMovieComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private planogramService: PlanogramService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    if (this.data.isEdit) {
      this.isEdit = true;
      this.initializeEditForm();
      this.itemForm.patchValue(this.data.itemData);
    } else {
      this.initializeForm();
      if (this.data.itemData) {
        this.itemForm.patchValue(this.data.itemData);
        this.isCurrentWeekEdit = true;
      }
    }
  }

  initializeForm() {
    this.itemForm = this.formBuilder.group({
      name: this.data.name || 'N/A',
      weeks_old: ['', Validators.required],
      street: ['', Validators.required],
      title: ['', Validators.required],
      studio: ['', Validators.required],
      sku: ['', Validators.required],
      upc: ['', Validators.required],
      street_week: ['', Validators.required],
      tw_forecast: ['', Validators.required],
      group: new FormControl(0),
      minimum_facing: new FormControl(0),
      maximum_facing: new FormControl(0),
      enforce_top: new FormControl(false),
      planogram_side: ['None'],
      created_by: this.data.userId || 1,
      id: new FormControl(null)
    });
  }

  initializeEditForm() {
    this.itemForm = this.formBuilder.group({
      weeks_old: ['', Validators.required],
      tw_forecast: ['', Validators.required],
      on_hand: [],
      on_order: [],
      base_ca: [],
      calc_ca: []
    });
  }

  close(data = null) {
    this.dialogRef.close(data || false);
  }

  save() {
    this.loading = true;

    const obj = this.itemForm.value;

    if (this.isEdit) {
      this.planogramService.updatePlanogramData(this.data.itemData.id, obj)
      .subscribe((res: any) => {
        this.toastrService.success('Successfully updated!');
        this.loading = false;
        this.close(obj);
      },
      err => {
        this.toastrService.error('Something went wrong.');
        this.loading = false;
      });
    } else {

      switch (obj.planogram_side) {
        case enforcementDropDownOptions[1]:
          obj.planogram_side = 'FOS B';
          break;
        case enforcementDropDownOptions[2]:
          obj.planogram_side = 'FOS C';
          break;
        case enforcementDropDownOptions[3]:
          obj.planogram_side = 'FOS D';
          break;
        default:
          obj.planogram_side = '';
          break;
      }

      if (this.isCurrentWeekEdit) {
        this.planogramService.updateMovie(obj.id, obj)
        .subscribe((res: any) => {
          this.toastrService.success('Successfully updated the item!');
          this.loading = false;
          this.close(res.payload);
        },
        err => {
          this.toastrService.error('Something went wrong.');
          this.loading = false;
        });
      } else {
      this.planogramService.addSingleMovie(obj)
        .subscribe((res: any) => {
          this.toastrService.success('Successfully added a new item!');
          this.loading = false;
          this.close(res.payload.movie);
        },
          err => {
            this.toastrService.error('Something went wrong.');
            this.loading = false;
          });
      }
    }
  }

}
