import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class PromotionPlannerService {

  //Variables
  selectedPromotionPlannerItem: any;

  // Subjects
  public promotionPlannerGridActionCellSubject: Subject<any> = new Subject();
  public promotionPlannerUpsertSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setPromotionPlannerGridActionCellSubject = (value: any) => { this.promotionPlannerGridActionCellSubject.next(value); };
  setPromotionPlannerUpsertSubject = (value: any) => { this.promotionPlannerUpsertSubject.next(value); };

  // Observables
  getPromotionPlannerGridActionCellSubject = () => this.promotionPlannerGridActionCellSubject.asObservable();
  getPromotionPlannerUpsertSubject = () => this.promotionPlannerUpsertSubject.asObservable();


  constructor(private http: HttpClient) {
  }

  CreatePromotionPlannerItem(promtion: any) {
    return this.http.post<any>(`${environment.AppItemPromotionPlanner}/add`, promtion)
      .pipe(map(data => {
        return data;
      }));
  }

  UpdatePromotionPlannerItem(promotion: any) {
    return this.http.put(`${environment.AppItemPromotionPlanner}/update`, promotion)
      .pipe(map(data => {
        return data;
      }));
  }

  DeletePromotionPlannerItem(promotionId: string) {
    return this.http.delete(`${environment.AppItemPromotionPlanner}/delete?PromotionId=${promotionId}`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetAllPromotionPlannerItems(offSet, pageSize) {
    return this.http.get(`${environment.AppItemPromotionPlanner}/getlist`)
      .pipe(map(data => {
        return data;
      }));
  }

  FilterPromotionPlannerItemList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotionPlanner}/getlist?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  BulkAddPromotionPlannerDetailItems(body) {
    return this.http.post<any>(`${environment.AppItemPromotionPlanner}/bulkaddpromotiondetail`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  BulkAddPromotionPlannerDetailItemsTransactional(body) {
    return this.http.post<any>(`${environment.AppItemPromotionPlanner}/bulkaddpromotiondetailtransactional`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  CopyPromotionPlannerDetailItems(body) {
    return this.http.put<any>(`${environment.AppItemPromotionPlanner}/copypromotiondetail`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  MovePromotionPlannerDetailItems(body) {
    return this.http.put<any>(`${environment.AppItemPromotionPlanner}/movepromotiondetail`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  GetPromotionPlannerDetailItemList(promotionItemPlannerId) {
    return this.http.get(`${environment.AppItemPromotionPlanner}/getpromotiondetaillist?PromotionItemPlannerId=${promotionItemPlannerId}`)
      .pipe(map(data => {
        return data;
      }));
  }

  FilterPromotionPlannerDetailItemList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotionPlanner}/getpromotiondetaillist?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  BulkUpdatePromotionPlannerDetailItems(body) {
    return this.http.post<any>(`${environment.AppItemPromotionPlanner}/bulkupdatepromotiondetail`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  
  GetPromotionPlannerItemDetailWeeklyForecastList(pomotionPlannerItemId) {
    return this.http.get<any>(`${environment.AppItemPromotionPlanner}/GetPromotionItemPlannerDetailWeeklyForecastList?PromotionItemPlannerId=${pomotionPlannerItemId}`)
      .pipe(map(data => {
        return data;
      }));
  }

  BulkUpdatePromotionPlannerItemWeeklyForecastList(promotion) {
    return this.http.post<any>(`${environment.AppItemPromotionPlanner}/BulkUpdatePromotionPlannerWeeklyForecast`, promotion)
      .pipe(map(data => {
        return data;
      }));
  }


  FilterPromotionPlannerItemDetailWeeklyForecastList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotionPlanner}/GetPromotionItemPlannerDetailWeeklyForecastList?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  ExportPromotionPlannerDetailDataTemplate() {
    return this.http.get(`${environment.AppItemPromotionPlanner}/exportpromotiondetail`)
      .pipe(map(data => {
        return data;
      }));
  }

  ImportPromotionPlannerDetailTemplate(body) {
    return this.http.post(`${environment.AppItemPromotionPlanner}/importpromotiondetail`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  ImportPromotionPlannerTemplate(body) {
    return this.http.post(`${environment.AppItemPromotionPlanner}/import`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  GetActivePromotionByFixtureAndDateRange(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotionPlanner}/getpromotionitemplanneractivecountondaterangefixture?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetPromotionPlannerDisabledPositionList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotionPlanner}/getpromotiondetaildisabledpositionlist?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

}
