<br>

<mat-horizontal-stepper>
  <!-- <mat-step label="Calendar Setup">
    <app-calendar-setup-white-labeling></app-calendar-setup-white-labeling>
  </mat-step> -->

  <mat-step label="Product Hierarchy Setup">
    <app-product-hierarchy-white-labeling></app-product-hierarchy-white-labeling>
  </mat-step>

  <!-- <mat-step label="Location Hierarchy Setup">
    <mat-form-field>
      <mat-label>Address</mat-label>
      <input matInput>
    </mat-form-field>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step> -->
</mat-horizontal-stepper>
