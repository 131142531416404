import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { SegmentationService } from 'src/services/segmentation.service';
import { columnVariables, SEGMENTATION_OUTLIER_ID } from '../../constants/columnVariables';
import { SelectMeasuresComponent } from '../select-measures/select-measures.component';
import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";

@Component({
  selector: 'app-summary-statistics-segmentaion',
  templateUrl: './summary-statistics.component.html',
  styleUrls: ['./summary-statistics.component.scss']
})
export class SummaryStatisticsComponent implements OnInit, AfterViewInit {

  @Input() isOutputTab = true;
  @Input() sampleData: any;
  @Input() allSegments = [];
  @Input() allSegmentsUpdated = new Subject();

  groupedColumns = ['SegmentCol'];
  displayedColumns = ['Segment'];
  dataSource = [];

  selectedColumns =  JSON.parse(JSON.stringify(columnVariables));
  perClusterCount = {} as any;

  SEGMENTATION_OUTLIER_ID = SEGMENTATION_OUTLIER_ID

  totalCases = 0;
  validCases = 0;
  outliersCount = 0;

  constructor(private dialog: MatDialog, private segmentationService: SegmentationService) {
  }
  
  ngOnInit(): void {
    this.allSegmentsUpdated
    .subscribe(() => {
      this.fetchStats(this.selectedColumns);
    });
  }

  ngAfterViewInit(): void {
    let obj = {
      id: 'dicount_percentage',
      label: 'Discount Percentage'
    };
    this.selectedColumns.push(obj);
    obj = {
      id: 'total_sales_per_' + this.sampleData.groupby,
      label: 'Total Sales per ' + this.sampleData.groupby
    };
    this.selectedColumns.push(obj);
    this.fetchStats(this.selectedColumns);
  }

  fetchStats(variables) {
    const selectedVariablesColumns = [];
    variables.forEach(element => {
      selectedVariablesColumns.push(element.id);
    });
    this.segmentationService.fetchMembershipSegments(this.sampleData.userId, this.sampleData.fileName, this.sampleData.versionNo, (this.sampleData.uu_id || '0'), selectedVariablesColumns)
    .subscribe((res: any) => {
      this.perClusterCount = {};
      this.totalCases = 0;
      const keys = Object.keys(res.payload.result.data.per_cluster_count);
      const values = Object.values(res.payload.result.data.per_cluster_count);
      keys.forEach((k, i) => {
        if(Number(k) == SEGMENTATION_OUTLIER_ID) {
          this.outliersCount = Number(values[i]);
        }
        else this.perClusterCount[Number(k)] =  Number(values[i]);
        this.totalCases = this.totalCases + Number(values[i]);
      });
      this.validCases = this.totalCases - this.outliersCount;
      
      this.reorderTableColumns(res.payload.result.data.per_metric_colm_statistics);
    },
    err => {
    });
  }

  reorderTableColumns(data) {
    this.groupedColumns = ['SegmentCol'];
    this.displayedColumns = ['Segment'];
    this.dataSource = [];

    data.forEach((element) => {
      const key = Object.keys(element)[0];
      const value = Object.values(element)[0] as any;
      let label = key;
      this.selectedColumns.forEach(e => {
        if (e.id == label) this.groupedColumns.push(e.id);
      });
      this.displayedColumns.push(label + '_min');
      this.displayedColumns.push(label + '_mean');
      this.displayedColumns.push(label + '_max');
      value.forEach((valueItem, index) => {
        if (valueItem._id != SEGMENTATION_OUTLIER_ID) {
          !this.dataSource[index] && (this.dataSource[index] = []);
          this.allSegments.forEach((element: any) => {
            if (element.uu_id == valueItem._id) {
              this.dataSource[index].SegmentName = element.name;
            }
          });
          this.dataSource[index]._id = valueItem._id;
          this.dataSource[index][label + '_min'] = valueItem.min;
          this.dataSource[index][label + '_max'] = valueItem.max;
          this.dataSource[index][label + '_mean'] = valueItem.avg;
        }
      });
    });

  }

  getColumnName(id) {
    let label = '';
    this.selectedColumns.forEach(element => {
      if (element.id == id) label = element.label;
    });
    return label;
  }

  selectMeasuresPopup() {
    const selectedTooltipColumns = [];
    this.selectedColumns.forEach(element => {
      selectedTooltipColumns.push(element.id);
    });
    const columnsDialogRef = this.dialog.open(SelectMeasuresComponent, {
      width: '650px',
      data: {
        groupby: this.sampleData.groupby,
        selectedColumns: selectedTooltipColumns
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedColumns = result;
        this.fetchStats(this.selectedColumns);
      }
    });
  }

  downloadStatsTable() {
    this.exportAsXLSX(this.dataSource, 'Segmentation Summary Table')
  }

  downloadSegmentMembership() {
    const data = [] as any;
    data[0] = {};
    const keys = Object.keys(this.perClusterCount);
    const values = Object.values(this.perClusterCount);
    keys.forEach((element, index) => {
      let indexLabel = '';
      this.allSegments.forEach(e => {
        if(e.uu_id == element) indexLabel = e.name;
      })
      data[0][indexLabel] = values[index]; 
    });
    data[0]['Total Cases'] = 4;
    data[0]['Valid Cases'] = 2;
    data[0]['Outliers'] = 2;
    this.exportAsXLSX(data, 'Segmentation Membership Counts');
  }

  // DOWNLOAD FILE IMPLEMENTATION : EXCEL FILE
  private exportAsXLSX(data: any[], name: string): void {
    const workbookData = [
      {
        workSheet: 'Tab1',
        rows: data
      }
    ];
    this.exportAsExcelFile(workbookData, name);
  }

  private async exportAsExcelFile(workbookData: any[], excelFileName: string) {
    const workbook = new ExcelJS.Workbook();

    workbookData.forEach(({ workSheet, rows }) => {
      const sheet = workbook.addWorksheet(workSheet);
      const uniqueHeaders = [
        ...new Set(
          rows.reduce((prev, next) => [...prev, ...Object.keys(next)], [])
        )
      ];
      sheet.columns = uniqueHeaders.map(x => ({ header: x, key: x }));

      rows.forEach((jsonRow, i) => {
        let cellValues = { ...jsonRow };

        uniqueHeaders.forEach((header: any, j) => {
          if (Array.isArray(jsonRow[header])) {
            cellValues[header] = "";
          }
        });
        sheet.addRow(cellValues);
        uniqueHeaders.forEach((header: any, j) => {
          if (Array.isArray(jsonRow[header])) {
            const jsonDropdown = jsonRow[header];
            sheet.getCell(
              this.getSpreadSheetCellNumber(i + 1, j)
            ).dataValidation = {
              type: "list",
              formulae: [`"${jsonDropdown.join(",")}"`]
            };
          }
        });
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, excelFileName);
  }

  private getSpreadSheetCellNumber(row, column) {
    let result = "";

    // Get spreadsheet column letter
    let n = column;
    while (n >= 0) {
      result = String.fromCharCode((n % 26) + 65) + result;
      n = Math.floor(n / 26) - 1;
    }

    // Get spreadsheet row number
    result += `${row + 1}`;

    return result;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  // EXPORT CODE ENDED

}
