

<div class="journey-modal-header">
  <h2>Save View</h2>
</div>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Context Log Title</mat-label>
    <input id="contextLog" [formControl]="contextLog" matInput/>
  </mat-form-field>
</form>


<button (click)="save($event)" mat-raised-button color="accent" class="ml-2" style="float: right">Save</button>

<button (click)="dialogRef.close()" mat-stroked-button color="accent" style="float: right">Cancel</button>
