<div>
  <div class="all-show-grid journey-grid share-journey-ag-grid">
    <ag-grid-angular #foreCastManagerGrid class="ag-theme-material" [columnDefs]="columnDefs"
      [gridOptions]="gridOptions" [rowData]="gridData" [defaultColDef]="defaultColDef"
      [suppressRowClickSelection]="true" [frameworkComponents]="frameworkComponents" [animateRows]="true"
      [pagination]="true" [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
      (gridReady)="onGridReady($event)" (filterChanged)="filterChanged($event)"
      (sortChanged)="onGridStateChange($event)" (columnVisible)="onGridStateChange($event)"
      (columnMoved)="onGridStateChange($event)" (columnPinned)="onGridStateChange($event)">
    </ag-grid-angular>
  </div>
</div>