import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { clusterTechniques, dataScaling, intervals, matrices, methods, transformations } from '../../../constants/dropDowns';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import * as _ from 'lodash';
import { USER_INFO } from 'src/common/keys';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { getUniqueId } from 'src/modules/item-manager/features/util/util';
import { ManageTableColumnsComponent } from '../../dialogs/manage-table-columns/manage-table-columns.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataModuleService } from 'src/services/data-module.service';
import { MatTableDataSource } from '@angular/material/table';
import { FileUploadService } from 'src/services/file-upload.service';

@Component({
  selector: 'cluster-settings-tab',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {

  
  @Input() setSettingsExpansionSettings: Subject<boolean> = new Subject();
  @Output() runClusterEvent = new EventEmitter();

  clusterBy = '';

  clusterTechniques = clusterTechniques;
  methods = methods;
  intervals = intervals;
  transformations = transformations;
  dataScaling = dataScaling;
  matrices = matrices;

  fileSelectionCheckbox = '';

  displayedColumns = ['expand', 'fileName', 'status', 'createdAt'];
  innerColumns = ['selection', 'filename', 'status', 'createdAt'];
  dataSource = new MatTableDataSource<any>();
  expandedElement = null;
  // allFiles = [];

  selection = new SelectionModel<any>(false);

  settingsForm: FormGroup;

  step = 0;
  // 0: No file selected or uploaded;
  // 1: New file uploaded to frontend;
  // 2: File sent to backend for processing;
  // 3: Selected file has been processed (valid file)
  userObj = {} as any;
  selectedFileName = '';
  selectedVersionNo = null;

  metricesData: { id: string, name: string }[];
  uniqueId = '';

  filteredGridOptions: Observable<any[]>;

  _unsubscribeAll = new Subject<void>();

  clusteringCreated = false;

  showFilesFetchingSpinner = true;

  constructor(private dialog: MatDialog,
    private toastrService: ToastrService, private formBuilder: FormBuilder,
    private clusterForecastService: ClusteringForecastService,
    public storage: LocalstorageService,
    private dataModuleService: DataModuleService,
    private fileUploadService: FileUploadService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.uniqueId = getUniqueId();
    this.userObj = this.storage.get(USER_INFO);
    this.initializeForm();

    this.setSettingsExpansionSettings
    .pipe(
      takeUntil(this._unsubscribeAll)
    )
    .subscribe((res: boolean) => {
      if (res && this.step == 1) {
        this.step = 3;
        setTimeout(() => {
          this.step = 1;
        }, 10);
      }
    })

    this.fileUploadService.fileDeleted
    .pipe(
      takeUntil(this._unsubscribeAll)
    )
    .subscribe((res) => {
      if (this.selectedFileName && this.selectedVersionNo) {
        let hideData = false;
        if (res.deleteAll) hideData = true;
        else if (res.fileName && this.selectedFileName==res.fileName) {
          if (res.versionNo) {
            this.selectedVersionNo==res.versionNo && (hideData = true);
          }
          else
            hideData = true
        }
        if (hideData) {
          this.toastrService.info('Clustering module closed because the opened version has been deleted.');
          this.resetSettings();
          this.runClusterEvent.next(null);
          this.step = 0;
          this.selection.clear();
        }
      }
    });
  }

  ngAfterViewInit() {
    // for checking progress of files iteratively
    this.checkFileStatus();
  }

  private localeTimeString(date): Date {
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);

    const offset = utcDate.getTimezoneOffset() / 60;
    const hours = utcDate.getHours();

    localDate.setHours(hours - offset);

    return localDate;
  }

  toggleRow(element: any) {
    element.versions &&
      element.versions.length
      ? (this.expandedElement =
        this.expandedElement === element.file_name ? null : element.file_name)
      : null;
  }

  private initializeForm() {
    this.settingsForm = this.formBuilder.group({
      file_name: [],
      user_id: [this.userObj.userId],
      metricVariables: [[], Validators.required],
      noOfClusters: [null, [Validators.pattern("^[0-9]*$"), Validators.min(2), Validators.max(20), Validators.required]],
      setClustersAuto: [false],
      technique: [1],
      noOfIterations: [{ value: 10, disabled: true }],
      setIterationsAuto: [true],
      scaling: [2],
      calculationMethod: [1],
      intervalCalculation: [1],
      showAdvancedSettings: [false]
    });
    this.settingsForm.get('setClustersAuto').valueChanges
      .subscribe((res: boolean) => {
        if (res) this.settingsForm.get('noOfClusters').disable();
        else this.settingsForm.get('noOfClusters').enable();
      });
    this.settingsForm.get('setIterationsAuto').valueChanges
      .subscribe((res: boolean) => {
        if (res) this.settingsForm.get('noOfIterations').disable();
        else this.settingsForm.get('noOfIterations').enable();
      });
    this.settingsForm.get('showAdvancedSettings').valueChanges
      .subscribe((res: boolean) => {
        if (!res) {
          this.settingsForm.get('technique').setValue(1);
          this.settingsForm.get('noOfIterations').setValue(10);
          this.settingsForm.get('noOfIterations').disable();
          this.settingsForm.get('setIterationsAuto').setValue(true);
          this.settingsForm.get('scaling').setValue(2);
          this.settingsForm.get('calculationMethod').setValue(1);
          this.settingsForm.get('intervalCalculation').setValue(1);
        }
      });
  }

  checkboxChanged(field: string, e) {
    if (e.checked)
      this.clusterBy = field;
    else
      this.clusterBy = null;
  }

  updateIterations(add: boolean) {
    if (this.settingsForm.get('setIterationsAuto').value) return;
    if (add && this.settingsForm.get("noOfIterations").value < 50) this.settingsForm.get("noOfIterations").setValue(this.settingsForm.get("noOfIterations").value + 1);
    else if (!add && this.settingsForm.get("noOfIterations").value > 10) this.settingsForm.get("noOfIterations").setValue(this.settingsForm.get("noOfIterations").value - 1);
  }

  async runCluster() {
    if (!this.clusterBy) {
      this.toastrService.warning('Please select CLUSTER BY value.', 'Warning');
      return;
    }
    const confirmation = await this.showConfirmation();
    if (!confirmation) return;
    const obj = this.settingsForm.value;
    obj.objectsToCluster = this.clusterBy;
    obj.setClustersAuto && (obj.noOfClusters = null);
    obj.setIterationsAuto && (obj.noOfIterations = null);
    obj.noOfClusters && (obj.noOfClusters = Number(obj.noOfClusters));
    if (obj.noOfClusters && obj.noOfClusters > 20) {
      this.toastrService.warning('Number of clusters cannot be more than 20.', 'Warning');
      return;
    }

    obj.version_no = this.selectedVersionNo;
    const formData = new FormData();
    formData.append('cluster_settings', JSON.stringify(obj));
    this.spinner.show();

    const faultyColumnsFound = await this.getFaultyColumns();
    if (faultyColumnsFound) {
      this.spinner.hide();
      return;
    }

    this.clusterForecastService.runClustering(formData)
      .subscribe((res: any) => {
        this.showFilesFetchingSpinner = true;
        this.runClusterEvent.next(null); // hide all other tabs
        this.toastrService.info('Clustering is in progress. You can view the Output once it gets ready. View the status in Previous Files section!', 'Info');
        this.step = 0;
      },
        err => {
          this.spinner.hide();
        });
  }

  async getFaultyColumns(): Promise<boolean> {
    return new Promise((resolve) => {
      this.clusterForecastService.getFaultyColumns(this.userObj.userId, this.selectedFileName, this.selectedVersionNo)
        .subscribe((res: any) => {
          let resolveState = false;
          const metricVariables = this.settingsForm.get('metricVariables').value;
          res.payload.forEach(element => {
            if (metricVariables.indexOf(element) > -1) {
              this.toastrService.warning(element + ' is a faulty column.', 'Warning');
              resolveState = true;
            }
          });
          resolve(resolveState);
        },
          err => {
            resolve(true);
          });
    });
  }

  clearData() {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to clear this data?' }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetSettings();
        this.runClusterEvent.next(null);
        this.step = 0;
      }
    });
  }

  showConfirmation(): Promise<any> {
    return new Promise((resolve) => {
      if (!this.clusteringCreated) resolve(true);
      else {
        const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: { confirmationMessage: 'Already saved clustering results will be discarded. Do you want to proceed?' }
        });
        confirmationDialogRef.afterClosed().subscribe(result => {
          resolve(result);
        });
      }
    });
  }

  resetSettings() {
    this.initializeForm();
    this.step = 0;
  }

  clearSettings() {
    this.initializeForm();
  }

  openSelectedFileData() {
    this.step = 0;
    this.runClusterEvent.next(null); // hide all other tabs

    const fileversionName = this.selection.selected[0];
    this.dataSource.data.forEach(f => {
      f.versions.forEach(v => {
        if ((v.name+'_'+v.version_name) == fileversionName) {
          this.selectedFileName = v.name;
          this.selectedVersionNo = v.uu_id;
        }
      });
    });

    this.metricesData = [];
    
    if (this.selectedVersionNo == 1) {
      this.toastrService.warning('Original version cannot be updated.');
    } else {
      this.fetchFileDetails();
    }
  }

  private fetchFileDetails() {
    this.spinner.show();
    // this.clusterForecastService.fetchFileDetails(this.userObj.userId, this.selectedFileName)
    this.dataModuleService.fetchVersionDetails(this.selectedFileName, this.userObj.userId, this.selectedVersionNo)
      .subscribe((res: any) => {
        this.spinner.hide();
        const fileData = res.payload;
        fileData.variable_data.forEach((element, index) => {
          if (element.is_matric) {
            const obj = {
              id: element.name,
              name: element.displayName
            }
            this.metricesData.push(obj);
          }
        });
        this.clusteringCreated = false;
        this.resetSettings();
        this.step = 1;
        if (fileData.clustering_status == 'Clustering Run Complete') {
          fileData.clustering_details.data.fileName = this.selectedFileName;
          fileData.clustering_details.data.objectToCluster = fileData.clustering_settings.objectsToCluster;
          setTimeout(() => {
            const metricVariables = [];
            this.metricesData.forEach(element => {
              if (fileData.clustering_settings.metricVariables.includes(element.id)) {
                metricVariables.push(element);
              }
            });
            this.runClusterEvent.next(
              {
                fileName: this.selectedFileName,
                versionNo: this.selectedVersionNo,
                data: fileData.clustering_details.data,
                metrices: metricVariables,
                clustering_labels: fileData.labels.cluster_labels,
                run_labels: fileData.labels.run_labels,
                clustering_settings: fileData.clustering_settings,
                isAdjusted: fileData.Cluster_Id_adj
              }
            );
            setTimeout(() => {
              this.step = 0;
              setTimeout(() => {
                this.step = 1;
              }, 100);
            }, 500);
          }, 100);
          this.clusteringCreated = true;
          fileData.clustering_settings.showAdvancedSettings = true;
          !fileData.clustering_settings.noOfIterations && (fileData.clustering_settings.noOfIterations = 10);
          this.settingsForm.patchValue(fileData.clustering_settings);
          this.settingsForm.patchValue(fileData.clustering_settings.noOfIterations);
        } else {
          this.settingsForm.get('metricVariables').setValue([]);
        }
        this.settingsForm.get('file_name').setValue(this.selectedFileName);
        this.clusterBy = fileData.object_to_cluster || '';
      },
        err => {
          this.spinner.hide();
          this.toastrService.error('Failed to open file. Try again!');
        });
  }

  checkButtonDisabledCondition(): boolean {
    let result = false;
    if (!this.selection.hasValue()) result = true;
    else {
      const fileversionName = this.selection.selected[0];
      this.dataSource.data.forEach(f => {
        f.versions.forEach(v => {
          if ((v.name + '_' + v.version_name) == fileversionName && ['Uploading In Progress'].indexOf(v.clustering_status)>-1) {
            result = true;
          }
        });
      });
    }
    return result;
  }

  private _filterGridOptions(value: string): any[] {
    const filterValue = value.toLowerCase();
    const filteredResults = [];
    // this.gridColumnDefs.filter(option => option.headerName.toLowerCase().includes(filterValue));
    return filteredResults;
  }

  closeFile() {
    this.resetSettings();
    this.runClusterEvent.next(null);
    this.step = 0;
  }

  viewSelectedFile(row) {
    this.selection.clear();
    this.selection.select(row.file_name);
    this.openSelectedFileData();
  }

  selectMetricVariables() {
    const columnsDialogRef = this.dialog.open(ManageTableColumnsComponent, {
      width: '650px',
      data: {
        checkedValues: this.settingsForm.get('metricVariables').value,
        isSettingsTab: true,
        metrices: this.metricesData
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.settingsForm.get('metricVariables').setValue(result);
      }
    });
  }

  getMetricVariables() {
    const result = [];
    const variables = this.settingsForm.get('metricVariables').value;
    this.metricesData.forEach(element => {
      if (variables.includes(element.id)) {
        result.push(element.name);
      }
    });
    return result;
  }

  protected checkFileStatus() {
    this.showFilesFetchingSpinner && this.spinner.show();
    this.clusterForecastService.checkFileStatus(this.userObj.userId)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.dataSource.data = res.payload;
        this.dataSource.data.map((e) => {
          e.created_time = this.localeTimeString(e.created_time);
          e.versions.map((v) => {
            v.created_time = this.localeTimeString(v.created_time);
            v.version_name = v.name;
            v.name = e.file_name;
          });
        });
        this.showFilesFetchingSpinner && this.spinner.hide();
        this.showFilesFetchingSpinner = false;
        setTimeout(() => {
          this.checkFileStatus();
        }, 3000);
      },
        err => {
          setTimeout(() => {
            this.checkFileStatus();
          }, 3000);
        });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
    this.spinner.hide();
  }

}
