<div class="container">
  <form action="">
    <div class="row">
      <div class="col-md-6">
        <label><strong> Location Hierarchy Setup </strong></label>
      </div>
      <div class="col-md-6"></div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <table>
          <tr>
            <th class="column-height text-align text-cell-width">Hierarchy Levels</th>
            <th class="column-height text-align text-cell-width-name">Hierarchy Names</th>
          </tr>
          <tr *ngFor="let levelData of hierarchyLevels; let i = index">
            <td class="column-height text-align-center text-cell-width">
              {{'L' + levelData.locationHierarchyLevel}} <mat-icon
                *ngIf=" i >= locationHierarchyService.locationHierarchyMinimumLevel"
                [ngClass]="{'disable':isDisable === true}" class="cursor m-r"
                (click)="deleteHierarchyLevel(levelData.locationHierarchyLevel)">
                remove_circle</mat-icon>
            </td>
            <td><input placeholder="Insert Name" class="column-height text-align-left text-cell-width-name" type="text"
                [(ngModel)]="levelData.locationHierarchyName" [ngModelOptions]="{standalone: true}" required>
            </td>
          </tr>
        </table>
        <div *ngIf="addLevel" class="column-height text-align-center text-cell-width">
          <mat-icon [ngClass]="{'disable':isDisable === true}" class="circle-background" (click)="addHierarchyLevel()">
            add_circle</mat-icon>
        </div>

      </div>
      <div class="col-md-6">
        <table>
          <tr *ngFor="let nodeLevel of hierarchyNodes">
            <td class="column-height text-align-center text-cell-width">
              <strong>{{'Node ' + nodeLevel.locationHierarchyNodeLevel}}</strong></td>
            <td> <input class="column-height text-align-left text-cell-width-name" type="text"
                [(ngModel)]="nodeLevel.locationHierarchyNodeName" [ngModelOptions]="{standalone: true}"></td>
          </tr>
        </table>
        <div class="action-btns">
          <button mat-flat-button color="accent" class="ml-10" (click)="onSubmit()">Save</button>
        </div>
      </div>
    </div>
  </form>
</div>
