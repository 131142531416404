import { Injectable } from '@angular/core';
import { VEHICLE_STATE } from '../common/keys';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor() { }

  set(key: any, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
  get(key: any) {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }
  remove(key: any): void {
    localStorage.removeItem(key);
  }
  clear(): void {
    localStorage.clear();
  }
}
