<ng-container *ngIf="step==1">
    <h1 mat-dialog-title>Confirmation</h1>
    <div mat-dialog-content>
        <p>The process might take some time. Do you want to go back to the main page?</p>
    </div>
    <div class="action-panel text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onNoClick()">Go back to main screen</button>
        <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="fetchFilesProgress()">Wait</button>
    </div>
</ng-container>

<ng-container *ngIf="step==2">
    <div mat-dialog-content>
        <h5><strong>Updating File Statistics</strong></h5>
        <div class="w-100 p-4 d-flex justify-content-center align-items-end">
            <h1 class="text-center">{{progress}}%</h1>
            <div class="ml-1 loader-outer">
                <div class="loader">
                    <span class="dot dot-1"></span>
                    <span class="dot dot-2"></span>
                    <span class="dot dot-3"></span>
                    <span class="dot dot-4"></span>
                </div>
            </div>
            <h6 class="mb-3 ml-3">({{status}})</h6>
        </div>
    </div>
    <div mat-dialog-actions class="justify-content-end">
        <button class="mr-2" mat-stroked-button color="accent" (click)="onNoClick()">Go to main page</button>
    </div>
</ng-container>
