<div mat-dialog-content>
    <p><strong>Select Measures</strong></p>
    <div class="row d-flex justify-content-between">
        <ng-container *ngFor="let item of measuresArray">
            <div class="col-md-5">
                <section class="ag-column-radio-section">         
                    <mat-radio-button class="radio-margin" color="accent" labelPosition="after" (change)="data.sortTableBy.id=item.id" value="{{item.id}}" [checked]="item.id==data.sortTableBy.id">{{item.label}}</mat-radio-button>
                </section>
            </div>
        </ng-container>
    </div>
</div>
<div mat-dialog-actions class="justify-content-between align-items-center">
    <div>
        <mat-label class="mx-2">Ascending</mat-label>
        <mat-slide-toggle id="orderBySlider" [(ngModel)]="data.sortTableBy.direction" color="warn">
        </mat-slide-toggle>
        <mat-label class="mx-2">Descending</mat-label>
    </div>
    <div>
        <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="accent" (click)="onSaveClick()">Save</button>
    </div>
</div>
