import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {  ColumnApi, ColumnState, GridApi } from 'ag-grid-enterprise';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import {
  childGridColDefs,
  paginationPageSize,
  cacheBlockSize,
  rowSelection,
  defaultColDef
} from '../ag-grid/gridOptions';
import { ChildItemEditRenderer } from '../ag-grid/item-edit-renderer.component';
import { ItemDialogComponent } from '../../dialogs/item-dialog/item-dialog.component';
import { ChildItemActionCellRenderer } from '../ag-grid/child-item-action-cell-renderer.component';
import { downloadFromBase64, downloadFromLink, getFilterModel, getSortModel } from '../../util/util';
import { CHILD_ITEMS, INVALID_TEAM, MASTER_ITEMS, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { ChildItemService, ItemManagerService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-master-child-item-list',
  templateUrl: './master-child-item-list.component.html',
  styleUrls: ['./master-child-item-list.component.css']
})
export class MasterChildItemListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectedMasterRow?: any;
  @Input() isMasterPage?: boolean = false;
  @Input() isRetailerTeam?: any;

  INVALID_TEAM = INVALID_TEAM;

  rowModelType = 'serverSide';
  serverSideStoreType = 'partial';
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = rowSelection;
  public gridColumnDefs = childGridColDefs();
  public defaultColDef = defaultColDef;
  private gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: any;
  public gridColumnState: ColumnState[] = [];
  gridData: any = [];
  frameworkComponents = {
    childItemEditRenderer: ChildItemEditRenderer,
    childItemActionCellRenderer: ChildItemActionCellRenderer
  }

  public actionSubscription: Subscription;

  userTeam: any = '';
  filterEvent: any = {};

  screenPreference: any;

  constructor(
    public dialog: MatDialog,
    private childItemService: ChildItemService,
    public toastr: NgxToasterService,
    private itemManagerService: ItemManagerService,
    private usersService: UsersService,
    private userPreferenceService: UserPreferenceService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) { }

  ngOnInit() {
    this.getUserSecreenPrefereence();
    this.setRoleBaseAccess();
    this.actionSubscription = this.childItemService.getChildGridActionSubject().subscribe(res => {
      if (res) {
        this.performGridAction(res);
      }
    });
    this.getFormContorlsData();
  }

  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(this.isMasterPage ? MASTER_ITEMS : CHILD_ITEMS, CHILD_ITEMS);
    this.gridColumnState = this.screenPreference.columnState ? JSON.parse(this.screenPreference.columnState) : [];
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }

    this.gridColumnDefs = childGridColDefs(false, this.isRetailerTeam);
    this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
  }

  ngOnChanges() {
    if (this.selectedMasterRow) {
      this.getChildItemsByMasterId(this.selectedMasterRow.masterItemId);
    }
    this.showNoRowsDataOverlay();
  }

  showNoRowsDataOverlay() {
    if (this.isMasterPage && !this.selectedMasterRow) {
      if (this.gridApi) {
        this.gridApi.showNoRowsOverlay();
        this.getChildItemsByMasterId(-1);
      }
    }
  }

  performGridAction(actionObject) {
    switch (actionObject.action) {
      case 'edit': {
        this.openItemDialog(true, actionObject.actionData);
        break;
      }
      case 'delete': {
        this.openDeleteDialog(actionObject.actionData);
        break;
      }
    }
  }

  getFormContorlsData() {
    this.itemManagerService.GetAllRatings().toPromise();
    this.itemManagerService.GetAllClasses().toPromise();
    this.itemManagerService.GetAllSubClasses().toPromise();
    this.itemManagerService.GetAllAccounts().toPromise();
  }

  createGridDataSource(masterItemId = 0) {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody = {
          ...masterItemId !== 0 && { masterItemId: masterItemId },
          ...this.filterEvent,
          offSet: params.request.startRow ? params.request.startRow : 0,
          pageSize: paginationPageSize,
        };
        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);

        this.childItemService
          .GetAllChildItems(requestBody)
          .pipe(
            tap(() => this.gridApi.hideOverlay())
          )
          .subscribe((response: any) => {
            params.successCallback(response.data, response.totalRecord);
            response.data.length == 0 && this.gridApi.showNoRowsOverlay();
          });
      },
    };
    this.gridApi && this.gridApi.setServerSideDatasource(datasource);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    if (!this.isMasterPage) {
      this.createGridDataSource();
    } else {
      this.showNoRowsDataOverlay();
    }
    this.applyColumnState();
  }

  applyColumnState() {
    if (this.colApi && this.gridColumnState && this.gridColumnState.length) {
      setTimeout(() => {
        this.colApi.applyColumnState({ state: this.gridColumnState });
      }, 100);
    }
  }

  onGridStateChange(e) {
    this.gridColumnState = this.colApi.getColumnState();
    this.screenPreference.columnState = this.gridColumnState;
    this.updateScreenPreference();
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertColumnStatePreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  onExportGridData() {
    const filterModel = this.gridApi.getFilterModel();
    const sortModel = this.colApi.getColumnState();
    const requestBody = {
      ...this.isMasterPage && { MasterItemId: this.selectedMasterRow.masterItemId },
      ...this.filterEvent,
      pageSize: -1,
    };
    requestBody.filterModel = getFilterModel(filterModel);
    requestBody.sortModel = getSortModel(sortModel);

    this.childItemService
      .GetAllChildItems(requestBody)
      .subscribe((response: any) => {
        downloadFromBase64(_.get(response, 'data.0.fileBase64'), 'ChildItems.csv');
      });
  }

  exportGridTemplate() {
    this.childItemService.ExportChildDataTemplate().subscribe((res: any) => {
      downloadFromLink(res, 'ChildItemsTemplate');
    });
  }

  importData() {
    (document.getElementById('upload-child-data') as any).value = '';
    document.getElementById('upload-child-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const reader = new FileReader();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const childItemImportDataFile = reader.result as any;
        /*
          Files Validations Will go here
        */
        this.uploadImportedCsvFile(childItemImportDataFile);
      }
    }
  }

  uploadImportedCsvFile(childItemImportDataFile) {
    this.childItemService.ImportChildDataTemplate({
      fileBase64: childItemImportDataFile
    }).subscribe(
      (res: any) => {
        if (this.isMasterPage) {
          this.getChildItemsByMasterId(this.selectedMasterRow.masterItemId);
        } else {
          this.applyFilters(this.filterEvent);
        }
        this.toastr.success('Success', 'Child Items Imported Successfully.');
      },
      (err: any) => {
        this.toastr.error('Error', 'Child Item Import Failed.');
      });
  }

  getChildItemsByMasterId = (masterItemId) => {
    this.createGridDataSource(masterItemId);
  }

  openItemDialog(isEdit = false, data = {}): void {
    if (!this.childItemService.childItemDialogRef) {
      const dialogRef = this.dialog.open(ItemDialogComponent, {
        data: {
          isEdit: isEdit,
          isMasterPage: this.isMasterPage,
          formData: {
            ...data
          },
          selectedMasterRow: this.selectedMasterRow
        }
      });
      this.childItemService.childItemDialogRef = dialogRef;
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridApi.refreshServerSideStore({ purge: true });
        }
        this.childItemService.childItemDialogRef = null;
      });
    }
  }

  openColumnDialog() {
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select', 'cellAction']
      },
      width: '700px'
    });
  }

  openDeleteDialog(actionData) {
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: actionData.itemDescription }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteChildItem(actionData);
      }
    });
  }

  deleteChildItem(actionData) {
    this.childItemService.DeleteChildItem(actionData.childItemId).subscribe((res: any) => {
      this.toastr.success('Success', 'Child Item Deleted Successfully.');
      this.gridApi.refreshServerSideStore({ purge: true });
    }, err => {
      this.toastr.error('Error', 'Child Item Deletion Failed.');
    });
  }


  applyFilters(event) {
    this.filterEvent = event;
    this.createGridDataSource();
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
  }

}
