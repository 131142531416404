<ng-container *ngIf="allSegments.length">

    <app-summary-statistics-segmentaion [allSegmentsUpdated]="allSegmentsUpdated" [sampleData]="sampleData" [allSegments]="allSegments"></app-summary-statistics-segmentaion>

    <app-segmentation-graphs [allSegmentsUpdated]="allSegmentsUpdated" [resizeGraphSubject]="resizeGraphSubject" [sampleData]="sampleData" [allSegments]="allSegments"></app-segmentation-graphs>

    <app-memberships-table-segmentaion [allSegmentsUpdated]="allSegmentsUpdated" [allSegments]="allSegments" [sampleData]="sampleData" (segmentsUpdated)="fetchAllSegments(true)"></app-memberships-table-segmentaion>
    
    <div class="d-flex justify-content-end p-2">
        <button mat-stroked-button color="accent" (click)="saveRun()">SAVE RUN</button>
    </div>

</ng-container>
