import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pms-dashboard',
  templateUrl: './pms-dashboard.component.html',
  styleUrls: ['./pms-dashboard.component.scss']
})
export class PmsDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
