import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-creat-update-finance',
  templateUrl: './creat-update-finance.component.html',
  styleUrls: ['./creat-update-finance.component.css']
})
export class CreatUpdateFinanceComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CreatUpdateFinanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     public configService: ConfigurationService,
     public toastr : NgxToasterService,
     public spinner: NgxSpinnerService) {
    if (this.data.isEdit) {
      this.data.finance.effectiveStartDate = new Date(this.data.finance.effectiveStartDate)
      this.data.finance.effectiveEndDate = new Date(this.data.finance.effectiveEndDate)
    }

  }

  ngOnInit(): void {
  }
  saveAndClose() {
    if(!this.checkIfFormValid())
        return
    const model = {

      ...this.data.finance
    };
    this.spinner.show();
    if (!this.data.isEdit) {
      this.configService.FinanceAdd(model).subscribe(res => {
        this.dialogRef.close(true);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.configService.FinanceUpdate(model).subscribe(res => {
        this.dialogRef.close(true);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    }
  }
  checkIfFormValid(){
    if(!this.data.finance.customerName){
      this.toastr.error('Error', 'Customer Name is Required');
      return false
    }
    return true
  }
}
