


export class FuelTypeModel {
  constructor(
    public FuelTypeId: number,
    public FuelTypeName: string,
    public FuelTypeNameTranslation: string
  ) { }
}
export class ClassTypeList {
  constructor(
    public ClassTypeId: number,
    public ClassTypeName: string,
    public ClassTypeNameTranslation: string
  ) { }
}


export class EngineTypeList {
  constructor(
    public EngineTypeId: number,
    public EngineTypeName: string,
    public EngineTypeNameTranslation: string
  ) { }
}

export class TransmissionTypeList {
  constructor(
    public TransmissionTypeId: number,
    public TransmissionTypeName: string,
    public TransmissionTypeNameTranslation: string
  ) { }
}

export class BodyTypeList {
  constructor(
    public BodyTypeId: number,
    public BodyTypeName: string,
    public BodyTypeNameTranslation: string
  ) { }
}

export class DriveTypeList {
  constructor(
    public driveTypeId: number,
    public driveTypeName: string,
    public DriveTypeNameTranslation: string
  ) { }
}

export class FaceliftList {
  constructor(
    public faceLiftId: number,
    public faceLiftName: string,
    public faceliftNameTranslation: string
  ) { }
}

export class MakeModelList {
  constructor(
    public modelId: number,
    public modelName: string,
  ) { }
}


export class LkpNameValue {
  constructor(
    public Name: string,
    public Value: string
  ) { }
}

export class SearchModel {
  constructor(
    public Key: string
  ) { }
}
export const REPORT_LIST = [
  { key: 1, value: 'Cashflow Simulation', description: 'cs' },
  { key: 2, value: 'CVP', description: 'cvp' },
  { key: 3, value: 'PED', description: 'ped' },
  { key: 4, value: 'Operational Expense', description: 'oe' },
  { key: 5, value: 'Cash Flow Sensitivity', description: 'cfs' },
  { key: 6, value: 'Operational Cost Impact', description: 'oci' },
  { key: 7, value: 'CVP Model', description: 'cvp model' },
  { key: 8, value: 'Order Cancellation', description: 'oc' },
  { key: 9, value: 'Price Elasticity', description: 'pe' },
  { key: 10, value: 'Sales Impact', description: 'si' },
  { key: 11, value: 'Shipments with No Sales', description: 'swns' },
  { key: 12, value: 'Significant Performance', description: 'sp' },
  { key: 13, value: 'Shipment On Store', description: 'sos' },
  { key: 14, value: 'Store Closure', description: 'sc' }
];
