import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProcurementService } from 'src/services/procurement.service';
@Component({
  selector: 'app-pms-add-edit-grn',
  templateUrl: './pms-add-edit-grn.component.html',
  styleUrls: ['./pms-add-edit-grn.component.scss'],
})
export class PmsAddEditGrnComponent implements OnInit {
  prItems = [];
  uniqueId = '';
  uploadedFile = {
    name: '',
    file: null,
  };
  receiveDate = null;
  formData = new FormData();
  constructor(
    public dialogRef: MatDialogRef<PmsAddEditGrnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private procurementService: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService
  ) {}
  ngOnInit(): void {
    this.uniqueId = this.getUniqueId();
    this.fetchGRNItems();
  }
  fetchGRNItems() {
    this.spinner.show();
    this.procurementService.getGRNItems(this.data.prId).subscribe(
      (prItems: []) => {
        this.prItems = prItems;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  onFileSelect(event) {
    const files = event.target.files as FileList;
    const file = files[0];
    this.uploadedFile = {
      name: '',
      file: null,
    };
    this.formData.append('file', file);
    this.uploadedFile.name = file.name;
    this.uploadedFile.file = file;
  }
  uploadFileBtn() {
    document.getElementById('pms-grn-file-btn' + this.uniqueId) &&
      document.getElementById('pms-grn-file-btn' + this.uniqueId).click();
  }
  getUniqueId = (parts = 2): string => {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      const S4 = (((1 + Math.random()) * 0x10000) | 0)
        .toString(16)
        .substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('_');
  };
  onSaveClick() {
    this.spinner.show();
    if (this.formData.has('file') === true) {
      this.procurementService
        .uploadGRNDocument(this.formData)
        .subscribe((res: any) => {
          const payload = {
            documentId: res.documentId,
            prId: this.data.prId,
            poId: this.data.poId,
            grnItems: this.prItems,
          };
          this.procurementService.addGRN(payload).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.toastrService.success('GRN Created successfully.');
              this.dialogRef.close(true);
            },
            (err) => {
              this.toastrService.error('Failed to create GRN.');
              this.spinner.hide();
            }
          );
        });
    } else {
      const payload = {
        prId: this.data.prId,
        poId: this.data.poId,
        grnItems: this.prItems,
      };
      this.procurementService.addGRN(payload).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toastrService.success('GRN Created successfully.');
          this.dialogRef.close(true);
        },
        (err) => {
          this.toastrService.error('Failed to create GRN.');
          this.spinner.hide();
        }
      );
    }
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
