import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-new-item-planner',
  templateUrl: './new-item-planner.component.html',
  styleUrls: ['./new-item-planner.component.css']
})
export class DemoBBNewItemPlannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('app-new-item-planner');
  }

}
