<div class="container-fluid">
  <div class="user-page">
    <div [style.margin-top]="'20px'" class="row">
      <div class="col-md-4">
        <div class="form-group">
          <mat-select placeholder="Select Graph" [(ngModel)]="chartType" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let res of ['Line', 'Bar', 'Pie']; let i = index" [value]="res">
              {{res}}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"  [suppressMenuHide]="true"
       groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
      (cellDoubleClicked)="onCellClicked($event)" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
      [rowData]="allActivityList" (gridReady)="onGridReady($event)"  #grid></ag-grid-angular>
  </div> -->
  <ngx-charts-line-chart *ngIf="chartType === 'Line' && allActivityList.length > 0" #chart [view]="view"
    [scheme]="colorScheme" [legend]="false" [showXAxisLabel]="''" [showYAxisLabel]="''" [xAxis]="xAxis" [yAxis]="yAxis"
    [xAxisLabel]="''" [yAxisLabel]="''" [timeline]="true" [results]="chartDatasets">
  </ngx-charts-line-chart>
  <ngx-charts-bar-vertical *ngIf="chartType === 'Bar'" #chart [view]="view" [scheme]="colorScheme" [legend]="false"
    [showXAxisLabel]="''" [showYAxisLabel]="''" [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="''" [yAxisLabel]="''"
    [timeline]="true" [results]="chartDatasetsBar">
  </ngx-charts-bar-vertical>
  <ngx-charts-pie-chart [labels]="true" *ngIf="chartType === 'Pie'" #chart [view]="view" [scheme]="colorScheme"
    [legend]="false" [showXAxisLabel]="''" [showYAxisLabel]="''" [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="''"
    [yAxisLabel]="''" [timeline]="true" [results]="chartDatasetsBar">
  </ngx-charts-pie-chart>
  <!-- <ngx-charts-tree-map *ngIf="allActivityList && allActivityList.length > 0" [view]="view" [scheme]="colorScheme" [results]="chartDatasetsBar" (select)="onSelect($event)">
  </ngx-charts-tree-map> -->
</div>
