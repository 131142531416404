export class ContextLogModel {
  public JourneyId: number;
  public Id?: number;
  public DisplayName?: string;
  public ActualName?: string;
  public ReportId?: string;
  public GroupId?: string;
  public IsDeleted?: boolean = false;
  public ClientConfigurationId: number;
  public Instance: string;

  constructor() {}
}
