
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { CreatUpdateFinanceComponent } from './creat-update-finance/creat-update-finance.component';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {

  public columnDefs = [
    {
      headerName: 'Customer',
      field: 'customerName',
      minWidth: 30,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openModal.bind(this),
      },
    },
    {
      headerName: 'Effective Start Date',
      field: 'effectiveStartDate',
      minWidth: 20,
      resizable: true,
      valueFormatter: this.dateFormatter.bind(this)

    },
    {
      headerName: 'Effective End Date',
      field: 'effectiveEndDate',
      minWidth: 20,
      resizable: true,
      valueFormatter: this.dateFormatter.bind(this)

    },
    {
      headerName: 'Accural',
      field: 'accrual',
      minWidth: 20,
      resizable: true

    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      // pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveDialog.bind(this),
      },
      width: 20
    }
  ];;
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public teamsList: any;

  public financeList = []
  public defaultColDef;
  constructor(
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public configurationService: ConfigurationService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        'deleteButtonRenderer': ButtonRendererComponent,
        'nameRenderer': NameRendererComponent,
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    }
  }
  ngOnInit() {
    this.getFinanceSetting();
  }
  public getFinanceSetting = () => {
    this.configurationService.FinanceGetAll({}).subscribe(res => {
      this.financeList = res;
    })
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }


  openRemoveDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.customer }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.removeTeam(row.rowData);
      }
    });
  }
  openModal(event) {
    let isEdit = false
    let data = {}
    if (event.rowData) {
      isEdit = true;
      data = event.rowData
    }
    this.dialogRef = this.dialog.open(CreatUpdateFinanceComponent, {
      width: '600px',
      data: { isEdit: isEdit, finance: data }
    });
    if (this.dialogRef) {
      this.dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.getFinanceSetting();
        }
      })
    }

  }
  dateFormatter(params) {
    if (params.value)
      return new Date(params.value).toLocaleDateString("en-US")
    return ""
  }
}


