<!-- File Uploader ~ START -->
<mat-accordion>
    <mat-expansion-panel [expanded]="step==0?'true':'false'">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Cluster Data Input
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="col px-4 py-4 m-0">
                <h4 class="d-flex align-items-center">Previously Uploaded Files
                    <!-- <mat-icon matTooltip="Reload data"
                        class="ml-2 cursor-pointer" (click)="fetchPreviouslyUploadedFiles(paginator.pageIndex + 1)">refresh</mat-icon> -->
                    <!-- <button style="margin-left: auto;" [disabled]="!dataSource.data.length" mat-raised-button color="accent" matTooltip="Removes all the uploaded saved files." (click)="deleteAllFiles()">DELETE ALL
                    </button> -->
                </h4>
                <div class="mat-elevation-z8 previousFilesTable">
                    <table mat-table [dataSource]="dataSource.data" multiTemplateDataRows class="w-100">
                        <!-- expand Column -->
                        <ng-container matColumnDef="expand">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                            <td mat-cell *matCellDef="let row" style="width: 50px;">
                                <mat-icon *ngIf="row.file_name != expandedElement && row.versions.length">
                                    keyboard_arrow_right</mat-icon>
                                <mat-icon *ngIf="row.file_name == expandedElement && row.versions.length">
                                    keyboard_arrow_down</mat-icon>
                            </td>
                        </ng-container>

                        <!-- name Column -->
                        <ng-container matColumnDef="fileName">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> File Name </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.file_name}}</td>
                        </ng-container>

                        <!-- status Column -->
                        <ng-container matColumnDef="status">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> File Status </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.status}} <small>({{element.progress}}%)</small> </td>
                        </ng-container>

                        <!-- createdAt Column -->
                        <ng-container matColumnDef="createdAt">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> Created At </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.created_time |
                                date:"MM/dd/yy @h:mm a"}} </td>
                        </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                <div class="example-element-detail" *ngIf="element.versions.length"
                                    [@detailExpand]="element.file_name == expandedElement ? 'expanded' : 'collapsed'">
                                    <div class="inner-table mat-elevation-z8 w-100" *ngIf="expandedElement">
                                        <table mat-table [dataSource]="element.versions" class="w-100">
                                            <!-- Checkbox Column -->
                                            <ng-container matColumnDef="selection">
                                                <th mat-header-cell *matHeaderCellDef>
                                                </th>
                                                <td mat-cell *matCellDef="let row" style="width: 50px;">
                                                    <mat-checkbox
                                                        *ngIf="row.clustering_status!='Uploading In Progress' && row.uu_id>1"
                                                        (click)="$event.stopPropagation()"
                                                        (change)="$event ? selection.toggle(row.name+'_'+row.version_name) : null"
                                                        [checked]="selection.isSelected(row.name+'_'+row.version_name)">
                                                    </mat-checkbox>
                                                </td>
                                            </ng-container>

                                            <!-- Name Column -->
                                            <ng-container matColumnDef="filename">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    Version Name
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <div [ngClass]="row.uu_id==1?'grey-out':''">{{row.version_name}}</div>
                                                </td>
                                            </ng-container>

                                            <!-- Status Column -->
                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    Clustering Status
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <ng-container *ngIf="row.uu_id!=1">
                                                        {{row.clustering_status}} <small>({{row.clustering_progress}}%)</small>
                                                    </ng-container>
                                                    
                                                    <div  *ngIf="row.uu_id==1" [ngClass]="row.uu_id==1?'grey-out':''">
                                                        Clustering cannot be run on original version.
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <!-- Created At -->
                                            <ng-container matColumnDef="createdAt">
                                                <th mat-header-cell *matHeaderCellDef>Created At</th>
                                                <td mat-cell *matCellDef="let row">
                                                    <div [ngClass]="row.uu_id==1?'grey-out':''">{{row.created_time | date:"MM/dd/yy @h:mm a"}}</div>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="innerColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: innerColumns;"></tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                            [class.example-element-row]="element.versions.length"
                            [class.example-expanded-row]="expandedElement === element.file_name" (click)="toggleRow(element)">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <h6 *ngIf="!dataSource.data.length" style="opacity: 0.4;"
                        class="text-center text-secondary my-3 p-4">
                        No data to show.
                    </h6>
                </div>
                <div class="row d-flex justify-content-between align-items-center mt-4 mx-1">
                    <button [disabled]="checkButtonDisabledCondition()" mat-raised-button color="accent"
                        (click)="openSelectedFileData()">OPEN SELECTED
                    </button>
                    <!-- <mat-paginator [pageSizeOptions]="[5]" [pageSize]="5" (page)="pageChanged()" [length]="allFiles.length" showFirstLastButtons
                        aria-label="Select page">
                    </mat-paginator> -->
                </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- File Uploader ~ END -->

<ng-container *ngIf="step==1 || step==2">
    <!-- Cluster Settings ~ START -->
    <hr class="theme-hr" />
    <mat-accordion>
        <mat-expansion-panel expanded (opened)="step=1" (closed)="step=2">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Cluster Settings
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="m-3">
                <form *ngIf="settingsForm" [formGroup]="settingsForm">
                    <div class="row align-items-start">
                        <div class="col-md-6 pr-5">
                            <strong>Measures</strong>
                            <div class="d-flex w-100 flex-column">
                                <div class="metric-variables-div p-3">
                                    <h6 *ngFor="let item of getMetricVariables()">
                                        {{item}}
                                    </h6>
                                </div>

                                <div class="mt-2">
                                    <button mat-stroked-button color="accent" [disabled]="settingsForm.get('metricVariables').value.length==0"
                                        (click)="settingsForm.get('metricVariables').setValue([])">CLEAR ALL
                                    </button>
                                    <button mat-raised-button color="accent" class="ml-2" (click)="selectMetricVariables()">SELECT MEASURES
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="theme-hr" />

                    <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-between p-0">
                            <div class="col-md-8">
                                <strong>Number of Clusters</strong>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="noOfClusters" type="numeric">
                                </mat-form-field>
                                <small class="text-danger" [ngStyle]="{'opacity': (settingsForm.get('noOfClusters').invalid && (settingsForm.get('noOfClusters').dirty || settingsForm.get('noOfClusters').touched)) ? '1': '0'}">
                                    Number of Clusters must be between 2 & 20.
                                </small>
                            </div>
                            <div class="col-md-4">
                                <mat-checkbox color="warn" formControlName="setClustersAuto">Set Automatically
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end">
                            <mat-checkbox color="warn" formControlName="showAdvancedSettings">
                                <h5 class="m-0">Advanced Settings</h5>
                            </mat-checkbox>
                        </div>
                    </div>

                    <div *ngIf="settingsForm.get('showAdvancedSettings').value"
                        class="row d-flex align-items-center justify-content-between">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <strong>Clustering Technique</strong>
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="technique">
                                    <mat-option *ngFor="let item of clusterTechniques" [value]="item.value">
                                        {{item.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <ng-container *ngIf="settingsForm.get('technique').value=='1'">
                            <div class="col-md-6"></div>
                            <div class="col-lg-6 align-items-center justify-content-between">
                                <strong class="mr-2">No. of Iterations</strong>
                                <div class="row align-items-center justify-content-between m-0">
                                    <div class="d-flex align-items-center">
                                        <mat-form-field appearance="fill">
                                            <input matInput formControlName="noOfIterations" readonly />
                                        </mat-form-field>
                                        <mat-icon class="add-minus-btn ml-2" [ngClass]="settingsForm.get('setIterationsAuto').value?'grayBtn':''" (click)="updateIterations(false)">remove</mat-icon>
                                        <mat-icon class="add-minus-btn" [ngClass]="settingsForm.get('setIterationsAuto').value?'grayBtn':''" (click)="updateIterations(true)">add</mat-icon>
                                    </div>
                                    <div class="d-flex text-right">
                                        <mat-checkbox formControlName="setIterationsAuto" color="warn">Set Iterations
                                            Automatically</mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-3 text-right">
                            </div> -->
                        </ng-container>

                        <ng-container *ngIf="settingsForm.get('technique').value=='2'">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <strong>Calculation Method</strong>
                                <mat-form-field appearance="fill">
                                    <mat-select formControlName="calculationMethod">
                                        <mat-option *ngFor="let item of methods" [value]="item.value">
                                            {{item.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <strong>Distance Calculation Method</strong>
                                <mat-form-field appearance="fill">
                                    <mat-select formControlName="intervalCalculation">
                                        <mat-option *ngFor="let item of intervals" [value]="item.value">
                                            {{item.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <strong>Data Scaling</strong>
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="scaling">
                                    <mat-option *ngFor="let item of dataScaling" [value]="item.value">
                                        {{item.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6 d-flex justify-content-end">
                        <button mat-stroked-button color="accent" (click)="clearSettings()">CLEAR SETTINGS</button>
                        <button mat-raised-button color="accent" class="ml-2" [disabled]="settingsForm.invalid" (click)="runCluster()">RUN CLUSTERING
                        </button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <!-- Cluster Settings ~ END -->

</ng-container>
