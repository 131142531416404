import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateUpdateRoleComponent } from './create-update-role/create-update-role.component';
import { throwError } from 'rxjs';
import { AddButtonRendererComponent } from 'src/common/add-button-renderer';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { CloneRendererComponent } from 'src/common/clone-renderer';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ImageRendererComponent } from 'src/common/image-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  public columnDefs = [
    {
      headerName: 'Role Name',
      field: 'roleName',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openForm.bind(this),
      },
    },
    {
      headerName: 'Status',
      field: 'active',
      minWidth: 50,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
      cellRenderer: 'toggleRenderer',
      cellRendererParams: (params) => {
        const obj = {
          label: params.value ? 'Active' : 'Inactive',
          onChange: this.updateStatus.bind(this),
        }
        return obj;
      },
    },
    {
      headerName: '',
      field: 'delete',
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
      // pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveDialog.bind(this),
      },
      width: 100
    },
    // {
    //   headerName: '',
    //   resizable: false,
    //   sortable: false,
    //   filter: false,
    //   suppressMenu: true,
    //   cellRenderer: 'cloneButtonRenderer',
    //   cellRendererParams: (params) => {
    //     const obj = {
    //       onClick: this.openCloneDialog.bind(this),
    //     }
    //     return obj;
    //   },
    // },
    {
      headerName: 'Users',
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
      field: 'pathList',
      cellRenderer: 'imageRenderer',
    },
    {
      headerName: '',
      cellRenderer: 'addButtonRenderer',
      cellStyle: (params) => {
        return { 'text-align': 'left', 'padding': '10px' };
      },
      cellRendererParams: (params) => {
        const obj = {
          onClick: this.openAssociateRoleDialog.bind(this),
        }
        return obj;
      },
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true
    },

  ];showForm: boolean;
  roleData: any;
;
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public roleList: any;

  public user: any;
  headerHeight = 45;
  public rowHeight;
  public defaultColDef;
  public userList = [];
  @ViewChild('cloneDialogTemplate') cloneDialogTemplate: TemplateRef<any>;
  @ViewChild('associateUserRole', { static: false }) associateUserRole: TemplateRef<any>;

  constructor(
    public router: Router,
    public userService: UsersService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public configurationService: ConfigurationService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        'toggleRenderer': ToggleButtonRendererComponent,
        'nameRenderer': NameRendererComponent,
        'deleteButtonRenderer': ButtonRendererComponent,
        'cloneButtonRenderer': CloneRendererComponent,
        'imageRenderer': ImageRendererComponent,
        'addButtonRenderer': AddButtonRendererComponent,

      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    }
  }
  async ngOnInit() {
    const userRespone = await this.userService.GetAllUsers({ userTypeId: 1 }).toPromise().catch(error => throwError(error));
    if (userRespone && userRespone.data) {
      this.userList = userRespone.data;
    }
    this.getRoleList();
    this.userService.listUpdate$.subscribe(res => {
      if (res) {
        this.getRoleList();
      }
    })
  }
  openModal(event) {
    const roleComponent = {
      menuId: `840`,
      name: 'Role Setting',
      selector: 'app-create-update-role',
      displayName: 'Role Setting',
      data: event,
      component: CreateUpdateRoleComponent
    }
    this.configurationService.menuRendererSubject.next(roleComponent);
  }
  public getRoleList = () => {
    const params = {
      active: true
    };
    this.userService.GetUserRolesAll().subscribe(res => {
      this.roleList = (res as any).data;
      this.roleList = this.roleList.map(role => this.mapTeamList(role))
    });
  }
  public mapTeamList = (role) => {
    const ids = role.applicationUser.map(el => el.userId);
    return {
      ...role,
      oldUsers: role.applicationUser,
      applicationUser: this.userList.filter(user => ids.includes(user.userId)),
      pathList: role.applicationUser.map(el => el.documentPath)
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.rowHeight = 50;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {

    this.router.navigate(['addRole'], {
      queryParams: {
        id: btoa($event.data.roleId)
      }
    });
  }
  // public navigateToRoles = () => {
  //   const roleComponent = {
  //     menuId: `840`,
  //     name: 'Role Setting',
  //     selector: 'app-create-update-role',
  //     displayName: 'Role Setting',
  //     component: CreateUpdateRoleComponent
  //   }
  //   this.configurationService.menuRendererSubject.next(roleComponent);
  // }
  openRemoveDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.roleName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeRole(row.rowData);
      }
    });
  }
  openCloneDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(this.cloneDialogTemplate, {
      width: '500px',
      data: { ...row.rowData, cloneObject: { roleName: '', roleNameCode: '', claimGroups: [], active: true } }
    });
  }
  public removeRole = (row) => {
    const model = {
      roleId: row.roleId,
      isDeleted: true,
      active: true
    }
    this.userService.ActivateRole(model).subscribe(res => {
      this.toastr.success('', 'Role deleted successfully');
      this.getRoleList();
    });
  }
  public updateStatus = (row) => {
    const model = {
      roleId: row.rowData.roleId,
      active: row.event.checked,
      isDeleted: false,
    }
    this.userService.ActivateRole(model).subscribe(res => {
      this.toastr.success('', 'Role status updated successfully');
      this.getRoleList();
    });
  }
  public saveAndClose = (row, errorTemplate: TemplateRef<any>, errorRoleCodeTemplate: TemplateRef<any>) => {
    if (row.cloneObject && row.cloneObject.roleName && row.cloneObject.roleNameCode) {
      if (!this.checkIfAlreadyExists(row.cloneObject)) {
        if (!this.checkIfRoleCodeAlreadyExists(row.cloneObject)) {
          const requestVM = {
            ...row.cloneObject,
            active: true
          };
          this.spinner.show();
          this.userService.CreateRole(requestVM).subscribe(res => {
            if (res) {
              this.userService.GetRoleById(row.roleId).subscribe(role => {
                const clone = {
                  ...requestVM,
                  roleId: res,
                  claimGroups: role.claimGroups
                };
                this.userService.UpdateRole(clone).subscribe(response => {
                  this.spinner.hide();
                  this.confirmationDialogRef.close();
                  this.getRoleList();
                });
              }, error => {
                this.spinner.hide();
              })
            }
          }, error => {
            this.spinner.hide();
          })
        }
        else {
          this.dialogRef = this.dialog.open(errorRoleCodeTemplate);
        }

      } else {
        this.dialogRef = this.dialog.open(errorTemplate);
      }
    }
  }
  checkIfAlreadyExists(role) {
    return this.roleList.find(row => row.roleName.toLowerCase() === role.roleName.toLowerCase());
  }
  checkIfRoleCodeAlreadyExists(role) {

    return this.roleList.find(row => row.roleNameCode.toLowerCase() === role.roleNameCode.toLowerCase());
  }
  public exportExcel = () => {
    try {
      this.gridApi.exportDataAsExcel();
    }
    catch {
      this.gridApi.exportDataAsCsv();
    }
  }
  public openAssociateRoleDialog = (rowModel) => {
    this.dialogRef = this.dialog.open(this.associateUserRole, { width: '500px', data: rowModel.rowData })
    this.dialogRef.afterClosed().subscribe(res => {
      if (res && res.applicationUser) {
        const userIds = rowModel.rowData.oldUsers.map(el => el.userId);
        const newUsers = res.applicationUser.filter(el => !userIds.includes(el.userId)).map(user => user.userId);
        if (newUsers && newUsers.length > 0) {
          newUsers.forEach(userId => {
            const model = {
              ...rowModel.rowData,
              userId
            }
            this.userService.AddUserRole(model).subscribe(res => { });
          });
        }
      }
    });
  }
  public closePanel = () => {
    this.showForm = false;
  }
  openForm(rowModel) {
    this.showForm = false;
    this.configurationService.roleModel = JSON.stringify(rowModel)
    this.showForm = true;

  }
  updateList(){
    this.getRoleList();
  }
}
