import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { CLIENT_INFO, JOURNEY_MY_LIST, USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { JourneyShareComponent } from '../journey-share/journey-share.component';
import { JourneyModel } from '../journey.model';
import { CreateJourneyComponent } from '../create-journey/create-journey.component';

import { ColumnApi, ColumnState, GridApi, GridOptions } from 'ag-grid-community';
import { defaultColDef, getMyJourneyColDefs } from '../ag-grid/grid-options';
import { PersistentContextService } from 'src/services/persistent-context-services/persistent-context.service';
import { MyJourneyActionCellRenderer } from '../ag-grid/my-journey-action-cell-renderer.component';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';

export interface PeriodicElement {
  name?: string;
  action?: string;
  createdBy?: number;
  createdOn?: Date;
  journeyId?: number;
  journeyName?: string;
  originalGroupId?: string;
  originalReportId?: string;
  originalReportName?: string;
}

@Component({
  selector: 'app-my-journey',
  templateUrl: './my-journey.component.html',
  styleUrls: ['./my-journey.component.scss'],
})
export class MyJourneyComponent implements OnInit, OnDestroy {

  paginationPageSize = 20;
  cacheBlockSize = 20;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  public defaultColDef = defaultColDef;
  public columnDefs = getMyJourneyColDefs({}, this);
  gridData: any = [];
  frameworkComponents = {
    myJourneyActionCellRenderer: MyJourneyActionCellRenderer
  };
  public gridColumnState: ColumnState[] = [];
  public actionSubscription: Subscription;
  contextLogs: any[] = [];
  screenPreference: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: any,
    public dialogRef: MatDialogRef<any>,
    public userService: UsersService,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private persistentContextService: PersistentContextService,
    private userPreferenceService: UserPreferenceService
  ) { }

  public get data(): any {
    return this._data;
  }
  public set data(value: any) {
    this._data = value;
  }

  ngOnInit() {
    this.fetchMyJourneys();
    this.actionSubscription = this.persistentContextService.getMyJourneyGridActionSubject().subscribe(res => {
      if (res) {
        this.performGridAction(res);
      }
    });
  }

  performGridAction(actionObject) {
    switch (actionObject.action) {
      case 'delete': {
        this.deleteJourney(actionObject.data);
        break;
      }
      case 'edit': {
        this.editJourney(actionObject.data);
        break;
      }
      case 'visibility': {
        this.viewJourney(actionObject.data);
        break;
      }
      case 'share': {
        this.shareJourney(actionObject.data);
        break;
      }
    }
  }

  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(JOURNEY_MY_LIST, JOURNEY_MY_LIST);
    this.gridColumnState = this.screenPreference.columnState ? JSON.parse(this.screenPreference.columnState) : [];
    this.colApi.applyColumnState({ state: this.gridColumnState });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.getUserSecreenPrefereence();
  }

  onGridStateChange(e) {
    this.gridColumnState = this.colApi.getColumnState();
    this.screenPreference.columnState = this.gridColumnState;
    this.updateScreenPreference();
  }

  filterChanged(params) {
    this.getBottomTotalRowPinned();
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertColumnStatePreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  private fetchMyJourneys() {
    this.configService
      .GetJourneyByUserId(this.storage.get(USER_ID))
      .subscribe((res: any[]) => {
        this.gridData = res;
        this.getBottomTotalRowPinned();
      });
  }

  getBottomTotalRowPinned() {
    this.gridApi.setPinnedBottomRowData([]);
    setTimeout(() => {
      // get the total number of rows
      const rowCount = this.gridApi.getDisplayedRowCount();
      // loop through the displayed rows and get the data
      const rowData: any = [];
      for (let i = 0; i < rowCount; i++) {
        const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
        rowData.push(rowNode.data);
      }
      const assignedValueSum = _.sumBy(rowData, a => a.assignedValue);
      const pinnedRow: any = { journeyId: 'Total:', journeyName: `${rowCount} Journeys`, assignedValue: assignedValueSum };
      this.gridApi.setPinnedBottomRowData([pinnedRow]);
    }, 300);
  }

  editJourney(element: any) {
    const dialogRef = this.dialog.open(CreateJourneyComponent, {
      restoreFocus: false,
      width: '600px',
      data: {
        isEdit: true,
        journey: element
      }
    });
    dialogRef.afterClosed().subscribe((journey: JourneyModel) => {
      if (journey) {
        // save journey to database and get journeyId in this.journeyId
        journey.clientConfigurationId = this.storage.get(CLIENT_INFO).clientConfigurationId;
        journey.instance = this.document.baseURI;
        journey.modifyBy = this.storage.get(USER_ID);

        this.configService.updateJourney(journey).subscribe((journeyId: number) => {
          this.toastr.success(`Journey Updated`, `${journey.journeyName}`);
          this.updateEditedRow(journey, element);
        });
      }
    });
  }

  updateEditedRow(updated: any, old: any) {
    const indexOfElement = this.gridData.findIndex(a => a.journeyId === old.journeyId);
    if (indexOfElement >= 0) {
      this.gridData[indexOfElement] = { ...old, ...updated };
      this.gridApi.setRowData(this.gridData);
      this.getBottomTotalRowPinned();
    }
  }

  viewJourney(element: any) {
    console.log('element', element);
    if (element?.metaData) {
      const data = JSON.parse(element.metaData);
      data.isPowerBiReport = true;
      data.createdBy = element.createdBy;
      data.createdOn = element.createdOn;
      data.journeyId = element.journeyId;
      data.journeyName = element.journeyName;
      data.originalGroupId = element.originalGroupId;
      data.originalReportId = element.originalReportId;
      data.originalReportName = element.originalReportName;
      this.configService.viewJourney.emit(data);
      this.dialogRef.close();
    }
  }

  deleteJourney(element) {
    console.log('delete-element', element);
    element.clientConfigurationId = this.storage.get(CLIENT_INFO).clientConfigurationId;
    element.instance = this.document.baseURI;
    if (confirm(`Please confirm you want to delete ${element.journeyName}?`)) {
      this.configService
        .DeleteJourney(element)
        .subscribe((res: any) => {
          if (res) {
            const indexOfElement = this.gridData.findIndex(a => a.journeyId == element.journeyId);
            if (indexOfElement >= 0) {
              this.gridData.splice(indexOfElement, 1);
              this.gridApi.setRowData(this.gridData);
              this.getBottomTotalRowPinned();
              this.toastr.success(
                'Journey Removed',
                `${element.journeyName} removed successfully`
              );
              this.getContextLog(element);
            }
          }
        });
    }
  }

  shareJourney(element) {
    const dialogRef = this.dialog.open(JourneyShareComponent, {
      restoreFocus: false,
      width: '500px',
      data: { journeyId: element.journeyId, createdBy: element.createdBy, sharedBy: this.storage.get(USER_ID) }
    });
  }

  getContextLog(element: any) {
    this.configService
      .GetContextLog(element.journeyId)
      .subscribe((contexts: any[]) => {
        contexts.forEach((context) => {
          this.contextLogs.push({
            name: context.displayName,
            actualName: context.actualName,
            reportId: context.reportId,
            groupId: context.groupId,
            Id: context.id,
          });
        });

        this.contextLogs.forEach((tab: any) => {
          this.configService
            .DeleteReportByGroupId(tab.groupId, tab.reportId)
            .subscribe((res: any) => {
              this.configService
                .DeleteContextLog(tab)
                .subscribe((res: any) => { });
            });
        });

        this.contextLogs = [];
      });
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
  }

}
