<div>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <mat-label *ngIf="isAdd">
        <strong> Create a Channel </strong>
      </mat-label>
      <mat-label *ngIf="!isAdd">
        <strong> Manage Channel </strong>
      </mat-label>
      <br>
      <br>
      <div class="message-search-wrapper">
        <div class="form-group" class="form-fields show-search">
          <mat-label>Search for Members</mat-label>
          <mat-form-field>
            <input matInput (keyup)="onFilterTextBoxChanged($event)" autocomplete="off">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div class="row">
        <div class="col-md-6">
          <mat-label> Select Members to Invite</mat-label>
        </div>
        <div class="col-md-6">
          <mat-label> Selected Members</mat-label>
        </div>
      </div>
      <br>
      <mat-divider></mat-divider>
    </div>
    <div class="col-md-1"></div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10" *ngIf="isAdd">
      <div *ngIf="applicationUsersDetails" class="col-md-6 scrollbar scrollbar-primary">
        <mat-list *ngFor="let users of applicationUsersDetails">
          <mat-selection-list #shoes (selectionChange)="selectionChange($event)">
            <mat-list-option checkboxPosition="after" [value]="users" [selected]="users.selected">
              <img matListAvatar class="rounded-circle img-fluid" style="float: left; max-width: 20%;"
                [src]="users.documentPath || 'assets/images/thumbnail.png'" alt="user Image">
              {{users.userFullName}}
            </mat-list-option>
          </mat-selection-list>
        </mat-list>
      </div>
      <div *ngIf="selectedArrayData.length > 0" class="col-md-6 scrollbar scrollbar-primary">
        <mat-list *ngFor="let users of selectedArrayData">
          <mat-selection-list #shoes (selectionChange)="selectionChange($event)">
            <mat-list-option checkboxPosition="after" [value]="users" [selected]="true">
              <img matListAvatar class="rounded-circle img-fluid" style="float: left; max-width: 20%;"
                [src]="users.documentPath || 'assets/images/thumbnail.png'" alt="user Image">
              <span *ngIf="users.userFullName">{{users.userFullName}}</span>
              <span *ngIf="!users.userFullName">{{users.firstName + ' ' + users.lastName}}</span>
            </mat-list-option>
          </mat-selection-list>
        </mat-list>
      </div>
    </div>
    <div class="col-md-10" *ngIf="!isAdd">
      <div *ngIf="applicationUsersDetails" class="col-md-6 scrollbar scrollbar-primary">
        <mat-list *ngFor="let users of applicationUsersDetails">
          <mat-selection-list #shoes (selectionChange)="selectionChange($event)"
            [disabled]="data?.formData.createdById !==userId">
            <mat-list-option checkboxPosition="after" [value]="users" [selected]="users.selected">
              <img matListAvatar class="rounded-circle img-fluid" style="float: left; max-width: 20%;"
                [src]="users.documentPath || 'assets/images/thumbnail.png'" alt="user Image">
              {{users.userFullName}}
            </mat-list-option>
          </mat-selection-list>
        </mat-list>
      </div>
      <div *ngIf="selectedArrayData.length > 0" class="col-md-6 scrollbar scrollbar-primary">
        <mat-list *ngFor="let users of selectedArrayData">
          <mat-selection-list #shoes (selectionChange)="selectionChange($event)"
            [disabled]="data?.formData.createdById !==userId">
            <mat-list-option checkboxPosition="after" [value]="users" [selected]="true">
              <img matListAvatar class="rounded-circle img-fluid" style="float: left; max-width: 20%;"
                [src]="users.documentPath || 'assets/images/thumbnail.png'" alt="user Image">
              <span *ngIf="users.userFullName">{{users.userFullName}}</span>
              <span *ngIf="!users.userFullName">{{users.firstName + ' ' + users.lastName}}</span>
            </mat-list-option>
          </mat-selection-list>
        </mat-list>
      </div>
    </div>
    <div class="col-md-1"></div>
    <mat-divider></mat-divider>
  </div>
  <br>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <mat-divider></mat-divider>
    </div>
    <div class="col-md-1"></div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div class="message-search-wrapper">
        <div class="form-group" class="form-fields show-search">
          <form [formGroup]="group">
            <mat-label *ngIf="isAdd">Name Channel (Optional)</mat-label>
            <mat-label *ngIf="!isAdd">Rename Channel</mat-label>
            <mat-form-field>
              <input matInput formControlName="channelName" type="text" maxlength="80" autocomplete="off" />
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div class="action-panels text-right">
        <a mat-stroked-button color="accent" (click)="onCancel()">Cancel</a>
        <button *ngIf="isAdd" mat-raised-button color="accent" class="ml-1" (click)="onSubmit()">
          Create Channel
        </button>
        <button *ngIf="!isAdd" mat-raised-button color="accent" class="ml-1"
          [disabled]="data?.formData.createdById !==userId"
          (click)="openUpdateDialog(updateDialog); $event.stopPropagation()">
          Update
        </button>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
<ng-template #updateDialog>
  <mat-icon style="float: right;" (click)="matDialogRef.close()">clear</mat-icon>
  <div mat-dialog-content>
    <mat-label> <strong>
        New members will be able to view the entire history of this channel, would you like to proceed?
      </strong>
    </mat-label>
  </div>
  <div class="action-panel text-center" style="margin-left:15px; margin-right:15px;">
    <button mat-raised-button color="accent" type="submit" class="d-block" (click)="updateDetails()">Yes</button>
    <button mat-stroked-button color="accent" class="ml-1" color="warn" (click)="matDialogRef.close()">Cancel</button>
  </div>
</ng-template>