import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SegmentationService } from 'src/services/segmentation.service';
import { SEGMENTATION_OUTLIER_ID } from '../../constants/columnVariables';
import { SelectMeasuresComponent } from '../select-measures/select-measures.component';
import { SortByPopupComponent } from '../sort-by-popup/sort-by-popup.component';

interface ISegmentCol {_id:string, id: string, segmentName: string, tooltip: string, data: any[], page: 1, total: number, search: '', loading: boolean};

@Component({
  selector: 'app-memberships-table-segmentaion',
  templateUrl: './memberships-table.component.html',
  styleUrls: ['./memberships-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MembershipsTableComponent implements OnInit, AfterViewInit {

  @Input() isOutputTab = true;
  @Input() allSegments = [];
  @Input() sampleData: any;
  @Output() segmentsUpdated = new EventEmitter();
  @Input() allSegmentsUpdated = new Subject();

  dataSource: Array<ISegmentCol>;
  
  searchSubject = new Subject();
  selectedTooltipColumns = [];
  sortTableBy = {
    label: '',
    id: '',
    direction: true // true for Descending, false for Ascending
  };

  draggedObj = {
    rowIndex: '',
    colIndex: ''
  };

  dragDropObj = {
    row_ids: [],
    segmentation_ids: []
  };

  changesMade = false;

  SEGMENTATION_OUTLIER_ID = SEGMENTATION_OUTLIER_ID;

  constructor(private dialog: MatDialog, private toastrService: ToastrService, private segmentationService: SegmentationService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
     // search filter
     this.searchSubject
     .pipe(
       debounceTime(300),
       distinctUntilChanged()
       )
     .subscribe((res: {col, value}) => {
        this.fetchSegmentDataById(this.dataSource[res.col].id, 1, this.dataSource[res.col].search, (this.sampleData.uu_id || '0'), res.col);
     });
  }

  ngAfterViewInit(): void {
    this.arrangeSegments();
    
    this.isOutputTab && this.allSegmentsUpdated
    .subscribe(() => {
     this.arrangeSegments();
    });
  }

  arrangeSegments() {
    this.dataSource = [];
    this.allSegments.forEach((e, index) => {
      const obj = {} as ISegmentCol;
      obj.id = e.uu_id.toString();
      obj.page = 1;
      obj.search = '';
      obj.total = 1;
      obj.segmentName = e.name;
      obj.data = [];
      obj.loading = false;
      obj._id = e._id;
      this.dataSource.push(obj);
    });
    this.reFetchData();
  }

  fetchSegmentDataById(segment_id, page, search, run_tab_index, arrayIndex) {
    if (page == 1) {
      this.dataSource[arrayIndex].data = this.dataSource[arrayIndex].data.filter((item) => item.dragged);
    }
    this.dataSource[arrayIndex].loading = true;
    this.segmentationService.fetchSegmentDatById(this.sampleData.userId, page, this.sampleData.fileName, this.sampleData.versionNo, run_tab_index, this.sampleData.groupby, search, segment_id, this.sortTableBy.id, this.sortTableBy.direction)
    .subscribe((res: any) => {
      res.payload.results.forEach((element: any) => {
        if (this.dragDropObj.row_ids.indexOf(element._id) == -1)
          this.dataSource[arrayIndex].data.push(element);
      });
      this.dataSource[arrayIndex].total = res.payload.count;
      this.dataSource[arrayIndex].page = page;

      this.dataSource[arrayIndex].loading = false;
    }, err => {
      this.dataSource[arrayIndex].loading = false;
    });
  }

  sortBy() {
    const columnsDialogRef = this.dialog.open(SortByPopupComponent, {
      width: '650px',
      data: {
        groupby: this.sampleData.groupby,
        sortTableBy: JSON.parse(JSON.stringify(this.sortTableBy))
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sortTableBy = result;
        this.reFetchData();
      }
    });
  }

  reFetchData() {
    this.allSegments.forEach((e, index) => {
      this.fetchSegmentDataById(e.uu_id, 1, '', (this.sampleData.uu_id || '0'), index);
    });
  }

  showTooltip(item) {
    if (this.selectedTooltipColumns.length == 0) return null;
    else {
      let result = '';
      this.selectedTooltipColumns.forEach((e, index) => {
        if (result) result = result + ', ';
        result = result + e.label + ': ' + item[e.id];
      });
      return result;
    }
  }

  tooltipMeasuresPopup() {
    const selectedTooltipColumns = [];
    this.selectedTooltipColumns.forEach(element => {
      selectedTooltipColumns.push(element.id);
    });
    const columnsDialogRef = this.dialog.open(SelectMeasuresComponent, {
      width: '650px',
      data: {
        groupby: this.sampleData.groupby,
        selectedColumns: selectedTooltipColumns,
        isTooltip: true
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedTooltipColumns = result;
      }
    });
  }

  onDragStart(rowIndex, colIndex) {
    this.draggedObj.rowIndex = rowIndex;
    this.draggedObj.colIndex = colIndex;
  }
  
  onDragOver(event) {
    event.preventDefault();
  }

  onDrop(rowIndex, colIndex, event) {
    if (colIndex == this.draggedObj.colIndex) return; // in case item is moved within the same segment
    // FIND DRAGGED ELEMENT, REMOVE IT FROM ARRAY AND PUSH IT AT DROPPED INDEX
    const draggedElement = this.dataSource[this.draggedObj.colIndex].data[this.draggedObj.rowIndex];
    draggedElement.dragged = true;
    this.dataSource[this.draggedObj.colIndex].data.splice(this.draggedObj.rowIndex, 1);
    this.dataSource[colIndex].data.splice(rowIndex, 0, draggedElement);
    if (this.dragDropObj.row_ids.indexOf(draggedElement['_id']) > -1) {
      const index = this.dragDropObj.row_ids.indexOf(draggedElement['_id']);
      this.dragDropObj.segmentation_ids.splice(index, 1); 
      this.dragDropObj.row_ids.splice(index, 1);
    }
    this.dragDropObj.segmentation_ids.push(this.dataSource[colIndex].id);
    this.dragDropObj.row_ids.push(draggedElement['_id']);
    event && event.preventDefault();
    event && event.stopPropagation();
    this.changesMade = true;
  }

  onDropColumn(colIndex) {
    this.onDrop(0, colIndex, null);
  }

  onLoadMore(item: ISegmentCol, index: number) {
    this.fetchSegmentDataById(item.id, item.page + 1, item.search, (this.sampleData.uu_id || '0'), index);
  }
  
  downloadData() {
    const obj = {
      'user_id': this.sampleData.userId,
      'file_name': this.sampleData.fileName,
      'version_no': this.sampleData.versionNo,
      'run_tab_index': this.sampleData.uu_id
    }
    this.spinner.show();
    this.segmentationService.downloadSegmentationRunsData(obj)
    .subscribe((res: any) => {
      if (res && res.payload) {
        window.open(res.payload, '_blank');
      }
      this.spinner.hide();
    },
    err => {
      this.toastrService.error('Something went wrong!', 'Error');
      this.spinner.hide();
    })
  }
  
  adjustMembership() {
    const segmentationData = [];
    this.dataSource.forEach((element: ISegmentCol) => {
      const segmentationObj = {
        _id: element._id,
        uu_id: element.id,
        name: element.segmentName
      }
      segmentationData.push(segmentationObj);
    });
    const obj =  {
      row_ids: this.dragDropObj.row_ids,
      segmentation_ids: this.dragDropObj.segmentation_ids,
      update_segmentation_labels: segmentationData
    };
    this.spinner.show();
    this.segmentationService.applyAdjustment(obj)
    .subscribe((res: any) => {
      this.toastrService.success('Adjustments saved successfully.');
      this.changesMade = false;
      this.dragDropObj.row_ids = [];
      this.dragDropObj.segmentation_ids = [];
      this.spinner.hide();
      this.segmentsUpdated.next(true);
    },
    err => {
      this.toastrService.error('Failed to save adjustments.');
    this.spinner.hide();
    });
  }
  
  reset() {
    const obj = {
      file_name: this.sampleData.fileName,
      version_no: this.sampleData.versionNo,
      user_id: this.sampleData.userId
    };
    this.spinner.show();
    this.segmentationService.resetAdjustments(obj)
    .subscribe(() => {
      this.toastrService.success('Reset successfully executed.');
      this.changesMade = false;
      this.dragDropObj.row_ids = [];
      this.dragDropObj.segmentation_ids = [];
      this.spinner.hide();
      this.segmentsUpdated.next(true);
    },
    err => {
      this.spinner.hide();
      this.toastrService.error('Failed to reset data.')
    });
  }

}
