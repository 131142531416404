<h1 mat-dialog-title>Confirmation</h1>
<div mat-dialog-content>
  <p>{{confirmationMessage}}</p>
</div>
<div class="action-panel text-right">
  <button *ngIf="data.payload" mat-raised-button class="btn btn-primary m-left" color="primary"
    (click)="saveAndClose()">Confirm Save</button>
  <button *ngIf="!data.payload" mat-raised-button class="btn btn-primary m-left" color="primary"
    (click)="saveAndClose()">Yes</button>
  <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="onNoClick()">No</button>
</div>
