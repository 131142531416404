import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createCustomElement } from '@angular/elements';
import { MaterialModule } from 'src/shared/material.module';
import { ConfigurationService } from 'src/services/configuration.service';
import { Pages } from 'src/common/pages';
import { ChannelMessagingComponent } from './channel-messaging/channel-messaging.component';
import { ChatDetailsComponent } from './chat-details/chat-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChannelsMenuComponent } from './channels-menu/channels-menu.component';

@NgModule({
  declarations: [ ChannelMessagingComponent, ChatDetailsComponent, ChannelsMenuComponent],
  imports: [
    CommonModule,
    MaterialModule,
    InfiniteScrollModule,
  ],
  exports: [ChannelsMenuComponent]
})
export class MessagingModule {

  constructor(private injector: Injector, public state: ConfigurationService) {
    if (!this.state.isMessagingLoaded) {
      this.state.isMessagingLoaded = true;
      const imPagesList = Pages.filter(res => res.module === 'MessagingModule');
      imPagesList.forEach(page => {
        const el = createCustomElement(page.component, { injector });
        customElements.define(page.selector, el);
      });
    }
  }
}
