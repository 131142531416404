
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
// import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: 'app-dropdown-renderer',
  template: `<mat-select *ngIf="isDropDown" [ngModel] = "params.value" (selectionChange)="onClick($event)">
    <mat-option *ngIf = "params.screen === 'Suggest'" value="Approve/Reject">Approve/Reject</mat-option>
    <mat-option  *ngFor = "let item of items" [value]="item" >{{item}}</mat-option>
  </mat-select>
  <span *ngIf="!isDropDown">{{params.value}}</span>`
})

export class DropdownRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;
  items = ['Primary Method Base', 'Expotential Smoothing', 'Linear Regression', `Holt's Double Expotential Smoothing`];
  isDropDown = false;
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    this.items = params.items;
    this.isDropDown = params.isDropDown || false;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onChange instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node
        // ...something
      };
      this.params.onChange(params);
    }
  }
}
