<h2 mat-dialog-title>{{(isEdit||isCurrentWeekEdit)  ? 'Update' : 'Create New'}} Item</h2>
<form [formGroup]="itemForm">
    <div mat-dialog-content>
        <div class="row">

            <div class="col-md-2 label-cls">
                <strong>Age</strong>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill">
                    <input matInput formControlName="weeks_old">
                </mat-form-field>
            </div>


            <ng-container *ngIf="!isEdit">
                <div class="col-md-2 label-cls">
                    <strong>Street</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="street" type="date">
                    </mat-form-field>
                </div>
                <div class="col-md-2 label-cls">
                    <strong>Title</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="title">
                    </mat-form-field>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>Vendor</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="studio">
                    </mat-form-field>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>SKU</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="sku">
                    </mat-form-field>
                </div>


                <div class="col-md-2 label-cls">
                    <strong>UPC</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="upc">
                    </mat-form-field>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>Forecast</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="street_week">
                    </mat-form-field>
                </div>

            </ng-container>

            <div class="col-md-2 label-cls">
                <strong>TW Forecast</strong>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="fill">
                    <input matInput formControlName="tw_forecast">
                </mat-form-field>
            </div>

            <ng-container *ngIf="!isEdit">
                <div class="col-md-2 label-cls">
                    <strong>Group</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="group">
                    </mat-form-field>
                </div>


                <div class="col-md-2 label-cls">
                    <strong>Min Facings</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="minimum_facing">
                    </mat-form-field>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>Max Facings</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="maximum_facing">
                    </mat-form-field>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>Enforce Top Row?</strong>
                </div>
                <div class="col-md-4 label-cls">
                    <mat-checkbox color="accent" formControlName="enforce_top"></mat-checkbox>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>FOSNR Enforcement</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <mat-select formControlName="planogram_side">
                            <mat-option *ngFor="let side of enforcementDropDownOptions" [value]="side">
                                {{side}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngIf="isEdit">
                <div class="col-md-2 label-cls">
                    <strong>On Hand</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="on_hand">
                    </mat-form-field>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>On Order</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="on_order">
                    </mat-form-field>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>Base CA</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="base_ca">
                    </mat-form-field>
                </div>

                <div class="col-md-2 label-cls">
                    <strong>Calc CA</strong>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="calc_ca">
                    </mat-form-field>
                </div>
            </ng-container>

        </div>

    </div>
</form>
<div mat-dialog-actions style="justify-content: flex-end;">
    <button type="button" mat-button mat-flat-button color="accent" [disabled]="loading" (click)="close()">Close</button>
    <button type="submit" mat-button mat-flat-button color="accent" (click)="save()" [disabled]="itemForm.invalid || loading">{{loading?'Saving...':'Save'}}</button>
</div>