import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlotlyHTMLElement } from 'plotly.js';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { USER_INFO } from 'src/common/keys';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { generateDenogramTicks } from '../../../constants/clusteringHelpers';
import * as _ from 'lodash';
import { colorCodes } from 'src/modules/clustering-forecast/constants/graphColors';

@Component({
  selector: 'cluster-output-tab',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss']
})
export class OutputComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Output() saveRunOutput = new EventEmitter();
  @Input() sampleData: any;
  @Input() resizeGraphSubject: Subject<boolean>;
  @Input() versionNo: any;

  @ViewChild("outputGraphDiv", { static: false }) outputGraphDiv: ElementRef;
  @ViewChild("radarChart", { static: false }) radarChart: ElementRef;

  clusterStatistics: any;
  perClusterCount: any;
  fileName = '';
  objectToCluster = '';
  summaryStatsMetricVariables = [];
  clusteringLabels = [];
  clusterSettings: any;

  dendrogramUP = {
    data: [] as any,
    layout: {
      showlegend: false,
      xaxis: {
        title: '',
        showticklabels: true,
        tickmode: "array",
        ticks: "outside",
        showgrid: false,
        mirror: "allticks",
        zeroline: false,
        showline: false,
        ticktext: [],
        rangemode: "tozero",
        type: "linear",
        tickvals: []
      },
      yaxis: {
        title: 'Distance',
        showticklabels: true,
        ticks: "outside",
        showgrid: false,
        mirror: "allticks",
        zeroline: false,
        showline: false,
        rangemode: "tozero",
        type: "linear",
        // tickvals: [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0]
      },
      hovermode: "closest",
      autosize: true
    }
  };

  elbowChart = {
    data: [{ x: [], y: [], type: 'scatter', showlegend: false, marker: { color: 'red' }, name: '' }],
    layout: {
      autosize: true,
      xaxis: {
        title: {
          text: 'Number of Clusters K',
        }
      },
      yaxis: {
        title: {
          text: 'Sum of squared distance',
        }
      }
    }
  };

  radarPlot = {
    data: [],
    layout: {
      autosize: true
    }
  };

  graphTitle = '';

  metrices = [];

  userObj = {} as any;
  isAdjusted = false;

  adjustedData = {
    row_ids: [],
    cluster_ids: []
  };

  spiderVariables = [];
  disableSaveRunCheck = false;
  spiderChartResponse = [] as any;

  colorCodes = colorCodes;

  _unsubscribeAll = new Subject<void>();
  spiderPlotSubscription: Subscription;


  constructor(private plotlyRef: PlotlyService, private spinner: NgxSpinnerService, private clusteringService: ClusteringForecastService, private storage: LocalstorageService) {
  }

  ngOnInit(): void {
    this.userObj = this.storage.get(USER_INFO);
    // for window resize handling (graph responsiveness)
    fromEvent(window, 'resize').pipe(
      takeUntil(this._unsubscribeAll),
      debounceTime(250))
      .subscribe(() => this.refreshGraph());

    this.resizeGraphSubject
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(250))
      .subscribe(() => this.refreshGraph());
  }

  fetchSpiderChartData() {

    const matrixColumnIds = [];
    this.metrices.forEach(element => {
      matrixColumnIds.push(element.id);
    });
    const obj = {
      file_name: this.fileName,
      user_id: this.userObj.userId,
      run_tab_index: 'Cluster_Id_adj',
      matrix_columns: matrixColumnIds,
      version_no: this.versionNo
    };
    this.spiderPlotSubscription && this.spiderPlotSubscription.unsubscribe();
    this.spiderPlotSubscription = this.clusteringService.fetchSpiderChartData(obj)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.spiderChartResponse = res.payload;
        this.plotSpiderChart(res.payload);
      },
        err => {

        });
  }

  plotSpiderChart(data) {
    if (data) {
      const spiderChartData = [];
      const theta = [];

      data.columns.forEach(element => {
        if (this.spiderVariables.indexOf(element) > -1) {
          this.metrices.forEach((metricVariable) => {
            metricVariable.id == element && theta.push(metricVariable.name);
          });
        }
      });

      for (let i = 0; i < data.cluster_ids.length; i++) {
        let clusterName = '';
        this.clusteringLabels.forEach(element => {
          (element.label_id == data.cluster_ids[i]) ? clusterName = element.label_name : null;
        });
        const r = data.spider_plot[i];

        spiderChartData.push({
          r: r,
          theta: theta,
          type: 'scatterpolar',
          fill: 'toself',
          name: clusterName,
          marker: { color: this.colorCodes[(i) % 20] }
        });
      }
      this.radarPlot.data = spiderChartData;
      this.appendRedarChart();
    }
  }

  patchData(data: any) {
    this.fileName = data.fileName;
    this.perClusterCount = data.per_cluster_count;
    this.objectToCluster = data.objectToCluster;
    this.clusterStatistics = data.cluster_statistics;
    if (data.elbow_chart) {
      data.elbow_chart.user_k = this.clusterSettings.setClustersAuto ? -1 : this.clusterSettings.noOfClusters;
      this.elbowChart.data[0].x = data.elbow_chart.x;
      this.elbowChart.data[0].y = data.elbow_chart.y;
      const userKIndex = _.findIndex(data.elbow_chart.x, a => a == data.elbow_chart.user_k);
      const bestKIndex = _.findIndex(data.elbow_chart.x, a => a == data.elbow_chart.best_k);
      this.elbowChart.data[1] = { x: [data.elbow_chart.best_k], y: [data.elbow_chart.y[bestKIndex]], type: 'scatter', name: `Best K ${data.elbow_chart.best_k}`, marker: { size: 10, color: 'purple' } } as any;
      if (userKIndex > -1) {
        this.elbowChart.data[2] = { x: [data.elbow_chart.user_k], y: [data.elbow_chart.y[userKIndex]], type: 'scatter', name: `User K ${data.elbow_chart.user_k}`, marker: { size: 10, color: 'green' } } as any;
      }
      this.plotGraph(this.elbowChart.data, this.elbowChart.layout);
      this.graphTitle = 'Elbow Chart';
    } else {
      this.dendrogramUP.layout.xaxis.title = this.objectToCluster;
      for (let i = 0; i < data.dendogram_chart.icoord.length; i++) {
        const dataObj = {
          yaxis: "y",
          y: data.dendogram_chart.dcoord[i],
          mode: "lines",
          xaxis: "x",
          x: data.dendogram_chart.icoord[i],
          type: "scatter"
        };
        this.dendrogramUP.data.push(dataObj);
      }
      this.dendrogramUP.layout.xaxis.tickvals = generateDenogramTicks(data.dendogram_chart.icoord, data.dendogram_chart.dcoord);
      this.dendrogramUP.layout.xaxis.ticktext = data.dendogram_chart.ivl;
      this.plotGraph(this.dendrogramUP.data, this.dendrogramUP.layout);
      this.graphTitle = 'Dendrogram';
    }
    this.fetchSpiderChartData();
  }

  ngOnChanges() {
    this.readAndExecuteDataFromSettingsTab();
  }

  ngAfterViewInit() {
    this.isAdjusted = this.sampleData.isAdjusted;
    this.readAndExecuteDataFromSettingsTab();
    this.clusteringService.adjustMembershipSubject
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        if (!res) {
          this.isAdjusted = false;
          this.reset();
        }
        else {
          this.isAdjusted = true;
          this.perClusterCount = res.per_cluster_count;
        }
      });
  }

  readAndExecuteDataFromSettingsTab() {
    if (!this.sampleData) return;
    setTimeout(() => {
      this.metrices = this.sampleData.metrices;
      this.clusteringLabels = JSON.parse(JSON.stringify(this.sampleData.clustering_labels));
      this.clusterSettings = this.sampleData.clustering_settings;
      this.metrices.forEach((element, index) => {
        if (index < 10) {
          this.spiderVariables.push(element.id);
        }
      });
      this.patchData(this.sampleData.data);
    }, 300);
  }

  appendRedarChart() {
    this.plotGraph(this.radarPlot.data, this.radarPlot.layout, this.radarChart);
    setTimeout(() => {
      this.refreshGraph();
    }, 200);
  }

  variablesSelectionChanged() {

    const spiderChartData = [];
    const theta = [];

    this.spiderChartResponse.columns.forEach(element => {
      if (this.spiderVariables.indexOf(element) > -1) {
        this.metrices.forEach((metricVariable) => {
          metricVariable.id == element && theta.push(metricVariable.name);
        });
      }
    });

    for (let i = 0; i < this.spiderChartResponse.cluster_ids.length; i++) {
      let clusterName = '';
      this.clusteringLabels.forEach(element => {
        (element.label_id == this.spiderChartResponse.cluster_ids[i]) ? clusterName = element.label_name : null;
      });
      const r = this.spiderChartResponse.spider_plot[i];

      const spiderChartDataObj = {
        r: r,
        theta: theta,
        type: 'scatterpolar',
        fill: 'toself',
        name: clusterName,
        marker: { color: this.colorCodes[(i) % 20] }
      } as any;
      this.radarPlot.data[i].visible && (spiderChartDataObj.visible = this.radarPlot.data[i].visible);
      spiderChartData.push(spiderChartDataObj);
    }
    this.radarPlot.data = spiderChartData;
    setTimeout(() => {
      this.refreshGraph();
    }, 100);
  }

  plotGraph(data, layout, elementRef?: ElementRef) {
    this.plotlyRef.newPlot(
      elementRef ? elementRef.nativeElement : this.outputGraphDiv.nativeElement,
      data,
      layout
    ).then((res: PlotlyHTMLElement) => {
    });
  }

  refreshGraph() {
    if (this.graphTitle == 'Elbow Chart')
      this.plotlyRef.update(this.outputGraphDiv.nativeElement, this.elbowChart.data, this.elbowChart.layout);
    else
      this.plotlyRef.update(this.outputGraphDiv.nativeElement, this.dendrogramUP.data, this.dendrogramUP.layout);

    // for RADAR CHART
    this.plotlyRef.update(this.radarChart.nativeElement, this.radarPlot.data, this.radarPlot.layout);
  }

  reset() {
    this.readAndExecuteDataFromSettingsTab();
    this.adjustedData.row_ids = [];
    this.adjustedData.cluster_ids = [];
  }

  adjustmentSaved(dragDropObj) {
    if (this.adjustedData.row_ids.length) {
      for (let i = 0; i < dragDropObj.row_ids.length; i++) {
        if (this.adjustedData.row_ids.indexOf(dragDropObj.row_ids[i]) > -1) {
          const index = this.adjustedData.row_ids.indexOf(dragDropObj.row_ids[i]);
          this.adjustedData.row_ids.splice(index, 1);
          this.adjustedData.cluster_ids.splice(index, 1);
        }
      }
    }
    dragDropObj.row_ids.forEach(element => {
      this.adjustedData.row_ids.push(element);
    });
    dragDropObj.cluster_ids.forEach(element => {
      this.adjustedData.cluster_ids.push(element);
    });
    this.clusteringLabels = dragDropObj.clusteringLabels;
    this.patchData(this.sampleData.data);
  }

  saveRun() {
    this.saveRunOutput.next({
      metrices: this.metrices,
      fileName: this.fileName,
      clusteringLabels: this.clusteringLabels,
      perClusterCount: this.perClusterCount,
      objectToCluster: this.objectToCluster,
      summaryStatsMetricVariables: this.summaryStatsMetricVariables,
      clusterStatistics: this.clusterStatistics,
      spiderPlot: []
    });
  }

  downloadAsJPG() {
    (document.getElementsByClassName('modebar-btn')[0] as HTMLElement).click();
  }

  disableSaveRun(e: boolean) {
    this.disableSaveRunCheck = e;
  }

  ngOnDestroy() {
    this.spiderPlotSubscription && this.spiderPlotSubscription.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
  }
}
