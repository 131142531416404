
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-insight-form-v2',
  templateUrl: './insight-form-v2.component.html',
  styleUrls: ['./insight-form-v2.component.css'],
})
export class InsightFormV2Component implements OnInit, OnDestroy {
  public fg: FormGroup;
  public submitted = false;
  public countryList = [];
  public retailerList = [];
  public categoryList = [];
  public divisionList = [];
  public productList = [];
  public metricList = [];
  public metricDropdownList = [];
  public selectedMetricList = [];
  public files = [];
  public isEdit = false;
  public columnDefs = [
    {
      headerName: 'Metric',
      field: 'metricApplicationDescription',
    },
    {
      headerName: 'Value',
      field: 'value',
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80,
    },
  ];
  public gridApi;
  public gridOptions;

  public rightFiles = [];
  public fileModel = {};
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<InsightFormV2Component>,
    public dialogRefUpload: MatDialogRef<any>,
    public matDialog: MatDialog,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public configService: ConfigurationService,
    public toast: NgxToasterService,
    public spinner: NgxSpinnerService,
    public storage: LocalstorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.gridOptions = {
      frameworkComponents: {
        deleteButtonRenderer: ButtonRendererComponent,
      },
      pagination: false,
      paginationAutoPageSize: false,
    };
    this.initializeForm();
  }

  ngOnInit() {
    this.getDefaultDropdowns();
    if (this.data && this.data.applicationInsightsId) {
      Object.keys(this.fg.controls).forEach((key) => {
        this.fg.controls[key].setValue(this.data[key]);
      });
      this.metricList = this.data.metricApplicationInsightsList;
      this.isEdit = true;
    }
    this.addNewMetric();
  }
  ngOnDestroy() {
    this.isEdit = false;
    this.data = null;
  }
  public initializeForm = () => {
    this.fg = this.fb.group({
      applicationInsightsId: [0],
      dateGenerated: [new Date()],
      expiryDate: [],
      expiryCondition: [],
      insights: ['', Validators.required],
      retailerId: ['', Validators.required],
      countryId: ['', Validators.required],
      productId: ['', Validators.required],
      value: ['', Validators.required],
      isPublish: [true],
      isExpired: [false],
      active: [true],
      documentId: [],
      documentPath: [],
      description: [],
      suggestedAction: [],
      graphId: [],
      graphPath: [],
    });
  };
  public getDefaultDropdowns = () => {
    let url = `offSet=${0}&pageSize=${99999}&active=true`;
    this.configService.ItemManagerGetAll(url).subscribe((res) => {
      this.productList = res.data;
    });
    this.GetRetailerList();
    this.GetCountryList();
  };
  public GetRetailerList = () => {
    this.configService
      .AccountSettingMappingChannelGetList({})
      .subscribe((res) => {
        this.retailerList = res;
      });
  };
  public GetCountryList = () => {
    this.configService.AccountSettingMappingCountryGetList({}).subscribe((res) => {
      this.countryList = res;
    });
  };
  onSubmit = (flag) => {
    this.submitted = true;
    if (this.fg.valid) {
      const model = {
        ...this.fg.value,
        metricApplicationInsightsList: this.metricList
          .filter((el) => el.value)
          .map((el) => ({
            ...el,
            applicationInsightsId: this.fg.controls.applicationInsightsId.value,
          })),
      };
      this.spinner.show();
      if (this.isEdit) {
        this.configService.ApplicationInsightsUpdate(model).subscribe(
          (res) => {
            if (res) {
              this.spinner.hide();
              this.toast.success(
                'Application Insights',
                'Application Insight updated successfully'
              );
              if (flag) {
                this.initializeForm();
                this.isEdit = false;
                this.data = null;
                this.submitted = false;
                this.selectedMetricList = [];
                this.gridApi.setRowData(this.selectedMetricList);
              } else {
                this.dialogRef.close(true);
              }
            }
          },
          (error) => {
            this.spinner.hide();
            this.toast.error(
              'Application Insights',
              'Application Insight not updated successfully'
            );
            this.dialogRef.close();
          }
        );
      } else {
        this.configService.ApplicationInsightsAdd(model).subscribe(
          (res) => {
            if (res) {
              this.spinner.hide();
              this.toast.success(
                'Application Insights',
                'Application Insight added successfully'
              );
              if (flag) {
                this.initializeForm();
                this.isEdit = false;
                this.data = null;
                this.submitted = false;
                this.selectedMetricList = [];
                this.gridApi.setRowData(this.selectedMetricList);
              } else {
                this.dialogRef.close(true);
              }
            }
          },
          (error) => {
            this.spinner.hide();
            this.toast.error(
              'Application Insights',
              'Application Insight not added successfully'
            );
            this.dialogRef.close();
          }
        );
      }
    }
  };
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  addMetric() {
    if (
      this.fg.controls['metrics'].value &&
      this.fg.controls['metricValue'].value
    ) {
      const model = {
        metricApplicationInsightsId:
          this.fg.controls['metrics'].value.commonCodeValueId,
        applicationInsightsId: 0,
        metricApplicationDescription: this.fg.controls['metrics'].value.value,
        value: this.fg.controls['metricValue'].value,
        createdById: 1,
      };
      this.selectedMetricList.push(model);
      this.metricDropdownList = this.metricDropdownList.filter(
        (metric) =>
          metric.commonCodeValueId !== model.metricApplicationInsightsId
      );
      this.gridApi.setRowData(this.selectedMetricList);
      this.fg.controls['metrics'].setValue(null);
      this.fg.controls['metricValue'].setValue(null);
    }
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.matDialog.open(
      ConfirmationDialogComponent,
      {
        width: '500px',
        data: { headerName: 'Metric' },
      }
    );

    this.confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeMetric(row.rowData);
      }
    });
  }
  public removeMetric(index: number) {
    this.metricList.splice(index, 1);
  }
  public setExpiredFlag = (date) => {
    if (date && date.value) {
      return date.value > new Date()
        ? this.fg.controls['isExpired'].setValue(false)
        : this.fg.controls['isExpired'].setValue(true);
    }
  };
  onSelect(event, key) {
    console.log(event);
    this.files.push(...event.addedFiles);
    this.files.forEach((file) => {
      this.getFile(file, key);
    });
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  public uploadFile = (file, fileName, key) => {
    this.spinner.show();
    this.configService.DocumentAdd({ file, fileName }).subscribe(
      (res) => {
        if (key === 'documentId') {
          this.fg.controls.documentId.setValue(res.documentId);
          this.fg.controls.documentPath.setValue(res.documentPath);
        } else if (key === 'graphId') {
          this.fg.controls.graphId.setValue(res.documentId);
          this.fg.controls.graphPath.setValue(res.documentPath);
        }

        this.dialogRefUpload.close();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  };
  private getFile(file: any, key) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadFile((reader.result as any).split(',')[1], file.name, key);
    };
    return reader;
  }
  public deleteImage = (control) => {
    if (control === 'Document') {
      this.fg.controls.documentPath.setValue(null);
      this.fg.controls.documentId.setValue(null);
    } else if (control === 'Graph') {
      this.fg.controls.graphPath.setValue(null);
      this.fg.controls.graphId.setValue(null);
    }
  };
  public openImageDialog = (template: TemplateRef<any>, key) => {
    this.dialogRefUpload = this.matDialog.open(template, {
      width: '500px',
      data: key,
    });
    this.dialogRefUpload.afterClosed().subscribe((res) => {
      this.files = [];
    });
  };
  addNewMetric = () => {
    this.metricList.unshift({
      metricApplicationInsightsId: 0,
      metricApplicationDescription: '',
      value: null,
    });
  };
}
