import { Injector, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';


import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxCaptureModule, NgxCaptureService } from 'ngx-capture';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';

import { NgxPowerBiModule, NgxPowerBiService } from 'ngx-powerbi';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxLoadingModule } from 'ngx-loading';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {
  ChildItemActionCellRenderer,
  ChildItemEditRenderer,
  MasterItemActionCellRenderer,
  MasterItemEditRenderer,
  PromotionItemEditRenderer,
  PromotionPlannerEditRenderer,
} from '../modules/item-manager/features';
import { FusionChartsModule } from 'angular-fusioncharts';
// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);
import { TreeviewModule } from 'ngx-treeview';
import { AGNumberEditorComponent } from 'src/common/ag-number-cell-editor';
import { PrivilegeErrorComponent } from 'src/common/privilege-error/privilege-error.component';

import { environment } from 'src/environments/environment';
import { MaterialModule } from './material.module';
import { AuthenticationService } from 'src/services/Authentication-Services/authentication-services';
import { UsersService } from 'src/services/User-services/user-services';
import { AddButtonRendererComponent } from 'src/common/add-button-renderer';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { ImageRendererComponent } from 'src/common/image-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { HttpInterceptorService } from 'src/services/http-interceptor.service';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { LineChartComponent } from 'src/modules/layout/features/charts/line-chart/line-chart.component';
import { CloneRendererComponent } from 'src/common/clone-renderer';
import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';
import { CdkTreeModule } from '@angular/cdk/tree';
import {
  ChildItemService,
  ItemManagerService,
  ItemPromotionService,
  MasterProductService,
  PromotionPlannerService
} from 'src/services/Item-Management-Services';
import { AGColumnDialogComponent } from '../common/ag-column-dialog/ag-column-dialog.component';
import { CheckboxRenderer } from 'src/common/ag-checkbox-renderer';
import { DropdownRendererComponent } from 'src/common/dropdown-renderer';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { BlobStorageService } from 'src/services/azure-storage/blob-storage.service';
import { BLOB_STORAGE_TOKEN, IAzureStorage } from 'src/services/azure-storage/azure-storage';
import { UpdateNameDialogComponent } from 'src/common/update-name-dialog/update-name-dialog.component';
import {
  CommitActionCellRenderer,
  DepromoWeekCellEditor,
  ForecastManagerEditRenderComponent,
  CombinationEditRenderer,
  ForecastDataManagerEditRenderComponent,
  OutlierCustomHeaderGroup,
  ProductItemEditRenderer,
  PromoItemsEditRenderer,
  SeasonalityEditRenderer,
  SeasonalityLineChartCellRenderer,
  LocationSetupEditRenderer
} from 'src/modules/forecast-engin/features';
import { OutlierService } from 'src/services/forecast-services/outlier-service';
import { OutlierLineChartCellRenderer } from 'src/modules/forecast-engin/features/outlier-manager/ag-grid/outlier-line-chart-cell-renderer.component';
import { MappingService } from 'src/services/forecast-services/mapping-service';
import { PromoService } from 'src/services/forecast-services/promo-service';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';
import { TreeViewAccountDropdownComponent } from './select-account/treeview-account-dropdown.component';
import { TreeViewItemHierarchyDropdownComponent } from './select-product-hierarchy/treeview-product-hierarchy-dropdown.component';
import { DemoService } from 'src/services/demo-services/demo-service';
import { ClientPreferenceService } from 'src/services/client-services/client-preference.service';
import { MultiDatepickerModule } from 'src/common/multidatepicker/multidatepicker.module';
import { ForecastItemManagerService } from 'src/services/forecast-services/item-manager.service';
import { ForecastLocationManagerService } from 'src/services/forecast-services/location-manager.service';
import { CombinationManagerService } from 'src/services/forecast-services/combination-manager.service';
import { TreeviewDropdownComponent } from 'src/common/treeview-dropdown/treeview-dropdown.component';
import { ThreeStateCustomToggleButtonComponent } from './three-state-custom-toggle-button/three-state-custom-toggle-button.component';
import { ShowGRNSummaryRenderer } from 'src/modules/procurement/ag-grid/show-grn-summary-renderer.component';
import { MentionModule } from 'angular-mentions';
import { PersistentContextService } from 'src/services/persistent-context-services/persistent-context.service';
import { MyJourneyActionCellRenderer } from 'src/modules/reporting/features/context-log-journey/ag-grid/my-journey-action-cell-renderer.component';
import { ShareJourneyActionCellRenderer } from 'src/modules/reporting/features/context-log-journey/ag-grid/share-journey-action-cell-renderer.component';
import { JourneyLogActionCellRenderer } from 'src/modules/reporting/features/context-log-journey/ag-grid/journey-log-action-cell-renderer.component';

declare var AzureStorage: IAzureStorage;
export let AppInjector: Injector;

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    AgGridModule,
    TreeViewModule,
    TreeGridModule,
    CKEditorModule,
    NgxCaptureModule,
    NgxDropzoneModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    MaterialModule,
    NgxPowerBiModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxLoadingModule,
    FusionChartsModule,
    TreeviewModule.forRoot(),
    HttpClientModule,
    CdkTreeModule,
    MultiDatepickerModule,
    MentionModule
  ],
  providers: [
    AuthenticationService,
    UsersService,
    UserPreferenceService,
    DatePipe,
    NgxCaptureService,
    NgxPowerBiService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    CurrencyPipe,
    ItemManagerService,
    ChildItemService,
    MasterProductService,
    ItemPromotionService,
    PromotionPlannerService,
    BlobStorageService,
    {
      provide: BLOB_STORAGE_TOKEN,
      useValue: AzureStorage.Blob
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    OutlierService,
    MappingService,
    PromoService,
    SeasonalityService,
    ForecastLookupService,
    ForecastManagerService,
    DemoService,
    ClientPreferenceService,
    ForecastItemManagerService,
    ForecastLocationManagerService,
    CombinationManagerService,
    PersistentContextService,
  ],
  declarations: [
    ButtonRendererComponent,
    ConfirmationDialogComponent,
    EditButtonRendererComponent,
    NameRendererComponent,
    MasterItemEditRenderer,
    ChildItemEditRenderer,
    PromotionItemEditRenderer,
    AGNumberEditorComponent,
    ImageRendererComponent,
    AddButtonRendererComponent,
    TreeviewDropdownComponent,
    ChildItemActionCellRenderer,
    MasterItemActionCellRenderer,
    PromotionPlannerEditRenderer,
    PrivilegeErrorComponent,
    LineChartComponent,
    CloneRendererComponent,
    ToggleButtonRendererComponent,
    AGColumnDialogComponent,
    CheckboxRenderer,
    DropdownRendererComponent,
    UpdateNameDialogComponent,
    CommitActionCellRenderer,
    OutlierLineChartCellRenderer,
    PromoItemsEditRenderer,
    LocationSetupEditRenderer,
    DepromoWeekCellEditor,
    SeasonalityEditRenderer,
    TreeViewAccountDropdownComponent,
    TreeViewItemHierarchyDropdownComponent,
    ForecastManagerEditRenderComponent,
    CombinationEditRenderer,
    ForecastDataManagerEditRenderComponent,
    SeasonalityLineChartCellRenderer,
    OutlierCustomHeaderGroup,
    ProductItemEditRenderer,
    ThreeStateCustomToggleButtonComponent,
    ShowGRNSummaryRenderer,
    ThreeStateCustomToggleButtonComponent,
    MyJourneyActionCellRenderer,
    ShareJourneyActionCellRenderer,
    JourneyLogActionCellRenderer,
  ],
  exports: [
    CommonModule,
    ToastrModule,
    AgGridModule,
    TreeViewModule,
    TreeGridModule,
    CKEditorModule,
    NgxCaptureModule,
    NgxDropzoneModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    MaterialModule,
    NgxPowerBiModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    ConfirmationDialogComponent,
    EditButtonRendererComponent,
    NameRendererComponent,
    NgMultiSelectDropDownModule,
    MasterItemEditRenderer,
    ChildItemEditRenderer,
    FusionChartsModule,
    PromotionItemEditRenderer,
    AGNumberEditorComponent,
    TreeviewDropdownComponent,
    ChildItemActionCellRenderer,
    MasterItemActionCellRenderer,
    PromotionPlannerEditRenderer,
    PrivilegeErrorComponent,
    HttpClientModule,
    ButtonRendererComponent,
    EditButtonRendererComponent,
    NameRendererComponent,
    ImageRendererComponent,
    AddButtonRendererComponent,
    LineChartComponent,
    CloneRendererComponent,
    ToggleButtonRendererComponent,
    CdkTreeModule,
    AGColumnDialogComponent,
    CheckboxRenderer,
    DropdownRendererComponent,
    UpdateNameDialogComponent,
    CommitActionCellRenderer,
    OutlierLineChartCellRenderer,
    PromoItemsEditRenderer,
    LocationSetupEditRenderer,
    DepromoWeekCellEditor,
    SeasonalityEditRenderer,
    TreeViewAccountDropdownComponent,
    TreeViewItemHierarchyDropdownComponent,
    ForecastManagerEditRenderComponent,
    CombinationEditRenderer,
    ForecastDataManagerEditRenderComponent,
    SeasonalityLineChartCellRenderer,
    MultiDatepickerModule,
    OutlierCustomHeaderGroup,
    ProductItemEditRenderer,
    ThreeStateCustomToggleButtonComponent,
    ShowGRNSummaryRenderer,
    ThreeStateCustomToggleButtonComponent,
    MentionModule,
    MyJourneyActionCellRenderer,
    ShareJourneyActionCellRenderer,
    JourneyLogActionCellRenderer,
  ],
  entryComponents: [
    ButtonRendererComponent,
    ConfirmationDialogComponent,
    EditButtonRendererComponent,
    NameRendererComponent,
    MasterItemEditRenderer,
    ChildItemEditRenderer,
    PromotionItemEditRenderer,
    AGNumberEditorComponent,
    ImageRendererComponent,
    AddButtonRendererComponent,
    TreeviewDropdownComponent,
    ChildItemActionCellRenderer,
    MasterItemActionCellRenderer,
    PromotionPlannerEditRenderer,
    PrivilegeErrorComponent,
    LineChartComponent,
    CloneRendererComponent,
    ToggleButtonRendererComponent,
    AGColumnDialogComponent,
    DropdownRendererComponent,
    UpdateNameDialogComponent,
    CommitActionCellRenderer,
    OutlierLineChartCellRenderer,
    PromoItemsEditRenderer,
    LocationSetupEditRenderer,
    DepromoWeekCellEditor,
    SeasonalityEditRenderer,
    TreeViewAccountDropdownComponent,
    TreeViewItemHierarchyDropdownComponent,
    ForecastManagerEditRenderComponent,
    CombinationEditRenderer,
    ForecastDataManagerEditRenderComponent,
    SeasonalityLineChartCellRenderer,
    OutlierCustomHeaderGroup,
    ProductItemEditRenderer,
    ShowGRNSummaryRenderer,
    MyJourneyActionCellRenderer,
    ShareJourneyActionCellRenderer,
    JourneyLogActionCellRenderer,
  ],
})
export class SharedModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
