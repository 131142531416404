import { GridApi } from 'ag-grid-enterprise';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { INVALID_TEAM, PROMOTION_PLANNER_LIST, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { ItemManagerService } from 'src/services/Item-Management-Services';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { UsersService } from 'src/services/User-services/user-services';

@Component({
    selector: 'app-promotion-planner-filters',
    templateUrl: './promotion-planner-filters.component.html',
    styleUrls: ['./promotion-planner-filters.component.css']
})
export class PromotionPlannerFiltersComponent implements OnInit, OnDestroy {

    @Output() filterEvent = new EventEmitter<any>();
    @Output() clearFilters = new EventEmitter<any>();
    @Input() isDialog?: boolean = false;
    @Input() gridApiRef: GridApi;

    public fiscalYearSubscription: Subscription;
    public planningMonthSubscription: Subscription;
    public planningWeekSubscription: Subscription;
    public accountSubscription: Subscription;

    promotionItemPlannerName: string = '';
    fiscalYears = [];
    planningMonths = [];
    planningWeeks = [];
    isActive;
    accounts: any = '';

    fiscalYearList = [];
    planningMonthList = [];
    planningWeekList = [];
    statusList = [
        { statusId: '0', statusName: 'Inactive' },
        { statusId: '1', statusName: 'Active' }
    ];
    accountList = [];

    userTeam: any = '';
    isRetailerTeam: boolean = false;
    disabledAccountSelection: boolean = false;

    screenPreference: any;
    screenFilters: any;

    constructor(
        private itemManagerService: ItemManagerService,
        private usersService: UsersService,
        private userPreferenceService: UserPreferenceService) { }

    ngOnInit() {
        !this.isDialog && this.getUserSecreenPrefereence();
        this.setRoleBaseAccess();
        this.initiateValues();
    }

    getUserSecreenPrefereence() {
        this.screenPreference = this.userPreferenceService.getPreferenceByKeys(PROMOTION_PLANNER_LIST, PROMOTION_PLANNER_LIST);
        this.screenFilters = this.screenPreference.screenFilters ? JSON.parse(this.screenPreference.screenFilters) : null;
        if (this.screenFilters) {
            this.promotionItemPlannerName = this.screenFilters.PromotionItemPlannerName;
            this.fiscalYears = this.screenFilters.FiscalYears || [];
            this.planningMonths = this.screenFilters.PlanningMonths || [];
            this.planningWeeks = this.screenFilters.CalendarIds || [];
            this.isActive = this.screenFilters.IsActive;
            this.accounts = this.screenFilters.Accounts || [];
        }
    }

    initiateValues() {
        this.fiscalYearSubscription = this.itemManagerService.getFiscalYearListSubject().subscribe((fiscalYearList: any) => {
            if (fiscalYearList && fiscalYearList.length) {
                this.fiscalYearList = fiscalYearList;
                this.setDefaulYear();
            }
        });
        this.planningMonthSubscription = this.itemManagerService.getPlanningMonthListSubject().subscribe((planningMonthList: any) => {
            if (planningMonthList && planningMonthList.length) {
                this.planningMonthList = planningMonthList;
            }
        });
        this.planningWeekSubscription = this.itemManagerService.getPlanningWeekListSubject().subscribe((planningWeekList: any) => {
            if (planningWeekList && planningWeekList.length) {
                this.planningWeekList = planningWeekList;
            }
        });
        this.accountSubscription = this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
            if (accountList && accountList.length) {
                this.accountList = accountList;
            }
        });
    }

    setDefaulYear() {
        const defaultYear = _.find(this.fiscalYearList, a => a.defaultYear);
        if ((defaultYear && this.fiscalYears && !this.fiscalYears.length)
            || (defaultYear && !this.screenFilters)
            || (defaultYear && this.screenFilters && !this.screenFilters.FiscalYears)
            || (defaultYear && this.screenFilters && this.screenFilters.FiscalYears && !this.screenFilters.FiscalYears.length)) {
            this.fiscalYears = [defaultYear.yearId];
            this.fiscalYearsChange();
        }

        if (this.screenFilters && this.screenFilters.FiscalYears && this.screenFilters.FiscalYears.length) {
            this.fiscalYearsChange();
        }
    }

    setRoleBaseAccess() {
        this.userTeam = this.usersService.checkLoginUserTeam();
        switch (this.userTeam) {
            case VENDOR_TEAM_CODE: {
                this.accounts = [this.usersService.getTeamRoleAccount()];
                this.disabledAccountSelection = true;
                this.isRetailerTeam = false;
                break;
            }
            case RETAILER_TEAM_CODE: {
                this.isRetailerTeam = true;
                break;
            }
            case INVALID_TEAM: {
                console.log('Valid Team Not assigned');
                break;
            }
        }
    }

    async fiscalYearsChange() {
        await this.itemManagerService.GetPlanningMonthList({
            ...(this.fiscalYears && this.fiscalYears.length) && { YearIds: this.fiscalYears }
        }).toPromise();

        if (!this.planningMonths.length) {
            await this.itemManagerService.GetPlanningWeekList({
                ...(this.fiscalYears && this.fiscalYears.length) && { YearIds: this.fiscalYears }
            }).toPromise();
        }

        if (this.fiscalYears && this.fiscalYears.length) {
            setTimeout(() => {
                if (this.planningMonths && this.planningMonths.length) {
                    const monthIds = _.map(this.planningMonthList, a => a.monthId);
                    const intersectMonth = _.intersection(this.planningMonths, monthIds);
                    if (intersectMonth.length) {
                        this.planningMonths = intersectMonth;
                    } else {
                        this.planningMonths = [];
                    }
                    this.planningMonthChange();
                } else if (this.planningWeeks && this.planningWeeks.length) {
                    const calendarIds = _.map(this.planningWeekList, a => a.calendarId);
                    const intersectWeeks = _.intersection(this.planningWeeks, calendarIds);
                    if (intersectWeeks.length) {
                        this.planningWeeks = intersectWeeks;
                    } else {
                        this.planningWeeks = [];
                    }
                    this.emitFilterEvent();
                } else {
                    this.emitFilterEvent();
                }
            }, 0);
        } else {
            this.emitFilterEvent();
        }
    }

    async planningMonthChange() {
        await this.itemManagerService.GetPlanningWeekList({
            ...(this.planningMonths && this.planningMonths.length) && { MonthIds: this.planningMonths },
            ...(this.fiscalYears && this.fiscalYears.length) && { YearIds: this.fiscalYears }
        }).toPromise();

        if (this.planningMonths && this.planningMonths.length) {
            setTimeout(() => {
                if (this.planningWeeks && this.planningWeeks.length) {
                    const calendarIds = _.map(this.planningWeekList, a => a.calendarId);
                    const intersectWeeks = _.intersection(this.planningWeeks, calendarIds);
                    if (intersectWeeks.length) {
                        this.planningWeeks = intersectWeeks;
                    } else {
                        this.planningWeeks = [];
                    }
                }
                this.emitFilterEvent();
            }, 0);
        } else {
            this.emitFilterEvent();
        }
    }

    emitFilterEvent() {
        const eventBody = {
            ...this.promotionItemPlannerName && { PromotionItemPlannerName: this.promotionItemPlannerName },
            ...(this.fiscalYears && this.fiscalYears.length) && { FiscalYears: this.fiscalYears },
            ...(this.planningMonths && this.planningMonths.length) && { PlanningMonths: this.planningMonths },
            ...(this.planningWeeks && this.planningWeeks.length) && { CalendarIds: this.planningWeeks },
            ...this.isActive && { IsActive: this.isActive },
            ...(this.accounts && this.accounts.length) && { Accounts: this.accounts }
        };
        if (!this.isDialog) {
            this.screenPreference.screenFilters = eventBody;
            this.updateScreenPreference();
        }
        this.filterEvent.emit(eventBody);
    }

    updateScreenPreference() {
        this.userPreferenceService.upsertFilterPreference(this.screenPreference).subscribe(res => {
            console.log(res);
        });
    }

    clearValues() {
        this.promotionItemPlannerName = '';
        this.fiscalYears = [];
        this.planningMonths = [];
        this.planningWeeks = [];
        this.isActive = '';
        this.accounts = this.disabledAccountSelection ? this.accounts : [];
        this.showLoader();
        this.setDefaulYear();
        this.itemManagerService.GetPlanningMonthList().toPromise();
        this.itemManagerService.GetPlanningWeekList().toPromise();
    }

    showLoader() {
        this.gridApiRef ? this.gridApiRef.showLoadingOverlay() : null;
    }

    ngOnDestroy() {
        this.fiscalYearSubscription.unsubscribe();
        this.planningMonthSubscription.unsubscribe();
        this.planningWeekSubscription.unsubscribe();
        this.accountSubscription.unsubscribe();
    }

}
