import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProcurementService } from 'src/services/procurement.service';
@Component({
  selector: 'app-pms-add-edit-item',
  templateUrl: './pms-add-edit-item.component.html',
  styleUrls: ['./pms-add-edit-item.component.scss'],
})
export class PmsAddEditItemComponent implements OnInit {
  addEditForm: FormGroup;
  editMode = false;
  typesArray = [
    {
      id: '1',
      title: 'Goods',
    },
    {
      id: '2',
      title: 'Services',
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<PmsAddEditItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private procurementService: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService
  ) {}
  ngOnInit(): void {
    this.initializeForm();
    if (this.data.id) {
      this.editMode = true;
      this.fetchDataById(this.data.id);
    }
  }
  fetchDataById(id) {
    this.spinner.show();
    this.procurementService.getItemById(id).subscribe(
      (res: any) => {
        console.log(res);
        res.purchaseTypeId = res.purchaseTypeId.toString();
        this.addEditForm.patchValue(res);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  initializeForm() {
    this.addEditForm = this.formBuilder.group({
      itemId: 0,
      itemName: [null, Validators.required],
      purchaseTypeId: [null, Validators.required],
    });
  }
  onSaveClick() {
    this.spinner.show();
    if (this.editMode) {
      this.procurementService.updateItem(this.addEditForm.value).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toastrService.success('Item updated successfully.');
          this.dialogRef.close(true);
        },
        (err) => {
          this.toastrService.error('Failed to update the item.');
          this.spinner.hide();
        }
      );
    } else {
      this.procurementService.addItem(this.addEditForm.value).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toastrService.success('Item added successfully.');
          this.dialogRef.close(true);
        },
        (err) => {
          this.toastrService.error('Failed to add item.');
          this.spinner.hide();
        }
      );
    }
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
