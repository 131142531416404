import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private sharedData = [];
  constructor() { }

  setData(data: any) {
    this.sharedData = data;
  }

  getAllData() {
    return this.sharedData;
  }

  getDataById(id) {
    const filteredData = this.sharedData.find(res => res.hierarchyMetadataId === id)?.hierarchyName;
    return filteredData;
  }
  
  getLabelByName(name) {

    let nameToReturn = ''
    let arry = name.split('&');

    if (arry.length > 1) {
      for (let i = 0; i < arry.length; i++) {
        const filteredData = this.sharedData.find(res => res.hierarchyName === arry[i].trim())?.hierarchyName;
        nameToReturn = nameToReturn.concat(filteredData, ' & ');
      }
      nameToReturn = nameToReturn.slice(0, -3) // remove last &
    }
    else {
      nameToReturn = name
    }
    return nameToReturn;
  }
}
