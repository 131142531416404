<div class="container-fluid promotion-detail-page">

  <hr class="theme-hr" />
  <!-- Form Expanstion Panel -->
  <mat-accordion class="inpage-expansion-panel">
    <form [formGroup]="promotionDetailForm" class="item-dialog-form" (ngSubmit)="checkDuplicatePromotionItem()">
      <mat-expansion-panel [(expanded)]="detailPanelOpenState" (opened)="detailPanelOpenState = true"
        (closed)="detailPanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            New Item Promotion Details
          </mat-panel-title>
          <mat-panel-description>
            <div class="row panel-header-action-button">
              <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
                <div class="btn-show">
                  <button type="submit" mat-button mat-flat-button color="accent" cdkFocusInitial>Save</button>
                  <button matTooltip="Add New Item Promotion" mat-icon-button color="primary" type="button"
                    (click)="openPromotionItemView($event)">
                    <i class="material-icons">
                      add_circle
                    </i>
                  </button>
                </div>
              </div>

            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row promotion-detail-fields">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 product-field-label">
                <strong>New Item Promotion Name*</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input matInput formControlName="promotionItemName">
                </mat-form-field>
              </div>

              <div class="col-md-4 product-field-label">
                <strong>Product Title ID*</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input readonly matInput formControlName="productTitleId">
                  <mat-icon *ngIf="!isEditMode && !isPromotionSaved" class="search-action-icon"
                    (click)="openMasterGrid()" matSuffix>
                    search</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-md-4 product-field-label">
                <strong>Start Date*</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input matInput [matDatepicker]="startDate" (dateChange)="startDateChanged()"
                    formControlName="startDate">
                  <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                  <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-md-4 product-field-label mt-4">
                <strong>End Date*</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input matInput [matDatepicker]="endDate" formControlName="endDate">
                  <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                  <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
                <div *ngIf="endDateControl.invalid && (endDateControl.dirty || endDateControl.touched) && (endDateControl.errors && endDateControl.errors.endDate)" class="alert alert-danger">
                  {{endDateControl.errors.endDate}}
                </div>
              </div>

              <div class="col-md-4 product-field-label">
                <strong>Status</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <mat-select formControlName="status">
                    <mat-option [value]="true">Active</mat-option>
                    <mat-option [value]="false">In Active</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-4 product-field-label">
                <strong>Account</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input matInput readonly formControlName="accountName">
                </mat-form-field>
              </div>

            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 product-field-label">
                <strong>Rating</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input matInput readonly formControlName="ratingName">
                </mat-form-field>
              </div>

              <div class="col-md-4 product-field-label">
                <strong>Release Date</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input matInput readonly [matDatepicker]="releaseDate" formControlName="releaseDate">
                  <mat-datepicker-toggle matSuffix [for]="releaseDate" disabled></mat-datepicker-toggle>
                  <mat-datepicker #releaseDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-md-4 product-field-label">
                <strong>Pre-awareness Date</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input matInput readonly [matDatepicker]="preAwarenessDate" formControlName="preAwarenessDate">
                  <mat-datepicker-toggle matSuffix [for]="preAwarenessDate" disabled></mat-datepicker-toggle>
                  <mat-datepicker #preAwarenessDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-md-4 product-field-label mt-4">
                <strong>Theatrical Release Date</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <input matInput readonly [matDatepicker]="theatricalReleaseDate"
                    formControlName="theatricalReleaseDate">
                  <mat-datepicker-toggle matSuffix [for]="theatricalReleaseDate" disabled></mat-datepicker-toggle>
                  <mat-datepicker #theatricalReleaseDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-md-4 product-field-label">
                <strong>Comments</strong>
              </div>
              <div class="col-md-8">
                <mat-form-field appearance="fill">
                  <textarea matInput formControlName="comments" rows="6" cdkAutosizeMinRows="6"
                    cdkAutosizeMaxRows="6"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </form>
  </mat-accordion>
  <!-- End Form Expansion Panel -->

  <hr class="theme-hr" />
  <!-- Promotion Item Detail Expansion Grid -->
  <mat-accordion *ngIf="isPromotionSaved || isEditMode" class="inpage-expansion-panel">
    <mat-expansion-panel expanded="true" (opened)="itemDetailGridPanelOpenState = true"
      (closed)="itemDetailGridPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Item Details
        </mat-panel-title>
        <mat-panel-description>
          <div class="row panel-header-action-button">
            <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
              <div class="btn-show">

                <button *ngIf="isGridDataUpdated" class="grid-bar-icon" type="button" mat-button mat-flat-button
                  color="accent" (click)="saveUpdatedGridData($event)">
                  Save
                </button>

                <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
                  (click)="openColumnDialog($event)">
                  <i class="material-icons">
                    ballot
                  </i>
                </button>
                <button matTooltip="Export" class="grid-bar-icon" mat-icon-button color="primary"
                  (click)="onExportGridData($event)">
                  <i class="material-icons">
                    insert_drive_file
                  </i>
                </button>

                <button class="import-export-button" mat-flat-button color="accent" [matMenuTriggerFor]="actionMenu"
                  (click)="handleExport($event)">Actions</button>
                <mat-menu #actionMenu="matMenu">
                  <button mat-menu-item (click)="addNewItems()">Add New Item</button>
                  <button mat-menu-item (click)="openActionsDialog()">Delete</button>
                </mat-menu>

              </div>
            </div>

          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="all-show-grid">
        <ag-grid-angular class="ag-theme-material" (sortChanged)="onGridStateChange($event)"
          (columnVisible)="onGridStateChange($event)" (columnMoved)="onGridStateChange($event)"
          [overlayLoadingTemplate]="overlayLoadingTemplate"
          (columnPinned)="onGridStateChange($event)" [suppressRowClickSelection]="true" [suppressMenuHide]="true"
          groupHeaders toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptions" [editType]="editType"
          [components]="components" [columnDefs]="gridColumnDefs" [defaultColDef]="defaultColDef" [rowData]="gridData"
          [pagination]="true" [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true"
          [stopEditingWhenCellsLoseFocus]="true" (selectionChanged)="gridRowSelectionChanged($event)"
          (cellValueChanged)="gridDataUpdated($event)" [paginationPageSize]="paginationPageSize"
          [cacheBlockSize]="cacheBlockSize" (gridReady)="onGridReady($event)"
          (cellKeyDown)="onCellKeyDown($event, gridApi)" #promotionGrid>
        </ag-grid-angular>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- End Promotion Item Detail Expansion Grid -->


</div>
