<div class="">
  <div class="container">
    <div class="header-reg">
      <img class="petrol-img" src="https://cdn.algomus.com/algo/algo-bot-no-bin.png">
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <div class="error-content justify-content-top align-items-top">
          <h2>404</h2>
          <h3>Page Not Found</h3>
        </div>
      </div>
    </div>
  </div>
</div>
