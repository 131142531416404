<div class="d-flex justify-content-between align-items-center p-2">
    <h6 class="m-0"><strong>Change Membership Segmentation</strong></h6>
    <div>
        <button *ngIf="isOutputTab" (click)="sortBy()" mat-raised-button class="mx-2" color="accent">Sort Segments by
        </button>
        <button *ngIf="isOutputTab" (click)="tooltipMeasuresPopup()" mat-raised-button class="mx-2" color="accent">Tooltip Measures
        </button>
        <button *ngIf="!isOutputTab" mat-raised-button class="mx-2" color="accent" (click)="downloadData()">Download Table
        </button>
    </div>
</div>
<div class="segmentation-columns-main-div">
<div *ngFor="let items of dataSource; let j=index" class="segmentation-columns-item">
    <ng-container *ngIf="items">
    <mat-form-field appearance="outline">
        <input matInput placeholder="Segment name" (change)="changesMade=true" [(ngModel)]="items.segmentName" [disabled]="!isOutputTab || SEGMENTATION_OUTLIER_ID==items.id" [readonly]="!isOutputTab">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <input matInput placeholder="Search" [(ngModel)]="items.search" (change)="searchSubject.next({col: j, value: items.search})">
        <mat-icon matSuffix style="cursor: default;">search</mat-icon>
    </mat-form-field>
    <div class="inner-items" (dragover)="onDragOver($event)" (drop)="onDropColumn(j)">
        <div class="customer-item-cls" *ngFor="let item of items.data; let i =index;"
            [ngClass]="[item.dragged ? 'dragged' : '', i%2==0?'':'dark-clr']"
            [matTooltip]="showTooltip(item)"
            [draggable]="isOutputTab" (dragstart)="onDragStart(i, j)" (dragover)="onDragOver($event)" (drop)="onDrop(i, j, $event)">
            {{item[sampleData.groupby]}}
        </div>
        <h6 class="p-4 text-center" *ngIf="items.data.length==0 && !items.loading">No data to show</h6>
        <div class="loader-inner line-scale-pulse-out" *ngIf="items.loading">
            <div></div>
            <div></div>
            <img src="../assets/images/a-log.png" alt="" />
        </div>
        <p class="p-1 text-center m-0 text-secondary" *ngIf="items.data.length && !items.loading && (items.page*20 < items.total)"><a (click)="onLoadMore(items, j)" class="cursor-pointer"><u>Load more</u></a></p>
    </div>
    </ng-container>
</div>
</div>
<div class="d-flex justify-content-start p-2" *ngIf="isOutputTab">
        <button mat-raised-button color="accent" [disabled]="!changesMade" (click)="adjustMembership()">APPLY CHANGES</button>
        <button mat-stroked-button color="accent" class="ml-2" (click)="reset()">RESET TO ALGO</button>
</div>
  