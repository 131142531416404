import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {  GridApi, ColumnApi, GridOptions } from 'ag-grid-enterprise'
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PromoService } from 'src/services/forecast-services/promo-service';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { ConfigurationService } from 'src/services/configuration.service';
import { defaultColDef, getPromoHistoryColDefs, getPromoItemColDefs } from '../ag-grid/grid-options';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { PromoItemDialogComponent } from '../promo-item-dialog/promo-item-dialog.component';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { downloadFromLink, gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { Observable, of, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SharedDataService } from 'src/services/shared-data.service';
import TreeControlParams, { CreateForecastAccountTree, CreateForecastItemTree2 } from 'src/common/forecast-function';
import { TreeviewItem } from 'ngx-treeview';
import { ClientPreferenceService } from 'src/services/client-services/client-preference.service';
import { PromoItemsEditRenderer } from '../ag-grid/promo-items-edit-renderer.component';

@Component({
  selector: 'app-promo-setup-detail',
  templateUrl: './promo-setup-detail.component.html',
  styleUrls: ['./promo-setup-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromoSetupDetailComponent implements OnInit {

  @Input() data?: any;

  promotionForm = new FormGroup({
    status: new FormControl(true, Validators.required),
    promotionPlannerId: new FormControl(0),
    promoKey: new FormControl('', Validators.required),
    promoName: new FormControl('', Validators.required),
    discount: new FormControl(''),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    promotionEventName: new FormControl('', Validators.required),
    promoCustomEvent: new FormControl(''),
    accounts: new FormControl('', Validators.required),
    skus: new FormControl([], Validators.required),
    regions: new FormControl('', Validators.required),
    countries: new FormControl('', Validators.required),
    upliftType: new FormControl('historical', Validators.required),
    upliftPromoId: new FormControl(0),
    upliftValue: new FormControl('', Validators.required),
    promoInfo: new FormControl('retailer'),
    decayTrendPromoId: new FormControl(0),
    decayTrendType: new FormControl('historical', Validators.required),
    trendManagerId: new FormControl(0),
  });

  SKIP_FORM_KEYS = ['startDate', 'endDate', 'promoKey'];

  debouncedDiscountInput = _.debounce(this.calculatePromoName, 500);
  debouncedCustomInput = _.debounce(this.calculatePromoName, 500);

  prvSelectedAccounts: any = [];
  isPromoSaved: boolean = false;
  isOldPromo: boolean = false;
  isPromoHistoryGridLoaded: boolean = false;
  isEdit: boolean = false;
  promoData: any;
  isPromoDateUpdated: boolean = false;
  isCopyOperation: boolean = false;

  promoEventList: any = [];
  accountsList: any = [];
  regionsList: any = [];
  countryList: any = [];
  decayTrendList: any = [];
  likePromoList: any = [];
  trendWeeks: any = [
    { weekIndex: 1, trendValue: '', trendManagerId: 0, trendManagerWeekDetailId: 0 },
    { weekIndex: 2, trendValue: '', trendManagerId: 0, trendManagerWeekDetailId: 0 },
    { weekIndex: 3, trendValue: '', trendManagerId: 0, trendManagerWeekDetailId: 0 },
    { weekIndex: 4, trendValue: '', trendManagerId: 0, trendManagerWeekDetailId: 0 },
  ];
  customTrendWeeks: any = [];
  historicalTrendWeeks: any = [];
  numberOfWeeks: Number = 0;
  isDecayTrendValid: boolean = true;
  clientWeekStartDay: number = 0;
  paginationPageSize = 50;
  cacheBlockSize = 50;

  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public columnDefs = getPromoItemColDefs({}, this);
  public columnHistoryDefs = [];

  public historyGridApi: GridApi;
  public historyColApi: ColumnApi;

  public defaultColDef = defaultColDef;
  promoItemDetailGridData: any = [];
  promoHistoryDetailGridData: any = [];
  selectedRows: any = [];
  public itemSettingList: any = [];

  public accountSubscription: Subscription;
  public promoEventSubscription: Subscription;
  public likePromoSubscription: Subscription;

  endDateMin: Date;

  filteredPromoList: Observable<any[]>;
  decayedPromo: any;
  upliftPromo: any;
  promosDisabled = false;

  selectedAccounts = [];
  selectedSKU = [];
  public mappedItemIds: any = [];
  public mappedRetailerKeys: any = [];

  public disabledItemIds: any = [];
  public disabledRetailerKeys: any = [];

  productList: TreeviewItem[];
  public channelDropdownList: TreeviewItem[];
  decayTrendWeekList: any;
  yearHistoryToggle: boolean = false;

  frameworkComponents = {
    promoItemsEditRenderer: PromoItemsEditRenderer,
  };

  constructor(
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public promoService: PromoService,
    public forecastLookupService: ForecastLookupService,
    public _dataService: SharedDataService,
    public configurationService: ConfigurationService,
    public clientPreferenceService: ClientPreferenceService,

  ) {
  }

  get promoKey() {
    return this.promotionForm.get('promoKey');
  }

  get promoName() {
    return this.promotionForm.get('promoName');
  }

  get promotionEventName() {
    return this.promotionForm.get('promotionEventName');
  }

  get promotionPlannerId() {
    return this.promotionForm.get('promotionPlannerId');
  }

  get startDate() {
    return this.promotionForm.get('startDate');
  }

  get upliftType() {
    return this.promotionForm.get('upliftType');
  }

  get decayTrendType() {
    return this.promotionForm.get('decayTrendType');
  }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  get isHistoryFilteredApplied(): boolean {
    return this.historyGridApi ? !_.isEmpty(this.historyGridApi.getFilterModel()) : false;
  }

  get itemsCount() {
    return this.promoItemDetailGridData.length;
  }

  async ngOnInit() {
    await this.getControlsLookupData();
    this.spinner.hide();
    this.loadClientPreference();
    this.addCustomValidators();
    this.initEditMode();
  }

  getControlsLookupData() {
    this.spinner.show();
    const promisesArray: Promise<any>[] = [];
    promisesArray.push(this.getAccountSettingList());
    promisesArray.push(this.getPromotionEventList());
    promisesArray.push(this.getLikePromoList());
    return Promise.all(promisesArray);
  }

  getAccountSettingList() {
    return new Promise((resolve, reject) => {
      this.forecastLookupService.GetForecastRetailerList({})
        .subscribe((res) => {
          this.accountsList = res;
          resolve(res);
        });
    });
  }

  createAccountDropdownList(accoutSettingList, mappedAccounts: any = [], disableValues: any = []) {
    this.channelDropdownList = CreateForecastAccountTree({
      dataList: accoutSettingList,
      mappedValueList: mappedAccounts,
      disableValueList: disableValues
    } as TreeControlParams);
  }

  getPromotionEventList() {
    return new Promise((resolve, reject) => {
      this.forecastLookupService.GetPromotionEventList().subscribe(eventList => {
        this.promoEventList = eventList;
        resolve(eventList);
      });
    });
  }

  getLikePromoList() {
    return new Promise((resolve, reject) => {
      this.promoService.GetLikePromoList().subscribe(likePromoList => {
        this.likePromoList = likePromoList;
        if (this.isEdit || this.isCopyOperation) {
          this.onUpliftPromoFocusOutEvent(null);
          this.onDecayPromoFocusOutEvent(null);
        }
        resolve(likePromoList);
      });
    });
  }

  calculatePromoName() {
    const promoEvent = this.promotionForm.get('promotionEventName').value;
    const promoCustomEvent = this.promotionForm.get('promoCustomEvent').value;
    const discount = this.promotionForm.get('discount').value;
    const promoStartDate = this.promotionForm.get('startDate').value;
    const promoInfoBy = this.promotionForm.get('promoInfo').value;
    const evntName = `${promoEvent ? (promoEvent === 'Custom' ? promoCustomEvent : promoEvent) : ''}`;
    const accountNames = `${this.selectedAccounts.length ? (this.selectedAccounts.length === 1 ? _.find(this.accountsList, a => a.retailerKey === this.selectedAccounts[0]).retailer : 'Multiple Accounts') : ''}`;
    const dateLabels = `${promoStartDate ? moment(promoStartDate).format('MMM YYYY') : ''}`;
    const promoName = `${evntName} ${discount ? (discount + '%') : ''} ${accountNames} ${dateLabels} ${_.capitalize(promoInfoBy)}`;
    this.promotionForm.get('promoName').setValue(promoName);
  }

  async initEditMode() {
    const data = JSON.parse(this.data ? this.data : {});
    this.isEdit = data.isEdit ? data.isEdit : false;
    this.isCopyOperation = data.isCopyOperation ? data.isCopyOperation : false;
    this.promoData = data.promoData;

    if (this.isCopyOperation) {
      this.loadPromoFormValues();
      this.promoItemDetailGridData = data.promoItemList;
      this.trendWeeks = data.trendWeeks ? data.trendWeeks : this.trendWeeks;
      this.setDecayPromoTypeObjectList();
      this.createAccountDropdownList(this.accountsList, this.selectedAccounts, this.disabledRetailerKeys);
    } else if (this.isEdit) {
      this.getPromotionplannerById(this.promoData);
    } else {
      this.createAccountDropdownList(this.accountsList);
    }
  }

  disableFieldsOnOldPromo() {
    if (this.isOldPromo) {
      this.promotionForm.get('status').disable();
      this.promotionForm.get('promoKey').disable();
      this.promotionForm.get('promoName').disable();
      this.promotionForm.get('discount').disable();
      this.promotionForm.get('startDate').disable();
      this.promotionForm.get('endDate').disable();
      this.promotionForm.get('promotionEventName').disable();
      this.promotionForm.get('promoCustomEvent').disable();
      this.promotionForm.get('accounts').disable();
      this.promotionForm.get('regions').disable();
      this.promotionForm.get('countries').disable();
      this.promotionForm.get('upliftType').disable();
      this.promotionForm.get('upliftPromoId').disable();
      this.promotionForm.get('upliftValue').disable();
      this.promotionForm.get('promoInfo').disable();
      this.promotionForm.get('decayTrendPromoId').disable();
      this.promotionForm.get('decayTrendType').disable();
      this.promosDisabled = true;
    }
  }

  applyPromoData() {
    this.trendWeeks = this.promoData.trendManagerWeekDetails;
    this.loadPromoFormValues();
    this.getPromoItemsList();
    this.calculateWeeks();
    this.checkDecayTrend();
    this.setDecayPromoTypeObjectList();
    this.isOldPromo = this.checkOldPromo();
    this.disableFieldsOnOldPromo();
  }

  accountTreeValueChange(selectedValues) {
    this.selectedAccounts = selectedValues.value;
    if ((this.isCopyOperation || this.isEdit) && !this.selectedSKU.length) {
      this.getItemSettingList(this.selectedAccounts, this.mappedItemIds, this.disabledItemIds);
    } else {
      this.getItemSettingList(this.selectedAccounts, this.selectedSKU, this.disabledItemIds);
    }
    this.accountsChange(true);
  }

  itemTreeValueChange(selectedValues) {
    this.selectedSKU = selectedValues.value;
    this.promotionForm.get('skus').setValue(this.selectedSKU);
  }

  getPromotionplannerById(payload) {
    this.promoService.GetPromotionplannerById((payload.promotionPlannerId) ? payload.promotionPlannerId : payload.PromotionPlannerId).subscribe(promoData => {
      this.promoData = promoData;
      this.applyPromoData();
    });
  }

  checkOldPromo() {
    const currentDate = new Date();
    const endDate = new Date(this.promotionForm.get('endDate').value);
    if (endDate.getTime() < currentDate.getTime()) {
      return true;
    }
    return false;
  }

  copyPromotion(event) {
    const formData = _.cloneDeep(_.omit(this.promotionForm.getRawValue(), this.SKIP_FORM_KEYS));
    formData.accounts = _.get(formData, 'accounts', []).join(',');
    formData.regions = _.get(formData, 'regions', []).join(',');
    formData.countries = _.get(formData, 'countries', []).join(',');
    const promotionPlannerItems: any = [];
    this.selectedAccounts.forEach(retailerKey => {
      this.selectedSKU.forEach(itemKey => {
        const item = _.find(this.itemSettingList, a => a.itemKey === itemKey)
        promotionPlannerItems.push({
          retailerKey: retailerKey,
          sku: itemKey,
          brand: item.brand,
          productGroup: item.productGroup
        });
      });
    });
    formData.promotionPlannerItems = promotionPlannerItems;
    const promotionDetailItems = _.map(this.promoItemDetailGridData,
      b => {
        const omitProbs = _.omit(b, ['promotionPlannerItemId', 'promotionPlannerId']);
        return omitProbs;
      });
    const copyData = {
      isEdit: false,
      isCopyOperation: true,
      promoItemList: promotionDetailItems,
      promoData: formData,
      trendWeeks: this.trendWeeks
    }
    this.openPromotionItemView(copyData);
  }

  openPromotionItemView(data = {}) {
    const promotionDetailComponent = {
      menuId: '449',
      name: 'Promo Details',
      selector: 'app-promo-setup-detail',
      displayName: 'Promo Details',
      module: 'ForecastEnginModule',
      data: data,
      component: PromoSetupDetailComponent
    }
    this.configurationService.menuRendererSubject.next(promotionDetailComponent);
  }

  loadPromoFormValues() {
    const formData = Object.assign({}, this.promoData);
    formData.accounts = _.get(formData, 'accounts', '').split(',');
    formData.regions = _.get(formData, 'regions', '').split(',');
    formData.countries = _.get(formData, 'countries', '').split(',');
    this.promotionForm.patchValue(formData);
    this.decayedPromo = formData.decayTrendPromoId && _.find(this.likePromoList, a => a.promotionPlannerId === formData.decayTrendPromoId).promoName;
    this.upliftPromo = formData.upliftPromoId && _.find(this.likePromoList, a => a.promotionPlannerId === formData.upliftPromoId).promoName;
    this.promotionForm.get('promotionPlannerId').setValue(this.isEdit ? this.promoData.promotionPlannerId : 0);
    this.selectedAccounts = Object.assign([], formData.accounts);
    this.mappedItemIds = _.uniq(formData.promotionPlannerItems.map(data => data.sku));
    this.mappedRetailerKeys = _.uniq(formData.promotionPlannerItems.map(data => data.retailerKey));
  }

  addCustomValidators() {
    setTimeout(() => {
      this.promotionForm.controls['endDate'].setValidators([Validators.required, this.endWeekValidator]);
    }, 0);
  }

  endWeekValidator(control: FormControl) {
    let startDate = control.parent.controls['startDate'].value;
    const endDate = control.value;
    if (startDate) {
      startDate = new Date(startDate);
      if (endDate < startDate) {
        return {
          endDates: 'Start Week should be less then End Week.'
        };
      }
      return null;
    } else {
      return null;
    }
  }

  startDateChange(event) {
    this.calculateWeeks();
    this.checkDecayTrend();
    this.setEndDateMin();
    this.calculatePromoName();
  }

  setEndDateMin() {
    const startDate = this.promotionForm.get('startDate').value;
    if (startDate) {
      this.endDateMin = new Date(startDate);
    }
  }

  endDateChange(event) {
    this.calculateWeeks();
    this.checkDecayTrend();
  }

  promoEventChange() {
    this.calculatePromoName();
    if (this.promotionEventName.value === 'Custom') {
      this.promotionForm.controls['promoCustomEvent'].setValidators([Validators.required]);
    } else {
      this.promotionForm.controls['promoCustomEvent'].setValidators([]);
    }
    this.promotionForm.get('promoCustomEvent').updateValueAndValidity();
  }

  promoInfoChange() {
    this.calculatePromoName();
  }

  accountsChange(calculatePromoName = false) {
    this.promotionForm.get('accounts').setValue(this.selectedAccounts as any);
    this.calculateRegionAndCountryValues(this.selectedAccounts);
    calculatePromoName && this.calculatePromoName();
  }

  getItemSettingList(retailerList: any[], mappedItems: any = [], disableValues: any = []) {
    const retList = { retailerDetails: [] };
    retailerList.forEach(element => {
      if (element?.retailerKey) {
        retList.retailerDetails.push({
          retailerKey: element.retailerKey,
          retailer: element.retailer
        });
      } else {
        retList.retailerDetails.push({
          retailerKey: element
        });
      }
    });
    this.forecastLookupService
      .GetItemsListByRetailer(retList)
      .subscribe((productSettingList) => {
        this.itemSettingList = productSettingList;
        this.productList = CreateForecastItemTree2({
          dataList: productSettingList,
          mappedValueList: mappedItems,
          disableValueList: disableValues
        } as TreeControlParams);
        if (this.isCopyOperation && this.isEdit) {
          setTimeout(() => {
            // Clear mapped items after first selection
            this.mappedItemIds = [];
          }, 100);
        }
      });
  };

  calculateRegionAndCountryValues(selectedAccounts) {
    this.regionsList = [];
    this.countryList = [];
    selectedAccounts.forEach(acc => {
      const accountObj = _.find(this.accountsList, a => a.retailerKey === acc);
      if (accountObj) {
        this.regionsList.indexOf(accountObj.region) == -1 && this.regionsList.push(accountObj.region);
        this.countryList.indexOf(accountObj.country) == -1 && this.countryList.push(accountObj.country);
      }
    });
    this.promotionForm.get('countries').setValue(this.countryList);
    this.promotionForm.get('regions').setValue(this.regionsList);
  }

  getPromtionPlannerDetailtemsCountByListAttribute(attribute, valueList) {
    if (this.promoItemDetailGridData.length) {
      return _.filter(this.promoItemDetailGridData, a => valueList.includes(a[attribute])).length;
    } else return 0;
  }

  applyPromoUplift(event) {
    const upliftPromoId = this.promotionForm.get('upliftPromoId').value;
    const selectedPromo = this.likePromoList.find(a => a.promotionPlannerId == upliftPromoId);
    this.promotionForm.get('upliftValue').setValue(selectedPromo ? selectedPromo.upliftValue : 0);
  }

  applyPromoDecay(event) {
    const decayTrendPromoId = this.promotionForm.get('decayTrendPromoId').value;
    const selectedPromo = this.likePromoList.find(a => a.promotionPlannerId == decayTrendPromoId);
    this.trendWeeks = selectedPromo ? selectedPromo.trendManagerWeekDetails : [];
    this.checkDecayTrend();
  }

  setDecayPromoTypeObjectList() {
    const decayTrendType = this.promotionForm.get('decayTrendType').value;
    if (decayTrendType === 'custom') {
      this.customTrendWeeks = _.cloneDeep(this.trendWeeks);
    } else {
      this.historicalTrendWeeks = _.cloneDeep(this.trendWeeks);
    }
  }

  decayTrendTypeChange(event) {
    const decayTrendType = event.value;
    if (decayTrendType === 'custom') {
      this.historicalTrendWeeks = _.cloneDeep(this.trendWeeks);
      const isEmptyWeeks = this.isEmptyTrendWeeks();
      this.trendWeeks = isEmptyWeeks ? _.cloneDeep(this.historicalTrendWeeks) : _.cloneDeep(this.customTrendWeeks);
    } else {
      this.customTrendWeeks = _.cloneDeep(this.trendWeeks);
      this.trendWeeks = _.cloneDeep(this.historicalTrendWeeks);
    }
  }

  isEmptyTrendWeeks() {
    let isEmptyWeeks = true;
    this.customTrendWeeks.every(week => {
      if (week.trendValue) {
        isEmptyWeeks = false;
        return false;
      }
      return true;
    });
    return isEmptyWeeks;
  }


  addWeek() {

    if (this.trendWeeks.length < this.numberOfWeeks) {
      this.trendWeeks.push({
        weekIndex: this.trendWeeks.length + 1,
        trendValue: '',
        trendManagerId: 0,
        trendManagerWeekDetailId: 0
      });
      this.checkDecayTrend();
    } else {
      this.toastr.error('Error', 'Number of weeks filled according to start and end date.');
    }
  }

  removeWeek(weekIndex) {
    let rebaseIndex = 1;
    this.trendWeeks.splice((weekIndex - 1), 1);
    this.trendWeeks.forEach(week => {
      week.weekIndex = rebaseIndex;
      rebaseIndex = rebaseIndex + 1;
    });
    this.checkDecayTrend();
  }

  calculateWeeks() {
    const startDate = this.promotionForm.get('startDate').value;
    const endDate = this.promotionForm.get('endDate').value;
    if (startDate && endDate) {
      this.numberOfWeeks = this.differenceInWeeks(new Date(startDate), new Date(endDate));
    }
  }

  differenceInWeeks(startDate: Date, endDate: Date) {
    const weekStartDate = this.getStartDayOfCurrentWeek(startDate);
    const weekEndDate = this.getStartDayOfCurrentWeek(endDate);
    // let diff = (weekStartDate.getTime() - weekEndDate.getTime()) / 1000;
    // diff /= (60 * 60 * 24 * 7);
    let differenceInWeeks = (weekEndDate.getTime() - weekStartDate.getTime());
    let diff = Math.ceil(((differenceInWeeks / (1000 * 3600 * 24)) + 1) / 7);
    return Math.abs(Math.round(diff));
  }

  getStartDayOfCurrentWeek(currentDate: Date) {
    const startDayofWeek = this.getPreviousWeekDate(currentDate);
    return new Date(currentDate.setDate(startDayofWeek));
  }

  getPreviousWeekDate(currentDate) {
    let day = currentDate.getDay();
    let diff;
    if (day === this.clientWeekStartDay) {
      return currentDate.getDate();
    }
    else if (this.clientWeekStartDay === 6) {
      return currentDate.getDate() - (day + 1);
    }
    else {
      // adjust when day is sunday
      diff = currentDate.getDate() - day;
    }
    return diff;
  }

  checkDecayTrend() {
    if (this.trendWeeks.length > this.numberOfWeeks) {
      this.isDecayTrendValid = false;
    } else {
      this.isDecayTrendValid = true;
    }
  }

  savePromo() {
    const isValidTrendWeeks = this.isVaildTrendWeeks();
    if (this.promotionForm.valid && isValidTrendWeeks) {
      const payload: any = Object.assign({}, this.promotionForm.value);
      payload.accounts = _.get(payload, 'accounts', []).join(',');
      payload.regions = _.get(payload, 'regions', []).join(',');
      payload.countries = _.get(payload, 'countries', []).join(',');
      payload.trendManagerWeekDetails = this.trendWeeks;
      const promotionPlannerItems: any = [];
      this.selectedAccounts.forEach(retailerKey => {
        this.selectedSKU.forEach(itemKey => {
          const item = _.find(this.itemSettingList, a => a.itemKey === itemKey)
          promotionPlannerItems.push({
            retailerKey: retailerKey,
            sku: itemKey,
            brand: item.brand,
            productGroup: item.productGroup
          });
        });
      });
      payload.promotionPlannerItems = promotionPlannerItems;

      if (this.isEdit) {
        this.spinner.show();
        this.promoService.UpdatePromo(payload).subscribe(res => {
          this.isPromoDateUpdated = true;
          this.spinner.hide();
          this.promoService.setPromoSetupActionSubject(true);
          this.getPromoItemsList();
          this.toastr.success('Success', 'Promotion updated successfully.');
        }, err => {
          this.toastr.error('Error', 'System failed to update promotion.');
        });
      } else if (this.isCopyOperation) {
        this.spinner.show();
        this.promoService.CopyPromo(payload).subscribe((res: any) => {
          if (_.get(res, 'messageRecords.0.messageCode', '') !== 200) {
            this.toastr.error('Error', _.get(res, 'messageRecords.0.messageText', 'System failed to copy promotion.'));
          } else if (res.promotionPlannerId) {
            this.promotionForm.get('promotionPlannerId').setValue(res.promotionPlannerId);
            this.promotionForm.get('trendManagerId').setValue(res.trendManagerId);
            this.trendWeeks = res.trendManagerWeekDetails;
            this.promoItemDetailGridData = res.promotionPlannerItems ? res.promotionPlannerItems : this.promoItemDetailGridData;
            this.isEdit = true;
            this.isPromoSaved = true;
            this.isPromoDateUpdated = true;
            this.isCopyOperation = false;
            this.promoService.setPromoSetupActionSubject(true);
            this.getPromoItemsList();
            this.toastr.success('Success', 'Promotion created successfully.');
          } else {
            this.toastr.error('Error', 'System failed to copy promotion.');
          }
          this.spinner.hide();
        });
      } else {
        this.spinner.show();
        this.promoService.AddPromoPlanner(payload).subscribe((res: any) => {
          if (_.get(res, 'messageRecords.0.messageCode', '') !== 200) {
            this.toastr.error('Error', _.get(res, 'messageRecords.0.messageText', 'System failed to copy promotion.'));
          } else if (res.promotionPlannerId) {
            this.promotionForm.get('promotionPlannerId').setValue(res.promotionPlannerId);
            this.promotionForm.get('trendManagerId').setValue(res.trendManagerId);
            this.trendWeeks = res.trendManagerWeekDetails;
            this.isEdit = true;
            this.isPromoSaved = true;
            this.isPromoDateUpdated = true;
            this.promoService.setPromoSetupActionSubject(true);
            this.getPromoItemsList();
            this.toastr.success('Success', 'Promotion created successfully.');
          } else {
            this.toastr.error('Error', 'System failed to create promotion.');
          }
          this.spinner.hide();
        });
      }
    } else {
      this.toastr.error('Error', 'Please fill the promotion details correctly.');
    }
  }

  isVaildTrendWeeks() {
    let isValidWeeks = this.trendWeeks.length ? true : false;
    this.trendWeeks.every(week => {
      if (!week.trendValue) {
        isValidWeeks = false;
        return false;
      }
      return true;
    });
    return isValidWeeks;
  }

  getPromoItemsList() {
    this.spinner.show();
    const promotionPlannerId = this.promotionForm.get('promotionPlannerId').value;
    this.promoService.GetPromotionPlannerItemList(promotionPlannerId).subscribe((promoItemsList: any) => {
      this.promoItemDetailGridData = promoItemsList;
      this.gridApi && this.gridApi.setRowData(this.promoItemDetailGridData);
      this.spinner.hide();
      this.redrawAccountTreeDropdown();
    })
  }

  redrawAccountTreeDropdown() {
    this.disabledItemIds = _.uniq(this.promoItemDetailGridData.map(data => data.sku));
    this.disabledRetailerKeys = _.uniq(this.promoItemDetailGridData.map(data => data.retailerKey));
    this.createAccountDropdownList(this.accountsList, this.selectedAccounts, this.disabledRetailerKeys);
  }

  onHistoryGridReady(params) {
    this.historyGridApi = params.api;
    this.historyColApi = params.columnApi;
  }

  historyToggleChange(event) {
    this.applySearch();
  }

  clearHistoryGridFilters() {
    this.historyGridApi.setFilterModel(null);
  }

  removeHistoryGrid() {
    this.isPromoHistoryGridLoaded = false;
  }

  openHistoryColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.historyColApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onExportHistoryGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Promotion-History-Items',
      colDefs: this.columnHistoryDefs,
      allColumns: false,
      columnKeys: _.map(this.columnHistoryDefs, a => a.field)
    });
    this.historyGridApi.exportDataAsCsv(csvExportParams);
  }

  isPromoDatesAvailable(): boolean {
    const startDate = this.promotionForm.get('startDate').value;
    const endDate = this.promotionForm.get('endDate').value;
    return (startDate && endDate) ? true : false;
  }

  isItemAccountSelect(): boolean {
    return (this.selectedAccounts.length && this.selectedSKU.length) ? true : false;
  }

  applySearch() {
    const isItemAccountValid = this.isItemAccountSelect();
    const isPromoDatesValid = this.isPromoDatesAvailable();
    if (isItemAccountValid && (this.yearHistoryToggle && isPromoDatesValid || !this.yearHistoryToggle)) {
      const payload = {
        startDate: new Date(this.promotionForm.get('startDate').value),
        endDate: new Date(this.promotionForm.get('endDate').value),
        promotionHistorySkuAccounts: [],
        OneYearBeforeORAfter: this.yearHistoryToggle
      }
      this.selectedAccounts.forEach(retailerKey => {
        this.selectedSKU.forEach(itemKey => {
          payload.promotionHistorySkuAccounts.push({
            retailerKey: retailerKey,
            itemKey: itemKey
          });
        });
      });
      this.spinner.show();
      this.promoService.GetPromotionHistoryList(payload).subscribe((data: any) => {
        this.promoHistoryDetailGridData = data;
        this.isPromoHistoryGridLoaded = true;
        data.forEach(element => {
          this.decayTrendWeekList = Object.keys(element).filter(f => f.substring(0, 2) === 'WK');
        });

        this.columnHistoryDefs = getPromoHistoryColDefs(this.decayTrendWeekList, data, this);
        this.spinner.hide();
      });
    } else {
      this.isPromoHistoryGridLoaded = false;
      this.toastr.warning('Warning', 'Please fill the form correctly.');
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  clearGridFilters() {
    this.gridApi.setFilterModel(null);
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onExportGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Promotion-Items',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  exportDataTemplate() {
    this.promoService.ExportPromotionItemPlannerTemplate().subscribe((res: any) => {
      downloadFromLink(res, 'PromotionItemPlannerTemplate');
    });
  }

  importDataByTemplate() {
    (document.getElementById('upload-promotion-item-planner-data') as any).value = '';
    document.getElementById('upload-promotion-item-planner-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;

      formDataReader.append('file', file);
      this.uploadImportedPromoCsvFile(formDataReader);

    }
  }

  uploadImportedPromoCsvFile(formDataReader) {
    this.promoService.ImportPromotionItemPlannerTemplate(formDataReader).subscribe(
      (res: any) => {
        if (res && res.length) {
          const successMessages = [];
          const errorMessages = [];
          const warningMessages = [];
          const infoMessages = [];
          res.forEach(element => {

            switch (element.messageType) {
              case 'Success':
                successMessages.push(element.messageText);
                break;
              case 'Warning':
                warningMessages.push(element.messageText);
                break;
              case 'Info':
                infoMessages.push(element.messageText);
                break;
              case 'Error':
                errorMessages.push(element.messageText);
                break;
              default:
                break;
            }
          });

          if (errorMessages.length)
            this.toastr.error('Error', `${errorMessages.map(a => a).join('<br />')}`);
          if (successMessages.length)
            this.toastr.success('Success', `${successMessages.map(a => a).join('<br />')}`);
          if (warningMessages.length)
            this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
          if (infoMessages.length)
            this.toastr.info('Info', `${infoMessages.map(a => a).join('<br />')}`);
          this.getPromoItemsList();
        } else {
          this.toastr.warning('Warning', 'Promotion items data import failed.');
        }
      },
      (err: any) => {
        this.toastr.error('Error', 'Promotion Data Import Failed.');
      });
  }

  // openPromotionItemsSetupDialog() {

  //   const dialogRef = this.dialog.open(PromoItemDialogComponent, {
  //     panelClass: 'promo-item-dialog',
  //     disableClose: true,
  //     data: {
  //       promoData: this.promotionForm.value,
  //       promoItems: this.promoItemDetailGridData
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.getPromoItemsList();
  //     }
  //   });
  // }

  deletePromotionItems() {
    if (this.selectedRows.length) {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Delete Promotions`,
          confirmationMessage: `Are you sure you want to delete these promotion items?`
        }
      });
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          this.promoService.DeletePromoItems({ promotionPlannerItems: this.selectedRows }).subscribe(res => {
            if (res) {
              this.removeFromGrid();
              this.spinner.hide();
              this.toastr.success('Success', `Promotion item(s) deleted successfully.`);
            };
          }, err => {
            this.toastr.error('Error', `System failed to delete promotion item(s).`);
            this.spinner.hide();
          });
        }
      });
    } else {
      this.toastr.error('Error', 'Please select at least one row.');
    }
  }

  removeFromGrid() {
    this.selectedRows.forEach(row => {
      const index = this.promoItemDetailGridData.findIndex(a => a.promotionPlannerItemId === row.promotionPlannerItemId);
      this.promoItemDetailGridData.splice(index, 1);
    });
    this.clearSelectedRows();
    this.gridApi.setRowData(this.promoItemDetailGridData);
    this.onItemDeleteCheckForDisableValues();
  }

  onItemDeleteCheckForDisableValues() {
    this.disabledItemIds = _.uniq(this.promoItemDetailGridData.map(data => data.sku));
    this.disabledRetailerKeys = _.uniq(this.promoItemDetailGridData.map(data => data.retailerKey));
    this.selectedAccounts = _.intersectionWith(this.selectedAccounts, this.disabledRetailerKeys, _.isEqual);
    this.selectedSKU = this.selectedAccounts.length ? this.disabledItemIds : [];
    this.createAccountDropdownList(this.accountsList, this.selectedAccounts, this.disabledRetailerKeys);
  }

  displayPromoFn(item): string {
    const datepipe: DatePipe = new DatePipe('en-US');
    return item && typeof item === 'object' ?
      (item.promoName ? `${item.promoName + ' ' + datepipe.transform(new Date(item.startDate), 'dd/MM/yyyy') + ' - ' + datepipe.transform(new Date(item.endDate), 'dd/MM/yyyy')}` : '') : item;
  }

  _filterDecayPromoItems(event) {
    let value = _.toLower(event.target.value);
    if (!value) {
      this.promotionForm.get('decayTrendPromoId').setValue(0);
      this.applyPromoDecay(null);
    }
    const itemsList = _.filter(this.likePromoList, item => { return _.toLower(item.promoName).indexOf(value) > -1; })
    this.filteredPromoList = of(itemsList);
  }

  onDecayPromoFocus(event) {
    if (this.promotionForm.get('decayTrendPromoId').value) {
      const filteredObj = [_.find(this.likePromoList, item => item.promotionPlannerId == this.promotionForm.get('decayTrendPromoId').value)];
      this.filteredPromoList = of(filteredObj);
    } else {
      this.filteredPromoList = of(this.likePromoList);
    }
  }

  onDecayPromoFocusOutEvent(e) {
    const filteredObj = _.find(this.likePromoList, item => item.promotionPlannerId == this.promotionForm.get('decayTrendPromoId').value);
    this.decayedPromo = filteredObj;
  }

  selectDecayPromoChange = (item) => {
    this.promotionForm.get('decayTrendPromoId').setValue(item.promotionPlannerId);
    this.applyPromoDecay(null);
  }

  _filterUpliftPromoItems(event) {
    let value = _.toLower(event.target.value);
    if (!value) {
      this.promotionForm.get('upliftPromoId').setValue(0);
      this.applyPromoUplift(null);
    }
    const itemsList = _.filter(this.likePromoList, item => { return _.toLower(item.promoName).indexOf(value) > -1; })
    this.filteredPromoList = of(itemsList);
  }

  onUpliftPromoFocus(event) {
    if (this.promotionForm.get('upliftPromoId').value) {
      const filteredObj = [_.find(this.likePromoList, item => item.promotionPlannerId == this.promotionForm.get('upliftPromoId').value)];
      this.filteredPromoList = of(filteredObj);
    } else {
      this.filteredPromoList = of(this.likePromoList);
    }
  }

  onUpliftPromoFocusOutEvent(e) {
    const filteredObj = _.find(this.likePromoList, item => item.promotionPlannerId == this.promotionForm.get('upliftPromoId').value);
    this.upliftPromo = filteredObj;
  }

  selectUpliftPromoChange = (item) => {
    this.promotionForm.get('upliftPromoId').setValue(item.promotionPlannerId);
    this.applyPromoUplift(null);
  }

  get checkWeekDiff() {
    if (this.promotionForm.get('startDate').value
      && this.promotionForm.get('endDate').value) return true;
    else return false;
  }

  get getWeeksDiff() {
    return this.numberOfWeeks;
    // return Math.ceil(this.getDaysDiff / 7);
  }

  get getDaysDiff() {
    const date1 = new Date(this.promotionForm.get('startDate').value) as any;
    const date2 = new Date(this.promotionForm.get('endDate').value) as any;
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    return diffDays;
  }

  loadClientPreference() {
    this.clientWeekStartDay = this.clientPreferenceService.getClientWeekStartDay();
  }

  ngOnDestroy() {
    // this.accountSubscription.unsubscribe();
    // this.promoEventSubscription.unsubscribe();
    // this.likePromoSubscription.unsubscribe();
  }

}
