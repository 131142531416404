import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { USER_ID } from 'src/common/keys';
import { Observable, of } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
import { Location } from '@angular/common';
import { TreeView } from '@syncfusion/ej2-angular-navigations';
import * as _ from 'lodash';

import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ImageRendererComponent } from 'src/common/image-renderer';

@Component({
  selector: 'app-tab-configuration',
  templateUrl: './tab-configuration.component.html',
  styleUrls: ['./tab-configuration.component.scss']
})
export class TabConfigurationComponent implements OnInit {
  @ViewChild('tabCreateTemplate') tabCreateTemplate: TemplateRef<any>;
  @ViewChild('tree') public tree: TreeView;
  public submitted = false;

  isEdit: boolean;
  public roleList: any = [];
  public registerForm: FormGroup;
  public screenList = [];
  public tapList: any[] = [];
  applicationTabName: any = '';

  filteredTabs: Observable<any[]>;
  public field = {};
  public allowEditing: boolean = true;
  public tabList = [];


  public headerHeight = 45;
  public rowHeight;
  public defaultColDef;
  public gridOptions: any;
  private gridApi: any;
  public columnDefs = [
    {
      headerName: 'Tab Name',
      field: 'applicationTabName',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openForm.bind(this,),
      },
    },
    {
      headerName: 'Status',
      field: 'active',
      minWidth: 50,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
      // cellRenderer: 'toggleRenderer',
      // cellRendererParams: (params) => {
      //   debugger;
      //   const obj = {
      //     label: params.value ? 'Active' : 'Inactive',
      //     onChange: this.updateStatus.bind(this),
      //   }
      //   return obj;
      // },
    },
    {
      headerName: 'Roles',
      field: 'roleId',
      minWidth: 350,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
      // cellRenderer: 'imageRenderer',
    },



  ];



  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogAddRef: MatDialogRef<any>,
    public userService: UsersService,
    public location: Location,
    public configurationService: ConfigurationService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,

    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,

  ) {
    this.gridOptions = {
      frameworkComponents: {
        'toggleRenderer': ToggleButtonRendererComponent,
        'nameRenderer': NameRendererComponent,
        'imageRenderer': ImageRendererComponent,
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    }
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getRoleList();
    this.populateTabList();
    this.userService.listUpdate$.subscribe(res => {
      if (res) {
        this.getRoleList();
      }
    });
  }

  onSubmit(data: any) {
    this.submitted = true;
    if (this.registerForm.valid) {
      if (this.isEdit) {
        this.dialogAddRef.close({ tabId: 0, ...this.registerForm.value });
      } else {
        this.dialogAddRef.close({ tabId: 0, ...this.registerForm.value, parentId: data.tabId });
      }
    }
  }


  public _filterScreen(event) {
    let value = event.target.value;
    const filterValue: string = value.toLowerCase();
    const pageList = this.screenList.filter(page => {
      const list = filterValue.toLocaleLowerCase().split(' ');
      return (page.applicationTabName.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) > -1);
    });
    this.filteredTabs = of(pageList);
  }

  submitSearch = (value) => {
    this.registerForm.controls['applicationTabId'].setValue(value.applicationTabId);
  }

  displayFn(screen): string {
    return screen && screen.applicationTabName ? screen.applicationTabName : '';
  }

  public getRoleList = () => {
    this.userService.GetUserRolesAll().subscribe(res => {
      this.roleList = (res as any).data;
    });
  }



  public populateTabList = (params: any = {}) => {
    this.spinner.show();

    this.configurationService.getListByAdminTabRole(params).subscribe((res: any) => {
      this.screenList = res;
      this.filteredTabs = of(this.screenList);
      let tabDataList = [];
      res.forEach(element => {
        let rolesId = [];
        element.applicationTabRoleViewModels.forEach(elementRole => {
          rolesId.push(elementRole.roleName);
        });
        tabDataList.push({
          active: element.active,
          applicationTabId: element.applicationTabId,
          applicationTabName: element.applicationTabName,
          roleId: rolesId.join(","),
          applicationTabRoleViewModels: element.applicationTabRoleViewModels,
          isEdit: true
        })
      });
      this.tabList = tabDataList;
      this.spinner.hide();
    });
  }



  initializeForm() {
    this.registerForm = this.formBuilder.group({
      menuId: [0],
      applicationTabId: [2, Validators.required],
      parentId: [0],
      active: true,
      currentUserId: [this.storage.get(USER_ID)],
      roleIds: [''],
    });
  }

  openCategoryDialog(nodeData: any, templateRef: TemplateRef<any>, isEdit: boolean = false) {
    if (isEdit) {
      this.isEdit = true;
      this.getRolesByMenuId(nodeData);
    } else {
      this.isEdit = false;
      this.initializeForm();
    }
    // tslint:disable-next-line: no-use-before-declare
    this.dialogAddRef = this.dialog.open(templateRef, {
      width: '800px',
      data: nodeData,
      autoFocus: false
    });

    this.dialogAddRef.afterClosed().subscribe(result => {
      result.roleIds = result.roleIds ? result.roleIds.join(',') : '';
      if (result) {
        if (this.isEdit) {
          this.updateTabs(result);
        } else {
          this.addTabs(result);
        }
      }
    });
  }

  getRolesByMenuId(nodeData) {
    if (nodeData.applicationTabRoleViewModels && nodeData.applicationTabRoleViewModels.length) {
      const roleIds = nodeData.applicationTabRoleViewModels.map(a => a.roleId);
      nodeData.roleIds = roleIds;
      this.applicationTabName = nodeData;
      this.populateForm({ data: nodeData });
    } else {
      this.populateForm({ data: nodeData });
    }
  }

  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
      Object.keys(this.registerForm.controls).forEach(key => {
        if (dataToPopulate[key]) {
          this.registerForm.controls[key].setValue(dataToPopulate[key]);
        }
      });
    }
  }



  public updateTabs = (result: any) => {
    this.spinner.show();
    if (result) {
      let updateTabList: any[] = [];
      const screen = this.screenList.find(row => row.applicationTabId === result.applicationTabId);
      let rollData = result.roleIds.split(",")
      rollData.forEach(element => {
        updateTabList.push({
          applicationTabRoleId: Number(element),
          roleId: Number(element),
          applicationTabId: screen.applicationTabId,
          applicationTabName: screen.applicationTabName,
          createdById: 0,
          createdDate: new Date(),
          modifiedById: 0,
          modifiedByDate: new Date(),
          active: result.active
        })
      });
      this.configurationService.addTabs(updateTabList).subscribe(res => {
        if (res) {
          this.submitted = false;
          this.populateTabList();
          this.spinner.hide();
          this.configurationService.menuListSubject.next(true);
          this.submitted = false;
          this.isEdit = false;
        }
      }, error => {
        this.spinner.hide();
      });

    }
  }
  public addTabs = (result: any) => {
    if (result) {
      this.spinner.show();
      let addTabList: any[] = [];
      const screen = this.screenList.find(row => row.applicationTabId === result.applicationTabId);
      let rollData = result.roleIds.split(",")
      rollData.forEach(element => {
        addTabList.push({
          applicationTabRoleId: Number(element),
          roleId: Number(element),
          applicationTabId: screen.applicationTabId,
          applicationTabName: screen.applicationTabName,
          createdById: 0,
          createdDate: new Date(),
          modifiedById: 0,
          modifiedByDate: new Date(),
          active: result.active
        })
      });
      this.configurationService.addTabs(addTabList).subscribe(res => {
        if (res) {
          this.submitted = false;
          this.populateTabList();
          this.spinner.hide();
          this.configurationService.menuListSubject.next(true);
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }




  openForm(rowModel) {
    // this.showForm = false;
    this.openCategoryDialog(rowModel.rowData, this.tabCreateTemplate, rowModel.rowData.isEdit)
    // this.configurationService.roleModel = JSON.stringify(rowModel)
    // this.showForm = true;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.rowHeight = 50;
  }

  // public updateStatus = (row) => {
  //   const model = {
  //     roleId: row.rowData.roleId,
  //     active: row.event.checked,
  //     isDeleted: false,
  //   }
  //   this.userService.ActivateRole(model).subscribe(res => {
  //     this.toastr.success('', 'Role status updated successfully');
  //     this.getRoleList();
  //   });
  // }

  public removeRole = (row) => {
    const model = {
      roleId: row.roleId,
      isDeleted: true,
      active: true
    }
    this.userService.ActivateRole(model).subscribe(res => {
      this.toastr.success('', 'Role deleted successfully');
      this.getRoleList();
    });
  }

  openRemoveDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.roleName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeRole(row.rowData);
      }
    });
  }



}
