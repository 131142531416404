<div class="container-fluid mt-2 mapping-manager-dialog">
  
  <div class="item-wrapper">
    <form class="mapping-form" [formGroup]="forecastItemForm">
      <div class="row w-100 m-0">
        <div class="col-3 mapping-1-box">
          <p><strong>Forecast Item</strong></p>
          <div class="form-group">
            <label for="retailer">{{_dataService.getDataById(1)}}</label>

            <input [readonly]="isEdit || isSKUSelected" name="retailer" formControlName="retailer"
              [ngClass]="{'error-field': (!validForecastForm.retailer) || (retailer.invalid && (retailer.dirty || retailer.touched)) }"
              [matAutocomplete]="retailerauto" (input)="_filterRetailerLookupItems($event)"
              (focus)="onRetailerFocus($event)" [title]="retailer.value ? retailer.value : ''" />

            <mat-autocomplete [displayWith]="displayRetailerFn" #retailerauto="matAutocomplete"
              (optionSelected)="retailerChange($event.option.value)">
              <mat-option *ngFor="let item of filteredRetailerLookupItems | async" [value]="item">
                {{item.retailer}}
              </mat-option>
            </mat-autocomplete>

          </div>

          <div class="form-group">
            <label for="brand">{{_dataService.getDataById(2)}}</label>

            <input [readonly]="isEdit || isSKUSelected" name="brand" formControlName="brand"
              [ngClass]="{'error-field': (!validForecastForm.brand) || (brand.invalid && (brand.dirty || brand.touched)) }"
              [matAutocomplete]="brandauto" (input)="_filterBrandLookupItems($event)" (focus)="onBrandFocus($event)"
              [title]="brand.value ? brand.value : ''" />

            <mat-autocomplete [displayWith]="displayBrandFn" #brandauto="matAutocomplete"
              (optionSelected)="brandChange($event.option.value)">
              <mat-option *ngFor="let item of filteredBrandLookupItems | async" [value]="item">
                {{item.brand}}
              </mat-option>
            </mat-autocomplete>

          </div>

          <div class="form-group">
            <label for="productGroup">{{_dataService.getDataById(3)}}</label>

            <input [readonly]="isEdit || isSKUSelected" name="productGroup" formControlName="productGroup"
              [ngClass]="{'error-field': (!validForecastForm.productGroup) || (productGroup.invalid && (productGroup.dirty || productGroup.touched)) }"
              [matAutocomplete]="productGroupauto" (input)="_filterProductGroupLookupItems($event)"
              (focus)="onProductGroupFocus($event)" [title]="productGroup.value ? productGroup.value : ''" />

            <mat-autocomplete [displayWith]="displayProductGroupFn" #productGroupauto="matAutocomplete"
              (optionSelected)="productGroupChange($event.option.value)">
              <mat-option *ngFor="let item of filteredProductGroupLookupItems | async" [value]="item">
                {{item.productGroup}}
              </mat-option>
            </mat-autocomplete>
          </div>

          <div class="form-group">
            <label for="productDescription">{{_dataService.getDataById(5)}}</label>

            <input [readonly]="isEdit || isSKUSelected" name="productDescription" formControlName="productDescription"
              [ngClass]="{'error-field': (!validForecastForm.productDescription) || (productDescription.invalid && (productDescription.dirty || productDescription.touched)) }"
              [matAutocomplete]="productDescriptionauto" (input)="_filterProductDescriptionLookupItems($event)"
              (focus)="onProductDescriptionFocus($event)"
              [title]="productDescription.value ? productDescription.value : ''" />

            <mat-autocomplete [displayWith]="displayProductDescriptionFn" #productDescriptionauto="matAutocomplete"
              (optionSelected)="productDescriptionChange($event.option.value)">
              <mat-option *ngFor="let item of filteredProductDescriptionLookupItems | async" [value]="item">
                {{item.productDescription}}
              </mat-option>
            </mat-autocomplete>

          </div>

          <div class="form-group">
            <label for="skuNumber">{{_dataService.getDataById(4)}}</label>
            <input [readonly]="isEdit || isSKUSelected" name="skuNumber" formControlName="sku"
              [matAutocomplete]="auto"
              [ngClass]="{'error-field': (!validForecastForm.sku) || (sku.invalid && (sku.dirty || sku.touched)) }"
              (input)="_filterLookupItems($event)" (focus)="onSKUFocus($event)"
              [title]="sku.value ? sku.value : ''" />

            <mat-autocomplete [displayWith]="displaySKUFn" #auto="matAutocomplete"
              (optionSelected)="selectSKUItem($event.option.value)">
              <mat-option *ngFor="let item of filteredLookupItems | async" [value]="item">
                {{item.sku}}
              </mat-option>
            </mat-autocomplete>

          </div>
        </div>

        <div class="col-3">
          <p><strong>Effective Time</strong></p>
          <div class="form-group">
            <label for="effectiveStartDate">Starting Week</label>
            <mat-form-field>
              <input matInput readonly [matDatepicker]="startWeekPicker" [matDatepickerFilter]="weekDayFilter"
                [max]="maxEffectiveWeekDate" name="effectiveStartDate" formControlName="effectiveStartDate"
                (dateChange)="dateInputChange('change', $event)">
              <mat-datepicker-toggle matSuffix [for]="startWeekPicker"></mat-datepicker-toggle>
              <mat-datepicker #startWeekPicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="form-group">
            <label for="effectiveEndDate">End Week</label>
            <mat-form-field>
              <input matInput readonly [matDatepicker]="endWeekPicker" [matDatepickerFilter]="weekDayFilter"
                [max]="maxEffectiveWeekDate" name="effectiveEndDate" formControlName="effectiveEndDate"
                (dateChange)="dateInputChange('change', $event)">
              <mat-datepicker-toggle matSuffix [for]="endWeekPicker"></mat-datepicker-toggle>
              <mat-datepicker #endWeekPicker></mat-datepicker>
            </mat-form-field>

          </div>

          <div *ngIf="effectiveEndDate.invalid && (effectiveEndDate.dirty || effectiveEndDate.touched)"
            class="alert alert-danger">
            Start week should be less then end week.
          </div>

          <div *ngIf="isWeekOverlapping" class="alert alert-danger">
            This date range overlaps with an already created mapping, please enter a valid
            date range or edit/delete already created mapping.
          </div>

        </div>

        <div class="col-6 mapping-2-box">
          <p><strong>Mappings History</strong></p>
          <table class="mapping-history-tbl">
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Level</th>
                <th>Mapping</th>
                <th>Likeness Factor</th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="isEdit || isForecastSKUSelected">
              <tr *ngFor="let item of mappingHistory; let i = index">
                <td>{{ item.effectiveStartDate | date }}</td>
                <td>{{ item.effectiveEndDate | date }}</td>
                <td>{{ item.levelType }}</td>
                <td>{{ item.levelValues }}</td>
                <td>{{ item.likeness +'%'}}</td>
                <td>
                  <button mat-icon-button (click)="deleteForecastItem(item, i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <ng-container *ngIf="isEdit || isForecastSKUSelected">

        <hr>

        <div class="row w-100 m-0">
          <p class="mx-2"><strong>Like SKU Mapping</strong></p>

          <div class="col-12">
            <table class="mapping-tbl">
              <thead>
                <tr>
                  <th>{{_dataService.getDataById(1)}}</th>
                  <th>{{_dataService.getDataById(2)}}</th>
                  <th>{{_dataService.getDataById(3)}}</th>
                  <th>{{_dataService.getDataById(5)}}</th>
                  <th>{{_dataService.getDataById(4)}}</th>
                  <th>Avg. Sale Units</th>
                  <th>Likeness Factor %</th>
                  <th>Factored Sales</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let mapItem of mappedSKUList; let i = index">
                  <td class="w-10">

                    <input [matAutocomplete]="mapretailerauto" [(ngModel)]="mapItem.retailer"
                      [ngClass]="{'error-field': !validMappedSKUList[i].retailer }"
                      [ngModelOptions]="{standalone: true}" (input)="_filterMapRetailerLookupItems($event, i)"
                      (focus)="onMapRetailerFocus($event, i)" [title]="mapItem.retailer ? mapItem.retailer : ''" />

                    <mat-autocomplete [displayWith]="displayRetailerFn" #mapretailerauto="matAutocomplete"
                      (optionSelected)="mapRetailerChange($event.option.value, i)">
                      <mat-option *ngFor="let item of filteredMapRetailerLookupItems | async" [value]="item">
                        {{item.retailer}}
                      </mat-option>
                    </mat-autocomplete>

                  </td>

                  <td class="w-10">

                    <input [(ngModel)]="mapItem.brand" [ngModelOptions]="{standalone: true}"
                      [ngClass]="{'error-field': !validMappedSKUList[i].brand }" [matAutocomplete]="mapbrandauto"
                      [readonly]="isReadonlyControl(i, LEVEL_MAPPING.BRAND)"
                      (input)="_filterMapBrandLookupItems($event, i)" (focus)="onMapBrandFocus($event, i)"
                      [title]="mapItem.brand ? mapItem.brand : ''" />

                    <mat-autocomplete [displayWith]="displayBrandFn" #mapbrandauto="matAutocomplete"
                      (optionSelected)="mapBrandChange($event.option.value, i)">
                      <mat-option *ngFor="let item of filteredMapBrandLookupItems | async" [value]="item">
                        {{item.brand}}
                      </mat-option>
                    </mat-autocomplete>
                  </td>

                  <td class="w-10">

                    <input [(ngModel)]="mapItem.productGroup" [ngModelOptions]="{standalone: true}"
                      [matAutocomplete]="mapproductGroupauto"
                      [ngClass]="{'error-field': !validMappedSKUList[i].productGroup }"
                      [readonly]="isReadonlyControl(i, LEVEL_MAPPING.PRODUCT_GROUP)"
                      (input)="_filterMapProductGroupLookupItems($event, i)"
                      (focus)="onMapProductGroupFocus($event, i)"
                      [title]="mapItem.productGroup ? mapItem.productGroup : ''" />

                    <mat-autocomplete [displayWith]="displayProductGroupFn" #mapproductGroupauto="matAutocomplete"
                      (optionSelected)="mapProductGroupChange($event.option.value, i)">
                      <mat-option *ngFor="let item of filteredMapProductGroupLookupItems | async" [value]="item">
                        {{item.productGroup}}
                      </mat-option>
                    </mat-autocomplete>

                  </td>

                  <td class="w-10">

                    <input [(ngModel)]="mapItem.productDescription" [ngModelOptions]="{standalone: true}"
                      [matAutocomplete]="mapproductDescriptionauto"
                      [readonly]="isReadonlyControl(i, LEVEL_MAPPING.SKU)"
                      [ngClass]="{'error-field': !validMappedSKUList[i].productDescription }"
                      (input)="_filterMapProductDescriptionLookupItems($event, i)"
                      (focus)="onMapProductDescriptionFocus($event, i)"
                      [title]="mapItem.productDescription ? mapItem.productDescription : ''" />

                    <mat-autocomplete [displayWith]="displayProductDescriptionFn"
                      #mapproductDescriptionauto="matAutocomplete"
                      (optionSelected)="mapProductDescriptionChange($event.option.value, i)">
                      <mat-option *ngFor="let item of filteredMapProductDescriptionLookupItems | async"
                        [value]="item">
                        {{item.productDescription}}
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td class="w-10">
                    <input [(ngModel)]="mapItem.sku" [ngModelOptions]="{standalone: true}"
                      [matAutocomplete]="mapskuauto" (input)="_filterMapLookupItems($event, i)"
                      [ngClass]="{'error-field': !validMappedSKUList[i].sku }" (focus)="onMapSKUFocus($event, i)"
                      [title]="mapItem.sku ? mapItem.sku : ''" />

                    <mat-autocomplete [displayWith]="displaySKUFn" #mapskuauto="matAutocomplete"
                      (optionSelected)="mapSKUChange($event.option.value,i)">
                      <mat-option *ngFor="let item of filteredMapLookupItems | async" [value]="item">
                        {{item.sku}}
                      </mat-option>
                    </mat-autocomplete>
                  </td>

                  <td>
                    {{ mapItem.avgWeekSaleUnits | number }}
                  </td>
                  <td>
                    <input type="number" name="likenessFactor" [(ngModel)]="mapItem.likeness"
                      [ngModelOptions]="{standalone: true}" />
                  </td>
                  <td>
                    {{ ((mapItem.avgWeekSaleUnits && mapItem.likeness) ? (mapItem.avgWeekSaleUnits *
                    mapItem.likeness/100) : 0) | number
                    }}
                  </td>
                  <td>
                    <button mat-icon-button (click)="deleteMappedItem(mapItem, i)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>

              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div class="plus-map-item-btn">
                      <button mat-mini-fab (click)="mapNewSKU()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><strong>Average</strong></td>
                  <td class="average-cell" style="background-color: yellow;">{{ averageWeeklySaleUnits | number }}
                  </td>
                  <td class="average-cell" style="background-color: yellowgreen;">{{ (averageLikeness | number) + '%'
                    }}
                  </td>
                  <td class="average-cell" style="background-color: yellow;">{{ averageFactoredSales | number }}</td>

                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </ng-container>


    </form>
  </div>
  
  <div class="mapping-action-btn mt-2" *ngIf="isEdit || isForecastSKUSelected">
    <button mat-flat-button color="accent" (click)="applyMappedItems()">Apply</button>
    <button mat-flat-button class="mx-2" color="accent" (click)="saveForecastMappings()">Save</button>
  </div>

  <hr>

  <div class="mapping-chart-weeks-meta">
    <div><strong>Original POS Weeks:</strong> {{ originalPosWeek }}</div>
    <div><strong>Mapped POS Weeks:</strong> {{ mappedPosWeek }}</div>
  </div>

  <fusioncharts *ngIf="weekSaleUnitChartData.length && (isEdit || isForecastSKUSelected)" width="100%" height="500"
    [chartConfig]=chartConfig [dataSource]=dataSource>
  </fusioncharts>

</div>
