
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime } from 'rxjs/operators';
import { CClaim } from 'src/common/claims';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ProcurementService } from 'src/services/procurement.service';
import {
  cacheBlockSize,
  defaultColDef,
  generateColumnDefsWithRowSelection,
  paginationPageSize,
  purchaseOrderColumns,
} from '../ag-grid/gridOptions';
import { ShowGRNSummaryRenderer } from '../ag-grid/show-grn-summary-renderer.component';
@Component({
  selector: 'pms-purchase-orders',
  templateUrl: './pms-purchase-orders.component.html',
  styleUrls: ['./pms-purchase-orders.component.scss'],
})
export class PmsPurchaseOrdersComponent implements OnInit {
  columns = purchaseOrderColumns;
  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  defaultColDef = defaultColDef;
  filterStatuses = [];
  filtersForm: FormGroup;
  public gridColumnDefs = generateColumnDefsWithRowSelection(this.columns);

  public gridOptions: any;
  selectedStatuse = 'PendingBid';
  private gridApi;
  frameworkComponents = {
    showGRNSummaryRenderer: ShowGRNSummaryRenderer,
  };
  orderSummary: any;
  payableAfterTax = 0;
  statuses = [];
  dataSource = [];
  cClaim = CClaim;
  prItems = [];
  rowSelection = 'single';
  formBuilder: any;
  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    private procurementService: ProcurementService,
    private formBuild: FormBuilder
  ) {}
  ngOnInit(): void {
    this.procurementService.getStatusLookup().subscribe((res: any) => {
      this.statuses = res;
      this.filterStatuses = this.statuses.filter((status) => {
        return (
          status.statusDescription.toLowerCase() === 'approved' ||
          status.statusDescription.toLowerCase() === 'rejected'
        );
      });
    });
    this.initializeFilterForm();
    this.fetchData();
  }
  initializeFilterForm() {
    this.filtersForm = this.formBuild.group({
      prTitle: [''],
      statusDescription: [''],
    });
    this.filtersForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((res: any) => {
        console.log('called');
        this.fetchData();
      });
  }
  fetchData() {
    this.spinner.show();
    this.dataSource = [];
    this.procurementService
      .getPurchaseOrdersListing(
        this.filtersForm.get('prTitle').value,
        this.filtersForm.get('statusDescription').value
      )
      .subscribe(
        (res: any) => {
          this.dataSource = res;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  onStatusSelection() {
    this.fetchData();
  }
  viewDetails(value = null) {
    this.fetchPOSummary(value.grnData.prId);
  }
  fetchPOSummary(paramId = null) {
    console.log('paramId' + paramId);
    this.procurementService
      .getPurchaseOrderSummary(paramId)
      .subscribe((res: any) => {
        this.orderSummary = res;
        this.payableAfterTax =
          this.orderSummary?.totalAmount -
          (this.orderSummary?.totalAmount * this.orderSummary?.taxRate) / 100;
        this.fetchGRNItems(paramId);
      });
  }
  fetchGRNItems(paramId = null) {
    this.spinner.show();
    this.procurementService.getGRNItems(paramId).subscribe(
      (prItems: []) => {
        this.prItems = prItems;
        //var doc = new jsPDF("p", "px", [1000,700]);
        setTimeout(() => {
          this.spinner.hide();
          var doc = new jsPDF();
          var pdfHTML = document.getElementById('poInvoice').innerHTML;
          doc.html(pdfHTML, {
            callback: function (doc) {
              // Save the PDF
              doc.output('dataurlnewwindow');
            },
            x: 15,
            y: 15,
            width: 170, //target width in the PDF document
            windowWidth: 650, //window width in CSS pixels
          });
        }, 1000);
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  createdByPurchaseOrder(data) {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    const payload = {
      poId: _.get(selectedRow, 'poId', 0),
      prId: _.get(selectedRow, 'prId', 0),
      prTitle: _.get(selectedRow, 'prTitle', null), //.format('MM/DD/YYYY')
      poDate: _.get(selectedRow, 'poDate', null).format('MM/DD/YYYY'),
      // issuedBy: this.gridApi.getSelectedRows()[0].issuedBy,
      status: _.get(selectedRow, 'status', 0),
      statusDescription: _.get(selectedRow, 'prTitle', null),
      taxable: _.get(selectedRow, 'prTitle', false),
    };
    this.procurementService
      .addPurchaseOrder(payload)
      .subscribe((res: any) => {});
  }
  updatePurchaseOrderStatus(status) {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    const payload = {
      poId: _.get(selectedRow, 'poId', 0),
      prId: _.get(selectedRow, 'prId', 0),
      prTitle: _.get(selectedRow, 'prTitle', null), //.format('MM/DD/YYYY')
      poDate: _.get(selectedRow, 'poDate', null),
      // issuedBy: this.gridApi.getSelectedRows()[0].issuedBy,
      status: status,
      statusDescription: _.get(selectedRow, 'prTitle', null),
      taxable: _.get(selectedRow, 'taxable', false),
    };
    console.log(payload);
    this.procurementService.updatePurchaseOrder(payload).subscribe(
      (res: any) => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Success', `PO updated successfully.`);
          this.fetchData();
        }
      },
      (err) => {
        this.toastr.error('Error', `System failied to updated PO.`);
      }
    );
  }
  clearFilter() {
    this.initializeFilterForm();
    this.fetchData();
  }
  rejectPurchaseOrder() {}
  onGridReady(params) {
    this.gridApi = params.api;
  }
  cellClicked(event) {
    if (
      event &&
      event.value &&
      event.colDef &&
      event.colDef.field &&
      event.colDef.field == 'prTitle'
    ) {
      const selectedRow = this.gridApi.getSelectedRows()[0];
      this.fetchPOSummary(_.get(selectedRow, 'prId', 0));
    }
  }
  get isRowSelected() {
    if (
      this.gridApi &&
      this.gridApi.getSelectedRows() &&
      this.gridApi.getSelectedRows().length > 0
    )
      return false;
    else return true;
  }
}
