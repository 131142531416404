import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { columnVariables } from '../../constants/columnVariables';

@Component({
  selector: 'app-select-measures',
  templateUrl: './select-measures.component.html',
  styleUrls: ['./select-measures.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectMeasuresComponent implements OnInit {

  measuresArray = [];
  
  columnVariables = JSON.parse(JSON.stringify(columnVariables));

  constructor(
    private dialogRef: MatDialogRef<SelectMeasuresComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    let obj = {
      id: 'dicount_percentage',
      label: 'Discount Percentage'
    };
    this.columnVariables.push(obj);
    obj = {
      id: 'total_sales_per_' + this.data.groupby,
      label: 'Total Sales per ' + this.data.groupby
    };
    this.columnVariables.push(obj);
    this.columnVariables.forEach((e) => {
      const obj = {
        checked: this.data.selectedColumns.indexOf(e.id) > -1,
        label: e.label,
        id: e.id
      };
      this.measuresArray.push(obj);
    });
  }

  onSaveClick = () => {
    const newGridCols = [];
    this.measuresArray.forEach(e => {
      if (e.checked) newGridCols.push({id: e.id, label: e.label});
    });
    if (newGridCols.length == 0 && !this.data.isTooltip)
      this.toastrService.warning('Please select at least 1 Measure');
    else
      this.dialogRef.close(newGridCols);
  }

  onNoClick = () => {
    this.dialogRef.close();
  }

  clearAll() {
    this.measuresArray.forEach(e => {
      e.checked = false;
    });
  }

  selectAll() {
    this.measuresArray.forEach(e => {
      e.checked = true;
    });
  }

}
