import { Router } from '@angular/router';
import { ChannelMessagingService } from '../../../services/Messaging-Services/channel-messaging.service';
import { Component, Inject, OnInit, TemplateRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import * as _ from 'lodash';
import { ChannelMessaging } from 'src/models/channelMessaging.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { USER_ID } from 'src/common/keys';
import { LocalstorageService } from 'src/services/localstorage.service';
import { MatListOption } from '@angular/material/list';
import { ChannelHandlerService } from 'src/services/Messaging-Services/channel-handler.service';
@Component({
  selector: 'app-channel-messaging',
  templateUrl: './channel-messaging.component.html',
  styleUrls: ['./channel-messaging.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChannelMessagingComponent implements OnInit, AfterViewInit {
  public applicationUsersDetails: any;
  public applicationUsersDetailsListOrg: any;
  public selectedArrayData: any[] = [];
  public group: FormGroup;
  public isAdd: boolean = true;
  public channelMessagingModel = new ChannelMessaging();
  public userId = this.storage.get(USER_ID);
  studiosList: any[] = [];
  selectedStudio = 0;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChannelMessagingComponent>,
    private channelMessagingService: ChannelMessagingService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public router: Router,
    public storage: LocalstorageService,
    public matDialogRef: MatDialogRef<any>,
    private channelHandlerService: ChannelHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    if (this.data?.isEdit === true) {
      this.isAdd = false;
      this.data?.formData?.roleId && (this.selectedStudio = this.data?.formData?.roleId);
      this.GetChannelByChannelId(this.data?.formData);
    }
    this.getUserChannelInfo();
  }

  ngAfterViewInit(): void {
    this.channelHandlerService.connection.invoke('JoinGroup', 'join-channel').then((res: any) => {
    }, err => {
    });
  }

  onFilterTextBoxChanged(event) {
    if (event.target.value) {
      this.applicationUsersDetails = this.applicationUsersDetailsListOrg.filter(
        (res) => {
          return res.userFullName
            .toLocaleLowerCase()
            .match(event.target.value.toLocaleLowerCase());
        }
      );
    } else {
      this.getUserChannelInfo();
    }
  }

  studioSelectionChanged(event) {

    if (this.selectedStudio && this.selectedStudio != event.value) {
      let studioMemberSelected = false;
      this.selectedArrayData.forEach(selectedMember => {
        (selectedMember.roleId == this.selectedStudio) && (studioMemberSelected = true);
      });
      if (studioMemberSelected) {
        this.toastr.warning('Warning', 'You have one or more members belonging to the selected studio.');
        const previouslySelectedStudioCopy = JSON.parse(JSON.stringify(this.selectedStudio));
        this.selectedStudio = 0;
        setTimeout(() => {
          this.selectedStudio = previouslySelectedStudioCopy;
        }, 50);
        return;
      }
    }

    this.selectedStudio = event.value;
    this.getUserChannelInfo();
  }

  getUserChannelInfo() {
    this.spinner.show();
    this.channelMessagingService.GetUsersDetails().subscribe(
      (response: any) => {
        // this.studiosList = response.applicationRoles;
        // this.studiosList.length == 1 && (this.selectedStudio = this.studiosList[0].roleId);
        let applicationUsers = [];
        if (response.length) {
          applicationUsers = response;
        } else if (response.applicationUsers) {
          applicationUsers = response.applicationUsers
        }

        applicationUsers.forEach((element) => {
          if (typeof element === 'object') {
            if (element) {
              element['userFullName'] =
                element.firstName + ' ' + element.lastName;
            }
          }
        });
        this.applicationUsersDetails = applicationUsers;
        this.spinner.hide();
        _.remove(this.applicationUsersDetails, (currentUser) => {
          return currentUser.userId === this.userId;
        });
        this.applicationUsersDetails = _.sortBy(
          this.applicationUsersDetails,
          (user) => user.firstName.toLowerCase()
        );
        if (this.selectedArrayData.length > 0) {
          this.selectedArrayData.forEach((filterData) => {
            _.remove(this.applicationUsersDetails, (remove) => {
              return remove.userId === filterData.userId;
            });
          });
        }
        this.applicationUsersDetailsListOrg = Object.assign([], this.applicationUsersDetails);
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Error',
          'System failed to get user information.');
      }
    );
  }

  initializeForm() {
    this.group = this.formBuilder.group({
      channelName: ['', [Validators.maxLength(80)]],
    });
  }

  selectionChange(optionChanged: any) {
    const option = optionChanged.options[0];
    if (option.selected === true) {
      if (option.value.delete === true) {
        option.value.delete = false;
      }

      this.selectedArrayData.forEach(function (e) {
        if (e.userFullName) {
          const splitData = _.split(e.userFullName, ' ', 5);
          if (typeof e === 'object') {
            e['firstName'] = splitData[0];
            e['lastName'] = splitData[1];
          }
        }
      });
      this.applicationUsersDetails.forEach(function (element) {
        if (element.delete === true) {
          if (typeof option.value === 'object') {
            return (option.value['channelId'] = element.channelId);
          }
        }
      });
      this.selectedArrayData.push(option.value);

      this.selectedArrayData = _.sortBy(this.selectedArrayData, (o) =>
        o.firstName.toLowerCase()
      );
      _.remove(this.applicationUsersDetails, (remove) => {
        return remove.userId === option.value.userId;
      });
    } else {
      if (option.value.userFullName) {
        const splitData = _.split(option.value.userFullName, ' ', 5);
        option.value.firstName = splitData[0];
        if (splitData[2]) {
          option.value.lastName = splitData[1] + '' + splitData[2];
        } else {
          option.value.lastName = splitData[1];
        }
        option.value.delete = true;
      }
      this.applicationUsersDetails.push(option.value);
      this.applicationUsersDetails = _.sortBy(
        this.applicationUsersDetails,
        (o) => o.firstName.toLowerCase()
      );
      _.remove(this.selectedArrayData, (remove) => {
        return remove.userId === option.value.userId;
      });
    }
  }

  onSubmit() {
    if (this.selectedArrayData.length == 0) {
      this.toastr.warning('', 'No member added in channel.');
      return;
    }
    this.spinner.show();
    const form = this.group.value;
    this.channelMessagingModel.channelName = form.channelName;
    this.channelMessagingModel.roleId = this.selectedStudio;
    this.selectedArrayData.forEach((user) => {
      this.channelMessagingModel.UsersInChannel.push({
        usersInChannelId: user.usersInChannelId,
        channelId: user.channelId,
        userId: user.userId,
        createdDate: null,
        createdbyId: user.createdbyId,
        modifiedById: user.modifiedById,
        modifiedDate: null,
        active: user.active,
        userFullName: user.userFullName,
        delete: false,
      });
    });
    this.channelHandlerService.connection.invoke('Add', 'join-channel', this.channelMessagingModel).then((res: any) => {
      this.spinner.hide();
      this.dialogRef.close();
    }, err => {
      this.spinner.hide();
    });
  }

  GetChannelByChannelId(data) {
    this.spinner.show();
    // this.isAdd = false;
    this.channelMessagingService
      .GetChannelByChannelId(data.channelId)
      .subscribe(
        (channelDetails: any) => {
          this.getUserChannelInfo();
          this.spinner.hide();
          this.selectedArrayData = channelDetails.usersInChannel;
          this.channelMessagingModel.channelId = channelDetails.channelId;
          this.channelMessagingModel.createdDate = channelDetails.createdDate;
          this.channelMessagingModel.modifiedDate = channelDetails.modifiedDate;
          if (this.selectedArrayData.length > 0) {
            _.remove(this.selectedArrayData, (currentUser) => {
              return currentUser.userId === this.userId;
            });
            this.selectedArrayData.forEach((filterData) => {
              _.remove(this.applicationUsersDetails, (remove) => {
                return remove.userId === filterData.userId;
              });
            });
            this.selectedArrayData.forEach(function (e) {
              if (e.userFullName) {
                const splitData = _.split(e.userFullName, ' ', 5);
                if (typeof e === 'object') {
                  e['firstName'] = splitData[0];
                  e['lastName'] = splitData[1];
                }
              }
            });
            this.selectedArrayData = _.sortBy(this.selectedArrayData, (o) =>
              o.firstName.toLowerCase()
            );
          }
          this.group.controls['channelName'].setValue(
            channelDetails.channelName
          );
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            'Error',
            'System failed to get channel information.'
          );
        }
      );
  }

  updateDetails() {
    this.spinner.show();
    const form = this.group.value;
    this.channelMessagingModel.channelName = form.channelName;
    this.channelMessagingModel.createdById = this.userId;
    this.channelMessagingModel.roleId = this.selectedStudio;
    this.selectedArrayData.forEach((user) => {
      this.channelMessagingModel.UsersInChannel.push({
        usersInChannelId: user.usersInChannelId,
        channelId: user.channelId || this.channelMessagingModel.channelId,
        userId: user.userId,
        createdDate: null,
        createdbyId: user.createdbyId,
        modifiedById: user.modifiedById,
        modifiedDate: null,
        active: user.active,
        userFullName: user.userFullName,
        delete: false,
      });
    });
    this.channelHandlerService.connection.invoke('Update', 'join-channel', this.channelMessagingModel).then((res: any) => {
      this.spinner.hide();
      this.dialog.closeAll();
    }, err => {
      this.spinner.hide();
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  public openUpdateDialog = (template: TemplateRef<any>) => {
    if (this.selectedArrayData.length == 0) {
      this.toastr.warning('', 'No member added in channel.');
      return;
    }
    this.matDialogRef = this.dialog.open(template, { width: '500px' });
  };
}
