import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { DemoService } from 'src/services/demo-services/demo-service';

@Component({
  selector: 'app-demo-item-approval-filter',
  templateUrl: './item-approval-filter.component.html',
  styleUrls: ['./item-approval-filter.component.css']
})
export class DemoItemApprovalFilterComponent implements OnInit, OnDestroy {

  @Output() filterEvent = new EventEmitter<any>();

  monthList: any = [];
  actionList: any = [];
  brandList: any = [];
  makeList: any = [];
  modelList: any = [];
  yearList: any = [];
  promoNameList: any = [];
  submittedByList: any = [];
  fulfillmentTypeList: any = [];

  month: any;
  actions: any = [];
  brands: any = [];
  makes: any = [];
  models: any = [];
  years: any = [];
  promoName: any;
  submittedBy: any;
  fulfillmentType: any;

  userTeam: any = '';
  isRetailerTeam: boolean = false;
  disabledAccountSelection: boolean = false;

  screenPreference: any;
  screenFilters: any;

  constructor(public demoService: DemoService) { }

  ngOnInit() {
    this.initiateValues();
  }

  initiateValues() {
    this.demoService.GetMonthList().subscribe(monthList => {
      this.monthList = monthList;
    });
    this.demoService.GetActionsList().subscribe(actionList => {
      this.actionList = actionList;
    });
    this.demoService.GetItemBrandList().subscribe(brandList => {
      this.brandList = brandList;
    });
    this.demoService.GetItemMakeList().subscribe(makeList => {
      this.makeList = makeList;
    });
    this.demoService.GetItemModelList().subscribe(modelList => {
      this.modelList = modelList;
    });
    this.demoService.GetItemYearList().subscribe(yearList => {
      this.yearList = yearList;
    });
    this.demoService.GetPromoNameList().subscribe(promoNameList => {
      this.promoNameList = promoNameList;
    });
    this.demoService.GetSubmittedByList().subscribe(submittedByList => {
      this.submittedByList = submittedByList;
    });
    this.demoService.GetFulfillmentTypeList().subscribe(fulfillmentTypeList => {
      this.fulfillmentTypeList = fulfillmentTypeList;
    });
  }

  emitFilterEvent() {
    const eventBody = {
      ...this.month && { Month: this.month },
      ...this.years.length && { Years: this.years },
      ...this.actions.length && { Actions: this.actions },
      ...this.makes.length && { Makes: this.makes },
      ...this.models.length && { Models: this.models },
      ...this.brands.length && { Brands: this.brands },
      ...this.promoName && { PromoName: this.promoName },
      ...this.submittedBy && { SubmittedBy: this.submittedBy },
      ...this.fulfillmentType && { FulfillmentType: this.fulfillmentType  }
    };
    this.filterEvent.emit(eventBody);
  }


  clearValues() {
    this.month = '';
    this.years = [];
    this.actions = [];
    this.makes = [];
    this.models = [];
    this.brands = [];
    this.promoName = '';
    this.submittedBy = '';
    this.fulfillmentType = '';
    this.emitFilterEvent();
  }

  ngOnDestroy() {
  }

}

