import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy } from '@angular/core';
import { PromotionPlannerService } from 'src/services/Item-Management-Services';

@Component({
  selector: 'promotion-planner-edit-cell-renderer',
  template: `<a class="product-title-link" (click)="btnEditClickHandler()">{{params.value || 'null'}}</a>`,
})
export class PromotionPlannerEditRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private promotionPlannerService: PromotionPlannerService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnEditClickHandler() {
    this.promotionPlannerService.setPromotionPlannerGridActionCellSubject({ action: 'edit', actionData: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
