import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as signalR from '@aspnet/signalr';

@Injectable({
  providedIn: 'root',
})
export class ChatNotifyService {

  connection: any;

  constructor(public http: HttpClient) {
  }

  createConnection() {
    this.connection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withUrl(environment.messagingHubUrl)
    .build();
  }

  connect() {
    this.connection
      .start()
      .then(function () {
      })
      .catch(function (err) {
        return console.error();
      });
  }

  stop() {
    this.connection.stop()
      .then(function () {
      })
      .catch(function (err) {
        return console.error();
      });
  }

}
