import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmentationService {

  constructor(private httpClient: HttpClient) { }

  runSegmentation(obj = {}) {
    return this.httpClient.post(environment.ClusterBaseUrl + '/segmentation-api/run_segmentation/', obj);
  }

  fetchMembershipSegments(user_id, file_name, version_no, run_tab_index, matrix_columns) {
    return this.httpClient.get(`${environment.ClusterBaseUrl}/segmentation-api/fetch-statistics-table/?user_id=${user_id}&file_name=${file_name}&version_no=${version_no}&run_tab_index=${run_tab_index}&matrix_columns=${matrix_columns}`);
  }

  fetchAllSegments(user_id, file_name, version_no, run_tab_index) {
    return this.httpClient.get(`${environment.ClusterBaseUrl}/segmentation-api/fetch-all-segments/?user_id=${user_id}&file_name=${file_name}&version_no=${version_no}&run_tab_index=${run_tab_index}`)
  }

  fetchSegmentDatById(user_id, page, file_name, version_no, run_tab_index, search_by, search_value, segmentaion_id, sort_by, sort_direction) {
    return this.httpClient.get(`${environment.ClusterBaseUrl}/segmentation-api/fetch-segment-data/?user_id=${user_id}&file_name=${file_name}&version_no=${version_no}&run_tab_index=${run_tab_index}&search_by=${search_by}&search_value=${search_value}&segmentation_id=${segmentaion_id}&page=${page}&sort_by=${sort_by}&sort_direction=${sort_direction}`)
  }

  applyAdjustment(obj = {}) {
    return this.httpClient.post(environment.ClusterBaseUrl + '/segmentation-api/apply-adjustment/', obj);
  }

  resetAdjustments(obj = {}) {
    return this.httpClient.post(environment.ClusterBaseUrl + '/segmentation-api/reset-adjustment/', obj); 
  }

  saveRun(obj = {}) {
    return this.httpClient.post(environment.ClusterBaseUrl + '/segmentation-api/save-run/', obj); 
  }

  fetchAlreadySavedRuns(user_id, file_name, version_no) {
    return this.httpClient.get(environment.ClusterBaseUrl + `/segmentation-api/run-tabs/?user_id=${user_id}&file_name=${file_name}&version_no=${version_no}`); 
  }
  
  deleteSavedRun(obj = {}) {
    return this.httpClient.post(environment.ClusterBaseUrl + `/segmentation-api/run-tabs/`, obj); 
  }

  UpdateSavedRun(obj = {}) {
    return this.httpClient.put(environment.ClusterBaseUrl + `/segmentation-api/run-tabs/`, obj); 
  }

  downloadSegmentationRunsData(obj = {}) {
    return this.httpClient.post(environment.ClusterBaseUrl + `/segmentation-api/download-membership-segments-file/`, obj); 
  }

  fetchbarChartData(user_id, file_name, version_no, run_tab_index, groupby) {
    return this.httpClient.get(environment.ClusterBaseUrl + `/segmentation-api/bar-chart-data/?file_name=${file_name}&version_no=${version_no}&user_id=${user_id}&run_tab_index=${run_tab_index}&groupby=${groupby}`);
  }

  fetchScatterPlotData(user_id, file_name, version_no, run_tab_index, xVariable, yVariable, segmentationIds) {
    return this.httpClient.get(environment.ClusterBaseUrl + `/segmentation-api/scatter-plot-data/?file_name=${file_name}&version_no=${version_no}&user_id=${user_id}&run_tab_index=${run_tab_index}&x_variable=${xVariable}&y_variable=${yVariable}&segmentation_ids=${segmentationIds}`);
  }

  fetchBubblePlotData(user_id, file_name, version_no, run_tab_index, groupby, variableOne, variableTwo) {
    return this.httpClient.get(environment.ClusterBaseUrl + `/segmentation-api/bubble-plot-data/?file_name=${file_name}&version_no=${version_no}&user_id=${user_id}&run_tab_index=${run_tab_index}&groupby=${groupby}&variable_one=${variableOne}&variable_two=${variableTwo}`);
  }

  fetchSpiderPlotData(user_id, file_name, version_no, run_tab_index) {
    return this.httpClient.get(environment.ClusterBaseUrl + `/segmentation-api/spider-chart-data/?file_name=${file_name}&version_no=${version_no}&user_id=${user_id}&run_tab_index=${run_tab_index}`);
  }
}
