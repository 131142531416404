import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-save-journey',
  templateUrl: './save-journey.component.html',
  styleUrls: ['./save-journey.component.scss']
})
export class SaveJourneyComponent implements OnInit {

  contextLog = new FormControl('');

  constructor(public dialogRef: MatDialogRef<SaveJourneyComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  save($event){
    if((this?.contextLog?.value as string).toLowerCase().trim() === 'default'){
      alert('Title Name cannot be set as Default, please choose a different name.')
      return;
    }
    this.dialogRef.close(this.contextLog.value);
  }

}
