import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  cacheBlockSize,
  defaultColDef,
  generateColumnDefs,
  itemColumns,
  paginationPageSize,
} from '../ag-grid/gridOptions';
import { PmsAddEditItemComponent } from './pms-add-edit-item/pms-add-edit-item.component';

import { NgxSpinnerService } from 'ngx-spinner';
import {
  ProcurementService,
  typesArray,
} from 'src/services/procurement.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'pms-items',
  templateUrl: './pms-items.component.html',
  styleUrls: ['./pms-items.component.scss'],
})
export class PmsItemsComponent implements OnInit {
  columns = itemColumns;
  typesArray = typesArray;
  filtersForm: FormGroup;
  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  defaultColDef = defaultColDef;
  public gridColumnDefs = generateColumnDefs(this.columns);

  public gridOptions: any;
  private gridApi;
  frameworkComponents = {};
  dataSource = [];
  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private procurementService: ProcurementService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.initializeFilterForm();
    this.fetchData();
  }
  clearFilter() {
    this.initializeFilterForm();
    this.fetchData();
  }
  initializeFilterForm() {
    this.filtersForm = this.formBuilder.group({
      itemName: [''],
      purchaseTypeId: [''],
    });
    this.filtersForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((res: any) => {
        this.fetchData();
      });
  }
  fetchData() {
    this.spinner.show();
    this.dataSource = [];
    this.procurementService
      .getItemsListing(
        this.filtersForm.get('itemName').value,
        this.filtersForm.get('purchaseTypeId').value
      )
      .subscribe(
        (res: any) => {
          this.dataSource = res;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  addEditItem(id = null) {
    const confirmationDialogRef = this.dialog.open(PmsAddEditItemComponent, {
      width: '500px',
      data: { id: id },
    });
    confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchData();
      }
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  cellClicked(event) {
    if (
      event &&
      event.value &&
      event.colDef &&
      event.colDef.field &&
      event.colDef.field == 'itemName'
    ) {
      const selectedRow = this.dataSource.find(
        (element) => element.itemName == event.value
      );
      this.addEditItem(selectedRow.itemId);
    }
  }
}
