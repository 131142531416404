<div class="item-wrapper admin-wrapper">
  <mat-tab-group [@.disabled]="true">
    <mat-tab>
      <ng-template mat-tab-label>Roles</ng-template>
      <ng-template matTabContent>
        <app-roles></app-roles>
      </ng-template>

    </mat-tab>
    <mat-tab label="Teams">
      <ng-template matTabContent>
        <app-teams></app-teams>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>Users</ng-template>
      <ng-template matTabContent>
        <app-users></app-users>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>Menu Configuration</ng-template>
      <ng-template matTabContent>
        <app-algo-menu></app-algo-menu>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="userObj.isSuperUser">
      <ng-template mat-tab-label>Client Configuration</ng-template>
      <ng-template matTabContent>
        <app-clients></app-clients>
      </ng-template>

    </mat-tab>

  </mat-tab-group>
</div>
