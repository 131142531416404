import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { USER_INFO } from 'src/common/keys';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { getUniqueId } from 'src/modules/item-manager/features/util/util';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataModuleService } from 'src/services/data-module.service';
import { SegmentationService } from 'src/services/segmentation.service';
import { cacheBlockSize, defaultColDef, generateGridColumns, paginationPageSize } from '../../ag-grid/gridOptions';
import { FileUploadService } from 'src/services/file-upload.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ColumnApi, GridApi } from 'ag-grid-enterprise';

@Component({
    selector: 'app-settings-segmentaion',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition(
          'expanded <=> collapsed',
          animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
        )
      ])
  ]
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() runSegmentationEvent = new EventEmitter();

  fileSelectionCheckbox = '';

  displayedColumns = ['expand', 'fileName', 'status', 'createdAt'];
  innerColumns = ['selection', 'filename', 'status', 'createdAt', 'action'];
  dataSource = new MatTableDataSource<any>();
  expandedElement = null;
  // allFiles = [];

  selection = new SelectionModel<any>(false);
  settingsForm: FormGroup;
  step = 0;
  // 0: No file selected or uploaded;
  // 1: New file uploaded to frontend; 
  // 2: File sent to backend for processing; 
  // 3: Selected file has been processed (valid file)
  userObj = {} as any;
  selectedFileName = '';
  selectedVersionNo = null;

  uniqueId = '';

  _unsubscribeAll = new Subject();

  metricesData = [];

  // Grid Variables
  public gridColumnDefs: any[] = [];
  public defaultColDef = defaultColDef;
  private gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions = {};
  gridData: any = [];
  rowModelType = 'serverSide';
  // serverSideStoreType = ServerSideStoreType.Partial;
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  // public modules = AllModules;

  showFilesFetchingSpinner = true;

  allColumns = [];

  segmentationCreated = false;

  settingFields = [
      {id: 'customer_id', label: 'Customer ID*'},
      {id: 'transaction_id', label: 'Transaction ID*'},
      {id: 'transaction_amount', label: 'Transaction Amount*'},
      {id: 'transaction_date', label: 'Transaction Date*'},
      {id: 'category_id', label: 'SKU/ Subclass/ Category ID'},
      {id: 'quantity_sold', label: 'Quantity Sold*'},
      {id: 'per_unit_original_price', label: 'Per Unit Original Price'},
      {id: 'discount_amount', label: 'Discount Amount'}
  ];

  selectionDropDowns = {
    'customer_id': [],
    'transaction_id': [],
    'transaction_amount': [],
    'transaction_date': [],
    'category_id': [],
    'quantity_sold': [],
    'per_unit_original_price': [],
    'discount_amount': []
  };

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    enableCheckAll: false,
    itemsShowLimit: 6,
    allowSearchFilter: true
  };

  groupByField = '';

  constructor(private dialog: MatDialog,
    private toastrService: ToastrService, private formBuilder: FormBuilder,
    public storage: LocalstorageService, private segmentaionService: SegmentationService,
    private fileModuleService: FileUploadService,
    private dataModuleService: DataModuleService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.uniqueId = getUniqueId();
    this.userObj = this.storage.get(USER_INFO);
    this.initializeForm();
  }

  ngAfterViewInit() {
    // for checking progress of files iteratively
    this.checkFileStatus();
  }

  private localeTimeString(date): Date {
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);

    const offset = utcDate.getTimezoneOffset() / 60;
    const hours = utcDate.getHours();

    localDate.setHours(hours - offset);

    return localDate;
  }

  toggleRow(element: any) {
    element.versions &&
      element.versions.length
      ? (this.expandedElement =
        this.expandedElement === element.file_name ? null : element.file_name)
      : null;
  }

  private initializeForm() {
    this.settingsForm = this.formBuilder.group({
      file_name: [null],
      user_id: [this.userObj.userId],
      version_no: [null],
      customer_id: [null, Validators.required],
      transaction_id: [null, Validators.required],
      transaction_amount: [null, Validators.required],
      transaction_date: [null, Validators.required],
      category_id: [null],
      quantity_sold: [null, Validators.required],
      per_unit_original_price: [null],
      discount_amount: [null],
      separate_segment: [false],
      allow_outliers: [false],
      no_of_segments: [null, [Validators.pattern("^[0-9]*$"), Validators.min(2), Validators.max(20), Validators.required]],
      set_no_of_segments_auto: [false]
    });
    this.settingsForm.get('set_no_of_segments_auto').valueChanges
      .subscribe((res: boolean) => {
        if (res) this.settingsForm.get('no_of_segments').disable();
        else this.settingsForm.get('no_of_segments').enable();
      });
  }

  async runSegmentation() {
    const fields = [];
    let isDuplicated = false;
    this.settingFields.forEach(element => {
        if(this.selectionDropDowns[element.id].length) {
          if (fields.indexOf(this.selectionDropDowns[element.id][0].id) > -1) {
            isDuplicated = true;
          } else {
            fields.push(this.selectionDropDowns[element.id][0].id);
          }
        }
    });
    if (isDuplicated) {
      this.toastrService.warning("Same column cannot be selected for multiple fields");
      return;
    }
    const confirmation = await this.showConfirmation();
    if (!confirmation) return;
    this.spinner.show();
    this.segmentaionService.runSegmentation(this.settingsForm.value)
    .subscribe((res: any) => {
      this.showFilesFetchingSpinner = true;
      this.runSegmentationEvent.next(null); // hide all other tabs
      this.toastrService.info('Segmentation is in progress. You can view the Output once it gets ready. View the status in Files section!', 'Info');
      this.selectedFileName = '';
      this.selectedVersionNo = '';
      this.resetSettings();
      setTimeout(() => {
        this.step = 1;
      }, 100);
    },
    err => {
      this.spinner.hide();
    });
  }

  clearData() {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to clear this data?' }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedFileName = '';
        this.selectedVersionNo = '';
        this.resetSettings();
        this.step = 0;
      }
    });
  }

  resetSettings() {
    this.runSegmentationEvent.next(null);
    this.initializeForm();
    this.step = 0;
  }

  clearSettings() {
    this.initializeForm();
  }

  showConfirmation(): Promise<any> {
    return new Promise((resolve) => {
      if (!this.segmentationCreated) resolve(true);
      else {
        const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: { confirmationMessage: 'Already saved segmentaion results will be discarded. Do you want to proceed?' }
        });
        confirmationDialogRef.afterClosed().subscribe(result => {
          resolve(result);
        });
      }
    });
  }

  openSelectedFileData() {
    this.resetSettings();
    this.segmentationCreated = false;
    const fileversionName = this.selection.selected[0];
    this.dataSource.data.forEach(f => {
      f.versions.forEach(v => {
        if ((v.name+'_'+v.version_name) == fileversionName) {
          this.selectedFileName = v.name;
          this.selectedVersionNo = v.uu_id;
        } 
      });
    });
    this.fetchFileDetails();
  }

  private fetchFileDetails() {
    this.spinner.show();
    this.resetFormSettings();
    this.dataModuleService.fetchVersionDetails(this.selectedFileName, this.userObj.userId, this.selectedVersionNo)
      .subscribe((res: any) => {
        this.settingsForm.get('file_name').setValue(this.selectedFileName);
        this.settingsForm.get('version_no').setValue(this.selectedVersionNo);
        const fileData = res.payload;
        this.allColumns = [];
        this.metricesData = [];
        fileData.variable_data.forEach((element, index) => {
          const obj = {
            id: element.name,
            name: element.displayName
          }
          if (element.is_matric) {
            this.metricesData.push(obj);
          }
          this.allColumns.push(obj);
        });
        fileData.segmentation_settings && this.settingsForm.patchValue(fileData.segmentation_settings);
        this.settingsForm.get('user_id').setValue(this.userObj.userId)
        this.settingFields.forEach(e => {
          if(this.settingsForm.get(e.id) && this.settingsForm.get(e.id).value) {
            this.allColumns.forEach(col => {
              if(col.id == this.settingsForm.get(e.id).value) this.selectionDropDowns[e.id] = [{id: col.id, name: col.name}];
            });
          }
        });
        this.loadFileData(fileData);
        
        if (this.selectionDropDowns.customer_id.length == 0) {
          const uniqueIdObj = fileData.object_to_cluster;
          this.allColumns.forEach(col => {
            if(col.id == uniqueIdObj) this.selectionDropDowns.customer_id = [{id: col.id, name: col.name}];
          });
        }
        this.groupByField = fileData.group_by;
        if (fileData.segmentation_status == 'Run Completed') {
          this.segmentationCreated = true;
          setTimeout(() => {
            this.runSegmentationEvent.next(
              {
                userId: this.userObj.userId,
                fileName: this.selectedFileName,
                versionNo: this.selectedVersionNo,
                runNumber: 'RUN_0',
                data: fileData.segmentation_details,
                metrices: this.metricesData,
                segmentation_labels: [],
                run_labels: [],
                segmentation_settings: fileData.segmentation_settings,
                groupby: this.groupByField
              }
            );
          }, 100);
        }
      });
  }

  loadFileData(fileData) {

    const arrayTest = [];
    fileData.variable_data.forEach((element) => {
      arrayTest.push(element.name)
    });
    this.gridColumnDefs = generateGridColumns(arrayTest, false);

    const datasource = {
      getRows: (params) => {
        const limit = params.request.startRow ? params.request.startRow + paginationPageSize + 1 : paginationPageSize + 1;
        const page = Math.floor(limit / paginationPageSize);
        this.spinner.show();
        this.dataModuleService.fetchFileDataById(this.selectedFileName, this.userObj.userId, page, false, false, false, false, false, this.selectedVersionNo, false)
          .subscribe((res: any) => {
            this.gridData = res.payload.results;
            const timeOut = 100;
            setTimeout(() => {
              this.gridData.map((e) => {
                e.uu_id = e.uu_id || e.mongo_id;
                e.update_duplicate = false;
                e.update_blanks = false;
                e.update_faulty = false;
              });
              // SET VARIABLES
              params.successCallback(this.gridData, res.payload.count);
              this.gridData.length == 0 && this.gridApi.showNoRowsOverlay();
              this.spinner.hide();
            }, timeOut);
          },
            err => {
              this.spinner.hide();
            });
      },
    };
    setTimeout(() => {
      this.gridApi && this.gridApi.setServerSideDatasource(datasource);
    }, 0);
  }

  closeFile() {
    this.resetSettings();
    this.step = 0;
  }

  viewSelectedFile(row) {
    this.selection.clear();
    this.selection.select(row.file_name);
    this.openSelectedFileData();
  }
  
  protected checkFileStatus() {
    this.showFilesFetchingSpinner && this.spinner.show();
    this.fileModuleService.fetchFilesProgress(this.userObj.userId)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.dataSource.data = res.payload;
        this.dataSource.data.map((e) => {
          e.created_time = this.localeTimeString(e.created_time);
          e.versions.map((v) => {
            v.created_time = this.localeTimeString(v.created_time);
            v.version_name = v.name;
            v.name = e.file_name;
          });
        });
        this.showFilesFetchingSpinner && this.spinner.hide();
        this.showFilesFetchingSpinner = false;
        setTimeout(() => {
          this.checkFileStatus();
        }, 3000);
      },
        err => {
          setTimeout(() => {
            this.checkFileStatus();
          }, 3000);
        });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
    this.spinner.hide();
  }

  selectionChange(id) {
    this.settingsForm.get(id).setValue(null);
    if (this.selectionDropDowns[id].length) {
      this.allColumns.forEach((e) => {
        if(e.id == this.selectionDropDowns[id][0].id) {
          this.settingsForm.get(id).setValue(e.id);
        }
      });
    }
  }

  resetFormSettings() {
    this.selectionDropDowns = {
      'customer_id': [],
      'transaction_id': [],
      'transaction_amount': [],
      'transaction_date': [],
      'category_id': [],
      'quantity_sold': [],
      'per_unit_original_price': [],
      'discount_amount': []
    };  
    this.settingsForm.reset();
    this.settingsForm.get('file_name').setValue(this.selectedFileName);
    this.settingsForm.get('version_no').setValue(this.selectedVersionNo);
  }

}

