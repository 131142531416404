import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { columnVariables } from '../../constants/columnVariables';

@Component({
  selector: 'app-sort-by-popup',
  templateUrl: './sort-by-popup.component.html',
  styleUrls: ['./sort-by-popup.component.scss']
})
export class SortByPopupComponent implements OnInit {
  
  measuresArray = [];
  columnVariables = JSON.parse(JSON.stringify(columnVariables));

  constructor(
    private dialogRef: MatDialogRef<SortByPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    let obj = {
      id: 'dicount_percentage',
      label: 'Discount Percentage'
    };
    this.columnVariables.push(obj);
    obj = {
      id: 'total_sales_per_' + this.data.groupby,
      label: 'Total Sales per ' + this.data.groupby
    };
    this.columnVariables.push(obj);

    this.columnVariables.forEach((e) => {
      const obj = {
        checked: this.data.sortTableBy.id == e.id,
        label: e.label,
        id: e.id
      };
      this.measuresArray.push(obj);
    });
  }

  onSaveClick = () => {
    this.data.sortTableBy.id ? this.dialogRef.close(this.data.sortTableBy) : this.onNoClick();
  }

  onNoClick = () => {
    this.dialogRef.close();
  }

}
