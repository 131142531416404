import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { planogramUrl } from 'src/modules/item-manager/features/planogram-optimizer/contants/planogram-contants';
import { PLANOGRAM_AUTH_KEY } from '../../common/keys';
import { LocalstorageService } from '../localstorage.service';

@Injectable({
  providedIn: 'root' 
})
export class PlanogramService {
 
  baseUrl = planogramUrl;
  updatePlanogramItem = new Subject();

  planogramItemActionHandler = new Subject();

  authCredentials = {
    username: "usama.dar",
    password: "algo@1234"
  };

  constructor(private httpClient: HttpClient, private storage: LocalstorageService) { }

  setAuthToken(): Promise<any> {
    return new Promise((resolve) => {
      if (this.storage.get(PLANOGRAM_AUTH_KEY))
        resolve(true);
      else {
        this.httpClient.post<any>(this.baseUrl + '/main/api/jwt/token/', this.authCredentials)
        .subscribe((res: any) => {
          if (res) {
            this.storage.set(PLANOGRAM_AUTH_KEY, res.access);
            resolve(true);
          }
          else resolve(false);
        },
        err => {
          resolve(false);
        })
      }
    });
  }

  addPlanogramData(file: any, name: string, user: Number) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('user', user.toString());
    return this.httpClient.post<any>(this.baseUrl + `/main/planogram/?user=${user}&name=${name}`, formData);
  }

  getPlanogramData(name: string, user: Number) {
    return this.httpClient.get<any>(this.baseUrl + `/main/planogram/?user=${user}&name=${name}`);
  }

  updatePlanogramData(id: Number, obj = {}) {
    return this.httpClient.put<any>(this.baseUrl + `/main/planogram/${id}`, obj);
  }

  bulkUpdatePlanogramData(obj: FormData) {
    return this.httpClient.post<any>(this.baseUrl + '/main/update-previous-planogram/', obj);
  }

  addNewMovies(file: any, name: string, user: Number) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('user', user.toString());
    return this.httpClient.post<any>(this.baseUrl + `/main/new-movies/`, formData);
  }

  addSingleMovie(newMovieObj: any) {
    return this.httpClient.post<any>(this.baseUrl + '/main/add-single-movie/', newMovieObj);
  }

  getNewMovies(name: string, user: Number) {
    return this.httpClient.get<any>(this.baseUrl + `/main/new-movies/?user=${user}&name=${name}`);
  }

  updateMovie(id: number, movieObj: {}) {
    return this.httpClient.put<any>(this.baseUrl + `/main/new-movies/${id}`, movieObj);
  }

  deleteMovie(id) {
    return this.httpClient.delete<any>(this.baseUrl + `/main/new-movies/${id}`);
  }

  runOptimizer(obj: FormData) {
    return this.httpClient.post<any>(this.baseUrl + `/main/run-optimization/`, obj);
  }

  getLatestNames() {
    return this.httpClient.get<any>(this.baseUrl + '/main/get-latest-optimizer-movies/');
  }

  savePlanogram(obj: FormData) {
    return this.httpClient.post<any>(this.baseUrl + '/main/save-planogram/', obj);
  }

  fetchLockedPlanogram() {
    return this.httpClient.get<any>(this.baseUrl + '/main/save-planogram/');
  }

  deleteSavedPlanogram(name) {
    return this.httpClient.delete<any>(this.baseUrl + `/main/save-planogram/${name}`);
  }

  updatePlanogramOnSwap(obj: FormData) {
    return this.httpClient.put<any>(this.baseUrl + '/main/save-planogram/', obj);
  }
}
