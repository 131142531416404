import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LocationHierarchyService {
  locationHierarchyMinimumLevel: number = 2;
  locationHierarchyMaximumLevel: number = 10;
  locationHierarchyMetadataList: any = [];
  locationHierarchyNodeMetadataList: any = [];

  constructor(private http: HttpClient) { }

  getLocationNodeLabel(level) {
    return _.get(_.find(this.locationHierarchyNodeMetadataList, a => a.locationHierarchyNodeLevel === level), 'locationHierarchyNodeName');
  }

  getLocationHierarchyLabel(level) {
    return _.get(_.find(this.locationHierarchyMetadataList, a => a.locationHierarchyLevel === level), 'locationHierarchyName');
  }


  GetLocationHierarchyListMetaDataList(): Observable<any> {
    return this.http.get<any>(`${environment.HierarchyManager}/locationHierarchyMetadataGetList`,)
      .pipe(map(response => {
        return response;
      }));
  }

  GetLocationHierarchyListNodeMetadataList(): Observable<any> {
    return this.http.get<any>(`${environment.HierarchyManager}/locationHierarchyNodeMetadataGetList`,)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateLocationHierarchyList(body): Observable<any> {
    return this.http.post<any>(`${environment.HierarchyManager}/locationHierarchyInsertOrUpdateMetadataItems`, body)
      .pipe(map(response => {
        return response;
      }));
  }
}
