export const rowSelection = 'single';
export const defaultColDef = {
  filter: false,
  sortable: false,
  minWidth: 50,
  resizable: true
};

export const generateGridColumns = (dataArray: any[], editable = false) => {
    const gridColumnDefs = [];
    dataArray.forEach((element: any) => {
        const headerObj: any = {
            headerName: element,
            field: element,
            minWidth: 40,
            resizable: true,
            editable: editable,
            isMetricVariable: true
        };
        gridColumnDefs.push(headerObj);
    });
    return gridColumnDefs;
}
