<div mat-dialog-content>
  <img [src]="rowData.document" width="250px" height="130px"><br>
  <span>{{rowData.retailer}}&nbsp;&nbsp;{{rowData.products}}&nbsp;&nbsp;{{rowData.insight}}</span><br>
  <span><b>Value:</b>{{rowData.value}}</span>
</div>
<div>
  <p><b>Actions:</b> Sell in additional 500 units to maintain 5 WOS <a style="color: blueviolet;" (click)="addElementToDashboard({
        menuId:543,
        orignalName:'FaceBook Actionable Insights',
        description:'Insights',
        content:'Insights',
        selector:'app-dynamic-report',
        isPowerBiReport:true,
        name:'Insights',
        displayName:'Insights',
        report:{
                    reportId:'35092adf-39fd-4cdb-ac2d-10ece3d8e87b',
                    reportName:'FaceBook Actionable Insights',
                    embedUrl:'https://app.powerbi.com/reportEmbed?reportId=35092adf-39fd-4cdb-ac2d-10ece3d8e87b&groupId=a3e52170-d',
                    datasetId:'41895f82-3ddc-477a-b683-4b2640914bab',
                    groupId:'a3e52170-d3e7-42ce-add2-390223dc488e',
                    webUrl:null
                 }
    }

    );">Click here</a> </p>
</div>
