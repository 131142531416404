
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-image-renderer',
  template: `<img style="    width: 40px;
  height: 40px; border-radius: 50%; margin-right: 5px; margin-left: -12px;" *ngFor = "let path of (params.data.pathList || [])" [src]= "path ? path:'assets/images/thumbnails.png'" >`
})

export class ImageRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      };
      this.params.onClick(params);
    }
  }
}
