<div class="card-item">
    <!-- <div class="labels">
        <div>
            <small>{{cardData.title}}</small>
        </div>
    </div> -->
    <img *ngIf="itemImage; else label" [src]="itemImage" [alt]="itemDescription" loading="lazy" />
    <ng-template #label>
        <label>{{itemDescription}}</label>
    </ng-template>
    
    <!-- <div class="fosner-stats">
        <p>{{cardData.weeks_old}}</p>
        <p>{{cardData.tw_forecast}}</p>
    </div> -->
</div>