import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
@Injectable()
export class ForecastLocationManagerService {
  constructor(private http: HttpClient) {
  }
  public locationGridActionCellSubject: Subject<any> = new Subject();
  public locationSetupActionSubject: Subject<any> = new Subject();
  // Observables
  // setter
  setLocationGridActionCellSubject = (value: any) => { this.locationGridActionCellSubject.next(value); };
  setLocationSetupActionSubject = (value: any) => { this.locationSetupActionSubject.next(value); };
  // getter
  getLocationGridActionCellSubject = () => this.locationGridActionCellSubject.asObservable();
  getLocationSetupActionSubject = () => this.locationSetupActionSubject.asObservable();
  ImportLocationManagerTemplate(body) {
    return this.http.post(`${environment.LocationManagerForecast}/importLocationManager`, body)
      .pipe(map(data => {
        return data;
      }));
  }
  LocationHierarchyGetList(body = {}) {
    return this.http.post(`${environment.LocationManagerForecast}/locationManagerGetList`, body)
      .pipe(map(response => {
        return response;
      }));
  }
  LocationManagerHierarchyGetList() {
    return this.http.get(`${environment.LocationManagerForecast}/locationManagerHierarchyGetList`)
      .pipe(map(response => {
        return response;
      }));
  }
  LocationManagerNodesGetList() {
    return this.http.get<any>(`${environment.LocationManagerForecast}/locationManagerNodesGetList`,)
      .pipe(map(response => {
        return response;
      }));
  }
  LocationManagerAdd(params) {
    return this.http.post(`${environment.LocationManagerForecast}/locationManagerAdd`, params)
      .pipe(map(response => {
        return response;
      }));
  }
  LocationManagerUpdate(params) {
    return this.http.post(`${environment.LocationManagerForecast}/locationManagerUpdate`, params)
      .pipe(map(response => {
        return response;
      }));
  }
  LocationManagerDelete(param) {
    return this.http.post(`${environment.LocationManagerForecast}/locationManagerDelete`, param)
      .pipe(map(response => {
        return response;
      }));
  }
  GetLocationSetupById(locationId) {
    return this.http.get(`${environment.LocationManagerForecast}/getLocationManagerById?locationId=${locationId}`)
      .pipe(map(response => {
        return response;
      }));
  }
}
