import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { element } from 'protractor';
import { USER_TOKEN_INFO } from 'src/common/keys';
import { AuthenticationService } from './Authentication-Services/authentication-services';

@Injectable({
  providedIn: 'root'
})
export class PMSPermissionAccessService {

  tokenData: any;
  claimnsCode=['U_TYPE1','U_TYPE2','ACC_AD'];
  constructor(private authService: AuthenticationService) {
  }

  setClaimsValues(){
    this.tokenData = this.getTokenData();
  console.log('Token Data'+this.tokenData);
}

compareClainm(){
  // if(this.tokenData.ACC_AD===)
}

canCreate(){
 this.claimnsCode.forEach(element=>{
  if(element===this.tokenData.ACC_AD)
  return true;
 });
}
getTokenData() {
  const token = localStorage.getItem(USER_TOKEN_INFO);
  try {
      return jwt_decode(token);
  } catch (Error) {
      return null;
  }
}

}

