<div class="container-fluid">
  <form [formGroup]="filtersForm" class="row product-grid-filter-row mt-10 mx-2">
    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <input formControlName="prTitle" matInput type="search" placeholder="Title">
        <mat-icon class="search-action-icon" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <mat-select formControlName="statusDescription" placeholder="Select statuses">
          <mat-option value=0>Awaiting</mat-option>
          <mat-option value=1>Received</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-clear-btn">
      <button (click)="clearFilter()" mat-flat-button color="accent">Clear</button>
    </div>
  </form>
  <div class="grid-action-btn-bar">
    <div class="d-flex align-items-center">
      <h6 class="mb-0 mx-2">PO Listing</h6>
      <mat-slide-toggle color="warn" [(ngModel)]="listingNgModel" (change)="slideToggleChanged()"></mat-slide-toggle>
      <h6 class="mb-0 mx-2">GRN Listing</h6>
    </div>
    <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>
    <button class="ml-10" [disabled]="isRowSelected" (click)="actionButton()" mat-flat-button
      color="accent">{{listingNgModel ? 'View' : 'Create GRN'}}</button>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
      toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
      [columnDefs]="gridColumnDefs" [rowData]="dataSource" (cellClicked)="cellClicked($event)" [pagination]="true"
      [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize" (gridReady)="onGridReady($event)"  #grnGrid>
    </ag-grid-angular>
  </div>
</div>
<div id="grnInvoice" class="container" hidden>
  <div class="row">
    <div class="col-6">
      <img src="/assets/images/algo-user-logo.png" style="width:100px">
    </div>
    <div class="col-6">
      <p class="text-center"><span class=" font-weight-bold h5">Algomus &nbsp; Limited </span></p>
      <p class="text-center">Office 1103, DHA business Hub</p>
      <p class="text-center">DHA Phase 8, Lahore. </p>
      <p class="text-center"><b>Ph:</b> 042-3661 0210 <b>Fax:</b> 042-3665 3043</p>
    </div>
  </div>
  <div class="row">
    <p class="col-12 text-center font-weight-bold h5">Goods &nbsp; Receipt &nbsp; Note</p>
  </div>
  <div class="row">
    <div class="col text-left ">
      <table>
        <tr>
          <td><b>Vendor :</b></td>
          <td>{{this.vendorDetail?.vendorName}}</td>
        </tr>
        <tr>
          <td><b>Address :</b></td>
          <td>{{this.vendorDetail?.vendorAddress}}</td>
        </tr>
        <tr>
          <td><b>Phone :</b></td>
          <td>{{this.vendorDetail?.vendorPhone}}</td>
        </tr>
        <tr>
          <td><b>Mobile :</b></td>
          <td>{{this.vendorDetail?.vendorMobile}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="row">
    <table class="mt-3 col-12 itemTable ">
      <thead>
        <tr>
          <th>Item Name</th>
          <th>Specifications</th>
          <th>Quanity</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of this.prItems">
          <td>{{item?.itemName}}</td>
          <td>{{item?.itemSpecifications}}</td>
          <td>{{item?.quantity}}</td>
          <td>100</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div class="row mt-5">
    <p class="col-12">
      <b>Approved by: Admin Algo</b>
    </p>
    <hr style="width:20%;text-align:left;margin-left:0">
  </div>
  <div class="row">
    <p class="col-12 font-weight-bold text-center">Please&nbsp; deliver&nbsp; the above&nbsp; mentioned&nbsp; item(s) at
      Office 1103, DHA business&nbsp;&nbsp; Hub, DHA Phase 8, Lahore&nbsp; within days</p>
  </div>
  <div class="row">
    <p class="col-12 text-center">This is a system generated document and does not require signature.</p>
  </div>
  <div class="row">
    <p class="col-12 text-center">----End of Document----</p>
  </div>
</div>
