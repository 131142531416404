<div class="container-fluid outlier-manager-page">

  <div class="action-btn-bar" style="justify-content: space-between;">
    <div style="display: flex">
      <div class="toggle-btn">
        <mat-slide-toggle (change)="toggleValueChange($event)" [(ngModel)]="isShowAnomalous">Show Only Anomalous Rows
        </mat-slide-toggle>
      </div>
      <ng-container *ngIf="azpipeline">
        <button matTooltipPosition="right" matTooltip="Data Sync Status {{azpipeline.status}}, &nbsp;Last Sync {{azpipeline.lastUpdated| date: 'dd/MM/yyyy HH-mm'}} &nbsp;" mat-icon-button class="mx-2 sync-btn" [ngClass]="setSyncButtonClassFunction(azpipeline.status)"
          (click)="triggerMappingManagerPipeline()">
          <i class="material-icons">
            sync
          </i>
        </button>
      </ng-container>
      <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
        (click)="openColumnDialog()">
        <i class="material-icons">
          ballot
        </i>
      </button>
    </div>

    <div class="right-side-section">
      <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
        (click)="onExportGridData()">
        <i class="material-icons">
          insert_drive_file
        </i>
      </button>

      <div class="item-wrapper">
        <div class="form-group">
          <mat-form-field>
            <mat-select placeholder="Number of Weeks" [(ngModel)]="numberOfWeeks"
              (selectionChange)="numberOfWeeksChange()">
              <mat-option [value]="52">52</mat-option>
              <mat-option [value]="104">104</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <button mat-flat-button color="accent" class="ml-10" (click)="getOutlierWeeksInfo()">Commit</button>

      <button mat-flat-button color="accent" class="ml-10" [matMenuTriggerFor]="menu">Action</button>
      <mat-menu #menu="matMenu" style="justify-content: center;">
        <button mat-menu-item (click)="resetToSuggestedEarlistUnadjustedWeek()">Reset All to
          Suggested</button>
        <button mat-menu-item (click)="updateOutlierAboveOriginalEarlistUnadjustedWeek()">Retain Values
          Above Original</button>
        <button mat-menu-item (click)="updateOutlierBelowOriginalEarlistUnadjustedWeek()">Retain Values
          Below Original</button>
      </mat-menu>

      <button class="add-icon-btn ml-10" (click)="openMappingSetupScreen()" matTooltip="Add Item Mapping" mat-icon-button
        color="primary" type="button">
        <i class="material-icons">
          add_circle
        </i>
      </button>

      <button *ngIf="isGridDataUpdated" mat-raised-button color="accent" (click)="saveUpdatedGridData()">Save all
        Changes</button>

    </div>
  </div>

  <div class="all-show-grid forecast-engine-ag-grid" *ngIf="isGridLoaded">
    <div class="inline-flex">
      <div *ngIf="gridSelectedRows.length" class="row-info">
        <spnan><strong>{{gridSelectedRows.length}}</strong> rows selected out of <strong>{{totalRows}}</strong> </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{totalRows}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>

    <ag-grid-angular #bottomDataGrid class="ag-theme-material outlier-grid" id="outlier-data-grid"
      rowSelection="multiple" [gridOptions]="bottomDataGridOptions" [groupDisplayType]="groupDisplayType"
       [columnDefs]="columnDefs" [animateRows]="true" [components]="components"
      [frameworkComponents]="outlierFrameworkComponents" [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType" [pagination]="true" [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize" [stopEditingWhenCellsLoseFocus]="true" [suppressColumnVirtualisation]="true"
      [suppressMaxRenderedRowRestriction]="true" [suppressRowClickSelection]="true" (gridReady)="onGridReady($event)"
      (selectionChanged)="gridRowSelectionChanged($event)" (cellValueChanged)="gridDataUpdated($event)">
    </ag-grid-angular>

    <div style="width: 0; height: 0;">
      <ag-grid-angular #bottomDataGridHidden class="ag-theme-material outlier-grid" id="outlier-data-grid-hidden"
        rowSelection="multiple" [gridOptions]="bottomDataGridOptions" [groupDisplayType]="groupDisplayType"
         [rowModelType]="'clientSide'" [columnDefs]="columnDefs" [components]="components"
        [frameworkComponents]="outlierFrameworkComponents" [pagination]="false" [suppressColumnVirtualisation]="true"
        [suppressMaxRenderedRowRestriction]="true" (gridReady)="onBottomHiddenGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>

</div>
