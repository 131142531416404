<h1 mat-dialog-title>{{editMode ? 'Update' : 'Add'}} Location</h1>
<form mat-dialog-content [formGroup]="addEditForm">
    <div class="row m-0">
        <div class="col-md-6">
            <strong>Address*</strong>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter address" matInput formControlName="locationName">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <strong>Currency Code*</strong>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter currency Code" matInput formControlName="currencyCode">
            </mat-form-field>
        </div>
    </div>
</form>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="accent" (click)="onSaveClick()" [disabled]="addEditForm.invalid">{{editMode ? 'Update' : 'Save'}}</button>
</div>
