import { Injectable } from '@angular/core';
import { ToastrService, ToastrConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NgxToasterService {
  toastConfig: any = {
    disableTimeOut: false,
    preventDuplicates: false,
    enableHtml: true,
    closeButton: true
  };

  constructor(private toast: ToastrService) { }

  /**
   * @param title String
   * @param message String
   * @param config Object
   */
  // tslint:disable-next-line: deprecation
  success(title: string, message: string, config?: ToastrConfig): void {
    this.toast.success(message, title, { ...this.toastConfig, ...(config || {}) });
  }
  clear(): void {
    this.toast.clear();
  }
  /**
   * @param title String
   * @param message String
   * @param config Object
   */
  info(title: string, message: string, config?: any): void {
    this.toast.info(message, title, { ...this.toastConfig, ...(config || {}) });
  }

  /**
   * @param title String
   * @param message String
   * @param config Object
   */
  // tslint:disable-next-line: deprecation
  warning(title: string, message: string, config?: ToastrConfig): void {
    this.toast.warning(message, title, { ...this.toastConfig, ...(config || {}) });
  }

  /**
   * @param title String
   * @param message String
   * @param config Object
   */
  // tslint:disable-next-line: deprecation
  error(title: string, message: string, config?: ToastrConfig): void {
    this.toast.error(message, title, { ...this.toastConfig, ...(config || {}) });
  }
}
