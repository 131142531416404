<!-- <div class="container">
  <iframe src='https://webchat.botframework.com/embed/Algo?s=vu1jY70DFVo.hKfmoPuMK5iC6NuaP0nniCPZlAZaSnmG4GIwE4Dk6Z0'
  style='width: 80%; height: 500px;'></iframe>
</div> -->
<!--
<div class="chat-wrapper bounce_out" (click)="clickEvent()">
  <img src="./assets/images/white-background-v2.png" alt="" class="message">
  <img src="../../assets/images/cancel.png" alt="" class="cross">
</div>
<div class="chat-popup" [ngClass]="status ? 'active' : 'inactive'">
  <div class="top-header">
    <img src="../../assets/images/algo-header-logo-white.png" alt="">
  </div>
  <div class="chat-history">
    <iframe
      src='https://webchat.botframework.com/embed/bot-algo-dev-1?s=0KCcwLEvqNg.CLXa73mBOiz7clZk9WWFe6SoW57ZcwOy1mF9NSCHLEI'>
    </iframe>
  </div>
  <div class="top-bottom">
  <mat-form-field floatLabel="never">
    <input matInput placeholder="Send a message" aria-label="Search">
  </mat-form-field>
</div>


</div> -->

<!-- <ngx-capture *ngIf="captureScreen" [target]="screen" (resultImage)="saveImage($event)"></ngx-capture> -->
<div id="dashboard" class="container-fluid">
  <div class="row page-head" [class.page-head-cred]="cred">
    <div class="col-12 col-md-12">
      <img src="assets/images/search-logo.png" alt="search-logo">
      <form action="">
        <mat-form-field class="example-full-width" floatLabel="never">
          <input [style.font-size]="'18px'" (keydown)="checkKey($event)" matInput placeholder="Ask me anything . . ."
            aria-label="Search" [matAutocomplete]="auto" [formControl]="searchCtrl">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="submitSearch($event.option.value)">
            <mat-option *ngFor="let page of filteredPages | async; let i=index"
              [style.border-bottom-color]="'linear-gradient(90deg, #232323 0%, #2AF598 100%)'"
              [style.color]="i%2 === 0 ? '#fff' : ''" [value]="page">
              <mat-chip-list aria-label="Fish selection">
                <mat-chip (click)="submitSearch(page)" [style.width]="'30%'">{{getName(page)}}</mat-chip>
                <!-- <mat-chip [style.width]="'15%'" [style.justify-content]="'center'" [style.margin]="'0 25px'"
                  color="primary" selected style="float: right;">
                  {{(i + 1000)* 10000}}
                </mat-chip> -->
                <mat-chip [style.width]="'55%'" color="accent" selected>{{page.description}}</mat-chip>
              </mat-chip-list>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- <span class="material-icons" (click)="askBot()">
          arrow_drop_down
        </span> -->
        <button style="display: none;" mat-button matSuffix (click)="askBot()">Ask</button>
      </form>

    </div>
    <!-- <div class="col-md-2 need-help">
  <button mat-button [matMenuTriggerFor]="menu">Need Help?</button>
  <mat-menu #menu="matMenu"  yPosition="above">
    <button mat-menu-item>FAQs</button>
    <button mat-menu-item>Contact Us</button>
  </mat-menu>
</div> -->
  </div>
  <div [class.loadingDiv]="loading" class="loadingDiv"></div>

  <div #screen id="bot" class="container-fluid">
    <div class="mt-5 action-panel top text-right">
      <!-- <button (click)="openTelemetry()" title="Recent Activity" mat-icon-button color="primary">
        <i class="material-icons">
          history
        </i>
      </button> -->
      <button style="margin-left: 5px;" *ngIf="resultViewList.length > 0" (click)="resultViewList = []"
        title="Clear All" mat-icon-button color="primary">
        <i class="material-icons">
          clear_all
        </i>
      </button>

    </div>

    <div *ngFor="let iss of resultViewList;let i=index" class="bot-wrapper">
      <div id="{{iss.menuId}}" *ngIf="iss.searchText" class="right-chat">
        <div class="right-icon">
          <mat-icon fontSet="material-icons-outlined">
            account_circle
          </mat-icon>
          <p>{{user?.firstName}}</p>
        </div>
        <div *ngIf="iss.searchText" class="container darker">
          <p> {{iss.searchText}}</p>
          <!-- <span class="time-right">{{getTime(iss) | date: 'hh:mm a'}}</span> -->

        </div>
      </div>
      <div class="d-flex">
        <div *ngIf="!(iss.name === 'Ask Algo') && !iss.isMenuLink"
          class="bot-img">
          <img src="../../assets/images/algo-user-logo.png" alt="algo-logo">
        </div>
        <div *ngIf="!iss.searchText && iss.timestamp && !iss.isMenuLink" class="response-back">

          <div class="accordion-icon">
            <span class="material-icons" [ngClass]="iss && iss.liked ? 'green': ''" matTooltip="Like"
              (click)="likeMenuItem(iss.displayName, true);iss.liked = true;iss.disLiked = false"
              matTooltipPosition="above">
              thumb_up
            </span>
            <!-- <img matTooltip="Like" (click)="likeMenuItem(iss.displayName, true)" matTooltipPosition="above"
              src="assets/images/thumb_up.svg" alt=""> -->

            <span class="separator"></span>
            <span class="material-icons" [ngClass]="iss && iss.disLiked ? 'red': ''" matTooltip="Dislike"
              (click)="likeMenuItem(iss.displayName, false); iss.disLiked = true;iss.liked = false;"
              matTooltipPosition="above">
              thumb_down
            </span>
            <!-- <img matTooltip="Dislike" (click)="likeMenuItem(iss.displayName, false)" matTooltipPosition="above"
          src="assets/images/thumb_down.svg" alt=""> -->
            <span class="separator"></span>
            <span class="material-icons" [ngClass]="iss && ifInFavourite(iss) ? 'red': ''" matTooltip="Favorite"
              (click)="addToFavourites(iss)" matTooltipPosition="above">
              star
            </span>
            <!-- <img matTooltip="Favorite" (click)="addToFavourites(iss.displayName)" matTooltipPosition="above"
          src="assets/images/star.svg" alt=""> -->
            <!-- <span class="separator"></span>
            <span class="material-icons" matTooltip="Home" (click)="scrollToTop('dashboard')"
              matTooltipPosition="above">
              home
            </span> -->
            <!-- <img matTooltip="Home" (click)="scrollToTop('dashboard')" matTooltipPosition="above" src="assets/images/home.svg" alt=""> -->
            <!-- <span class="separator"></span>
            <span class="material-icons" matTooltip="Mail" matTooltipPosition="above">
              email
            </span> -->
            <!-- <img matTooltip="Mail" matTooltipPosition="above" src="assets/images/email.svg" alt=""> -->
            <span class="separator"></span>
            <span class="material-icons" matTooltip="Delete" (click)="deleteMenuItem(iss, i)" matTooltipPosition="above">
              delete
            </span>
            <!-- <img matTooltip="Delete" (click)="deleteMenuItem(iss.displayName)" matTooltipPosition="above"
            src="assets/images/delete.svg" alt=""> -->
            <!-- <span class="separator"></span>
            <span class="material-icons" matTooltip="Refresh" matTooltipPosition="above">
              refresh
            </span> -->
            <!-- <span class="separator"></span>
            <span class="material-icons" matTooltip="Share" matTooltipPosition="above">
              share
            </span> -->
            <!-- <span class="separator"></span>
            <span class="material-icons" matTooltip="BroadCast" matTooltipPosition="above">
              cast
            </span> -->
            <!-- <span class="material-icons" (click)="openFullScreenMode(iss.menuId+''+i)" matTooltip="Full Screen"
              matTooltipPosition="above">
              fullscreen
            </span> -->
            <!-- <img matTooltip="Refresh" matTooltipPosition="above" src="assets/images/refresh.svg" alt=""> -->
          </div>
          <div class="response-inner">
            <div id="{{iss.menuId}}{{i}}" class="response-inner-div">
              {{appendChild(iss, i)}}
            </div>
            <!-- <span>{{getTime(iss) | date:'hh:mm a'}} </span> -->

          </div>
        </div>
        <ng-container *ngIf="!iss.searchText && !iss.timestamp && !iss.isMenuLink">
          <mat-accordion class="bot-accordian" style="width: 100%;">
            <div class="accordion-icon">
              <span class="material-icons" [ngClass]="iss && iss.liked ? 'green': ''" matTooltip="Like"
                (click)="likeMenuItem(iss.displayName, true);iss.liked = true;iss.disLiked = false"
                matTooltipPosition="above">
                thumb_up
              </span>

              <span class="separator"></span>
              <span class="material-icons" [ngClass]="iss && iss.disLiked ? 'red': ''" matTooltip="Dislike"
                (click)="likeMenuItem(iss.displayName, false); iss.disLiked = true;iss.liked = false;"
                matTooltipPosition="above">
                thumb_down
              </span>

              <span class="separator"></span>
              <span class="material-icons" (click)="iss.isOpen = !iss.isOpen" type="button" cdkOverlayOrigin
                #trigger="cdkOverlayOrigin" matTooltip="Take htmltocanvas Screenshot" matTooltipPosition="above">
                photo_camera
              </span>

              <!-- -->
              <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                [cdkConnectedOverlayOpen]="iss.isOpen" [cdkConnectedOverlayHasBackdrop]="true"
                (backdropClick)="iss.isOpen = !iss.isOpen">
                <div class="screen-shot-menu">
                  <h5>Share to Channel</h5>
                  <input placeholder="Search..." name="retailer" [matAutocomplete]="channelauto"
                    (input)="_filterChannelList($event)" (focus)="onChannelInputFocus($event)"
                    (click)="stopPropagation($event)" />

                  <mat-autocomplete [displayWith]="displayChannelFn" #channelauto="matAutocomplete"
                    (optionSelected)="selectChannel($event.option.value, iss, i)">
                    <mat-option *ngFor="let item of filteredChannelList | async" [value]="item">
                      {{item.channelName}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </ng-template>

              <span class="separator"></span>
              <span class="material-icons" [ngClass]="iss && ifInFavourite(iss) ? 'red': ''" matTooltip="Favorite"
                (click)="addToFavourites(iss)" matTooltipPosition="above">
                star
              </span>
              <!-- <span class="separator"></span>
              <span class="material-icons" matTooltip="Home" (click)="scrollToTop('dashboard')"
                matTooltipPosition="above">
                home
              </span> -->
              <!-- <span class="separator"></span>
              <span class="material-icons" matTooltip="Mail" matTooltipPosition="above">
                email
              </span> -->
              <span class="separator"></span>
              <span class="material-icons" matTooltip="Delete" (click)="deleteMenuItem(iss)" matTooltipPosition="above">
                delete
              </span>
              <!-- <span class="separator"></span>
              <span class="material-icons" matTooltip="Refresh" matTooltipPosition="above">
                refresh
              </span> -->
              <!-- <span class="separator"></span>
              <span class="material-icons" matTooltip="Share" (click)="openDialog(iss, shareDialog)"
                matTooltipPosition="above">
                share
              </span> -->
              <!-- <span class="separator"></span>
              <span class="material-icons" matTooltip="BroadCast" (click)="openDialog(iss, broadCastDialog)"
                matTooltipPosition="above">
                cast
              </span> -->
              <span class="separator"></span>
              <span class="material-icons" (click)="openFullScreenMode(iss.menuId+''+i)" matTooltip="Full Screen"
                matTooltipPosition="above">
                fullscreen
              </span>
            </div>
            <mat-expansion-panel [expanded]="isExpanded(iss.menuId+''+i)">
              <mat-expansion-panel-header collapsedHeight="70px" expandedHeight="70px">
                <mat-panel-title>
                  {{iss.displayName || iss.name}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div #menuScreens id="{{iss.menuId}}{{i}}">
                {{appendChild(iss, i)}}
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>


    </div>
    <div *ngIf="loading" style="margin-left: 35px;" class="loader-inner line-scale-pulse-out">
      <div></div>
      <div></div>
      <img src="../../assets/images/a-log.png" alt="">
    </div>

  </div>
  <ng-template #channel>
    <app-channel id="channel" class="row">
    </app-channel>
  </ng-template>
  <ng-template #source>
    <app-source id="source" class="row">
    </app-source>
  </ng-template>
  <ng-template #distributor>
    <app-customer-distributor id="distributor" class="row"></app-customer-distributor>
  </ng-template>
  <ng-template #resetPassword>
    <app-reset-password id="resetPassword" class="row"></app-reset-password>
  </ng-template>
  <ng-template #roles>
    <app-roles id="roles" class="row"></app-roles>
  </ng-template>
  <ng-template #category>
    <!-- <app-category class="row">
  </app-category> -->
  </ng-template>
  <ng-template #simulation>
    <app-report [selectedReport]="selectedReport" class="row" id="simulation">
    </app-report>
  </ng-template>
</div>
<ng-template #broadCastDialog>
  <h1 mat-dialog-title>Confirmation!</h1>
  <div mat-dialog-content>
    <p>Are you sure want to broad cast {{selectedMenu?.name}}?</p>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="saveAndClose()">Yes</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="dialogRef.close()">No</button>
  </div>
</ng-template>
<ng-template #shareDialog>
  <h1 mat-dialog-title>Share</h1>
  <div mat-dialog-content>
    <div style="height: 40px;">
      <mat-select placeholder="Select User" multiple [(ngModel)]="selectedUsers" [ngModelOptions]="{standalon: true}">
        <mat-option *ngFor="let res of userList; let i = index" [value]="res">
          {{res.firstName}} {{res.lastName}}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="share()">Share</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="dialogRef.close()">Cancel</button>
  </div>
</ng-template>