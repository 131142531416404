<div class="dialog-close-btn">
  <button mat-icon-button (click)="onNoClick()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>

<div class="row">
  <div class="col-12 item-wrapper">
    <div class="form-group">
      <label for="commitWeekControl">Select Week</label>
      <mat-form-field>
        <input matInput [matDatepicker]="commitWeekControl" [matDatepickerFilter]="weekDayFilter" name="commitWeekControl"
          [(ngModel)]="commitWeekDate" [min]="minCommitWeek" [max]="maxCommitWeek">
        <mat-datepicker-toggle matSuffix [for]="commitWeekControl"></mat-datepicker-toggle>
        <mat-datepicker #commitWeekControl></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions class="mat-dialog-actions-section">
  <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="accent" (click)="commitWeek(false)">Apply</button>
  <button mat-flat-button color="accent" (click)="commitWeek(true)">Commit All Weeks</button>
</div>