import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { UpdateNameDialogComponent } from 'src/common/update-name-dialog/update-name-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SegmentationService } from 'src/services/segmentation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-data-segmentation',
  templateUrl: './segmentation.component.html',
  styleUrls: ['./segmentation.component.scss']
})
export class SegmentationComponent implements OnInit {

  outputTabData = null;
  runTabs = [];
  selectedTab = 0;

  resizeGraphSubject: Subject<boolean> = new Subject();

  userObj = {} as any;
  versionNo = null;
  
  constructor(private changeDetectionRef: ChangeDetectorRef, private toastrService: ToastrService, private spinner: NgxSpinnerService, private dialog: MatDialog, private segmentationService: SegmentationService) { }

  ngOnInit(): void {
  }

  fetchRunTabs() {
    this.spinner.show();
    this.runTabs = [];
    this.segmentationService.fetchAlreadySavedRuns(this.outputTabData.userId, this.outputTabData.fileName, this.outputTabData.versionNo)
    .subscribe((res: any) => {
      res.payload.forEach(e => {
        const obj = {
          name: e.name,
          uu_id: e.uu_id,
          _id: e._id,
          fileName: e.file_name,
          versionNo: Number(e.version_no),
          userId: Number(e.user_id),
          groupby: this.outputTabData.groupby
        };
        this.runTabs.push(obj);
      });
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    });
  }

  runSegmentationHandler(event) {
    this.versionNo = null;
    this.runTabs = [];
    if (event && event.versionNo) this.versionNo = event.versionNo;
    this.outputTabData = event;
    this.outputTabData && this.fetchRunTabs();
    this.selectedTab = 0;
    setTimeout(() => {
      this.outputTabData && (this.selectedTab = 1);
      this.changeDetectionRef.detectChanges();
      this.onTabChanged();
    }, 200);
  }
 
  onTabChanged() {
    this.resizeGraphSubject.next(true);
  }

  editTabName(tab, event) {
    event && event.preventDefault();
    event && event.stopPropagation();

    const columnsDialogRef = this.dialog.open(UpdateNameDialogComponent, {
      width: '650px',
      data: {
        name: tab.name
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const obj = {
          id: tab._id,
          name: result
        };
        this.segmentationService.UpdateSavedRun(obj)
        .subscribe(() => {
          this.toastrService.success('Tab name updated successfully.');
          this.spinner.hide();
          tab.name = result;
        },
        err => {
          this.spinner.hide();
          this.toastrService.error('Failed to update tab name.');
        });
      }
    });
  }

  removeTab(i: number, uu_id: number, event) {
    event && event.preventDefault();
    event && event.stopPropagation();
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to remove this Run?' }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const obj = {
          'file_name': this.outputTabData.fileName,
          'version_no': this.outputTabData.versionNo,
          'user_id': this.outputTabData.userId,
          'uu_id': uu_id
        };
        this.segmentationService.deleteSavedRun(obj)
        .subscribe(() => {
          this.toastrService.success('Tab deleted successfully.');
          this.runTabs.splice(i, 1);
          this.spinner.hide();
        },
        err => {
          this.toastrService.error('Failed to delete tab.');
          this.spinner.hide();
        });
      }
    });
  }

  outputTabRunBtnHandler() {
    this.fetchRunTabs();
  }

}
