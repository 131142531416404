import { Injectable, ViewChild } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { USER_TOKEN_INFO, BOT_TOKEN_INFO, USER_INFO, PLANOGRAM_AUTH_KEY } from '../common/keys';
import { LocalstorageService } from './localstorage.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from './Authentication-Services/authentication-services';
import { planogramUrl } from 'src/modules/item-manager/features/planogram-optimizer/contants/planogram-contants';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  FILE_UPLOAD_URLS: any = [
    `${environment.AppDocument}/UploadFiles`,
    `${environment.AppCommonCode}/DocumentAdd`,
    `${environment.AppAccount}/AddClientConfigurationOnboarding`,
  ];

  constructor(
    public storage: LocalstorageService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token: string = this.storage.get(USER_TOKEN_INFO);
    if (request.url.includes(environment.ClusterBaseUrl) || request.url.includes(environment.FileManagementURL)) {
      token && (request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) }));
    }
    else if (request.url.includes(planogramUrl)) {
      const planogramAuthKey: string = this.storage.get(PLANOGRAM_AUTH_KEY);
      if (planogramAuthKey)
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + planogramAuthKey) });
    }
    else {
      if (token) {
        if (request.url === `https://directline.botframework.com/v3/directline/tokens/generate`) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + environment.BotSecretKey) });
        } else if (request.url.startsWith(`https://directline.botframework.com/v3/directline/conversations`)) {
          const conversatonToken: string = this.storage.get(BOT_TOKEN_INFO);
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + conversatonToken) });
        } else if (request.url !== `https://login.microsoftonline.com/dd70a646-992f-4f0e-a15e-d9c554e1f576/oauth2/v2.0/token`) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }
      }
      // if (!(request.url === `${environment.AppDocument}/UploadFiles` || request.url === `${environment.AppCommonCode}/DocumentAdd`)) {
      if (!(this.isFileObjectInFormData(request.body))) {
        if (!request.headers.has('Content-Type')) {
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }


        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        request = request.clone({ headers: request.headers.set('x-application-key', 'AdminPortal') });
      } else {
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
      }
    }

    // console.log(this.translate.currentLang);
    const self = this;
    return next.handle(request)
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            const user = this.storage.get(USER_INFO);
            this.authService.logoutUser(user).subscribe(res => { });
            if (self.spinner) {
              self.spinner.hide();
            }
            self?.router.navigate(['auth/Login']);
            this.storage.clear();
            console.clear();
            return;
          } else {
            if (self.spinner) {
              self.spinner.hide();
            }
          }
          return throwError(error);
        }
      }));
    // return next.handle(request).pipe(
    //     map((event: HttpEvent<any>) => {
    //         if (event instanceof HttpResponse) {
    //         }
    //         return event;
    //     }));

  }

  isFileObjectInFormData(body) {
    let isFileInBody: boolean = false;
    if (body instanceof FormData) {
      isFileInBody = true;
    }
    return isFileInBody
  }
}
