import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TreeviewItem } from 'ngx-treeview/lib/models/treeview-item';
import { CreateProductHierarchyTree } from 'src/common/item-tree-dorpdown';
import { CreateLocationHierarchyTree } from 'src/common/location-tree-dropdown';
import { CombinationManagerService } from 'src/services/forecast-services/combination-manager.service';
import { ForecastItemManagerService } from 'src/services/forecast-services/item-manager.service';
import { ForecastLocationManagerService } from 'src/services/forecast-services/location-manager.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ProductHierarchyService } from 'src/services/setup-services/product-hierarchy.service';

@Component({
  selector: 'app-combination-setup',
  templateUrl: './combination-setup.component.html',
  styleUrls: ['./combination-setup.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CombinationSetupComponent implements OnInit {
  isEdit: boolean = false;

  public locationHierarchyTreeList: TreeviewItem[];
  public productHierarchyTreeList: TreeviewItem[];
  productHierarchyList: any = [];
  locationHierarchyList: any = [];
  public selectedHierarchiesLocation = [];
  public selectedHierarchiesProduct = [];
  combinationSetupForm = new FormGroup({
    productHierarchyLastLevelId: new FormControl(null, Validators.required),
    locationHierarchyLastLevelId: new FormControl(null, Validators.required),
    active: new FormControl(true)
  });

  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public itemManagerService: ForecastItemManagerService,
    private locationManagerService: ForecastLocationManagerService,
    private combinationManagerService: CombinationManagerService,
    public dialogRef: MatDialogRef<CombinationSetupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.getLookupData();
  }

  setHierarchyValue(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'LocationHierarchy': {
        this.selectedHierarchiesLocation = selectedDropdown.value;
        this.combinationSetupForm.get('locationHierarchyLastLevelId').setValue(this.selectedHierarchiesLocation[0]);
        break;
      }
      case 'ProductHierarchy': {
        this.selectedHierarchiesProduct = selectedDropdown.value;
        this.combinationSetupForm.get('productHierarchyLastLevelId').setValue(this.selectedHierarchiesProduct[0]);
        break;
      }
    }
  };

  getLookupData() {
    this.spinner.show();
    this.itemManagerService.ProductHierarchyTreeGetList().subscribe(response => {
      this.productHierarchyList = response;
      this.createProductHierarchyDropdownList(response);
    });
    this.locationManagerService.LocationManagerHierarchyGetList().subscribe(res => {
      this.locationHierarchyList = res;
      this.createLocationHierarchyDropdownList(res);
      this.spinner.hide();
    });
  }

  createProductHierarchyDropdownList(itemData, selectedValues: any = []) {
    this.productHierarchyTreeList = CreateProductHierarchyTree(itemData, selectedValues);
  };

  createLocationHierarchyDropdownList(locationData, select: any = []) {
    this.locationHierarchyTreeList = CreateLocationHierarchyTree(locationData, select);
  };



  saveCombination() {
    if (this.combinationSetupForm.valid && this.selectedHierarchiesLocation.length < 2 && this.selectedHierarchiesProduct.length < 2) {
      const payload = {
        ...this.combinationSetupForm.value,
      };
      if (this.isEdit) {
        this.combinationManagerService.CombinationUpdate(payload).subscribe(response => {
          this.dialogRef.close(true);
          this.toastr.success('Success', 'Combination updated successfully.');
          // this.dimItemGetById(this.itemData.dimItemId);
        }, err => {
          this.toastr.error('Error', 'System failed to create item.');
        });
      } else {
        this.combinationManagerService.CombinationAdd(payload).subscribe((response: any) => {
          // this.dimItemGetById(response.dimItemId);
          this.isEdit = true;
          this.dialogRef.close(true);
          this.toastr.success('Success', 'Combination created successfully.');
        }, err => {
          this.toastr.error('Error', 'System failed to create Combination.');
        });
      }

    } else {
      this.toastr.error('Error', 'Please fill the form correctly.');
    }
  }

  closeModal() {
    this.dialogRef.close(true);
  }

}
