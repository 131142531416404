import { ColDef } from 'ag-grid-enterprise';
import * as _ from 'lodash';
import { dateRenderer, yesNoRenderer } from 'src/ag-grid/renderers/common-renderers/common-renderers';

export const paginationPageSize = 50;
export const cacheBlockSize = 50;
export const rowSelection = 'single';

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true
};

export const masterGridColDefs = (isRetailerTeam = false) => {
  const masterGridColDefs: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      width: 150,
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false
    },
    {
      headerName: '',
      width: 100,
      field: 'cellAction',
      cellRenderer: 'masterItemActionCellRenderer',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false
    },
    {
      headerName: 'Product Title ID',
      field: 'productTitleId',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Product Title Name',
      field: 'productTitleName',
      cellRenderer: 'masterItemEditRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Account',
      field: 'accountName',
      filter: isRetailerTeam ? 'agTextColumnFilter' : false,
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Release Date',
      field: 'releaseDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    },
    {
      headerName: 'Pre-awareness Date',
      field: 'preAwarenessDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    },
    {
      headerName: 'Announce Date',
      field: 'announcementDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    },
    {
      headerName: 'Rating',
      field: 'ratingName',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Theatrical Release Date',
      field: 'theatricalReleaseDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    }

  ];
  return masterGridColDefs;
}

export const childGridColDefs = (addSelectionBox = false, isRetailerTeam = false) => {
  const itemChildColDef: ColDef[] = [
    {
      headerName: '',
      width: 100,
      field: 'cellAction',
      cellRenderer: 'childItemActionCellRenderer',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false
    },
    {
      headerName: 'Image',
      width: 150,
      cellRenderer: (params) => {
        return `<img class="item-grid-thumbnail"
          src="${_.get(_.pickBy(_.get(params, 'data.document', {}), (value, key) => { return value !== '' }), 'documentThumbnailPath', 'assets/images/default-grid-image.png')}"
        />`;
      },
      filter: false,
      sortable: false
    },
    {
      headerName: 'Internal SKU ID',
      field: 'internalSKUId',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'External Item ID',
      field: 'externalItemId',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Product Title ID',
      field: 'productTitleId',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Item Description',
      field: 'itemDescription',
      cellRenderer: 'childItemEditRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Release Date',
      field: 'releaseDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    },
    {
      headerName: 'UPC',
      field: 'upc',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains']
      }
    },
    {
      headerName: 'Account',
      field: 'accountName',
      filter: isRetailerTeam ? 'agTextColumnFilter' : false,
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Class Name',
      field: 'className',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Class Code',
      field: 'classCode',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Subclass Name',
      field: 'subClassName',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Subclass Code',
      field: 'subClassCode',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Retek Cost',
      field: 'retekCost',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Regular Retail Price',
      field: 'regRetailPrice',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'MSRP',
      field: 'msrp',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Rating',
      field: 'ratingName',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Digital Code',
      field: 'digitalCode',
      cellRenderer: yesNoRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Expiry Date',
      field: 'expirationDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    }
  ]
  if (addSelectionBox) {
    itemChildColDef.unshift({
      headerName: 'Select',
      field: 'select',
      width: 150,
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false
    });
  }
  return itemChildColDef;
};
