import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';


@Injectable()
export class CombinationManagerService {

  constructor(private http: HttpClient) {
  }

  public combinationGridActionCellSubject: Subject<any> = new Subject();

  setCombinationGridActionCellSubject = (value: any) => { this.combinationGridActionCellSubject.next(value) };

  getCombinationGridActionCellSubject = () => this.combinationGridActionCellSubject.asObservable();





  ImportCombinationManagerTemplate(body) {
    return this.http.post(`${environment.CombinationManager}/importDetail`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  CombinationMangerGetList(body ) {
    return this.http.post(`${environment.CombinationManager}/combinationManagerGetList`, body)
      .pipe(map(response => {
        return response;
      }));
  }
  GetCombinationMangerGetList() {
    return this.http.get(`${environment.CombinationManager}/combinationManagerGetList`)
      .pipe(map(response => {
        return response;
      }));
  }

  CombinationAdd(body) {
    return this.http.post(`${environment.CombinationManager}/combinationManagerAdd`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  CombinationManagerAddRelationShip(body) {
    return this.http.post(`${environment.CombinationManager}/combinationManagerAddRelationship`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  CombinationUpdate(body) {
    return this.http.post(`${environment.CombinationManager}/combinationManagerUpdateRelationship`, body)
      .pipe(map(response => {
        return response;
      }));
  }


}
