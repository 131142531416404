import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cacheBlockSize, defaultColDef, generateColumnDefs, paginationPageSize, vendorColumns } from '../ag-grid/gridOptions';

import { NgxSpinnerService } from 'ngx-spinner';
import { ProcurementService } from 'src/services/procurement.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PmsAddEditVendorComponent } from './pms-add-edit-vendor/pms-add-edit-vendor.component';
import { element } from 'protractor';
@Component({
  selector: 'pms-vendors',
  templateUrl: './pms-vendors.component.html',
  styleUrls: ['./pms-vendors.component.scss']
})
export class PmsVendorsComponent implements OnInit {

  columns =vendorColumns;

  filtersForm: FormGroup;
   // Grid Variables
   paginationPageSize = paginationPageSize;
   cacheBlockSize = cacheBlockSize;
   defaultColDef = defaultColDef;
   public gridColumnDefs = generateColumnDefs(this.columns);

   public gridOptions: any;
   private gridApi;
   frameworkComponents = {};
   dataSource = [];

  constructor(private dialog: MatDialog, private spinner: NgxSpinnerService,
    private procurementService: ProcurementService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.initializeFilterForm();
    this.fetchData();
  }

  clearFilter() {
    this.initializeFilterForm();
    this.fetchData();
  }

  initializeFilterForm() {
    this.filtersForm = this.formBuilder.group({
      VendorName: [''],
      VendorEmail: [''],
      NTNNumber: ['']
    });

    this.filtersForm.valueChanges
    .pipe(
      debounceTime(300)
    )
    .subscribe((res: any) => {
      this.fetchData();
    });
  }

  fetchData() {
    this.spinner.show();
    this.dataSource = [];
    this.procurementService.getVendorsListing(
      this.filtersForm.get('VendorName').value, this.filtersForm.get('VendorEmail').value,
      this.filtersForm.get('NTNNumber').value)
    .subscribe((res: any) => {
      this.dataSource = res;
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    });
  }

  addEditVendor(id = null) {
    const confirmationDialogRef = this.dialog.open(PmsAddEditVendorComponent,
      {
        width: '800px',
        data: { id: id }
      });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchData();
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  cellClicked(event) {
    if (event && event.value && event.colDef && event.colDef.field && event.colDef.field == 'vendorName') {
      console.log(this.dataSource);
      const selectedRow= this.dataSource.find(element=>element.vendorName==event.value);
      console.log(selectedRow);
      this.addEditVendor(selectedRow.vendorId);
    }
  }

}
