import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';

@Component({
  selector: 'seasonality-line-chart-cell-renderer',
  template: `<div class="chart-cell" (click)="viewChartHandler()">
    <fusioncharts
      [chartConfig]="chartConfig"
      [dataSource]="dataSource">
    </fusioncharts>
</div>`,
})
export class SeasonalityLineChartCellRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;
  SKIP_KEYS: any = [];

  dataSource: any = {
    chart: {
      theme: "fusion",
      showXAxisLine: 0,
      showYAxisValues: 0,
      showValues: 0,
      chartLeftMargin: "0",
      chartTopMargin: "0",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      canvasPadding: "0",
      drawAnchors: "0",
      divLineAlpha: "0"
    },
    data: []
  }

  chartConfig: Object = {
    width: '200',
    height: '40',
    type: 'line',
    dataFormat: 'json',
  };

  constructor(public seasonalityService: SeasonalityService) {
  }

  agInit(params: any): void {
    this.params = params;
    this.updateChartData();
  }

  refresh(params?: any): boolean {
    this.updateChartData();
    return true;
  }

  getSeasonalityChartData() {
    const payload = {
      seasonalityId: this.params.data.seasonalityId,
      startWeekDate: this.params.data.seasonalityType === 'static' ? this.params.data.startWeekDate : null,
      endWeekDate: this.params.data.seasonalityType === 'static' ? this.params.data.endWeekDate : null,
      rollingWeek: this.params.data.seasonalityType === 'rolling' ? this.params.data.rollingWeek : null,
    };
    this.seasonalityService.GetSeasonalityGridData(payload).subscribe((data: any) => {
      this.params.data.saleData = data;
      this.updateChartData();
    });
  }

  getWeekList() {
    const weekMetaList = _.keys(_.omit(_.get(this.params, 'data.saleData', {}), this.SKIP_KEYS));
    let weekList: any = [];
    /*
     Split this string "2021#202101#2021-01-03" on hash(#)
     0 - Report Year
     1 - Week Number
     2 - Week Start Date
   */
    weekMetaList.forEach(week => {
      const weekLables = week.split('#');
      weekList.push({
        columnField: week,
        year: weekLables[0],
        weekNumber: Number(weekLables[1]),
        weekStartDate: weekLables[2]
      });
    });
    weekList = _.orderBy(weekList, ['weekNumber'], ['asc']);

    return weekList;
  }

  updateChartData() {
    const weekList = this.getWeekList();
    const chartData = [];
    weekList.forEach(week => {
      const value = _.get(this.params.data, `saleData.${week.columnField}`, '0_0').split('_');
      chartData.push({ value: value[1] });
    });
    this.dataSource.data = chartData;
  }

  viewChartHandler() {
    this.seasonalityService.setSeasonalityManagerGridChartActionSubject(this.params.data);
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
