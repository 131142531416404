import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  TreeviewComponent,
  TreeviewConfig,
  TreeviewHelper,
  TreeviewI18n,
  TreeviewItem,
} from 'ngx-treeview';
import { DropdownTreeViewAccountSelectI18n } from './dropdown-treeview-account-selectI18n';
import * as _ from 'lodash';

@Component({
  selector: 'app-treeview-account-dropdown',
  templateUrl: './treeview-account-dropdown.component.html',
  styleUrls: ['./treeview-account-dropdown.component.scss'],
  providers: [
    { provide: TreeviewI18n, useClass: DropdownTreeViewAccountSelectI18n },
  ],
})
export class TreeViewAccountDropdownComponent implements OnInit {
  @Input() items: TreeviewItem[];
  @Input() isCheckBoxRequired;
  @Input() isSingleSelection = false;
  @Input() isReadonly = false;
  @Output() valueChange = new EventEmitter();
  @Input() type = '';
  @Input() disabled;
  @ViewChild('tree') tree: TreeviewComponent;
  values: number[];
  previousSelectedValues: number[];
  selectedItem: number[] = [];
  scrollData;
  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark',
  ];
  buttonClass = this.buttonClasses[0];
  config: TreeviewConfig;
  constructor() { }
  ngOnInit(): void {
    this.config = TreeviewConfig.create({
      hasAllCheckBox: this.isCheckBoxRequired ? true : false,
      hasFilter: true,
      hasCollapseExpand: true,
      decoupleChildFromParent: this.isSingleSelection ? true : false,
      maxHeight: 400,
    });

  }
  setValues = (values) => {
    // if (this.isSingleSelection === true) {
    //   this.previousSelectedValues = Object.assign([], this.selectedItem);
    //   this.selectedItem = _.difference(values, this.previousSelectedValues);
    //   values.length >= 2 && this.onItemCheckedd();
    //   values = this.selectedItem;
    // }
    this.selectedItem = values;
    const outputObject = {
      value: values,
      type: this.type,
    };
    this.valueChange.emit(outputObject);
  };

  // clearTreeSelection() {
  //   this.tree.allItem.checked = false;
  //   this.tree.onAllCheckedChange();
  // }

  // onItemCheckedd() {
  //   this.clearTreeSelection();
  //   this.items.forEach(async (item) => {
  //     if (item.checked) {
  //       item.checked = false;
  //     }
  //     await this.uncheckChildren(item);
  //   });

  //   this.items = this.items;
  //   this.tree.onAllCheckedChange();

  // }

  // uncheckChildren(item: TreeviewItem) {
  //   return new Promise((resolve, reject) => {
  //     if (item.children && item.children.length) {
  //       item.children.forEach(async (child) => {
  //         child.checked = false;
  //         await this.uncheckChildren(child);
  //       });
  //     } else {
  //       item.checked = this.selectedItem.includes(item.value);
  //       resolve(true);
  //     }
  //   });
  // }

  // uncheckAllChildren(item: TreeviewItem) {
  //   if (item.children) {
  //     item.children.forEach((child) => {
  //       if (child.checked) {
  //         child.checked = false;
  //         this.uncheckChildren(child.value, child);
  //       }
  //     });
  //   }
  // }

  onFilterChange(value: string): void {
    console.log('filter:', value);
  }
}
