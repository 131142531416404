<div class="container-fluid mapping-manager-page">
  <div class="row product-grid-filter-row mt-10">
    <div class="product-filter-field">
      <!-- <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()"
          placeholder="{{locationHierarchyService.getLocationNodeLabel(2)}}" [(ngModel)]="node2">
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
      </mat-form-field> -->
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()" class="seasonalityKey-width"
          placeholder="{{locationHierarchyService.getLocationNodeLabel(2)}}" name="node2" [(ngModel)]="node2"
          [ngModelOptions]="{standalone: true}" [ngModelOptions]="{standalone: true}"
          [matAutocomplete]="locationKeyAuto" (input)="_filterLocationByKey($event)"
          (focus)="onLocationFocus($event)" />
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
        <mat-autocomplete [displayWith]="displayLocationFn" #locationKeyAuto="matAutocomplete"
          (optionSelected)="selectLocationManager($event.option.value)">
          <mat-option *ngFor="let location of node2list | async" [matTooltip]="location.node2" [value]="location"
            matTooltipClass="tooltips">
            {{location.node2}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <!-- <mat-form-field appearance="fill">
        <mat-select placeholder="{{locationHierarchyService.getLocationNodeLabel(1)}}" [(ngModel)]="node1"
          (selectionChange)="applyFilterEvent()">
          <mat-option *ngFor="let storeCode of storeCodeList" [value]="storeCode.statusId">{{storeCode.statusName}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()" class="seasonalityKey-width"
          placeholder="{{locationHierarchyService.getLocationNodeLabel(1)}}" name="node1" [(ngModel)]="node1"
          [ngModelOptions]="{standalone: true}" [ngModelOptions]="{standalone: true}"
          [matAutocomplete]="locationKeyAutoNode1" (input)="_filterLocationByKey2($event)"
          (focus)="onLocationFocus2($event)" />
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
        <mat-autocomplete [displayWith]="displayLocationFn2" #locationKeyAutoNode1="matAutocomplete"
          (optionSelected)="selectLocationManager2($event.option.value)">
          <mat-option *ngFor="let location of node1list | async" [matTooltip]="location.node1" [value]="location"
            matTooltipClass="tooltips">
            {{location.node1}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput placeholder="Launch Date" (dateChange)="applyFilterEvent()" [matDatepicker]="launchDateData"
          [(ngModel)]="launchDate">
        <mat-datepicker-toggle matSuffix [for]="launchDateData"></mat-datepicker-toggle>
        <mat-datepicker #launchDateData></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput placeholder="Closing Date" (dateChange)="applyFilterEvent()" [matDatepicker]="closeDate"
          [(ngModel)]="closingDate">
        <mat-datepicker-toggle matSuffix [for]="closeDate"></mat-datepicker-toggle>
        <mat-datepicker #closeDate></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <mat-select placeholder="Active / InActive" [(ngModel)]="active" (selectionChange)="applyFilterEvent()">
          <mat-option *ngFor="let status of statusList" [value]="status.statusId">{{status.statusName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-clear-btn mr-2">
      <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
    </div>
  </div>
  <div class="mapping-action-btn-bar">

    <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>

    <div class="grid-actions inline-flex">
      <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
        (click)="exportLocationListGridData()">
        <i class="material-icons">
          insert_drive_file
        </i>
      </button>
      <div *ngIf="selectedRows.length" class="row-info">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{totalRows}}</strong>
        </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{totalRows}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>



    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="importExportMenu">Export/Import</button>
    <mat-menu #importExportMenu="matMenu">
      <button mat-menu-item (click)="onExportLocationTemplate()">Export Template</button>
      <button mat-menu-item (click)="importDataByTemplate()">Import
        <input class="hidden" type="file" id="upload-location-setup-data" name="upload-location-setup-data"
          accept=".csv" (change)="validateUploadedCSV($event)">
      </button>
    </mat-menu>

    <button class="add-icon-btn ml-10" (click)="openLocationSetupDetail()" matTooltip="Add Location Setup"
      mat-icon-button color="primary" type="button">
      <i class="material-icons">
        add_circle
      </i>
    </button>

  </div>

  <div class="all-show-grid" *ngIf="isGridLoaded">
    <ag-grid-angular #mappedSKUsGrid id="outlier-mapped-sku-data-grid" class="ag-theme-material mapped-skus-grid"
      [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowData]="gridData"
      [rowSelection]="rowSelection" [animateRows]="true" [pagination]="true" [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize" [rowModelType]="rowModelType" [serverSideStoreType]="serverSideStoreType"
      [frameworkComponents]="frameworkComponents" (selectionChanged)="rowSelectionChanged($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

  <div style="width: 0; height: 0;" *ngIf="isGridLoaded">
    <ag-grid-angular #locationExportTemplateHidden class="ag-theme-material hide-grid" [gridOptions]="gridOptions"
      [columnDefs]="columnHiddenDefs" [defaultColDef]="defaultColDef" (gridReady)="onHiddenGridReady($event)">
    </ag-grid-angular>
  </div>



</div>