import {
  ChannelMessaging,
  MessageModel,
  UsersInChannel,
} from './../../models/channelMessaging.model';
import { Injectable, Pipe } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChannelMessagingService {
  constructor(public http: HttpClient) { }

  public GetUsersDetails() {
    return this.http.get(`${environment.messagingUrl}/GetApplicationUser`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public CreateChannelMessaging(channelMessaging: ChannelMessaging) {
    return this.http.post<ChannelMessaging>(
      `${environment.createChannelUrl}/Add`,
      channelMessaging
    );
  }
  public GetChannelList() {
    return this.http
      .get(`${environment.createChannelUrl}/GetChannelsByChannelOwner`)
      .pipe(
        map((channelList) => {
          return channelList;
        })
      );
  }
  public MarkChannelMessagesRead(channelId: any) {
    return this.http
      .put(`${environment.channelMessage}/MarkChannelMessagesRead?chennelId=${channelId}`, null)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public GetChannelByChannelId(channelId: any) {
    return this.http
      .get(
        `${environment.createChannelUrl}/GetChannelByChannelId?channelId=${channelId}`
      )
      .pipe(
        map((channelDetails) => {
          return channelDetails;
        })
      );
  }

  public UpdateChannelDetails(channelMessagingModel: ChannelMessaging) {
    return this.http.put<ChannelMessaging>(
      `${environment.createChannelUrl}/Update`,
      channelMessagingModel
    );
  }
  public DeleteChannelRecord(channelId) {
    return this.http
      .delete(`${environment.createChannelUrl}/delete?channelId=${channelId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public LeaveChannelUser(userLeaveChannel: UsersInChannel) {
    return this.http
      .put<UsersInChannel>(
        `${environment.createChannelUrl}/LeaveChannel`,
        userLeaveChannel
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public GetAllChannelMessage(channelId: any, currentPage: number, pageSize: number) {
    return this.http
      .get(
        `${environment.channelMessage}/GetAllChannelMessageByChannelId?ChannelId=${channelId}&currentPage=${currentPage}&pageSize=${pageSize}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  public GetUnreadChannelMessagesCount(channelId: number) {
    return this.http
      .get(
        `${environment.channelMessage}/GetUnreadChannelMessagesCount?chennelId=${channelId}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  public SendMessage(messageModel: any) {
    return this.http
      .post<MessageModel>(`${environment.channelMessage}/Add`, messageModel)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  public UpdateMessage(messageModel: MessageModel) {
    return this.http
      .put<MessageModel>(`${environment.channelMessage}/Update`, messageModel)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  public DeleteMessage(channelMessageId) {
    return this.http
      .delete(
        `${environment.channelMessage}/Delete?ChannelMessagId=${channelMessageId}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
