import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProductHierarchyService {
  productHierarchyMinimumLevel: number = 2;
  productHierarchyMaximumLevel: number = 10;
  productHierarchyMetadataList: any = [];
  productHierarchyNodeMetadataList: any = [];

  constructor(private http: HttpClient) { }


  getProductNodeLabel(level) {
    return _.get(_.find(this.productHierarchyNodeMetadataList, a => a.productHierarchyNodeLevel === level), 'productHierarchyNodeName');
  }

  getProductHierarchyLabel(level) {
    return _.get(_.find(this.productHierarchyMetadataList, a => a.productHierarchyLevel === level), 'productHierarchyName');
  }

  GetProductHierarchyList(): Observable<any> {
    return this.http.get<any>(`${environment.HierarchyManager}/productHierarchyMetadataGetList`,)
      .pipe(map(response => {
        return response;
      }));
  }

  GetHierarchyNodeMetadataList(): Observable<any> {
    return this.http.get<any>(`${environment.HierarchyManager}/productHierarchyNodeMetadataGetList`,)
      .pipe(map(response => {
        return response;
      }));
  }

  GetProductHierarchyNodeMetadataList(): Observable<any> {
    return this.http.get<any>(`${environment.HierarchyManager}/dimItemNodesGetList`,)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateProductHierarchyLIst(body): Observable<any> {
    return this.http.post<any>(`${environment.HierarchyManager}/productHierarchyInsertOrUpdateMetadataItems`, body)
      .pipe(map(response => {
        return response;
      }));
  }

}
