<div class="dialog-close-btn">
  <button mat-icon-button (click)="closeModal()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>
<div class="container-fluid promo-manager-detail-page blog-height">
  <mat-label><strong> Combination Setup </strong></mat-label>
  <div class="item-wrapper m-5">
    <form class="promo-form m-2" [formGroup]="combinationSetupForm">
      <div id="fb-forecast-new-planner" class="container-fluid">
        <div class="btn-wrapper">
          <div class="form-group">
            <app-treeview-account-dropdown [type]="'LocationHierarchy'" [items]="locationHierarchyTreeList"
              (valueChange)="setHierarchyValue($event)">
            </app-treeview-account-dropdown>
          </div>
          <div class="form-group">
            <app-treeview-item-hierarchy-dropdown [type]="'ProductHierarchy'" [items]="productHierarchyTreeList"
              (valueChange)="setHierarchyValue($event)">
            </app-treeview-item-hierarchy-dropdown>
          </div>
        </div>
      </div>
      <div class="create-button">
        <button mat-flat-button color="warn" class="ml-10" (click)="saveCombination()">Save</button>
        <mat-slide-toggle class="promo-toggle-btn ml-10 mt-five" name="active" formControlName="active">Active
        </mat-slide-toggle>
      </div>
    </form>
  </div>
</div>
