import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { USER_INFO } from 'src/common/keys';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

interface IClusterCol { id: string, clusterName: string, data: any[], page: 1, total: number, search: '', hiddenItems: number };

@Component({
  selector: 'cluster-column',
  templateUrl: './cluster-column.component.html',
  styleUrls: ['./cluster-column.component.scss']
})

export class ClusterColumnComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() isOutputTab = false;
  @Input() fileName = '';
  @Input() runIndex = 'RUN_0';
  @Input() objectToCluster = '';
  @Input() clusteringLabels: [];
  @Input() isAdjusted?: boolean = false;
  @Input() versionNo: any;
  @Output() adjustmentSaved = new EventEmitter();
  @Output() disableSaveRun: EventEmitter<boolean> = new EventEmitter();

  metrices = [];

  searchSubject = new Subject();

  editableFields = [];
  reorderedData: any;

  draggedObj = {
    rowIndex: null,
    colIndex: null
  };

  userObj = {} as any;

  clusteringDataSource: Array<IClusterCol>;

  dragDropObj = {
    row_ids: [],
    cluster_ids: [],
    clusteringLabels: []
  };


  nameChanged = false;

  _unsubscribeAll = new Subject<void>();
  fetchClustersSubscription: Subscription;

  constructor(private spinner: NgxSpinnerService, private toastrService: ToastrService, private clusteringService: ClusteringForecastService, private storage: LocalstorageService) { }

  ngOnInit(): void {
    this.userObj = this.storage.get(USER_INFO);
    this.clusteringDataSource = [];

    // search filter
    this.searchSubject
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((res: { col, value }) => {
        this.spinner.show();
        this.fetchClusterData(this.clusteringDataSource[res.col].id, res.col, 1, this.clusteringDataSource[res.col].search, this.clusteringDataSource[res.col].clusterName).finally(() => {
          this.spinner.hide();
        });
      });
  }

  ngAfterViewInit() {
    this.clusteringLabels && this.patchData();
    this.isOutputTab && this.addSummaryStatsListener();
  }

  addSummaryStatsListener() {
    this.clusteringService.summaryStatsChangeSubject
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: Array<any>) => {
        this.metrices = res;
      });
  }

  ngOnChanges() {
  }

  patchData(clusteringLabels = this.clusteringLabels) {
    if (!clusteringLabels) return;
    this.clusteringDataSource = [];
    this.fetchAllClusters();
  }

  fetchAllClusters() {
    this.spinner.show();
    const obj = {
      user_id: this.userObj.userId,
      file_name: this.fileName,
      run_tab_index: this.runIndex == 'RUN_0' ? "Cluster_Id_adj" : 'Cluster_Id_' + this.runIndex,
      unique_key: this.objectToCluster,
      version_no: this.versionNo
    };
    this.fetchClustersSubscription && this.fetchClustersSubscription.unsubscribe();
    this.fetchClustersSubscription = this.clusteringService.fetchAllClusters(obj)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.spinner.hide();

        this.clusteringLabels.forEach((item: any, clusterIndex: number) => {
          this.clusteringDataSource[clusterIndex] = {
            id: item.label_id,
            data: [],
            page: 1,
            total: 0,
            search: '',
            clusterName: item.label_name,
            hiddenItems: 0
          };
          res.payload.results.forEach((clusterElement) => {
            if (clusterElement.cluster_id == item.label_id) {
              const dataArray = [];
              clusterElement.members.forEach(member => {
                dataArray.push({ [this.objectToCluster]: member });
              });
              this.clusteringDataSource[clusterIndex] = {
                id: clusterElement.cluster_id,
                data: dataArray,
                page: 1,
                total: clusterElement.total_count,
                search: '',
                clusterName: item.label_name,
                hiddenItems: 0
              };
            }
          });
        });
      },
        err => {
          this.spinner.hide();
        })
  }

  fetchClusterData(clusterId, index, page, search, clusterName): Promise<any> {
    return new Promise((resolve, reject) => {
      const obj = {
        file_name: this.fileName,
        user_id: this.userObj.userId,
        cluster_id: clusterId,
        run_tab_index: this.runIndex == 'RUN_0' ? "Cluster_Id_adj" : this.runIndex,
        search_field: this.objectToCluster,
        search_value: search,
        version_no: this.versionNo,
        page_no: page
      };
      this.clusteringService.fetchClusterColumnData(obj)
        .pipe(
          takeUntil(this._unsubscribeAll)
        )
        .subscribe((res: any) => {
          if (page == 1 && this.clusteringDataSource[index] && this.clusteringDataSource[index].data) {
            const filteredData = this.clusteringDataSource[index].data.filter((item) => item.dragged);
            this.clusteringDataSource[index].data = [];
            let hiddenItems = 0;
            filteredData.forEach((e) => {
              if (this.showHideAdjustedData(e[this.objectToCluster], true, search)) e.hideItem = false;
              else {
                e.hideItem = true;
                hiddenItems++;
              }
              this.clusteringDataSource[index].data.push(e);
            });
            this.clusteringDataSource[index].hiddenItems = hiddenItems;
          }
          if (page == 1 && !this.clusteringDataSource[index]) {
            this.clusteringDataSource[index] = {
              id: clusterId,
              data: res.payload.results,
              page: page,
              total: res.payload.total_count,
              search: search,
              clusterName: clusterName,
              hiddenItems: 0
            };
          } else {
            this.clusteringDataSource[index].page = page;
            this.clusteringDataSource[index].total = res.payload.total_count;
            res.payload.results.forEach((element: any) => {
              if (this.dragDropObj.row_ids.indexOf(element[this.objectToCluster]) == -1)
                this.clusteringDataSource[index].data.push(element);
            });
          }

          resolve(true);
        },
          err => {
            reject();
          });
    });
  }

  onDragStart(rowIndex, colIndex) {
    this.draggedObj.rowIndex = rowIndex;
    this.draggedObj.colIndex = colIndex;
  }

  onDragOver(event) {
    event.preventDefault();
  }

  onDrop(rowIndex, colIndex, event) {
    if (this.runIndex != 'RUN_0' || this.draggedObj.colIndex == null || this.draggedObj.rowIndex == null) {
      this.onDragEnd(event);
      return;
    }
    if (colIndex == this.draggedObj.colIndex) {
      this.onDragEnd(event);
      return;
    } // in case item is moved within the same cluster
    // FIND DRAGGED ELEMENT, REMOVE IT FROM ARRAY AND PUSH IT AT DROPPED INDEX
    const draggedElement = this.clusteringDataSource[this.draggedObj.colIndex].data[this.draggedObj.rowIndex];
    draggedElement.dragged = true;
    this.clusteringDataSource[this.draggedObj.colIndex].data.splice(this.draggedObj.rowIndex, 1);
    this.clusteringDataSource[colIndex].data.splice(rowIndex, 0, draggedElement);
    if (this.dragDropObj.row_ids.indexOf(draggedElement[this.objectToCluster]) > -1) {
      const index = this.dragDropObj.row_ids.indexOf(draggedElement[this.objectToCluster]);
      this.dragDropObj.cluster_ids.splice(index, 1);
      this.dragDropObj.row_ids.splice(index, 1);
    }
    this.dragDropObj.cluster_ids.push(this.clusteringDataSource[colIndex].id);
    this.dragDropObj.row_ids.push(draggedElement[this.objectToCluster]);
    this.disableSaveRun.emit(true);
    this.onDragEnd(event);
  }

  onDragEnd(event) {
    this.draggedObj = {
      rowIndex: null,
      colIndex: null
    };
    event && event.preventDefault();
    event && event.stopPropagation();
  }

  onDropColumn(colIndex) {
    this.onDrop(0, colIndex, null);
  }

  async onScroll(item: IClusterCol, index: number) {
    if (item.page * 20 < item.total) { // 20 items per page
      this.spinner.show();
      item.page++;
      await this.fetchClusterData(item.id, index, item.page, item.search, item.clusterName);
      this.spinner.hide();
    }
  }

  downloadData() {
    const formData = new FormData();
    formData.append('user_id', this.userObj.userId);
    formData.append('file_name', this.fileName);
    formData.append('run_tab_index', this.runIndex);
    formData.append('version_no', this.versionNo);
    this.spinner.show();
    this.clusteringService.downloadClusterColumnsData(formData)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        if (res && res.payload) {
          window.open(res.payload, '_blank');
        }
        this.spinner.hide();
      },
        err => {
          this.toastrService.error('Something went wrong!', 'Error');
          this.spinner.hide();
        })
  }

  adjustMembership() {
    const clustersData = [];
    let emptyName = false;
    let isSpecialCharacter = false;
    let isFirstCharacterSpecial = false;
    let isOnlyDigit = false;
    let isFirstCharacterDigit = false;

    // check for if header is special character
    const checkForSpecialCharacters = (headerName: string) => {
      const format = /[a-zA-Z0-9]/;
      return !format.test(headerName);
    }

    this.clusteringLabels.forEach((element: any) => {
      const clusterObj = {
        uu_id: element.label_id,
        name: element.label_name.trim()
      }
      !clusterObj.name && (emptyName = true);
      checkForSpecialCharacters(clusterObj.name) && (isSpecialCharacter = true);
      checkForSpecialCharacters(clusterObj.name && clusterObj.name[0]) && (isFirstCharacterSpecial = true);
      !isNaN(clusterObj.name) && (isOnlyDigit = true);
      !isNaN(clusterObj.name && clusterObj.name[0]) && (isFirstCharacterDigit = true);
      clustersData.push(clusterObj);
    });
    if (emptyName) {
      this.toastrService.warning('Names cannot be empty.');
      return;
    }
    else if (isSpecialCharacter) {
      this.toastrService.warning('Names cannot consist of special characters entirely.');
      return;
    }
    else if (isFirstCharacterSpecial) {
      this.toastrService.warning('Names cannot start with a special character.');
      return;
    }
    else if (isOnlyDigit) {
      this.toastrService.warning('Names cannot consist of digits entirely.');
      return;
    } else if (isFirstCharacterDigit) {
      this.toastrService.warning('Names cannot start with a digit.');
      return;
    }


    // checking for duplicate headers
    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
    const lowerCasedArray = [];
    clustersData.forEach(headerElement => {
      lowerCasedArray.push(headerElement.name.toLowerCase());
    });
    const duplicates = [...new Set(findDuplicates(lowerCasedArray))];
    if (duplicates.length) {
      this.toastrService.warning('Duplicate names are not allowed: ' + duplicates, 'Warning');
      return;
    }

    const obj = {
      file_name: this.fileName,
      user_id: this.userObj.userId,
      row_ids: this.dragDropObj.row_ids,
      cluster_ids: this.dragDropObj.cluster_ids,
      cluster_by: this.objectToCluster,
      metric_columns: this.metrices,
      update_cluster_label: clustersData,
      version_no: this.versionNo
    };
    const formData = new FormData();
    formData.append('adjustment_settings', JSON.stringify(obj));
    this.spinner.show();
    this.clusteringService.adjustMembership(formData)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.isAdjusted = true;
        this.dragDropObj.clusteringLabels = this.clusteringLabels;
        this.adjustmentSaved.next(this.dragDropObj);
        this.dragDropObj.cluster_ids = [];
        this.dragDropObj.row_ids = [];
        this.disableSaveRun.emit(false);
        res.payload.clusteringLabels = this.clusteringLabels;
        this.clusteringService.adjustMembershipSubject.next(res.payload);
        this.patchData();
        this.nameChanged = false;
        this.toastrService.success('Adjustment saved successfully!', 'Success');
      },
        err => {
          this.toastrService.error('Failed to save adjustment.', 'Error');
          this.spinner.hide();
        });
  }

  refetchAdjustedMembersData(clusterIds) {
    this.patchData(clusterIds);
  }

  resetToAlgo() {
    this.spinner.show();
    const obj = {
      file_name: this.fileName,
      user_id: this.userObj.userId,
      version_no: this.versionNo
    };
    this.clusteringService.resetToAlgo(obj)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.toastrService.success('Data has been reset successfully.');
        this.reset();
      },
        err => {
          this.toastrService.error('Failed to reset data.');
          this.spinner.hide();
        });
  }

  reset() {
    this.dragDropObj.cluster_ids = [];
    this.dragDropObj.row_ids = [];
    this.disableSaveRun.emit(false);
    this.isAdjusted = false;
    this.patchData();
    this.nameChanged = false;
    this.clusteringService.adjustMembershipSubject.next(false);
  }

  showHideAdjustedData(itemValue, isDragged = false, searchValue = ''): boolean {
    let response = true;
    searchValue = searchValue.trim();
    if (isDragged && !(itemValue as string).startsWith(searchValue)) {
      response = false;
    }
    return response;
  }

  ngOnDestroy() {
    this.fetchClustersSubscription && this.fetchClustersSubscription.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
  }

}
