import {  ColumnApi, GridApi, GridOptions } from 'ag-grid-enterprise';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';
import { SharedDataService } from 'src/services/shared-data.service';
import { getForecastSeasonalityWeeksColDefs } from '../ag-grid/grid-options';
@Component({
  selector: 'app-forecast-seasonality-modal',
  templateUrl: './forecast-seasonality-modal.component.html',
  styleUrls: ['./forecast-seasonality-modal.component.scss']
})
export class ForecastSeasonalityModalComponent implements OnInit {


  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  public defaultColDef = {
    filter: false,
    sortable: false,
    minWidth: 50,
    resizable: true,
    suppressMenu: true
  };
  isGridLoaded: boolean = false;
  loadChartData: boolean = false;
  public columnDefs = getForecastSeasonalityWeeksColDefs();

  components = {
    numericCellEditor: getNumericCellEditor()
  }
  SKIP_KEYS = ['SeasonalityId'];
  dataSource: any = {
    chart: {
      caption: "Seasonality Graph",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100"
    },
    categories: [
      {
        category: []
      }
    ],
    dataset: [
      {
        seriesname: "Original",
        color: "#ff0000",
        data: []
      },
      {
        seriesname: "Adjusted",
        color: "#0000ff",
        data: []
      }
    ]
  };
  chartConfig: Object = {
    type: 'msline',
    dataFormat: 'json',
    width: '900',
    height: '500',
  };
  seasonalityData: any = {};
  weekList: any = [];
  gridData: any = [];

  seasonalityLevelCode: any;
  brandList: any = [];
  productGroupList: any = [];
  skuDescriptionList: any = [];
  skuList: any = [];

  constructor(
    public dialogRef: MatDialogRef<ForecastSeasonalityModalComponent>,
    public seasonalityService: SeasonalityService,
    public spinner: NgxSpinnerService,
    public _dataService: SharedDataService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {

    this.getSeasonalityById(this.data.chartData.forecastData.seasonalityId);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  getSeasonalityById(seasonalityId) {
    this.spinner.show();
    this.seasonalityService.GetSeasonalityById(seasonalityId).subscribe(seasonalityData => {
      this.seasonalityData = seasonalityData;
      const seasonalityType = this.seasonalityData.seasonalityType;
      const payload = {
        seasonalityId: seasonalityId,
        startWeekDate: seasonalityType === 'static' ? this.seasonalityData.startWeekDate : null,
        endWeekDate: seasonalityType === 'static' ? this.seasonalityData.endWeekDate : null,
        rollingWeek: seasonalityType === 'rolling' ? this.seasonalityData.rollingWeek : null,
      };
      this.loadSeasonalityFormValues();
      this.getSeasonalityGridData(payload);
    });
  }

  loadSeasonalityFormValues() {
    this.seasonalityLevelCode = this.seasonalityData.seasonalityLevelCode;
    const retailer = _.get(this.seasonalityData, 'seasonalityHierarchyLevels.0.retailer', '');
    this.brandList = _.compact(_.map(_.get(this.seasonalityData, 'seasonalityHierarchyLevels', []), a => a.brand));
    this.productGroupList = _.compact(_.map(_.get(this.seasonalityData, 'seasonalityHierarchyLevels', []), a => a.productGroup));
    this.skuDescriptionList = _.compact(_.map(_.get(this.seasonalityData, 'seasonalityHierarchyLevels', []), a => a.productDescription));
    this.skuList = _.compact(_.map(_.get(this.seasonalityData, 'seasonalityHierarchyLevels', []), a => a.sku));

    this.seasonalityData.retailer = retailer;
    this.seasonalityData.brands = this.brandList;
    this.seasonalityData.productGroups = this.productGroupList;
    this.seasonalityData.skuDescriptions = this.skuDescriptionList;
    this.seasonalityData.sku = this.skuList;
    this.seasonalityData.seasonalityLevelName = this.whiteLabelSeasonalityLevel();
  }


  whiteLabelSeasonalityLevel() {
    let whiteLabelSeasonalityLevel = '';
    if (this.seasonalityLevelCode === 'AC')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(1);
    else if (this.seasonalityLevelCode === 'B')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(2);
    else if (this.seasonalityLevelCode === 'PG')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(3);
    else if (this.seasonalityLevelCode === 'BAC')
      whiteLabelSeasonalityLevel = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(2)}`;
    else if (this.seasonalityLevelCode === 'PGAC')
      whiteLabelSeasonalityLevel = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(3)}`;
    else if (this.seasonalityLevelCode === 'SK')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(5);
    else if (this.seasonalityLevelCode === 'ACSKU')
      whiteLabelSeasonalityLevel = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(4)}`;

    return whiteLabelSeasonalityLevel;
  }

  validateSeasonalityLevelControls(control) {
    switch (control) {
      case 'brand': {
        if (this.seasonalityLevelCode === 'B' || this.seasonalityLevelCode === 'BAC') {
          return true;
        }
        break;
      }
      case 'productGroup': {
        if (this.seasonalityLevelCode === 'PG' || this.seasonalityLevelCode === 'PGAC') {
          return true;
        }
        break;
      }
      case 'skuDescription': {
        if (this.seasonalityLevelCode === 'SK') {
          return true;
        }
        break;
      }
      case 'sku': {
        if (this.seasonalityLevelCode === 'ACSKU') {
          return true;
        }
        break;
      }

      default: {
        return false;
      }
    }
  }

  getSeasonalityGridData(payload) {

    this.seasonalityService.GetSeasonalityGridData(payload).subscribe((gridData: any) => {
      this.weekList = this.getWeekList(gridData);
      this.createSeasonalityWeekColDef(this.weekList);
      this.createGridData(gridData);
      this.spinner.hide();
    });
  }

  createSeasonalityWeekColDef(weekList) {
    this.columnDefs = getForecastSeasonalityWeeksColDefs(weekList);
    if (this.gridApi) {
      this.gridApi.setColumnDefs([]);
      setTimeout(() => {
        this.gridApi.setColumnDefs(this.columnDefs);
        this.gridApi.refreshHeader();
      }, 100);
    }
  }

  createGridData(data) {
    const gridData: any = [];
    data.forEach(seasonality => {
      const originalSeasonality: any = {};
      const adjustedSeasonality: any = {};
      this.weekList.forEach(week => {
        /*
          0: original value
          1: adjusted value
        */
        originalSeasonality.values = 'Seasonality';
        adjustedSeasonality.values = 'Adjusted';
        const splitedValue = (seasonality[week.columnField] ? seasonality[week.columnField] : '').split('_');
        originalSeasonality[week.columnField] = splitedValue[0];
        adjustedSeasonality[week.columnField] = splitedValue[1];
      });

      gridData.push(originalSeasonality);
      gridData.push(adjustedSeasonality);
    });
    this.gridData = gridData;
    this.gridApi && this.gridApi.setRowData(this.gridData);
    this.isGridLoaded = true;
    this.createChartData();
  }

  createChartData() {
    const weekList = this.weekList.map(week => {
      return { label: `${moment(week.weekStartDate).format('MM/DD/YYYY')}-W${week.weekNumber.toString().slice(-2)}` }
    });

    const orgChartData = {
      seriesname: 'Original',
      data: []
    };

    const adjChartData = {
      seriesname: 'Adjusted',
      data: []
    };

    for (let i = 0; i < this.gridData.length; i++) {
      this.weekList.forEach(week => {
        /*
          0: original value
          1: adjusted value
        */
        // const splitedValue = (this.gridData[i][week.field] ? seasonality[week.field] : '').split('_');
        if (i === 0) {
          orgChartData.data.push({ value: this.gridData[i][week.columnField] });
        } else {
          adjChartData.data.push({ value: this.gridData[i][week.columnField] });
        }
      });


      this.dataSource.categories[0].category = weekList;
      this.dataSource.dataset = [orgChartData, adjChartData];
      this.loadChartData = true;
    }
  }



  getWeekList(data) {
    // const weekMetaList = _.keys(_.omit(data), this.SKIP_KEYS);
    const weekMetaList = _.keys(_.omit(_.get(data, '0', {}), this.SKIP_KEYS));;
    let weekList: any = [];
    /*
     Split this string "2021#202101#2021-01-03" on hash(#)
     0 - Report Year
     1 - Week Number
     2 - Week Start Date
   */
    weekMetaList.forEach(week => {
      const weekLables = week.split('#');
      weekList.push({
        columnField: week,
        year: weekLables[0],
        weekNumber: Number(weekLables[1]),
        weekStartDate: weekLables[2]
      });
    });
    weekList = _.orderBy(weekList, ['weekNumber'], ['asc']);

    return weekList;
  }



  onNoClick(): void {
    this.dialogRef.close();
  }


}
