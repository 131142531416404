
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit {

  public registerForm: FormGroup;
  public submitted = false;
  public isEdit = false;
  public columnDefs = [
    {
      headerName: 'Currency',
      field: 'currencyName',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openModal.bind(this),
      },
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      // pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveDialog.bind(this),
      },
      width: 80
    },

  ];
  private gridApi: any;
  public getRowHeight;
  public headerHeight;
  public currencyList = [];
  public gridOptions: any;

  @ViewChild('currencyForm') dialogTemplate: TemplateRef<any>;
  constructor(
    private formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialogRef: MatDialogRef<any>) {
    this.gridOptions = {
      frameworkComponents: {
        'nameRenderer': NameRendererComponent,
        'deleteButtonRenderer': ButtonRendererComponent,
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function (params) {
      return 40;
    };
  }

  ngOnInit() {
    this.populateList();
  }
  public populateList = () => {
    this.configurationService.CurrencyGetAll({ clientId: 1 }).subscribe((res: any) => {
      this.currencyList = res;
    });
  }
  public onSubmit = (data) => {

    this.submitted = true;
    if (data.currencyName) {
      if (this.checkIfAlreadyExists(data)) {
        this.toastr.error('Error', 'Currency with this name already exists');
        return
      }

      const model = { ...data };
      delete model.currencyRates;
      this.spinner.show();
      if (this.isEdit) {
        this.configurationService.CurrencyUpdate(model).subscribe(res => {
          const rateModel = {
            currencyRates: data.currencyRates
          };
          this.configurationService.CurrencyRateAdd(rateModel).toPromise().catch(error => throwError(error));
          this.populateList();
          this.dialogRef.close();
          this.isEdit = false;
          this.submitted = false;
          this.spinner.hide();
        }, error => {

        });
      } else {
        this.configurationService.CurrencyAdd(model).subscribe(res => {
          this.populateList();
          this.dialogRef.close();
          this.submitted = false;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  Cancel_Click() {
    this.submitted = false;
    this.isEdit = false;
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  openRemoveDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.currencyName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeCurrency(row.rowData);
      }
    });
  }
  public removeCurrency = (row) => {
    const model = {
      currencySettingId: row.currencySettingId,
      active: false
    }
    this.configurationService.CurrencyActivate(model).subscribe(res => {
      this.toastr.success('', 'Currency deleted successfully');
      this.populateList();
    });
  }
  openModal(data?) {
    if (data && data.rowData) {
      this.isEdit = true;
      this.getCurrencyRate(data.rowData);
    } else {
      this.isEdit = false;
      this.dialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: {} });
    }
  }
  public getCurrencyRate = (data) => {
    const model = {
      currencySettingId: data.currencySettingId
    }
    this.configurationService.CurrencyRateGetAll(model).subscribe(res => {
      data.currencyRates = res;
      this.dialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: data || {} });
    })
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  checkIfAlreadyExists(data) {
    return this.currencyList.find(row => row.currencyName.toLowerCase() === data.currencyName.toLowerCase());
  }
}
