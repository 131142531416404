import { Subscriber } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { SharedDataService } from 'src/services/shared-data.service';

@Component({
  selector: 'app-product-hierarchy-white-labeling',
  templateUrl: './product-hierarchy-white-labeling.component.html',
  styleUrls: ['./product-hierarchy-white-labeling.component.css']
})
export class ProductHierarchyWhiteLabelingComponent implements OnInit {
  headers = ["Hierarchy Levels", "Hierarchy Names"];
  rows: any = [
    // {
    //   hierarchyLevel: 'H1',
    //   hierarchyName: 'Account/Retailer',
    // },
    // {
    //   hierarchyLevel: 'H2',
    //   hierarchyName: 'Brand',
    // },
    // {
    //   hierarchyLevel: 'H3',
    //   hierarchyName: 'Product Group',
    // },
    // {
    //   hierarchyLevel: 'H4',
    //   hierarchyName: 'SKU',
    // },
    // {
    //   hierarchyLevel: 'H5',
    //   hierarchyName: ' SKU Description'
    // },
  ];



  constructor(private forecastLookupService: ForecastLookupService, private _dataService: SharedDataService) { }

  ngOnInit() {
    this.getHierarchyList();
  }


  getHierarchyList() {
    this.forecastLookupService.GetProductHierarchyList().subscribe(response => {
      this.rows = response.hierarchyMetaDataList;
      this._dataService.setData(response.hierarchyMetaDataList);
    });

  }

  onSubmit() {
    const payload = {
      hierarchyMetaDataList: this.rows
    }
    this.forecastLookupService.UpdateProductHierarchyLIst(payload).subscribe(data => {
      console.log("🚀 ~ file: product-hierarchy.component.ts ~ line 62 ~ ProductHierarchyComponent ~ this.forecastLookupService.UpdateProductHierarchyLIst ~ data", data)
    });
  }

}
