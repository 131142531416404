import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { USER_INFO } from 'src/common/keys';
import { FileUploadService } from 'src/services/file-upload.service';
import { LocalstorageService } from 'src/services/localstorage.service';

@Component({
  selector: 'app-show-progress',
  templateUrl: './show-progress.component.html',
  styleUrls: ['./show-progress.component.scss']
})
export class ShowProgressComponent implements OnInit, OnDestroy {

  step = 2;
  userObj = {} as any;
  _unsubscribeAll = new Subject<void>();
  progress = 0;
  status = 'In Progress';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fileUploadService: FileUploadService,
    private localStorage: LocalstorageService,
    public dialogRef: MatDialogRef<ShowProgressComponent>) { }

  ngOnInit(): void {
    this.userObj = this.localStorage.get(USER_INFO);
    this.fetchFilesProgress();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  fetchFilesProgress() {
    this.step = 2;
    this.fileUploadService.fetchFilesProgress(this.userObj.userId)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
         res.payload.forEach(f => {
           if (f.file_name == this.data.file_name) {
             f.versions.forEach(v => {
               if (v.uu_id == this.data.version_no) {
                 this.progress = v.progress;
                 this.status = v.status;
                 if (this.status == 'File Stats Updated' && this.progress == 100) {
                   this.dialogRef.close(true);
                 }
               }
             });
           }
         });
        setTimeout(() => {
          this.fetchFilesProgress();
        }, 3000);
      },
        err => {
          setTimeout(() => {
            this.fetchFilesProgress();
          }, 3000);
        });
  }

}
