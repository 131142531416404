<div class="journey-modal-header">
  <h2>Add to Existing Journey</h2>
</div>

<form class="example-form" [formGroup]="addToJourneyForm"
  (ngSubmit)="addToJourneyForm.valid && saveToExistingJourney()">
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Add to</mat-label>
    <input type="text" placeholder="Journey Title" matInput [matAutocomplete]="auto" (focus)="onFocus($event)"
      (input)="_filter($event)">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
      (optionSelected)="optionSelected($event.option.value)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.journeyName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Name As</mat-label>
    <input formControlName="nameAs" matInput placeholder="Title" />
  </mat-form-field>
</form>

<button (click)="selectedJourney && saveToExistingJourney()" mat-raised-button color="accent" class="ml-1"
  style="float: right">Save</button>

<button (click)="dialogRef.close()" mat-stroked-button color="accent" style="float: right">Close</button>