<div class='main-content' style="height: calc(100vh - 55px); position: relative;">
  <mat-list-item style="height: 50px;" *ngIf="channelDetails">
    <mat-card class="channel-info-container">
      <div class="row" style="padding-top: 5px;">
        <div class="col-md-9">
          <mat-label>{{channelDetails.channelName}}</mat-label>
        </div>
        <div class="col-md-2">
          <div style="float: right;">
            <mat-label>{{channelDetails.usersInChannel.length}} Members</mat-label>
          </div>
        </div>
        <div class="col-md-1">
          <div style="float: right;">
            <mat-icon class="channel-member-info" [matMenuTriggerFor]="usersDetails"
              [matMenuTriggerData]="channelDetails">chevron_right
            </mat-icon>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-menu #usersDetails="matMenu" class="users-list-menu">
      <ng-template matMenuContent let-userItems="usersInChannel">
        <button mat-menu-item *ngFor="let item of userItems">
          {{item.userFullName}}
        </button>
      </ng-template>
    </mat-menu>
  </mat-list-item>

  <div class="search-results chat-msgs-box" infinite-scroll [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()"
    (scrolledUp)="onUp()" #scrollMe>
    <!-- [scrollTop]="scrollMe.scrollHeight" -->
    <div *ngFor="let monthData of resultData; let j=index">
      <div class="chat-date-label">
        <span>{{monthData.date | date : 'EEEE, MMM d, y' }}</span>
      </div>

      <div class="row" style="margin: 20px;" *ngFor="let msg of monthData.messageModelList; let i = index">
        <div class="col-md-1">
          <img matListAvatar class="rounded-circle img-fluid" style="float: left; max-width: 100%;"
            src="assets/images/algo-user-logo.png" alt="user Image">
        </div>
        <div class="col-md-11 p-0">
          <div class="msg-box">
            <div class="chat-block-border-radius" [ngStyle]="msg.isEdit && { 'border': '3px solid #ff4081' }"
              [ngClass]="{'sender': userObj.userId==msg.createdById, 'receiver':  userObj.userId!=msg.createdById}"
              (mouseover)="enter(i)" (mouseout)="leave(i)" [id]="msg.channelMessageId">
              <div
                [ngClass]="{'left-arrow': userObj.userId==msg.createdById, 'right-arrow':  userObj.userId!=msg.createdById}">
              </div>
              <div class="user-name">
                <mat-label>{{msg.fullName}}</mat-label>

                <div *ngIf="!msg.isEdit && userObj.userId==msg.createdById"
                  [ngStyle]="{ 'display': hoverIndex == i ? 'block': 'none'}" class="actions-buttons">
                  <button mat-button mat-flat-button color="accent" class="mr-1"
                    (click)="editMessage(msg)">Edit</button>
                  <button mat-button mat-flat-button color="accent"
                    (click)="openDeleteDialog(DeleteDialog); $event.stopPropagation()">Delete</button>
                  <ng-template #DeleteDialog>
                    <mat-icon style="float: right;" (click)="matDialogRef.close()">clear</mat-icon>
                    <div mat-dialog-content>
                      <mat-label> <strong>
                          Are you sure you want to delete this message?
                        </strong>
                      </mat-label>
                    </div>
                    <div class="action-panel text-center" style="margin-left:15px; margin-right:15px;">
                      <button mat-raised-button class="btn m-left btn-light" color="warn"
                        (click)="matDialogRef.close()">Cancel</button>
                      <button type="submit" class="btn btn-primary d-block"
                        (click)="deleteMessage(msg, i, j)">Delete</button>
                    </div>
                  </ng-template>
                </div>
                <div *ngIf="msg.isEdit" class="actions-buttons">
                  <button mat-button mat-flat-button color="accent" class="mr-1" (click)="cancel(msg)">Cancel</button>
                  <button mat-button mat-flat-button color="accent" (click)="updateMessage(msg)">Save</button>
                </div>
              </div>

              <span style="word-wrap: break-word" *ngIf="!msg.isEdit">{{msg.message}}</span>

              <textarea matInput *ngIf="msg.isEdit" [(ngModel)]="msg.message" cdkTextareaAutosize cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="7" autocomplete="off" (keydown.esc)="cancel(msg)"
                (keydown.enter)="onKeydown($event)" (keyup.enter)="msg.message!='' && updateMessage(msg)">
              </textarea>
              <div class="document-section">
                <ng-container *ngFor="let attacment of msg.messageAttachmentWithDocumentViewModels;">
                  <div class="document-info">
                    <a [href]="getDocumentPath(attacment)">{{attacment.displayName}}</a>
                    <span class="action-section">
                      <button mat-icon-button (click)="viewFile(attacment,imageViewTemplate )">
                        <mat-icon>visibility</mat-icon>
                      </button>
                      <button mat-icon-button (click)="downloadFile(attacment)">
                        <mat-icon>download</mat-icon>
                      </button>
                    </span>
                    <img class="document-thumnail" (click)="viewFile(attacment,imageViewTemplate )"
                      [src]="getDocumentPath(attacment)">
                  </div>

                </ng-container>
              </div>

            </div>
            <div class="chat-time" *ngIf="!msg.isEdit">{{ msg.createdDateFormatted | date:'shortTime'}}</div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="my-1" style="position: absolute; right: 0; left: 0; bottom: 0;">
    <p class="mx-4 mb-0">
      <mat-form-field appearance="outline" class="chat-textarea">
        <textarea matInput name="message" cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="7"
          autocomplete="off" [(ngModel)]="messageModel.message" (keydown.enter)="onKeydown($event)"
          (keyup.enter)="(messageModel.message!='' || files.length) && postMessage()"
          (focus)="resetReadMessageCount()"></textarea>
        <button mat-icon-button matSuffix (click)="messageAttachment()">
          <mat-icon class="cursor" color="accent">attach_file</mat-icon>
        </button>
        <input multiple class="hidden" type="file" id="message-attachements" name="message-attachements"
          (change)="validateUploadedCSV($event)">
        <button mat-icon-button matSuffix (click)="(messageModel.message!=''  || files.length) && postMessage()">
          <mat-icon class="cursor" color="accent">send
          </mat-icon>
        </button>
      </mat-form-field>
      <mat-chip-list style="min-height: 40px;">
        <mat-chip *ngFor="let filename of filesNameList; let index=i" (removed)="removeFile(index)">
          {{filename}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </p>
    <ng-template #imageViewTemplate let-data>
      <div mat-dialog-content>
        <img class="document-view" [src]="data.documentPath">
      </div>
    </ng-template>
  </div>
</div>