import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PersistentContextService {

  // Subjects
  public myJourneyGridActionSubject: Subject<any> = new Subject();
  public shareJourneyGridActionSubject: Subject<any> = new Subject();
  public journeyLogGridActionSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setMyJourneyGridActionSubject = (value: any) => { this.myJourneyGridActionSubject.next(value); };
  setShareJourneyGridActionSubject = (value: any) => { this.shareJourneyGridActionSubject.next(value); };
  setJourneyLogGridActionSubject = (value: any) => { this.journeyLogGridActionSubject.next(value); };

  // Observables
  getMyJourneyGridActionSubject = () => this.myJourneyGridActionSubject.asObservable();
  getShareJourneyGridActionSubject = () => this.shareJourneyGridActionSubject.asObservable();
  getJourneyLogGridActionSubject = () => this.journeyLogGridActionSubject.asObservable();

  constructor(private http: HttpClient) {
  }

}
