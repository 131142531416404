import { Component, ElementRef, Input, AfterViewInit, TemplateRef, ViewChild, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_ID, USER_INFO, USER_TOKEN_INFO } from 'src/common/keys';
import { MessageModel } from 'src/models/channelMessaging.model';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ChannelMessagingService } from 'src/services/Messaging-Services/channel-messaging.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize, takeUntil } from 'rxjs/operators';
import { getDocumentPath } from 'src/modules/item-manager/features/util/util';
import { convertBase64ToFile } from 'src/common/util/util';
import { ChatNotifyService } from 'src/services/Messaging-Services/chat-notify.service';
import { ChatHandlerService } from 'src/services/Messaging-Services/chat-handler.service';
import { Subject } from 'rxjs';
const nisPackage = require("package.json")

@Component({
  selector: 'app-chat-details',
  templateUrl: './chat-details.component.html',
  styleUrls: ['./chat-details.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ChatDetailsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollMe') scrollMe: ElementRef;
  panelOpenState = false;
  public editText = false;
  public messageModel = new MessageModel();
  public messageModelList: MessageModel[] = [];
  userText: any;
  // @Input() data: any;
  menuData: any;
  channelDetails: any;
  public group: FormGroup;
  channelMessageId: any = '';
  hoverIndex: any = null;
  page: number = 1;
  pageSize: number = 20;
  userObj: any;
  throttle = 100;
  scrollDistance = .5;
  scrollUpDistance = .5;
  direction = '';
  nisVersion = nisPackage.dependencies["ngx-infinite-scroll"];
  public resultData = [];
  @Input() data: any;

  filesNameList: string[] = [];
  files: any = [];
  user_Id: number;

  _unsubscribeAll = new Subject<void>(); // for killing subscriptions on component destroy

  constructor(
    private formBuilder: FormBuilder,
    private channelMessagingService: ChannelMessagingService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public router: Router,
    public storage: LocalstorageService,
    public matDialogRef: MatDialogRef<any>,
    private dialog: MatDialog,
    private chatNotifyService: ChatNotifyService,
    private ChatHandlerService: ChatHandlerService,
    private changeDetectionRef: ChangeDetectorRef

  ) {
    this.userObj = this.storage.get(USER_INFO);
  }

  localeTimeString(date): string {
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);

    const offset = utcDate.getTimezoneOffset() / 60;
    const hours = utcDate.getHours();

    localDate.setHours(hours - offset);

    return localDate.toString();
  }

  ngAfterViewInit(): void {

    this.initializeForm();
    this.user_Id = this.storage.get(USER_ID);
    this.ChatHandlerService.connection.on('Send', (msg: any) => {
      if (msg.delete) {
        for (let i = 0; i < this.resultData.length; i++) {
          for (let j = 0; j < this.resultData[i].messageModelList.length; j++) {
            if (this.resultData[i].messageModelList[j].channelMessageId == msg.channelMessageId) {
              this.resultData[i].messageModelList.splice(j, 1);
              break;
            }
          }
        }
      } else if (msg.isUpdated) {
        for (let i = 0; i < this.resultData.length; i++) {
          for (let j = 0; j < this.resultData[i].messageModelList.length; j++) {
            if (this.resultData[i].messageModelList[j].channelMessageId == msg.channelMessageId) {
              msg.createdDateFormatted = msg.createdDate;
              this.resultData[i].messageModelList[j] = msg;
              break;
            }
          }
        }
      } else {
        this.addMsgToModel(msg);
      }
    });
    setTimeout(() => {
      if (this.data) {
        this.initializeDatasourceSettings();
      }
    }, 500);
  }

  initializeDatasourceSettings() {
    const data = JSON.parse(this.data);
    
    this.ChatHandlerService.connection.invoke('JoinGroup', data.channelName).then((res: any) => {
    }, err => {
    });

    this.chatNotifyService.connection.on("SendMessageToChannel", (msg) => {
      this.addMsgToModel(msg);
    });
    
    this.getChannelByChannelId(data);
    this.getAllChannelMessageByUsers(1, data);
  }

  checkInitialChatWorkflow(data) {
    switch (data.workflow) {
      case 'screenshot': {
        const file = convertBase64ToFile(data.screenshotURL, data.screenshotName);
        this.filesNameList.push(data.screenshotName);
        this.files.push(file)
        break;
      }
      default: {
        break;
      }
    }
  }

  initializeForm() {
    this.group = this.formBuilder.group({
      message: [''],
    });
  }

  expand(obj) {
    if (!obj.savesize) obj.savesize = obj.size;
    obj.size = Math.max(obj.savesize, obj.value.length);
  }

  getChannelByChannelId(data) {
    this.spinner.show();
    this.channelMessagingService
      .GetChannelByChannelId(data.channelId)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (channelDetails: any) => {
          this.spinner.hide();
          this.channelDetails = channelDetails;

        },
        (error) => {
          this.spinner.hide();
          !(error.name && error.name == 'ObjectUnsubscribedError') && this.toastr.error(
            'Error',
            'System failed to get channel information.'
          );
        }
      );
  }

  getAllChannelMessageByUsers(currentPage: number, jsonInitialData = null) {
    const data = JSON.parse(this.data);
    this.spinner.show();
    this.editText = true;
    this.channelMessagingService.GetAllChannelMessage(data.channelId, currentPage, this.pageSize)
    .pipe(
      takeUntil(this._unsubscribeAll)
    )
    .subscribe(
      (channelMessaging: any) => {
        this.spinner.hide();
        this.messageModelList = channelMessaging;
        this.messageModelList.forEach((msg: any) => {
          msg.createdDateFormatted = this.localeTimeString(msg.createdDate);
        });
        let data = new Set(this.messageModelList.map((item) => (item.createdDate as any).split('T')[0]));
        if (this.page === 1) {
          this.resultData = [];
          data.forEach((date) => {
            this.resultData.push({
              date: date,
              messageModelList: this.messageModelList.filter((i) => (i.createdDate as any).split('T')[0] === date),
            });
          });
        } else {
          data.forEach((date) => {

            const res = this.resultData.find(item => item.date == date)
            if (res) {
              res.messageModelList.unshift(...this.messageModelList.filter((i) => (i.createdDate as any).split('T')[0] === date))
            }
            else {
              this.resultData.unshift({
                date: date,
                messageModelList: this.messageModelList.filter((i) => (i.createdDate as any).split('T')[0] === date),
              });
            }

          });

        }
        this.resultData = _.orderBy(this.resultData, ['date'], ['asc'])
        currentPage == 1 && this.scrollBottom();
        this.spinner.hide();
        if (jsonInitialData && jsonInitialData.sendMessage) {
          setTimeout(() => {
            this.checkInitialChatWorkflow(jsonInitialData);
            setTimeout(() => {  
              jsonInitialData.sendMessage && this.postMessage();
              jsonInitialData.sendMessage = false;
            }, 200);
          }, 10);
        }
      },
      (error) => {
        this.spinner.hide();
        !(error.name && error.name == 'ObjectUnsubscribedError') && this.toastr.error(
          'Error',
          'System failed to get channel messages.'
        );
      }
    );
  }

  scrollBottom() {
    setTimeout(() => {
      try {
        this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
      } catch (err) { }
    }, 100);
  }

  onKeydown(event) {
    event.preventDefault();
  }

  getDocumentPath(document) {
    return getDocumentPath(document);
  }

  downloadFile(file) {
    const link = document.createElement("a");
    link.href = getDocumentPath(file);
    link.target = '_blank';
    link.download = `${file.documentName}.${file.documentExtension}`;
    link.click();
    link.remove();
  }

  viewFile(document, templateRef: TemplateRef<any>) {

    // tslint:disable-next-line: no-use-before-declare
    const dialogAddRef = this.dialog.open(templateRef, {
      maxHeight: '70vh',
      data: {
        documentPath: getDocumentPath(document)
      }
    });

    dialogAddRef.afterClosed().subscribe(result => {
    });
  }

  messageAttachment() {
    (document.getElementById('message-attachements') as any).value = '';
    document.getElementById('message-attachements').click();
  }

  validateUploadedCSV(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      this.files = fileInput.target.files;
      this.createFileNameList();
    }
  }

  createFileNameList() {
    this.filesNameList = [];
    const filesArray = Array.from(this.files);
    filesArray.forEach((file: File) => {
      this.filesNameList.push(file.name);
    });
  }

  removeFile(index): void {
    this.filesNameList.splice(index, 1);
    this.files.splice(index, 1);
  }

  createFormDataObject(messageModel: any) {
    const formData = new FormData();
    const data = JSON.parse(this.data);
    formData.append('channelId', data.channelId);
    formData.append('channelMessageId', messageModel.channelMessageId);
    formData.append('message', messageModel.message);
    formData.append('parentMessageId', messageModel.parentMessageId);
    formData.append('delete', messageModel.delete);
    formData.append('active', messageModel.active);
    formData.append('deletedDate', messageModel.deletedDate);
    formData.append('deletedById', messageModel.deletedById);
    formData.append('isRead', messageModel.isRead);

    for (const file of this.files) {
      formData.append('files', file);
    }
    return formData;
  }

  addMsgToModel(msg) {
    const dateIndex = _.findIndex(this.resultData, a => a.date === msg.createdDate.split('T')[0]);
    msg.createdDateFormatted = msg.createdDate;
    if (dateIndex >= 0) {
      this.resultData[dateIndex].messageModelList.push(msg);
    } else {
      this.resultData.push({
        date: msg.createdDate.split('T')[0],
        messageModelList: [msg],
      });
    }
    this.changeDetectionRef.detectChanges();
    setTimeout(() => {
      const newMsgDiv = document.getElementById(msg.channelMessageId);
      if (newMsgDiv) {
        this.scrollBottom();
        setTimeout(() => {
          newMsgDiv.classList.add('new-message-class');
          setTimeout(() => {
            newMsgDiv.classList.remove('new-message-class');
          }, 1300);
        }, 105);
      }
    }, 200);
  }

  postMessage() {

    const data = JSON.parse(this.data);
    this.messageModel.channelId = data.channelId;
    this.messageModel.createdById = this.user_Id;
    const messagePayload = JSON.parse(JSON.stringify(this.messageModel));

    this.messageModel = new MessageModel();
    if (this.files.length == 0) {
      this.ChatHandlerService.connection.invoke('SendMessageToGroup', data.channelName, messagePayload).then((res: any) => {
      }, err => {
      })
    } else {
      this.messageModel.deletedDate = (new Date().toISOString() as any);
      const messagePayload = this.createFormDataObject(this.messageModel);

      this.messageModel = new MessageModel();
      this.files = [];
      this.filesNameList = [];

      this.channelMessagingService.SendMessage(messagePayload).pipe(finalize(() => this.spinner.hide()))
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (msg: any) => {
          if (msg.delete == true) {
            this.toastr.info(
              'information',
              `This channel "${data.channelName}" has been deleted by an administrator.`
            );
            // this.spinner.hide();
          }
          this.messageModel = new MessageModel();
          this.files = [];
          this.filesNameList = [];
        },
        (error) => {
          !(error.name && error.name == 'ObjectUnsubscribedError') && this.toastr.error(
            'Error',
            'Chat service is down, please try again.'
          );
        }
      );

    }
  }

  enter(i) {
    this.hoverIndex = i;
  }

  leave(msg) {
    this.hoverIndex = null;
  }

  editMessage(msg) {
    msg.isEdit = true;
    this.channelMessageId = msg.channelMessageId;
    msg.preMessage = msg.message;
  }

  updateMessage(messageModel) {
    const data = JSON.parse(this.data);

    this.ChatHandlerService.connection.invoke('Update', data.channelName, messageModel).then((res: any) => {
    }, err => {
      console.log(err);
    })
    messageModel.isEdit = false;
  }

  deleteMessage(messageModel, msgIndex, monthIndex) {

    const data = JSON.parse(this.data);

    this.dialog.closeAll();

    this.ChatHandlerService.connection.invoke('Delete', data.channelName, messageModel).then((res: any) => {
      // this.resultData[monthIndex]['messageModelList'].splice(msgIndex, 1);
    }, err => {
      console.log(err);
    })
  }

  cancel(msg) {
    msg.isEdit = false;
    msg.message = msg.preMessage;
    msg.preMessage = null;
  }
  ////////////////Deleet Dialogue///
  public openDeleteDialog = (template: TemplateRef<any>) => {
    this.matDialogRef = this.dialog.open(template, { width: '500px' });
  };

  //////////////Pagination on scroll
  onScrollDown() {
  }

  onUp() {
    this.page = this.page + 1;
    this.getAllChannelMessageByUsers(this.page);
  }

  //////////////Set message read////
  resetReadMessageCount() {
    const data = JSON.parse(this.data);
    this.channelMessagingService.GetUnreadChannelMessagesCount(data.channelId)
    .pipe(
      takeUntil(this._unsubscribeAll)
    )
    .subscribe(
      (count: number) => {
        if (count > 0) {

          this.markChannelMessagesRead(data.channelId);
        }
      },
      (error) => {
        this.spinner.hide();
        !(error.name && error.name == 'ObjectUnsubscribedError') && this.toastr.error(
          'Error',
          'Chat service is service down, please try again.'
        );
      }
    );
  }


  markChannelMessagesRead(channelId: number) {
    this.channelMessagingService.MarkChannelMessagesRead(channelId)
    .pipe(
      takeUntil(this._unsubscribeAll)
    )
    .subscribe(
      (count: number) => {
      },
      (error) => {
        this.spinner.hide();
        !(error.name && error.name == 'ObjectUnsubscribedError') && this.toastr.error('Error', 'Chat service is service down, please try again.');
      }
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
    this.spinner.hide();
  }

}
