import { dateRenderer, statusRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import { tooltipcellRenderer } from "src/common/util/util";
import * as _ from 'lodash';

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  filterParams: {
    excelMode: 'windows',
  },
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getPromoColDefs = (filterLookupModel: any = {}, isDepromo: boolean = false, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: 'Promo Key',
      field: 'promoKey',
      width: 125,
      cellRenderer: tooltipcellRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Promo Name',
      field: 'promoName',
      width: 135,
      cellRenderer: 'promoItemsEditRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Promo Event',
      field: 'promoCustomEvent',
      width: 130,
      // cellRenderer: eventNameRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Planning Month',
      field: 'planningMonth',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
      width: 150,
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: false,
      sortable: false,
      width: 85,
      cellRenderer: statusRenderer,
    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'accounts',
      width: 105,
      cellRenderer: tooltipcellRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    },
    {
      headerName: 'Item Count',
      field: 'itemCount',
      filter: false,
      width: 115,
    },
    {
      headerName: 'Item(s)',
      field: 'itemDescriptions',
      width: 110,
      cellRenderer: tooltipcellRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      type: 'date',
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      },
      width: 110,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      width: 110,
      type: 'date',
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      },
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Uplift %',
      field: 'upliftValue',
      filter: false,
      sortable: false,
      width: 110,
    },
    {
      headerName: 'Decay Trend',
      field: 'trendName',
      cellRenderer: tooltipcellRenderer,
      width: 240,
      filter: 'agTextColumnFilter',
      resizable: true,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    },
    {
      headerName: 'Region(s)',
      field: 'regions',
      width: 110,
      cellRenderer: tooltipcellRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    },
    {
      headerName: 'Country(s)',
      field: 'countries',
      width: 110,
      cellRenderer: tooltipcellRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      },
    },
    ...isDepromo ? [{
      headerName: 'Pre-Promo',
      field: 'weeksBeforePromo',
      width: 140,
      filter: false,
      sortable: false,
      editable: true,
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Post-Promo',
      field: 'weeksAfterPromo',
      width: 140,
      filter: false,
      sortable: false,
      editable: true,
      cellEditor: 'numericCellEditor'
    }] : []
  ];

  return colDefs;
}

export const getPromoItemColDefs = (filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'retailer',
      width: 105,
    },
    {
      headerName: sharedDataService.getDataById(2),
      field: 'brand',
      width: 100,
    },
    {
      headerName: sharedDataService.getDataById(3),
      field: 'productGroup',
      width: 140,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: sharedDataService.getDataById(5),
      field: 'productDescription',
      width: 150,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: sharedDataService.getDataById(4),
      field: 'sku',
      width: 105,
    },
    {
      headerName: 'Discount %',
      field: 'discount',
      width: 110,
    },
    {
      headerName: 'Retail Price',
      field: 'retailPrice',
      width: 115,
    },
    {
      headerName: 'RP Currency',
      field: 'currency',
      width: 125,
    },
    {
      headerName: 'Rebate Amount',
      field: 'rebateAmount',
      width: 140,
    },
    {
      headerName: 'Discount Price',
      field: 'discountPrice',
      width: 140,
    }
  ];

  return colDefs;
}

export const getItemDialogColDefs = (filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'retailer',
    },
    {
      headerName: sharedDataService.getDataById(4),
      field: 'sku',
    },
    {
      headerName: sharedDataService.getDataById(5),
      field: 'productDescription',
      width: 410,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
  ];

  return colDefs;
}

export const getPromoHistoryColDefs = (decayTrendWeekList: any = [], filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: 'Promo Key',
      field: 'PromoKey',
      width: 130,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: 'Promo Name',
      field: 'PromoName',
      width: 140,
      cellRenderer: 'promoItemsEditRenderer',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: 'Promo Event',
      field: 'PromoCustomEvent',
      width: 130,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'Accounts',
      width: 110,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: sharedDataService.getDataById(4),
      field: 'Items',
      width: 200,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: 'Start Date',
      field: 'StartDate',
      cellRenderer: dateRenderer,
      width: 110
    },
    {
      headerName: 'End Date',
      field: 'EndDate',
      cellRenderer: dateRenderer,
      width: 110
    },
    {
      headerName: 'Discount %',
      field: 'Discount',
      cellRenderer: numberRenderer,
      width: 120
    },
    {
      headerName: 'Uplift %',
      field: 'UpliftValue',
      cellRenderer: numberRenderer,
      width: 95
    }
  ];

  decayTrendWeekList = decayTrendWeekList.sort((a, b) => {
    let x = parseInt(a.slice(2));
    let y = parseInt(b.slice(2))
    return x - y
  });
  const decayColDef: any = {
    headerName: 'Decay',
    headerClass: 'decay-column-group',
    children: []
  };
  decayTrendWeekList.forEach(week => {
    decayColDef.children.push({
      headerName: `${week.toString()}`,
      field: `${week}`,
      cellRenderer: numberRenderer,
      width: 80
    });
  });
  colDefs.push(decayColDef);
  return colDefs;
}

const numberRenderer = (params) => {
  return params.value ? Number(params.value).toFixed(2) : '';
}

export const eventNameRenderer = (params) => {
  return `${params.value === 'Custom' ? params.data.promoCustomEvent : params.value}`;
}
