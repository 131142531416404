import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChannelMessagingService } from 'src/services/Messaging-Services/channel-messaging.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as _ from 'lodash';
import { LocalstorageService } from 'src/services/localstorage.service';
import {
  ChannelMessaging,
  UsersInChannel,
} from 'src/models/channelMessaging.model';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChannelMessagingComponent } from 'src/modules/messaging/channel-messaging/channel-messaging.component';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { ChatDetailsComponent } from 'src/modules/messaging/chat-details/chat-details.component';
import { ChatNotifyService } from 'src/services/Messaging-Services/chat-notify.service';
import { ChatHandlerService } from 'src/services/Messaging-Services/chat-handler.service';
import { ChannelHandlerService } from 'src/services/Messaging-Services/channel-handler.service';


@Component({
  selector: 'app-channels-menu',
  templateUrl: './channels-menu.component.html',
  styleUrls: ['./channels-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChannelsMenuComponent implements OnInit {
  public channelMessagingModel = new ChannelMessaging();
  public userLeaveChannel = new UsersInChannel();
  public userId = this.storage.get(USER_ID);
  selectedId = null;
  public loadedComponent;
  public unreadMessagesCount: number;

  @Output() selectedChat = new EventEmitter();
  @Input() selectedChatData = null as any;

  constructor(
    private channelMessagingService: ChannelMessagingService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public router: Router,
    private dialog: MatDialog,
    public matDialogRef: MatDialogRef<any>,
    public storage: LocalstorageService,
    private injector: Injector,
    public configurationService: ConfigurationService,
    private chatNotifyService: ChatNotifyService,
    private chatHandlerService: ChatHandlerService,
    private channelHandlerService: ChannelHandlerService,
    private changeDetectionRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.channelHandlerConnectionListener();
      this.getChannelList();
    }, 500);

  }

  channelHandlerConnectionListener() {
    this.channelHandlerService.connection.on('send', (objectReceived: any) => {
      if (typeof objectReceived == 'object') {
        // find and remove already existing record
        const alreadyExistsIndex = _.findIndex(this.channelMessagingModel.UsersInChannel, function (o: any) { return o.channelId == objectReceived.channelId; });
        if (alreadyExistsIndex > -1) {
          this.channelMessagingModel.UsersInChannel.splice(alreadyExistsIndex, 1);
          this.hideChannelDisplay(objectReceived);
        }

        // insert & sort the records
        !objectReceived.delete && this.channelMessagingModel.UsersInChannel.push(objectReceived);

        this.channelMessagingModel.UsersInChannel = _.sortBy(this.channelMessagingModel.UsersInChannel, (o) =>
          o.channelName.toLowerCase()
        );
        this.changeDetectionRef.detectChanges();
      }
    });
  }

  getChannelList(disabledFlag?) {
    if (disabledFlag === true) {
      this.spinner.hide();
    } else {
      this.spinner.show();
    }
    this.channelMessagingService.GetChannelList().subscribe(
      (channelList: any) => {
        this.spinner.hide();

        this.channelMessagingModel.UsersInChannel = _.sortBy(channelList, (o) =>
          o.channelName.toLowerCase()
        );
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error('Error', 'Messaging services are down, please contact administrator.');
      }
    );
  }

  markChannelMessagesRead(item, channelId: number) {
    this.channelMessagingService.MarkChannelMessagesRead(channelId).subscribe(
      (count: number) => {
        item.messageCount = 0;
        this.spinner.hide();

      },
      (error) => {
        this.spinner.hide();
        this.toastr.error('Error', 'Messaging services are down, please contact administrator.');
      }
    );
  }

  deleteChannel(item: any, index) {

    item.delete = true;
    this.spinner.show();
    this.matDialogRef.close();
    this.channelHandlerService.connection.invoke('Delete', 'join-channel', item).then((res: any) => {
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  leaveChannel(item: any, index) {
    this.spinner.show();
    this.userLeaveChannel = item.usersInChannel.find(
      (id) => id.userId === this.userId
    );
    this.userLeaveChannel.delete = true;

    this.channelHandlerService.connection.invoke('LeaveChannel', 'join-channel', this.userLeaveChannel).then((res: any) => {
      this.hideChannelDisplay(item);
      this.channelMessagingModel.UsersInChannel.splice(index, 1);
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  openChannelDialog(isEdit = false, data = {}): void {
    const dialogRef = this.dialog.open(ChannelMessagingComponent, {
      width: '700px',
      minHeight: '710px',
      disableClose: true,
      data: {
        isEdit: isEdit,
        formData: {
          ...data,
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
  public openDeleteDialog = (template: TemplateRef<any>) => {
    this.matDialogRef = this.dialog.open(template, { width: '500px' });
  };
  activeList(item) {
    this.selectedId = item.channelId;
  }
  showChatDetailsElement(item) {
    if (item.messageCount > 0) {
      this.markChannelMessagesRead(item, item.channelId);
    }

    this.selectedChat.emit(item);
  }

  hideChannelDisplay(channel: any) {
    if (this.selectedChatData) {
      const selectedChatData = JSON.parse(this.selectedChatData);
      if (selectedChatData && selectedChatData.channelId == channel.channelId) {
        this.selectedChat.emit(null);
      }
    }
  }
}
