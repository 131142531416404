import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterProductService } from 'src/services/Item-Management-Services';

@Component({
  selector: 'master-edit-cell-renderer',
  template: `<a class="product-title-link" (click)="btnEditClickHandler()">{{params.value}}</a>`,
})
export class MasterItemEditRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private masterService: MasterProductService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnEditClickHandler() {
    this.masterService.setMasterGridActionCellSubject({ action: 'edit', actionData: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
