<!-- File Uploader ~ START -->
<mat-accordion>
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                File Uploading
            </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- PREVIOUS FILES + UPLOAD NEW SECTION -->
        <div class="row">
            <div class="col-md-5 pl-4 py-4">
                <h4>Import New Data</h4>
                <div class="d-flex justify-content-between">
                    <p class="text-secondary m-0">Download Data Template <a (click)="downloadTemplate()"
                            class="clr-pink-hyperlink">here</a></p>
                    <small class="text-secondary">Max file size 1 GB</small>
                </div>
                <div class="file-upload-div">
                    <div class="w-100" id="{{'fileUploaderDiv'+this.uniqueId}}">
                        <h5>Drag Excel file (.csv) into this field</h5>
                        <p class="text-secondary font-italic mb-5">Attention: The file must have variable names in
                            the
                            first row and values in
                            the following rows.</p>
                        <input class="hidden" type="file" id="{{'cluster-upload-data-btn'+this.uniqueId}}"
                            name="cluster-upload-data-btn" accept=".csv," (change)="onFileSelect($event)">
                    </div>
                </div>
                <div class="row d-flex mx-1" style="margin-top: 2rem;">
                    <button mat-raised-button color="accent" (click)="clusterUploadFileBtn()">UPLOAD DATA
                    </button>
                    <!-- <mat-checkbox color="warn">Save import for future runs</mat-checkbox> -->
                </div>
            </div>
            <div class="col-md-7 px-4 py-4">
                <h4 class="d-flex align-items-center">Previously Uploaded Files
                    <!-- <mat-icon matTooltip="Reload data"
                        class="ml-2 cursor-pointer" (click)="fetchPreviouslyUploadedFiles(paginator.pageIndex + 1)">
                        refresh</mat-icon> -->
                    <button style="margin-left: auto;" [disabled]="!dataSource.data.length" mat-raised-button
                        color="accent" matTooltip="Removes all the uploaded saved files."
                        (click)="deleteAllFiles()">DELETE ALL
                    </button>
                </h4>
                <div class="mat-elevation-z8 previousFilesTable">
                    <table mat-table [dataSource]="dataSource.data" multiTemplateDataRows class="w-100">
                        <!-- expand Column -->
                        <ng-container matColumnDef="expand">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                            <td mat-cell *matCellDef="let row" style="width: 50px;">
                                <mat-icon *ngIf="row.file_name != expandedElement && row.versions.length">
                                    keyboard_arrow_right</mat-icon>
                                <mat-icon *ngIf="row.file_name == expandedElement && row.versions.length">
                                    keyboard_arrow_down</mat-icon>
                            </td>
                        </ng-container>

                        <!-- name Column -->
                        <ng-container matColumnDef="fileName">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> File Name </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.file_name}}</td>
                        </ng-container>

                        <!-- status Column -->
                        <ng-container matColumnDef="status">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> File Status </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.status}} <small>({{element.progress}}%)</small> </td>
                        </ng-container>

                        <!-- createdAt Column -->
                        <ng-container matColumnDef="createdAt">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> Created At </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.created_time |
                                date:"MM/dd/yy @h:mm a"}} </td>
                        </ng-container>

                        <!-- action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width: 55px;text-align: right;"> </th>
                            <td mat-cell *matCellDef="let element" style="width: 55px;text-align: right;">
                                <mat-icon class="cursor-pointer" matTooltip="Delete file"
                                    (click)="$event.stopPropagation();deleteFileAndAllVersions(element.file_name)">
                                    delete</mat-icon>
                            </td>
                        </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                <div class="example-element-detail" *ngIf="element.versions.length"
                                    [@detailExpand]="element.file_name == expandedElement ? 'expanded' : 'collapsed'">
                                    <div class="inner-table mat-elevation-z8 w-100" *ngIf="expandedElement">
                                        <table mat-table [dataSource]="element.versions" class="w-100">
                                            <!-- Checkbox Column -->
                                            <ng-container matColumnDef="selection">
                                                <th mat-header-cell *matHeaderCellDef>
                                                </th>
                                                <td mat-cell *matCellDef="let row" style="width: 50px;">
                                                    <mat-checkbox
                                                        *ngIf="row.status=='File Uploaded' || row.status=='File Stats Updated'"
                                                        (click)="$event.stopPropagation()"
                                                        (change)="$event ? selection.toggle(row.name+'_'+row.version_name) : null"
                                                        [checked]="selection.isSelected(row.name+'_'+row.version_name)">
                                                    </mat-checkbox>
                                                </td>
                                            </ng-container>

                                            <!-- Name Column -->
                                            <ng-container matColumnDef="filename">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    Version Name
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.version_name}}
                                                </td>
                                            </ng-container>

                                            <!-- Status Column -->
                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    Status
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.status}} <small>({{row.progress_status}}%)</small>
                                                </td>
                                            </ng-container>

                                            <!-- Created At -->
                                            <ng-container matColumnDef="createdAt">
                                                <th mat-header-cell *matHeaderCellDef>Created At</th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.created_time | date:"MM/dd/yy @h:mm a"}}
                                                </td>
                                            </ng-container>

                                            <!-- Action Column -->
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef
                                                    style="width: 55px;text-align: right;"> </th>
                                                <td mat-cell *matCellDef="let row"
                                                    style="width: 55px;text-align: right;">
                                                    <mat-icon *ngIf="row.uu_id != 1" class="cursor-pointer"
                                                        color="warn" matTooltip="Delete version"
                                                        (click)="$event.stopPropagation(); deleteFile(row)">
                                                        delete</mat-icon>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="innerColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: innerColumns;"></tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                            [class.example-element-row]="element.versions.length"
                            [class.example-expanded-row]="expandedElement === element.file_name" (click)="toggleRow(element)">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <h6 *ngIf="!dataSource.data.length" style="opacity: 0.4;"
                        class="text-center text-secondary my-3 p-4">
                        No data to show.
                    </h6>
                </div>
                <div class="row d-flex justify-content-start align-items-center mt-4 mx-1">
                    <button [disabled]="checkButtonDisabledCondition()" mat-raised-button color="accent"
                        (click)="openSelectedFileData()">OPEN SELECTED
                    </button>
                    <!-- <mat-paginator [pageSizeOptions]="[20]" (page)="pageChanged($event)" [length]="dataSource.total"
                        showFirstLastButtons aria-label="Select page">
                    </mat-paginator> -->
                </div>
            </div>
        </div>

        <div *ngIf="step!=0" class="d-flex justify-content-end m-2 align-items-center">
            <div class="d-flex align-items-center">
                <label class="mb-0 mr-5" style="font-weight: 500;">Use Toggle to Modify Variable Details</label>
                <h6 class="mb-0 mx-2">Data View</h6>
                <mat-slide-toggle color="warn" [(ngModel)]="isVariableView"></mat-slide-toggle>
                <h6 class="mb-0 mx-2">Variable View</h6>
            </div>
        </div>

        <!-- Data View -->
        <div class="all-show-grid mt-3" [ngStyle]="(isVariableView || step == 0) ? {width: '0', 'height': '0'} : {}">
            <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders suppressRowClickSelection
                toolPanelSuppressGroups toolPanelSuppressValues  [gridOptions]="gridOptions"
                [columnDefs]="gridColumnDefs" [defaultColDef]="defaultColDef" [pagination]="false"
                (gridReady)="onGridReady($event)" #fileUploadingGrid>
            </ag-grid-angular>
        </div>

        <!-- Variable View -->
        <div class="variableView ml-2" *ngIf="isVariableView && gridColumnDefs.length && step != 0">
            <div class="d-flex headerData">
                <h6 class="srNo mb-0"><strong>#</strong></h6>
                <h6 class="fieldName ml-3 mb-0"><strong>Field Name</strong></h6>
                <h6 class="fieldName ml-3 mb-0">
                    <strong class="mr-3">Field Type</strong>
                </h6>
                <h6 class="fieldName mb-0 text-center" style="width: 120px;"><strong>Unique ID</strong></h6>
                <h6 class="fieldName mb-0 text-center" style="width: 120px;"><strong>Group By</strong></h6>
            </div>
            <div style="max-height: 50vh; overflow-y: auto;">
                <div class="d-flex align-items-center rowData">
                    <div class="d-flex align-items-center mb-2" style="margin-left: 267px;">
                        <h6 class="mb-0 mr-2">Attribute</h6>
                            <app-three-state-custom-toggle-button [toggleValue]="toggleAll" (toggleChanged)="toggleChanged($event)"></app-three-state-custom-toggle-button>
                        <h6 class="mb-0 ml-3">Measure</h6>
                    </div>
                </div>
                <div class="d-flex align-items-center rowData mb-2" *ngFor="let colItem of gridColumnDefs; let i=index">
                    <h6 class="mb-0 srNo">{{i+1}}</h6>
                    <h6 class="mb-0 fieldName ml-3 d-flex align-items-center justify-content-between">
                        {{colItem.headerName}}<mat-icon (click)="updateName(colItem)" matTooltip="Edit name"
                            class="mx-2">edit</mat-icon>
                    </h6>
                    <div class="d-flex ml-2 align-items-center">
                        <h6 class="mb-0 mx-2">Attribute</h6>
                        <mat-slide-toggle color="warn" [disabled]="selectedUniqueID==colItem.field" [(ngModel)]="colItem.isMetricVariable" (change)="slideToggleChanged()"></mat-slide-toggle>
                        <h6 class="mb-0 ml-2">Measure</h6>
                    </div>

                    <div class="d-flex ml-5 align-items-center justify-content-center" style="width: 120px;">
                        <mat-radio-button value="{{colItem.field}}" [checked]="colItem.field==selectedUniqueID"
                            (change)="radioChanged(colItem, $event)" name="uniqueIDgroup"></mat-radio-button>
                    </div>
                    <div class="d-flex align-items-center justify-content-center" style="width: 120px;">
                        <mat-checkbox [checked]="groupBy === colItem.field"
                            (change)="checkboxChanged(colItem.field, $event)"></mat-checkbox>
                    </div>
                </div>
            </div>
        </div>

        <!-- UPLOAD TO SERVER & BACKEND BUTTON -->
        <div class="row mx-2" *ngIf="file && step==1 && !uploadingFile">
            <div class="col-md-6"></div>
            <div class="col-md-6 d-flex justify-content-end">
                <button mat-stroked-button color="accent" (click)="clearData()">Cancel</button>
                <button mat-raised-button color="accent" class="ml-2" (click)="uploadFile()">Next
                </button>
            </div>
        </div>

        <!-- PROGRESS BAR -->
        <div *ngIf="file && step==1 && uploadingFile" class="row m-2 d-flex justify-content-center align-items-center">
            <mat-progress-bar mode="determinate" color="accent" [value]="fileProgress"></mat-progress-bar>
            <p class="m-0 ml-2"><strong>{{fileProgress}}%</strong></p>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- File Uploader ~ END -->
