import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import {  ColumnApi, GridApi, GridOptions } from 'ag-grid-enterprise';
import { defaultColDef, getCalendarViewColDefs } from './ag-grid/grid-options';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { CalendarSetupService } from 'src/services/setup-services/calendar-setup.service';

@Component({
  selector: 'app-calendar-setup',
  templateUrl: './calendar-setup.component.html',
  styleUrls: ['./calendar-setup.component.scss']
})
export class CalendarSetupComponent implements OnInit, AfterViewInit {

  private _onDestroy: Subject<void> = new Subject<void>();
  isCalendarSaved: boolean = false;

  calendarSetupForm = new FormGroup({
    calendarType: new FormControl(null, [Validators.required]),
    weekEndDay: new FormControl(null),
    fyStartMonth: new FormControl(null),
    fyEndMonth: new FormControl(null),
    weekStartDay: new FormControl(null),
    calendarNRFType: new FormControl(null),
    fyStartYear: new FormControl(null, [Validators.required]),
    fyEndYear: new FormControl(null, [Validators.required]),
  });

  calendarData = [{
    key: 1,
    value: 'Gregorian',
  },
  {
    key: 2,
    value: 'NRF',
  },
  {
    key: 3,
    value: 'Custom'
  }];
  monthList = [
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
  ];
  nrfTypes=[
    { key: '4-5-4', value: '4-5-4' },
    { key: '4-4-5', value: '4-4-5' },
    { key: '5-4-4', value: '5-4-4' },

  ];
  weekDayList = [
    { key: 1, value: 'Monday' },
    { key: 2, value: 'Tuesday' },
    { key: 3, value: 'Wednesday' },
    { key: 4, value: 'Thursday' },
    { key: 5, value: 'Friday' },
    { key: 6, value: 'Saturday' },
    { key: 7, value: 'Sunday' },
  ];
  calendarDateList: any = [];


  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  public defaultColDef = defaultColDef;
  public columnDefs = getCalendarViewColDefs();
  gridData: any = [];
  yearList: any = [];
  selectedYear: any;
  isWeekendDateView: boolean = false;

  constructor(
    private calendarSetupService: CalendarSetupService,
    public dialog: MatDialog,
    public toastr: NgxToasterService,) { }

  get calendarType() {
    return this.calendarSetupForm.get('calendarType');
  }

  get fyStartYear() {
    return this.calendarSetupForm.get('fyStartYear');
  }

  get fyEndYear() {
    return this.calendarSetupForm.get('fyEndYear');
  }

  get endYearMin() {
    const startYear = this.fyStartYear.value;
    const endYear = this.fyEndYear.value;
    return this.isCalendarSaved ? moment(endYear).format('YYYY') : (startYear ? moment(startYear).format('YYYY') : null);
  }

  get startYearMax() {
    const endYear = this.fyEndYear.value;
    return endYear ? moment(endYear).format('YYYY') : null;
  }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit(): void {
    this.fyStartYear.valueChanges.subscribe(value => {
      this.viewCalendarDetail();
    });
    this.fyEndYear.valueChanges.subscribe(value => {
      this.viewCalendarDetail();
    });
  }

  initializeForm() {
    this.getCalendarSetupDetail();
  }

  getCalendarSetupDetail() {
    this.calendarSetupService.GetCalendarSetupDetail().subscribe((response: any) => {
      if (response && response.length) {
        this.calendarSetupForm.patchValue(response[0]);
        this.fyStartYear.setValue(new Date(response[0].fyStartYear, 1, 1));
        this.fyEndYear.setValue(new Date(response[0].fyEndYear, 1, 1));
        this.isCalendarSaved = true;
        this.viewCalendarDetail();
      }
    }, err => {
      console.log(err);
    })
  }

  calendarTypeChange(event) {
    if (event.value === 'Custom') {
      this.calendarSetupForm.controls['fyStartMonth'].setValidators([Validators.required]);
      this.calendarSetupForm.controls['fyEndMonth'].setValidators([Validators.required]);
      this.calendarSetupForm.controls['weekStartDay'].setValidators([Validators.required]);
      this.calendarSetupForm.controls['weekEndDay'].setValidators([Validators.required]);
    } else {
      this.calendarSetupForm.controls['fyStartMonth'].setValidators([]);
      this.calendarSetupForm.controls['fyEndMonth'].setValidators([]);
      this.calendarSetupForm.controls['weekStartDay'].setValidators([]);
      this.calendarSetupForm.controls['weekEndDay'].setValidators([]);
    }
    this.calendarSetupForm.get('fyStartMonth').updateValueAndValidity();
    this.calendarSetupForm.get('fyEndMonth').updateValueAndValidity();
    this.calendarSetupForm.get('weekStartDay').updateValueAndValidity();
    this.calendarSetupForm.get('weekEndDay').updateValueAndValidity();
    setTimeout(() => {
      this.viewCalendarDetail();
    }, 100);
  }

  fyStartMonthChange(event) {
    const value = this.calendarSetupForm.get('fyStartMonth').value;
    this.setOtherFYMonth('start', value);
    this.viewCalendarDetail();
  }

  fyEndMonthChange(event) {
    const value = this.calendarSetupForm.get('fyEndMonth').value;
    this.setOtherFYMonth('end', value);
    this.viewCalendarDetail();
  }

  setOtherFYMonth(type, value) {
    const valueIndex = _.findIndex(this.monthList, a => a.key == value);
    let otherMonthIndex: number;
    if (type === 'start') {
      otherMonthIndex = (valueIndex - 1) >= 0 ? (valueIndex - 1) : 11;
      this.calendarSetupForm.get('fyEndMonth').setValue(this.monthList[otherMonthIndex].key);
    } else {
      otherMonthIndex = (valueIndex + 1) > 11 ? 0 : (valueIndex + 1);
      this.calendarSetupForm.get('fyStartMonth').setValue(this.monthList[otherMonthIndex].key);
    }
  }

  weekStartDayChange(event) {
    const value = this.calendarSetupForm.get('weekStartDay').value;
    this.setOtherWeekDay('start', value);
  }

  weekEndDayChange(event) {
    const value = this.calendarSetupForm.get('weekEndDay').value;
    this.setOtherWeekDay('end', value);
  }

  setOtherWeekDay(type, value) {
    const valueIndex = _.findIndex(this.weekDayList, a => a.key == value);
    let otherDayIndex: number;
    if (type === 'start') {
      otherDayIndex = (valueIndex - 1) >= 0 ? (valueIndex - 1) : 6;
      this.calendarSetupForm.get('weekEndDay').setValue(this.weekDayList[otherDayIndex].key);
    } else {
      otherDayIndex = (valueIndex + 1) > 6 ? 0 : (valueIndex + 1);
      this.calendarSetupForm.get('weekStartDay').setValue(this.weekDayList[otherDayIndex].key);
    }
  }

  yearRangeChange(event) {
    this.viewCalendarDetail();
  }

  viewCalendarDetail() {
    if (this.calendarSetupForm.valid) {
      const payload = {
        ...this.calendarSetupForm.value,
        ...{
          fyStartYear: moment(this.fyStartYear.value).format('YYYY'),
          fyEndYear: moment(this.fyEndYear.value).format('YYYY')
        }
      };
      this.calendarSetupService.GetCalendarView(payload).subscribe(response => {
        this.calendarDateList = response;
        this.setYearList(response);
        this.setGridData(response);
      }, err => {
        this.calendarDateList = [];
      });
    }
  }

  saveCalendar() {
    if (this.calendarSetupForm.valid) {
      const payload = {
        ...this.calendarSetupForm.value,
        ...{
          fyStartYear: moment(this.fyStartYear.value).format('YYYY'),
          fyEndYear: moment(this.fyEndYear.value).format('YYYY')
        }
      };
      if (this.isCalendarSaved) {
        this.calendarSetupService.UpdateCalendar(payload).subscribe(response => {
          this.toastr.success('Success', 'Calendar extended successfully.');
        });
      } else {
        this.calendarSetupService.AddCalendar(payload).subscribe(response => {
          this.isCalendarSaved = true;
          this.toastr.success('Success', 'Calendar created successfully.');
        });
      }
    } else {
      this.toastr.warning('Warning', 'Please fill the form correctly.');
    }
  }

  setYearList(calendarDateList) {
    this.yearList = _.uniq(_.map(calendarDateList, a => a.reportYear));
  }

  selectYear(event) {
    this.setGridData(this.calendarDateList);
  }

  setGridData(calendarDateList) {
    this.selectedYear = this.selectedYear ? this.selectedYear : this.yearList[0];
    const gridData = _.filter(calendarDateList, a => a.reportYear == this.selectedYear);
    this.gridData = this.generateGridData(gridData);
    this.gridApi && this.gridApi.setRowData(this.gridData);
  }

  generateGridData(calendarData) {
    if (!this.isWeekendDateView) {
      const dailyCalendar: any = [];
      for (let i = 0; i < calendarData.length; i++) {
        const weekEndDate = calendarData[i].weekEndDate;
        let nextDate = calendarData[i].weekStartDate;
        while (new Date(nextDate) <= new Date(weekEndDate)) {
          dailyCalendar.push({
            ...calendarData[i],
            weekStartDate: nextDate
          });
          nextDate = moment(nextDate, 'YYYY-MM-DDT00:00:00').add(1, 'd').format('YYYY-MM-DDT00:00:00');
        }
      }
      return _.orderBy(dailyCalendar, ['reportYear', 'weekOfYear'], ['asc', 'asc']);
    } else {
      return calendarData;
    }
  }

  calendarViewChange(event) {
    this.setGridData(this.calendarDateList);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  onExportGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'calendar-view',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
  }

}
