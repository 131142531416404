export const conditionsDropdownData = [
    {
        id: '<',
        label: 'is less than'
    },
    {
        id: '<=',
        label: 'is less than or equal to'
    },
    {
        id: '>',
        label: 'is greater than'
    },
    {
        id: '>=',
        label: 'is greater than or equal to'
    },
    {
        id: '==',
        label: 'is'
    },
    {
        id: '!=',
        label: 'is not'
    },
    {
        id: 'is blank',
        label: 'is blank'
    },
    {
        id: 'is not blank',
        label: 'is not blank'
    }
];
