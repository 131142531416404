<div
  class="fb-forecast-accordion item-wrapper-panel insight-modal-wrapper"
  id="fb-forecast"
>
  <form [formGroup]="fg" class="item-wrapper">
    <div class="row">
      <div class="col-md-7 form-group">
        <label style="min-width: 50px">Insight</label>
        <mat-form-field>
          <input
            matInput
            formControlName="insights"
            [class.error]="submitted && !fg.controls.insights.value"
          />
        </mat-form-field>
      </div>
      <div class="col-md-5 form-group">
        <label style="padding-left: 40px; min-width: 170px">Value</label>
        <mat-form-field>
          <input
            matInput
            formControlName="value"
            type="number"
            [class.error]="submitted && !fg.controls.value.value"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row insight-form-wrapper">
      <div class="col-md-3">
        <img
          *ngIf="fg.controls && !fg.controls.documentPath.value"
          src="assets/images/thumbnail.png"
          alt=""
          class="img-fluid"
        />
        <img
          *ngIf="fg.controls && fg.controls.documentPath.value"
          [src]="fg.controls.documentPath.value"
          alt=""
          class="img-fluid"
        />
        <ul>
          <li>
            <a
              (click)="openImageDialog(DocumentImport, 'documentId')"
              href="javascript:void(0)"
              >Upload Image</a
            >
          </li>
          <li>
            <a (click)="deleteImage('Document')" href="javascript:void(0)"
              >Clear Image</a
            >
          </li>
        </ul>
        <img
          *ngIf="fg.controls && !fg.controls.graphPath.value"
          src="assets/images/thumbnail.png"
          alt=""
          class="img-fluid"
        />
        <img
          *ngIf="fg.controls && fg.controls.graphPath.value"
          [src]="fg.controls && fg.controls.graphPath.value"
          alt=""
          class="img-fluid"
        />
        <ul>
          <li>
            <a
              (click)="openImageDialog(DocumentImport, 'graphId')"
              href="javascript:void(0)"
              >Upload Graph</a
            >
          </li>
          <li>
            <a (click)="deleteImage('Graph')" href="javascript:void(0)"
              >Clear Graph</a
            >
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Retailer</label>
              <mat-select
                formControlName="retailerId"
                [class.error]="submitted && !fg.controls.retailerId.value"
              >
                <mat-option
                  *ngFor="let retailer of retailerList"
                  [value]="retailer.channelId"
                  >{{ retailer.channelName }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="">Country</label>
              <mat-select
                formControlName="countryId"
                [class.error]="submitted && !fg.controls.countryId.value"
              >
                <mat-option
                  *ngFor="let country of countryList"
                  [value]="country.countryId"
                  >{{ country.countryName }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="">Product</label>
              <mat-select
                formControlName="productId"
                [class.error]="submitted && !fg.controls.productId.value"
              >
                <mat-option
                  *ngFor="let product of productList"
                  [value]="product.itemManagerId"
                >
                  {{ product.description }}</mat-option
                >
              </mat-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <mat-form-field>
                <label>Generated Date</label>
                <input
                  disabled
                  (click)="picker1.open()"
                  formControlName="dateGenerated"
                  matInput
                  [matDatepicker]="picker1"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field>
                <label>Expiry Date</label>
                <input
                  [disabled]="fg.value.expiryCondition ? true : false"
                  (click)="picker2.open()"
                  formControlName="expiryDate"
                  matInput
                  [matDatepicker]="picker2"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error
                  *ngIf="
                    submitted && fg.controls['expiryDate'].hasError('required')
                  "
                >
                  *Required Field
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label for="">Expiry Condition</label>
              <input
                [disabled]="fg.controls['expiryDate'].value ? true : false"
                type="text"
                formControlName="expiryCondition"
              />
            </div>
            <div class="form-group">
              <label for="">Expired</label>
              <mat-slide-toggle
                [disabled]="fg.controls['expiryDate'].value ? true : false"
                formControlName="isExpired"
                >{{ fg.controls?.isExpired?.value ? "Yes" : "No" }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Description</label>
              <textarea formControlName="description"></textarea>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Suggested Action</label>
              <textarea formControlName="suggestedAction"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Metric</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Value</label>
            </div>
          </div>
          <div
            class="row row-inner"
            *ngFor="let metric of metricList; let i = index"
          >
            <div class="col-md-6">
              <div class="form-group">
                <input
                  [(ngModel)]="metric.metricApplicationDescription"
                  [ngModelOptions]="{ standalone: true }"
                />
                <i
                  class="icon-add"
                  (click)="addNewMetric()"
                  *ngIf=" metricList.length - 1 === i"
                ></i>
                <i
                  class="icon-cross-slim"
                  *ngIf="metricList.length - 1 !== i"
                  (click)="removeMetric(i)"
                ></i>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="number"
                  [(ngModel)]="metric.value"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 btn-wrapper">
      <mat-slide-toggle formControlName="isPublish">Publish</mat-slide-toggle>
      <button class="btn btn-secondary" mat-dialog-close>Close</button>
      <button class="btn btn-primary" (click)="onSubmit(false)">Save</button>
    </div>
  </form>
</div>

<ng-template #DocumentImport let-data>
  <div mat-dialog-content>
    <form>
      <div>
        <ngx-dropzone (change)="onSelect($event, data)" [multiple]="false">
          <ngx-dropzone-label>Drop Image here!</ngx-dropzone-label>
          <ngx-dropzone-preview
            *ngFor="let f of files"
            [removable]="true"
            (removed)="onRemove(f)"
          >
            <ngx-dropzone-label style="outline: none"
              >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
            >
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
      <div class="action-panel text-right">
        <button
          mat-raised-button
          class="btn btn-secondary m-left"
          (click)="dialogRefUpload.close()"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
