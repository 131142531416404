import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ContextLogModel } from '../contextLog.model';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-existing-journey',
  templateUrl: './existing-journey.component.html',
  styleUrls: ['./existing-journey.component.scss'],
})
export class ExistingJourneyComponent implements OnInit {

  addToJourneyForm = new FormGroup({
    journeyId: new FormControl(null, Validators.required),
    nameAs: new FormControl(null, Validators.required),
  });

  private readonly reportNamePrefix = '@algo-journey~';

  journeysList: any = [];
  filteredOptions: Observable<any[]>;

  selectedJourney: any;
  selectedSubJourney: any;
  reportMode: 'create' | 'save' = 'create';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExistingJourneyComponent>,
    private configService: ConfigurationService,
    public storage: LocalstorageService,
    public toastr: NgxToasterService,) {

  }

  ngOnInit() {
    this.journeysList = this.data.journeysList;
    this.selectedSubJourney = this.data.selectedSubJourney;
    this.reportMode = this.data.reportMode;
  }


  onFocus(event: any) {
    this.filteredOptions = of(this.journeysList);
  }

  displayFn(element: any): string {
    return element && element.journeyName ? element.journeyName : '';
  }

  _filter(event): any {
    let value = _.toLower(event.target.value);
    this.filteredOptions = of(this.journeysList.filter(element => element.journeyName.toLowerCase().includes(value)));
  }

  optionSelected(element: any) {
    this.selectedJourney = element;
    this.addToJourneyForm.get('journeyId').setValue(element.journeyId);
  }

  saveToExistingJourney() {
    if (this.reportMode === 'save') {
      const contextLogModel: ContextLogModel = new ContextLogModel();
      contextLogModel.ActualName = this.reportNamePrefix + this.addToJourneyForm.get('nameAs').value;
      contextLogModel.DisplayName = this.addToJourneyForm.get('nameAs').value;
      contextLogModel.GroupId = this.selectedSubJourney.groupId;
      contextLogModel.ReportId = this.selectedSubJourney.reportId;
      contextLogModel.JourneyId = this.selectedJourney.journeyId;
      this.configService.createContextLog(contextLogModel).subscribe((contextLogId: number) => {
        this.dialogRef.close();
        this.toastr.success(
          `Journey Updated`,
          `Log Saved: ${contextLogModel.DisplayName}`
        );
      });
    } else {
      this.dialogRef.close({
        ...this.selectedJourney,
        nameAs: this.addToJourneyForm.get('nameAs').value
      });
    }
  }
}
