import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { SegmentationService } from 'src/services/segmentation.service';

@Component({
  selector: 'app-run-segmentaion',
  templateUrl: './run.component.html',
  styleUrls: ['./run.component.scss']
})
export class RunComponent implements OnInit, AfterViewInit {
  @Input() sampleData: any;
  @Input() resizeGraphSubject: Subject<boolean>;
  
  allSegments = [];

  constructor(private segmentationService: SegmentationService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    this.fetchAllSegments(false);
  }

  fetchAllSegments(updated: boolean) {
    this.spinner.show();
    this.segmentationService.fetchAllSegments(this.sampleData.userId, this.sampleData.fileName, this.sampleData.versionNo, 'RUN_0')
    .subscribe((res: any) => {
      this.allSegments = res.payload;
      this.spinner.hide();
    });
  }
}
