import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CLIENT_INFO, USER_ID, USER_INFO } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { JourneyCommentsRequestVM } from '../journeyComment.model.ts';

@Component({
  selector: 'app-journey-comment',
  templateUrl: './journey-comment.component.html',
  styleUrls: ['./journey-comment.component.scss'],
})
export class JourneyCommentComponent implements OnInit {
  comment: FormControl = new FormControl();
  comments: JourneyCommentsRequestVM[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    private spinner: NgxSpinnerService,
    public storage: LocalstorageService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.configService.getJourneyComments(this.data).subscribe((comments: JourneyCommentsRequestVM[]) => {
      this.comments = comments;
    });
  }

  onKeydown(event) {
    event.preventDefault();
  }

  saveComment() {
    const journeyComment = new JourneyCommentsRequestVM();
    journeyComment.comment = this?.comment?.value as string;
    journeyComment.journeyId = this.data;
    journeyComment.createdBy = this.storage.get(USER_ID);
    journeyComment.createdAt = new Date();
    journeyComment.clientConfigurationId = this.storage.get(CLIENT_INFO).clientConfigurationId;
    journeyComment.instance = this.document.baseURI;
    this.configService
      .setJourneyComments(journeyComment)
      .subscribe((res: JourneyCommentsRequestVM) => {
        // console.log(res);
        journeyComment.id = res.id;
        journeyComment.fullName = this.storage.get(USER_INFO).firstName + ' ' + this.storage.get(USER_INFO).lastName;
        this.comments.push(journeyComment);
        this.comment.reset();
      });
  }

  isMyComment(comment:JourneyCommentsRequestVM){
    return this.storage.get(USER_ID) === comment.createdBy;
  }
}
