export const columnVariables = [
    {
        id: 'Length',
        label: 'Relationship duration'
    },
    {
        id: 'Recency',
        label: 'Day since last purchase'
    },
    {
        id: 'Frequency',
        label: 'Purchase per half year'
    },
    {
        id: 'Monetary',
        label: 'Monetary per half year'
    },
    {
        id: 'Variety',
        label: 'Breadth of item purchase'
    }
];

export const SEGMENTATION_OUTLIER_ID = 10000;

export const SEGMENTATION_OUTLIER_COL_NAM = 'Outliers';
