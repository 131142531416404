import {
  EventEmitter,
  Injectable,
  Injector
} from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
//Sometimes it may show error as environment folder have to be created before building
import { environment } from '../environments/environment';
import {
  Calender,
  Category,
  Channel,
  Client,
  Distributor,
  Form,
  Source,
} from '../models/interfaces/configuration.model';
import { BehaviorSubject, Observable, Subject, ReplaySubject } from 'rxjs';
import {
  Calendar,
  FbChannel,
  FbForecast,
  Measure,
  UserFbForecast,
  Version,
} from '../common/facebook.models';
import { Item, ItemAccount } from '../modules/items/item.model';

var mouseDown = 0;
document.body.onmousedown = function () {
  mouseDown = 1;
};
document.body.onmouseup = function () {
  mouseDown = 0;
};
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  // Module State Flags
  isMfpLoaded = false;
  isPdmLoaded = false;
  isReportingLoaded = false;
  isCpmLoaded = false;
  isIMLoaded = false;
  isFbLoaded = false;
  isMsLoaded = false;
  isItemLoaded = false;
  isAuthLoaded = false;
  isLayoutLoaded = false;
  isPromotionLoaded = false;
  isAppInsightLoaded = false;
  isMessagingLoaded = false;
  isClusteringLoaded = false;
  isForecastManagerLoaded = false;
  isDemoModuleLoaded = false;
  isSegmentationLoaded = false;
  isProcurementLoaded = false;
  tempForecastList = [];
  regTempForecastList = [];
  colabTempForecastList = [];
  // Subjects
  public selectorModuleSubject: Subject<any> = new Subject();
  public editColumnSubject: Subject<any> = new Subject();
  public removeColumnSubject: Subject<any> = new Subject();
  public sendMessageToChannel: Subject<any> = new Subject();
  public openFavouriteSubject = new ReplaySubject<any>(null);
  public simulationSubject = new BehaviorSubject<any>(null);
  public favouriteRemoveSubject = new BehaviorSubject<any>(null);
  public menuRendererSubject = new BehaviorSubject<any>(null);
  public takeScreenshot = new BehaviorSubject<any>(null);
  public promotionListSubject = new BehaviorSubject<any>(null);
  public itemMasterListSubject = new BehaviorSubject<any>(null);
  public menuListSubject = new BehaviorSubject<any>(null);
  public tabListSubject = new BehaviorSubject<any>(null);
  public languageSubject = new BehaviorSubject<any>(null);
  public updateListing = new BehaviorSubject<any>(null);
  public removeMenu = new BehaviorSubject<any>(null);
  public yearListUpdated = new BehaviorSubject<any>(null);
  public savedPlannerOpened = new BehaviorSubject<any>(null);
  public savedItemPlannerOpened = new BehaviorSubject<any>(null);
  public updateForecastList = new BehaviorSubject<any>(null);
  public updateForecastListRegional = new BehaviorSubject<any>(null);
  public updateMethodManagerList = new BehaviorSubject<any>(null);
  public updateMethodManagerListRegional = new BehaviorSubject<any>(null);
  public updateGridList = new BehaviorSubject<any>(null);
  public openImportDialog = new BehaviorSubject<any>(null);
  public updateWeeks = new BehaviorSubject<any>(null);
  public viewJourney = new EventEmitter<any>();
  // Observables
  public openFavouriteSubject$ = this.openFavouriteSubject.asObservable();
  public favouriteListSubject = new Subject();
  public favouriteListSubject$ = this.favouriteListSubject.asObservable();
  public simulationSubject$ = this.simulationSubject.asObservable();
  public favouriteRemoveSubject$ = this.favouriteRemoveSubject.asObservable();
  public menuRendererSubject$ = this.menuRendererSubject.asObservable();
  public takeScreenshot$ = this.takeScreenshot.asObservable();
  public promotionListSubject$ = this.promotionListSubject.asObservable();
  public itemMasterListSubject$ = this.itemMasterListSubject.asObservable();
  public menuListSubject$ = this.menuListSubject.asObservable();
  public tabListSubject$ = this.tabListSubject.asObservable();
  public languageSubject$ = this.languageSubject.asObservable();
  public updateListing$ = this.updateListing.asObservable();
  public removeMenu$ = this.removeMenu.asObservable();
  public yearListUpdated$ = this.yearListUpdated.asObservable();
  public savedPlannerOpened$ = this.savedPlannerOpened.asObservable();
  public updateForecastList$ = this.updateForecastList.asObservable();
  public updateGridList$ = this.updateGridList.asObservable();
  public openImportDialog$ = this.openImportDialog.asObservable();
  public updateForecastListRegional$ =
    this.updateForecastListRegional.asObservable();
  public updateMethodManagerList$ = this.updateMethodManagerList.asObservable();
  public updateMethodManagerListRegional$ =
    this.updateMethodManagerListRegional.asObservable();
  public updateWeeks$ = this.updateWeeks.asObservable();
  public itemModel = new Item(new Item());
  public accountModel = new ItemAccount(new ItemAccount());
  public roleModel;
  public teamsModel;
  public promotionModel: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public http: HttpClient, private injector: Injector,) { }
  get isMouseDown() {
    return mouseDown;
  }
  setMousedown(value) {
    mouseDown = value;
  }
  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */

  setEditColumnSubject = (value: any) => { this.editColumnSubject.next(value); };
  setRemoveColumnSubject = (value: any) => { this.removeColumnSubject.next(value); };
  setSelectorModuleSubject = (value: any) => { this.removeColumnSubject.next(value); };
  getEditColumnSubject = () => this.editColumnSubject.asObservable();
  getRemoveColumnSubject = () => this.removeColumnSubject.asObservable();
  getSelectorModuleSubject = () => this.removeColumnSubject.asObservable();
  /* Calender CRUD Model */

  loadModule(module) {
    switch (module) {
      case 'Auth':
        if (!this.isAuthLoaded) {
          import('../modules/auth/auth.module').then((m) => {
            const loadedComponent = new m.AuthModule(
              this.injector,
              this
            );
          });
        }
      case 'ReportingModule':
        if (!this.isReportingLoaded) {
          import('../modules/reporting/reporting.module').then((m) => {
            const loadedComponent = new m.ReportingModule(
              this.injector,
              this
            );
          });
        }
        break;
      case 'ItemManagerModule':
        {
          if (!this.isIMLoaded) {
            import('../modules/item-manager/item-manager.module').then((m) => {
              const loadedComponent = new m.ItemManagerModule(this.injector, this);
            });
          }
          break;
        }
      case 'MessagingModule':
        {
          if (!this.isMessagingLoaded) {
            import('../modules/messaging/messaging.module').then((m) => {
              const loadedComponent = new m.MessagingModule(this.injector, this);
            });
          }
          break;
        }
      case 'ForecastEnginModule': {
        if (!this.isForecastManagerLoaded) {
          import('../modules/forecast-engin/forecast-engin.module').then((m) => {
            const loadedComponent = new m.ForecastEnginModule(
              this.injector,
              this
            );
          });
        }
        break;
      }
      case 'DemoManagerModule': {
        if (!this.isDemoModuleLoaded) {
          import('../modules/demo-manager/demo-manager.module').then((m) => {
            const loadedComponent = new m.DemoManagerModule(
              this.injector,
              this
            );
          });
        }
        break;
      }
      default:
        break;
    }
  }

  addCalender = (calender: Calender) => {
    return this.http.post<any>(`${environment.AppCalender}/add`, calender);
  };
  updateCalender = (calender: Calender) => {
    return this.http.put(`${environment.AppCalender}/update`, calender);
  };
  activateCalender = (calender: Calender) => {
    return this.http.put(`${environment.AppCalender}/activate`, calender);
  };
  getGetCalenderEventGroup = (params: any) => {
    return this.http.get<any>(
      `${environment.AppCalender}/getGetCalenderEventGroup`,
      { params }
    );
  };

  /* Category CRUD Model */

  addCategory = (category: Category) => {
    return this.http.post<any>(`${environment.AppCategory}/add`, category);
  };
  updateCategory = (category: Category) => {
    return this.http.put(`${environment.AppCategory}/update`, category);
  };
  activateCategory = (category: any) => {
    return this.http.put(`${environment.AppCategory}/activate`, category);
  };
  searchCategory = (searchText: string) => {
    return this.http.get<any>(
      `${environment.AppCategory}/search?search=${searchText}`
    );
  };
  getCategory = (categoryId: string) => {
    return this.http.get<any>(
      `${environment.AppCategory}/get?CategoryId=${categoryId}`
    );
  };
  getAllCategories = (params: any) => {
    return this.http.get<any>(`${environment.AppCategory}/getall`, { params });
  };
  getCategoryList = (params: any) => {
    return this.http.get<any>(`${environment.AppCategory}/getlist`, { params });
  };

  /* Channel CRUD Model */

  addChannel = (channel: Channel) => {
    return this.http.post<any>(`${environment.AppChannel}/add`, channel);
  };
  updateChannel = (channel: Channel) => {
    return this.http.put(`${environment.AppChannel}/update`, channel);
  };
  activateChannel = (channel: Channel) => {
    return this.http.put(`${environment.AppChannel}/activate`, channel);
  };
  getChannel = (ChannelId: string) => {
    return this.http.get<any>(
      `${environment.AppChannel}/get?ChannelId=${ChannelId}`
    );
  };
  getAllChannels = (params: any) => {
    return this.http.get<any>(`${environment.AppChannel}/getall`, { params });
  };
  getChannelList = (params: any) => {
    return this.http.get<any>(`${environment.AppChannel}/getlist`, params);
  };

  /* Client CRUD Model */

  addClient = (client: Client) => {
    return this.http.post<any>(`${environment.AppClient}/add`, client);
  };
  updateClient = (client: Client) => {
    return this.http.put(`${environment.AppClient}/update`, client);
  };
  activateClient = (client: Client) => {
    return this.http.put(`${environment.AppClient}/activate`, client);
  };
  getClient = (ClientId: string) => {
    return this.http.get<any>(
      `${environment.AppClient}/get?ClientId=${ClientId}`
    );
  };
  getAllClients = (params: any) => {
    return this.http.get<any>(`${environment.AppClient}/getall`, params);
  };
  getClientList = (params: any) => {
    return this.http.get<any>(`${environment.AppClient}/getlist`, params);
  };
  /* Distributor CRUD Model */

  addDistributor = (distributor: Distributor) => {
    return this.http.post<any>(
      `${environment.AppDistributor}/add`,
      distributor
    );
  };
  updateDistributor = (distributor: Distributor) => {
    return this.http.put(`${environment.AppDistributor}/update`, distributor);
  };
  activateDistributor = (distributor: Distributor) => {
    return this.http.put(`${environment.AppDistributor}/activate`, distributor);
  };
  getDistributor = (DistributorId: string) => {
    return this.http.get<any>(
      `${environment.AppDistributor}/get?DistributorId=${DistributorId}`
    );
  };
  getAllDistributors = (params: any) => {
    return this.http.get<any>(`${environment.AppDistributor}/getall`, {
      params,
    });
  };
  getDistributorList = (params: any) => {
    return this.http.get<any>(`${environment.AppDistributor}/getlist`, params);
  };

  /* Distributor CRUD Model */

  addForm = (form: Form) => {
    return this.http.post<any>(`${environment.AppForms}/add`, form);
  };
  updateForm = (form: Form) => {
    return this.http.put(`${environment.AppForms}/update`, form);
  };
  activateForm = (form: Form) => {
    return this.http.put(`${environment.AppForms}/activate`, form);
  };
  getForm = (FormId: string) => {
    return this.http.get<any>(`${environment.AppForms}/get?FormId=${FormId}`);
  };
  getAllForms = (params: any) => {
    return this.http.get<any>(`${environment.AppForms}/getall`, params);
  };
  getFormList = (params: any) => {
    return this.http.get<any>(`${environment.AppForms}/getlist`, params);
  };
  /* Sourcing CRUD Model */

  addSource = (source: Source) => {
    return this.http.post<any>(`${environment.AppSource}/add`, source);
  };
  updateSource = (source: Source) => {
    return this.http.put(`${environment.AppSource}/update`, source);
  };
  activateSource = (source: Source) => {
    return this.http.put(`${environment.AppSource}/activate`, source);
  };
  getSource = (SourceId: string) => {
    return this.http.get<any>(
      `${environment.AppSource}/get?SourceId=${SourceId}`
    );
  };
  getAllSources = (params: any) => {
    return this.http.get<any>(`${environment.AppSource}/getall`, { params });
  };
  getSourceList = (params: any) => {
    return this.http.get<any>(`${environment.AppSource}/getlist`, params);
  };
  /* Goal CRUD Model */

  addGoal = (goal: any) => {
    return this.http.post<any>(`${environment.AppGoal}/add`, goal);
  };
  updateGoal = (goal: any) => {
    return this.http.put(`${environment.AppGoal}/update`, goal);
  };
  activateGoalType = (goal: any) => {
    return this.http.put(`${environment.AppGoalType}/activate`, goal);
  };
  getGoalByGoalId = (goalId: any) => {
    return this.http.get<any>(`${environment.AppGoal}/get?goalId=${goalId}`);
  };
  getGoalList = (params: any) => {
    return this.http.get<any>(`${environment.AppGoal}/getlist`, { params });
  };
  /* Source Mix Form CRUD Model */

  addSourceMixForm = (SourceMixForm: any) => {
    return this.http.post<any>(
      `${environment.AppSourceMixForm}/add`,
      SourceMixForm
    );
  };
  updateSourceMixForm = (SourceMixForm: any) => {
    return this.http.put(
      `${environment.AppSourceMixForm}/update`,
      SourceMixForm
    );
  };
  activateSourceMixForm = (SourceMixForm: any) => {
    return this.http.put(
      `${environment.AppSourceMixForm}/activate`,
      SourceMixForm
    );
  };
  getSourceMixFormBySourceMixFormId = (SourceMixFormId: any) => {
    return this.http.get<any>(
      `${environment.AppSourceMixForm}/get?SourceMixFormId=${SourceMixFormId}`
    );
  };
  getSourceMixFormList = (params: any) => {
    return this.http.get<any>(`${environment.AppSourceMixForm}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addSourceMixRow = (SourceMixRow: any) => {
    return this.http.post<any>(
      `${environment.AppSourceMixRow}/add`,
      SourceMixRow
    );
  };
  updateSourceMixRow = (SourceMixRow: any) => {
    return this.http.put(`${environment.AppSourceMixRow}/update`, SourceMixRow);
  };
  activateSourceMixRow = (SourceMixRow: any) => {
    return this.http.put(
      `${environment.AppSourceMixRow}/activate`,
      SourceMixRow
    );
  };
  getSourceMixRowBySourceMixRowId = (SourceMixRowId: any) => {
    return this.http.get<any>(
      `${environment.AppSourceMixRow}/get?SourceMixRowId=${SourceMixRowId}`
    );
  };
  getSourceMixRowList = (params: any) => {
    return this.http.get<any>(`${environment.AppSourceMixRow}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addSourceMixHeader = (SourceMixHeader: any) => {
    return this.http.post<any>(
      `${environment.AppSourceMixHeader}/AddSourceMixHeader`,
      SourceMixHeader
    );
  };
  updateSourceMixHeader = (SourceMixHeader: any) => {
    return this.http.put(
      `${environment.AppSourceMixHeader}/update`,
      SourceMixHeader
    );
  };
  activateSourceMixHeader = (SourceMixHeader: any) => {
    return this.http.put(
      `${environment.AppSourceMixHeader}/activate`,
      SourceMixHeader
    );
  };
  getSourceMixHeaderBySourceMixHeaderId = (SourceMixHeaderId: any) => {
    return this.http.get<any>(
      `${environment.AppSourceMixHeader}/get?SourceMixHeaderId=${SourceMixHeaderId}`
    );
  };
  getSourceMixHeaderList = (params: any) => {
    return this.http.get<any>(`${environment.AppSourceMixHeader}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addCategoryMixRow = (CategoryMixRow: any) => {
    return this.http.post<any>(
      `${environment.AppCategoryMixRow}/add`,
      CategoryMixRow
    );
  };
  updateCategoryMixRow = (CategoryMixRow: any) => {
    return this.http.put(
      `${environment.AppCategoryMixRow}/update`,
      CategoryMixRow
    );
  };
  activateCategoryMixRow = (CategoryMixRow: any) => {
    return this.http.put(
      `${environment.AppCategoryMixRow}/activate`,
      CategoryMixRow
    );
  };
  getCategoryMixRowByCategoryMixRowId = (CategoryMixRowId: any) => {
    return this.http.get<any>(
      `${environment.AppCategoryMixRow}/get?CategoryMixRowId=${CategoryMixRowId}`
    );
  };
  getCategoryMixRowList = (params: any) => {
    return this.http.get<any>(`${environment.AppCategoryMixRow}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addCategoryMixHeader = (CategoryMixHeader: any) => {
    return this.http.post<any>(
      `${environment.AppCategoryMixHeader}/AddCategoryMixHeader`,
      CategoryMixHeader
    );
  };
  updateCategoryMixHeader = (CategoryMixHeader: any) => {
    return this.http.put(
      `${environment.AppCategoryMixHeader}/update`,
      CategoryMixHeader
    );
  };
  activateCategoryMixHeader = (CategoryMixHeader: any) => {
    return this.http.put(
      `${environment.AppCategoryMixHeader}/activate`,
      CategoryMixHeader
    );
  };
  getCategoryMixHeaderByCategoryMixHeaderId = (CategoryMixHeaderId: any) => {
    return this.http.get<any>(
      `${environment.AppCategoryMixHeader}/get?CategoryMixHeaderId=${CategoryMixHeaderId}`
    );
  };
  getCategoryMixHeaderList = (params: any) => {
    return this.http.get<any>(`${environment.AppCategoryMixHeader}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addCategoryMixForm = (CategoryMixForm: any) => {
    return this.http.post<any>(
      `${environment.AppCategoryMixForm}/add`,
      CategoryMixForm
    );
  };
  updateCategoryMixForm = (CategoryMixForm: any) => {
    return this.http.put(
      `${environment.AppCategoryMixForm}/update`,
      CategoryMixForm
    );
  };
  getCategoryMixFormByCategoryMixFormId = (CategoryMixFormId: any) => {
    return this.http.get<any>(
      `${environment.AppCategoryMixForm}/get?CategoryMixFormId=${CategoryMixFormId}`
    );
  };
  getCategoryMixFormList = (params: any) => {
    return this.http.get<any>(`${environment.AppCategoryMixForm}/getlist`, {
      params,
    });
  };

  addStoreMixRow = (StoreMixRow: any) => {
    return this.http.post<any>(
      `${environment.AppStoreMixRow}/add`,
      StoreMixRow
    );
  };
  updateStoreMixRow = (StoreMixRow: any) => {
    return this.http.put(`${environment.AppStoreMixRow}/update`, StoreMixRow);
  };
  activateStoreMixRow = (StoreMixRow: any) => {
    return this.http.put(`${environment.AppStoreMixRow}/activate`, StoreMixRow);
  };
  getStoreMixRowByStoreMixRowId = (StoreMixRowId: any) => {
    return this.http.get<any>(
      `${environment.AppStoreMixRow}/get?StoreMixRowId=${StoreMixRowId}`
    );
  };
  getStoreMixRowList = (params: any) => {
    return this.http.get<any>(`${environment.AppStoreMixRow}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addStoreMixHeader = (StoreMixHeader: any) => {
    return this.http.post<any>(
      `${environment.AppStoreMixHeader}/AddNewFormHeader`,
      StoreMixHeader
    );
  };
  updateStoreMixHeader = (StoreMixHeader: any) => {
    return this.http.put(
      `${environment.AppStoreMixHeader}/update`,
      StoreMixHeader
    );
  };
  activateStoreMixHeader = (StoreMixHeader: any) => {
    return this.http.put(
      `${environment.AppStoreMixHeader}/activate`,
      StoreMixHeader
    );
  };
  getStoreMixHeaderByStoreMixHeaderId = (StoreMixHeaderId: any) => {
    return this.http.get<any>(
      `${environment.AppStoreMixHeader}/get?StoreMixHeaderId=${StoreMixHeaderId}`
    );
  };
  getStoreMixHeaderList = (params: any) => {
    return this.http.get<any>(`${environment.AppStoreMixHeader}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addStoreMixForm = (StoreMixForm: any) => {
    return this.http.post<any>(
      `${environment.AppStoreMixForm}/add`,
      StoreMixForm
    );
  };
  updateStoreMixForm = (StoreMixForm: any) => {
    return this.http.put(`${environment.AppStoreMixForm}/update`, StoreMixForm);
  };
  getStoreMixFormByStoreMixFormId = (StoreMixFormId: any) => {
    return this.http.get<any>(
      `${environment.AppStoreMixForm}/get?StoreMixFormId=${StoreMixFormId}`
    );
  };
  getStoreMixFormList = (params: any) => {
    return this.http.get<any>(`${environment.AppStoreMixForm}/getlist`, {
      params,
    });
  };

  /* Goal CRUD Model */
  addGoalTenureType = (goalTenureType: any) => {
    return this.http.post<any>(
      `${environment.AppGoalTenure}/AddGoalTenureType`,
      goalTenureType
    );
  };
  updateGoalTenureType = (goalTenureType: any) => {
    return this.http.put(`${environment.AppGoalTenure}/update`, goalTenureType);
  };
  activateGoalTenureType = (goalTenureType: any) => {
    return this.http.put(
      `${environment.AppGoalTenure}/activate`,
      goalTenureType
    );
  };
  getGoalTenureTypeByGoalTenureTypeId = (goalTenureTypeId: any) => {
    return this.http.get<any>(
      `${environment.AppGoalTenure}/get?goalTenureTypeId=${goalTenureTypeId}`
    );
  };
  getGoalTenureTypeList = (params: any) => {
    return this.http.get<any>(`${environment.AppGoalTenure}/getlist`, {
      params,
    });
  };
  addCalenderActivities = (CalenderActivity: any) => {
    return this.http.post<any>(
      `${environment.AppCalenderActivities}/Add`,
      CalenderActivity
    );
  };
  updateCalenderActivities = (CalenderActivity: any) => {
    return this.http.put(
      `${environment.AppCalenderActivities}/Update`,
      CalenderActivity
    );
  };
  getAllCalenderActivities = (params: any) => {
    return this.http.get<any>(`${environment.AppCalenderActivities}/getList`, {
      params,
    });
  };
  addEventTypes = (EventType: any) => {
    return this.http.post<any>(`${environment.AppEventTypes}/Add`, EventType);
  };
  updateEventTypes = (EventType: any) => {
    return this.http.put(`${environment.AppEventTypes}/Update`, EventType);
  };
  getAllEventTypes = (params: any) => {
    return this.http.get<any>(`${environment.AppEventTypes}/getList`, {
      params,
    });
  };
  public getCalendarView = (params) => {
    return this.http.get<any>(`${environment.AppCalender}/getCalendarView`, {
      params,
    });
  };
  public getGoalTypeList = (params) => {
    return this.http.get<any>(`${environment.AppGoalType}/getList`, { params });
  };
  addSameStoreRow = (SameStoreRow: any) => {
    return this.http.post<any>(
      `${environment.AppSameStoreRow}/add`,
      SameStoreRow
    );
  };
  updateSameStoreRow = (SameStoreRow: any) => {
    return this.http.put(`${environment.AppSameStoreRow}/update`, SameStoreRow);
  };
  activateSameStoreRow = (SameStoreRow: any) => {
    return this.http.put(
      `${environment.AppSameStoreRow}/activate`,
      SameStoreRow
    );
  };
  getSameStoreRowBySameStoreRowId = (SameStoreRowId: any) => {
    return this.http.get<any>(
      `${environment.AppSameStoreRow}/get?SameStoreRowId=${SameStoreRowId}`
    );
  };
  getSameStoreRowList = (params: any) => {
    return this.http.get<any>(`${environment.AppSameStoreRow}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addSameStoreHeader = (SameStoreHeader: any) => {
    return this.http.post<any>(
      `${environment.AppSameStoreHeader}/AddSameStoreHeader`,
      SameStoreHeader
    );
  };
  updateSameStoreHeader = (SameStoreHeader: any) => {
    return this.http.put(
      `${environment.AppSameStoreHeader}/update`,
      SameStoreHeader
    );
  };
  activateSameStoreHeader = (SameStoreHeader: any) => {
    return this.http.put(
      `${environment.AppSameStoreHeader}/activate`,
      SameStoreHeader
    );
  };
  getSameStoreHeaderBySameStoreHeaderId = (SameStoreHeaderId: any) => {
    return this.http.get<any>(
      `${environment.AppSameStoreHeader}/get?SameStoreHeaderId=${SameStoreHeaderId}`
    );
  };
  getSameStoreHeaderList = (params: any) => {
    return this.http.get<any>(`${environment.AppSameStoreHeader}/getlist`, {
      params,
    });
  };
  /* Source Mix Form CRUD Model */

  addSameStoreForm = (SameStoreForm: any) => {
    return this.http.post<any>(
      `${environment.AppSameStoreForm}/add`,
      SameStoreForm
    );
  };
  updateSameStoreForm = (SameStoreForm: any) => {
    return this.http.put(
      `${environment.AppSameStoreForm}/update`,
      SameStoreForm
    );
  };
  getSameStoreFormBySameStoreFormId = (SameStoreFormId: any) => {
    return this.http.get<any>(
      `${environment.AppSameStoreForm}/get?SameStoreFormId=${SameStoreFormId}`
    );
  };
  getSameStoreFormList = (params: any) => {
    return this.http.get<any>(`${environment.AppSameStoreForm}/getlist`, {
      params,
    });
  };
  likeDislikeAdd = (model) => {
    return this.http.post<any>(
      `${environment.AppActionItems}/likeDislikeAdd`,
      model
    );
  };
  favouriteAdd = (model) => {
    return this.http.post<any>(
      `${environment.AppActionItems}/favouriteAdd`,
      model
    );
  };
  favouriteUpdate = (model) => {
    return this.http.put<any>(
      `${environment.AppActionItems}/favouriteUpdate`,
      model
    );
  };
  favouriteActivate = (model) => {
    return this.http.put<any>(
      `${environment.AppActionItems}/favouriteActivate`,
      model
    );
  };
  favouriteGetlist = (params: any) => {
    return this.http.get<any>(
      `${environment.AppActionItems}/favouriteGetlist`,
      { params }
    );
  };
  addChannelSourceWiseRow = (ChannelSourceWiseRow: any) => {
    return this.http.post<any>(
      `${environment.AppChannelSourceWise}/ChannelSourceWiseAdd`,
      ChannelSourceWiseRow
    );
  };
  updateChannelSourceWiseRow = (ChannelSourceWiseRow: any) => {
    return this.http.put(
      `${environment.AppChannelSourceWise}/update`,
      ChannelSourceWiseRow
    );
  };
  activateChannelSourceWiseRow = (ChannelSourceWiseRow: any) => {
    return this.http.put(
      `${environment.AppChannelSourceWise}/activate`,
      ChannelSourceWiseRow
    );
  };
  getChannelSourceWiseRowByChannelSourceWiseRowId = (
    ChannelSourceWiseRowId: any
  ) => {
    return this.http.get<any>(
      `${environment.AppChannelSourceWise}/get?ChannelSourceWiseRowId=${ChannelSourceWiseRowId}`
    );
  };
  getChannelSourceWiseRowList = (params: any) => {
    return this.http.get<any>(
      `${environment.AppChannelSourceWise}/ChannelSourceWiseGetlist`,
      { params }
    );
  };
  /* Source Mix Form CRUD Model */

  addChannelSourceWiseHeader = (ChannelSourceWiseHeader: any) => {
    return this.http.post<any>(
      `${environment.AppChannelSourceWise}/ChannelSourceWiseHeaderAdd`,
      ChannelSourceWiseHeader
    );
  };
  updateChannelSourceWiseHeader = (ChannelSourceWiseHeader: any) => {
    return this.http.put(
      `${environment.AppChannelSourceWise}/update`,
      ChannelSourceWiseHeader
    );
  };
  activateChannelSourceWiseHeader = (ChannelSourceWiseHeader: any) => {
    return this.http.put(
      `${environment.AppChannelSourceWise}/ChannelSourceWiseHeaderActivate`,
      ChannelSourceWiseHeader
    );
  };
  getChannelSourceWiseHeaderByChannelSourceWiseHeaderId = (
    ChannelSourceWiseHeaderId: any
  ) => {
    return this.http.get<any>(
      `${environment.AppChannelSourceWise}/get?ChannelSourceWiseHeaderId=${ChannelSourceWiseHeaderId}`
    );
  };
  getChannelSourceWiseHeaderList = (params: any) => {
    return this.http.get<any>(
      `${environment.AppChannelSourceWise}/ChannelSourceWiseHeaderGetlist`,
      { params }
    );
  };
  /* Category CRUD Model */

  addMenu = (Menu: any) => {
    return this.http.post<any>(`${environment.AppMenu}/add`, Menu);
  };

  addTabs = (Menu: any) => {
    return this.http.post<any>(`${environment.AppMenu}/ApplicationTabRoleInsertUpdate`, Menu);
  };

  ApplicationTabRoleGetByRoleId = (roleIds: any) => {
    return this.http.post<any>(`${environment.AppMenu}/ApplicationTabRoleGetByRoleId`, { roleIds });
  };

  updateMenu = (Menu: any) => {
    return this.http.put(`${environment.AppMenu}/update`, Menu);
  };
  activateMenu = (Menu: any) => {
    return this.http.put(`${environment.AppMenu}/activate`, Menu);
  };
  updateMenuOrder = (Menu: any) => {
    return this.http.put(`${environment.AppMenu}/UpdateOrder`, Menu);
  };
  searchMenu = (searchText: string) => {
    return this.http.get<any>(
      `${environment.AppMenu}/search?search=${searchText}`
    );
  };
  getMenu = (MenuId: string) => {
    return this.http.get<any>(`${environment.AppMenu}/get?MenuId=${MenuId}`);
  };
  getAllMenus = (params: any) => {
    return this.http.get<any>(`${environment.AppMenu}/getall`, { params });
  };

  getMenuList = (params: any) => {
    return this.http.get<any>(`${environment.AppMenu}/getlist`, { params });
  };

  getlistbyadmin = (params: any) => {
    return this.http.get<any>(`${environment.AppMenu}/getlistbyadmin`, { params });
  };

  getListByAdminTabRole = (params: any) => {
    return this.http.get<any>(`${environment.AppMenu}/ApplicationTabGetList`, { params });
  };

  GetRolesByMenuId = (menuId: string) => {
    return this.http.get<any>(`${environment.AppMenu}/GetRoleByMenuId?menuId=${menuId}`);
  };

  ScreenGetlist = (params: any) => {
    return this.http.get<any>(`${environment.AppMenu}/ScreenGetlist`, {
      params,
    });
  };

  TabGetList = (params: any) => {
    return this.http.get<any>(`${environment.AppMenu}/ApplicationTabGetList`, {
      params,
    });
  };
  GetAllActivity = (params: any) => {
    return this.http.get<any>(`${environment.AppActionItems}/AllActivity`, {
      params,
    });
  };
  GetMyActivity = (params: any) => {
    return this.http.get<any>(`${environment.AppActionItems}/MyActivity`, {
      params,
    });
  };
  // Algo Announcement

  public AnnouncementAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppAnnouncement}/add`, body);
  };
  public AnnouncementUpdate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppAnnouncement}/update`,
      body
    );
  };
  public AnnouncementActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppAnnouncement}/activate`,
      body
    );
  };
  AnnouncementGetAll(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppAnnouncement}/getAllAnnouncements`,
      { params }
    );
  }
  updateAnnouncementOrderNumber(body: any): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAnnouncement}/updateAnnouncementOrderNumber`,
      body
    );
  }
  // Algo User Interface
  public AddUserInterface = (body: any) => {
    return this.http.post<any>(
      `${environment.AppActionItems}/AddUserInterface`,
      body
    );
  };
  GetUserInterfaceActivityAll = (params: any) => {
    return this.http.get<any>(
      `${environment.AppActionItems}/GetUserInterfaceActivityAll`,
      { params }
    );
  };
  // Algo Announcement

  public NoteAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppNote}/add`, body);
  };
  public NoteUpdate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppNote}/update`, body);
  };
  public NoteActivate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppNote}/activate`, body);
  };
  NoteGetList(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppNote}/GetList`, { params });
  }
  NoteGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppNote}/Get`, { params });
  }
  GetBudgetList = (params: any) => {
    return this.http.get<any>(`${environment.AppAPBudgetType}/GetList`, {
      params,
    });
  };
  // Algo Promotion

  public PromotionAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppPromotion}/add`, body);
  };
  public PromotionUpdate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppPromotion}/update`, body);
  };
  public PromotionActivate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppPromotion}/activate`, body);
  };
  PromotionGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppPromotion}/getList`, {
      params,
    });
  }
  PromotionGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppPromotion}/get`, { params });
  }
  // Algo SKU

  public SKUAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppSKU}/add`, body);
  };
  public SKUUpdate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppSKU}/update`, body);
  };
  public SKUActivate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppSKU}/activate`, body);
  };
  SKUGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppSKU}/getList`, { params });
  }
  SKUGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppSKU}/get`, { params });
  }
  // Algo SKU

  public SKUPromotionAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppSKUPromotion}/add`, body);
  };
  public SKUPromotionUpdate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppSKUPromotion}/update`,
      body
    );
  };
  public SKUPromotionActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppSKUPromotion}/activate`,
      body
    );
  };
  SKUPromotionGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppSKUPromotion}/getList`, {
      params,
    });
  }
  SKUPromotionGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppSKUPromotion}/get`, { params });
  }
  // Algo Decay Curve

  public DecayCurveAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppDecayCurve}/add`, body);
  };
  public DecayCurveUpdate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppDecayCurve}/update`, body);
  };
  public DecayCurveActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppDecayCurve}/activate`,
      body
    );
  };
  DecayCurveGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppDecayCurve}/getList`, {
      params,
    });
  }
  DecayCurveGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppDecayCurve}/get`, { params });
  }
  // Algo Item Master

  public ItemMasterAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppItemMaster}/add`, body);
  };
  public ItemMasterUpdate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppItemMaster}/update`, body);
  };
  public ItemMasterActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppItemMaster}/activate`,
      body
    );
  };
  ItemMasterGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppItemMaster}/getList`, {
      params,
    });
  }
  ItemMasterGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppItemMaster}/get`, { params });
  }
  public UploadDoc = (body: any) => {
    return this.http.post<any>(`${environment.UploadDoc}`, body);
  };
  public findAnomoly = (body: any) => {
    return this.http.post<any>(`${environment.Anomoly}`, body);
  };
  oldAnomolyFiles(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AnomolyFiles}`, { params });
  }
  syncPoweBIReports(): Observable<any> {
    return this.http.get<any>(`${environment.SyncPowerBIReports}`);
  }

  // Algo App Insight

  public ApplicationInsightsAdd = (body: any) => {
    return this.http.post<number>(
      `${environment.ApplicationInsights}/add`,
      body
    );
  };
  public ApplicationInsightsUpdate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.ApplicationInsights}/update`,
      body
    );
  };
  public ApplicationInsightsActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.ApplicationInsights}/activate`,
      body
    );
  };
  ApplicationInsightsGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.ApplicationInsights}/getList`, {
      params,
    });
  }
  ApplicationInsightsGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.ApplicationInsights}/get`, {
      params,
    });
  }
  ApplicationInsightsPublish(params: any): Observable<any> {
    return this.http.put<any>(
      `${environment.ApplicationInsights}/publish`,
      params
    );
  }
  ApplicationInsightDelete(params: any): Observable<any> {
    return this.http.put<any>(
      `${environment.ApplicationInsights}/delete`,
      params
    );
  }
  UploadFiles(model) {
    return this.http.post<number>(
      `${environment.AppDocument}/UploadFiles`,
      model
    );
  }
  LookupGetByCode(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppCommonCode}/getList?code=${params}`
    );
  }
  importExcel = (fileName: string) => {
    return this.http.get(`assets/${fileName}.xlsx`, { responseType: 'blob' });
  };
  // Algo App Insight

  public SuggestAdminAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppSuggestAdmin}/add`, body);
  };
  public SuggestAdminUpdate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppSuggestAdmin}/update`,
      body
    );
  };
  public SuggestAdminUpdateStatus = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppSuggestAdmin}/updatestatus`,
      body
    );
  };
  public SuggestAdminActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppSuggestAdmin}/activate`,
      body
    );
  };
  SuggestAdminGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppSuggestAdmin}/getList`, {
      params,
    });
  }
  SuggestAdminGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppSuggestAdmin}/get`, { params });
  }
  SuggestionStatusGetList(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppSuggestAdmin}/SuggestionStatusGetList`,
      { params }
    );
  }

  public CurrencyAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppCurrency}/add`, body);
  };
  public CurrencyUpdate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppCurrency}/update`, body);
  };
  public CurrencyActivate = (body: any) => {
    return this.http.put<boolean>(`${environment.AppCurrency}/activate`, body);
  };
  CurrencyGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppCurrency}/getList`, { params });
  }
  public CurrencyRateAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppCurrencyRate}/add`, body);
  };
  CurrencyRateGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppCurrencyRate}/getList`, {
      params,
    });
  }
  CustomerGetList(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppBuyerSetting}/CustomerGetList`,
      { params }
    );
  }
  ChannelGetList(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppBuyerSetting}/ChannelGetList`, {
      params,
    });
  }
  CountryGetList(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppAccountSettingMapping}/CountryGetList`,
      { params }
    );
  }
  BuyerSettingGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppBuyerSetting}/Get`, { params });
  }
  BuyerSettingAdd(model: any): Observable<any> {
    return this.http.post<any>(`${environment.AppBuyerSetting}/Add`, model);
  }
  BuyerSettingUpdate(model: any): Observable<any> {
    return this.http.put<any>(`${environment.AppBuyerSetting}/Update`, model);
  }
  public FinanceAdd = (body: any) => {
    return this.http.post<number>(`${environment.AppFinanceSetting}/add`, body);
  };
  public FinanceUpdate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppFinanceSetting}/update`,
      body
    );
  };
  public FinanceActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppFinanceSetting}/activate`,
      body
    );
  };
  FinanceGetAll(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppFinanceSetting}/getList`, {
      params,
    });
  }
  TeamFinanceGetList(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppFinanceSetting}/getFinancialSettingTeam`,
      { params }
    );
  }
  AddFinancialSettingTeam(model: any): Observable<any> {
    return this.http.post<any>(
      `${environment.AppFinanceSetting}/addFinancialSettingTeam`,
      model
    );
  }
  TeamCurrencyGetList(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppCurrency}/getCurrencySettingTeam`,
      { params }
    );
  }
  AddCurrencySettingTeam(model: any): Observable<any> {
    return this.http.post<any>(
      `${environment.AppCurrency}/addCurrencySettingTeam`,
      model
    );
  }
  GetItemList(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppProductSetting}/getItemList`, {
      params,
    });
  }
  AddProductSetting(model: any): Observable<any> {
    return this.http.post<any>(`${environment.AppProductSetting}/add`, model);
  }
  UpdateProductSetting(model: any): Observable<any> {
    return this.http.put<any>(`${environment.AppProductSetting}/update`, model);
  }
  GetProductSetting(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppProductSetting}/get`, {
      params,
    });
  }
  measuresGetList(params: any): Observable<Measure[]> {
    return this.http.get<Measure[]>(
      `${environment.AppFBForcast}/measuresGetList`,
      { params }
    );
  }
  channelGetList(params: any): Observable<FbChannel[]> {
    return this.http.get<FbChannel[]>(
      `${environment.AppFBForcast}/channelGetList`,
      { params }
    );
  }
  calendarGetList(params: any): Observable<Calendar[]> {
    return this.http.get<Calendar[]>(
      `${environment.AppFBForcast}/calendarGetList`,
      { params }
    );
  }
  versionTypeGetList(params: any): Observable<Version[]> {
    return this.http.get<Version[]>(
      `${environment.AppFBForcast}/versionTypeGetList`,
      { params }
    );
  }
  userFBForecastGetList(params: any): Observable<UserFbForecast[]> {
    return this.http.get<UserFbForecast[]>(
      `${environment.AppFBForcast}/userFBForecastGetList`,
      { params }
    );
  }
  userFBForecastDeleteByVersionID(fbForecast: any) {
    return this.http.post<boolean>(
      `${environment.AppFBForcast}/UserFBForecastDeleteByVersionID`,
      fbForecast
    );
  }
  GetFbForecast(params: any): Observable<FbForecast> {
    return this.http.post<FbForecast>(
      `${environment.AppFBForcast}/fbForecastGet`,
      params
    );
  }
  GetUserSession(params: any): Observable<FbForecast> {
    return this.http.post<FbForecast>(
      `${environment.AppFBForcast}/fBForecastGetUserSession`,
      params
    );
  }
  AddFbForecast(FbForecast: FbForecast): Observable<FbForecast> {
    return this.http.post<FbForecast>(
      `${environment.AppFBForcast}/fbForecastAdd`,
      FbForecast
    );
  }
  fbForecastAddPerformance(FbForecast: FbForecast): Observable<FbForecast> {
    return this.http.post<FbForecast>(
      `${environment.AppFBForcast}/fbForecastAddPerformance`,
      FbForecast
    );
  }
  FBForecastSelectedUpdateByDropDownType(model): Observable<FbForecast> {
    return this.http.post<FbForecast>(
      `${environment.AppFBForcast}/FBForecastSelectedUpdateByDropDownType`,
      model
    );
  }
  FBForecastCommit(FbForecast: any): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.AppFBForcast}/FBForecastCommit`,
      FbForecast
    );
  }
  userFBForecastAdd(FbForecast: any): Observable<number> {
    return this.http.post<number>(
      `${environment.AppFBForcast}/userFBForecastAdd`,
      FbForecast
    );
  }
  userFBForecastUpdate(FbForecast): Observable<number> {
    return this.http.put<number>(
      `${environment.AppFBForcast}/userFBForecastUpdate`,
      FbForecast
    );
  }
  userFBForecastRefreshMeasure(params) {
    return this.http.post<any>(
      `${environment.AppFBForcast}/fbForecastMasterRefreshGet`,
      params
    );
  }
  DefaultAddRefresh(params) {
    return this.http.post<any>(
      `${environment.AppFBSpreading}/DefaultAddRefresh`,
      params
    );
  }
  fbForecastGetPerformance(params) {
    return this.http.post<any>(
      `${environment.AppFBForcast}/fbForecastGetPerformance`,
      params
    );
  }
  fbForecastGetPerformanceRI(params) {
    return this.http.post<any>(
      `${environment.AppFBForcast}/fbForecastGetPerformanceRI`,
      params
    );
  }

  // Item Actions

  public ItemManagerAdd = (body: any) => {
    return this.http.post<number>(
      `${environment.AppItemManager}/ItemManagerAdd`,
      body
    );
  };
  public ItemManagerUpdate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppItemManager}/ItemManagerUpdate`,
      body
    );
  };
  public ItemManagerActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppItemManager}/ItemManagerActivate`,
      body
    );
  };
  ItemManagerGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppItemManager}/ItemManagerGet`, {
      params,
    });
  }
  ItemManagerGetAll(url: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppItemManager}/ItemManagerGetAll?${url}`
    );
  }
  OrderListGetAll(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.OrderList}/MSOrderListGetAll`, { params }
    );
  }
  public ItemAccountAdd = (body: any) => {
    return this.http.post<number>(
      `${environment.AppItemManager}/ItemAccountAdd`,
      body
    );
  };
  public ItemAccountUpdate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppItemManager}/ItemAccountUpdate`,
      body
    );
  };
  public ItemAccountActivate = (body: any) => {
    return this.http.put<boolean>(
      `${environment.AppItemManager}/ItemAccountActivate`,
      body
    );
  };
  ItemAccountGet(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppItemManager}/ItemAccountGet`, {
      params,
    });
  }
  ItemAccountGetAll(url: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppItemManager}/ItemAccountGetAll?${url}`
    );
  }
  ItemAccountGetList(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppItemManager}/ItemAccountGetList`,
      { params }
    );
  }
  public ItemAccountSettingAdd = (body: any) => {
    return this.http.post<any>(
      `${environment.AppItemManager}/ItemAccountSettingAdd`,
      body
    );
  };
  public ItemAccountSettingDelete = (body: any) => {
    return this.http.post<any>(
      `${environment.AppItemManager}/ItemAccountSettingDeleteByIds`,
      body
    );
  };
  public AccountSettingUpdate = (body: any) => {
    return this.http.post<any>(
      `${environment.AppItemManager}/AccountSettingUpdate`,
      body
    );
  };
  public AccountSettingListUpdate = (body: any) => {
    return this.http.post<any>(
      `${environment.AppItemManager}/AccountSettingListUpdate`,
      body
    );
  };
  public GetNIPCalculation = (body: any) => {
    return this.http.post<any>(
      `${environment.AppItemManager}/GetNIPCalculation`,
      body
    );
  };
  ItemAccountSettingGet(params: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppItemManager}/ItemAccountSettingGet`,
      { params }
    );
  }
  GetItemSettings(params: any): Observable<any> {
    return this.http.get<any>(`${environment.AppItemManager}/GetItemSettings`, {
      params,
    });
  }
  AccountsListGetByItem(model: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppItemManager}/AccountsListGetByItem?itemManagerId=${model}`
    );
  }
  GetLookupByCode(code: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppCommonCode}/GetLookupByCode?LookupTableCode=${code}`
    );
  }
  GetLookupList(code: any): Observable<any> {
    return this.http.get<any>(
      `${environment.AppCommonCode}/GetList?Code=${code}`
    );
  }
  GetRegionList(): Observable<any> {
    return this.http.get<any>(`${environment.AppItemManager}/RegionGetList`);
  }

  public DocumentAdd = (body: any) => {
    return this.http.post<any>(
      `${environment.AppCommonCode}/DocumentAdd`,
      body
    );
  };
  TeamScreenGroupGetList(params): Observable<any> {
    return this.http.get<any>(`${environment.AppTeamScreenGroup}/GetList`, {
      params,
    });
  }
  TeamScreenGroupAdd(body): Observable<any> {
    return this.http.post<any>(`${environment.AppTeamScreenGroup}/Add`, body);
  }
  // Item forecast

  itemMeasuresGetList(params: any): Observable<Measure[]> {
    return this.http.get<Measure[]>(
      `${environment.AppItemForcast}/measuresGetList`,
      { params }
    );
  }
  itemChannelGetList(params: any): Observable<FbChannel[]> {
    return this.http.get<FbChannel[]>(
      `${environment.AppItemForcast}/channelGetList`,
      { params }
    );
  }
  itemCalendarGetList(params: any): Observable<Calendar[]> {
    return this.http.get<Calendar[]>(
      `${environment.AppItemForcast}/calendarGetList`,
      { params }
    );
  }
  itemVersionTypeGetList(params: any): Observable<Version[]> {
    return this.http.get<Version[]>(
      `${environment.AppItemForcast}/versionTypeGetList`,
      { params }
    );
  }
  userItemForecastGetList(params: any): Observable<UserFbForecast[]> {
    return this.http.get<UserFbForecast[]>(
      `${environment.AppItemForcast}/userItemForecastGetList`,
      { params }
    );
  }
  userItemForecastDeleteByVersionID(fbForecast: any) {
    return this.http.post<boolean>(
      `${environment.AppItemForcast}/UserItemForecastDeleteByVersionID`,
      fbForecast
    );
  }
  GetItemForecast(params: any): Observable<FbForecast> {
    return this.http.get<FbForecast>(
      `${environment.AppItemForcast}/ItemForecastGet`,
      { params }
    );
  }
  AddItemForecast(FbForecast): Observable<FbForecast> {
    return this.http.post<FbForecast>(
      `${environment.AppItemForcast}/ItemForecastAdd`,
      FbForecast
    );
  }
  ItemForecastCommit(FbForecast: any): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.AppItemForcast}/ItemForecastCommit`,
      FbForecast
    );
  }
  userItemForecastAdd(FbForecast: any): Observable<number> {
    return this.http.post<number>(
      `${environment.AppItemForcast}/userItemForecastAdd`,
      FbForecast
    );
  }
  userItemForecastUpdate(FbForecast): Observable<number> {
    return this.http.put<number>(
      `${environment.AppItemForcast}/userItemForecastUpdate`,
      FbForecast
    );
  }
  AccountSettingMappingGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppAccountSettingMapping}/GetList`,
      { params }
    );
  }
  AccountSettingMappingAddChannel(body): Observable<any> {
    return this.http.post<any>(
      `${environment.AppAccountSettingMapping}/AddChannel`,
      body
    );
  }
  AccountSettingMappingAddContinent(body): Observable<any> {
    return this.http.post<any>(
      `${environment.AppAccountSettingMapping}/AddContinent`,
      body
    );
  }
  AccountSettingMappingAddCountry(body): Observable<any> {
    return this.http.post<any>(
      `${environment.AppAccountSettingMapping}/AddCountry`,
      body
    );
  }
  AccountSettingMappingAddDistributor(body): Observable<any> {
    return this.http.post<any>(
      `${environment.AppAccountSettingMapping}/AddDistributor`,
      body
    );
  }
  AccountSettingMappingUpdateChannel(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAccountSettingMapping}/UpdateChannel`,
      body
    );
  }
  AccountSettingMappingUpdateContinent(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAccountSettingMapping}/UpdateContinent`,
      body
    );
  }
  AccountSettingMappingUpdateCountry(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAccountSettingMapping}/UpdateCountry`,
      body
    );
  }
  AccountSettingMappingUpdateDistributor(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAccountSettingMapping}/UpdateDistributor`,
      body
    );
  }
  AccountSettingMappingActivateChannel(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAccountSettingMapping}/ActivateChannel`,
      body
    );
  }
  AccountSettingMappingActivateContinent(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAccountSettingMapping}/ActivateContinent`,
      body
    );
  }
  AccountSettingMappingActivateCountry(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAccountSettingMapping}/ActivateCountry`,
      body
    );
  }
  AccountSettingMappingActivateDistributor(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppAccountSettingMapping}/ActivateDistributor`,
      body
    );
  }
  ProductSettingMappingGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppProductSettingMapping}/GetList`,
      { params }
    );
  }
  ProductSettingMappingAddBrand(body): Observable<any> {
    return this.http.post<any>(
      `${environment.AppProductSettingMapping}/AddBrand`,
      body
    );
  }
  ProductSettingMappingAddProductGroup(body): Observable<any> {
    return this.http.post<any>(
      `${environment.AppProductSettingMapping}/AddProductGroup`,
      body
    );
  }
  ProductSettingMappingAddProductFamily(body): Observable<any> {
    return this.http.post<any>(
      `${environment.AppProductSettingMapping}/AddProductFamily`,
      body
    );
  }
  ProductSettingMappingAddParentProduct(body): Observable<any> {
    return this.http.post<any>(
      `${environment.AppProductSettingMapping}/AddParentProduct`,
      body
    );
  }
  ProductSettingMappingUpdateBrand(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppProductSettingMapping}/UpdateBrand`,
      body
    );
  }
  ProductSettingMappingUpdateProductGroup(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppProductSettingMapping}/UpdateProductGroup`,
      body
    );
  }
  ProductSettingMappingUpdateProductFamily(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppProductSettingMapping}/UpdateProductFamily`,
      body
    );
  }
  ProductSettingMappingUpdateParentProduct(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppProductSettingMapping}/UpdateParentProduct`,
      body
    );
  }
  ProductSettingMappingActivateBrand(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppProductSettingMapping}/ActivateBrand`,
      body
    );
  }
  ProductSettingMappingActivateProductGroup(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppProductSettingMapping}/ActivateProductGroup`,
      body
    );
  }
  ProductSettingMappingActivateProductFamily(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppProductSettingMapping}/ActivateProductFamily`,
      body
    );
  }
  ProductSettingMappingActivateParentProduct(body): Observable<any> {
    return this.http.put<any>(
      `${environment.AppProductSettingMapping}/ActivateParentProduct`,
      body
    );
  }

  AccountSettingMappingChannelGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppAccountSettingMapping}/ChannelsGetList`,
      { params }
    );
  }
  AccountSettingMappingContinentGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppAccountSettingMapping}/ContinentsGetList`,
      { params }
    );
  }
  AccountSettingMappingCountryGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppAccountSettingMapping}/CountrysGetList`,
      { params }
    );
  }
  AccountSettingMappingDistributorGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppAccountSettingMapping}/DistributorGetListByCountryId`,
      { params }
    );
  }
  ProductSettingMappingBrandGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppProductSettingMapping}/getlistBrand`,
      { params }
    );
  }
  ProductSettingMappingProductGroupGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppProductSettingMapping}/getlistProductGroup`,
      { params }
    );
  }
  ProductSettingMappingProductFamilyGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppProductSettingMapping}/getlistProductFamily`,
      { params }
    );
  }
  ProductSettingMappingParentProductGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppProductSettingMapping}/getlistParentProduct`,
      { params }
    );
  }
  FbForecastGetMeasureProfileList(params): Observable<any> {
    return this.http.get<any>(`${environment.FbForecastProfile}/getlist`, {
      params,
    });
  }
  MsForecastGetMeasureOrderList(params): Observable<any> {
    return this.http.get<any>(`${environment.MsForecastProfile}/getlist`, {
      params,
    });
  }
  FbForecastSaveMeasureProfile(params): Observable<any> {
    return this.http.post<any>(`${environment.FbForecastProfile}/add`, params);
  }
  FbForecastActivateMeasureProfile(params): Observable<any> {
    return this.http.put<any>(
      `${environment.FbForecastProfile}/activate`,
      params
    );
  }
  FbForecastApplyMeasureProfile(params): Observable<any> {
    return this.http.put<any>(
      `${environment.FbForecastProfile}/Applied`,
      params
    );
  }
  MsForecastApplyMeasureOrderList(params): Observable<any> {
    return this.http.put<any>(
      `${environment.MsForecastProfile}/Applied`,
      params
    );
  }
  FbForecastMasterRefreshGet(params): Observable<any> {
    return this.http.post<any>(
      `${environment.AppFBForcast}/fbForecastMasterRefreshGet`,
      params
    );
  }
  FbForecastUpdateMeasureProfile(params): Observable<any> {
    return this.http.put<any>(
      `${environment.FbForecastProfile}/update`,
      params
    );
  }
  MsForecastUpdateMeasureOrderList(params): Observable<any> {
    return this.http.put<any>(
      `${environment.MsForecastProfile}/update`,
      params
    );
  }
  // Promotion Planner
  PromotionPlannerGetAll(params): Observable<any> {
    return this.http.get<any>(`${environment.PromotionPlanner}/getall`, {
      params,
    });
  }
  PromotionPlannerGetAdd(params): Observable<any> {
    return this.http.post<any>(`${environment.PromotionPlanner}/add`, params);
  }
  PromotionPlannerGetUpdate(params): Observable<any> {
    return this.http.put<any>(`${environment.PromotionPlanner}/update`, params);
  }
  PromotionPlannerGet(params): Observable<any> {
    return this.http.get<any>(`${environment.PromotionPlanner}/get`, {
      params,
    });
  }
  DataManagerMeasuresGetList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppFBForcast}/dataManagerMeasuresGetList`,
      {
        params,
      }
    );
  }
  GetMethodManagerValues(params): Observable<any> {
    return this.http.get<any>(
      `${environment.AppFBForcast}/FbMeasureMasterGetByForecastMapping`,
      {
        params,
      }
    );
  }
  FbMeasureMasterGetByForecastMappingPerformance(params): Observable<any> {
    return this.http.post<any>(
      `${environment.AppFBForcast}/FbMeasureMasterGetByForecastMappingPerformance`,
      params
    );
  }
  FbForecastExport(params): Observable<any> {
    return this.http.post(`${environment.AppFBForcast}/export`, params, {
      observe: 'response',
      responseType: 'blob',
    });
  }
  FbForecastSpreadingAdd(params): Observable<any> {
    return this.http.post(`${environment.AppFBSpreading}/SpreadingAdd`, params);
  }
  FbForecastSpreadingAddList(params): Observable<any> {
    return this.http.post(`${environment.AppFBSpreading}/spreadingAddList`, params);
  }
  FbForecastSpreadingAddDisti(params): Observable<any> {
    return this.http.post(`${environment.AppFBSpreading}/SpreadingAddDisti`, params);
  }
  FbForecastDefaultAdd(params): Observable<any> {
    return this.http.post(
      `${environment.AppFBForcast}/fbForecastDefaultAdd`,
      params
    );
  }
  fbForecastDefaultDelete(params): Observable<any> {
    return this.http.post(
      `${environment.AppFBForcast}/fbForecastDefaultDelete`,
      params
    );
  }
  FbForecastPlannerCopyMeasure(params): Observable<any> {
    return this.http.post(
      `${environment.AppFBForcast}/PlannerCopyMeasure`,
      params
    );
  }
  createJourney = (body: any) => {
    return this.http.post<any>(`${environment.AppJourney}/CreateJourney`, body);
  };
  updateJourney = (body: any) => {
    return this.http.post<any>(`${environment.AppJourney}/UpdateJourney`, body);
  };
  createContextLog = (body: any) => {
    return this.http.post<any>(`${environment.AppJourney}/CreateContextLog`, body);
  };
  getJourneyComments(data: number) {
    return this.http.get<any>(`${environment.AppJourney}/GetJourneyComments?JourneyId=${data}`);
  }
  setJourneyComments(body: any) {
    return this.http.post<any>(`${environment.AppJourney}/CreateJourneyComments`, body);
  }
  createJourneyCollaborations(body: any) {
    return this.http.post<any>(`${environment.AppJourney}/CreateJourneyCollaborations`, body);
  }
  updateJourneyCollaborations(body: any) {
    return this.http.post<any>(`${environment.AppJourney}/UpdateJourneyCollaborations`, body);
  }
  getJourneyCollaboration(SharedBy: number = null, sharedWith: number = null, journeyId: number = null) {
    if (SharedBy) {
      return this.http.get<any>(`${environment.AppJourney}/GetJourneyCollaboration?SharedBy=${SharedBy}`);
    } else if (sharedWith) {
      return this.http.get<any>(`${environment.AppJourney}/GetJourneyCollaboration?SharedWith=${sharedWith}`);
    } else if (journeyId) {
      return this.http.get<any>(`${environment.AppJourney}/GetJourneyCollaboration?JourneyId=${journeyId}`);
    }
  }
  UpdateContextLog = (body: any) => {
    return this.http.put<any>(`${environment.AppJourney}/UpdateContextLog`, body);
  };
  GetJourneyByUserId(CreatedBy: number) {
    return this.http.get<any>(`${environment.AppJourney}/GetJourneyByUserId?CreatedBy=${CreatedBy}`);
  }
  GetAllJourneys() {
    return this.http.get<any>(`${environment.AppJourney}/GetAllJourneys`);
  }
  DeleteJourney(journey: any) {
    return this.http.post<any>(`${environment.AppJourney}/DeleteJourney`, journey);
  }
  GetContextLog(journeyId: number) {
    return this.http.get<any>(`${environment.AppJourney}/GetContextLog?JourneyId=${journeyId}`);
  }
  DeleteJourneyCollaboration(journeyId: number, sharedWith: number) {
    return this.http.delete<any>(`${environment.AppJourney}/DeleteJourneyCollaboration?JourneyId=${journeyId}&SharedWith=${sharedWith}`);
  }
  GetNotViewedJourneys(userId: number) {
    return this.http.get<any>(`${environment.AppJourney}/GetNotViewedJourneys?SharedWith=${userId}`);
  }
  UpdateJourneyViewedAt = (body: any) => {
    return this.http.put<any>(`${environment.AppJourney}/UpdateJourneyViewedAt`, body);
  };
  DeleteReportByGroupId(groupId: string, reportId: string) {
    return this.http.delete<any>(`${environment.AppPowerBi}/DeleteReportByGroupId?GroupId=${groupId}&ReportId=${reportId}`);
  }
  DeleteContextLog = (contextLog: any) => {
    return this.http.post<any>(`${environment.AppJourney}/DeleteContextLog`, contextLog);
  };
  GetJourneyUserActivityLog() {
    return this.http.get<any>(`${environment.AppJourney}/GetJourneyUserActivityLog`);
  }
  GetJourneyContextLogComments(id: number) {
    return this.http.get<any>(`${environment.AppJourney}/JourneyContextLogCommentsGetAll?journeyContextLogId=${id}`);
  };
  CreateJourneyContextLogComments(body: any) {
    return this.http.post<any>(`${environment.AppJourney}/CreateJourneyContextLogComments`, body);
  };
  GetJourneyOppertunityTypes() {
    return this.http.get<any>(`${environment.AppJourney}/JourneyLookupGetAll`);
  }
}
