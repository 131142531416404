<!-- Main Content area -->
<div class="container-fluid">

  <div style="display: flex; justify-content: flex-end; margin-top: 20px; margin-bottom: 10px;">
    <div class="item-wrapper w-25">
      <div class="form-group mr-10">
        <label for="brands">Select Role</label>
        <mat-select (selectionChange)="applyRoleFilter($event)">
          <mat-option value="clear">Clear Selection</mat-option>
          <mat-option *ngFor="let role of roleList" [value]="role.roleId">
            {{role.roleName}}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <button class="btn btn-primary mat-raised-button mat-button-base" (click)="syncPowerBIReports()">Sync Power
      BI</button>
  </div>
  <div class="action-panel text-right top">
    <h2 class="form-heading">Menu Configuration</h2>
    <!-- <button mat-raised-button class="btn btn-primary m-left" color="primary"
      (click)="openCategoryDialog({}, menuCreateTemplate,isEdit)">{{isEdit ? 'Update' : 'Add New Menu'}} <i
        class="icon-add"></i></button> -->
    <div class="sub-menu-icon" (click)="openCategoryDialog({}, menuCreateTemplate,isEdit)"><img
        src="../../assets/images/plus.png" alt=""></div>
  </div>
  <ejs-treeview id="tree" #tree [fields]="field" (nodeDragStop)="dragStop($event)" (nodeDragging)="nodeDrag($event)"
    [allowDragAndDrop]="true" [allowEditing]="allowEditing" (nodeEditing)='editing($event)'
    (nodeEdited)='onNodeEdited($event)'>
    <ng-template #nodeTemplate="" let-data="">
      <div>
        <div style="pointer-events: all;" class="action-panel">
          <p class="m-0"> {{data.name}} ({{removeSpaces(data.orignalName)}})</p>
          <div class="menu">
            <div [id]="data.menuId" class="menu-toggle menu-hide"
              (click)="clickEvent(data.menuId);data.status=  !data.status">
              <i class="icon-more-vertical"></i>
            </div>
            <div class="sub-menu" [ngClass]="data.status ? 'active' : 'inactive'">
              <div class="sub-menu-icon" (click)="openConfirmationDialog(data)"><img src="../../assets/images/bin.svg"
                  alt=""></div>
              <div class="sub-menu-icon" (click)="openCategoryDialog(data,menuCreateTemplate, true)"><img
                  src="../../assets/images/edit.svg" alt=""></div>
              <div class="sub-menu-icon" (click)="openCategoryDialog(data,menuCreateTemplate, false)"><img
                  src="../../assets/images/plus.png" alt=""></div>
            </div>
          </div>
          <!-- <div class="internal-info">
            <button mat-raised-button class="btn btn-primary"
              (click)="openCategoryDialog(data,menuCreateTemplate, false)">
              <span>Sub Menu</span>
              <i class="icon-add"></i>
            </button>
            <button style="width: 40px; margin-left: 10px;" class="btn btn-secondary" color="primary" mat-icon-button>
              <mat-icon (click)="openCategoryDialog(data,menuCreateTemplate, true)"><i style="cursor: pointer;"
                  class="material-icons">edit</i>
              </mat-icon>
            </button>
            <button style="width: 40px; margin-left: 10px;" class="btn btn-secondary" color="primary" mat-icon-button>
              <mat-icon (click)="openConfirmationDialog(data)"><i style="cursor: pointer;"
                  class="material-icons">clear</i>
              </mat-icon>
            </button>
          </div> -->
        </div>
      </div>
    </ng-template>
  </ejs-treeview>
</div>
<ng-template #menuCreateTemplate let-data>
  <div mat-dialog-content>
    <form [formGroup]="registerForm" class="form-fields">
      <div class="row">
        <div class="col-md-4 form-group">
          <mat-form-field>
            <input matInput formControlName="name" placeholder="Enter Menu Name">
          </mat-form-field>
          <mat-error *ngIf="submitted && registerForm.controls['name'].hasError('required')">
            *Required Field
          </mat-error>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <input matInput formControlName="germanName" placeholder="Enter Menu Name (DE)">
          </mat-form-field>
          <mat-error *ngIf="submitted && registerForm.controls['germanName'].hasError('required')">
            *Required Field
          </mat-error>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <input matInput formControlName="chineseName" placeholder="Enter Menu Name (ZH)">
          </mat-form-field>
          <mat-error *ngIf="submitted && registerForm.controls['chineseName'].hasError('required')">
            *Required Field
          </mat-error>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <input matInput formControlName="content" placeholder="Enter Content">
          </mat-form-field>
          <mat-error *ngIf="submitted && registerForm.controls['content'].hasError('required')">
            *Required Field
          </mat-error>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <input matInput formControlName="description" placeholder="Enter Description">
          </mat-form-field>
          <mat-error *ngIf="submitted && registerForm.controls['description'].hasError('required')">
            *Required Field
          </mat-error>
        </div>

        <div class="col-md-4 form-group">
          <mat-form-field class="example-full-width" floatLabel="never">
            <input [style.font-size]="'18px'" matInput placeholder="Select Screen" aria-label="Search"
              [matAutocomplete]="auto" (input)="_filterScreen($event)" formControlName="orignalName">
            <mat-autocomplete [displayWith]="displayFn" #auto="matAutocomplete"
              (optionSelected)="submitSearch($event.option.value)">
              <mat-option *ngFor="let page of filteredMenus | async; let i=index" [value]="page">
                {{page.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-md-4 form-group">
          <mat-form-field>
            <mat-label>Assign Roles</mat-label>
            <mat-select multiple formControlName="roleIds">
              <mat-option *ngFor="let role of roleList" [value]="role.roleId">{{ role.roleName }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="submitted && registerForm.controls['roleIds'].hasError('required')">
            *Required Field
          </mat-error>
        </div>
        <div class="col-md-2 form-group">
          <mat-slide-toggle formControlName="isGlobal">Is Public</mat-slide-toggle>
        </div>

      </div>
      <div class="action-panel text-right">
        <button mat-raised-button class="btn btn-primary m-left" type="submit" (click)="onSubmit(data)"
          color="primary">Save</button>
        <button mat-raised-button class="btn m-left btn-light" color="warn"
          (click)="dialogAddRef.close()">Cancel</button>
      </div>
    </form>
  </div>
</ng-template>