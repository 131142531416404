<h1 mat-dialog-title>Login History</h1>
<div mat-dialog-content>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"
       [suppressMenuHide]="true"  groupHeaders suppressRowClickSelection
      toolPanelSuppressGroups toolPanelSuppressValues
      [columnDefs]="columnDefs" [rowData]="loginHistoryList" (gridReady)="onGridReady($event)"
       #grid></ag-grid-angular>
  </div>
</div>
<div class="action-panel text-right">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="dialogRef.close()">Ok</button>
</div>
