
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'manage-outlier-renderer',
  template: `<button mat-raised-button color="accent" *ngIf="showButton" (click)="onClick($event)">Manage Outliers</button>
 <span *ngIf="!showButton">{{label}}</span>`
})

export class ManageOutlierButtonRendererComponent implements ICellRendererAngularComp {

  params;
  showButton = false;
  label = '';

  agInit(params): void {
    this.params = params;
    if (!this.params.value) {
      if (isNaN(this.params.value))
        this.label = '-';
      else {
        this.label = this.params.value;
      }
    } else {
      if (typeof this.params.value === 'object') {
        this.showButton = true;
      } else {
        if (isNaN(this.params.value))
          this.label = '-';
        else {
          this.label = this.params.value;
        }
      }
    }
    if (!this.params.colDef.isMetricVariable) {
      this.label = '-';
      this.showButton = false;
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      };
      this.params.onClick(params);
    }
  }
}
