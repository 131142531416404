<div class="container-fluid" style="margin-top: 15px;">
    <!-- TABS ~ Start -->
    <mat-tab-group [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged()">
        <mat-tab label="Settings">
            <app-settings-segmentaion (runSegmentationEvent)="runSegmentationHandler($event)"></app-settings-segmentaion>
        </mat-tab>
        <mat-tab label="Output" *ngIf="outputTabData">
            <app-output-segmentaion [resizeGraphSubject]="resizeGraphSubject" [sampleData]="outputTabData" (saveRunOutput)="outputTabRunBtnHandler()"></app-output-segmentaion>
        </mat-tab>
        <mat-tab *ngFor="let tab of runTabs; let i=index" label="{{tab.name}}">
            <ng-template mat-tab-label>
                <div class="tab-label-div">
                    {{tab.name}}
                    <mat-icon style="right: 0;" matTooltip="Edit Name" color="primary" (click)="editTabName(tab, $event)">edit</mat-icon>
                    <mat-icon matTooltip="Remove Run" color="accent" (click)="removeTab(i, tab.uu_id, $event)">highlight_off</mat-icon>
                </div>
            </ng-template>
            <app-run-segmentaion [sampleData]="tab" [resizeGraphSubject]="resizeGraphSubject"></app-run-segmentaion>
        </mat-tab>
        <mat-tab *ngIf="runTabs.length" label="Comparison">
            <app-comparison-segmentaion [runTabs]="runTabs" [sampleData]="outputTabData" [resizeGraphSubject]="resizeGraphSubject"></app-comparison-segmentaion>
        </mat-tab>
    </mat-tab-group>
    <!-- TABS ~ End -->
</div>
