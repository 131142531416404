import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-three-state-custom-toggle-button',
  templateUrl: './three-state-custom-toggle-button.component.html',
  styleUrls: ['./three-state-custom-toggle-button.component.scss']
})
export class ThreeStateCustomToggleButtonComponent implements OnInit, OnChanges {

  @Input() disabled: boolean = false;
  @Input() toggleValue:any = null;
  @Output() toggleChanged = new EventEmitter();

  constructor(private changeDetectionRef: ChangeDetectorRef) { }
  ngOnInit(): void {
  }

  ngOnChanges() {
    this.changeDetectionRef.detectChanges();
  }

  triggerOutput(event) {
    if (this.disabled) return;
    this.toggleValue = event;
    this.toggleChanged.emit(this.toggleValue);
  }

}
