<!-- File Uploader ~ START -->
<mat-accordion>
    <mat-expansion-panel [expanded]="step==0?'true':'false'">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Segmentation Data Input
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="col px-4 py-4 m-0">
                <h4 class="d-flex align-items-center">Previously Uploaded Files
                </h4>
                <div class="mat-elevation-z8 previousFilesTable">
                    <table mat-table [dataSource]="dataSource.data" multiTemplateDataRows class="w-100">
                        <!-- expand Column -->
                        <ng-container matColumnDef="expand">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                            <td mat-cell *matCellDef="let row" style="width: 50px;">
                                <mat-icon *ngIf="row.file_name != expandedElement && row.versions.length">
                                    keyboard_arrow_right</mat-icon>
                                <mat-icon *ngIf="row.file_name == expandedElement && row.versions.length">
                                    keyboard_arrow_down</mat-icon>
                            </td>
                        </ng-container>

                        <!-- name Column -->
                        <ng-container matColumnDef="fileName">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> File Name </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.file_name}}</td>
                        </ng-container>

                        <!-- status Column -->
                        <ng-container matColumnDef="status">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> File Status </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.status}} <small>({{element.progress}}%)</small> </td>
                        </ng-container>

                        <!-- createdAt Column -->
                        <ng-container matColumnDef="createdAt">
                            <th class="truncate-cell" mat-header-cell *matHeaderCellDef> Created At </th>
                            <td class="truncate-cell" mat-cell *matCellDef="let element"> {{element.created_time |
                                date:"MM/dd/yy @h:mm a"}} </td>
                        </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                <div class="example-element-detail" *ngIf="element.versions.length"
                                    [@detailExpand]="element.file_name == expandedElement ? 'expanded' : 'collapsed'">
                                    <div class="inner-table mat-elevation-z8 w-100" *ngIf="expandedElement">
                                        <table mat-table [dataSource]="element.versions" class="w-100">
                                            <!-- Checkbox Column -->
                                            <ng-container matColumnDef="selection">
                                                <th mat-header-cell *matHeaderCellDef>
                                                </th>
                                                <td mat-cell *matCellDef="let row" style="width: 50px;">
                                                    <mat-checkbox
                                                        *ngIf="row.status=='File Uploaded' || row.status=='File Stats Updated'"
                                                        (click)="$event.stopPropagation()"
                                                        (change)="$event ? selection.toggle(row.name+'_'+row.version_name) : null"
                                                        [checked]="selection.isSelected(row.name+'_'+row.version_name)">
                                                    </mat-checkbox>
                                                </td>
                                            </ng-container>

                                            <!-- Name Column -->
                                            <ng-container matColumnDef="filename">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    Version Name
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.version_name}}
                                                </td>
                                            </ng-container>

                                            <!-- Status Column -->
                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    Segmentation Status
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.segmentation_status}} <small>({{row.segmentation_progress}}%)</small>
                                                </td>
                                            </ng-container>

                                            <!-- Created At -->
                                            <ng-container matColumnDef="createdAt">
                                                <th mat-header-cell *matHeaderCellDef>Created At</th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.created_time | date:"MM/dd/yy @h:mm a"}}
                                                </td>
                                            </ng-container>

                                            <!-- Created At -->
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let row">
                                                    <u class="cursor-pointer" (click)="selection.clear(); selection.toggle(row.name+'_'+row.version_name); openSelectedFileData();">{{row.segmentation_status == 'Run Completed' ? 'View Results' : null}}</u>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="innerColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: innerColumns;"></tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                            [class.example-element-row]="element.versions.length"
                            [class.example-expanded-row]="expandedElement === element.file_name" (click)="toggleRow(element)">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <h6 *ngIf="!dataSource.data.length" style="opacity: 0.4;"
                        class="text-center text-secondary my-3 p-4">
                        No data to show.
                    </h6>
                </div>
                <div class="row d-flex justify-content-between align-items-center mt-4 mx-1">
                    <button [disabled]="!selection.hasValue()" mat-raised-button color="accent"
                        (click)="openSelectedFileData()">OPEN SELECTED
                    </button>
                </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- File Uploader ~ END -->

<hr class="theme-hr" />

 <!-- Data View -->
 <div class="all-show-grid mt-3" *ngIf="selectedFileName">
    <ag-grid-angular
        style="flex: 1 1 auto;"
        #settingsGrid [suppressMenuHide]="true"
        suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
        class="ag-theme-material"
        [rowModelType]="rowModelType"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
        id="settingsGrid"
        (gridReady)="onGridReady($event)"
        [columnDefs]="gridColumnDefs">
    </ag-grid-angular>

    <button mat-raised-button color="accent" (click)="clearData()">Clear Data</button>

    <hr class="theme-hr" />

    <h4>Segmentation Settings</h4>
    <h6>Please specify column names for each of the following metrices</h6>
    <h6><b>Note: Specifying column names for SKU/Subclass/Category ID and Discount Amount is optional.</b></h6>

    <form *ngIf="settingsForm" [formGroup]="settingsForm">
        <div class="row m-0">
            <div class="col-md-6">
                <div *ngFor="let item_ of settingFields; let i=index">
                    <div class="form-group multi-select d-flex align-items-center">
                        <label style="width: 200px;">{{item_.label}}</label>
                        <ng-multiselect-dropdown [placeholder]="' '" [settings]="dropdownSettings"
                            [data]="allColumns" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectionDropDowns[item_.id]"
                            (onSelect)="selectionChange(item_.id)" (onDeSelect)="selectionChange(item_.id)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex text-right mb-4">
                    <mat-checkbox formControlName="separate_segment" color="warn">Separate Segmentation by {{groupByField}}?</mat-checkbox>
                </div>

                <div class="d-flex text-right mb-4">
                    <mat-checkbox formControlName="allow_outliers" color="warn">Allow Algo to check for outliers?</mat-checkbox>
                </div>

                <div class="d-flex">
                    <strong class="mr-2" style="min-width: 150px;">No. of Segments</strong>
                    <mat-form-field appearance="fill">
                        <input type="number" formControlName="no_of_segments" matInput />
                    </mat-form-field>
                    <div class="d-flex text-right ml-4">
                        <mat-checkbox formControlName="set_no_of_segments_auto" color="warn">Set Automatically</mat-checkbox>
                    </div>
                </div>
                
            </div>
        </div>
    </form>
    <div class="d-flex">
        <button mat-stroked-button color="accent" (click)="resetFormSettings()">Clear Settings</button>
        <button mat-raised-button color="accent" class="ml-2" [disabled]="settingsForm.invalid" (click)="runSegmentation()">Run Segmentation
        </button>
    </div>

</div>
