<div class="dialog-close-btn">
  <button mat-icon-button (click)="onNoClick()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>

<form [formGroup]="itemForm" class="item-dialog-form" (ngSubmit)="itemForm.valid && onSubmit()">
  <div mat-dialog-content>

    <h5>Create/Edit Item</h5>

    <div class="row">
      <div class="col-md-3">
        <div>
          <div *ngIf="!imageSrc" class="no-image-class">
            <label>Image</label>
          </div>
          <img [src]="imageSrc" *ngIf="imageSrc" alt="Image" style="height: 300px; max-width:100%">
        </div>
        <div class="file-controls">
          <label class="file-upload">
            <input #itemFile formControlName="file" size="60" id="file" type="file" class="form-control"
              (change)="onFileChange($event)">
            Upload Image
          </label>
          <button mat-button type="button" (click)="clearItemImage()">Clear Image</button>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row">

          <div class="col-md-2 product-field-label">
            <strong>Item Description</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput formControlName="itemDescription">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Sub Class Code*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select *ngIf="!isEditMode" formControlName="subClassId" (selectionChange)="onSubClassChange()">
                <mat-option *ngFor="let subClass of getSubclassList" [value]="subClass.subClassId">
                  {{subClass.subClassCode}}
                </mat-option>
              </mat-select>
              <input *ngIf="isEditMode" readonly matInput formControlName="subClassCode">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Product Title ID*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input readonly matInput formControlName="productTitleId">
              <mat-icon *ngIf="!data.isMasterPage && !isEditMode" class="search-action-icon" matSuffix (click)="openMasterGrid()">
                search</mat-icon>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Sub Class Name*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select *ngIf="!isEditMode" formControlName="subClassId" (selectionChange)="onSubClassChange()">
                <mat-option *ngFor="let subClass of getSubclassList" [value]="subClass.subClassId">
                  {{subClass.subClassName}}
                </mat-option>
              </mat-select>
              <input *ngIf="isEditMode" readonly matInput formControlName="subClassName">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>UPC*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput [readonly]="isEditMode" formControlName="upc">
            </mat-form-field>
            <div *ngIf="upc.invalid && (upc.dirty || upc.touched)" class="alert alert-danger">
              UPC must be a unique 12 digit numeric value.
            </div>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Retek Cost*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input [readonly]="(isEditMode && !isRetailerTeam)" matInput type="number" formControlName="retekCost">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Account</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="accountId" [disabled]="disabledAccountSelection">
                <mat-option *ngFor="let account of accountList" [value]="account.accountId">
                  {{account.accountName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Regular Retail Price</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input [readonly]="!isRetailerTeam" matInput type="number" formControlName="regRetailPrice">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Release Date*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="releaseDate" formControlName="releaseDate">
              <mat-datepicker-toggle matSuffix [for]="releaseDate"></mat-datepicker-toggle>
              <mat-datepicker #releaseDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Rating</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="ratingId">
                <mat-option *ngFor="let rating of ratingList" [value]="rating.ratingId">
                  {{rating.ratingName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>MSRP</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput formControlName="msrp">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Digital Code</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="digitalCode">
                <mat-option [value]="1">Yes</mat-option>
                <mat-option [value]="0">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Class Code*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select [disabled]="isEditMode" formControlName="classId" (selectionChange)="onClassChange()">
                <mat-option *ngFor="let class of classList" [value]="class.classId">
                  {{class.classCode}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Expiry Date</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="expirationDate" formControlName="expirationDate">
              <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
              <mat-datepicker #expirationDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Class Name*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select [disabled]="isEditMode" formControlName="classId" (selectionChange)="onClassChange()">
                <mat-option *ngFor="let class of classList" [value]="class.classId">
                  {{class.className}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>External Item ID</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput [readonly]="isRetailerTeam" formControlName="externalItemId">
            </mat-form-field>
          </div>

        </div>
      </div>
    </div>

  </div>
  <div mat-dialog-actions style="justify-content: flex-end;">
    <button type="button" *ngIf="data && data.isEdit" mat-button mat-stroked-button color="accent"
      (click)="openRemoveDialog()">Delete</button>
    <button type="button" mat-button mat-flat-button color="accent" (click)="onNoClick()">Cancel</button>
    <button type="submit" mat-button mat-flat-button color="accent" cdkFocusInitial>Save</button>
  </div>
</form>