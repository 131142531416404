import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, Subject, Subscription } from 'rxjs';
import { USER_INFO } from 'src/common/keys';
import { getUniqueId } from 'src/modules/item-manager/features/util/util';
import { ISasToken } from 'src/services/azure-storage/azure-storage';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import * as Papa from 'papaparse';
import { BlobStorageService } from 'src/services/azure-storage/blob-storage.service';
import { combineAll, map, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { defaultColDef, generateGridColumns } from './ag-grid/gridOptions';
import {  ColumnApi, GridApi } from 'ag-grid-enterprise';
import { FileUploadService } from 'src/services/file-upload.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UpdateNameDialogComponent } from 'src/common/update-name-dialog/update-name-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

interface IUploadProgress {
  filename: string;
  progress: number;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class FileUploadComponent implements OnInit, OnDestroy {

  @Output() openFile = new EventEmitter();
  @Input() newVersionSaved = new Subject();

  filesSubscription: Subscription;

  uniqueId = '';
  userObj = {} as any;
  displayedColumns = ['expand', 'fileName', 'status', 'createdAt', 'action'];
  innerColumns = ['selection', 'filename', 'status', 'createdAt', 'action'];
  dataSource = new MatTableDataSource<any>();
  uploadProgress$!: Observable<IUploadProgress[]>;
  fileProgress = 0;
  file: File;
  uploadingFile = false;
  selection = new SelectionModel<any>(false);
  isVariableView = true;
  step = 0;
  groupBy = null;
  selectedUniqueID = null;
  expandedElement = null;

  // Grid Variables

  public gridColumnDefs: any[] = [];
  public defaultColDef = defaultColDef;
  private gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions = {};
  gridData: any = [];

  showFilesFetchingSpinner = true;

  toggleAll = null;

  constructor(private toastrService: ToastrService, private fileUploadService: FileUploadService, private dialog: MatDialog, private blobStorage: BlobStorageService, private storage: LocalstorageService, private spinner: NgxSpinnerService, private clusterForecastService: ClusteringForecastService) { }

  ngOnInit(): void {
    this.userObj = this.storage.get(USER_INFO);
    this.uniqueId = getUniqueId();
    this.newVersionSaved.subscribe((fileObj: { fileName, versionNo, versionName }) => {
      this.selection.clear();
      this.selection.toggle(fileObj.fileName + '_' + fileObj.versionName);
    });
  }

  ngAfterViewInit() {
    const filerUploaderDiv = document.getElementById('fileUploaderDiv' + this.uniqueId);
    filerUploaderDiv.ondragover = () => {
      if (!filerUploaderDiv.classList.contains('shakeDiv'))
        filerUploaderDiv.classList.add('shakeDiv');
      return false;
    };
    filerUploaderDiv.ondragleave = () => {
      filerUploaderDiv.classList.remove('shakeDiv');
      return false;
    };
    filerUploaderDiv.ondrop = (e) => {
      filerUploaderDiv.classList.remove('shakeDiv');
      e.preventDefault();
      if (e.dataTransfer.files.length > 1) {
        this.toastrService.warning('Only one file can be dropped!', 'Warning');
        return;
      } else {
        this.onFileSelect(e, e.dataTransfer.files);
      }
    }
    this.fetchFilesProgress();
  }

  toggleChanged(e) {
    this.toggleAll = e;
    if (e != null) {
      this.gridColumnDefs.forEach(element => {
        element.field != this.selectedUniqueID && (element.isMetricVariable = e);
      });
    }
  }

  slideToggleChanged() {
    let trueCount = 0;
    let falseCount = 0;
    this.gridColumnDefs.forEach(element => {
      if (element.field != this.selectedUniqueID) {
        if (element.isMetricVariable) {
          trueCount++;
        } else {
          falseCount++;
        }
      }
    });
    const oldState = JSON.parse(JSON.stringify(this.toggleAll));
    if (trueCount && falseCount) {
      this.toggleAll = null;
    } else {
      if (trueCount) this.toggleAll = true;
      else this.toggleAll = false;
    }
    if (oldState != null && this.toggleAll == null) {
      this.toggleAll = !oldState;
      setTimeout(() => {
        this.toggleAll = null;
      }, 200);
    }
  }

  private localeTimeString(date): Date {
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);

    const offset = utcDate.getTimezoneOffset() / 60;
    const hours = utcDate.getHours();

    localDate.setHours(hours - offset);

    return localDate;
  }

  downloadTemplate() {
    window.open('/assets/import/Data template for clustering v2.xlsx', '_blank');
  }

  onFileSelect(event, droppedFile = null) {

    this.clearData();

    const files = (droppedFile ? droppedFile : event.target.files) as FileList;
    const file = files[0];

    if (['csv'].indexOf(file.name.split('.').pop().toLowerCase()) == -1) {
      this.toastrService.warning('File must be in CSV format.', 'Warning');
      event.target.value = null;
      return false;
    }

    const rowsData = [];
    let headerData = [];
    let fileChecked = false;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      preview: 10,
      worker: true,
      step: (row) => {
        rowsData.push(row.data);
        headerData = row.meta.fields;
        if (rowsData.length == 10) {
          fileChecked = true;
          this.checkFileValidations(file, event, rowsData, headerData);
        }
        setTimeout(() => {
          if (!fileChecked) {
            fileChecked = true;
            if (rowsData.length) {
              this.checkFileValidations(file, event, rowsData, headerData);
            } else {
              this.clearData();
              this.toastrService.error('Failed to upload file.');
              event.target.value = null;
              return;
            }
          }
        }, 800);
      },
      complete: (result, file) => {
        setTimeout(() => {
          if (!fileChecked) {
            fileChecked = true;
            if (rowsData.length, file) {
              this.checkFileValidations(file, event, rowsData, headerData);
            } else {
              this.clearData();
              this.toastrService.error('Failed to upload file.');
              event.target.value = null;
              return;
            }
          }
        }, 1200);
      }
    });
  }

  private checkFileValidations(file: File, event = null, rowsData, headersData) {
    if (headersData.length > 100) {
      this.toastrService.warning('File cannot have columns more than 100.', 'Warning');
      event.target.value = null;
      return;
    }
    if (file.size > 1024 * 1024 * 1024) {
      this.toastrService.warning('File size should not exceed 1GB limit.', 'Warning');
      event.target.value = null;
      return;
    }

    // checking for duplicate headers
    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
    const lowerCasedArray = [];
    headersData.forEach(headerElement => {
      lowerCasedArray.push(headerElement.toLowerCase());
    });
    const duplicates = [...new Set(findDuplicates(lowerCasedArray))];
    if (duplicates.length) {
      this.toastrService.warning('File contains duplicate header names. Check the following Columns: ' + duplicates, 'Warning');
      event.target.value = null;
      return;
    }

    // check for special characters
    const hasSpecialCharacters = headerName => {
      var specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (headerName.match(specialCharacters)) {
        return true;
      }
      else {
        return false;
      }
    }
    const faultyHeaders = [];
    for (let i = 0; i < headersData.length; i++) {
      if (hasSpecialCharacters(headersData[i]) || headersData[i].includes(' ')) {
        faultyHeaders.push(headersData[i])
      }
    }
    if (faultyHeaders.length) {
      this.toastrService.warning('Header names cannot have spaces & special characters. Check the following Columns: ' + faultyHeaders, 'Warning');
      event.target.value = null;
      return;
    }

    // check for empty headers
    let emptyHeaders = 0;
    headersData.forEach(header => {
      !header && emptyHeaders++;
    });
    if (emptyHeaders) {
      this.toastrService.warning(emptyHeaders + ` empty column${emptyHeaders > 1 ? 's' : ''} found this file.`, 'Warning');
      event.target.value = null;
      return;
    }

    // check if column is number
    const numberColumns = [];
    for (let i = 0; i < headersData.length; i++) {
      if (!isNaN(headersData[i])) {
        numberColumns.push(headersData[i])
      }
    }
    if (numberColumns.length) {
      this.toastrService.warning('Header names cannot be number. Check the following Columns: ' + numberColumns, 'Warning');
      event.target.value = null;
      return;
    }

    // SETTING TABLE HEADER CONFIG
    this.gridColumnDefs = generateGridColumns(headersData, false);
    this.gridData = rowsData;
    // FOR CHECKING IF VARIABLE IS METRIC OR NOT
    for (let i = 0; i < this.gridData.length && i < 10; i++) {
      const values = Object.values(this.gridData[i]);
      values.forEach((element, index) => {
        if (isNaN(+element)) {
          this.gridColumnDefs[index].isMetricVariable = false;
        }
      });
    }
    this.step = 1;
    this.file = file;
    this.isVariableView = true;
    this.groupBy = null;
    this.selectedUniqueID = this.gridColumnDefs[0].field;
    this.gridColumnDefs[0].isMetricVariable = false;
    event.target.value = null;
    this.gridData.length == 0 && this.gridApi.showNoRowsOverlay();
    this.gridApi && this.gridApi.setRowData(this.gridData);
    this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
    this.slideToggleChanged();
  }

  async uploadFile(file: File = this.file) {
    // AZURE UPLOAD CODE
    this.spinner.show();
    const azureContainer = await this.fileUploadService.getSASToken().toPromise();
    this.spinner.hide();
    let filename = `${file.name.split('.').slice(0, -1).join('.')}_${new Date().getTime()}.${file.name.split('.').pop()}`;
    filename = _.replace(filename, new RegExp(" ", "g"), "_");
    this.uploadProgress$ = from([file]).pipe(
      map(file => this.uploadFileToAzure(file, azureContainer, filename)),
      combineAll()
    );
    let uploadProgressSubscription: Subscription;
    this.uploadingFile = true;
    uploadProgressSubscription = this.uploadProgress$.subscribe((res: IUploadProgress[]) => {
      if (res[0].progress == 100) {
        const filePath = azureContainer.storageUri + '/' + azureContainer.container + '/' + filename;
        this.uploadFileForPreprocessing(filePath, filename);
      }
    }, err => {
      this.fileProgress = 0;
      this.toastrService.error('File uploading failed!', 'ERROR');
      uploadProgressSubscription && uploadProgressSubscription.unsubscribe();
    });
  }

  private uploadFileToAzure(file: File, azureContainer: any, filename: string): Observable<IUploadProgress> {
    const accessToken: ISasToken = {
      container: azureContainer.container,
      filename: filename,
      storageAccessToken: azureContainer.sasToken,
      storageUri: azureContainer.storageUri
    };
    return this.blobStorage
      .uploadToBlobStorage(accessToken, file)
      .pipe(map((progress: any) => this.mapProgress(file, progress)));
  }

  private mapProgress(file: File, progress: number): IUploadProgress {
    this.fileProgress = progress;
    return {
      filename: file.name,
      progress: progress
    };
  }

  uploadFileForPreprocessing(filePath, fileName) {
    const variableData = [];
    this.gridColumnDefs.forEach(element => {
      variableData.push({
        name: element.field,
        displayName: element.headerName,
        is_matric: element.isMetricVariable
      });
    });
    const obj = {
      file: filePath,
      file_name: fileName,
      user_id: this.userObj.userId,
      variable_data: variableData,
      unique_id: this.selectedUniqueID,
      object_to_cluster: this.selectedUniqueID,
      group_by: this.groupBy
    };

    this.spinner.show();
    this.fileUploadService.uploadFileForProcessing(obj)
      .subscribe((res: any) => {
        this.toastrService.info('File uploaded successfully. It will take some time for processing. Wait till then!', 'Info');
        this.clearData();
        this.showFilesFetchingSpinner = true;
      },
        err => {
          this.uploadingFile = false;
          this.spinner.hide();
        });
  }

  clusterUploadFileBtn() {
    if (document.getElementById('cluster-upload-data-btn' + this.uniqueId))
      document.getElementById('cluster-upload-data-btn' + this.uniqueId).click();
  }

  checkButtonDisabledCondition(): boolean {
    let result = false;
    if (!this.selection.hasValue()) result = true;
    else {
      const fileversionName = this.selection.selected[0];
      this.dataSource.data.forEach(f => {
        f.versions.forEach(v => {
          if ((v.name + '_' + v.version_name) == fileversionName && ['File Uploaded', 'File Stats Updated'].indexOf(v.status)==-1) {
            result = true;
          }
        });
      });
    }
    return result;
  }

  openSelectedFileData() {
    const fileversionName = this.selection.selected[0];
    this.dataSource.data.forEach(f => {
      f.versions.forEach(v => {
        if ((v.name + '_' + v.version_name) == fileversionName) {
          this.openFile.emit(v);
        }
      });
    });
  }

  deleteFile(fileObj) {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to remove this version?' }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        const obj = {
          user_id: this.userObj.userId,
          file_name: fileObj.name,
          version_no: fileObj.uu_id
        };
        this.spinner.show();
        this.fileUploadService.deleteFileVersion(obj)
          .subscribe((res: any) => {
            this.fileUploadService.fileDeleted.next({ fileName: fileObj.name, versionNo: fileObj.uu_id });
            this.toastrService.success('Version has been deleted successfully.');
            this.showFilesFetchingSpinner = true;
            this.selection.clear();
          },
            err => {
              this.toastrService.error('Failed to delete version.')
              this.spinner.hide();
            });
      }
    });
  }

  deleteAllFiles() {

    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to remove all files?' }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const obj = {
          user_id: this.userObj.userId
        }
        this.clusterForecastService.deleteAllFiles(obj)
          .subscribe((res: any) => {
            this.toastrService.success('All files have been deleted successfully!', 'Success');
            this.dataSource.data = [];
            this.fileUploadService.fileDeleted.next({ deleteAll: true });
            this.spinner.hide();
            this.selection.clear();
          },
            err => {
              this.toastrService.error('Files deletion failed!');
              this.spinner.hide();
            });
      }
    });
  }

  deleteFileAndAllVersions(fileName) {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to remove this file?' }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const obj = {
          user_id: this.userObj.userId,
          file_name: fileName
        };
        this.fileUploadService.deleteFileAndAllVersions(obj)
          .subscribe((res: any) => {
            this.fileUploadService.fileDeleted.next({ fileName: fileName });
            this.toastrService.success('File deleted successfully!');
            this.showFilesFetchingSpinner = true;
            this.selection.clear();
          },
            err => {
              this.toastrService.error('Failed to delete file.');
              this.spinner.hide();
            });
      }
    });

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  updateName(item) {
    const columnsDialogRef = this.dialog.open(UpdateNameDialogComponent, {
      width: '650px',
      data: {
        name: item.headerName
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let duplicateName = false;
        this.gridColumnDefs.forEach(element => {
          if (element.field != item.field && element.headerName == result) {
            duplicateName = true;
          }
        });
        if (duplicateName) {
          this.toastrService.warning('Duplicate names cannot be saved.', result);
          return;
        }
        item.headerName = result;
        this.gridApi.setColumnDefs(this.gridColumnDefs);
      }
    });
  }

  clearData() {
    this.step = 0;
    this.gridData = [];
    this.gridColumnDefs = [];
    this.file = null;
    this.fileProgress = 0;
    this.uploadingFile = false;
    this.selectedUniqueID = null;
  }

  radioChanged(colItem, e) {
    this.selectedUniqueID = e.value;
    colItem.isMetricVariable = false;
    this.slideToggleChanged();
  }

  checkboxChanged(field: string, e) {
    if (e.checked)
      this.groupBy = field;
    else
      this.groupBy = null;
  }

  toggleRow(element: any) {
    element.versions &&
      element.versions.length
      ? (this.expandedElement =
        this.expandedElement === element.file_name ? null : element.file_name)
      : null;
    // this.cd.detectChanges();
    // this.innerTables.forEach((table, index) => table.dataSource);
  }

  fetchFilesProgress() {
    this.showFilesFetchingSpinner && this.spinner.show();
    this.filesSubscription && this.filesSubscription.unsubscribe();
    this.filesSubscription = this.fileUploadService.fetchFilesProgress(this.userObj.userId)
      .subscribe((res: any) => {
        this.dataSource.data = res.payload;
        this.dataSource.data.map((e) => {
          e.created_time = this.localeTimeString(e.created_time);
          e.versions.map((v) => {
            v.created_time = this.localeTimeString(v.created_time);
            v.version_name = v.name;
            v.name = e.file_name;
            v.progress_status = v.progress;
            if (v.uu_id == 1) v.progress_status = e.progress
          });
        });
        this.showFilesFetchingSpinner && this.spinner.hide();
        this.showFilesFetchingSpinner = false;
        setTimeout(() => {
          this.fetchFilesProgress();
        }, 3000);
      },
        err => {
          setTimeout(() => {
            this.fetchFilesProgress();
          }, 3000);
        });
  }

  ngOnDestroy() {
    this.filesSubscription && this.filesSubscription.unsubscribe();
    this.spinner.hide();
  }
}
