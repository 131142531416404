import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class ItemPromotionService {

  //Variables
  selectedPromotion: any;

  // Subjects
  public promotionEditSubject: Subject<any> = new Subject();
  public promotionUpsertSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setPromotionEditSubject = (value: any) => { this.promotionEditSubject.next(value); };
  setPromotionUpsertSubject = (value: any) => { this.promotionUpsertSubject.next(value); };

  // Observables
  getPromotionEditSubject = () => this.promotionEditSubject.asObservable();
  getPromotionUpsertSubject = () => this.promotionUpsertSubject.asObservable();


  constructor(private http: HttpClient) {
  }


  CreatePromotion(promtion: any) {
    return this.http.post<any>(`${environment.AppItemPromotion}/add`, promtion)
      .pipe(map(data => {
        return data;
      }));
  }

  UpdatePromotionItem(promotion: any) {
    return this.http.put(`${environment.AppItemPromotion}/update`, promotion)
      .pipe(map(data => {
        return data;
      }));
  }

  DeletePromotionItem(promotionId: string) {
    return this.http.delete(`${environment.AppItemPromotion}/delete?PromotionId=${promotionId}`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetAllPromotionItems(offSet, pageSize) {
    return this.http.get(`${environment.AppItemPromotion}/getlist`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetFilterPromotionItemList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotion}/getlist?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetFilterPromotionItemDetailList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotion}/getpromotiondetailapprovallist?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  BulkAddPromotionDetailItems(body) {
    return this.http.post<any>(`${environment.AppItemPromotion}/bulkaddpromotiondetail`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  FilterPromotionDetailItemList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotion}/getpromotiondetaillist?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  BulkUpdatePromotionDetailItems(body) {
    return this.http.post<any>(`${environment.AppItemPromotion}/bulkupdatepromotiondetail`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  GetPromotionsDetailItemsApprovalList(offSet = 0, pageSize = 100) {
    return this.http.get(`${environment.AppItemPromotion}/getpromotiondetailapprovallist`)
    .pipe(map(data => {
      return data;
    }));
  }
  
  GetPromotionItemDetailWeeklyForecastList() {
    return this.http.get<any>(`${environment.AppItemPromotion}/GetPromotionItemDetailWeeklyForecastList`)
      .pipe(map(data => {
        return data;
      }));
  }

  BulkUpdatePromotionItemWeeklyForecastList(promotion) {
    return this.http.post<any>(`${environment.AppItemPromotion}/BulkUpdatePromotionDetailWeeklyForecast`, promotion)
      .pipe(map(data => {
        return data;
      }));
  }

  FilterPromotionsDetailItemsApprovalList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotion}/getpromotiondetailapprovallist?${query}`)
    .pipe(map(data => {
      return data;
    }));
  }

  FilterPromotionItemDetailWeeklyForecastList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotion}/GetPromotionItemDetailWeeklyForecastList?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetPromotionItemDetailWeeklyForecastNewItemPromoList(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotion}/GetPromotionItemDetailWeeklyForecastNewItemPromoList?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  CheckDuplicatePromotionItem(body) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppItemPromotion}/CheckDuplicatePromotionItem?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  ImportNewItemPromotionDataTemplate(body) {
    return this.http.post(`${environment.AppItemPromotion}/import`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  ImportNewItemPromotionsList(body) {
    return this.http.post(`${environment.AppItemPromotion}/ImportNewItemPromotionsList`, body)
      .pipe(map(data => {
        return data;
      }));
  }

}
