import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { User } from '../../models/User';
import { environment } from '../../environments/environment';
import { UserClaims, Claims } from '../../models/UserRoles';
import { BEST_BUY_ROLE_CODE, INVALID_ROLE, INVALID_TEAM, RETAILER_TEAM_CODE, USER_ROLES, USER_TEAMS, USER_TOKEN_INFO, VENDOR_TEAM_CODE } from '../../common/keys';
import { LocalstorageService } from '../localstorage.service';


@Injectable()
export class UsersService {
  editRow: Subject<number>;
  userInfo: any = {};
  clicked: boolean;
  claimGroupList: any[];
  tokenClaims = new BehaviorSubject<string[]>([]);
  listUpdate = new BehaviorSubject<boolean>(false);
  listUpdate$ = this.listUpdate.asObservable();
  constructor(private http: HttpClient, public storage: LocalstorageService) {
    this.editRow = new Subject<number>();
  }
  updateUserInformation(info: any): void {
    this.userInfo = {
      ...this.userInfo,
      ...info
    };
  }
  editClickedSet(clicked) {
    this.clicked = clicked;
  }
  editClickedGet() {
    return this.clicked;
  }
  show(id: number) {
    this.editRow.next(id);
  }
  getObservable() {
    return this.editRow;
  }


  /*
  *   This method will return Team code of Login User
  */
  checkLoginUserTeam() {
    const roles = this.storage.get(USER_ROLES);
    const teams = this.storage.get(USER_TEAMS);
    const isRetailer = _.find(teams, a => a.teamNameCode === RETAILER_TEAM_CODE);
    const isVendor = _.find(teams, a => a.teamNameCode === VENDOR_TEAM_CODE);
    const isBestBuyRole = _.find(roles, a => a.roleCode === BEST_BUY_ROLE_CODE);
    if (isRetailer) {
      return isBestBuyRole ? RETAILER_TEAM_CODE : INVALID_TEAM;
    } else if (isVendor) {
      return isBestBuyRole ? INVALID_TEAM : VENDOR_TEAM_CODE;
    } else {
      return INVALID_TEAM;
    }
  }

  checkUserRoleCode() {
    const roles = this.storage.get(USER_ROLES);
    if (roles && roles.length) {
      return _.first(roles).roleCode;
    } else {
      return INVALID_ROLE;
    }
  }

  getTeamRoleAccount() {
    const token = this.storage.get(USER_TOKEN_INFO);
    try {
      const tokenData: any = jwt_decode(token);
      return Number(tokenData.AccountId);
    } catch (Error) {
      return null;
    }
  }

  CreateUser(user: any) {
    return this.http.post<any>(`${environment.AppAccount}/createuser`, user)
      .pipe(map(data => {
        return data;

      }));
  }

  UpdateUser(user: any) {

    return this.http.post(`${environment.AppAccount}/update`, user)
      .pipe(map(data => {
        return data;
      }));
  }
  ActivateUser(user: any) {

    return this.http.put(`${environment.AppAccount}/activate`, user)
      .pipe(map(data => {
        return data;
      }));
  }
  GetUserById(userId: string) {
    return this.http.get<any>(`${environment.AppAccount}/getUserById?UserId=${userId}`, {})
      .pipe(map(user => {
        return user;
      }));
  }

  SearchUser(ch: any) {
    return this.http.get<any>(`${environment.AppAccount}/search?search=${ch}`, {})
      .pipe(map(user => {
        return user;
      }));
  }

  GetAllInspetors(params) {
    return this.http.get<any>(`${environment.AppAccount}/getall?PageSize=200&UserTypeId=${params}`, {})
      .pipe(map(inspection => {
        return inspection;
      }));
  }

  GetAllUsers(params) {
    return this.http.get<any>(`${environment.AppAccount}/getall`, { params })
      .pipe(map(inspection => {
        return inspection;
      }));
  }
  GetUserRolesList() {
    return this.http.get(`${environment.AppRole}/getlist`)
      .pipe(map(userRoles => {
        return userRoles;
      }));
  }

  GetUserRolesAll() {
    return this.http.get(`${environment.AppRole}/getall`)
      .pipe(map(userRoles => {
        return userRoles;
      }));
  }


  CreateRole(role: any) {

    return this.http.post<any>(`${environment.AppRole}/add`, role)
      .pipe(map(roles => {
        return roles;
      }));
  }

  UpdateRole(role: any) {

    return this.http.put(`${environment.AppRole}/update`, role)
      .pipe(map(data => {
        return data;
      }));
  }
  UpdateUserLock(role: any) {

    return this.http.put(`${environment.AppAccount}/updateUserLocked`, role)
      .pipe(map(data => {
        return data;
      }));
  }
  ActivateRole(role: any) {

    return this.http.put(`${environment.AppRole}/activate`, role)
      .pipe(map(data => {
        return data;
      }));
  }
  GetRoleById(roleId: string) {
    return this.http.get<any>(`${environment.AppRole}/get?RoleId=${roleId}`, {})
      .pipe(map(role => {
        return role;
      }));
  }


  SearchUsers(ch: any) {
    return this.http.get<any>(`${environment.AppAccount}/search?search=${ch}`, {});
  }

  getClaimsByRole(RoleId) {
    return this.http.get<UserClaims>(`${environment.AppRole}/get?RoleId=${RoleId}`, {});
  }

  GetUserByEmail(email: string) {
    return this.http.get<any>(`${environment.AppAccount}/getUserByEmail?Email=${encodeURIComponent(email)}`, {})
      .pipe(map(user => {
        return user;
      }));
  }
  getAllClaimGroups() {
    return this.http.get<any>(`${environment.AppClaimGroup}/getall`, {});

  }
  getClaimByCurrentUser(id) {
    return this.http.get<any>(`${environment.AppClaimGroup}/getAllByUser?CurrentUserId=${id}`, {});
  }
  getTeams() {
    return this.http.get(`${environment.AppTeams}/getlist`)
      .pipe(map(teams => {
        return teams;
      }));
  }
  CreateTeam(team: any) {

    return this.http.post<any>(`${environment.AppTeams}/add`, team)
      .pipe(map(teams => {
        return teams;
      }));
  }
  GetTeamById(teamId: string) {
    return this.http.get<any>(`${environment.AppTeams}/get?TeamId=${teamId}`, {})
      .pipe(map(team => {
        return team;
      }));
  }
  UpdateTeam(team: any) {

    return this.http.put(`${environment.AppTeams}/update`, team)
      .pipe(map(data => {
        return data;
      }));
  }
  ActivateTeam(team: any) {

    return this.http.put(`${environment.AppTeams}/activate`, team)
      .pipe(map(data => {
        return data;
      }));
  }
  DeleteTeam(team: any) {

    return this.http.put(`${environment.AppTeams}/delete`, team)
      .pipe(map(data => {
        return data;
      }));
  }
  AddUserRole(team: any) {
    return this.http.post<any>(`${environment.AppAccount}/AddUserRole`, team)
      .pipe(map(teams => {
        return teams;
      }));
  }
  AddUserTeam(team: any) {
    return this.http.post<any>(`${environment.AppAccount}/AddUserTeam`, team)
      .pipe(map(teams => {
        return teams;
      }));
  }

  GetClientUserById(userId: string) {
    return this.http.get<any>(`${environment.AppAccount}/getClientUserById?userId=${userId}`)
      .pipe(map(clients => {
        return clients;
      }));
  }

  GetClientConfigurationAccessToken(clientConfigurationId: string) {
    return this.http.get<any>(`${environment.AppAccount}/GetClientConfigurationAccessToken?clientConfigurationId=${clientConfigurationId}`)
      .pipe(map(token => {
        return token;
      }));
  }

  GetAllClients() {
    return this.http.get(`${environment.AppAccount}/GetListClientConfiguration`);
  }

  UpdateClient(requestBody: any) {
    return this.http.put(`${environment.AppAccount}/UpdateClientConfiguration`, requestBody);
  }

  AddClient(requestBody: any) {
    return this.http.post(`${environment.AppAccount}/AddClientConfigurationOnboarding`, requestBody);
  }

  GetClientById(clientConfigurationId) {
    return this.http.get(`${environment.AppAccount}/clientConfigurationGetById?clientConfigurationId=${clientConfigurationId}`);
  }
}
