import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PersistentContextService } from 'src/services/persistent-context-services/persistent-context.service';

@Component({
  selector: 'journey-log-action-cell-renderer',
  template: `<div *ngIf="params.data.actionPerformed !=='Delete Journey'" class="grid-action-cell">
        <span *ngIf="params.data.journey.metaData" class="material-icons" title="Open Journey" (click)="actionClickHandler('visibility')">visibility</span>
        <span *ngIf="!params.data.journey.metaData" style="cursor: pointer;" title="Journey has been deleted" class="material-icons">visibility_off</span>
    </div>`,
})
export class JourneyLogActionCellRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private persistentContextService: PersistentContextService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  actionClickHandler(action) {
    this.persistentContextService.setJourneyLogGridActionSubject({ action, data: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
