import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationHierarchyManagerService {

  constructor(private http: HttpClient) { }

  GetLocationHierarchyList(): Observable<any> {
    return this.http.get<any>(`${environment.LocationHierarchyManager}/locationHierarchyGetList`,)
      .pipe(map(response => {
        return response;
      }));
  }

  GetLocationHierarchyMetaDataList(): Observable<any> {
    return this.http.get<any>(`${environment.HierarchyManager}/locationHierarchyMetadataGetList`,)
      .pipe(map(response => {
        return response;
      }));
  }

  AddUpdateLocationHierarchyList(body): Observable<any> {
    return this.http.post<any>(`${environment.LocationHierarchyManager}/locationHierarchyInsertOrUpdate`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  ImportLocationHierarchyManagerList(body) {
    return this.http.post(`${environment.LocationHierarchyManager}/locationsHierarchyImport`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  DeleteLocationHierarchyRow(body): Observable<any> {
    return this.http.post<any>(`${environment.LocationHierarchyManager}/locationHierarchyDelete`, body)
      .pipe(map(response => {
        return response;
      }));
  }
}
