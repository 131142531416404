<div mat-dialog-content>
    <p><strong>{{data.title || 'Change Name'}}</strong></p>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter name" matInput [(ngModel)]="name">
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="accent" [disabled]="!name" (click)="onSaveClick()">Done</button>
</div>
