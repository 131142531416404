<div class="container-fluid">
  <div class="user-page">
    <div class="row">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <button (click) = "openDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <button (click) = "openDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>

        </div>
      </div>
    </div>
  </div>

  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"  [suppressMenuHide]="true"
      groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptions"
      [columnDefs]="columnDefs" [rowData]="insightList" (gridReady)="onGridReady($event)"  #grid>
    </ag-grid-angular>
  </div>


</div>
