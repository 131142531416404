import { Component, OnDestroy, OnInit } from '@angular/core';
import {GridApi, ColumnApi, GridOptions } from 'ag-grid-enterprise';
import * as _ from 'lodash';
import { defaultColDef, getColDefs } from './ag-grid/grid-options';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { AddEditMappingForecastItemComponent } from './add-edit-mapping-forecast-item/add-edit-mapping-forecast-item.component';
import { MappingService } from 'src/services/forecast-services/mapping-service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import * as signalR from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import { UnmappedItemListComponent } from './unmapped-item-list/unmapped-item-list.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { Subscription } from 'rxjs';
import { setSyncButtonClass } from '../forecast-helper-functions';
import { SharedDataService } from 'src/services/shared-data.service';

@Component({
  selector: 'app-mapping-manager',
  templateUrl: './mapping-manager.component.html',
  styleUrls: ['./mapping-manager.component.css']
})
export class MappingManagerComponent implements OnInit, OnDestroy {

  paginationPageSize = 50;
  cacheBlockSize = 50;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public defaultColDef = defaultColDef;
  public columnDefs = getColDefs({}, this);
  gridData: any = [];
  azpipeline: any;

  selectedRows: any = [];

  addEditMappingActionSubjectSubscription: Subscription;

  setSyncButtonClassFunction = setSyncButtonClass;

  constructor(
    public mappingService: MappingService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    private configurationService: ConfigurationService,
    public _dataService: SharedDataService,
  ) {
  }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  get getGridRowsCount(): Number {
    return this.gridApi ? this.gridApi.getModel().getRowCount(): 0;
  }

  ngOnInit() {
    this.setupHubConnection();
    this.getMappingItemGetList();

    this.addEditMappingActionSubjectSubscription = this.mappingService.addEditMappingActionSubject.subscribe(() => {
      this.getMappingItemGetList();
    });
  }

  setupHubConnection() {
    const configUrl=`${environment.configurationUrl}`;
    const hubUrl = configUrl.substring(0,configUrl.length - 3) + "notify";
    this.azurePipelineTriggerGetList();
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(hubUrl)
      .build();
    connection
      .start()
      .then(function () {
        console.log('SignalR Connected!');
      })
      .catch(function (err) {
        return console.error();
      });
    connection.on('BroadcastMessage', (con) => {
      this.azurePipelineTriggerGetList();
    });
  }

  getMappingItemGetList() {
    this.spinner.show();
    this.mappingService.MappingItemGetList().subscribe(forecastItemList => {
      this.gridData = forecastItemList;
      this.spinner.hide();
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    // this.createDataSource();
  }

  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  clearGridFilters() {
    this.gridApi.setFilterModel(null);
  }

  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }


  openMappingSetupScreen(isEdit = false) {
    if ((isEdit && this.selectedRows.length === 1) || !isEdit) {
       const data = {
          isEdit: isEdit,
          ...isEdit && { selectedSKU: this.selectedRows[0] }
      };
      this.openAddEditScreen(data);
    } else {
      this.toastr.warning('Warning', 'Please select only one row to modify.')
    }
  }

  openAddEditScreen(data = {}) {
    const forecastSetupComponent = {
      menuId: '462',
      name: 'Item Mapping Setup',
      selector: 'app-add-edit-mapping-forecast-item',
      displayName: 'Item Mapping Setup',
      module: 'ForecastEnginModule',
      data: data,
      component: AddEditMappingForecastItemComponent
    }
    this.configurationService.menuRendererSubject.next(forecastSetupComponent);
  }

  deleteForecastItem() {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Delete Mappings`,
        confirmationMessage: `Are you sure you want to delete these mappings?`
      }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.mappingService.DeleteForecastItem({ forecastItem: this.selectedRows }).subscribe(res => {
          if (res) {
            this.removeFromGrid();
            this.spinner.hide();
            this.toastr.success('Success', `Mappings deleted successfully.`);
          };
        }, err => {
          this.toastr.error('Error', `System failed to delete mappings.`);
          this.spinner.hide();
        });
      }
    });
  }

  removeFromGrid() {
    this.selectedRows.forEach(row => {
      const index = this.gridData.findIndex(a => a.forecastSKUItemId === row.forecastSKUItemId);
      this.gridData.splice(index, 1);
    });
    this.clearSelectedRows();
    this.gridApi.setRowData(this.gridData);
  }

  updateForecaseItemStatus(status) {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Status Change`,
        confirmationMessage: `Are you sure you want to ${status ? 'activate' : 'disable'} these mappings?`
      }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const payload = this.selectedRows.map(a => { return { forecastSKUItemId: a.forecastSKUItemId, status: status } });

        this.mappingService.UpdateForecastItemStatus({ forecastItem: payload }).subscribe(res => {
          if (res) {
            this.updateForecastItemsStatus(status);
            this.spinner.hide();
            this.toastr.success('Success', `Mapping status change successfully.`);
          };
        }, err => {
          this.toastr.error('Error', `System failed to update status.`);
          this.spinner.hide();
        });
      }
    });
  }

  updateForecastItemsStatus(status) {
    this.selectedRows.forEach(row => {
      const index = this.gridData.findIndex(a => a.forecastSKUItemId === row.forecastSKUItemId);
      this.gridData[index].status = status;
    });
    this.gridApi.setRowData(this.gridData);
  }

  triggerMappingManagerPipeline() {

    if (this.azpipeline && this.azpipeline.status && ['InProgress', 'Queued'].indexOf(this.azpipeline.status) > -1) {
      this.toastr.info('Info','Pipeline already in-progress.');
      return;
    }

    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Delete Mappings`,
        confirmationMessage: `Are you sure you want to trigger mapping manager pipeline?`
      }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.mappingService.TriggerMappingManagerPipeline().subscribe(response => {
          this.azpipeline = response;
          this.spinner.hide();
                   if (this.azpipeline.status == "InProgress") {
              this.toastr.success('Success', 'Outlier mapping manager pipeline is triggered.');
            }
            else {
              this.azpipeline.status = "InProgress";
              this.toastr.info('info', 'pipeline already in-Progress.');
            }
        }, error => {
          this.toastr.error('Error', 'Outlier mapping manager pipeline is failed to triggered.');
        });
      }
    });
  }
  azurePipelineTriggerGetList() {
    this.spinner.show();
    this.mappingService.AzurePipelineTriggerGetList().subscribe(azpipeline => {
      this.azpipeline = azpipeline;
      this.spinner.hide();
    });
  }

  viewUnmappedItems() {
    const dialogRef = this.dialog.open(UnmappedItemListComponent, {
      disableClose: true,
      width: '90%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openAddEditScreen(result);
      }
    });
  }

  ngOnDestroy() {
    this.addEditMappingActionSubjectSubscription.unsubscribe();
  }

}
