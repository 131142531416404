<div class="container-fluid">
  <form [formGroup]="filtersForm" class="row product-grid-filter-row mt-10 mx-2">
    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <input formControlName="itemName" matInput type="search" placeholder="Item Name">
        <mat-icon class="search-action-icon" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <mat-select placeholder="Select item type" formControlName="purchaseTypeId">
          <mat-option *ngFor="let item of typesArray" [value]="item.title">{{item.title}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-clear-btn">
      <button (click)="clearFilter()" mat-flat-button color="accent">Clear</button>
    </div>
  </form>
  <div class="grid-action-btn-bar">
    <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>
    <button class="add-icon-btn ml-10" (click)="addEditItem()" matTooltip="Add Item" mat-icon-button color="primary"
      type="button">
      <i class="material-icons">
        add_circleg
      </i>
    </button>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
      toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
      [columnDefs]="gridColumnDefs" [rowData]="dataSource" (cellClicked)="cellClicked($event)" [pagination]="true"
      [defaultColDef]="defaultColDef" [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
      (gridReady)="onGridReady($event)"  #itemsGridGrid>
    </ag-grid-angular>
  </div>
</div>
