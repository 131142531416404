import { Component, OnInit } from '@angular/core';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import moment from 'moment';
import { ProductHierarchyService } from 'src/services/setup-services/product-hierarchy.service';
import { ProductItemEditRenderer } from './ag-grid/product-item-edit-renderer.component';
import { ForecastItemManagerService } from 'src/services/forecast-services/item-manager.service';
import { ItemSetupComponent } from './item-setup/item-setup.component';
import { defaultColDef, getColDefs, getExportTemplateColDefs } from './ag-grid/grid-options';
import { ConfigurationService } from 'src/services/configuration.service';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { getFilterModel, getSortModel } from 'src/common/util/util';

@Component({
  selector: 'app-forecast-engine-item-manager',
  templateUrl: './forecast-engine-item-manager.component.html',
  styleUrls: ['./forecast-engine-item-manager.component.scss']
})
export class ForecastEngineItemManagerComponent implements OnInit {

  totalRows = 0;
  rowModelType = 'serverSide';
  serverSideStoreType = 'partial';
  paginationPageSize = 100;
  cacheBlockSize = 100;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public defaultColDef = defaultColDef;
  public columnDefs: any = [];
  isGridLoaded: boolean = false;
  selectedRows: any = [];
  frameworkComponents = {
    productItemEditRenderer: ProductItemEditRenderer,
  };
  columnHiddenDefs: any = [];
  hiddenGridApi: GridApi;

  releaseFromDate;
  releaseToDate;
  node1: string = '';
  node2: string = '';
  filterEvent: any = {};

  productHierarchyMetadataList: any = [];
  productHierarchyNodeMetadataList: any = [];

  actionSubscription: Subscription;
  itemSetupSubscription: Subscription;

  constructor(
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public productHierarchyService: ProductHierarchyService,
    private itemManagerService: ForecastItemManagerService,
  ) { }


  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  ngOnInit() {
    this.listenSubscriptions();
    this.GetProductMetadataList();
  }

  listenSubscriptions() {
    this.actionSubscription = this.itemManagerService.getProductGridActionCellSubject().subscribe(data => {
      if (data) {
        const itemDetailData = {
          isEdit: true,
          itemData: data.itemData
        };
        this.openItemSetupDetail(itemDetailData);
      }
    });
    this.itemSetupSubscription = this.itemManagerService.getProductSetupActionSubject().subscribe(data => {
      if (data) {
        this.createDataSource();
      }
    });
  }

  GetProductMetadataList() {
    this.productHierarchyService.GetProductHierarchyList().subscribe(response => {
      this.productHierarchyMetadataList = response.productMetadataList;
      this.productHierarchyService.productHierarchyMetadataList = response.productMetadataList;
      this.GetHierarchyNodeMetadataList();
    });
  }

  GetHierarchyNodeMetadataList() {
    this.productHierarchyService.GetHierarchyNodeMetadataList().subscribe(response => {
      this.productHierarchyNodeMetadataList = response.productHierarchyNodeMetaList;
      this.productHierarchyService.productHierarchyNodeMetadataList = response.productHierarchyNodeMetaList;
      this.columnDefs = getColDefs(this.productHierarchyMetadataList, this.productHierarchyNodeMetadataList);
      this.columnHiddenDefs = getExportTemplateColDefs(this.productHierarchyMetadataList, this.productHierarchyNodeMetadataList);
      this.isGridLoaded = true;
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.createDataSource();
  }

  onHiddenGridReady(params) {
    this.hiddenGridApi = params.api;
  }

  onExportItemTemplate() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Item-Template',
      colDefs: this.columnHiddenDefs,
      isExportTemplate: true,
      allColumns: true
    });
    this.hiddenGridApi.exportDataAsCsv(csvExportParams);
  }

  createDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody: any = {
          // 0 base index of page number
          offSet: params.request.startRow,
          pageSize: this.paginationPageSize,
          ...this.filterEvent
        };

        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);

        this.itemManagerService.DimItemGetList(requestBody).pipe(
          tap(() => this.gridApi.hideOverlay())
        ).subscribe((response: any) => {
          const gridData = response.data;
          const totalRecord = _.get(response, 'totalRecord', 0);
          this.totalRows = totalRecord;
          this.totalRows = response.data.length;
          params.successCallback(gridData, response.data.length);
          this.deselectNode();
          response.data.length == 0 && this.gridApi.showNoRowsOverlay();
          this.gridApi && this.gridApi.setRowData(gridData);
          this.spinner.hide();
        });
      },
    };
    this.gridApi && this.gridApi.setServerSideDatasource(datasource);
  }

  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }


  openItemSetupDetail(data = {}): void {
    const itemSetupComponent = {
      menuId: '448',
      name: 'Item setup ',
      selector: 'app-item-setup',
      displayName: 'Item Setup',
      module: 'ForecastEnginModule',
      data: data,
      component: ItemSetupComponent
    }
    this.configurationService.menuRendererSubject.next(itemSetupComponent);
  }


  exportItemListGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Item Setup',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  applyFilterEvent() {
    this.filterEvent = {
      ...this.releaseFromDate && { ReleaseFromDate: this.releaseFromDate },
      ...this.releaseToDate && { ReleaseToDate: this.releaseToDate },
      ...this.node1 && { Node1: this.node1 },
      ...this.node2 && { Node2: this.node2 },
    };
    this.createDataSource();
  }

  clearValues() {
    this.releaseFromDate = '';
    this.releaseToDate = '';
    this.node1 = '';
    this.node2 = '';
    this.applyFilterEvent();
  }

  importDataByTemplate() {
    (document.getElementById('upload-item-setup-data') as any).value = '';
    document.getElementById('upload-item-setup-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();

    if (fileInput.target.files && fileInput.target.files.length) {
      const [files] = fileInput.target.files;

      formDataReader.append('file', files);
      this.uploadImportedItemCsvFile(formDataReader);
    }
  }

  uploadImportedItemCsvFile(file) {
    this.itemManagerService.ImportItemManagerTemplate(file).subscribe(
      (res: any) => {
        if (res && res.length) {
          const successMessages = [];
          const errorMessages = [];
          const warningMessages = [];
          const infoMessages = [];
          res.forEach(element => {

            switch (element.messageType) {
              case 'Success':
                successMessages.push(element.messageText);
                break;
              case 'Warning':
                warningMessages.push(element.messageText);
                break;
              case 'Info':
                infoMessages.push(element.messageText);
                break;
              case 'Error':
                errorMessages.push(element.messageText);
                break;
              default:
                break;
            }
          });

          if (errorMessages.length)
            this.toastr.error('Error', `${errorMessages.map(a => a).join('<br />')}`);
          if (successMessages.length)
            this.toastr.success('Success', `${successMessages.map(a => a).join('<br />')}`);
          if (warningMessages.length)
            this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
          if (infoMessages.length)
            this.toastr.info('Info', `${infoMessages.map(a => a).join('<br />')}`);

          this.createDataSource();
        } else {
          this.toastr.warning('Warning', 'Item Data Import Failed.');
        }
      },
      (err: any) => {
        this.toastr.error('Error', 'Item Data Import Failed.');
      });
  }


}
