import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { LocationHierarchyService } from 'src/services/setup-services/location-hierarchy.service';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-location-hierarchy',
  templateUrl: './location-hierarchy.component.html',
  styleUrls: ['./location-hierarchy.component.scss']
})
export class LocationHierarchyComponent implements OnInit {
  hierarchyNodes: any[] = [{
    locationHierarchyNodeLevel: 1,
    locationHierarchyNodeName: ''

  },
  {
    locationHierarchyNodeLevel: 2,
    locationHierarchyNodeName: ''
  }];
  hierarchyLevels: any[] = [];
  hierarchyLevel: any;
  hierarchyName: any;
  isDisable: boolean;

  constructor(public locationHierarchyService: LocationHierarchyService,
    public toastr: NgxToasterService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialog: MatDialog,
  ) { }


  get addLevel(): boolean {
    return this.hierarchyLevels.length < this.locationHierarchyService.locationHierarchyMaximumLevel;
  }

  ngOnInit() {
    this.getHierarchyList();
    this.getHierarchyNodeMetadataList();
  }

  getHierarchyList() {
    this.locationHierarchyService.GetLocationHierarchyListMetaDataList().subscribe(response => {
      if (response) {
        this.hierarchyLevels = response.locationMetadataList;
        // this._dataService.setData(response.productMetadataList);
        if (response.locationMetadataList.length >= 2) {
          this.isDisable = true;
        }
      }
    },
      err => {
        this.toastr.error('Error', 'System failed to Get Location Hierarchy.');
      });

  }

  getHierarchyNodeMetadataList() {
    this.locationHierarchyService.GetLocationHierarchyListNodeMetadataList().subscribe(response => {
      if (response.locationHierarchyNodeMetaList.length > 0) {
        this.hierarchyNodes = response.locationHierarchyNodeMetaList;
      }
    },
      err => {
        this.toastr.error('Error', 'System failed to Get Location Hierarchy.');
      });

  }

  isAllHierarchyValueAvailable(hierarchyValuesList) {
    const valuesListOnly = _.map(hierarchyValuesList, a => a.locationHierarchyName);
    return valuesListOnly.length === _.compact(valuesListOnly).length;
  }

  isAllNodeValueAvailable(hierarchyNodes) {
    const valuesListOnly = _.map(hierarchyNodes, a => a.locationHierarchyNodeName);
    return valuesListOnly.length === _.compact(valuesListOnly).length;
  }

  isAllCombinationUniq(hierarchyValuesList) {
    let isValidCombination = true;
    const newHierarchyValues = _.map(_.cloneDeep(_.orderBy(hierarchyValuesList, ['locationHierarchyLevel'], ['asc'])), a => a.locationHierarchyName.toLowerCase());
    if (newHierarchyValues.length === _.uniq(newHierarchyValues).length) {
      return true;
    } else {
      isValidCombination = false;
    }
    return isValidCombination;
  }

  onSubmit() {
    const isAllValuesAvailable = this.isAllHierarchyValueAvailable(this.hierarchyLevels);
    const isAllCombinationUniq = isAllValuesAvailable && this.isAllCombinationUniq(this.hierarchyLevels);
    const isAllNodeValuesAvailable = this.isAllNodeValueAvailable(this.hierarchyNodes);

    if (this.hierarchyLevels.length >= this.locationHierarchyService.locationHierarchyMinimumLevel) {
      if (isAllValuesAvailable && isAllCombinationUniq && isAllNodeValuesAvailable) {
        const payload = {
          locationHierarchyMetadataList: this.hierarchyLevels,
          locationHierarchyNodeMetadataList: this.hierarchyNodes,
        };
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: {
            payload: payload,
            confirmationMessage: this.isDisable !== true ? 'Hierarchy levels can only be selected once, please confirm before saving.' : ''
          }
        });
        this.dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.locationHierarchyService.UpdateLocationHierarchyList(payload).subscribe(res => {
              if (res) {
                this.isDisable = res;
                this.toastr.success('Success', 'Product Hierarchy Created successfully.');
              }
            },
              err => {
                this.toastr.error('Error', 'System failed to Created Location Hierarchy.');
              });
          }
        });
      } else {
        if (!isAllValuesAvailable || !isAllCombinationUniq  || !isAllNodeValuesAvailable) {
          this.toastr.error('Error', 'Please fill data in correct format.');
        }
      }
    } else {
      this.toastr.error('Error', 'Please Add minimum two hierarchy levels.');
    }
  }


  addHierarchyLevel() {
    this.hierarchyLevels.push({
      locationHierarchyDisplayName: '',
      locationHierarchyLevel: this.hierarchyLevels.length + 1,
      hierarchyName: '',
    });
  }

  deleteHierarchyLevel(event) {
    this.hierarchyLevels = _.remove(this.hierarchyLevels, level => level.locationHierarchyLevel !== event);
    let hierarchyLevelIndex = 1;
    this.hierarchyLevels.forEach(hierarchy => {
      hierarchy.locationHierarchyLevel = hierarchyLevelIndex;
      hierarchyLevelIndex = hierarchyLevelIndex + 1;
    });
  }


}
