import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { USER_ID } from 'src/common/keys';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';

@Component({
  selector: 'app-user-interface',
  templateUrl: './user-interface.component.html',
  styleUrls: ['./user-interface.component.css']
})
export class UserInterfaceComponent implements OnInit {
  xAxis = [];
  yAxis = [];
  colorScheme = {
    domain: ['#E44D25', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  chartDatasets = [];
  chartDatasetsBar = [];
  public chartType = 'Line';
  @ViewChild('chart', { static: true }) chart: any;
  view: any[] = [800, 500];
  public columnDefs = [
    {
      headerName: 'Menu',
      field: 'screenName',
      minWidth: 50,
      resizable: true
    },
    {
      headerName: 'User',
      field: 'username',
      minWidth: 50,
      resizable: true
    },
    {
      headerName: 'Count',
      field: 'counter',
      minWidth: 400,
      resizable: true
    }
  ];
  @Input() isMyActivity: any;
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public userList = [];

  public user: any;
  public allActivityList = [];
  public userActivityList = [];
  constructor(public router: Router, public configService: ConfigurationService, public storage: LocalstorageService) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getUserInterfaceActivityList();
  }
  public getUserInterfaceActivityList = () => {
    const userId = this.storage.get(USER_ID);
    const params = {
      userId: userId,
      clientId: environment.ClientId
    };
    this.configService.GetUserInterfaceActivityAll(params).subscribe(res => {
      this.allActivityList = res;
      this.chartDatasetsBar = this.allActivityList.map(row => ({ name: row.menuName, value: row.counter }))
      this.chartDatasets = [{
        name: '',
        series: this.allActivityList.map(row => ({ name: row.menuName, value: row.counter }))
      }]
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {
    this.router.navigate(['addUser'], {
      queryParams: {
        id: btoa($event.data.userId)
      }
    });
  }
  public navigateToRoles = () => {
    this.router.navigate(['Roles']);
  }
  onSelect = () => {}
}
