<div class="container-fluid mapping-manager-page">

  <div class="mapping-action-btn-bar">

    <ng-container *ngIf="azpipeline">
      <button matTooltipPosition="right" matTooltip="Data Sync Status {{azpipeline.status}}, &nbsp;Last Sync {{azpipeline.lastUpdated| date: 'dd/MM/yyyy HH-mm'}} &nbsp;" mat-icon-button class="mx-2 sync-btn" [ngClass]="setSyncButtonClassFunction(azpipeline.status)"
        (click)="triggerMappingManagerPipeline()">
        <i class="material-icons">
          sync
        </i>
      </button>
      <!-- <span style="margin-left: 8px;" *ngIf="azpipeline" class="sync-msg">Data Sync Status {{azpipeline.status}} &nbsp;<br>Last Sync
        {{azpipeline.lastUpdated| date: "dd/MM/yyyy HH-mm"}} &nbsp; </span> -->
    </ng-container>
    <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>
    <div class="grid-actions inline-flex">
      <div *ngIf="selectedRows.length" class="row-info">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{gridData.length}}</strong>
        </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{getGridRowsCount}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>
    <button mat-flat-button color="accent" class="ml-10" (click)="viewUnmappedItems()">View Un-Mapped Items</button>

    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="importExportMenu">Export/Import</button>
    <mat-menu #importExportMenu="matMenu">
      <button mat-menu-item>Export</button>
      <button mat-menu-item>Import
        <input class="hidden" type="file" id="upload-promotion-planner-detail-data"
          name="upload-promotion-planner-detail-data" accept=".csv">
      </button>
    </mat-menu>

    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="actionMenu">Actions</button>
    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="openMappingSetupScreen(true)">Modify</button>
      <button mat-menu-item (click)="selectedRows.length && updateForecaseItemStatus(true)">Accept</button>
      <button mat-menu-item (click)="selectedRows.length && updateForecaseItemStatus(false)">Reject</button>
      <button mat-menu-item (click)="selectedRows.length && deleteForecastItem()">Delete</button>
    </mat-menu>

    <button class="add-icon-btn ml-10" (click)="openMappingSetupScreen()" matTooltip="Add Mapping Manager" mat-icon-button
      color="primary" type="button">
      <i class="material-icons">
        add_circle
      </i>
    </button>

  </div>

  <div class="all-show-grid forecast-engine-ag-grid" >

    <ag-grid-angular #mappedSKUsGrid id="outlier-mapped-sku-data-grid" class="ag-theme-material mapped-skus-grid"
      [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [gridOptions]="gridOptions"   [suppressRowClickSelection]="true"
      [rowData]="gridData" [rowSelection]="rowSelection" [animateRows]="true" [pagination]="true"
      [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
      (selectionChanged)="rowSelectionChanged($event)" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

</div>
