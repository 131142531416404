export const APP = 'ALGO-PLUS';
export const USER_ID = `${APP}:USER:ID`;
export const CLIENT_ID = `${APP}:CLIENT:ID`;
export const USER_INFO = `${APP}:USER:INFO`;
export const USER_INFO_USER = `${APP}:USER:INFO:USER`;
export const USER_ROLES = `${APP}:USER:ROLES`;
export const USER_TEAMS = `${APP}:USER:TEAMS`;
export const USER_TOKEN_INFO = `${APP}:USER:TOKEN:INFO`;
export const SELECTED_LANGUAGE = `${APP}:USER:SELECTED::LANGUAGE`;
export const BOT_TOKEN_INFO = `${APP}:BOT:TOKEN:INFO`;
export const MICROSOFT_TOKEN_INFO = `${APP}:MICROSOFT:TOKEN:INFO`;
export const BOT_CONVERSATION_INFO = `${APP}:BOT:CONVERSATION:INFO`;
export const INSPECTOR_ROLE = 'inspector';
export const ADMIN_ROLE = 'admin';
export const VEHICLE_STATE = `${APP}:VEHICLE:STATE`;
export const VEHICLE_DELIVERY_STATE = `${APP}:VEHICLE:DELIVERY:STATE`;
export const DOCUSIGN_URL = `${APP}DOCUSIGN:URL`;
export const REG_NO = `${APP}REGNO`;
export const DATE_FORMAT = 'dd/MM/yyyy';
export const INVALID_TEAM = 'INVALID';
export const INVALID_ROLE = 'INVALID';
export const RETAILER_TEAM_CODE = 'BB_RT';
export const VENDOR_TEAM_CODE = 'BB_VT';
export const BEST_BUY_ROLE_CODE = 'R_BB';
export const DISNEY_ROLE_CODE = 'V_DIS';
export const LIONSGATE_ROLE_CODE = 'V_LIO';
export const MILL_CREEK_ROLE_CODE = 'V_MIL';
export const PARAMOUNT_ROLE_CODE = 'V_PAR';
export const SDS_ROLE_CODE = 'V_SDS';
export const SONY_ROLE_CODE = 'V_SON';
export const PLANOGRAM_AUTH_KEY = 'PLANOGRAM_AUTHORIZATION_KEY';
export const USER_PREFERNCE_KEY = 'USER_PREFERNCES';
export const MASTER_ITEMS = 'MASTER_ITEMS';
export const CHILD_ITEMS = 'CHILD_ITEMS';
export const NEW_ITEM_PROMOTION_LIST = 'NEW_ITEM_PROMOTION_LIST';
export const NEW_ITEM_PROMOTION_DETAIL = 'NEW_ITEM_PROMOTION_DETAIL';
export const NEW_ITEM_PROMOTION_DETAIL_APROVAL = 'NEW_ITEM_PROMOTION_DETAIL_APROVAL';
export const NEW_ITEM_PROMOTION_FORECAST_APROVAL = 'NEW_ITEM_PROMOTION_FORECAST_APROVAL';
export const PROMOTION_PLANNER_LIST = 'PROMOTION_PLANNER_LIST';
export const PROMOTION_PLANNER_DETAIL = 'PROMOTION_PLANNER_DETAIL';
export const PROMOTION_PLANNER_FORECAST = 'PROMOTION_PLANNER_FORECAST';
export const CLIENT_INFO = `${APP}:CLIENT_INFO`;
export const CLIENT_PREFERNCE_KEY = `${APP}:CLIENT_PREFERNCE`;
export const CLIENT_WEEK_START_DAY = `CLIENT_WEEK_START_DAY`;
export const JOURNEY_MY_LIST = `JOURNEY_MY_LIST`;
export const JOURNEY_SHARED_LIST = `JOURNEY_SHARED_LIST`;
export const JOURNEY_ACTIVITY_LIST = `JOURNEY_ACTIVITY_LIST`;
