<!-- Filter Section Start -->
<div class="row approval-grid-filter-row">
  <div class="approval-filter-field">
    <mat-form-field appearance="fill" >
      <mat-select multiple placeholder="Year" [(ngModel)]="years" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let year of yearList"  [value]="year">{{year}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill" >
      <mat-select multiple placeholder="Make" [(ngModel)]="makes" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let make of makeList"  [value]="make">{{make}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill" >
      <mat-select multiple placeholder="Model" [(ngModel)]="models" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let model of modelList"  [value]="model">{{model}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="Brand" multiple [(ngModel)]="brands" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let brand of brandList" [value]="brand">{{brand}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="Category Group" [(ngModel)]="promoName" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let promoName of promoNameList" [value]="promoName">{{promoName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="Submission Month" [(ngModel)]="month" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="Submitted By" [(ngModel)]="submittedBy" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let submittedBy of submittedByList" [value]="submittedBy">{{submittedBy}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="Fulfillment Type" [(ngModel)]="fulfillmentType" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let fulfillmentType of fulfillmentTypeList" [value]="fulfillmentType">{{fulfillmentType}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="SKU Status" multiple [(ngModel)]="actions" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let action of actionList" [value]="action">{{action}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-clear-btn">
    <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
  </div>
</div>
<!-- End Filter Section End -->