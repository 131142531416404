<div class="dialog-close-btn">
  <button mat-icon-button (click)="onNoClick()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>

<form [formGroup]="itemForm" class="item-dialog-form" (ngSubmit)="itemForm.valid && onSubmit()">
  <div mat-dialog-content>

    <h5>Create/Edit Item</h5>

    <div class="row">
      <div class="col-md-3">
        <div>
          <div *ngIf="!imageSrc" class="no-image-class">
            <label>Image</label>
          </div>
          <img [src]="imageSrc" *ngIf="imageSrc" alt="Image" style="height: 300px; max-width:100%">
        </div>
        <div class="file-controls">
          <label class="file-upload">
            <input #itemFile formControlName="file" size="60" id="file" type="file" class="form-control"
              (change)="onFileChange($event)">
            Upload Image
          </label>
          <button mat-button type="button" (click)="clearItemImage()">Clear Image</button>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row">

          <div class="col-md-2 product-field-label">
            <strong>Title*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput formControlName="Title">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Type*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="Class">
                <mat-option *ngFor="let class of classList" [value]="class">
                  {{class}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>UPC*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput [readonly]="isEditMode" formControlName="UPC">
            </mat-form-field>
            <div *ngIf="UPC.invalid && (UPC.dirty || UPC.touched)" class="alert alert-danger">
              UPC must be a unique 12 digit numeric value.
            </div>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Subtype*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="SubClass">
                <mat-option *ngFor="let subClass of subClassList" [value]="subClass">
                  {{subClass}}
                </mat-option>
              </mat-select>
              <input *ngIf="isEditMode" readonly matInput formControlName="subClassName">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Make*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="Make">
                <mat-option *ngFor="let make of makeList" [value]="make">
                  {{make}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Retail*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput type="number" formControlName="Retail">
            </mat-form-field>
          </div>
          
          <div class="col-md-2 product-field-label">
            <strong>Model*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="Model">
                <mat-option *ngFor="let model of modelList" [value]="model">
                  {{model}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
          <div class="col-md-2 product-field-label">
            <strong>Cost*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput type="number" formControlName="Cost">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Brand*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="Brand">
                <mat-option *ngFor="let brand of brandList" [value]="brand">
                  {{brand}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Manufacture Part Number*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <input matInput formControlName="ManufacturePartNumber">
            </mat-form-field>
          </div>

          <div class="col-md-2 product-field-label">
            <strong>Year*</strong>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-select formControlName="Year">
                <mat-option *ngFor="let year of yearList" [value]="year">
                  {{year}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div mat-dialog-actions style="justify-content: flex-end;">
    <button type="button" *ngIf="data && data.isEdit" mat-button mat-stroked-button color="accent"
      (click)="openRemoveDialog()">Delete</button>
    <button type="button" mat-button mat-flat-button color="accent" (click)="onNoClick()">Cancel</button>
    <button type="submit" mat-button mat-flat-button color="accent" cdkFocusInitial>Save</button>
  </div>
</form>