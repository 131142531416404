import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/services/configuration.service';

@Component({
  selector: 'app-create-journey',
  templateUrl: './create-journey.component.html',
  styleUrls: ['./create-journey.component.scss'],
})
export class CreateJourneyComponent implements OnInit {

  isEdit: boolean = false;
  journeyForm = new FormGroup({
    journeyId: new FormControl(null),
    journeyName: new FormControl(null, Validators.required),
    actionableBy: new FormControl(null, Validators.required),
    dueBy: new FormControl(null, Validators.required),
    journeyOppertunityTypeId: new FormControl(null, Validators.required),
    journeyCategoryId: new FormControl(null, Validators.required),
    assignedValue: new FormControl(null, Validators.required),
  });

  opportunityTypeList: any = [];
  categoryList: any = [];
  dueDateMin: Date;

  constructor(
    public dialogRef: MatDialogRef<CreateJourneyComponent>,
    private configService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  get getCategoryList() {
    if (this.journeyForm && this.categoryList.length) {
      const journeyOppertunityTypeId = this.journeyOppertunityTypeId.value;
      if (journeyOppertunityTypeId) {
        const categoryList = _.filter(this.categoryList, a => a.journeyOppertunityTypeId === journeyOppertunityTypeId);
        return categoryList;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  get journeyOppertunityTypeId() {
    return this.journeyForm.get('journeyOppertunityTypeId');
  }

  ngOnInit() {
    this.initEditMode();
    this.addCustomValidators();
    this.getJourneyOppertunityTypes();
  }

  initEditMode() {
    this.isEdit = this.data?.isEdit || false;
    if (this.isEdit) {
      this.journeyForm.patchValue(this.data.journey);
    }
  }

  getJourneyOppertunityTypes() {
    this.configService.GetJourneyOppertunityTypes().subscribe((res: any) => {
      if (res) {
        this.opportunityTypeList = res.journeyOppertunityTypes;
        this.categoryList = res.journeyCategories;
      }
    });
  }

  addCustomValidators() {
    setTimeout(() => {
      this.journeyForm.controls['dueBy'].setValidators([Validators.required, this.dueDateValidator]);
    }, 0);
  }

  dueDateValidator(control: FormControl) {
    let actionableBy = control.parent.controls['actionableBy'].value;
    const dueDate = control.value;
    if (actionableBy) {
      actionableBy = new Date(actionableBy);
      if (dueDate < actionableBy) {
        return {
          dueDate: 'Actionable date should be less then due date.'
        };
      }
      return null;
    } else {
      return null;
    }
  }

  actionableDateChange(event) {
    this.setDueDateMin();
  }

  setDueDateMin() {
    const actionableDate = this.journeyForm.get('actionableBy').value;
    if (actionableDate) {
      this.dueDateMin = new Date(actionableDate);
    }
  }

  save() {
    const payload = {
      ...this.journeyForm.value,
      oppertunityTypeDescription: this.opportunityTypeList.find(a => a.journeyOppertunityTypeId == this.journeyOppertunityTypeId.value).oppertunityTypeDescription
    };
    this.dialogRef.close(payload);
  }
}
