<div class="row m-0 justify-content-between align-items-center">
    <div class="d-flex align-items-center justify-content-center" style="width: 33%;">
        <div class="d-flex align-items-center justify-content-center w-100" #bubbleChart1>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center" style="width: 33%;">
        <div class="d-flex align-items-center justify-content-center w-100" #bubbleChart2>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center" style="width: 33%;">
        <div class="d-flex align-items-center justify-content-center w-100" #bubbleChart3>
        </div>
    </div>
</div>

<hr class="theme-hr" />

<div class="row m-0 justify-content-between align-items-center">
    <div class="d-flex align-items-center justify-content-center" style="width: 40%;">
        <div class="d-flex align-items-center justify-content-center w-100" #spiderChart>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center" style="width: 40%;">
        <div class="d-flex align-items-center justify-content-center w-100" #barChart>
        </div>
    </div>
</div>

<hr class="theme-hr" />

<ng-container *ngIf="isOutputTab">

    
<!-- GRAPH -->
    <div class="d-flex align-items-center justify-content-center m-3">
        <mat-form-field appearance="outline" style="width: 220px !important;">
            <mat-label>x-Axis</mat-label>
            <mat-select (selectionChange)="axisChanged()" [(ngModel)]="xAxis">
                <mat-option *ngFor="let item of columnVariables" [value]="item.id">{{item.label}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 220px !important;" class="ml-2">
            <mat-label>y-Axis</mat-label>
            <mat-select (selectionChange)="axisChanged()" [(ngModel)]="yAxis">
                <mat-option *ngFor="let item of columnVariables" [value]="item.id">{{item.label}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 220px !important;" class="ml-2">
            <mat-label>Segment</mat-label>
            <mat-select (selectionChange)="axisChanged()" multiple [(ngModel)]="selectedSegments">
                <ng-container *ngFor="let item of allSegments">
                    <mat-option *ngIf="item.uu_id!=SEGMENTATION_OUTLIER_ID" [value]="item.uu_id">{{item.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row m-0 justify-content-center align-items-center">
        <div class="d-flex align-items-center justify-content-center" style="width: 50%;">
            <div class="d-flex align-items-center justify-content-center w-100" #scatterChart>
            </div>
        </div>
    </div>

    <hr class="theme-hr" />

</ng-container>
