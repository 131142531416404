import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProcurementService } from 'src/services/procurement.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pms-add-edit-purchase-request',
  templateUrl: './pms-add-edit-purchase-request.component.html',
  styleUrls: ['./pms-add-edit-purchase-request.component.scss'],
})
export class PmsAddEditPurchaseRequestComponent implements OnInit {
  itemsArray: FormArray;
  pmsForm: FormGroup;
  locationList: any = [];
  itemList: any = [];
  requirementTypeList: any = [];
  purchaseTypeList: any = [
    {
      name: 'Goods',
      id: 1,
    },
    {
      name: 'services',
      id: 2,
    },
  ];

  uniqueId = '';

  uploadedFile = {
    name: '',
    content: null,
  };
  formData: FormData;

  constructor(
    public dialogRef: MatDialogRef<PmsAddEditPurchaseRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private procurementService: ProcurementService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.uniqueId = this.getUniqueId();
    this.initializeForm();
    this.initateEditMode();
    this.getLookups();
  }

  initateEditMode() {
    if (this.data.isEdit) {
      this.setFormValues(this.data);
      this.fetchItemDetails(this.data.selectedRow.prId);
    }
  }

  fetchItemDetails(id) {
    this.itemsArray.removeAt(0);
    this.spinner.show();
    this.procurementService.getPRItemGetListbyId(id).subscribe(
      (res: any) => {
        this.spinner.hide();
        res.forEach((element, index) => {
          this.addItem();
          (this.itemsArray.controls[index] as FormGroup).patchValue(element);
          this.itemsArray.controls[index].disable();
        });
        this.pmsForm.disable();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  setFormValues(data) {
    this.pmsForm.get('prTitle').setValue(data.selectedRow.prTitle);
    this.pmsForm.get('locationId').setValue(data.selectedRow.locationId);
    this.pmsForm
      .get('requirementTypeId')
      .setValue(data.selectedRow.requirementTypeId);
    this.pmsForm
      .get('purchaseTypeId')
      .setValue(data.selectedRow.purchaseTypeId);
  }

  getLookups() {
    this.procurementService.getLocationsListing().subscribe((res) => {
      this.locationList = res;
    });
    this.procurementService.getItemsListing().subscribe((res) => {
      this.itemList = res;
    });
    this.procurementService.getRequirementsLookup().subscribe((res) => {
      this.requirementTypeList = res;
    });

    this.procurementService.getPurchaseTypeList().subscribe((res) => {
      this.purchaseTypeList = res;
    });
  }

  getUniqueId = (parts = 2): string => {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      const S4 = (((1 + Math.random()) * 0x10000) | 0)
        .toString(16)
        .substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('_');
  };

  initializeForm() {
    this.pmsForm = this.formBuilder.group({
      prTitle: [null, Validators.required],
      locationId: [null, Validators.required],
      requirementTypeId: [null, Validators.required],
      purchaseTypeId: [null, Validators.required],
      items: new FormArray([]),
    });

    this.itemsArray = this.pmsForm.get('items') as FormArray;
    this.addItem();
  }

  addItem() {
    const formGroupObj = new FormGroup({
      itemId: new FormControl(null, Validators.required),
      quantity: new FormControl(null, Validators.required),
      estimatedAmount: new FormControl(null, Validators.required),
      itemSpecifications: new FormControl(null, Validators.required),
      reason: new FormControl(null, Validators.required),
    });
    this.itemsArray.push(formGroupObj);
    this.formData = new FormData();
  }

  removeItem(index: number) {
    if (this.data.isEdit) return;
    this.itemsArray.removeAt(index);
    this.itemsArray.length == 0 && this.addItem();
  }

  uploadFileBtn() {
    document.getElementById('pms-create-pr-btn' + this.uniqueId) &&
      document.getElementById('pms-create-pr-btn' + this.uniqueId).click();
  }

  onFileSelect(event) {
    const files = event.target.files as FileList;
    const file = files[0];

    this.uploadedFile = {
      name: '',
      content: null,
    };

    this.formData.append('file', file);
    this.uploadedFile.name = file.name;
  }

  submit() {
    this.spinner.show();
    const data = this.pmsForm.value;
    const body = {
      prTitle: data.prTitle,
      locationId: data.locationId,
      requirementTypeId: data.requirementTypeId,
      purchaseTypeId: data.purchaseTypeId,
      prDate: moment(new Date()).format('YYYY-MM-DD'),
      statusDescription: '',
      updated: false,
      purchaseReason: '',
      isBudgeted: false,
      estimatePrice: 0,
      markAsDelete: false,
      bidsApproved: false,
      pOcreated: false,
      bidsCalled: false,
      isLimit: true,
      invoiceAttached: true,
      rejectionReason: '',
      grnCreated: false,
      approveDate: moment(new Date()).format('YYYY-MM-DD'),
    };
    if (this.formData.has('file') === true) {
      this.procurementService.addPurchaseRequest(body).subscribe(
        (res: any) => {
          if (res) {
            this.formData.append('PRId', res);
            this.formData.append('DocumentId', '0');
            let aFormArray: FormArray = this.pmsForm.get('items') as FormArray;
            for (let c of aFormArray.controls) {
              const payload = {
                prId: res,
                itemId: c.get('itemId').value,
                itemSpecifications: c.get('itemSpecifications').value,
                quantity: c.get('quantity').value,
                estimatedAmount: c.get('estimatedAmount').value,
                reason: c.get('reason').value,
                markAsDelete: true,
                brandId: 0,
              };
              console.log('items Paylod' + payload);
              this.procurementService.addPrItem(payload).subscribe((res) => {
                console.log('🚀 this.procurementService.addPrItem ~ res', res);
              });
            }
            this.procurementService.uploadDocument(this.formData).subscribe(
              (res: any) => {
                if (res && res.payload) {
                  window.open(res.payload, '_blank');
                }
                this.toastrService.success(
                  'Purchase Request added successfully.'
                );
                this.spinner.hide();
                this.dialogRef.close(true);
              },
              (err) => {
                this.toastrService.error('Failed to add Purchase Request!', 'Error');
                this.spinner.hide();
              }
            );
          }
        },
        (err) => {
          this.toastrService.error('Failed to add Purchase Request!', 'Error');
          this.spinner.hide();
        }
      );
    } else {
      this.toastrService.error('Please Choose File!', 'Error');
      this.spinner.hide();
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
