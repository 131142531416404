<div class="container">
  <form action="">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <label><strong> Product Hierarchy Setup </strong></label>
      </div>
      <div class="col-md-3"></div>
    </div>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <table>
          <tr>
            <th *ngFor="let column of headers">{{column}}</th>
          </tr>
          <tr *ngFor="let levelData of rows">
            <td style="text-align:left" *ngIf="levelData.hierarchyLevel === '1'">{{'Account'}}</td>
            <td style="text-align:left" *ngIf="levelData.hierarchyLevel === '2'">{{'Brand'}}</td>
            <td style="text-align:left" *ngIf="levelData.hierarchyLevel === '3'">{{'Product Group'}}</td>
            <td style="text-align:left" *ngIf="levelData.hierarchyLevel === '4'">{{'Sku Number'}}</td>
            <td style="text-align:left" *ngIf="levelData.hierarchyLevel === '5'">{{'Sku Description'}}</td>
            <td><input type="text" [(ngModel)]="levelData.hierarchyName" [ngModelOptions]="{standalone: true}"></td>
          </tr>
        </table>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
  <div class="action-btns">
    <button mat-flat-button color="accent" class="ml-10" (click)="onSubmit()">Save</button>
  </div>
</div>
