import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { cacheBlockSize, defaultColDef, generateGridColumns, generateSecondGridColumns, paginationPageSize, rowSelection } from './ag-grid/gridOptions';
import {  ColumnApi, GridApi, ServerSideStoreType } from 'ag-grid-enterprise';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ManageExceptionsComponent } from './dialogs/manage-exceptions/manage-exceptions.component';
import { ManageOutliersComponent } from './dialogs/manage-outliers/manage-outliers.component';
import { TransformDataComponent } from './dialogs/transform-data/transform-data.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { DataModuleService } from 'src/services/data-module.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { USER_INFO } from 'src/common/keys';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { UpdateNameDialogComponent } from 'src/common/update-name-dialog/update-name-dialog.component';
import { ManageOutlierButtonRendererComponent } from './ag-grid/manage-outlier-button-renderer';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { ShowProgressComponent } from './dialogs/show-progress/show-progress.component';
import { SaveVersionComponent } from './dialogs/save-version/save-version.component';

@Component({
  selector: 'app-data-module',
  templateUrl: './data-module.component.html',
  styleUrls: ['./data-module.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataModuleComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() fileObj: any;
  @Output() updateFilesModule = new EventEmitter();

  versionNo = 0;
  fileName = '';
  versionName = '';

  // Grid Variables
  rowModelType = 'serverSide';
  serverSideStoreType: any = 'partial';
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = rowSelection;

  public gridColumnDefs: any[] = [];
  public defaultColDef = defaultColDef;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public secondGridApi: GridApi;
  public secondColApi: ColumnApi;
  public gridOptions = {};
  gridData: any = [];
  frameworkComponents = {
    numberCellEditor: getNumericCellEditor(true)
  };

  secondFrameworkComponents = {
    manageOutlierEditor: ManageOutlierButtonRendererComponent
  };

  userObj = {} as any;
  correlationValue = 0.85;
  correlationMatrix = null;

  showFaulty = false;
  showDuplicates = false;
  showBlanks = false;
  showExceptions = false;
  showOutliers = false;
  showValid = false;

  isVariableView = false;
  selectedUniqueID = null;
  originalUniqueId = null;
  groupBy = null;

  changesMade = false;
  updatedRows = [];
  selectedGridNodes = [];
  correlationUpdated = false;

  dataStats: any;
  statisticsData = {} as any;
  metricsData = [];

  topOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: false,
      resizable: true,
      filter: false,
      flex: 1,
      minWidth: 100
    }
    ,
    suppressHorizontalScroll: false
  };
  bottomOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100
    }
  };
  bottomGridColumns = [];
  bottomGridData = [
    {
      stat: 'Sum'
    },
    {
      stat: 'Avg'
    },
    {
      stat: 'Min'
    },
    {
      stat: 'Max'
    },
    {
      stat: 'SD'
    },
    {
      stat: 'Count'
    },
    {
      stat: 'Unique Count'
    },
    {
      stat: 'Outlier'
    },
    {
      stat: ''
    }
  ];

  @ViewChild('topGrid') topGrid;
  @ViewChild('bottomGrid') bottomGrid;

  toggleAll = null;

  constructor(private spinner: NgxSpinnerService, private toastrService: ToastrService, private dialog: MatDialog, private localStorage: LocalstorageService, private dataModuleService: DataModuleService) { }

  ngOnInit(): void {
    this.userObj = this.localStorage.get(USER_INFO);
  }


  ngAfterViewInit(): void {
    this.versionNo = this.fileObj.uu_id;
    this.fileName = this.fileObj.name;

    this.fetchVersionDetails();

    this.topOptions.alignedGrids.push(this.bottomOptions);
    this.bottomOptions.alignedGrids.push(this.topOptions);
  }

  ngOnDestroy(): void {
  }


  toggleChanged(e) {
    this.toggleAll = e;
    if (e != null) {
      this.gridColumnDefs.forEach(element => {
        (element.field && element.field != this.selectedUniqueID) && (element.isMetricVariable = e);
      });
    }
    this.changesMade = true;
  }

  slideToggleChanged() {
    let trueCount = 0;
    let falseCount = 0;
    this.gridColumnDefs.forEach(element => {
      if (element.field && element.field != this.selectedUniqueID) {
        if (element.isMetricVariable) {
          trueCount++;
        } else {
          falseCount++;
        }
      }
    });
    const oldState = JSON.parse(JSON.stringify(this.toggleAll));
    if (trueCount && falseCount) {
      this.toggleAll = null;
    } else {
      if (trueCount) this.toggleAll = true;
      else this.toggleAll = false;
    }
    if (oldState != null && this.toggleAll == null) {
      this.toggleAll = !oldState;
      setTimeout(() => {
        this.toggleAll = null;
      }, 200);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  onSecondGridReady(params) {
    this.secondGridApi = params.api;
    this.secondColApi = params.columnApi;
  }

  fetchCorrelationMatrix() {
    this.spinner.show();
    this.dataModuleService.fetchCorrelationMatrix(this.fileName, this.userObj.userId, this.versionNo)
      .subscribe((res: any) => {
        if (res.payload) {
          this.correlationMatrix = res.payload.correlation;
          !this.correlationUpdated && (this.correlationValue = res.payload.correlation_value);
        } else {
          this.correlationMatrix = null;
        }
        this.spinner.hide();
      },
        err => {
          this.spinner.hide();
        });
  }

  fetchSummaryStats() {
    this.spinner.show();
    this.dataModuleService.fetchSummaryStats(this.fileName, this.userObj.userId, this.versionNo)
      .subscribe((res: any) => {
        this.statisticsData = {};
        res.payload && res.payload.statistics_data && res.payload.statistics_data.forEach(element => {
          const keyObj = Object.keys(element);
          const valueObj = Object.values(element);
          this.statisticsData[keyObj[0]] = valueObj[0][0];
        });
        this.spinner.hide();
        this.fetchOutliersData();
      },
        err => {
          this.spinner.hide();
        });
  }

  fetchOutliersData() {
    this.spinner.show();
    this.dataModuleService.fetchOutliersData(this.fileName, this.userObj.userId, this.versionNo)
      .subscribe((res: any) => {
        this.bottomGridData = [
          {
            stat: 'Sum'
          },
          {
            stat: 'Avg'
          },
          {
            stat: 'Min'
          },
          {
            stat: 'Max'
          },
          {
            stat: 'SD'
          },
          {
            stat: 'Count'
          },
          {
            stat: 'Unique Count'
          },
          {
            stat: 'Outlier'
          },
          {
            stat: ''
          }
        ];
        const keysObj = Object.keys(this.statisticsData);
        const valuesObj = Object.values(this.statisticsData) as any;
        keysObj.forEach((element, index) => {
          this.bottomGridData[0][element] = Math.round((valuesObj[index].sum + Number.EPSILON) * 100) / 100;
          this.bottomGridData[1][element] = Math.round((valuesObj[index].avg + Number.EPSILON) * 100) / 100;
          this.bottomGridData[2][element] = Math.round((valuesObj[index].min + Number.EPSILON) * 100) / 100;
          this.bottomGridData[3][element] = Math.round((valuesObj[index].max + Number.EPSILON) * 100) / 100;
          this.bottomGridData[4][element] = Math.round((valuesObj[index].standard_deviation + Number.EPSILON) * 100) / 100;
          this.bottomGridData[5][element] = Math.round((valuesObj[index].count + Number.EPSILON) * 100) / 100;
          res.payload.outliers_data.forEach(outlierData => {
            if (element == outlierData.column_name) {
              this.statisticsData[element].outliersData = outlierData;
              this.bottomGridData[6][element] = outlierData.unique_count;
              this.bottomGridData[7][element] = outlierData.outlier_count;
              this.bottomGridData[8][element] = outlierData;
            }
          });
        });
        this.secondGridApi && this.secondGridApi.setRowData(this.bottomGridData);
        this.spinner.hide();
      },
        err => {
          this.spinner.hide();
        });
  }

  onCellClicked(e) {
    let showOutlierClicked = false;
    e.event.srcElement.classList.forEach(element => {
      if (element.includes('mat-but')) showOutlierClicked = true;
    });
    if (showOutlierClicked) {
      this.openPopUp(2, e.value, e.value.column_name);
    }
  }

  gridDataUpdated(params) {
    const prvUpdatedRowStateIndex = _.findIndex(this.updatedRows, a => a.uu_id === params.data.uu_id);
    if (prvUpdatedRowStateIndex < 0) {
      this.updatedRows.push(params.data);
    } else {
      this.updatedRows[prvUpdatedRowStateIndex] = params.data;
    }
    this.changesMade = true;
  }

  saveChanges() {
    const updatedRowsData = this.updatedRows;
    updatedRowsData.forEach((element) => {
      const values = Object.values(element);
      const keys = Object.keys(element);
      values.forEach((elementValue, index) => {
        this.gridColumnDefs.forEach(metric => {
          if (metric.field == keys[index] && (elementValue != null) && !isNaN(+elementValue)) {
            element[keys[index]] = Number(elementValue);
          }
        });
      });
    });

    const variableData = [];
    this.gridColumnDefs.forEach(element => {
      element.field && variableData.push({
        name: element.field,
        displayName: element.headerName,
        is_matric: element.isMetricVariable
      });
    });

    const obj = {
      updated_grid: updatedRowsData,
      updated_variable_data: variableData,
      user_file: this.fileName,
      user_id: this.userObj.userId,
      version_no: this.versionNo,
      unique_id: this.selectedUniqueID,
      object_to_cluster: this.selectedUniqueID,
      group_by: this.groupBy,
      unique_id_changed: this.originalUniqueId != this.selectedUniqueID
    };
    this.spinner.show();
    this.dataModuleService.updateGridData(obj)
      .subscribe((res: any) => {
        if (res.code == '203.') {
          this.toastrService.error(res.payload);
          this.spinner.hide();
        }
        else {
          this.updateStats();
          this.toastrService.success('Data updated successfully.');
          // this.fetchVersionDetails();
          // this.updateFilesModule.emit(true);
        }
      },
        err => {
          this.spinner.hide();
        })
  }

  loadFileData() {
    this.updatedRows = [];
    this.changesMade = false;
    this.selectedGridNodes = [];
    if (this.gridApi) {
      const nodes = this.gridApi.getSelectedNodes();
      nodes.forEach(node => {
        node.setSelected(false);
      });
    }
    const datasource = {
      getRows: (params) => {
        const limit = params.request.startRow ? params.request.startRow + paginationPageSize + 1 : paginationPageSize + 1;
        const page = Math.floor(limit / paginationPageSize);
        this.spinner.show();
        this.dataModuleService.fetchFileDataById(this.fileName, this.userObj.userId, page,
          this.showFaulty, this.showBlanks, this.showDuplicates, this.showOutliers, this.showExceptions, this.versionNo, this.showValid)
          .pipe(
            tap(() => this.gridApi.hideOverlay())
          )
          .subscribe((res: any) => {
            this.gridData = res.payload.results;

            const timeOut = 100;
            setTimeout(() => {
              this.gridData.map((e) => {
                e.uu_id = e.uu_id || e.mongo_id;
                e.update_duplicate = false;
                e.update_blanks = false;
                e.update_faulty = false;
              });

              // SET VARIABLES
              params.successCallback(this.gridData, res.payload.count);
              this.gridData.length == 0 && this.gridApi.showNoRowsOverlay();
              this.spinner.hide();
            }, timeOut);
          },
            err => {
              this.spinner.hide();
            });
      },
    };
    setTimeout(() => {
      this.gridApi && this.gridApi.setServerSideDatasource(datasource);
    }, 0);
  }

  openPopUp(type: number, outlierData?: [], columnName = null) {
    if (!this.originalVersionValidation()) return;
    let popUpComponent;
    type == 1 && (popUpComponent = ManageExceptionsComponent);
    type == 2 && (popUpComponent = ManageOutliersComponent);
    type == 3 && (popUpComponent = TransformDataComponent);
    if (!popUpComponent) return;
    const popUpRef = this.dialog.open(popUpComponent, {
      minWidth: '60vw',
      data: {
        versionNo: this.versionNo,
        fileName: this.fileName,
        userId: this.userObj.userId,
        columns: this.metricsData,
        outlierData: outlierData || null,
        columnName: columnName
      }
    });
    popUpRef.afterClosed().subscribe(result => {
      if (result) {
        if (type == 2) this.fetchOutliersData();
      }
    });
  }

  updateStats() {
    if (!this.originalVersionValidation()) return;
    this.spinner.show();
    const obj = {
      file_name: this.fileName,
      version_no: this.versionNo,
      user_id: this.userObj.userId
    };
    this.dataModuleService.updateFileStats(obj)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.showProgressPopup();
      },
      err => {
        this.spinner.hide();
        this.toastrService.error('Failed to update stats, try again!');
      });
  }

  showProgressPopup() {
    const columnsDialogRef = this.dialog.open(ShowProgressComponent, {
      width: '650px',
      data: {
        file_name: this.fileName,
        version_no: this.versionNo
      },
      disableClose: true
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.toastrService.success('File stats updated successfully!');
        this.fetchVersionDetails();
      } else {
        this.updateFilesModule.emit(false);
      }
    });
  }

  fetchVersionDetails() {
    this.spinner.show();
    this.dataModuleService.fetchVersionDetails(this.fileName, this.userObj.userId, this.versionNo)
      .subscribe((res: any) => {
        const variableData = res.payload.variable_data;
        this.gridColumnDefs = generateGridColumns(variableData, this.versionNo != 1);
        this.bottomGridColumns = generateSecondGridColumns(variableData);
        this.metricsData = [];
        this.gridColumnDefs.forEach(element => {
          if (element.isMetricVariable) this.metricsData.push(element);
        });
        this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
        this.secondGridApi && this.secondGridApi.setColumnDefs(this.bottomGridColumns);
        this.dataStats = res.payload.data_counts;
        this.groupBy = res.payload.group_by;
        this.selectedUniqueID = res.payload.object_to_cluster;
        this.originalUniqueId = res.payload.object_to_cluster;
        this.versionName = res.payload.name;
        this.loadFileData();
        this.fetchCorrelationMatrix();
        this.fetchSummaryStats();
        this.slideToggleChanged();
      },
        err => {
          this.spinner.hide();
        });
  }

  clearFilter() {
    this.showFaulty = false;
    this.showDuplicates = false;
    this.showBlanks = false;
    this.showExceptions = false;
    this.showOutliers = false;
    this.showValid = false;
    this.loadFileData();
  }

  openConfirmationDialog(action: string) {
    // actions: delete, remove_blanks, reset, clear
    let messageString = action;
    if (action == 'remove_blanks' || action == 'reset' || action == 'clear') {
      messageString = 'proceed';
    }
    if (action != 'clear') {
      if (!this.originalVersionValidation()) return;
    }
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: `Are you sure you want to ${messageString}?` }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (action) {
          case 'delete':
            this.deleteRows();
            break;
          case 'remove_blanks':
            this.replaceBlanksWithZeros();
            break;
          case 'reset':
            this.resetToOriginal();
            break;
          case 'clear':
            this.clearData();
            break;
          default:
            break;
        }
      }
    });
  }

  clearData() {
    this.updateFilesModule.emit(false);
  }

  updateCorrelationValue(action: number) {
    if (this.versionNo == 1) {
      this.toastrService.warning('This option is unavailable for Original Version.');
      return;
    }
    if (action == 1) {
      this.correlationValue = this.correlationValue + 0.01;
    } else {
      this.correlationValue = this.correlationValue - 0.01;
    }
    if (this.correlationValue <= 1 && this.correlationValue >= 0) this.correlationUpdated = true;
    if (this.correlationValue > 1) this.correlationValue = 1;
    if (this.correlationValue < 0) this.correlationValue = 0;
  }

  saveCorrelationValue() {
    this.spinner.show();
    const obj = {
      version_no: this.versionNo,
      user_id: this.userObj.userId,
      file_name: this.fileName,
      correlation_value: this.correlationValue
    }
    this.dataModuleService.updateCorrelationValue(obj)
    .subscribe((res: any) => {
      this.toastrService.success('Value updated successfully.');
      this.spinner.hide();
      this.correlationUpdated = false;
    },
    err => {
      this.toastrService.error('Failed to save the value.');
      this.spinner.hide();
    });
  }

  updateName(item) {
    if (!this.originalVersionValidation()) return;
    const columnsDialogRef = this.dialog.open(UpdateNameDialogComponent, {
      width: '650px',
      data: {
        name: item.headerName
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let duplicateName = false;
        this.gridColumnDefs.forEach(element => {
          if (element.field != item.field && element.headerName == result) {
            duplicateName = true;
          }
        });
        if (duplicateName) {
          this.toastrService.warning('Duplicate names cannot be saved.', result);
          return;
        }
        this.changesMade = true;
        item.headerName = result;
        this.gridApi.setColumnDefs(this.gridColumnDefs);
      }
    });
  }

  radioChanged(colItem, e) {
    this.selectedUniqueID = e.value;
    colItem.isMetricVariable = false;
    this.slideToggleChanged();
    this.changesMade = true;
  }

  checkboxChanged(field: string, e) {
    if (e.checked)
      this.groupBy = field;
    else
      this.groupBy = null;
    this.changesMade = true;
  }

  async onSelectionChanged(event) {
    const nodes = event.api.getSelectedNodes();
    const selectedNodes = nodes.map((el) => el.data);
    this.selectedGridNodes = selectedNodes;
  }

  deleteRows() {
    if (!this.originalVersionValidation()) return;
    this.spinner.show();
    const obj = {
      rows_id: [],
      version_no: this.versionNo,
      user_id: this.userObj.userId,
      file_name: this.fileName,
      deletion_type: null,
      cluster_by: this.selectedUniqueID
    };
    this.selectedGridNodes.forEach(element => {
      obj.rows_id.push(element.uu_id);
    });
    this.dataModuleService.deleteSelectedRows(obj)
      .subscribe((res: any) => {
        this.toastrService.success('Data updated successfully.');
        this.selectedGridNodes = [];
        this.updateStats();
      },
        err => {
          this.toastrService.error('Failed to delete data');
          this.spinner.hide();
        });
  }

  resetToOriginal() {
    if (!this.originalVersionValidation()) return;
    this.spinner.show();
    const obj = {
      user_id: this.userObj.userId,
      file_name: this.fileName,
      version_no: this.versionNo
    };
    this.dataModuleService.resetToOriginal(obj)
      .subscribe((res: any) => {
        this.toastrService.success('Data has been reset successfully.');
        this.correlationUpdated = false;
        this.fetchVersionDetails();
      },
        err => {
          this.toastrService.error('Failed to reset data.');
          this.spinner.hide();
        });
  }

  replaceBlanksWithZeros() {
    if (!this.originalVersionValidation()) return;

    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: `Blank entries for measures will be replaced with zeros. Do you want to proceed?` }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        const obj = {
          file_name: this.fileName,
          version_no: this.versionNo,
          user_id: this.userObj.userId
        };
        this.spinner.show();
        this.dataModuleService.replaceBlanksWithZeros(obj)
          .subscribe((res: any) => {
            this.toastrService.success('Data updated successfully.');
            this.updateStats();
          },
            err => {
              this.toastrService.error('Failed to update data.');
              this.spinner.hide();
            });
      }
    });

  }

  saveAs() {
    const saveAsDialogRef = this.dialog.open(SaveVersionComponent, {
      width: '650px',
      data: {
        name: this.versionName,
        title: 'Save Version Name'
      }
    });

    saveAsDialogRef.afterClosed().subscribe(result => {
      if (result && result.name) {
        if ((result.name.trim().toLowerCase() == this.versionName.toLowerCase() && this.versionNo == 1) || result.name.trim().toLowerCase()=='original version') {
          this.toastrService.warning('You cannot save a version name as Original Version.');
          return;
        } else {
          this.saveNewVersionApi(result.name.trim(), result.excludeDuplicates, result.excludeOutliers, result.excludeExceptions);
        }
      }
    });
  }

  saveNewVersionApi(name, excludeDuplicates, excludeOutliers, excludeExceptions, override = false) {
    const obj = {
      file_name: this.fileName,
      user_id: this.userObj.userId,
      update_version_label: {
        name: name,
        variable_data: [],
        object_to_cluster: this.selectedUniqueID,
        group_by: this.groupBy
      },
      current_version: this.versionNo,
      exclude_duplicates: excludeDuplicates,
      exclude_outliers: excludeOutliers,
      exclude_exceptions: excludeExceptions
    } as any;
    override && (obj.override = true);
    this.gridColumnDefs.forEach(element => {
      element.field && obj.update_version_label.variable_data.push({
        name: element.field,
        displayName: element.headerName,
        is_matric: element.isMetricVariable
      });
    });
    this.spinner.show();
    this.dataModuleService.saveAsNewVersion(obj)
      .subscribe((res: any) => {
        this.versionName = name;
        this.toastrService.success('Version has been saved successfully!');
        this.spinner.hide();
        this.correlationUpdated = false;
        if (res.payload.new_version_no) {
          this.versionNo = res.payload.new_version_no;
        }
        this.updateStats();
        const obj = {
          versionNo: this.versionNo,
          versionName: this.versionName,
          fileName: this.fileName
        }
        this.updateFilesModule.emit(obj);
      },
        err => {
          this.spinner.hide();
          if (err.error && err.error.code && err.error.code==406 && err.error.description) {
            this.toastrService.warning(err.error.description);
            const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
              width: '500px',
              data: { confirmationMessage: `The existing version will be overwritten. Do you want to proceed?` }
            });
            confirmationDialogRef.afterClosed().subscribe(resultConfirmation => {
              if (resultConfirmation) {
                this.saveNewVersionApi(name, excludeDuplicates, excludeOutliers, excludeExceptions, true);
              }
            });

          } else if (err.error && err.error.code && err.error.code==403 && err.error.description) {
            this.toastrService.warning(err.error.description);
          } else {
            this.toastrService.error('Failed to save the version.');
          }
        });
  }

  originalVersionValidation(): boolean {
    if (this.versionNo == 1) {
      this.toastrService.warning('Original version cannot be updated.');
    } else return true;
  }

  returnVariableLabel(key: string) {
    let result = '';
    this.gridColumnDefs.forEach(element => {
      if (element.field == key) result = element.headerName;
    });
    return result;
  }

  getCorrelationClass(val) {
    return Math.abs(val) > this.correlationValue ? 'highlight' : '';
  }
}
