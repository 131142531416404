import { Component, Inject, OnInit } from '@angular/core';
import {  GridApi, ColumnApi, GridOptions } from 'ag-grid-enterprise';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { MappingService } from 'src/services/forecast-services/mapping-service';
import { defaultColDef, getUnmappedItemGridColDefs } from '../ag-grid/grid-options';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import * as _ from 'lodash';
import { SharedDataService } from 'src/services/shared-data.service';

@Component({
  selector: 'app-unmapped-item-list',
  templateUrl: './unmapped-item-list.component.html',
  styleUrls: ['./unmapped-item-list.component.css']
})


export class UnmappedItemListComponent implements OnInit {

  paginationPageSize = 50;
  cacheBlockSize = 50;

  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public defaultColDef = defaultColDef;
  public columnDefs = getUnmappedItemGridColDefs({}, this);
  gridData: any = [];
  selectedRows: any = [];

  constructor(
    public dialogRef: MatDialogRef<UnmappedItemListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public mappingService: MappingService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public _dataService: SharedDataService,
  ) {
  }

  ngOnInit() {
    this.getUnmappedItemList();
  }

  getUnmappedItemList() {
    this.spinner.show();
    this.mappingService.GetUnmappedItemList().subscribe(response => {
      this.gridData = response;
      this.spinner.hide();
    });
  }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  get getGridRowsCount(): Number {
    return this.gridApi ? this.gridApi.getModel().getRowCount(): 0;
  }

  onCloseClick() {
    this.dialogRef.close(false);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.sizeToFit();
  }

  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  clearGridFilters() {
    this.gridApi.setFilterModel(null);
  }

  addMapping() {
    if (this.selectedRows.length <= 1) {
      const  data = {
          isEdit: false,
          isSKUSelected: this.selectedRows.length === 1 ? true : false,
          ...this.selectedRows.length === 1 && { selectedSKU: this.selectedRows[0] }
      }
      this.dialogRef.close(data);
    } else {
      this.toastr.warning('Warning', 'Please select only one row for mapping.')
    }
  }

  ngOnDestroy() {
  }

}
