import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { getMaxListeners } from 'process';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ConfigurationService } from 'src/services/configuration.service';
import { CustomChartService } from 'src/services/custom-chart.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-anomoly-detector-form',
  templateUrl: './anomoly-detector-form.component.html',
  styleUrls: ['./anomoly-detector-form.component.css']
})
export class AnomolyDetectorFormComponent implements OnInit {
  files = [];
  AnonomyFiles = [];
  AnomolyResponse = [];
  xAxis = [];
  yAxis = [];
  form: FormGroup;
  view: any[] = [700, 300];
  public columnDefs = [
    {
      headerName: 'Date',
      field: 'date'
    },
    {
      headerName: 'Sale QTY',
      field: 'saleQty'
    },
  ];
  public selectedDiscountIncrease = -10;
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public ItemMasterList = [];
  colorScheme = {
    domain: ['#E44D25', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  @ViewChild('chart', { static: true }) chart: any;
  constructor(public configurationService: ConfigurationService,
    public toastr: NgxToasterService, private fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public customChartService: CustomChartService
  ) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }

  ngOnInit() {
    this.form = this.fb.group({
      clientName: ['test6', Validators.required],
      file: null,
      fileName: ""
    });
    //this.getAnomolyFiles();
  }
  public data: any = [];
  public file: File;
  onSelect(event: any) {
    /* wire up file reader */
    // const target: any = <DataTransfer>(event.target);
    if (event.addedFiles.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    let reader1 = this.getFile(event);
    this.file = event.addedFiles[0];
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(event.addedFiles[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {
        type: 'binary', cellDates: true,
        cellNF: false,
        cellText: false
      });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data);
      this.data.forEach(row => {
        row = Object.keys(row).map(key => row[key.replace(/\s/g, '')] = row[key]); // Data will be logged in array format containing objects
      });
      console.log(this.data);
      this.chartDatasets = [{
        name: '',
        series: this.data.map(row => ({ name: row.date, value: row.saleQty }))
      }]
    };
  }
  onRemove = () => { }
  private getFile(event: any) {
    let reader = new FileReader();
    if (event.addedFiles && event.addedFiles.length > 0) {
      let file = event.addedFiles[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.get('file').setValue((reader.result as any).split(',')[1]);
        this.form.get('fileName').setValue(file.name);
      };
    }
    return reader;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }


  getlist() {
    if (this.data != null && this.data[0] != null) {
      // console.log(Object.keys(this.data[0]))
      return Object.keys(this.data[0]).filter(x => x !== "__EMPTY");
    }
    else return [];
  }
  public IsUploaded: boolean;
  upload() {
    const formModel = this.form.value;

    this.spinner.show();
    // this.configurationService.UploadDoc(formModel).subscribe(res => {
    //   this.spinner.hide();
    //   this.IsUploaded = true;
    //   this.anonomlyFormModel.file.container = res.container;
    //   this.anonomlyFormModel.file.directory = res.directory;
    //   this.anonomlyFormModel.file.name = res.name;
    // }, error => {
    //   this.spinner.hide();
    //   this.toastr.error('Document Upload Failed', error.error.text);
    // });
  }
  public anonomlyFormModel: any = {
    "data": {
      "time": "",
      "value": ""
    },
    "file": {
      "container": "",
      "directory": "",
      "name": ""
    },
    "settings": {
      "istrain": ""
    }
  }
  chartDatasets: Array<any> = [{ name: '', series: [] }];
  findAnomoly() {
    this.anonomlyFormModel.data.time = "date";
    this.anonomlyFormModel.data.value = "saleQty";
    this.anonomlyFormModel.settings.istrain = 0;

    this.spinner.show();
    // this.configurationService.findAnomoly(this.anonomlyFormModel).subscribe(res => {
    //   this.spinner.hide();
    //   res = JSON.parse(res);
    //   (res.split(' ')).forEach(obj => {
    //     const parsedObj = JSON.parse(obj);
    //     this.AnomolyResponse.push(parsedObj);
    //     this.xAxis.push(parsedObj.date);
    //     this.yAxis.push(parsedObj.saleQty);
    //   });
    //   console.log(this.AnomolyResponse);
    //   this.chartDatasets = [{
    //     name: '',
    //     series: this.AnomolyResponse.map(row => ({ name: row.date, value: row.saleQty }))
    //   }];
    //   setTimeout(() => {
    //     this.customChartService.showDots(this.chart);
    //     this.gridOptions.api.setRowData(this.AnomolyResponse);
    //   }, 100);
    //   // this.chartDatasets = [
    //   //   { data: this.yAxis, label: 'Anomoly' }
    //   // ];
    // }, error => {
    //   this.spinner.hide();
    //   this.toastr.error('anomaly', error.error.text);
    // });
  }

  // getAnomolyFiles(){
  //   const params = "" ;


  //   this.configurationService.oldAnomolyFiles(params).subscribe(res => {
  //     this.spinner.hide();
  //     this.AnonomyFiles = res;
  //   }, error => {
  //     this.spinner.hide();
  //     this.toastr.error('Document Upload Failed', error.error.text);
  //   });
  //   }
  onSelectChart(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}


