import { Component, Input, OnInit } from '@angular/core';
import { TreeviewItem } from 'ngx-treeview';
import { CreateCalendarList, CreateForecastAccountTree, CreateForecastItemTree2 } from 'src/common/forecast-function';
import TreeControlParams from 'src/common/forecast-function';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';
import { Calendar } from 'src/modules/forecast-engin/features/forecast-manager/models/Calendar';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import {  ColumnApi, GridApi, GridOptions } from 'ag-grid-enterprise';
import { NgxSpinnerService } from 'ngx-spinner';
import { downloadFromLink, gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { numberValueFormatter2Decimal, tooltipcellRenderer } from 'src/common/util/util';
import { ForecastDataManagerEditRenderComponent } from '../ag-grid/forecast-data-manager-edit-renderer.component';
import { ForecastSeasonalityModalComponent } from '../forecast-seasonality-modal/forecast-seasonality-modal.component';
import moment from 'moment';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { SharedDataService } from 'src/services/shared-data.service';
import { skuGetter } from '../ag-grid/grid-options';

@Component({
  selector: 'app-forecast-data-manager',
  templateUrl: './forecast-data-manager.component.html',
  styleUrls: ['./forecast-data-manager.component.scss']
})
export class ForecastDataManagerComponent implements OnInit {
  @Input() data?: any;
  SKIP_KEYS: any = ['SKU', 'SKUDescription', 'seasonalityKey', 'brand', 'productGroup', 'productFamily', 'seasonalityId', 'RetailerKey', 'Retailer','retailerName'];
  SKIP_COLUMNS = ['SKUDescription', 'seasonalityKey', 'measureDescription', 'Retailer'];
  EDITABLE_ROWS: any = ['002', '004'];
  public adjustment = 0;
  public channelDropdownList: TreeviewItem[];
  public channelFilterDropdownList: TreeviewItem[];
  public productFilterList: TreeviewItem[];
  public restoredItemsList = [];
  public filterListDropdown: TreeviewItem[];
  public forecastListDropdown: TreeviewItem[];
  public timeframeList: Calendar[];
  forecastId: any = 0;
  public mappedItemIds: any = [];
  public mappedRetailerKeys: any = [];
  public accountSettingList = [];
  public selectedItemIds = [];
  public selectedItemFiltersIds = [];
  public selectedRetailerKeys = [];
  public selectedFilterRetailerKeys = [];
  public filterTimeFrame = [];
  public forecastTimeFrame = [];
  public itemSettingList = [];
  public productList = [];
  public forecastManagerMeasureList = [];
  public refreshMeasure = [];
  public forecastMeasure = [];
  selectedFilterMeasures = ['001', '002', '003', '004', '005', '006'];
  selectedRefreshMeasures = [];
  selectedForecastMeasures = [];
  forecastFromWeek = new FormControl('');
  forecastToWeek = new FormControl('');
  lookBackFromWeek = new FormControl('');
  lookBackToWeek = new FormControl('');
  isCheckedValue: false;
  chartDataSource = {
    chart: {
      showhovereffect: "1",
      drawcrossline: "1",
      plottooltext: "<b>$dataValue</b> of youth were on $seriesName",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100"
    },
    categories: [
      {
        category: []
      }
    ],
    dataset: []
  };
  width = '100%';
  height = '500';
  type = "msline";
  dataFormat = "json";
  frameworkComponents = {
    forecastDataManagerEditRenderComponent: ForecastDataManagerEditRenderComponent,
  };

  isItemView: boolean = true;
  matrixData: any = [];
  structuredMatrixData: any = [];
  selectedCalendarLevel: string = 'weekly';


  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public defaultColDef = {
    filter: false,
    sortable: false,
    minWidth: 50,
    resizable: true,
    suppressMenu: true
  };
  rowHeight = 30;
  public columnDefs: any = [];
  gridData: any = [];
  isGridLoaded: boolean = false;
  isGridDataUpdated: boolean = false;
  gridUpdatedCells: any = [];
  forecastMethod: string = '';
  seasonalityList: any = [];

  itemViewGridDataCopy: any = [];
  isAdjustmentAppliedAlready: boolean = false;

  constructor(public forecastLookupService: ForecastLookupService,
    public forecastManagerService: ForecastManagerService,
    public toastr: NgxToasterService,
    public spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public _dataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.initialData();
  }

  openForecastDataManagerModel(forecastData) {
    const dialogRef = this.dialog.open(ForecastSeasonalityModalComponent, {
      disableClose: true,
      panelClass: 'mapping-manager-dialog',
      data: {
        chartData: forecastData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  initialData() {
    const data = JSON.parse(this.data ? this.data : {});
    this.forecastId = data.forecastData.forecastId;

    if (data.isSessionRestore) {
      const sessionData = JSON.parse(_.get(data, 'forecastData.sessionState', '{}'));
      this.setSessionState(sessionData);
    }
    this.forecastManagerMeasuresGetList();
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getForecastManagerDataById(forecastId) {
    this.forecastManagerService.GetForecastManagerDataById(forecastId).subscribe((forecastManagerData: any) => {
      this.spinner.hide();
      this.forecastFromWeek.setValue(forecastManagerData.forecastManager.forecastFromWeek);
      this.forecastToWeek.setValue(forecastManagerData.forecastManager.forecastFromWeekToTimePeriodDate);
      this.lookBackFromWeek.setValue(forecastManagerData.forecastManager.lookbackFromWeek);
      this.lookBackToWeek.setValue(forecastManagerData.forecastManager.lookbackToWeek);
      this.accountSettingList = forecastManagerData.forecastRetailerList;
      this.itemSettingList = forecastManagerData.forecastItemsList;
      this.timeframeList = forecastManagerData.forecastSelectedWeekList;
      this.forecastMethod = forecastManagerData.forecastManager.forecastMethod;
      this.seasonalityList = forecastManagerData.seasonalityList;


      this.createAccountDropdownList(this.accountSettingList);
      this.createFilterAccountDropdownList(this.accountSettingList);
      this.createItemDropdownList(this.itemSettingList);
      this.createFilterItemDropdownList(this.itemSettingList);
      this.createFilterListDropdown(this.filterTimeFrame);
      this.createForecastListDropdown(this.forecastTimeFrame);
      this.getForecastMatrixDataById();
    });
  }

  forecastManagerCreateUserSession() {
    const sessionState = this.getSessionState();
    const payload = { forecastId: this.forecastId, sessionState }
    this.forecastManagerService.ForecastManagerCreateUserSession(payload).subscribe(data => {

    });
  }

  getSessionState() {
    const sessionState = {
      ...this.selectedForecastMeasures.length && { selectedForecastMeasures: this.selectedForecastMeasures },
      ...this.adjustment && { adjustment: this.adjustment },
      ...this.forecastTimeFrame.length && { selectForecastTimeFrame: this.forecastTimeFrame },
    };
    return JSON.stringify(sessionState);
  }

  setDataManagerValues(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'FilterTimeFrame': {
        this.filterTimeFrame = selectedDropdown.value;
        this.forecastManagerCreateUserSession();
        break;
      }
      case 'ForecastTimeFrame': {
        this.forecastTimeFrame = selectedDropdown.value;
        this.forecastManagerCreateUserSession();
        break;
      }
    }
  }

  setSessionState(sessionData) {
    this.selectedForecastMeasures = sessionData.selectedForecastMeasures || [];
    this.adjustment = sessionData.adjustment || '';
    this.forecastTimeFrame = sessionData.selectForecastTimeFrame || [];
  }

  forecastManagerMeasuresGetList() {
    this.spinner.show();
    this.forecastLookupService.ForecastManagerMeasuresGetList().subscribe((res: any) => {
      this.forecastManagerMeasureList = res;
      this.refreshMeasure = this.forecastManagerMeasureList.filter((measure) => ['003', '006'].includes(measure.measureCode));
      this.forecastMeasure = this.forecastManagerMeasureList.filter((measure) => ['002', '004'].includes(measure.measureCode));
      this.getForecastManagerDataById(this.forecastId);
    });
  }

  createAccountDropdownList(accoutSettingList, mappedRetailerKeys: any = [], isAllChecked = true, isAllDisabled = true) {
    this.channelDropdownList = CreateForecastAccountTree({
      dataList: accoutSettingList,
      mappedValueList: mappedRetailerKeys,
      isAllChecked: isAllChecked,
      isAllDisabled: isAllDisabled
    } as TreeControlParams);
  }

  createFilterAccountDropdownList(accoutSettingList, mappedRetailerKeys: any = [], isAllChecked = true,) {
    this.channelFilterDropdownList = CreateForecastAccountTree({
      dataList: accoutSettingList,
      mappedValueList: mappedRetailerKeys,
      isAllChecked: isAllChecked
    } as TreeControlParams);
  }

  createItemDropdownList(productSettingList, mappedItemIds: any = [], isAllChecked = true, isAllDisabled = true) {
    this.productList = CreateForecastItemTree2({
      dataList: productSettingList,
      mappedValueList: mappedItemIds,
      isAllChecked: isAllChecked,
      isAllDisabled: isAllDisabled
    } as TreeControlParams);
  }

  createFilterItemDropdownList(productSettingList, mappedItemIds: any = [], isAllChecked = true,) {
    this.productFilterList = CreateForecastItemTree2({
      dataList: productSettingList,
      mappedValueList: mappedItemIds,
      isAllChecked: isAllChecked
    } as TreeControlParams);
  }

  createFilterListDropdown(mappedTimeFrame: any=[]) {
    this.filterListDropdown = CreateCalendarList(
      this.filterListDropdown,
      this.timeframeList,
      mappedTimeFrame
    );
  }

  createForecastListDropdown(mappedTimeFrame: any=[]) {
    this.forecastListDropdown = CreateCalendarList(
      this.forecastListDropdown,
      this.timeframeList,
      mappedTimeFrame
    );
  }

  setRestoredValue(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.selectedItemIds = selectedDropdown.value;
        this.createMeasureDataOfMatrix();
        break;
      }
      case 'Channel': {
        this.selectedRetailerKeys = selectedDropdown.value;
        this.createMeasureDataOfMatrix();
        break;
      }
    }
  }

  setFilterValue(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.selectedItemFiltersIds = selectedDropdown.value;
        this.applyFilterOnMatrixGrid();
        break;
      }
      case 'Channel': {
        this.selectedFilterRetailerKeys = selectedDropdown.value;
        this.applyFilterOnMatrixGrid();
        break;
      }
    }
  }

  applyFilterOnMatrixGrid() {
    this.spinner.show();
    let gridData = this.calculatedValueByCalendarLevel(this.structuredMatrixData);
    gridData = this.filterDataByItemAccount(this.structuredMatrixData);
    if (!this.isItemView) {
      this.gridData = this.applyAggregateView(gridData);
      this.gridData = this.filterMatrixDataByMeasures(this.gridData);
    } else {
      this.gridData = this.filterMatrixDataByMeasures(gridData);
    }
    this.gridApi && this.gridApi.setRowData(this.gridData);
    this.spinner.hide();
  }

  applyMeasureFilter() {
    this.applyFilterOnMatrixGrid();
    this.calculateMatrixValue(true);
  }

  applyTimeframeFilter() {
    this.createColDefs();
    this.createChartData(this.structuredMatrixData);
  }

  applyAdjustment() {
    if (
      !this.isAdjustmentAppliedAlready
      && this.selectedForecastMeasures.length
      && this.adjustment
      && this.forecastTimeFrame.length
      && this.selectedCalendarLevel === 'weekly'
      && this.selectedFilterMeasures.includes('002')
      && this.selectedFilterMeasures.includes('004')) {
      this.isAdjustmentAppliedAlready = true;
      this.gridData = this.applyAdjustementFactorOnGrid(this.gridData);
      if (this.gridApi) {
        this.gridApi.setRowData(this.gridData);
      }
    } else {
      this.toastr.info('Info', 'Please select required value to apply adjustment and also set the calendar view level to weekly.');
    }
  }

  applyAdjustementFactorOnGrid(gridData) {
    const weekList = this.getWeekList();
    gridData.forEach(row => {
      if (this.selectedForecastMeasures.includes(row.measureCode)) {
        weekList.forEach(week => {
          if (this.forecastTimeFrame.includes(week.weekNumber)) {
            if (row[week.weekField] && row[week.weekField] != 0) {
              row[week.weekField] = row[week.weekField] * Number(this.adjustment);
              this.isGridDataUpdated = true;
              const prvUpdatedRowStateIndex = _.findIndex(this.gridUpdatedCells,
                a => a.weekNumber === week.weekNumber
                  && a.itemKey === row.SKU
                  && a.retailerKey === row.RetailerKey
              );
              if (prvUpdatedRowStateIndex < 0) {
                this.gridUpdatedCells.push({
                  itemKey: row.SKU,
                  retailerKey: row.RetailerKey,
                  weekNumber: week.weekNumber,
                  weekField: week.weekField,
                  forecastId: this.forecastId,
                  measureCodes: [row.measureCode],
                  curatedBaselineForecast: row.measureCode === '002' ? row[week.weekField] : (-1000),
                  curatedPromoForecast: row.measureCode === '004' ? row[week.weekField] : (-1000),
                });
              } else {
                this.gridUpdatedCells[prvUpdatedRowStateIndex] = {
                  itemKey: row.SKU,
                  retailerKey: row.RetailerKey,
                  weekNumber: week.weekNumber,
                  weekField: week.weekField,
                  forecastId: this.forecastId,
                  measureCodes: _.uniq([...this.gridUpdatedCells[prvUpdatedRowStateIndex].measureCodes, row.measureCode]),
                  curatedBaselineForecast: row.measureCode === '002' ? row[week.weekField] : this.gridUpdatedCells[prvUpdatedRowStateIndex].curatedBaselineForecast,
                  curatedPromoForecast: row.measureCode === '004' ? row[week.weekField] : this.gridUpdatedCells[prvUpdatedRowStateIndex].curatedPromoForecast
                };
              }
            }
          }
        });
      }
    });
    return gridData;
  }

  lastSavedMatrixData() {
    this.gridUpdatedCells = [];
    this.isAdjustmentAppliedAlready = false;
    this.getForecastMatrixDataById();
  }

  resetMatrixDataToOriginal() {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Reset Forecast`,
        confirmationMessage: `Are you sure you want to reset to original, it will dicard all of your curated values?`
      }
    });
    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.forecastManagerService.ForecastManagerMatrixDataReset(this.forecastId).subscribe(data => {
          this.getForecastMatrixDataById();
          this.gridUpdatedCells = [];
          this.isAdjustmentAppliedAlready = false;
          this.spinner.hide();
        });
      }
    });
  }

  getForecastMatrixDataById() {
    this.spinner.show();
    this.forecastManagerService.GetForecastMatrixDataById(this.forecastId).subscribe(data => {
      this.matrixData = data;
      this.createColDefs();
      this.createMeasureDataOfMatrix();
      this.spinner.hide();
    });
  }

  createColDefs() {
    this.columnDefs = [];
    if (this.isItemView) {
      this.columnDefs.push(
        {
          headerName: this._dataService.getDataById(1),
          field: 'Retailer',
          pinned: 'left',
          width: 100
        },
        {
          headerName: this._dataService.getDataById(5),
          field: 'SKUDescription',
          pinned: 'left',
          width: 255
        },
        {
          headerName: this._dataService.getDataById(4),
          pinned: 'left',
          width: 110,
          colId: 'SKUGetter',
          valueGetter: skuGetter,
        },
        {
          headerName: this._dataService.getDataById(2),
          field: 'brand',
          pinned: 'left',
          width: 160,
          hide: true
        },
        {
          headerName: this._dataService.getDataById(3),
          field: 'productGroup',
          pinned: 'left',
          width: 160,
          hide: true
        },
        {
          headerName: 'Product Family',
          field: 'productFamily',
          pinned: 'left',
          width: 160,
          hide: true
        },
        {
          headerName: 'Seasonality',
          field: 'seasonalityKey',
          cellRenderer: 'forecastDataManagerEditRenderComponent',
          filterParams: {
            cellRenderer: tooltipcellRenderer,
          },
          pinned: 'left',
          width: 85
        },
        {
          headerName: 'Measure',
          field: 'measureDescription',
          pinned: 'left',
          width: 215
        });

    } else {
      this.columnDefs.push(
        {
          headerName: 'Measure',
          field: 'measureDescription',
          pinned: 'left',
          width: 220
        });
    }
    let weekList: any = this.getWeekList();
    weekList.forEach(week => {
      if (this.filterTimeFrame.length) {
        if (this.filterTimeFrame.includes(week.weekNumber)) {
          switch (this.selectedCalendarLevel) {
            case 'year': {
              const doesExist = _.find(this.columnDefs, a => a.field === week.year);
              !doesExist && this.columnDefs.push({
                headerName: week.year,
                field: week.year,
                width: 90,
                cellClassRules: {
                  'bg-gray': (params) => { return true; }
                },
                editable: () => {
                  return false
                },
                valueFormatter: numberValueFormatter2Decimal
              });
              break;
            }
            case 'halfyear': {
              const doesExist = _.find(this.columnDefs, a => a.field === week.halfyear);
              !doesExist && this.columnDefs.push({
                headerName: week.halfyear,
                field: week.halfyear,
                width: 90,
                cellClassRules: {
                  'bg-gray': (params) => { return true; }
                },
                editable: () => {
                  return false
                },
                valueFormatter: numberValueFormatter2Decimal
              });
              break;
            }
            case 'quarter': {
              const doesExist = _.find(this.columnDefs, a => a.field === week.quarter);
              !doesExist && this.columnDefs.push({
                headerName: `${week.quarter}`,
                field: week.quarter,
                width: 90,
                cellClassRules: {
                  'bg-gray': (params) => { return true; }
                },
                editable: () => {
                  return false
                },
                valueFormatter: numberValueFormatter2Decimal
              });
              break;
            }
            case 'monthly': {
              const doesExist = _.find(this.columnDefs, a => a.field === week.monthly);
              !doesExist && this.columnDefs.push({
                headerName: week.monthly,
                field: week.monthly,
                width: 90,
                cellClassRules: {
                  'bg-gray': (params) => { return true; }
                },
                editable: () => {
                  return false
                },
                valueFormatter: numberValueFormatter2Decimal
              });
              break;
            }
            default: {
              this.columnDefs.push({
                headerName: week.weekStartDate,
                field: week.weekField,
                width: 90,
                cellClassRules: {
                  'bg-gray': (params) => {
                    if (this.EDITABLE_ROWS.includes(params.data.measureCode) && this.isItemView) {
                      return false;
                    } else {
                      return true;
                    }
                  },
                  'bg-yellow': (params) => {
                    const editedCell = _.find(this.gridUpdatedCells,
                      a => this.EDITABLE_ROWS.includes(params.data.measureCode)
                        && a.retailerKey === params.data.RetailerKey
                        && a.itemKey === params.data.SKU
                        && a.weekField === params.colDef.field
                        && a.measureCodes.includes(params.data.measureCode));
                    return editedCell ? true : false;
                  },
                  'top-border': (params) => {
                    return params.data.measureCode === '001' ? true : false;
                  }
                },
                editable: (params) => {
                  if (this.EDITABLE_ROWS.includes(params.data.measureCode) && this.isItemView) {
                    return true;
                  } else {
                    return false;
                  }
                },
                valueFormatter: numberValueFormatter2Decimal
              });
              break;
            }
          }
        }
      } else {
        switch (this.selectedCalendarLevel) {
          case 'year': {
            const doesExist = _.find(this.columnDefs, a => a.field === week.year);
            !doesExist && this.columnDefs.push({
              headerName: week.year,
              field: week.year,
              width: 90,
              cellClassRules: {
                'bg-gray': (params) => { return true; }
              },
              editable: () => {
                return false
              },
              valueFormatter: numberValueFormatter2Decimal
            });
            break;
          }
          case 'halfyear': {
            const doesExist = _.find(this.columnDefs, a => a.field === week.halfyear);
            !doesExist && this.columnDefs.push({
              headerName: week.halfyear,
              field: week.halfyear,
              width: 90,
              cellClassRules: {
                'bg-gray': (params) => { return true; }
              },
              editable: () => {
                return false
              },
              valueFormatter: numberValueFormatter2Decimal
            });
            break;
          }
          case 'quarter': {
            const doesExist = _.find(this.columnDefs, a => a.field === week.quarter);
            !doesExist && this.columnDefs.push({
              headerName: `${week.quarter}`,
              field: week.quarter,
              width: 90,
              cellClassRules: {
                'bg-gray': (params) => { return true; }
              },
              editable: () => {
                return false
              },
              valueFormatter: numberValueFormatter2Decimal
            });
            break;
          }
          case 'monthly': {
            const doesExist = _.find(this.columnDefs, a => a.field === week.monthly);
            !doesExist && this.columnDefs.push({
              headerName: week.monthly,
              field: week.monthly,
              width: 90,
              cellClassRules: {
                'bg-gray': (params) => { return true; }
              },
              editable: () => {
                return false
              },
              valueFormatter: numberValueFormatter2Decimal
            });
            break;
          }
          default: {
            this.columnDefs.push({
              headerName: moment(week.weekStartDate).format('MM/DD/YYYY'),
              field: week.weekField,
              width: 90,
              cellClassRules: {
                'bg-gray': (params) => {
                  if (this.EDITABLE_ROWS.includes(params.data.measureCode) && this.isItemView) {
                    return false;
                  } else {
                    return true;
                  }
                },
                'bg-yellow': (params) => {
                  const editedCell = _.find(this.gridUpdatedCells,
                    a => this.EDITABLE_ROWS.includes(params.data.measureCode)
                      && a.retailerKey === params.data.RetailerKey
                      && a.itemKey === params.data.SKU
                      && a.weekField === params.colDef.field
                      && a.measureCodes.includes(params.data.measureCode));
                  return editedCell ? true : false;
                },
                'top-border': (params) => {
                  return params.data.measureCode === '001' ? true : false;
                }
              },
              editable: (params) => {
                if (this.EDITABLE_ROWS.includes(params.data.measureCode) && this.isItemView) {
                  return true;
                } else {
                  return false;
                }
              },
              valueFormatter: numberValueFormatter2Decimal
            });
            break;
          }
        }
      }

    });
    if (this.gridApi) {
      this.gridApi.setColumnDefs([]);
      setTimeout(() => {
        this.gridApi.setColumnDefs(this.columnDefs);
      }, 100);
    }
    this.isGridLoaded = true;
  }

  initMeasureMatrixRows(row) {
    const measureRowObject: any = {
      baselineFCUnits: {
        Retailer: row.retailerName,
        RetailerKey: row.RetailerKey,
        SKU: row.SKU,
        SKUDescription: row.SKUDescription,
        brand: row.brand,
        productGroup: row.productGroup,
        productFamily: row.productFamily,

        seasonalityKey: row.seasonalityKey, seasonalityId: row.seasonalityId, measureDescription: this.getmeasureDescriptionByCode('001'), measureCode: '001'

      },
      curatedBaselineFCUnits: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('002'), measureCode: '002' },
      promoFCUnits: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('003'), measureCode: '003' },
      curatedPromoFCUnits: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('004'), measureCode: '004' },
      totalFC: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('005'), measureCode: '005' },
      customerFC: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('006'), measureCode: '006' },
      actPOSUnits: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('007'), measureCode: '007' },
      lyActPOSUnits: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('008'), measureCode: '008' },
      varUnitsFC: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('009'), measureCode: '009' },
      varUnitsLY: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('010'), measureCode: '010' },
      fcAccuracy: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('011'), measureCode: '011' },
      totalFCChange: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('012'), measureCode: '012' },
      projectST: { SKU: row.SKU, RetailerKey: row.RetailerKey, measureDescription: this.getmeasureDescriptionByCode('013'), measureCode: '013' },
    };
    return measureRowObject;
  }

  getmeasureDescriptionByCode(measureCode) {
    const measure = _.find(this.forecastManagerMeasureList, a => a.measureCode === measureCode);
    return measure.measureDescription;
  }

  createMeasureDataOfMatrix() {
    this.structuredMatrixData = [];
    let mapMatrixData: any = [];
    if (this.matrixData.length > 0) {

      this.seasonalityList.forEach(element => {
        this.matrixData.forEach((data) => {
          if (data.SKU === element.itemKey && data.RetailerKey === element.retailerKey) {
            mapMatrixData.push({ ...data, seasonalityKey: element.seasonalityKey, seasonalityId: element.seasonalityId });
          }
        });
      })
    }

    if (mapMatrixData.length > 0) {
      this.matrixData = [];
      this.itemSettingList.forEach(ele => {
        mapMatrixData.forEach(data => {
          if (data.SKU === ele.itemKey && data.RetailerKey === ele.retailerKey) {
            this.matrixData.push({ ...data, brand: ele.brand, productFamily: ele.productFamily, productGroup: ele.productGroup, retailerName: ele.retailerName });
          }
        });
      })
    }

    this.matrixData.forEach(row => {
      let measureRowObject = this.initMeasureMatrixRows(row);
      const weekList = _.keys(_.omit(row, this.SKIP_KEYS));
      weekList.forEach(week => {

        if (row[`${week}`]) {
          /*
            Split value '12_12_13_13_12_1677_1567' on underscore ( _ )
            0 - Baseline FC Units
            1 - Curated Baseline FC Units
            2 - Promo FC Units
            3 - Curated Promo FC Units
            4 - Customer FC
            5 - This Year POS of Given Week
            6 - Last Year POS of Given Week
         */
          const dataSplit = row[`${week}`].split('_').map(Number);

          measureRowObject.baselineFCUnits[`${week}`] = dataSplit[0];
          measureRowObject.curatedBaselineFCUnits[`${week}`] = dataSplit[1];
          measureRowObject.promoFCUnits[`${week}`] = dataSplit[2];
          measureRowObject.curatedPromoFCUnits[`${week}`] = dataSplit[3];
          measureRowObject.totalFC[`${week}`] = measureRowObject.curatedBaselineFCUnits[`${week}`] + measureRowObject.curatedPromoFCUnits[`${week}`];
          measureRowObject.customerFC[`${week}`] = dataSplit[4];
          measureRowObject.actPOSUnits[`${week}`] = dataSplit[5];
          measureRowObject.lyActPOSUnits[`${week}`] = dataSplit[6];
          measureRowObject.varUnitsFC[`${week}`] = Math.abs(measureRowObject.actPOSUnits[`${week}`] - measureRowObject.totalFC[`${week}`]);
          measureRowObject.varUnitsLY[`${week}`] = Math.abs(measureRowObject.actPOSUnits[`${week}`] - measureRowObject.lyActPOSUnits[`${week}`]);
          measureRowObject.projectST[`${week}`] = measureRowObject.actPOSUnits[`${week}`] > 0 ? measureRowObject.actPOSUnits[`${week}`] : measureRowObject.totalFC[`${week}`];
          measureRowObject.fcAccuracy[`${week}`] = measureRowObject.totalFC[`${week}`] ? (Number((measureRowObject.projectST[`${week}`] / measureRowObject.totalFC[`${week}`]) * 100)) : 0;
          measureRowObject.totalFCChange[`${week}`] = '';
        }
      });

      this.structuredMatrixData.push(
        measureRowObject.baselineFCUnits,
        measureRowObject.curatedBaselineFCUnits,
        measureRowObject.promoFCUnits,
        measureRowObject.curatedPromoFCUnits,
        measureRowObject.totalFC,
        measureRowObject.customerFC,
        measureRowObject.actPOSUnits,
        measureRowObject.lyActPOSUnits,
        measureRowObject.varUnitsFC,
        measureRowObject.varUnitsLY,
        measureRowObject.fcAccuracy,
        measureRowObject.totalFCChange,
        measureRowObject.projectST
      );
      this.calculateTotalFCChange(measureRowObject);
    });
    // });
    this.structuredMatrixData = this.calculatedValueByCalendarLevel(this.structuredMatrixData);
    this.createChartData(this.structuredMatrixData);

    this.structuredMatrixData = this.filterDataByItemAccount(this.structuredMatrixData);
    if (!this.isItemView) {
      this.gridData = this.applyAggregateView(this.structuredMatrixData);
      this.gridData = this.filterMatrixDataByMeasures(this.gridData);
    } else {
      this.gridData = this.filterMatrixDataByMeasures(this.structuredMatrixData);
    }
    this.gridApi && this.gridApi.setRowData(this.gridData);
  }

  filterDataByItemAccount(structuredMatrixData) {
    let filteredData = _.filter(structuredMatrixData, a => this.selectedFilterRetailerKeys.includes(a.RetailerKey));
    filteredData = _.filter(filteredData, a => this.selectedItemFiltersIds.includes(a.SKU));
    return filteredData;
  }

  calculateTotalFCChange(measureRowObject) {
    for (let i = 0; i < this.columnDefs.length; i++) {
      if (!['Retailer', 'SKUDescription', 'seasonalityKey', 'brand', 'productGroup', 'productFamily', 'measureDescription'].includes(this.columnDefs[i].field)) {
        const currentWeekProjectST = measureRowObject.projectST[`${this.columnDefs[i].field}`] || 0;
        const lastWeekProjectST = measureRowObject.projectST[`${_.get(this.columnDefs, (i - 1).toString() + '.field', '')}`] || 1;
        measureRowObject.totalFCChange[`${this.columnDefs[i].field}`] = isNaN(lastWeekProjectST) ? 100 : (((currentWeekProjectST / (isNaN(lastWeekProjectST) ? 1 : lastWeekProjectST)) - 1) * 100).toFixed(2);
      }
    }
  }

  applyAggregateView(structuredMatrixData) {
    const weekList = this.getWeekList();
    const aggregateViewDataSet: any = [];
    const measureGroupedData = _.groupBy(structuredMatrixData, a => a.measureCode);
    const measureCodes = _.sortBy(_.keys(measureGroupedData));

    measureCodes.forEach(measureCode => {
      const measureRow: any = {
        measureDescription: this.getmeasureDescriptionByCode(measureCode),
        measureCode: measureCode
      };
      weekList.forEach(week => {
        switch (this.selectedCalendarLevel) {
          case 'year': {
            measureRow[`${week.year}`] = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === measureCode),
              b => b[`${week.year}`] ? Number(b[`${week.year}`]) : 0)));
            break;
          }
          case 'halfyear': {
            measureRow[`${week.halfyear}`] = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === measureCode),
              b => b[`${week.halfyear}`] ? Number(b[`${week.halfyear}`]) : 0)));
            break;
          }
          case 'quarter': {
            measureRow[`${week.quarter}`] = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === measureCode),
              b => b[`${week.quarter}`] ? Number(b[`${week.quarter}`]) : 0)));
            break;
          }
          case 'monthly': {
            measureRow[`${week.monthly}`] = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === measureCode),
              b => b[`${week.monthly}`] ? Number(b[`${week.monthly}`]) : 0)));
            break;
          }
          default: {
            measureRow[`${week.weekField}`] = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === measureCode),
              b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));
            break;
          }
        }
      });
      aggregateViewDataSet.push(measureRow);
    });
    return aggregateViewDataSet;
  }

  getWeekList() {
    const weekMetaList = _.keys(_.omit(this.matrixData[0], this.SKIP_KEYS));
    let weekList: any = [];
    /*
     Split this string "2022#H2-2022#202203#2022-09-06#202237" on hash(#)
     0 - Report Year
     1 - Half Year
     2 - Quarter of the Year
     3 - Month of the Year
     4 - Week Start Date
     5 - Week Number
   */
    weekMetaList.forEach(week => {
      const weekLables = week.split('#');
      weekList.push({
        weekField: week,
        weekly: week,
        year: weekLables[0],
        halfyear: weekLables[1],
        quarter: weekLables[2],
        monthly: weekLables[3],
        weekStartDate: weekLables[4],
        weekNumber: Number(weekLables[5])
      });
    });
    weekList = _.orderBy(weekList, ['weekNumber'], ['asc']);
    return weekList;
  }

  getWeekColumnFieldList() {
    return _.map(_.filter(this.columnDefs, a => !this.SKIP_COLUMNS.includes(a.field)), a => a.field);
  }

  calculatedValueByCalendarLevel(structuredMatrixData) {
    if (this.selectedCalendarLevel !== 'weekly') {
      const fieldsArray = this.getWeekColumnFieldList();
      const weekList = this.getWeekList();
      structuredMatrixData.forEach(row => {
        // reset all stats
        weekList.forEach(week => {
          row[`${week.year}`] = 0;
          row[`${week.halfyear}`] = 0;
          row[`${week.quarter}`] = 0;
          row[`${week.monthly}`] = 0;
        });

        weekList.forEach((week) => {
          // Skip FC Accuracy % + Total FC Change Over Time
          if (row.measureCode !== '011' && row.measureCode !== '012') {
            row[`${week[this.selectedCalendarLevel]}`] = (row[`${week[this.selectedCalendarLevel]}`] || 0) + Number(row[`${week.weekField}`]);
          }
        });
      });

      const groupedItemAccountRows = _.groupBy(structuredMatrixData, a => `${a.RetailerKey}_${a.SKU}`);
      _.keys(groupedItemAccountRows).forEach(key => {

        const totalFC = groupedItemAccountRows[key].find(a => a.measureCode === '005');
        const projectedST = groupedItemAccountRows[key].find(a => a.measureCode === '013');

        const fcAccuracy = groupedItemAccountRows[key].find(a => a.measureCode === '011');
        const totalFCChange = groupedItemAccountRows[key].find(a => a.measureCode === '012');

        fieldsArray.forEach((field, index) => {
          // Calculate FC Accuracy %
          fcAccuracy[field] = totalFC[field] ? ((projectedST[field] / totalFC[field]) * 100) : 0;

          // Caculate Total FC Change Over Time
          const currentWeekProjectST = projectedST[field] || 0;
          const lastWeekProjectST = projectedST[`${_.get(fieldsArray, (index - 1).toString(), '')}`];
          totalFCChange[field] = index === 0 ? 100 : lastWeekProjectST ? (((currentWeekProjectST / lastWeekProjectST) - 1) * 100).toFixed(2) : 0;
        });
      });
    }
    return structuredMatrixData;
  }

  createChartData(structuredMatrixData) {
    const weekList = this.getWeekList();
    const weekLabelList: any = [];
    weekList.forEach(week => {
      if (this.filterTimeFrame.length) {
        if (this.filterTimeFrame.includes(week.weekNumber)) {
          const weekNumber = week.weekNumber.toString().slice(-2);
          weekLabelList.push({ label: `${moment(week.weekStartDate).format('MM/DD/YYYY')}-W${weekNumber}` });
        }
      } else {
        const weekNumber = week.weekNumber.toString().slice(-2);
        weekLabelList.push({ label: `${moment(week.weekStartDate).format('MM/DD/YYYY')}-W${weekNumber}` });
      }
    });
    const totalFCSeriesData: any = { seriesname: 'Total FC', data: [] };
    const customerFCSeriesData: any = { seriesname: 'Customer FC', data: [] };
    const varUnitsFCSeriesData: any = { seriesname: 'Var. Units FC', data: [] };
    const actualSTSeriesData: any = { seriesname: 'Actual ST', data: [] };
    const lyActualSTSeriesData: any = { seriesname: 'LY Actual ST', data: [] };
    weekList.forEach(week => {
      if (this.filterTimeFrame.length) {
        if (this.filterTimeFrame.includes(week.weekNumber)) {
          const totalFCValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '005'),
            b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

          const customerFCValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '006'),
            b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

          const varUnitsFCValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '009'),
            b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

          const actualSTValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '007'),
            b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

          const lyActualSTValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '008'),
            b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

          totalFCSeriesData.data.push({ value: totalFCValueSum });
          customerFCSeriesData.data.push({ value: customerFCValueSum });
          varUnitsFCSeriesData.data.push({ value: varUnitsFCValueSum });
          actualSTSeriesData.data.push({ value: actualSTValueSum });
          lyActualSTSeriesData.data.push({ value: lyActualSTValueSum });
        }
      } else {
        const totalFCValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '005'),
          b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

        const customerFCValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '006'),
          b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

        const varUnitsFCValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '009'),
          b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

        const actualSTValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '007'),
          b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

        const lyActualSTValueSum = _.sum(_.compact(_.map(_.filter(structuredMatrixData, a => a.measureCode === '008'),
          b => b[`${week.weekField}`] ? Number(b[`${week.weekField}`]) : 0)));

        totalFCSeriesData.data.push({ value: totalFCValueSum });
        customerFCSeriesData.data.push({ value: customerFCValueSum });
        varUnitsFCSeriesData.data.push({ value: varUnitsFCValueSum });
        actualSTSeriesData.data.push({ value: actualSTValueSum });
        lyActualSTSeriesData.data.push({ value: lyActualSTValueSum });
      }
    });
    this.chartDataSource.categories[0].category = weekLabelList;
    this.chartDataSource.dataset = [totalFCSeriesData, customerFCSeriesData, varUnitsFCSeriesData, actualSTSeriesData, lyActualSTSeriesData];
  }

  filterMatrixDataByMeasures(structuredMatrixData) {
    const filteredMatrixData = _.filter(structuredMatrixData, a => this.selectedFilterMeasures.includes(a.measureCode));
    return filteredMatrixData;
  }

  calendarLevelChange() {
    this.createColDefs();
    this.applyFilterOnMatrixGrid();
  }

  matrixViewChange() {
    this.createColDefs();
    this.createMeasureDataOfMatrix();
  }

  onExportMatrixData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'ForecastDataMatrix',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.colApi.getAllDisplayedColumns(), a => a.colId)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  exportMatrixDataTemplate() {
    this.spinner.show();
    this.forecastManagerService.ExportForecastMatrixTemplate().subscribe((res: any) => {
      downloadFromLink(res, 'ForecastMatrixTemplate');
      this.spinner.hide();
    });
  }

  importMatrixData() {
    (document.getElementById('upload-matrix-data') as any).value = '';
    document.getElementById('upload-matrix-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();

    if (fileInput.target.files && fileInput.target.files.length) {
      const [files] = fileInput.target.files;
      formDataReader.append('file', files);
      this.uploadImportedCsvFile(formDataReader, this.forecastId);
    }
  }

  uploadImportedCsvFile(file, forecastId) {
    this.forecastManagerService.ImportForecastMatrixData(file, forecastId).subscribe(
      (res: any) => {
        this.toastr.success('Success', 'Forecast Data Imported Successfully.');
        this.refreshMatrixValue();
      },
      (err: any) => {
        this.toastr.error('Error', 'Forecast Data Import Failed.');
      });
  }

  refreshMatrixValue() {
    this.gridUpdatedCells = [];
    this.isGridDataUpdated = false;
    this.getForecastMatrixDataById();
  }

  calculateMatrixValue(isSilentCall: boolean = false) {
    if (this.selectedFilterMeasures.includes('002')
      && this.selectedFilterMeasures.includes('004')
      && this.selectedCalendarLevel === 'weekly') {
      this.spinner.show();
      this.structuredMatrixData = this.reCalculateMeasures(this.structuredMatrixData);
      this.createChartData(this.structuredMatrixData);
      this.gridData = this.reCalculateMeasures(this.gridData);
      this.gridApi && this.gridApi.setRowData(this.gridData);
      this.spinner.hide();
    } else if (!isSilentCall) {
      this.toastr.info('Info', 'Please select "Curated Baseline FC Units", "Curated Promo FC Units" and weekly item level view to calculate matrix data again.');
    }
  }

  reCalculateMeasures(matrixData) {
    const weekList = this.getWeekList();
    matrixData.forEach(row => {
      const curatedBaselineFC = _.find(matrixData, a => a.measureCode === '002' && a.SKU === row.SKU && a.RetailerKey == row.RetailerKey);
      const curatedPromoFC = _.find(matrixData, a => a.measureCode === '004' && a.SKU === row.SKU && a.RetailerKey == row.RetailerKey);

      // Calculate Total FC
      if (row.measureCode === '005') {
        weekList.forEach(week => {
          row[week.weekField] = Number(curatedBaselineFC[week.weekField] || 0) + Number(curatedPromoFC[week.weekField] || 0);
        });
      }

      // Calculate Var. Units FC
      if (row.measureCode === '009') {
        const actPOSUnits = _.find(matrixData, a => a.measureCode === '007' && a.SKU === row.SKU && a.RetailerKey == row.RetailerKey);
        if (actPOSUnits) {
          weekList.forEach(week => {
            const totalFC = Number(curatedBaselineFC[week.weekField] || 0) + Number(curatedPromoFC[week.weekField] || 0);
            row[week.weekField] = Math.abs(actPOSUnits[`${week.weekField}`] - totalFC);
          });
        }
      }

      // Calculate FC Accuracy %
      if (row.measureCode === '011') {
        const actPOSUnits = _.find(matrixData, a => a.measureCode === '007' && a.SKU === row.SKU && a.RetailerKey == row.RetailerKey);
        if (actPOSUnits) {
          weekList.forEach(week => {
            const totalFC = Number(curatedBaselineFC[week.weekField] || 0) + Number(curatedPromoFC[week.weekField] || 0);
            const projectST = actPOSUnits[`${week.weekField}`] > 0 ? actPOSUnits[`${week.weekField}`] : totalFC;
            row[week.weekField] = totalFC ? Number((projectST / totalFC) * 100) : 0;
          });
        }
      }

      // Calculate  % Total FC Change (Over Time)
      if (row.measureCode === '012') {
        const actPOSUnits = _.find(matrixData, a => a.measureCode === '007' && a.SKU === row.SKU && a.RetailerKey == row.RetailerKey);
        if (actPOSUnits) {
          for (let i = 0; i < this.columnDefs.length; i++) {
            if (!['Retailer', 'SKUDescription', 'seasonalityKey', 'brand', 'productGroup', 'productFamily', 'measureDescription'].includes(this.columnDefs[i].field)) {
              const currentWeektotalFC = Number(curatedBaselineFC[this.columnDefs[i].field] || 0) + Number(curatedPromoFC[this.columnDefs[i].field] || 0);
              const lastWeekTotalFC = Number(curatedBaselineFC[this.columnDefs[i - 1].field] || 0) + Number(curatedPromoFC[this.columnDefs[i - 1].field] || 0);
              const currentWeekProjectedST = actPOSUnits[`${this.columnDefs[i].field}`] > 0 ? actPOSUnits[`${this.columnDefs[i].field}`] : currentWeektotalFC;
              const lastWeekProjectST = actPOSUnits[`${this.columnDefs[i - 1].field}`] > 0 ? actPOSUnits[`${this.columnDefs[i - 1].field}`] : lastWeekTotalFC;
              row[`${this.columnDefs[i].field}`] = isNaN(lastWeekProjectST) ? 100 : ((currentWeekProjectedST / (lastWeekProjectST ? lastWeekProjectST : 1)) - 1) * 100;
            }
          }
        }
      }

      // Calculate Projeted ST
      if (row.measureCode === '013') {
        const actPOSUnits = _.find(matrixData, a => a.measureCode === '007' && a.SKU === row.SKU && a.RetailerKey == row.RetailerKey);
        if (actPOSUnits) {
          weekList.forEach(week => {
            const totalFC = Number(curatedBaselineFC[week.weekField] || 0) + Number(curatedPromoFC[week.weekField] || 0);
            row[week.weekField] = actPOSUnits[`${week.weekField}`] > 0 ? actPOSUnits[`${week.weekField}`] : totalFC;
          });
        }
      }
    });
    return matrixData;
  }

  saveMatrixData() {
    if (this.gridUpdatedCells.length) {
      this.spinner.show();
      this.forecastManagerService.ForecastMatrixDataUpdate({ forecastCuratedValues: this.gridUpdatedCells }).subscribe(res => {
        this.gridUpdatedCells = [];
        this.isGridDataUpdated = false;
        this.isAdjustmentAppliedAlready = false;
        this.calculateMatrixValue();
        this.gridApi.redrawRows();
        this.spinner.hide();
        this.forecastManagerDeleteUserSession();
        this.toastr.success('Success', `Forecast matrix data updated successfully.`);
      }, err => {
        this.toastr.error('Error', `Failed to update forecast matrix data.`);
        this.spinner.hide();
      });
    } else {
      this.toastr.info('Info', `Please update grid data.`);
    }
  }

  gridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {

      const column = params.column.colDef.field;
      /*
        Split this string "2022#H2-2022#202203#2022-09-06#202237" on hash(#)
        0 - Report Year
        1 - Half Year
        2 - Quarter of the Year
        3 - Month of the Year
        4 - Week Start Date
        5 - Week Number
      */
      const weekMetadat = column.split('#');
      weekMetadat[5] = Number(weekMetadat[5]);

      this.isGridDataUpdated = true;
      const prvUpdatedRowStateIndex = _.findIndex(this.gridUpdatedCells,
        a => a.weekNumber === weekMetadat[5]
          && a.itemKey === params.data.SKU
          && a.retailerKey === params.data.RetailerKey
      );
      if (prvUpdatedRowStateIndex < 0) {
        this.gridUpdatedCells.push({
          itemKey: params.data.SKU,
          retailerKey: params.data.RetailerKey,
          weekNumber: weekMetadat[5],
          weekField: column,
          forecastId: this.forecastId,
          measureCodes: [params.data.measureCode],
          curatedBaselineForecast: params.data.measureCode === '002' ? params.newValue : (-1000),
          curatedPromoForecast: params.data.measureCode === '004' ? params.newValue : (-1000),
        });
      } else {
        this.gridUpdatedCells[prvUpdatedRowStateIndex] = {
          itemKey: params.data.SKU,
          retailerKey: params.data.RetailerKey,
          weekNumber: weekMetadat[5],
          weekField: column,
          forecastId: this.forecastId,
          measureCodes: _.uniq([...this.gridUpdatedCells[prvUpdatedRowStateIndex].measureCodes, params.data.measureCode]),
          curatedBaselineForecast: params.data.measureCode === '002' ? params.newValue : this.gridUpdatedCells[prvUpdatedRowStateIndex].curatedBaselineForecast,
          curatedPromoForecast: params.data.measureCode === '004' ? params.newValue : this.gridUpdatedCells[prvUpdatedRowStateIndex].curatedPromoForecast
        };
      }

      params.api.refreshCells({
        columns: [params.column.getId()],
        rowNodes: [params.node],
        force: true // without this line, the cell style is not refreshed at the first time
      });

    }
  }

  forecastManagerDeleteUserSession() {
    this.spinner.show();
    this.forecastManagerService.ForecastManagerDeleteUserSession().subscribe(res => {
      if (res) {
        this.spinner.hide();
        // this.toastr.success('Success', `Forecast session deleted successfully.`);
      };
    }, err => {
      // this.toastr.error('Error', `System failed to delete Forecast session.`);
      this.spinner.hide();
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  ngOnDestroy(): void {
  }

}
